export default function LockIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6693 13.3333V10.6667C22.6693 6.98477 19.6845 4 16.0026 4C12.3207 4 9.33594 6.98477 9.33594 10.6667V13.3333M16.0026 19.3333V22M11.7359 28H20.2693C22.5095 28 23.6296 28 24.4852 27.564C25.2379 27.1805 25.8498 26.5686 26.2333 25.816C26.6693 24.9603 26.6693 23.8402 26.6693 21.6V19.7333C26.6693 17.4931 26.6693 16.373 26.2333 15.5174C25.8498 14.7647 25.2379 14.1528 24.4852 13.7693C23.6296 13.3333 22.5095 13.3333 20.2693 13.3333H11.7359C9.49573 13.3333 8.37562 13.3333 7.51998 13.7693C6.76733 14.1528 6.1554 14.7647 5.77191 15.5174C5.33594 16.373 5.33594 17.4931 5.33594 19.7333V21.6C5.33594 23.8402 5.33594 24.9603 5.77191 25.816C6.1554 26.5686 6.76733 27.1805 7.51998 27.564C8.37562 28 9.49573 28 11.7359 28Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
