/* eslint-disable max-lines */
import styled from 'styled-components';
import LargeBlob from '../Icons/LargeBlob';
import Screen1Image from '../Images/Screen1.png';
import Screen2Image from '../Images/Screen2.png';
import Screen3Image from '../Images/Screen3.png';
import Screen4Image from '../Images/Screen4.png';
import { scrollToMainInput } from '../HomePage';
import { EmblaOptionsType } from 'embla-carousel';
import Carousel from '@front_common/Components/Carousel';
import Blob2 from '../Icons/Blob2';
import { HTMLAttributes, useState } from 'preact/compat';
import iPhoneFrameImage from '@front_common/Components/Images/Iphone_frame.png';
import RightIcon from '../Icons/RightIcon';
import './HowEsimWorksCarousel.css';
import Button from '@front_common/Components/Button/Button';
import PhoneIcon from '../Icons/PhoneIcon';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import CompatibleDevicesModalContent from '../../../CompatibilityGuide/CompatibleDevicesModalContent';

export type Step = {
  step: number;
  title: string;
  text: string;
  image: string;
  color: string;
};

let stepsData: Step[] = [
  {
    step: 1,
    title: 'Verify for eSIM compatibility',
    text: 'Make sure your device supports eSIM before purchase',
    image: Screen1Image,
    color: 'var(--orange90)',
  },
  {
    step: 2,
    title: 'Select your country',
    text: 'Choose where you need service',
    image: Screen2Image,
    color: 'var(--cardsBackgroundLightBlueSecondary)',
  },
  {
    step: 3,
    title: 'Pick your data plan',
    text: 'Select how much data you will need',
    image: Screen3Image,
    color: 'var(--green90)',
  },
  {
    step: 4,
    title: 'Install your eSIM and enjoy',
    text: 'Follow simple steps provided to you after purchase to activate the eSIM on your device',
    image: Screen4Image,
    color: 'var(--purple100)',
  },
];

let OPTIONS: EmblaOptionsType = {
  slidesToScroll: 1,
  duration: 20,
  align: 'start',
};

export default function HowEsimWorks() {
  let [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <HowEsimWorksWrapper>
      <LeftBlock>
        <div>
          <Title>
            How eSIM <ColoredText>works</ColoredText>
          </Title>
          <Subtitle>
            Just a couple of steps separate you from hassle-free internet access
          </Subtitle>
          <CTAButton onClick={scrollToMainInput} sizeParam="L">
            Get started
          </CTAButton>
          <CompatibilityGuideBtn
            onClick={openModal}
            sizeParam="L"
            typeStyle="Secondary"
          >
            <span>Check my device’s compatibility</span>
            <PhoneIcon />
          </CompatibilityGuideBtn>
        </div>
      </LeftBlock>
      <RightBlock>
        <Carousel
          slides={stepsData}
          options={OPTIONS}
          baseClassName="how-it-works"
          getSlideComponent={(data: Step) => <Slide slideData={data} />}
          prevBtnChildren={
            <ButtonLeftWrapper>
              <BlobStep />
              <IconWrapper>
                <RightIcon />
              </IconWrapper>
            </ButtonLeftWrapper>
          }
          nextBtnChildren={
            <ButtonRightWrapper>
              <BlobStep />
              <IconWrapper>
                <RightIcon />
              </IconWrapper>
            </ButtonRightWrapper>
          }
        />
      </RightBlock>
      <Modal
        analyticType="device-compatibility"
        isOpen={isModalOpen}
        onClose={closeModal}
        closeButtonPrimary={false}
      >
        <CompatibleDevicesModalContent />
      </Modal>
    </HowEsimWorksWrapper>
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Step;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <SlideWrapper>
      <Blob $color={slideData.color} />
      <PhoneImageWrapper>
        <PhoneFrame loading="lazy" src={iPhoneFrameImage} alt="frame" />
        <ScreensWrapper>
          <img loading="lazy" src={slideData.image} alt="screen" />
        </ScreensWrapper>
      </PhoneImageWrapper>
      <StepInfo>
        <StepNumber>{slideData.step}</StepNumber>
        <StepTitle>{slideData.title}</StepTitle>
        <StepText>{slideData.text}</StepText>
      </StepInfo>
    </SlideWrapper>
  );
}

let ColoredText = styled.span`
  color: var(--textSelected);
`;

let HowEsimWorksWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 44px;

  @media (min-width: 700px) {
    text-align: center;
    padding-bottom: 20px;
  }

  @media (min-width: 960px) {
    gap: 60px;
    padding-top: 52px;
    display: flex;
  }
`;

let LeftBlock = styled.div`
  @media (min-width: 960px) {
    text-align: left;
    display: flex;
    align-items: center;
  }
`;

let Title = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  margin-bottom: 12px;
  font-size: 32px;
  line-height: 1;
  text-align: left;

  @media (min-width: 700px) {
    font-size: 52px;
    text-align: center;
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    text-align: left;
  }
`;

let Subtitle = styled.div`
  font-size: 20px;
  line-height: 28px;
`;

let CTAButton = styled(Button)`
  width: 100%;
  margin: 20px 0;

  @media (min-width: 700px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 960px) {
    display: flex;
    max-width: 327px;
    margin-top: 32px;
    margin-left: initial;
  }
`;

let RightBlock = styled.div`
  position: relative;
  padding-top: 20px;

  @media (min-width: 960px) {
    width: 540px;
    padding-top: 0;
    margin: initial;
  }

  @media (min-width: 1054px) {
    min-width: 635px;
    width: 635px;
  }
`;

let Blob = styled(LargeBlob)<{ $color: string }>`
  width: 100%;
  position: absolute;
  top: 100px;
  color: ${(props) => props.$color};
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 393px) {
    width: 343px;
  }

  @media (min-width: 960px) {
    top: 42px;
    width: 100%;
  }
`;

let SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

let PhoneImageWrapper = styled.div`
  position: relative;
  flex: 1;
`;

let PhoneFrame = styled.img`
  display: block;
  height: 380px;
  margin: 0 auto;
  position: relative;
  z-index: 3;

  @media (min-width: 960px) {
    top: 0;
    margin: 0 0 0 -120px;
    left: 50%;
    height: 500px;
  }
`;

let ScreensWrapper = styled.div`
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 5px;
  width: 168px;
  left: 50%;
  border-radius: 20px;
  margin-left: -84px;

  img {
    width: 100%;
    margin-top: 1px;
  }

  @media (min-width: 960px) {
    height: 480px;
    width: 225px;
    top: 8px;
    border-radius: 30px;
    margin-left: -113px;
  }
`;

let StepInfo = styled.div`
  margin-top: 18px;

  @media (min-width: 960px) {
    margin-top: 50px;
  }
`;

let StepNumber = styled.div`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--itemBrand);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 8px;
  color: var(--textPrimaryInvert);

  @media (min-width: 700px) {
    margin-bottom: 12px;
  }
`;

let StepTitle = styled.div`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  display: block;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    width: 100%;
    margin-bottom: 12px;
  }
`;

let StepText = styled.div`
  text-align: center;
  font-size: var(--fontSizeBig);
  line-height: 24px;

  @media (min-width: 700px) {
    width: 100%;
  }
`;

let BlobStep = styled(Blob2)`
  color: var(--iconsPrimary);
  opacity: 0.5;
  width: 70px;

  @media (min-width: 960px) {
    width: 90px;
  }
`;

let IconWrapper = styled.div`
  position: absolute;
  top: 9px;
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    width: 35px;
    height: 35px;

    path {
      fill: black;
    }
  }

  @media (min-width: 960px) {
    svg {
      width: 45px;
      height: 45px;
    }
  }
`;

let ButtonLeftWrapper = styled.div`
  position: relative;
  height: 100%;

  ${BlobStep} {
    transform: scaleX(-1);
  }

  ${IconWrapper} {
    transform: rotate(180deg);
  }
`;

let ButtonRightWrapper = styled.div`
  right: 0;
  height: 100%;

  ${BlobStep} {
    transform: scaleX(1);
  }
`;

let CompatibilityGuideBtn = styled(Button)`
  width: 100%;

  @media (min-width: 700px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 960px) {
    max-width: 327px;
    margin-left: initial;

    span {
      white-space: initial;
      width: 100%;
    }
  }
`;
