import styled from 'styled-components';
import BlueChevronDownIcon from './Icons/BlueChevronDownIcon';
import { useState } from 'react';

export default function DiscountDropdown(props: { children: React.ReactNode }) {
  let { children } = props;

  let [isOpen, setIsOpen] = useState(false);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <DropdownTitle onClick={toggleDropdown}>
        Add a discount code{' '}
        <ChevronIcon $isOpen={isOpen}>
          <BlueChevronDownIcon />
        </ChevronIcon>
      </DropdownTitle>
      <DropdownContent $isOpen={isOpen}>
        <DropdownItem>{children}</DropdownItem>
      </DropdownContent>
    </div>
  );
}

let DropdownTitle = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 22px;
  cursor: pointer;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  color: var(--buttonPrimary);
`;

let DropdownContent = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? '112px' : '0')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  overflow: hidden;
  transition:
    max-height 0.3s ease-out,
    opacity 0.3s ease-out;
`;

let DropdownItem = styled.div`
  margin-top: 12px;
`;

let ChevronIcon = styled.span<{ $isOpen: boolean }>`
  height: 24px;
  display: inline-block;
  transition: transform 0.2s ease-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: center;
`;
