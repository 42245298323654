import { ProviderName } from '../../Module/Provider/ProviderName';

export enum EsimStatus {
  UNKNOWN = 'unknown',
  PREPARING = 'preparing',
  NOT_INSTALLED = 'not-installed',
  INSTALLED = 'installed',
  ACTIVATED = 'activated',
  DATA_FINISHED = 'data-finished',
  EXPIRED = 'expired',
  REFUNDED = 'refunded',
}

export function initialEsimStatus(providerName: ProviderName) {
  return providerName === ProviderName.AirHub
    ? EsimStatus.UNKNOWN
    : EsimStatus.NOT_INSTALLED;
}
