export function GoogleIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 21.2533C38 19.7851 37.8808 18.7136 37.623 17.6025H20.8572V24.2294H30.6983C30.5 25.8763 29.4286 28.3565 27.0476 30.023L27.0142 30.2449L32.3152 34.3515L32.6825 34.3882C36.0555 31.2731 38 26.6897 38 21.2533Z"
        fill="#4285F4"
      />
      <path
        d="M20.8576 38.714C25.679 38.714 29.7265 37.1266 32.683 34.3886L27.048 30.0235C25.5401 31.0751 23.5163 31.8092 20.8576 31.8092C16.1355 31.8092 12.1276 28.6942 10.6989 24.3887L10.4895 24.4065L4.97736 28.6723L4.90527 28.8727C7.84174 34.706 13.8735 38.714 20.8576 38.714Z"
        fill="#34A853"
      />
      <path
        d="M10.6983 24.3884C10.3213 23.2773 10.1032 22.0868 10.1032 20.8567C10.1032 19.6265 10.3213 18.436 10.6785 17.3249L10.6685 17.0883L5.08731 12.7539L4.90471 12.8408C3.69445 15.2614 3 17.9797 3 20.8567C3 23.7336 3.69445 26.4518 4.90471 28.8725L10.6983 24.3884Z"
        fill="#FBBC05"
      />
      <path
        d="M20.8576 9.90469C24.2107 9.90469 26.4726 11.3531 27.7623 12.5635L32.802 7.64284C29.7068 4.76587 25.679 3 20.8576 3C13.8735 3 7.84174 7.00789 4.90527 12.8412L10.679 17.3253C12.1276 13.0198 16.1355 9.90469 20.8576 9.90469Z"
        fill="#EB4335"
      />
    </svg>
  );
}
