import {
  deleteCookie,
  getCookie,
  setCookie,
} from '@shared_frontend/Common/Cookie';
import { sha256 } from '@shared_frontend/Common/Sha256';
import { now } from '@shared_frontend/Common/Date';
import { AdEvents } from '@front_common/Ads/AdEvents';

export default class AuthService {
  private static loginTokenBasic: string | false;
  private static visitorToken: string;

  static getLoginTokenBasic(): string | false {
    if (this.loginTokenBasic) {
      return this.loginTokenBasic;
    }
    let local = localStorage.getItem('login_token_basic');
    if (local && local.length > 60) {
      this.loginTokenBasic = local;
      return local;
    }
    let cookie = getCookie('login_token_basic');
    if (cookie && cookie.length > 60) {
      this.loginTokenBasic = cookie;
      return cookie;
    }
    this.loginTokenBasic = false;
    return false;
  }

  static getVisitorToken(): string {
    if (this.visitorToken) {
      return this.visitorToken;
    }
    let local = localStorage.getItem('visitor_token');
    if (local && local.length > 60) {
      this.visitorToken = local;
      return local;
    }
    let cookie = getCookie('visitor_token');
    if (cookie && cookie.length > 60) {
      this.visitorToken = cookie;
      return cookie;
    }
    let newToken = this.createNewToken();
    this.visitorToken = newToken;
    localStorage.setItem('visitor_token', newToken);
    setCookie('visitor_token', newToken, 365);
    return newToken;
  }

  private static createNewToken(): string {
    let random: string;
    try {
      random = crypto.randomUUID();
    } catch {
      random = Date.now() + '_' + Math.random() * 999_999_999;
    }
    return sha256(
      [
        random,
        window.screen.height,
        window.screen.width,
        navigator.language,
      ].join('_'),
    );
  }

  static getFirstVisitTimestamp(): number {
    let local = localStorage.getItem('first_visit');
    if (local && Number(local) > 100_000) {
      return Number(local);
    }
    let cookie = getCookie('first_visit');
    if (cookie && Number(cookie) > 100_000) {
      return Number(cookie);
    }
    let ts = now();
    localStorage.setItem('first_visit', String(ts));
    setCookie('first_visit', String(ts), 365);
    return ts;
  }

  static isLoggedIn(): boolean {
    return Boolean(this.getLoginTokenBasic());
  }

  static getUserId(): number | null {
    let loginToken = this.getLoginTokenBasic();
    if (!loginToken) {
      return null;
    }
    let parts = atob(loginToken).split(':');
    if (parts[0] && Number(parts[0])) {
      return Number(parts[0]);
    }
    return null;
  }

  static storeLoginToken(userId: number, loginToken: string) {
    let loginTokenBasic = btoa(userId + ':' + loginToken);
    this.loginTokenBasic = loginTokenBasic;
    localStorage.setItem('login_token_basic', loginTokenBasic);
    setCookie('login_token_basic', loginTokenBasic, 365);
    AdEvents.setUser(userId);
  }

  static deleteLoginData() {
    this.loginTokenBasic = false;
    localStorage.removeItem('login_token_basic');
    localStorage.removeItem('unpaid_modal_touched');
    deleteCookie('login_token_basic');
  }
}
