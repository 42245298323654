import UnsubscribeEarthImage from '../Images/UnsubscribeEarth.png';
import { ModalCloseButton } from '@front_common/Components/Modal/OldDesign/Modal';
import CloseIcon from '@front_common/Components/Modal/OldDesign/CloseIcon';
import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';

type IProps = {
  cancelledAt: string;
  onClose: (event: MouseEvent) => void;
};

export default function UnsubscribeModalContent({
  onClose,
  cancelledAt,
}: IProps) {
  return (
    <ModalContentWrapper>
      {/* //TODO: the Modal already has a close button, so you don't need to add another one. Or do you need? */}
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <img src={UnsubscribeEarthImage} alt="unsubscribe image" />
      <UnsubscribeTitle>Subscription canceled!</UnsubscribeTitle>
      <UnsubscribeDescription>
        Your eSIM will stay active until {cancelledAt}, and you can use your
        remaining data during this time. We hope to see you again soon!
      </UnsubscribeDescription>
      <GotItButton sizeParam="L" onClick={onClose}>
        Got it
      </GotItButton>
    </ModalContentWrapper>
  );
}

let ModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`;

let CloseButton = styled(ModalCloseButton)`
  top: 4px;
  right: 4px;
  background-color: var(--backgroundPrimary);
`;

let UnsubscribeTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let UnsubscribeDescription = styled.p`
  margin-bottom: 16px;
`;

let GotItButton = styled(Button)`
  width: 100%;
`;
