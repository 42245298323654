import Card from '@front_common/Components/Card';
import styled from 'styled-components';
import AlertIcon from './AlertIcon';

export default function PaymentError({ errorText }: { errorText: string }) {
  return (
    <PaymentErrorWrapper>
      <AlertIcon />
      <span>{errorText}</span>
    </PaymentErrorWrapper>
  );
}

let PaymentErrorWrapper = styled(Card)`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  border-color: var(--red100);

  svg {
    min-width: 24px;
  }

  span {
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontWeightMedium);
    line-height: 20px;
  }

  @media (min-width: 960px) {
    padding: 14px 16px;
  }
`;
