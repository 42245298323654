import { LinkToRoute } from '@front_common/Router/Router';
import styled from 'styled-components';
import MoneyImage from './Images/Money.png';
import Button from '@front_common/Components/Button/Button';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';

export default function ExplorePlans() {
  return (
    <ExplorePlansWrapper>
      <Top>
        <img src={MoneyImage} alt="money" />
      </Top>
      <Bottom>
        <Title>Never worry about roaming again!</Title>
        <Text>
          Say goodbye to expensive roaming fees. Get your eSIM and stay
          connected globally with zero hassle.
        </Text>
        <CTAButtonLink
          style={{ textDecoration: 'none' }}
          // eslint-disable-next-line secrets/no-secrets
          href={`${WEB_ROUTE_HOME}?utm_source=landing_page&utm_content=how_to_install_esim`}
        >
          <Button sizeParam="L">Explore plans</Button>
        </CTAButtonLink>
      </Bottom>
    </ExplorePlansWrapper>
  );
}

let ExplorePlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

let Top = styled.div`
  background-color: var(--orange70);
  text-align: center;

  img {
    height: 160px;
  }

  @media (min-width: 700px) {
    img {
      height: 220px;
    }
  }

  @media (min-width: 960px) {
    order: 2;
    min-width: 325px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      width: 189px;
    }
  }
`;

let Bottom = styled.div`
  background-color: var(--orange90);
  padding: 20px;

  @media (min-width: 960px) {
    order: 1;
  }
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  margin-bottom: 4px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

let Text = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
  margin-bottom: 12px;

  @media (min-width: 700px) {
    margin-bottom: 16px;
  }
`;

let CTAButtonLink = styled(LinkToRoute)`
  button {
    width: 100%;
  }

  @media (min-width: 960px) {
    button {
      width: 236px;
    }
  }
`;
