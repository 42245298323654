import AbstractApi from '@front_common/Api/AbstractApi';
import AuthService from '@front_common/Api/AuthService';
import {
  API_ROUTE_POST_ACTION,
  API_ROUTE_SPLIT_MAY_ALLOCATE,
} from '@shared_backend/ApiRoutes.ts';
import ActionRequest from '@shared_backend/Module/Action/ActionRequest';
import {
  ActionTypes,
  getStringFromActionType,
} from '@shared_backend/Module/Action/ActionTypes';
import { SplitAddingContextEnum } from '@shared_backend/Module/SplitTesting/SplitAddingContext';
import SplitResponse from '@shared_backend/Module/SplitTesting/SplitResponse';
import { CONFIG_API_HOST, isProdEnv } from '@front_common/Config';
import { asyncHook } from '@shared_frontend/Common/HookFactory';

// eslint-disable-next-line no-shadow
export enum SplitGroupId {
  A = 29, //KEEP
}

// eslint-disable-next-line no-shadow
export enum DesignSplitGroupId {
  A = 35,
  B = 36,
}

type GroupId = SplitGroupId | DesignSplitGroupId;

let contextAllowSplitMap: Record<
  string,
  { allow: GroupId[]; default: GroupId }
> = {
  [SplitAddingContextEnum.LANDING_PRICE_PAGE_RENDER]: {
    allow: [SplitGroupId.A],
    default: SplitGroupId.A,
  },
  [SplitAddingContextEnum.LANDING_PAGE_RENDER]: {
    allow: [DesignSplitGroupId.A, DesignSplitGroupId.B],
    default: DesignSplitGroupId.A,
  },
  //another active context mapping
};

export default class Api extends AbstractApi {
  protected static async request<T>(method: string, options: RequestInit) {
    let loginTokenBasic = AuthService.getLoginTokenBasic();
    if (loginTokenBasic) {
      this.setHeaderForAll('Authorization', 'Basic ' + loginTokenBasic);
    }
    this.setHeaderForAll('X-Visitor-Token', AuthService.getVisitorToken());
    return AbstractApi.request<T>(method, options);
  }

  static trackAction(
    actionType: ActionTypes,
    actionValue?: string | number | undefined | null,
    url?: string,
  ) {
    if (!isProdEnv) {
      return;
    }

    if (url === undefined) {
      // eslint-disable-next-line no-param-reassign
      url = window.location.href;
    } else if (!url.startsWith('http')) {
      throw new Error('Url must be full and starts with "http" ' + url);
    }

    let requestData: ActionRequest = {
      actionType: getStringFromActionType(actionType),
      // @ts-ignore
      visitorId: AuthService.getVisitorToken(),
      userId: AuthService.getUserId(),
      actionValue,
      url,
    };

    if (navigator.sendBeacon) {
      navigator.sendBeacon(
        CONFIG_API_HOST + API_ROUTE_POST_ACTION,
        JSON.stringify(requestData),
      );
    }
  }

  static async mayAllocateToSplit(context: SplitAddingContextEnum) {
    let result: SplitResponse = await this.post(API_ROUTE_SPLIT_MAY_ALLOCATE, {
      context,
    });

    //if the result is not in the allowed list, return the default value
    if (
      !result.splitGroupId ||
      (result.splitGroupId &&
        !contextAllowSplitMap[context]?.allow.includes(result.splitGroupId))
    ) {
      return contextAllowSplitMap[context]?.default;
    }

    return result.splitGroupId;
  }
}

export const useMayAllocateToSplit = asyncHook(
  (context: SplitAddingContextEnum) => Api.mayAllocateToSplit(context),
);
