import {
  createContext,
  ReactNode,
  SetStateAction,
  Dispatch,
  useContext,
  useState,
} from 'react';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import UserApi from '@front_common/Api/UserApi';
import { SubProductType } from '@shared_backend/Module/Subscription/SubProductResponse';

interface PaymentContextType {
  subscription: SubProductType | null;
  paymentSession: PaymentSessionEntityType | null;
  setPaymentSession: Dispatch<SetStateAction<PaymentSessionEntityType | null>>;
  paymentSessionError: string;
  setPaymentSessionError: Dispatch<SetStateAction<string>>;
  paymentError: string;
  setPaymentError: Dispatch<SetStateAction<string>>;
  resetPaymentSession: () => void;
  amountCents: {
    sessionAmountCents: number;
    subscriptionAmountCents: number;
  };
}

let PaymentContext = createContext<PaymentContextType>({
  subscription: null,
  paymentSession: null,
  setPaymentSession: () => {},
  paymentSessionError: '',
  setPaymentSessionError: () => {},
  paymentError: '',
  setPaymentError: () => {},
  resetPaymentSession: () => {},
  amountCents: {
    sessionAmountCents: 0,
    subscriptionAmountCents: 0,
  },
});

export function usePaymentContext() {
  return useContext(PaymentContext);
}

interface PaymentProviderProps {
  subscription: SubProductType;
  children: ReactNode;
}

export function PaymentProvider({
  subscription,
  children,
}: PaymentProviderProps) {
  let [paymentSession, setPaymentSession] =
    useState<PaymentSessionEntityType | null>(null);
  let [paymentSessionError, setPaymentSessionError] = useState<string>('');
  let [paymentError, setPaymentError] = useState<string>('');

  async function resetPaymentSession() {
    try {
      let newPaymentSession = await UserApi.createSubscriptionAttempt(
        subscription.id,
      );
      setPaymentSession(newPaymentSession);
    } catch (error) {
      logger.error(error);
    }
  }

  let amountCents = {
    sessionAmountCents:
      paymentSession?.totalAmountCents || subscription.priceCents,
    subscriptionAmountCents: subscription.priceCents,
  };

  return (
    <PaymentContext.Provider
      value={{
        subscription,
        paymentSession,
        setPaymentSession,
        paymentSessionError,
        setPaymentSessionError,
        paymentError,
        setPaymentError,
        resetPaymentSession,
        amountCents,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}
