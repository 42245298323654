function canBeFrozen(value: unknown) {
  return (
    value !== null &&
    value !== undefined &&
    (typeof value === 'object' || typeof value === 'function')
  );
}

export function deepObjectFreeze<T>(obj: T): ReadonlyDeep<T> {
  if (!canBeFrozen(obj)) {
    return obj as ReadonlyDeep<T>;
  }
  for (let value of Object.values(obj)) {
    if (canBeFrozen(value) && !Object.isFrozen(value)) {
      deepObjectFreeze(value);
    }
  }
  return Object.freeze(obj) as ReadonlyDeep<T>;
}

/* eslint-disable */ // --> OFF

type Primitive = null | undefined | string | number | boolean | symbol | bigint;

type BuiltIns = Primitive | void | Date | RegExp;

type HasMultipleCallSignatures<T extends (...arguments_: any[]) => unknown> =
  T extends {
    (...arguments_: infer A): unknown;
    (...arguments_: infer B): unknown;
  }
    ? B extends A
      ? A extends B
        ? false
        : true
      : true
    : false;

export type ReadonlyDeep<T> = T extends BuiltIns
  ? T
  : T extends new (...arguments_: any[]) => unknown
    ? T // Skip class constructors
    : T extends (...arguments_: any[]) => unknown
      ? {} extends ReadonlyObjectDeep<T>
        ? T
        : HasMultipleCallSignatures<T> extends true
          ? T
          : ((...arguments_: Parameters<T>) => ReturnType<T>) &
              ReadonlyObjectDeep<T>
      : T extends Readonly<ReadonlyMap<infer KeyType, infer ValueType>>
        ? ReadonlyMapDeep<KeyType, ValueType>
        : T extends Readonly<ReadonlySet<infer ItemType>>
          ? ReadonlySetDeep<ItemType>
          : // Identify tuples to avoid converting them to arrays inadvertently; special case `readonly [...never[]]`, as it emerges undesirably from recursive invocations of ReadonlyDeep below.
            T extends readonly [] | readonly [...never[]]
            ? readonly []
            : T extends readonly [infer U, ...infer V]
              ? readonly [ReadonlyDeep<U>, ...ReadonlyDeep<V>]
              : T extends readonly [...infer U, infer V]
                ? readonly [...ReadonlyDeep<U>, ReadonlyDeep<V>]
                : T extends ReadonlyArray<infer ItemType>
                  ? ReadonlyArray<ReadonlyDeep<ItemType>>
                  : T extends object
                    ? ReadonlyObjectDeep<T>
                    : unknown;

/**
 Same as `ReadonlyDeep`, but accepts only `ReadonlyMap`s as inputs. Internal helper for `ReadonlyDeep`.
 */
type ReadonlyMapDeep<KeyType, ValueType> = {} & Readonly<
  ReadonlyMap<ReadonlyDeep<KeyType>, ReadonlyDeep<ValueType>>
>;

/**
 Same as `ReadonlyDeep`, but accepts only `ReadonlySet`s as inputs. Internal helper for `ReadonlyDeep`.
 */
type ReadonlySetDeep<ItemType> = {} & Readonly<
  ReadonlySet<ReadonlyDeep<ItemType>>
>;

/**
 Same as `ReadonlyDeep`, but accepts only `object`s as inputs. Internal helper for `ReadonlyDeep`.
 */
type ReadonlyObjectDeep<ObjectType extends object> = {
  readonly [KeyType in keyof ObjectType]: ReadonlyDeep<ObjectType[KeyType]>;
};
