import { useState, useEffect } from 'preact/hooks';
import { SubscriptionStatus } from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { useGetMySubscriptions } from '@front_common/Api/UserApi';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import UnpaidSubscriptionModalContent from './UnpaidSubscriptionModalContent';

export default function UnpaidSubscriptionModal() {
  let [subs, error] = useGetMySubscriptions();

  if (error || !subs?.length) {
    return null;
  }

  let [isUnpaidModalOpen, setIsUnpaidModalOpen] = useState(false);

  let unpaidSubscription = subs.find(
    (sub) => sub.status === SubscriptionStatus.UNPAID,
  );

  useEffect(() => {
    if (unpaidSubscription && unpaidSubscription.esimId) {
      let isUnpaidNotificationTouched = localStorage.getItem(
        'unpaid_modal_touched',
      );

      if (!isUnpaidNotificationTouched) {
        setIsUnpaidModalOpen(true);
      }
    }
  }, [unpaidSubscription]);

  function handleUnpaidModalClose() {
    setIsUnpaidModalOpen(false);
    //TODO: make it as an exported const in this component, and import it in the AuthService. In this case we will not forget that this variable is used in the AuthService too.
    localStorage.setItem('unpaid_modal_touched', 'true');
  }

  return (
    <Modal
      analyticType="unpaid-subscription"
      isOpen={isUnpaidModalOpen}
      onClose={handleUnpaidModalClose}
    >
      {unpaidSubscription && unpaidSubscription.esimId ? (
        <UnpaidSubscriptionModalContent
          onClose={handleUnpaidModalClose}
          unpaidESimId={unpaidSubscription.esimId}
        />
      ) : null}
    </Modal>
  );
}
