import styled from 'styled-components';
import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import WorldIcon from './Icons/WorldIcon';
import LinesIcon from './Icons/LinesIcon';
import CalendarIcon from './Icons/CalendarIcon';
import BlueChevronDownIcon from './Icons/BlueChevronDownIcon';
import { useState } from 'react';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';

export default function Summary(props: {
  totalAmount: string;
  targetCountry: string;
  dataAmountGb: number;
  durationDays: number;
  children: React.ReactNode;
}) {
  let { totalAmount, targetCountry, dataAmountGb, durationDays, children } =
    props;
  let windowDimensions = useWindowDimensions();

  let [isOpen, setIsOpen] = useState(windowDimensions.width >= 960);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  let countryName = getEsimPlanCountry(targetCountry);

  return (
    <SummaryWrapper>
      <DropdownButton onClick={toggleDropdown}>
        <TitleText>
          Order summary{' '}
          <ChevronIcon $isOpen={isOpen}>
            <BlueChevronDownIcon />
          </ChevronIcon>
        </TitleText>
        <TitleAmount>{totalAmount}</TitleAmount>
      </DropdownButton>
      <DropdownContent $isOpen={isOpen}>
        <DropdownItem>
          <PlanInfo>
            <InfoRow>
              <LeftSide>
                <WorldIcon />
                Country:
              </LeftSide>
              <RightSide>
                {countryName}{' '}
                <EsimPlanFlag withBorder targetCountry={targetCountry} />
              </RightSide>
            </InfoRow>
            <InfoRow>
              <LeftSide>
                <LinesIcon />
                Data amount:
              </LeftSide>
              <RightSide>{dataAmountGb}GB</RightSide>
            </InfoRow>
            <InfoRow>
              <LeftSide>
                <CalendarIcon />
                Valid for:
              </LeftSide>
              <RightSide>{durationDays} days</RightSide>
            </InfoRow>
          </PlanInfo>
          {children}
        </DropdownItem>
      </DropdownContent>
    </SummaryWrapper>
  );
}

let SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

let PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

let InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

let LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  text-align: left;
`;

let RightSide = styled.div`
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightBold);
  line-height: 22px;
  gap: 8px;

  img {
    height: 24px;
  }
`;

let DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (min-width: 960px) {
    pointer-events: none;
  }
`;

let DropdownContent = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? '400px' : '0')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  overflow: hidden;
  transition:
    max-height 0.3s ease-out,
    opacity 0.3s ease-out;
`;

let DropdownItem = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let ChevronIcon = styled.span<{ $isOpen: boolean }>`
  height: 24px;
  display: inline-block;
  transition: transform 0.2s ease-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: center;
`;

let TitleText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 22px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  color: var(--buttonPrimary);

  @media (min-width: 960px) {
    color: var(--textPrimary);
    font-size: 24px;
    font-weight: var(--fontWeightBold);
    line-height: 32px;

    svg {
      display: none;
    }
  }
`;

let TitleAmount = styled.span`
  font-weight: var(--fontWeightSemiBold);
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  line-height: 28px;
  color: var(--textPrimary);

  @media (min-width: 960px) {
    font-size: 24px;
    font-weight: var(--fontWeightBold);
    line-height: 32px;
  }
`;
