import './Normalize.css';
import '@shared_frontend/Common/DesignSystem/DesignSystem.css';
import './App.css';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import styled from 'styled-components';
import {
  WEB_ROUTE_AUTH,
  WEB_ROUTE_CHECKOUT,
  WEB_ROUTE_FAQ,
  WEB_ROUTE_FAQ_QUESTION,
  WEB_ROUTE_FAQ_TOPIC,
  WEB_ROUTE_GLOBAL_CHECKOUT,
  WEB_ROUTE_HOME,
  WEB_ROUTE_HOW_TO_INSTALL_ESIM,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_MY_PROFILE,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
  WEB_ROUTE_MY_SUBSCRIPTIONS_UNSUBSCRIBE,
  WEB_ROUTE_STORE_COUNTRY,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import React, { useEffect, useState } from 'preact/compat';
import AuthService from '@front_common/Api/AuthService';
import MobileNavBar from './MobileNavBar';
import Sidebar from './Sidebar';
import CloseMenuIcon from './Icons/CloseMenuIcon';
import ThreeLinesIcon from './Icons/ThreeLinesIcon';
import { getQueryParam } from '@front_common/Router/Router';
import {
  BURGER_MENU_CLASS_NAME,
  MOBILE_SUPPORT_BUTTON_CLASS_NAME,
  PROFILE_BUTTON_LAYOUT_CLASS_NAME,
  SUPPORT_BUTTON_CLASS_NAME,
} from '../Checkout/useDisplayingHeaderFooterElements';
import { HeaderContainer } from './HeaderContainer';
import { FooterContainer } from './FooterContainer';
import { ShortFooterContainer } from './ShortFooterContainer';
import { SignInProfileButtonContainer } from './SignInProfileButtonContainer';
import { MobileSupportChatButtonContainer } from '@front_common/ZendeskWidget/MobileSupportChatButtonContainer';
import { SupportChatButtonContainer } from '@front_common/ZendeskWidget/SupportChatButtonContainer';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { HEADER_HEIGHT } from './NewDesign/Header';

let withoutFooterRoutes = [
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_PROFILE,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_CHECKOUT,
  WEB_ROUTE_MY_SUBSCRIPTIONS_UNSUBSCRIBE,
  WEB_ROUTE_GLOBAL_CHECKOUT,
];
let withNavBarRoutes = [
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_PROFILE,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
];
let withoutBurgerMenuRoutes = [
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_PROFILE,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_CHECKOUT,
  WEB_ROUTE_GLOBAL_CHECKOUT,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
];
let withoutSignInProfileButtonRoutes = [
  WEB_ROUTE_AUTH,
  WEB_ROUTE_CHECKOUT,
  WEB_ROUTE_GLOBAL_CHECKOUT,
];
let withoutPaddingRoutes = [
  WEB_ROUTE_FAQ,
  WEB_ROUTE_FAQ_TOPIC,
  WEB_ROUTE_FAQ_QUESTION,
  WEB_ROUTE_STORE_COUNTRY,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_HOW_TO_INSTALL_ESIM,
  WEB_ROUTE_STORE_GLOBAL,
];
let withShortFooterRoutes = [WEB_ROUTE_STORE_COUNTRY, WEB_ROUTE_AUTH];
let withBottomMarginRoutes = [WEB_ROUTE_STORE_COUNTRY];
let onlyLogoHeaderRoutes = [WEB_ROUTE_CHECKOUT, WEB_ROUTE_GLOBAL_CHECKOUT];
let withCustomSupportOffsetRoute = [
  WEB_ROUTE_MY_PROFILE,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
];
let withoutSupportRoutes = [WEB_ROUTE_AUTH, WEB_ROUTE_GLOBAL_CHECKOUT];
let withoutMobileSupportRoutes = [WEB_ROUTE_FAQ, WEB_ROUTE_MY_PROFILE];

export const QUERY_PARAM_HIDE_LAYOUT = 'hide_layout';

function normalizeRoute(route: string): string {
  let paramIndex = route.lastIndexOf('/:');

  // eslint-disable-next-line unicorn/prefer-string-slice
  return paramIndex === -1 ? route : route.substring(0, paramIndex);
}

//TODO: need to fix this function!
//We have visual issues with the footer beacuse of this
function isRouteMatched(routeList: string[], currentPath: string): boolean {
  return routeList.some((route) =>
    currentPath.startsWith(normalizeRoute(route)),
  );
}

export default function Layout({ children }: { children: React.ReactNode }) {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  if (!splitGroupId) {
    return <LoadingAnimation />;
  }

  if (getQueryParam(QUERY_PARAM_HIDE_LAYOUT)) {
    return <>{children}</>;
  }

  let router = useRouterState();
  let currentPath = router.path || window.location.pathname;

  let authenticated = AuthService.isLoggedIn();

  let showFooter = !isRouteMatched(withoutFooterRoutes, currentPath);
  let shortFooter = isRouteMatched(withShortFooterRoutes, currentPath);
  let hideBurgerMenu = isRouteMatched(withoutBurgerMenuRoutes, currentPath);
  let withoutPadding = isRouteMatched(withoutPaddingRoutes, currentPath);
  let hideSupportButton = isRouteMatched(withoutSupportRoutes, currentPath);
  let hideMobileSupportButton = isRouteMatched(
    withoutMobileSupportRoutes,
    currentPath,
  );
  let withBottomMargin = isRouteMatched(withBottomMarginRoutes, currentPath);
  let onlyLogoHeader = isRouteMatched(onlyLogoHeaderRoutes, currentPath);
  let showMobileNavBar =
    authenticated && isRouteMatched(withNavBarRoutes, currentPath);

  let hideSignInProfileButton = isRouteMatched(
    withoutSignInProfileButtonRoutes,
    currentPath,
  );
  let withCustomSupportOffset = isRouteMatched(
    withCustomSupportOffsetRoute,
    currentPath,
  );

  // TODO (SPLIT TEST): (new design)
  let [showSidebar, setShowSidebar] = useState(false);
  let showBanner = currentPath === WEB_ROUTE_HOME;

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [router.url]);

  useEffect(() => {
    setShowSidebar(false);
  }, [currentPath]);

  useEffect(() => {
    function handleTouchMove(event: TouchEvent) {
      if (showSidebar) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    if (showSidebar) {
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [showSidebar]);

  // TODO (SPLIT TEST): (new design)
  useEffect(() => {
    function updateThemeColor(color: string) {
      let metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', color);
      } else {
        let meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = color;
        document.head.append(meta);
      }
    }
    let themeColor =
      // We cannot use design system variables
      splitGroupId === DesignSplitGroupId.A ? '#f4ad6e' : '#ef8268';
    updateThemeColor(themeColor);
  }, [splitGroupId]);

  return (
    <PageContainer>
      <HeaderWrapper $withBanner={showBanner} $withNewDesign={false}>
        <HeaderContainer
          splitGroupId={DesignSplitGroupId.A}
          onlyLogo={onlyLogoHeader}
          hideSignInProfileButton={hideSignInProfileButton}
          hideSupportButton={hideSupportButton}
        />
      </HeaderWrapper>
      <PageWrapper
        $bigBottomPadding={showMobileNavBar}
        $withoutPadding={withoutPadding}
        $useWithNewDesign={false}
      >
        {children}
      </PageWrapper>
      {showFooter && (
        <>
          {shortFooter ? (
            <ShortFooterContainer
              withBottomMargin={withBottomMargin}
              splitGroupId={DesignSplitGroupId.A}
            />
          ) : (
            <FooterContainer splitGroupId={DesignSplitGroupId.A} />
          )}
        </>
      )}

      {showMobileNavBar && (
        <MobileNavBarWrapper>
          <MobileNavBar />
        </MobileNavBarWrapper>
      )}

      {!hideSupportButton && !hideMobileSupportButton && (
        <MobileSupportChatButtonWrapper
          $isSidebarOpen={showSidebar}
          $authenticated={authenticated}
          $withCustomSupportOffset={withCustomSupportOffset}
          $showBanner={showBanner}
          $withNewDesign={false}
          className={MOBILE_SUPPORT_BUTTON_CLASS_NAME}
        >
          <MobileSupportChatButtonContainer />
        </MobileSupportChatButtonWrapper>
      )}

      {/* for the opened burger menu */}
      {!hideSignInProfileButton && !showMobileNavBar && (
        <SignInProfileButtonWrapper
          $isSidebarOpen={showSidebar}
          $authenticated={authenticated}
          $showBanner={showBanner}
          $withNewDesign={false}
          className={PROFILE_BUTTON_LAYOUT_CLASS_NAME}
        >
          <SignInProfileButtonContainer
            authenticated={authenticated}
            splitGroupId={DesignSplitGroupId.A}
          />
        </SignInProfileButtonWrapper>
      )}

      {!hideBurgerMenu && (
        <>
          <BurgerButton
            $showBanner={showBanner}
            $withNewDesign={false}
            className={BURGER_MENU_CLASS_NAME}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            {showSidebar ? <CloseMenuIcon /> : <ThreeLinesIcon />}
          </BurgerButton>
        </>
      )}
      <>
        <SidebarWrapper $show={showSidebar}>
          <Sidebar />
        </SidebarWrapper>
        <SidebarOverlay
          $show={showSidebar}
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </>
      {!hideSupportButton && (
        <div className={SUPPORT_BUTTON_CLASS_NAME}>
          <SupportChatButtonContainer
            shortFooter={shortFooter}
            splitGroupId={DesignSplitGroupId.A}
          />
        </div>
      )}
    </PageContainer>
  );
}

let PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

let PageWrapper = styled.div<{
  $bigBottomPadding: boolean;
  $withoutPadding: boolean;

  // TODO (SPLIT TEST): (design)
  $useWithNewDesign: boolean;
}>`
  flex: 1;
  width: 100%;
  max-width: ${(props) =>
    props.$withoutPadding || props.$useWithNewDesign ? 'initial' : '1440px'};
  min-width: 320px;
  margin: 0 auto;
  padding-bottom: ${(props) => (props.$bigBottomPadding ? '122px' : '32px')};
  padding: 0
    ${(props) =>
      props.$withoutPadding || props.$useWithNewDesign ? '0' : '16px'};
  margin-top: ${(props) => (props.$useWithNewDesign ? '0' : '72px')};

  @media (min-width: 700px) {
    padding: 0
      ${(props) =>
        props.$withoutPadding || props.$useWithNewDesign ? '0' : '40px'};
    padding-bottom: ${(props) =>
      props.$useWithNewDesign
        ? '0'
        : // eslint-disable-next-line unicorn/no-nested-ternary
          props.$bigBottomPadding
          ? '122px'
          : '32px'};
  }

  @media (min-width: 960px) {
    padding: 0
      ${(props) =>
        props.$withoutPadding || props.$useWithNewDesign ? '0' : '80px'};
    padding-bottom: ${(props) => (props.$useWithNewDesign ? '0' : '60px')};
  }
`;

let SignInProfileButtonWrapper = styled.div<{
  $withNewDesign: boolean;
  $authenticated: boolean;
  $isSidebarOpen: boolean;
  $showBanner: boolean;
}>`
  z-index: 105;
  position: fixed;
  right: ${(props) => (props.$withNewDesign ? '24px' : '0px')};
  top: ${(props) =>
    props.$withNewDesign && !props.$isSidebarOpen && props.$showBanner
      ? '76px'
      : '16px'};
  transform: translateX(-50%);
  transition: top 0.5s ease;

  @media (min-width: 393px) {
    right: ${(props) => (props.$withNewDesign ? '24px' : '16px')};
  }

  @media (min-width: 700px) {
    right: 86px;
  }

  @media (min-width: 960px) {
    display: none;
  }
`;

let MobileSupportChatButtonWrapper = styled.div<{
  $withCustomSupportOffset: boolean;
  $authenticated: boolean;
  $withNewDesign: boolean;
  $isSidebarOpen: boolean;
  $showBanner: boolean;
}>`
  z-index: 105;
  position: fixed;
  right: ${(props) =>
    // eslint-disable-next-line unicorn/no-nested-ternary
    props.$withCustomSupportOffset
      ? 16
      : props.$withNewDesign
        ? 149
        : // eslint-disable-next-line unicorn/no-nested-ternary
          props.$authenticated
          ? 153
          : 141}px;
  top: ${(props) =>
    props.$withNewDesign && !props.$isSidebarOpen && props.$showBanner
      ? '76px'
      : '16px'};
  transform: translateX(-50%);
  transition: top 0.5s ease;

  @media (min-width: 393px) {
    right: ${(props) =>
      // eslint-disable-next-line unicorn/no-nested-ternary
      props.$withCustomSupportOffset
        ? 16
        : props.$withNewDesign
          ? 149
          : // eslint-disable-next-line unicorn/no-nested-ternary
            props.$authenticated
            ? 170
            : 141}px;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

let MobileNavBarWrapper = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media (min-width: 960px) {
    display: none;
  }
`;

type HeaderWrapperProps = {
  $withBanner: boolean;
  $withNewDesign: boolean;
};

let HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: fixed;
  top: ${(props) =>
    props.$withBanner && props.$withNewDesign ? HEADER_HEIGHT : '0'};
  left: 0;
  z-index: 100;
  width: 100%;
`;

let BurgerButton = styled.button<{
  $withNewDesign: boolean;
  $showBanner: boolean;
}>`
  width: 24px;
  height: 24px;
  background-color: transparent;
  outline: transparent;
  cursor: pointer;
  border: none;
  z-index: 102;
  position: fixed;
  right: ${(props) => (props.$withNewDesign ? '16px' : '0px')};
  top: ${(props) =>
    props.$withNewDesign && props.$showBanner ? '84px' : '24px'};
  transform: translateX(-50%);
  transition: top 0.5s ease;

  @media (min-width: 393px) {
    right: 16px;
  }

  @media (min-width: 700px) {
    right: 40px;
  }

  @media (min-width: 960px) {
    display: none;
  }
`;

let SidebarWrapper = styled.div<{ $show: boolean }>`
  background-color: var(--backgroundSecondary);
  width: 262px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
  transition:
    opacity 0.5s ease,
    visibility 0.5s ease;

  @media (min-width: 393px) {
    width: 300px;
  }

  @media (min-width: 700px) {
    width: 331px;
  }

  @media (min-width: 960px) {
    display: none;
  }
`;

const SidebarOverlay = styled.div<{ $show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.$show ? '1' : '0')};
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
  z-index: 100;
  transition:
    opacity 0.3s,
    visibility 0.3s;

  @media (min-width: 960px) {
    display: none;
  }
`;
