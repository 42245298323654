import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import { StatusWrapper } from '../ESimCardsContent';

//TODO: maybe move to separate file for all cards (bundle/subscription)
export default function StatusBadge({
  eSimStatus,
}: {
  eSimStatus: EsimStatus;
}) {
  switch (eSimStatus) {
    case EsimStatus.ACTIVATED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusActivated)"
          data-testid={`esim-status-${EsimStatus.ACTIVATED}`}
        >
          Activated
        </StatusWrapper>
      );
    }
    case EsimStatus.NOT_INSTALLED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusNotActivated)"
          data-testid={`esim-status-${EsimStatus.NOT_INSTALLED}`}
        >
          Not installed
        </StatusWrapper>
      );
    }
    case EsimStatus.PREPARING: {
      return (
        <StatusWrapper
          $bgColor="var(--statusPreparing)"
          data-testid={`esim-status-${EsimStatus.PREPARING}`}
        >
          Preparing
        </StatusWrapper>
      );
    }
    case EsimStatus.INSTALLED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusInstalled)"
          data-testid={`esim-status-${EsimStatus.INSTALLED}`}
        >
          Installed
        </StatusWrapper>
      );
    }
    case EsimStatus.EXPIRED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.EXPIRED}`}
        >
          Expired
        </StatusWrapper>
      );
    }
    case EsimStatus.DATA_FINISHED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.DATA_FINISHED}`}
        >
          Data finished
        </StatusWrapper>
      );
    }
    case EsimStatus.REFUNDED: {
      return (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.REFUNDED}`}
        >
          Refunded
        </StatusWrapper>
      );
    }
    case EsimStatus.UNKNOWN: {
      return null;
    }
    default: {
      return null;
    }
  }
}
