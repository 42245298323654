export default function AlertIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14416_131711)">
        <path
          d="M12 24.5C9.62663 24.5 7.30655 23.7962 5.33316 22.4776C3.35977 21.1591 1.8217 19.2849 0.913451 17.0922C0.00519943 14.8995 -0.232441 12.4867 0.230582 10.1589C0.693605 7.83115 1.83649 5.69295 3.51472 4.01472C5.19295 2.33649 7.33115 1.1936 9.65892 0.730582C11.9867 0.267559 14.3995 0.505199 16.5922 1.41345C18.7849 2.3217 20.6591 3.85977 21.9776 5.83316C23.2962 7.80655 24 10.1266 24 12.5C23.9966 15.6815 22.7312 18.7318 20.4815 20.9815C18.2318 23.2312 15.1815 24.4966 12 24.5ZM12 2.50001C10.0222 2.50001 8.08879 3.0865 6.4443 4.18531C4.79981 5.28412 3.51809 6.84591 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C21.9971 9.84873 20.9426 7.30689 19.0679 5.43215C17.1931 3.55742 14.6513 2.50292 12 2.50001Z"
          fill="#EA4335"
        />
        <path d="M13 5.5H11V15.5H13V5.5Z" fill="#EA4335" />
        <path d="M13 17.5H11V19.5H13V17.5Z" fill="#EA4335" />
      </g>
      <defs>
        <clipPath id="clip0_14416_131711">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
