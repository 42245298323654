import styled from 'styled-components';
import shieldImage from './Images/Shield.png';
import { WEB_ROUTE_REFUND_POLICY } from '@shared_frontend/Common/WebRoutes';

export default function MoneyBackGuarantee() {
  return (
    <MoneyBackGuaranteeWrapper>
      <TitleBlock>
        <StyledImg src={shieldImage} alt="shield" />
        <Title>Money-back guarantee</Title>
      </TitleBlock>
      <Text>
        Buy with confidence — our hassle-free money-back promise ensures your
        satisfaction. Learn more in our{' '}
        <a href={WEB_ROUTE_REFUND_POLICY}>policy</a>.
      </Text>
    </MoneyBackGuaranteeWrapper>
  );
}

let MoneyBackGuaranteeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 4px;
  background-color: var(--cardsBackgroundGreenLight);
  border-radius: 16px;
  align-items: center;

  @media (min-width: 960px) {
    gap: 8px;
  }
`;

let TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  @media (min-width: 960px) {
    gap: 8px;
    flex-direction: row;
  }
`;

let Title = styled.h3`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
`;

let StyledImg = styled.img`
  width: 40px;
  height: 40px;

  @media (min-width: 960px) {
    width: 32px;
    height: 32px;
  }
`;

let Text = styled.p`
  line-height: 22px;
  text-align: center;
`;
