import { useEffect, useState } from 'react';
import SessionResponse from '@shared_backend/Module/Session/SessionResponse';

let sessionData: Promise<SessionResponse>;

export function useSessionData() {
  let [data, setData] = useState<SessionResponse | null>(null);

  useEffect(() => {
    (async () => {
      setData(await sessionData);
    })();
  }, []);

  return data;
}

export function setSessionDataPromise(promise: Promise<SessionResponse>) {
  sessionData = promise;
}
