import Button from '@front_common/Components/Button/Button';
import DomainSuggestion from '../../DomainSuggestion/NewDesign/DomainSuggestion';
import EmailConsent from '../../EmailConsent/NewDesign/EmailConsent';
import styled from 'styled-components';

type AuthFormEmailProps = {
  isLoading: boolean;
  errorTextEmail: string;
  onSubmit: (event: Event) => void;
  lastEmail: string;
  formId?: string;
  isChecked: boolean;
  onCheckBoxClick: (event: boolean) => void;
};

export default function AuthFormEmail(props: AuthFormEmailProps) {
  let {
    isLoading,
    errorTextEmail,
    onSubmit,
    lastEmail,
    formId = 'auth',
    onCheckBoxClick,
    isChecked,
  } = props;

  return (
    <AuthFormEmailStyled id={formId} noValidate onSubmit={onSubmit}>
      <TextInputWrapper>
        <DomainSuggestion
          name="email"
          type="email"
          placeholder="youremail@mail.com"
          label="Enter your email"
          errorText={errorTextEmail}
          value={lastEmail}
        />
      </TextInputWrapper>
      <EmailConsent
        form="auth"
        name="subscribe"
        onClick={() => onCheckBoxClick(!isChecked)}
      />
      <CreateAccountButton
        disabled={isLoading}
        form="auth"
        type="submit"
        sizeParam="M"
        isLoading={isLoading}
      >
        Continue
      </CreateAccountButton>
    </AuthFormEmailStyled>
  );
}

let AuthFormEmailStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 0;
`;

let TextInputWrapper = styled.div`
  z-index: 50;
  margin-bottom: 8px;
`;

let CreateAccountButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
  background-color: var(--backgroundPrimaryDarkNew);

  &:hover {
    background-color: var(--black100) !important;
  }
`;
