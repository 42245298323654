/* eslint-disable max-lines */
import styled from 'styled-components';
import Screen1Image from '../Images/Screen1.png';
import Screen2Image from '../Images/Screen2.png';
import Screen3Image from '../Images/Screen3.png';
import Screen4Image from '../Images/Screen4.png';
import Carousel from '@front_common/Components/Carousel';
import { EmblaOptionsType } from 'embla-carousel';
import iPhoneFrameImage from '@front_common/Components/Images/Iphone_frame.png';
import { HTMLAttributes } from 'preact/compat';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import './HowEsimWorksCarousel.css';

export type Step = {
  step: number;
  title: string;
  text: string;
  image: string;
  topBackground: string;
  bottomBackground: string;
};

let stepsData: Step[] = [
  {
    step: 1,
    title: 'Verify for eSIM compatibility',
    text: 'Make sure your device supports eSIM before purchase',
    image: Screen1Image,
    topBackground: 'var(--orange70)',
    bottomBackground: 'var(--orange90)',
  },
  {
    step: 2,
    title: 'Select your country',
    text: 'Choose where you need service',
    image: Screen2Image,
    topBackground: 'var(--lightBlue80)',
    bottomBackground: 'var(--cardsBackgroundLightBlueSecondary)',
  },
  {
    step: 3,
    title: 'Pick your data plan',
    text: 'Select how much data you will need',
    image: Screen3Image,
    topBackground: 'var(--green80)',
    bottomBackground: 'var(--green90)',
  },
  {
    step: 4,
    title: 'Install your eSIM and enjoy',
    text: 'Follow simple steps provided to you after purchase to activate the eSIM on your device',
    image: Screen4Image,
    topBackground: 'var(--purple90)',
    bottomBackground: 'var(--purple100)',
  },
];

let OPTIONS: EmblaOptionsType = {
  dragFree: true,
};

export default function HowEsimWorks() {
  return (
    <HowEsimWorksWrapper>
      <Carousel
        slides={stepsData}
        options={OPTIONS}
        baseClassName="how-esim-works"
        getSlideComponent={(data: Step) => <Slide slideData={data} />}
        prevBtnChildren={
          <ButtonLeftWrapper>
            <ChevronLeftIcon />
          </ButtonLeftWrapper>
        }
        nextBtnChildren={
          <ButtonRightWrapper>
            <ChevronLeftIcon />
          </ButtonRightWrapper>
        }
      />
    </HowEsimWorksWrapper>
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Step;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <>
      <TopBackground $background={slideData.topBackground}>
        <PhoneImageWrapper>
          <PhoneFrame
            loading="lazy"
            src={iPhoneFrameImage}
            alt="iphone frame"
          />
          <ScreensImage loading="lazy" src={slideData.image} alt="screen" />
        </PhoneImageWrapper>
        <Figure $background={slideData.bottomBackground} />
      </TopBackground>
      <BottomBackground $background={slideData.bottomBackground}>
        <StepsNumber>{slideData.step}</StepsNumber>
        <StepsTitle>{slideData.title}</StepsTitle>
        <StepsText>{slideData.text}</StepsText>
      </BottomBackground>
    </>
  );
}

let HowEsimWorksWrapper = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: 960px) {
    min-height: 476px;
  }
`;

let TopBackground = styled.div<{ $background: string }>`
  background-color: ${(props) => props.$background};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 12px 0;
  position: relative;
`;

let BottomBackground = styled.div<{ $background: string }>`
  background-color: ${(props) => props.$background};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 148px;
  padding: 12px;

  @media (min-width: 393px) {
    height: 152px;
  }

  @media (min-width: 960px) {
    padding: 16px;
    height: 168px;
  }
`;

let PhoneImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 83px;
  height: 170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 393px) {
    width: 98px;
    height: 200px;
  }

  @media (min-width: 700px) {
    width: 118px;
    height: 240px;
  }
`;

let Figure = styled.div<{ $background: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 116px;
  height: 116px;
  z-index: 2;
  background-color: ${(props) => props.$background};
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  border-radius: 28px;

  @media (min-width: 393px) {
    width: 138px;
    height: 138px;
  }

  @media (min-width: 700px) {
    width: 164px;
    height: 164px;
  }
`;

let PhoneFrame = styled.img`
  width: 100%;
  height: 100%;
  z-index: 4;
  position: absolute;
`;

let ScreensImage = styled.img`
  z-index: 3;
  width: 76px;
  border-radius: 11px;
  position: absolute;

  @media (min-width: 393px) {
    width: 89px;
  }

  @media (min-width: 700px) {
    width: 107px;
    border-radius: 16px;
  }
`;

let StepsNumber = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--itemBrand);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeRegular);
  color: var(--textPrimaryInvert);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 12px;
  line-height: 22px;

  @media (min-width: 393px) {
    width: 32px;
    height: 32px;
    font-size: var(--fontSizeBig);
    line-height: 24px;
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 28px;
  }
`;

let StepsTitle = styled.span`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: center;
  margin-bottom: 4px;
  display: block;

  @media (min-width: 393px) {
    line-height: 24px;
    font-size: var(--fontSizeBig);
  }

  @media (min-width: 960px) {
    line-height: 28px;
    font-size: 20px;
  }
`;

let StepsText = styled.span`
  display: block;
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  text-align: center;

  @media (min-width: 393px) {
    font-size: var(--fontSizeRegular);
  }
`;

let ButtonLeftWrapper = styled.div`
  position: relative;
  height: 100%;
`;

let ButtonRightWrapper = styled.div`
  right: 0;
  height: 100%;
  transform: rotate(180deg);
`;
