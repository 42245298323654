import styled from 'styled-components';
import ArrowRightIcon from '../ESimsPage/Icons/ArrowRightIcon';
import instructionsInstallationImage from './Images/InslallInstructionsImage.png';
import planDetailsImage from './Images/PlanDetailsImage.png';
import troubleshootingImage from './Images/TroubleShoutingImage.png';
import { routeRedirect } from '@front_common/Router/Router';
import {
  WEB_ROUTE_FAQ,
  WEB_ROUTE_MY_INSTALL_GUIDE,
} from '@shared_frontend/Common/WebRoutes';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';

// eslint-disable-next-line no-shadow
export enum ESimActions {
  INSTRUCTIONS_INSTALLATION = 'instructionsInstallation',
  PLAN_DETAILS = 'planDetails',
  TROUBLESHOOTING = 'troubleshooting',
}

function processInstallGuide(eSimId: number) {
  routeRedirect(fillRouteParams(WEB_ROUTE_MY_INSTALL_GUIDE, { id: eSimId }));
}

function processTroubleshooting() {
  routeRedirect(`${WEB_ROUTE_FAQ}/troubleshooting`);
}

type IProps = {
  eSimId: number;
  visibleActions: ESimActions[];
  onPlanDetailsClick: () => void;
};

export default function ESimPageActions({
  eSimId,
  visibleActions,
  onPlanDetailsClick,
}: IProps) {
  return (
    <ESimsActionsWrapper>
      {visibleActions.includes(ESimActions.INSTRUCTIONS_INSTALLATION) && (
        <ESimsAction onClick={() => processInstallGuide(eSimId)}>
          <RowBox>
            <img
              src={instructionsInstallationImage}
              alt="instruction-installation"
            />
            <span>Installation instructions</span>
          </RowBox>
          <ImageBox>
            <ArrowRightIcon />
          </ImageBox>
        </ESimsAction>
      )}
      {visibleActions.includes(ESimActions.PLAN_DETAILS) && (
        <ESimsAction
          $withBorder={visibleActions.length > 1}
          onClick={onPlanDetailsClick}
        >
          <RowBox>
            <img src={planDetailsImage} alt="plan-details" />
            <span>Plan details</span>
          </RowBox>
          <ImageBox>
            <ArrowRightIcon />
          </ImageBox>
        </ESimsAction>
      )}
      {visibleActions.includes(ESimActions.TROUBLESHOOTING) && (
        <ESimsAction $withBorder onClick={processTroubleshooting}>
          <RowBox>
            <img src={troubleshootingImage} alt="troubleshooting" />
            <span>Troubleshooting</span>
          </RowBox>
          <ImageBox>
            <ArrowRightIcon />
          </ImageBox>
        </ESimsAction>
      )}
    </ESimsActionsWrapper>
  );
}

let ESimsActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  border-radius: var(--borderRadiusLarge);
  background-color: var(--backgroundSecondary);
`;

type ESimsActionProps = {
  $withBorder?: boolean;
};

let ESimsAction = styled.div<ESimsActionProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer;
  border-top: ${({ $withBorder }) =>
    $withBorder ? '1px solid var(--strokeSecondary)' : 'none'};
`;

let RowBox = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 22px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontFamilyHeader);

  img {
    width: 32px;
    height: auto;
    margin-right: 5px;
  }
`;

let ImageBox = styled.span`
  svg {
    height: 12px;
    width: 12px;

    path {
      stroke: var(--iconsPrimaryInvert);
    }
  }
`;
