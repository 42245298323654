import { useEffect, useRef, useState } from 'preact/hooks';
import Button from '@front_common/Components/Button/Button';
import OTPInput from '../../OTPInput/NewDesign/OTPInput';
import TimerButton from '../../TimerButton/NewDesign/TimerButton';
import styled from 'styled-components';

type AuthFormOTPProps = {
  email: string;
  isLoading: boolean;
  errorTextOTP: string;
  onSubmit: (event: SubmitEvent) => void;
  onResendOTP: () => void;
  onChangeEmail: () => void;
};

export default function AuthFormOTP(props: AuthFormOTPProps) {
  let { email, isLoading, onSubmit, onResendOTP, errorTextOTP, onChangeEmail } =
    props;

  let [otp, setOtp] = useState('');
  let [randomKey, setRandomKey] = useState(0);

  let inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  function handleValueChange(value: string) {
    setOtp(value);
  }

  function isOTPValid() {
    return otp && otp.length === 6;
  }

  function handleTimerButtonClick() {
    setRandomKey(Date.now());
    onResendOTP();
  }

  return (
    <AuthFormOTPWrapperStyled>
      <NotificationMessage>
        We have sent the code to your email
      </NotificationMessage>
      <EmailChange>
        <EmailLink>{email}</EmailLink>
        <ChangeEmailButton
          disabled={isLoading}
          typeStyle="Secondary"
          sizeParam="S"
          onClick={onChangeEmail}
        >
          Change email
        </ChangeEmailButton>
      </EmailChange>
      <FormStyled noValidate onSubmit={onSubmit}>
        <OTPInput
          label="Enter verification code"
          name="otp"
          errorText={errorTextOTP}
          disabled={isLoading}
          ref={inputRef}
          onValueChange={handleValueChange}
        />
        <ContinueButton
          type="submit"
          sizeParam="M"
          isLoading={isLoading}
          disabled={isLoading || !isOTPValid()}
        >
          Continue
        </ContinueButton>
        <TimerButton
          key={randomKey}
          timeSeconds={60}
          activeName="Resend"
          timerName="Resend code in "
          onClick={handleTimerButtonClick}
          disabled={isLoading}
        />
      </FormStyled>
    </AuthFormOTPWrapperStyled>
  );
}

let AuthFormOTPWrapperStyled = styled.div`
  order: 0;
  color: var(--textPrimaryNew);
  font-family: var(--fontFamilyNew);
`;

let EmailChange = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

let EmailLink = styled.a`
  color: inherit;
  text-decoration: none;
  pointer-events: none;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;

  @media (min-width: 700px) {
    max-width: 100%;
  }
`;

let NotificationMessage = styled.span`
  display: inline-flex;
  margin-bottom: 12px;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;

let ChangeEmailButton = styled(Button)`
  border: none;
  height: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
  padding: 0;
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeRegular);
  color: var(--textPrimaryNew);

  &:hover {
    background-color: transparent;
    /*TODO: fix important after design variant confirmation*/
    border: none !important;
    color: var(--black100) !important;
  }
`;

let FormStyled = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

let ContinueButton = styled(Button)`
  background-color: var(--backgroundPrimaryDarkNew);

  &:hover {
    background-color: var(--black100) !important;
  }
`;
