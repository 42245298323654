import styled from 'styled-components';
import purchaseDateImage from './Images/PurchaseDateImage.png';
import validForImage from './Images/ValidForImage.png';
import planTypeImage from './Images/PlanTypeImage.png';
import speedImage from './Images/SpeedImage.png';
import activationImage from './Images/ActivationImage.png';
import cancellationPolicyImage from './Images/CancellationPolicyImage.png';
import { MODAL_HEIGHT } from '@front_common/Components/Modal/OldDesign/Modal';

type DetailItemProps = {
  label: string;
  value: string;
  icon: string;
};

function DetailItem({ label, value, icon }: DetailItemProps) {
  return (
    <DetailItemWrapper>
      <img src={icon} alt="purchase-date" width={24} height={24} />
      <ColumnBox>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </ColumnBox>
    </DetailItemWrapper>
  );
}

type IProps = {
  durationDays: number | null;
  purchaseDate: string;
};

export default function ESimPlanDetails({
  durationDays,
  purchaseDate,
}: IProps) {
  return (
    <PlanDetailsModal>
      <PlanDetailsContentWrapper>
        <ModalHeader>
          <Title>Plan details</Title>
        </ModalHeader>
        <ModalContent>
          {purchaseDate && (
            <DetailItem
              label="Purchase date:"
              value={purchaseDate}
              icon={purchaseDateImage}
            />
          )}
          <DetailItem
            label="Valid for:"
            value={`${durationDays} days from activation`}
            icon={validForImage}
          />
          <DetailItem
            label="Plan type:"
            value="Data only. eSIM does not come with a phone number"
            icon={planTypeImage}
          />
          <DetailItem
            label="Speed:"
            value="The speed may vary depending on your location"
            icon={speedImage}
          />
          <DetailItem
            label="Activation:"
            value="Activate anytime within 180 days of purchase"
            icon={activationImage}
          />
          <DetailItem
            label="Cancelation Policy:"
            value="Cancel any time before activation with no data usage for a full refund"
            icon={cancellationPolicyImage}
          />
        </ModalContent>
      </PlanDetailsContentWrapper>
    </PlanDetailsModal>
  );
}

let PlanDetailsModal = styled.div`
  width: 100%;
  max-height: 100%;
  overscroll-behavior-y: contain;
`;

let PlanDetailsContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: scroll;
`;

let ModalHeader = styled.div`
  padding: 32px 20px 20px 20px;
  background-color: var(--orange90);
`;

let Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let ModalContent = styled.div`
  display: flex;
  max-height: calc(${MODAL_HEIGHT} - 84px);
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
`;

let DetailItemWrapper = styled.div`
  display: flex;

  img {
    margin-right: 7px;
    height: 24px;
    width: 24px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--strokeSecondary);
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let Label = styled.span`
  line-height: 22px;
  text-align: left;
  margin-bottom: 4px;
  font-weight: var(--fontWeightBold);
`;

let Value = styled.span`
  line-height: 22px;
  text-align: left;
  font-weight: var(--fontWeightMedium);
`;
