import styled from 'styled-components';
import { ReactElement } from 'react';

type IProps = {
  size?: number;
  progress?: number | undefined;
  primaryColor?: string | undefined;
  secondaryColor?: string | undefined;
  children: ReactElement;
};

export default function ESimDataLoader({
  size = 201,
  progress = 0,
  primaryColor = 'var(--progressBarPrimary)',
  secondaryColor = 'var(--strokeSecondary)',
  children,
}: IProps) {
  let radius = size / 2;
  let strokeWidth = 12;
  let circumference = Math.PI * (radius - strokeWidth);
  let offset = circumference - (progress / 100) * circumference;

  return (
    <DataLoaderWrapper>
      <svg width={size} height={size / 2} viewBox={`0 0 ${size} ${size / 2}`}>
        <svg width={size} height={size / 2} viewBox={`0 0 ${size} ${size / 2}`}>
          <path
            d={`M ${strokeWidth} ${radius} A ${radius - strokeWidth} ${radius - strokeWidth} 0 0 1 ${size - strokeWidth} ${radius}`}
            fill="none"
            stroke={secondaryColor}
            strokeWidth={strokeWidth}
          />
          <path
            d={`M ${strokeWidth} ${radius} A ${radius - strokeWidth} ${radius - strokeWidth} 0 0 1 ${size - strokeWidth} ${radius}`}
            fill="none"
            stroke={primaryColor}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
        </svg>
      </svg>
      <>{children}</>
    </DataLoaderWrapper>
  );
}

let DataLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
