import styled from 'styled-components';
import { GuideCard, GuideStep } from '../GuideCard';
import { CurrentPlan } from '../CurrentPlan';
import Card from '@front_common/Components/Card';
import Warning from '../Warning';
import { QRCodeImage } from '@front_common/Components/QRCodeBox';

import AndroidQRInstallS1S1 from './Images/Qr/AndroidQRInstallS1S1.png';

import AndroidQRInstallS2S1 from './Images/Qr/AndroidQRInstallS2S1.png';

import AndroidQRInstallS3S1 from './Images/Qr/AndroidQRInstallS3S1.png';

import AndroidQRActivationS1S1 from './Images/Qr/AndroidQRActivationS1S1.png';
import AndroidQRActivationS1S2 from './Images/Qr/AndroidQRActivationS1S2.png';

import AndroidQRActivationS2S1 from './Images/Qr/AndroidQRActivationS2S1.png';
import AndroidQRActivationS2S2 from './Images/Qr/AndroidQRActivationS2S2.png';

import AndroidQRActivationS3S1 from './Images/Qr/AndroidQRActivationS3S1.png';
import AndroidQRActivationS3S2 from './Images/Qr/AndroidQRActivationS3S2.png';

const ACTIVATION_STEPS: GuideStep[] = [
  {
    stepNumber: 1,
    title: 'Turn on your eSIM',
    mainText: (
      <ul>
        <li>{`Find your installed eSIM in "Mobile Network" list.`}</li>
        <li>{`Click on your eSIM and switch on the toggle to start using eSIM.`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS1S1, AndroidQRActivationS1S2],
  },
  {
    stepNumber: 2,
    title: 'Make eSIM a preferred data plan',
    mainText: (
      <ul>
        <li>{`Go back into "Network&Internet" or "Connections" (depending on your device) tab in your Settings.`}</li>
        <li>{`Then go to "SIM preferences".`}</li>
        <li>{`Switch on the toggle for your installed eSIM as the preferred source for data.`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS2S1, AndroidQRActivationS2S2],
  },
  {
    stepNumber: 3,
    title: 'Enable Data Roaming',
    mainText: (
      <ul>
        <li>{`Go back to the "Network & Internet" or "Connections" (depending on your device) tab in your settings.`}</li>
        <li>{`Tap on "SIM Card Manager" and select your newly added eSIM plan.`}</li>
        <li>{`Turn on "Data Roaming".`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS3S1, AndroidQRActivationS3S2],
  },
];

type AndroidQRTabContentProps = {
  esimId: string | undefined;
  forGeneralPage: boolean;
};

export function AndroidQRTabContent(props: AndroidQRTabContentProps) {
  let { esimId, forGeneralPage } = props;

  let INSTALL_STEPS: GuideStep[] = [
    {
      stepNumber: 1,
      title: 'Scan QR code',
      mainText: (
        <ul>
          <li>
            Open your camera and scan the QR code to start the installation.
          </li>
          {forGeneralPage ? (
            <li>
              QR code can be found in the email or the installation guide, which
              is provided after your purchase.
            </li>
          ) : (
            <li>QR code can be found above or in your e-mail.</li>
          )}
        </ul>
      ),
      images: [AndroidQRInstallS1S1],
    },
    {
      stepNumber: 2,
      title: 'Install your eSIM',
      mainText: `Follow on-screen instructions to install your eSIM.`,
      images: [AndroidQRInstallS2S1],
    },
    {
      stepNumber: 3,
      title: 'Confirm successful installation',
      mainText: `If eSIM is installed successfully, you will see it in your "SIMs" list in "Mobile Network".`,
      images: [AndroidQRInstallS3S1],
    },
  ];

  return (
    <div>
      <PaddingWrapper>
        <Warning text="You need to be connected to the internet to install the eSIM." />
        <MainNote>
          <b>NOTE:</b> Screenshots may differ slightly from what you see on your
          device due to variations between device models.
        </MainNote>
        {esimId && (
          <CardStyled>
            <CurrentPlan title="Your QR-code for" esimId={esimId} />
            <QRWrapper>
              <QRCodeImage id={esimId} />
            </QRWrapper>
            <Note>
              *Take a picture with another phone to scan the code or open the
              email we sent with the QR code inside.
            </Note>
          </CardStyled>
        )}
      </PaddingWrapper>
      {INSTALL_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={INSTALL_STEPS.length === index + 1}
        />
      ))}
      <PaddingWrapper>
        <ActivationTitle $forGeneralPage={forGeneralPage}>
          Activation
        </ActivationTitle>
        <Warning text="The following steps should be done once you arrive at your destination, to ensure your eSIM's validity starts correctly." />
      </PaddingWrapper>
      {ACTIVATION_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={ACTIVATION_STEPS.length === index + 1}
        />
      ))}
    </div>
  );
}

let MainNote = styled(Card)`
  padding: 12px;
  font-size: var(--fontSizeSmall);
  line-height: 20px;

  b {
    font-weight: var(--fontWeightBold);
  }
`;

let PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin-left: auto;
    margin-right: auto;
  }
`;

let CardStyled = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

let Note = styled.span`
  font-size: 12px;
  line-height: 24px;
`;

let QRWrapper = styled.div`
  height: 120px;
  width: 120px;
`;

let ActivationTitle = styled.h3<{ $forGeneralPage: boolean }>`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: ${(props) => (props.$forGeneralPage ? 'center' : 'left')};
  margin-top: 32px;
  font-size: ${(props) => (props.$forGeneralPage ? '24px' : '34px')};
  line-height: ${(props) => (props.$forGeneralPage ? '32px' : '40px')};

  @media (min-width: 700px) {
    text-align: center;
    margin-top: 40px;
    font-size: ${(props) => (props.$forGeneralPage ? '28px' : '34px')};
    line-height: ${(props) => (props.$forGeneralPage ? '38px' : '40px')};
  }

  @media (min-width: 960px) {
    margin-top: 56px;
    font-size: 34px;
    line-height: 40px;
  }
`;
