import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';
import ConfettiImage from '@front_common/Components/Images/ConfettiImage.png';
import PetardImage from './Images/Petard.png';
import { useEffect, useState } from 'preact/hooks';
import { routeRedirect } from '@front_common/Router/Router';
import { WEB_ROUTE_MY_ESIMS } from '@shared_frontend/Common/WebRoutes';
import {
  setInstallLaterClicked,
  setInstallNowClicked,
  setReminderShown,
} from './ReminderModal';
import {
  getAppleUniversalLink,
  useGetMyEsimsQuery,
} from '@front_common/Api/UserApi';
import { useSessionData } from '@front_common/Api/Session';
import {
  SHOW_ESIM_QR,
  SHOW_HINT,
} from '@shared_frontend/Module/InstallFlow/Constants';

type EsimReadyContentProps = {
  esimId: number;
};

export default function EsimReadyContent(props: EsimReadyContentProps) {
  let { esimId } = props;

  let [startAnimation, setStartAnimation] = useState(false);
  let [appleUniversalLink, setAppleUniversalLink] = useState(false);

  let sessionData = useSessionData();
  let [response] = useGetMyEsimsQuery();

  let esim = response?.esims.find((item) => item.id === Number(esimId));

  useEffect(() => {
    setStartAnimation(true);
  }, []);

  useEffect(() => {
    if (
      sessionData &&
      sessionData.os.name === 'iOS' &&
      sessionData.os.version &&
      Number.parseFloat(sessionData.os.version) >= 17.4
    ) {
      setAppleUniversalLink(true);
    }
  }, [sessionData]);

  function handleInstall(isAppleUniversalLink?: boolean) {
    setInstallNowClicked(String(esimId));

    if (isAppleUniversalLink) {
      routeRedirect(WEB_ROUTE_MY_ESIMS);
    } else {
      routeRedirect(WEB_ROUTE_MY_ESIMS, {
        [SHOW_ESIM_QR]: String(esimId),
      });
    }
  }

  function handleInstallLater() {
    setReminderShown();
    setInstallLaterClicked(String(esimId));
    routeRedirect(WEB_ROUTE_MY_ESIMS, {
      [SHOW_HINT]: esimId,
    });
  }

  return (
    <div>
      <Top>
        <Animation>
          <Confetti src={ConfettiImage} $startAnimation={startAnimation} />
          <Petard src={PetardImage} />
          <BlurLine />
        </Animation>
        <Text>
          <Title>Your eSIM is ready!</Title>
        </Text>
      </Top>
      <Bottom>
        {appleUniversalLink && esim ? (
          <AnchorStyled
            href={getAppleUniversalLink(esim.smdpAddress, esim.activationCode)}
            title="iOS 17.4 and later eSIM install link"
          >
            <ButtonStyled sizeParam="L" onClick={() => handleInstall(true)}>
              Install now
            </ButtonStyled>
          </AnchorStyled>
        ) : (
          <ButtonStyled sizeParam="L" onClick={() => handleInstall()}>
            Install now
          </ButtonStyled>
        )}
        <ButtonStyled
          sizeParam="L"
          typeStyle="Secondary"
          onClick={handleInstallLater}
        >
          Install later
        </ButtonStyled>
      </Bottom>
    </div>
  );
}

let Top = styled.div`
  background-color: var(--orange90);
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

let Animation = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`;

let Confetti = styled.img<{ $startAnimation: boolean }>`
  width: 406px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${(props) => (props.$startAnimation ? '-543px' : '0px')};
  z-index: 1;
  transition: bottom 10s ease-out;

  @media (min-width: 700px) {
    width: 530px;
  }
`;

let Petard = styled.img`
  z-index: 2;
  width: 120px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

let BlurLine = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: -1px;
  height: 20px;

  mask: linear-gradient(transparent, grey, black);
  backdrop-filter: blur(2px);
`;

let Text = styled.div`
  padding: 16px 20px 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Bottom = styled.div`
  padding: 20px;
`;

let ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: initial;
  }
`;

let AnchorStyled = styled.a`
  display: block;
  margin-bottom: 16px;
  text-decoration: none;
`;
