export default function CloseIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0579 9.25586L14.7829 4.53836C14.9242 4.39713 15.0035 4.20559 15.0035 4.00586C15.0035 3.80613 14.9242 3.61459 14.7829 3.47336C14.6417 3.33213 14.4502 3.25279 14.2504 3.25279C14.0507 3.25279 13.8592 3.33213 13.7179 3.47336L9.00044 8.19836L4.28294 3.47336C4.14171 3.33213 3.95017 3.25279 3.75044 3.25279C3.55072 3.25279 3.35917 3.33213 3.21794 3.47336C3.07671 3.61459 2.99737 3.80613 2.99737 4.00586C2.99737 4.20559 3.07671 4.39713 3.21794 4.53836L7.94294 9.25586L3.21794 13.9734C3.14765 14.0431 3.09185 14.126 3.05377 14.2174C3.0157 14.3088 2.99609 14.4069 2.99609 14.5059C2.99609 14.6049 3.0157 14.7029 3.05377 14.7943C3.09185 14.8857 3.14765 14.9686 3.21794 15.0384C3.28766 15.1087 3.37062 15.1645 3.46201 15.2025C3.5534 15.2406 3.65143 15.2602 3.75044 15.2602C3.84945 15.2602 3.94748 15.2406 4.03888 15.2025C4.13027 15.1645 4.21322 15.1087 4.28294 15.0384L9.00044 10.3134L13.7179 15.0384C13.7877 15.1087 13.8706 15.1645 13.962 15.2025C14.0534 15.2406 14.1514 15.2602 14.2504 15.2602C14.3495 15.2602 14.4475 15.2406 14.5389 15.2025C14.6303 15.1645 14.7132 15.1087 14.7829 15.0384C14.8532 14.9686 14.909 14.8857 14.9471 14.7943C14.9852 14.7029 15.0048 14.6049 15.0048 14.5059C15.0048 14.4069 14.9852 14.3088 14.9471 14.2174C14.909 14.126 14.8532 14.0431 14.7829 13.9734L10.0579 9.25586Z"
        fill="black"
      />
    </svg>
  );
}
