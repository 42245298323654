import styled from 'styled-components';
import flags from './Flags/Index';
import { useEffect, useRef, useState } from 'preact/hooks';

interface FlagProps extends React.HTMLAttributes<HTMLImageElement> {
  countryCode: string;
  circle?: boolean;
  withBorder?: boolean;
}

export default function Flag(props: FlagProps) {
  let { countryCode, circle = true, withBorder = false, ...rest } = props;
  let flagSrc = flags[countryCode.toLowerCase()];

  let [wrapperSize, setWrapperSize] = useState(0);
  let imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current && (imgRef.current.height || imgRef.current.width)) {
      setWrapperSize(imgRef.current.height || imgRef.current.width);
    }
  }, [imgRef.current?.height, imgRef.current?.width]);

  if (!flagSrc) {
    return null;
  }

  return (
    <CircleBorderWrapper
      $size={wrapperSize}
      $withBorder={withBorder}
      $circle={circle}
    >
      <img
        ref={imgRef}
        src={flagSrc}
        alt={`Flag of ${countryCode}`}
        {...rest}
      />
    </CircleBorderWrapper>
  );
}

let CircleBorderWrapper = styled.div<{
  $circle: boolean;
  $size: number;
  $withBorder: boolean;
}>`
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$circle ? props.$size + 'px' : 'auto')};
  height: ${(props) => (props.$circle ? props.$size + 'px' : 'auto')};
  border-radius: ${(props) => (props.$circle ? '50%' : '0')};
  border: ${(props) =>
    props.$withBorder ? '1px solid var(--strokeSecondary)' : 'none'};
`;
