import Feedbacks from '@front_common/Components/Feedbacks/OldDesign/Feedbacks';
import './StoreFeedbackCarousel.css';
import { useGetReviewsPerCountry } from '@front_common/Api/VisitorApi';
import { setOrganizationSchema } from '@front_common/Seo/SeoHeaders';
import { useEffect } from 'preact/hooks';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { StoreItem } from '@shared_backend/Module/Store/StoreResponse';

type IProps = {
  countryId: string;
  storeItems: Readonly<StoreItem[]>; //TODO: Is this correct - Readonly?
};

export default function StoreFeedbacks({ countryId, storeItems }: IProps) {
  let defaultData = [
    {
      stars: 5,
      name: 'margret',
      city: 'Sydney',
      flag: countryId,
      text: 'I travel for business a lot and internet with no SIM card is super useful for me. Bought everything super and easy and fast. Was able to install everything right away. Thank you!',
    },
    {
      stars: 5,
      name: 's2312378',
      city: 'Ankara',
      flag: countryId,
      text: 'got 5gb and it was perfect for my trip. very useful to arrive at airport and be able to call a taxi right away with no hassle with airport wi-fi',
    },
    {
      stars: 5,
      name: 'James',
      city: 'Barcelona',
      flag: countryId,
      text: 'Great service! Thank you for keeping me online during my Europe trip!',
    },
  ];

  let [res, _error, isLoading] = useGetReviewsPerCountry(countryId);

  let apiData =
    res && res.length > 0
      ? res.map((item) => ({
          stars: item.stars,
          name: item.name,
          flag: countryId,
          city: item.city,
          text: item.text,
        }))
      : null;

  let data = apiData || defaultData;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let reviews = data.map((item) => ({
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: item.stars,
      },
      author: {
        '@type': 'Person',
        name: item.name,
      },
    }));

    let ratingValue =
      data.reduce((acc, item) => acc + item.stars, 0) / data.length;
    let bestRating = data.reduce((acc, item) => Math.max(acc, item.stars), 0);
    let ratingCount = data.length;
    let lowestPlanPriceCents = storeItems?.reduce(
      (acc, item) => Math.min(acc, item.totalAmountCents),
      Number.MAX_VALUE,
    );

    setOrganizationSchema({
      '@context': 'https://schema.org/',
      '@type': 'Product',
      description: `eSIM Plans for ${countryNameByCode(countryId)} from $${lowestPlanPriceCents / 100}. These plans allow users to avoid roaming charges and stay connected with high-speed 4G/5G mobile internet while traveling internationally.`,
      name: `eSIM plans for ${countryNameByCode(countryId)}`,
      review: reviews,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue,
        bestRating,
        ratingCount,
      },
      //TODO: add offers with '@type': 'AggregateOffer'.
      // offers: {
      //   '@type': 'AggregateOffer',
      //   lowPrice: 119.99,
      //   highPrice: 199.99,
      //   priceCurrency: 'USD',
      // },
    });
  }, [isLoading]);

  return <Feedbacks feedbacksData={data} baseClassName="storeFeedbacks" />;
}
