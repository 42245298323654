export enum PaymentSessionTypeEnum {
  ONE_TIME = 'one-time',
  SUBSCRIPTION = 'subscription',
}

export type PaymentSessionEntityType = {
  id: number;
  type: PaymentSessionTypeEnum;
  userId: number;
  bundlePriceId: number | null;
  priceId?: number | null; // TODO: needs to be refactored
  currency: string;
  totalAmountCents: number;
  paid: boolean;
  failed: boolean;
  targetCountry: string | null;
  meta: { [key: string]: string | number };
  createdAt: Date;
};
