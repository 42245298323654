import { TikTokPixel } from './TikTokPixel';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import { GoogleAdsPixel } from './GoogleAdsPixel';
import {
  PaymentSessionEntityType,
  PaymentSessionTypeEnum,
} from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { METACustomData, METAPixelAPI } from './METAPixel';

export const AdEvents = {
  setUser,
  trackPayment,
  trackReg,
};

async function setUser(userId: number) {
  try {
    await TikTokPixel.identifyUser(userId);
  } catch (error) {
    logger.error(error);
  }
}

async function trackPayment(
  paymentSession: PaymentSessionEntityType,
  isRebill: boolean,
) {
  let totalAmountOfDollars = paymentSession.totalAmountCents / 100;
  let isCountryPlan = paymentSession.type === PaymentSessionTypeEnum.ONE_TIME;

  //TikTok
  try {
    if (!isRebill) {
      await TikTokPixel.trackPayment(
        totalAmountOfDollars,
        paymentSession.currency,
        paymentSession.id,
      );
    }
  } catch (error) {
    logger.error(error);
  }

  //Google
  try {
    GoogleAdsPixel.trackPayment(
      totalAmountOfDollars,
      paymentSession.id,
      isRebill,
      isCountryPlan,
    );
  } catch (error) {
    logger.error(error);
  }

  //META
  try {
    let customData: METACustomData;

    if (isCountryPlan && paymentSession.targetCountry) {
      if (isRebill) {
        customData = {
          currency: paymentSession.currency,
          value: totalAmountOfDollars,
          type: 'meta-rebill',
          targetCountry: paymentSession.targetCountry,
        };
      } else {
        customData = {
          currency: paymentSession.currency,
          value: totalAmountOfDollars,
          type: 'meta-first-purchase',
          targetCountry: paymentSession.targetCountry,
        };
      }
    } else {
      customData = {
        currency: paymentSession.currency,
        value: totalAmountOfDollars,
        type: 'subscription-first-purchase',
      };
    }

    await METAPixelAPI.sendEvent(
      'Purchase',
      customData,
      `paymentSessionId_${paymentSession.id}`,
    );
  } catch (error) {
    logger.error(error);
  }
}

async function trackReg(userId: number) {
  try {
    // eslint-disable-next-line no-unused-expressions
    userId;
    await TikTokPixel.trackReg();
  } catch (error) {
    logger.error(error);
  }

  //META
  try {
    await METAPixelAPI.sendEvent(
      'CompleteRegistration',
      {},
      `userId_${userId}`,
    );
  } catch (error) {
    logger.error(error);
  }
}
