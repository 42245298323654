import { useContext, useState } from 'preact/hooks';
import { UnsubscribeContext } from './UnsubscribeProvider';
import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';

export default function SecondStep() {
  let context = useContext(UnsubscribeContext);

  if (!context) {
    throw new Error('UnsubscribeContext must be used within a provider');
  }

  let {
    setStep,
    unsubscribeData: { cancellationDetailsMessage },
    updateUnsubscribeData,
  } = context;

  let [hasValidationError, setHasValidationError] = useState(false);

  //TODO: try to avoid the xss/no-mixed-html
  function handleMessageChange(event: Event) {
    // eslint-disable-next-line xss/no-mixed-html
    let target = event.target as HTMLTextAreaElement;

    if (target.value.length >= 5) {
      setHasValidationError(false);
    }

    updateUnsubscribeData('cancellationDetailsMessage', target.value);
  }

  function handleContinueClick() {
    if (cancellationDetailsMessage.length < 5) {
      setHasValidationError(true);
    } else {
      setStep(3);
    }
  }

  return (
    <>
      <SecondStepWrapper>
        <SecondStepPageTitle>
          Please share more details to help us improve!
        </SecondStepPageTitle>
        <TextareaStyled
          rows={8}
          placeholder="Leave your comment here"
          value={cancellationDetailsMessage}
          onChange={handleMessageChange}
          $hasError={hasValidationError}
        />
        {hasValidationError && (
          <ValidationMessage>
            Feedback must contain minimum 5 characters
          </ValidationMessage>
        )}
      </SecondStepWrapper>
      <Footer>
        <ContinueButton
          sizeParam="L"
          onClick={handleContinueClick}
          disabled={!cancellationDetailsMessage}
        >
          Continue
        </ContinueButton>
      </Footer>
    </>
  );
}

let SecondStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media (min-width: 700px) {
    margin-bottom: 0px;
  }
`;

let SecondStepPageTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 22px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
`;

type TextareaStyledProps = {
  $hasError: boolean;
};

let TextareaStyled = styled.textarea<TextareaStyledProps>`
  border-radius: 14px;
  border: ${(props) =>
    props.$hasError
      ? `1px solid var(--red100)`
      : `1px solid var(--strokeSecondary)`};
  padding: 16px 20px;
  margin-bottom: 8px;

  &::placeholder {
    font-size: var(--fontSizeSmall);
    color: var(--textSecondary);
  }
`;

let ValidationMessage = styled.span`
  color: var(--red100);
`;

let Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
  background-color: var(--backgroundSecondary);
  z-index: 200;

  @media (min-width: 700px) {
    position: relative;
    background-color: transparent;
    padding: 24px 0;
    bottom: 0;
  }
`;

let ContinueButton = styled(Button)`
  width: 100%;

  @media (min-width: 700px) {
    max-width: 642px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;
