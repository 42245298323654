import RocketIcon from './Icons/RocketOrangeSVG';
import WorldWideIcon from './Icons/WorldWideSVG';
import SmartphoneIcon from './Icons/SmartphoneSVG';
import SlidersIcon from './Icons/SlidersSVG';
import Card from '@front_common/Components/Card';
import styled from 'styled-components';

export default function BulletPointsComp() {
  return (
    <BulletPointsWrapper>
      <ItemsSection>
        <Bullet>
          <IconBox>
            <RocketIcon />
          </IconBox>
          <BulletFirstRow>
            <ItemTitle>Instant Activation:</ItemTitle>
            <ItemDescription>
              Get online in seconds, no physical SIM required
            </ItemDescription>
          </BulletFirstRow>
        </Bullet>
        <Bullet>
          <IconBox>
            <WorldWideIcon />
          </IconBox>

          <BulletFirstRow>
            <ItemTitle>Worldwide Access:</ItemTitle>
            <ItemDescription>
              Seamless coverage in over 199 countries
            </ItemDescription>
          </BulletFirstRow>
        </Bullet>
      </ItemsSection>
      <ItemsSection>
        <Bullet>
          <IconBox>
            <SmartphoneIcon />
          </IconBox>

          <BulletFirstRow>
            <ItemTitle>Flexible Plans:</ItemTitle>
            <ItemDescription>
              Choose the data you need, when you need it
            </ItemDescription>
          </BulletFirstRow>
        </Bullet>
        <Bullet>
          <IconBox>
            <SlidersIcon />
          </IconBox>

          <BulletFirstRow>
            <ItemTitle>Effortless Setup:</ItemTitle>
            <ItemDescription>
              Configure your eSIM quickly with our easy-to-follow guide
            </ItemDescription>
          </BulletFirstRow>
        </Bullet>
      </ItemsSection>
    </BulletPointsWrapper>
  );
}

let BulletPointsWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 0;
  border: none;
  row-gap: 24px;
  background-color: var(--backgroundPrimaryDarkNew);
  color: var(--textPrimaryInvert);
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    margin: 0 32px;
    border-radius: 16px;
  }

  @media (min-width: 960px) {
    margin: 0 auto;
    width: calc(100% - 64px);
    max-width: 1040px;
  }
`;

let ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

let Bullet = styled.div`
  display: flex;
  text-align: initial;

  @media (min-width: 700px) {
    flex-direction: column;
    padding-bottom: 0;
    position: relative;
    row-gap: 24px;
    width: 50%;
    &:nth-child(even) {
      margin-left: 24px;
    }
  }
`;

let IconBox = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`;

let BulletFirstRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
  row-gap: 8px;
`;

let ItemTitle = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
`;

let ItemDescription = styled.span`
  line-height: 22px;
  color: var(--textSecondaryLightNew);
  font-size: var(--fontSizeRegular);
`;
