import FaqLayout from '../FaqLayout';
import { useFAQQuestions } from '@front_common/Strapi/StrapiFAQ';
import styled from 'styled-components';
import FaqNavigation from '../FaqNavigation';
import PageTitleText from '@front_common/Components/PageTitleText';
import { useEffect } from 'react';
import {
  setDescriptionHeader,
  setTitleHeader,
} from '@front_common/Seo/SeoHeaders';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';

export default function FaqQuestionPage({
  questionId,
}: {
  questionId: string;
}) {
  let [faqQuestions] = useFAQQuestions();

  let questionObj = faqQuestions?.find(
    (question) => question.attributes.slug === questionId,
  );

  if (!questionObj) {
    return <LoadingAnimation />;
  }

  useEffect(() => {
    setTitleHeader(
      questionObj.attributes.seo?.title || questionObj.attributes.question,
    );
    setDescriptionHeader(questionObj.attributes.seo?.description || '');
  }, [questionObj]);

  return (
    <FaqLayout>
      <Content>
        <NavigationWrapper>
          <FaqNavigation currentQuestionId={questionId} />
        </NavigationWrapper>
        <TitleAnswer>
          <Title data-testid="faq-question-page-title">
            <PageTitleText>{questionObj?.attributes.question}</PageTitleText>
          </Title>
          <Answer
            dangerouslySetInnerHTML={{
              __html: questionObj?.attributes.answer_html || '',
            }}
          />
        </TitleAnswer>
      </Content>
    </FaqLayout>
  );
}

let Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 700px) {
    gap: 40px;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 20px;
  }
`;

let NavigationWrapper = styled.div`
  @media (min-width: 960px) {
    min-width: 325px;
    width: 325px;
    order: 2;
  }
`;

let TitleAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    gap: 24px;
  }
`;

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
    text-align: left;
  }
`;

let Answer = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 20px;
  text-align: left;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 960px) {
    order: 1;
    background-color: var(--backgroundSecondary);
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--strokeSecondary);
  }

  * {
    all: revert;
  }

  figure.image:not(.image-style-side) {
    width: fit-content;
    margin: 0 auto;
  }
`;
