import styled from 'styled-components';
import InfoBigIcon from './Icons/InfoBigIcon';
import { ReactElement } from 'react';

type IProps = {
  message: string | ReactElement;
};

export default function ESimPageInfoBox({ message }: IProps) {
  return (
    <TooltipWrapper>
      <IconBox>
        <InfoBigIcon />
      </IconBox>
      <TooltipMessage>{message}</TooltipMessage>
    </TooltipWrapper>
  );
}

let TooltipWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  border: var(--borderOutlinePrimary);
  border-radius: var(--borderRadiusLarge);
  background-color: var(--backgroundSecondary);
`;

let TooltipMessage = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
`;

let IconBox = styled.span`
  margin-right: 8px;
`;
