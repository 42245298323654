export default function LocationIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M16 3C13.0826 3 10.2847 4.15893 8.22183 6.22183C6.15893 8.28473 5 11.0826 5 14C5 19.92 14.93 28.4 15.35 28.76C15.5311 28.9149 15.7616 29.0001 16 29.0001C16.2384 29.0001 16.4689 28.9149 16.65 28.76C17.07 28.4 27 19.92 27 14C27 11.0826 25.8411 8.28473 23.7782 6.22183C21.7153 4.15893 18.9174 3 16 3ZM16 26.67C13 24 7 17.85 7 14C7 11.6131 7.94821 9.32387 9.63604 7.63604C11.3239 5.94821 13.6131 5 16 5C18.3869 5 20.6761 5.94821 22.364 7.63604C24.0518 9.32387 25 11.6131 25 14C25 17.85 19 24 16 26.67Z"
          fill="currentColor"
        />
        <path
          d="M16 8C15.0111 8 14.0444 8.29325 13.2222 8.84265C12.3999 9.39206 11.759 10.173 11.3806 11.0866C11.0022 12.0002 10.9031 13.0055 11.0961 13.9755C11.289 14.9454 11.7652 15.8363 12.4645 16.5355C13.1637 17.2348 14.0546 17.711 15.0246 17.9039C15.9945 18.0969 16.9998 17.9978 17.9134 17.6194C18.8271 17.241 19.6079 16.6001 20.1574 15.7779C20.7068 14.9556 21 13.9889 21 13C21 11.6739 20.4732 10.4021 19.5355 9.46447C18.5979 8.52678 17.3261 8 16 8ZM16 16C15.4067 16 14.8266 15.8241 14.3333 15.4944C13.8399 15.1648 13.4554 14.6962 13.2284 14.1481C13.0013 13.5999 12.9419 12.9967 13.0576 12.4147C13.1734 11.8328 13.4591 11.2982 13.8787 10.8787C14.2982 10.4591 14.8328 10.1734 15.4147 10.0576C15.9967 9.94189 16.5999 10.0013 17.1481 10.2284C17.6962 10.4554 18.1648 10.8399 18.4944 11.3333C18.8241 11.8266 19 12.4067 19 13C19 13.7956 18.6839 14.5587 18.1213 15.1213C17.5587 15.6839 16.7957 16 16 16Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
