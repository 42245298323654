export default function SimCardIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.75 12.25H10.25V16.75H14.75V12.25Z" fill="#B3B3B3" />
      <path
        d="M20.69 7.345L13.715 1.5175C13.3102 1.18207 12.8007 0.998977 12.275 1H5.75C5.15326 1 4.58097 1.23706 4.15901 1.65901C3.73705 2.08097 3.5 2.65327 3.5 3.25V21.25C3.5 21.8467 3.73705 22.419 4.15901 22.841C4.58097 23.2629 5.15326 23.5 5.75 23.5H19.25C19.8467 23.5 20.419 23.2629 20.841 22.841C21.2629 22.419 21.5 21.8467 21.5 21.25V9.0775C21.5005 8.74695 21.4282 8.42035 21.2882 8.12091C21.1482 7.82147 20.944 7.55655 20.69 7.345ZM17 17.875C17 18.1734 16.8815 18.4595 16.6705 18.6705C16.4595 18.8815 16.1734 19 15.875 19H9.125C8.82663 19 8.54048 18.8815 8.3295 18.6705C8.11853 18.4595 8 18.1734 8 17.875V11.125C8 10.8266 8.11853 10.5405 8.3295 10.3295C8.54048 10.1185 8.82663 10 9.125 10H15.875C16.1734 10 16.4595 10.1185 16.6705 10.3295C16.8815 10.5405 17 10.8266 17 11.125V17.875Z"
        fill="#B3B3B3"
      />
    </svg>
  );
}
