import { useEffect } from 'preact/hooks';
import {
  setTitleHeader,
  setDescriptionHeader,
} from '@front_common/Seo/SeoHeaders';
import styled from 'styled-components';

type IProps = {
  content: string;
  seo: {
    title: string;
    description: string;
  } | null;
};

export default function DocumentContent({ content, seo }: IProps) {
  useEffect(() => {
    function handleAnchorClick(event: MouseEvent) {
      let clickedElement = event.target as Element | null;

      while (clickedElement && clickedElement.tagName !== 'A') {
        clickedElement = clickedElement.parentElement;
      }

      if (
        clickedElement instanceof HTMLAnchorElement &&
        clickedElement.tagName === 'A'
      ) {
        let id = clickedElement.hash.replace('#', '');

        if (id) {
          event.preventDefault();
          let element = document.querySelector<HTMLElement>(`#${id}`);

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    }

    document.addEventListener('click', handleAnchorClick);

    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);

  useEffect(() => {
    if (seo) {
      setTitleHeader(`${seo.title}`);
      setDescriptionHeader(`${seo.description}`);
    }
  }, [seo]);

  return (
    <Document>
      <HTMLContent
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line xss/no-mixed-html
          __html: content,
        }}
      />
    </Document>
  );
}

let Document = styled.div`
  padding: 50px 0 60px;
`;

let HTMLContent = styled.div`
  h1 {
    font-weight: 700;
    font-size: 34px;
  }
  p {
    padding-bottom: 8pt;
  }
`;
