import UnpaidSignImage from './Images/UnpaidSign.png';
import GlobalImage from './Images/Global.png';
import { ModalCloseButton } from '@front_common/Components/Modal/OldDesign/Modal';
import { useGetMyEsimsQuery } from '@front_common/Api/UserApi';
import CloseIcon from '@front_common/Components/Modal/OldDesign/CloseIcon';
import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';

type IProps = {
  unpaidESimId: number;
  onClose: (event: MouseEvent) => void;
};

//TODO: move to utils? Do we have a similar function?
function formatDataAmount(mb: number) {
  let ONE_GB = 1024;

  if (mb < ONE_GB) {
    return `${mb}MB`;
  }
  let gb = Math.floor((mb / ONE_GB) * 10) / 10;
  return `${gb}GB`;
}

export default function UnpaidSubscriptionModalContent({
  onClose,
  unpaidESimId,
}: IProps) {
  let [response] = useGetMyEsimsQuery();

  let eSim = response?.esims.find((sim) => sim.id === unpaidESimId);

  return (
    <ModalContentWrapper>
      {/* //TODO: the Modal component already has a close button */}
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <UnpaidSign src={UnpaidSignImage} alt="unpaid sign image" />
      <UnsubscribeTitle>Renewal failed</UnsubscribeTitle>
      <UnsubscribeSubTitle>
        <span>with Global plan: {eSim?.dataPurchasedGb}GB</span>
        <img src={GlobalImage} alt="global image" />
      </UnsubscribeSubTitle>
      {/* //TODO: what if dataRemainingMb is "0"? */}
      {typeof eSim?.dataRemainingMb === 'number' && (
        <UnsubscribeDescription>
          Oops! We couldn’t renew your plan. Please complete the payment to
          maintain access and retain your unused GBs. You currently have{' '}
          {formatDataAmount(eSim.dataRemainingMb)}s to lose.
        </UnsubscribeDescription>
      )}
      <GotItButton sizeParam="L" onClick={onClose}>
        Got it
      </GotItButton>
    </ModalContentWrapper>
  );
}

let ModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`;

let UnpaidSign = styled.img`
  margin-bottom: 16px;
`;

let CloseButton = styled(ModalCloseButton)`
  top: 4px;
  right: 4px;
  background-color: var(--backgroundPrimary);
`;

//TODO: is it correct to use H1 for the modal?
let UnsubscribeTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 2px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let UnsubscribeSubTitle = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 22px;
  margin: 0 auto 8px auto;
  font-weight: var(--fontWeightSemiBold);
  img {
    margin-left: 8px;
    width: 22px;
    height: 22px;
  }
`;

let UnsubscribeDescription = styled.p`
  margin-bottom: 16px;
`;

let GotItButton = styled(Button)`
  width: 100%;
`;
