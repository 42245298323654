import React, { useState } from 'react';
import styled from 'styled-components';

export type Tab = {
  label: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
};

type TabProps = {
  tabs: Tab[];
  defaultIndex?: number | undefined;
  tabListClassName?: string;
  onTabChange?: ((index: number) => void) | undefined;
};

export function Tabs(props: TabProps) {
  let { tabs, defaultIndex = 0, tabListClassName, onTabChange } = props;

  let [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);

  function handleTabClick(index: number) {
    setActiveTabIndex(index);

    if (onTabChange) {
      onTabChange(index);
    }
  }

  return (
    <>
      <TabList className={tabListClassName || ''}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            $isActive={activeTabIndex === index}
            onClick={() => handleTabClick(index)}
          >
            {tab.icon && <TabIcon>{tab.icon}</TabIcon>}
            <span>{tab.label}</span>
          </Tab>
        ))}
      </TabList>
      <div>{tabs[activeTabIndex]?.content}</div>
    </>
  );
}

let TabList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin-left: auto;
    margin-right: auto;
  }
`;

let Tab = styled.div<{ $isActive: boolean }>`
  height: 44px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$isActive
      ? '2px solid var(--tabBarActive)'
      : '1px solid var(--strokeSecondary)'};
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  span {
    padding-top: 14px;
    line-height: 16px;
    font-size: 12px;
    font-weight: ${(props) =>
      props.$isActive ? 'var(--fontWeightBold)' : 'var(--fontWeightMedium)'};
    color: ${(props) =>
      props.$isActive ? 'var(--textSelected)' : 'var(--textPrimary)'};
    transition: font-weight 0.2s;
  }

  svg path {
    fill: ${(props) =>
      props.$isActive
        ? 'var(--iconsPrimaryBrand)'
        : 'var(--iconsPrimaryInvert)'};
  }

  @media (min-width: 393px) {
    span {
      font-size: var(--fontSizeSmall);
    }
  }
`;

let TabIcon = styled.div`
  height: 20px;
  margin-top: 12px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
