import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import appleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/AppleDownloadNew.png';
import googleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/GoogleDownloadNew.png';
import {
  APP_DOWNLOAD_APPLE,
  APP_DOWNLOAD_GOOGLE,
} from '@shared_frontend/Module/Layout/FooterConstants';
import qRImage from '@front_common/Components/DownloadApp/Images/QR.png';
import Api from '@front_common/Api/Api';
import styled from 'styled-components';

function handleDownloadClick(value: 'Apple' | 'Google') {
  Api.trackAction(ActionTypes.button_app, value);
}

export default function DownloadApp() {
  return (
    <DownloadAppBackground>
      <DownloadAppWrapper>
        <MainContent>
          <AppDownload>
            <Title>Download the Ohayu app</Title>
            <Description>
              Or scan the code with your phone to download Ohayu.
            </Description>
            <DownloadLinks>
              <a
                href={APP_DOWNLOAD_APPLE}
                onClick={() => handleDownloadClick('Apple')}
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleDownloadImage} alt="apple download" />
              </a>
              <a
                href={APP_DOWNLOAD_GOOGLE}
                onClick={() => handleDownloadClick('Google')}
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleDownloadImage} alt="google download" />
              </a>
            </DownloadLinks>
          </AppDownload>
        </MainContent>
        <QRContent>
          <QRImage src={qRImage} alt="qr" />
        </QRContent>
      </DownloadAppWrapper>
    </DownloadAppBackground>
  );
}

let DownloadAppBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  background-color: var(--backgroundSecondaryNew);

  @media (min-width: 960px) {
    margin-top: 48px;
  }
`;

let DownloadAppWrapper = styled.div`
  display: flex;
  padding: 24px 16px;
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    margin-top: 0;
    width: 100%;
    padding: 48px 32px;
  }

  @media (min-width: 960px) {
    padding: 48px 32px;
    width: 100%;
    max-width: 1040px;
  }

  @media (min-width: 1104px) {
    padding: 48px 0;
  }
`;

let MainContent = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 700px) {
    justify-content: flex-start;
    align-items: center;
  }
`;

let AppDownload = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;

  @media (min-width: 700px) {
    row-gap: 0;
  }
`;

let Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 8px;
  }
`;

let Description = styled.p`
  display: none;
  font-size: var(--fontSizeRegular);

  @media (min-width: 700px) {
    display: block;
    margin-bottom: 32px;
  }
`;

let DownloadLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  a {
    display: flex;

    img {
      width: 100%;
      height: 52px;
    }
  }

  @media (min-width: 700px) {
    justify-content: flex-start;
    a {
      display: flex;

      img {
        width: 128px;
        height: 38px;
      }
    }
  }
`;

let QRContent = styled.div`
  display: none;

  @media (min-width: 700px) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

let QRImage = styled.img`
  @media (min-width: 700px) {
    width: 150px;
    height: auto;
  }

  @media (min-width: 960px) {
    bottom: 32px;
    width: 185px;
  }
`;
