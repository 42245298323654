import { forwardRef, HTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import SearchIcon from './Icons/SearchIcon';
import CrossIcon from './Icons/CrossIcon';

interface IProps extends HTMLAttributes<HTMLInputElement> {
  onClearClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

let SearchInput = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  let { onClearClick, ...rest } = props;
  let inputRef = useRef<HTMLInputElement>(null);

  function handleClearClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.onClearClick) {
      props.onClearClick(event);
    }

    if (ref && 'current' in ref && ref.current) {
      ref.current.focus();
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <InputContainer>
      <StyledInput ref={ref || inputRef} type="text" {...rest} />
      <IconPlace>
        {props.value ? (
          <IconButton onClick={handleClearClick}>
            <CrossIcon />
          </IconButton>
        ) : (
          <SearchIcon />
        )}
      </IconPlace>
    </InputContainer>
  );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;

let InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

let IconPlace = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
`;

let IconButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;

let StyledInput = styled.input.attrs((props) => ({
  type: 'text',
  ...props,
}))`
  height: 56px;
  padding: 0 16px;
  border-radius: var(--borderRadiusPrimary);
  width: 100%;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);
  border: 1px solid var(--strokeSecondary);
  outline: none;
  text-overflow: ellipsis;
  padding-right: 40px;

  &::placeholder {
    color: var(--strokePrimary);
  }

  &:hover {
    border: 1px solid var(--strokePrimary);
  }

  &:focus {
    border: 1px solid var(--strokePrimary);
  }

  &:focus::placeholder {
    color: transparent;
  }
`;
