import { useEffect, useRef, useState } from 'preact/hooks';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import UserApi from '@front_common/Api/UserApi';
import { ReactElement } from 'preact/compat';
import PaymentSuccessfulContent from '../../InstallFlow/PaymentSuccessfulContent';
import EsimReadyContent from '../../InstallFlow/EsimReadyContent';
import WaitContent from '../../InstallFlow/WaitContent';

type SuccessModalProps = {
  show: boolean;
  paymentSessionId: number;
};

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function fetchESims(): Promise<EsimEntityType[]> {
  try {
    let response = await UserApi.getMyEsims();
    return response.esims || [];
  } catch {
    return [];
  }
}

function findESimId(
  esims: EsimEntityType[],
  paymentSessionId: number,
): number | undefined {
  return esims.find((esim) => esim.paymentSessionId === paymentSessionId)?.id;
}

function startPolling(
  callback: () => Promise<boolean>,
  interval: number,
  maxDuration: number,
) {
  let intervalId = setInterval(async () => {
    let shouldStop = await callback();
    if (shouldStop) {
      cleanupPolling();
    }
  }, interval * 1000);

  let timeoutId = setTimeout(() => {
    cleanupPolling();
  }, maxDuration * 1000);

  function cleanupPolling() {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
  }

  return cleanupPolling;
}

//TODO: is this component different from the original SuccessModal in the InstallFlow module/folder?
export default function SuccessModal({
  show,
  paymentSessionId,
}: SuccessModalProps) {
  let [isSuccessModalOpen, setIsSuccessModalOpen] = useState(show);
  let [content, setContent] = useState<ReactElement | null>(
    <PaymentSuccessfulContent />,
  );

  let pollingCleanup = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (show) {
      checkPaymentsInitial();
    }
    setIsSuccessModalOpen(show);
  }, [show]);

  useEffect(
    () => () => {
      pollingCleanup.current?.();
    },
    [],
  );

  async function reFetchESims() {
    let eSims = await fetchESims();
    let eSimId = findESimId(eSims, paymentSessionId);
    if (eSimId) {
      setContent(<EsimReadyContent esimId={eSimId} />);
      return true;
    }
    setContent(<WaitContent />);
    return false;
  }

  async function checkPaymentsInitial() {
    setContent(<PaymentSuccessfulContent />);

    let eSims = await fetchESims();
    await delay(3000);

    let eSimId = findESimId(eSims, paymentSessionId);

    if (eSimId) {
      setContent(<EsimReadyContent esimId={eSimId} />);
    } else {
      pollingCleanup.current = startPolling(reFetchESims, 10, 60);
    }
  }

  function closeSuccessModal() {
    setIsSuccessModalOpen(false);
  }

  return (
    <Modal
      analyticType="successful-payment"
      isOpen={isSuccessModalOpen}
      onClose={closeSuccessModal}
      overlayClick={false}
      hideCloseButton={content?.type !== WaitContent}
    >
      {content}
    </Modal>
  );
}
