import { usePrivacyPolicy } from '@front_common/Strapi/StrapiLegal';
import DocumentContent from './DocumentContent';

export default function PrivacyPolicy() {
  let [privacyPolicy] = usePrivacyPolicy();

  return (
    <DocumentContent
      content={privacyPolicy?.attributes.content || ''}
      seo={privacyPolicy?.attributes.seo || null}
    />
  );
}
