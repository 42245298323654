import styled from 'styled-components';
import PlanItem from './PlanItem';
import { useEffect, useState } from 'preact/hooks';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { SubProductType } from '@shared_backend/Module/Subscription/SubProductResponse';
import { getPricePerGb } from './Utils';

type IProps = {
  plans: SubProductType[] | null | undefined;
  monthlyPlans: SubProductType[] | null | undefined;
  onPlanSelect: (id: number) => void;
  onCountriesClick: (id: number) => void;
  onPlanDetailsClick: (id: number) => void;
  onGoToCheckoutClick: (planId: number) => void;
};

export default function MonthlyPlans(props: IProps) {
  let {
    plans,
    monthlyPlans,
    onPlanSelect,
    onCountriesClick,
    onPlanDetailsClick,
    onGoToCheckoutClick,
  } = props;

  let sortedPlans = plans?.sort((first, next) => next.dataGb - first.dataGb);

  let defaultSelectedPlanId =
    sortedPlans && sortedPlans?.length >= 2
      ? sortedPlans && sortedPlans[1]?.id
      : sortedPlans && sortedPlans[0]?.id;

  let [selectedPlan, setSelectedPlan] = useState<number | null>(
    defaultSelectedPlanId || null,
  );

  function handlePlanItemClick(id: number) {
    onPlanSelect(id);
    setSelectedPlan(id);
  }

  useEffect(() => {
    if (defaultSelectedPlanId) {
      onPlanSelect(defaultSelectedPlanId);
    }
  }, [defaultSelectedPlanId]);

  function getMonthPriceDiscountPerGb(yearlyPlan: SubProductType) {
    let searchPlan = monthlyPlans?.find(
      (monthlyPlan) => monthlyPlan.dataGb * 12 === yearlyPlan.dataGb,
    );

    if (searchPlan) {
      return getPricePerGb(searchPlan);
    }

    return null;
  }

  return (
    <YearlyPlansWrapper>
      {sortedPlans?.map((plan) => (
        <PlanItem
          key={plan.id}
          amountGb={plan.dataGb}
          priceLine={`$${(plan.priceCents / 100 / 12).toFixed(2)} / month (billed yearly)`}
          pricePerGb={getPricePerGb(plan)}
          discount={plan.cheaperPercents}
          monthPriceDiscount={getMonthPriceDiscountPerGb(plan) || undefined}
          highlighted={defaultSelectedPlanId === plan.id}
          onCountriesClick={() => onCountriesClick(plan.id)}
          onPlanDetailsClick={() => onPlanDetailsClick(plan.id)}
          onClick={() => handlePlanItemClick(plan.id)}
          selected={(selectedPlan || defaultSelectedPlanId) === plan.id}
          onGoToCheckoutClick={() => onGoToCheckoutClick?.(plan.id)}
        />
      ))}
      {!plans && <LoadingAnimation />}
    </YearlyPlansWrapper>
  );
}

let YearlyPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    flex-wrap: nowrap;
  }
`;
