import {
  WEB_ROUTE_ACCEPTABLE_USE_POLICY,
  WEB_ROUTE_COMPATIBLE_DEVICES,
  WEB_ROUTE_FAQ,
  WEB_ROUTE_HOW_TO_INSTALL_ESIM,
  WEB_ROUTE_PRIVACY_POLICY,
  WEB_ROUTE_REFUND_POLICY,
  WEB_ROUTE_SUBSCRIPTION_POLICY,
  WEB_ROUTE_TERMS_AND_CONDITIONS,
} from '../../Common/WebRoutes';
import InstagramIcon from './Instagram.svg';
import InstagramIconNewIcon from './InstagramNew.svg';
import FacebookIcon from './Facebook.svg';
import FacebookNewIcon from './FacebookNew.svg';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
} from '../../Common/Constants/SocialsConstants';

export const HELP_LIST = [
  {
    href: WEB_ROUTE_COMPATIBLE_DEVICES,
    label: 'Compatible Devices',
  },
  {
    href: WEB_ROUTE_HOW_TO_INSTALL_ESIM,
    label: 'How to install eSIM',
  },
  {
    href: WEB_ROUTE_FAQ,
    label: 'FAQ',
  },
];

export const LEGAL_LIST = [
  {
    href: WEB_ROUTE_TERMS_AND_CONDITIONS,
    label: 'Terms and Conditions',
  },
  {
    href: `${WEB_ROUTE_PRIVACY_POLICY}`,
    label: 'Privacy Policy',
  },
  {
    href: WEB_ROUTE_REFUND_POLICY,
    label: 'Refund Policy',
  },
  {
    href: WEB_ROUTE_ACCEPTABLE_USE_POLICY,
    label: 'Acceptable Use Policy',
  },
  {
    href: WEB_ROUTE_SUBSCRIPTION_POLICY,
    label: 'Subscription Policy',
  },
];

export const SOCIALS_LIST = [
  {
    href: INSTAGRAM_LINK,
    label: 'Instagram',
    icon: InstagramIcon,
  },
  {
    href: FACEBOOK_LINK,
    label: 'Facebook',
    icon: FacebookIcon,
  },
];

// TODO: Remove after design split test
export const SOCIALS_LIST_NEW = [
  {
    href: INSTAGRAM_LINK,
    label: 'Instagram',
    icon: InstagramIconNewIcon,
  },
  {
    href: FACEBOOK_LINK,
    label: 'Facebook',
    icon: FacebookNewIcon,
  },
];

export const ADDRESS = 'Kridelo Limited. Florinis 7, Nicosia, Cyprus, 1065';

export const APP_DOWNLOAD_APPLE =
  'https://apps.apple.com/us/app/ohayu/id6535672837';
export const APP_DOWNLOAD_GOOGLE =
  'https://play.google.com/store/apps/details?id=com.ohayu';
