export default function CheckListSVG() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20004 14.1861C4.93746 14.1866 4.67736 14.1352 4.43471 14.0348C4.19205 13.9345 3.9716 13.7872 3.78604 13.6014L0.057373 9.87341L1.00004 8.93008L4.72871 12.6587C4.85372 12.7837 5.02326 12.8539 5.20004 12.8539C5.37682 12.8539 5.54635 12.7837 5.67137 12.6587L15 3.33008L15.9427 4.27274L6.61404 13.6014C6.42847 13.7872 6.20803 13.9345 5.96537 14.0348C5.72271 14.1352 5.46262 14.1866 5.20004 14.1861Z"
        fill="#21252A"
      />
    </svg>
  );
}
