export default function FileIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path
        d="M20.031 6.219l-3.75-3.75a.75.75 0 0 0-.531-.219h-7.5a1.5 1.5 0 0 0-1.5 1.5v1.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5v-1.5h1.5a1.5 1.5 0 0 0 1.5-1.5V6.75a.75.75 0 0 0-.219-.531zM12.75 18h-4.5a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75.75.75 0 0 1-.75.75zm0-3h-4.5a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75.75.75 0 0 1-.75.75zm6 2.25h-1.5v-7.5a.75.75 0 0 0-.219-.531l-3.75-3.75a.75.75 0 0 0-.531-.219h-4.5v-1.5h7.19l3.31 3.31v10.19z"
        fill="currentColor"
      />
    </svg>
  );
}
