export default function SupportIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11523_660)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.61438 14.5383C9.61438 13.9853 9.16606 13.537 8.61306 13.537C8.22303 13.537 7.77697 13.537 7.38694 13.537C7.12138 13.537 6.86669 13.6425 6.67891 13.8303C6.49113 14.0181 6.38562 14.2728 6.38562 14.5383C6.38562 15.0913 6.83394 15.5396 7.38694 15.5396C7.77697 15.5396 8.22303 15.5396 8.61306 15.5396C9.16606 15.5396 9.61438 15.0913 9.61438 14.5383ZM2.10503 11.9564C2.27228 11.9691 2.47463 11.9765 2.67788 11.9646C2.78616 12.5137 3.05516 13.023 3.45669 13.4246C3.98975 13.9576 4.71272 14.2571 5.46656 14.2571H5.84863C5.83178 14.3493 5.82312 14.4434 5.82312 14.5383C5.82312 14.6344 5.83178 14.7284 5.84837 14.8196H5.46656C4.56353 14.8196 3.69747 14.4609 3.05894 13.8223C2.54953 13.3129 2.21819 12.6587 2.10503 11.9564ZM1.81891 11.3578C1.37481 11.283 0.961563 11.0718 0.638969 10.7492C0.229844 10.3401 0 9.78517 0 9.20658C0 8.78152 0 8.30217 0 7.87711C0 7.29852 0.229844 6.74361 0.638969 6.33449C1.04809 5.92536 1.603 5.69549 2.18159 5.69549H2.32681C2.55894 2.76561 5.01025 0.460205 8 0.460205C10.9897 0.460205 13.4411 2.76561 13.6732 5.69549H13.8184C14.397 5.69549 14.9519 5.92536 15.361 6.33449C15.7702 6.74361 16 7.29852 16 7.87711V9.20658C16 9.78517 15.7702 10.3401 15.361 10.7492C14.9519 11.1584 14.397 11.3882 13.8184 11.3882H13.1746C12.9076 11.3882 12.6912 11.1717 12.6912 10.9048V6.15139C12.6912 3.56052 10.5909 1.46021 8 1.46021C5.40913 1.46021 3.30881 3.56052 3.30881 6.15139C3.30881 6.15139 3.30881 9.6082 3.30881 10.9048C3.30881 11.0933 3.20091 11.2566 3.04347 11.3363C2.61009 11.4867 1.938 11.3779 1.81891 11.3578Z"
          fill="#203ADB"
        />
      </g>
      <defs>
        <clipPath id="clip0_11523_660">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
