import styled from 'styled-components';
import { LinkToRoute } from '@front_common/Router/Router';
import {
  CONFIG_MIRROR_HOST,
  CONFIG_SUPPORT_EMAIL,
} from '@front_common/Config.ts';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import {
  ADDRESS,
  APP_DOWNLOAD_APPLE,
  APP_DOWNLOAD_GOOGLE,
  HELP_LIST,
  LEGAL_LIST,
  SOCIALS_LIST_NEW,
} from '@shared_frontend/Module/Layout/FooterConstants';
import NoIndexContent from '@front_common/Components/NoIndexContent';
import { WEB_ROUTE_BLOG } from '@shared_frontend/Common/WebRoutes';
import appleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/AppleDownload.png';
import googleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/GoogleDownload.png';
import { SHORT_FOOTER_CLASS_NAME } from '../../Checkout/useDisplayingHeaderFooterElements';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { getESimCountryUrlByCountryCode } from '../../CountryStore/OldDesign/CountryStoreUtils';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';

function handleDownloadClick(value: 'Apple' | 'Google') {
  Api.trackAction(ActionTypes.button_app, value);
}

function AppLinks() {
  return (
    <DownloadLinks>
      <a
        href={APP_DOWNLOAD_APPLE}
        onClick={() => handleDownloadClick('Apple')}
        target="_blank"
        rel="noreferrer"
      >
        <img src={appleDownloadImage} alt="apple download" />
      </a>
      <a
        href={APP_DOWNLOAD_GOOGLE}
        onClick={() => handleDownloadClick('Google')}
        target="_blank"
        rel="noreferrer"
      >
        <img src={googleDownloadImage} alt="google download" />
      </a>
    </DownloadLinks>
  );
}

function TopDestinationColumn() {
  return (
    <ContentItem>
      <SubTitle>Top destinations</SubTitle>
      <ListColumn>
        {POPULAR_COUNTRIES.map((countryCode) => (
          <ListItem key={countryCode}>
            <LinkToRoute href={getESimCountryUrlByCountryCode(countryCode)}>
              {countryNameByCode(countryCode)}
            </LinkToRoute>
          </ListItem>
        ))}
      </ListColumn>
    </ContentItem>
  );
}

function LegalColumn() {
  return (
    <ContentItem>
      <SubTitle>Legal</SubTitle>
      <ListColumn>
        {LEGAL_LIST.map((legalItem) => (
          <ListItem key={legalItem.label}>
            <LinkToRoute href={`${legalItem.href}/`}>
              {legalItem.label}
            </LinkToRoute>
          </ListItem>
        ))}
      </ListColumn>
    </ContentItem>
  );
}

function HelpColumn() {
  return (
    <ContentItem>
      <SubTitle>Help</SubTitle>
      <ListColumn>
        {HELP_LIST.map((helpItem) => (
          <ListItem key={helpItem.label}>
            <LinkToRoute href={`${helpItem.href}/`}>
              {helpItem.label}
            </LinkToRoute>
          </ListItem>
        ))}
        <ListItem>
          <a href={`${CONFIG_MIRROR_HOST}${WEB_ROUTE_BLOG}`}>Blog</a>
        </ListItem>
      </ListColumn>
    </ContentItem>
  );
}

function SocialColumn() {
  return (
    <ContentItem>
      <SubTitle>Socials</SubTitle>
      <ListRow>
        {SOCIALS_LIST_NEW.map((socialItem) => (
          <ListItem key={socialItem.label}>
            <a href={socialItem.href} target="_blank" rel="noreferrer">
              {<img src={socialItem.icon} alt={`${socialItem.label} icon`} />}
            </a>
          </ListItem>
        ))}
      </ListRow>
    </ContentItem>
  );
}

export default function PageFooter() {
  return (
    <FooterWrapper>
      <Footer>
        <MainContent>
          <NavigationContainer>
            <NavigationLinks>
              <RowWrapper>
                <TopDestinationColumn />
                <LegalColumn />
              </RowWrapper>
              <HelpColumn />
            </NavigationLinks>
            <SocialWrapper>
              <SocialColumn />
              <AppLinks />
            </SocialWrapper>
          </NavigationContainer>
        </MainContent>
        <Divider />
        <BottomSide>
          <NoIndexContent>{ADDRESS}</NoIndexContent>
        </BottomSide>
      </Footer>
    </FooterWrapper>
  );
}

type PageShortFooterProps = {
  withBottomMargin: boolean;
};

export function PageShortFooter(props: PageShortFooterProps) {
  let { withBottomMargin } = props;

  return (
    <ShortFooterWrapper
      className={SHORT_FOOTER_CLASS_NAME}
      $withBottomMargin={withBottomMargin}
    >
      <ShortFooterPaddingWrapper>
        <ShortFooterContent>
          <ShortFooterSupport href={'mailto:' + CONFIG_SUPPORT_EMAIL}>
            {CONFIG_SUPPORT_EMAIL}
          </ShortFooterSupport>
          <ShortFooterBottomSide>
            <NoIndexContent>{ADDRESS}</NoIndexContent>
          </ShortFooterBottomSide>
        </ShortFooterContent>
      </ShortFooterPaddingWrapper>
    </ShortFooterWrapper>
  );
}

let FooterWrapper = styled.div`
  padding: 48px 16px 2px 16px;
  width: 100%;
  color: var(--textPrimaryInvert);
  font-family: var(--fontFamilyNew);
  background-color: var(--backgroundPrimaryDarkNew);

  @media (min-width: 700px) {
    padding: 40px 40px 20px;
  }

  @media (min-width: 960px) {
    padding: 64px 32px 32px 32px;
    width: 100%;
  }

  @media (min-width: 1104px) {
    padding: 64px 0 32px 0;
  }
`;

let Footer = styled.footer`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 960px) {
    max-width: 1040px;
  }
`;

let MainContent = styled.div`
  margin-bottom: 32px;
  width: 100%;

  @media (min-width: 700px) {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 20px;
  }
`;

let NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  color: var(--textPrimaryInvert);
  width: 100%;

  @media (min-width: 700px) {
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
  }

  @media (min-width: 960px) {
    column-gap: 20px;
  }
`;

let NavigationLinks = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 24px;

  @media (min-width: 700px) {
    flex-direction: row;
    width: 75%;
  }
`;

let RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 24px;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 700px) {
    flex-direction: row;
    column-gap: 16px;
    width: 66.66%;
    & > div {
      width: 50%;
    }
  }

  @media (min-width: 960px) {
    column-gap: 20px;
  }
`;

let ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

let SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  width: 100%;
  span {
    text-align: center;
    margin-bottom: 8px;
  }

  @media (min-width: 700px) {
    width: 25%;
    align-items: flex-start;
    span {
      text-align: left;
      margin-bottom: 0;
    }
  }
`;

let SubTitle = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 960px) {
    white-space: nowrap;
  }
`;

let ListColumn = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let ListRow = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

let ListItem = styled.li`
  min-height: 20px;
  font-size: 14px;
  list-style-type: none;

  a {
    text-decoration: none;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--textPrimaryInvert);

    &:hover {
      color: var(--textSecondaryLightNew);
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

let BottomSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 32px;
  line-height: 20px;
  color: var(--textPrimaryInvert);
  font-size: 12px;
  opacity: 75%;
`;

let Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--strokePrimary);
  opacity: 10%;
`;

let DownloadLinks = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;

  a {
    display: flex;

    img {
      width: 128px;
      height: 40px;
    }
  }

  @media (min-width: 700px) {
    flex-direction: column;
  }
`;

let ShortFooterWrapper = styled.div<{ $withBottomMargin: boolean }>`
  width: 100%;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--backgroundSecondary);
  font-family: var(--fontFamilyNew);
  color: var(--textSecondaryNew);
  padding-bottom: ${(props) => (props.$withBottomMargin ? '128px' : 'initial')};

  @media (min-width: 700px) {
    padding-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: ${(props) =>
      props.$withBottomMargin ? '127px' : 'initial'};
  }

  @media (min-width: 960px) {
    padding: 0 32px;
    padding-bottom: ${(props) =>
      props.$withBottomMargin ? '98px' : 'initial'};
  }

  @media (min-width: 1104px) {
    padding: 0;
  }
`;

let ShortFooterPaddingWrapper = styled.div`
  @media (min-width: 700px) {
    padding: 13px 0px;
  }

  @media (min-width: 960px) {
    padding: 13px 0px;
  }
`;

let ShortFooterContent = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media (min-width: 700px) {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let ShortFooterSupport = styled.a`
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
  color: var(--textSecondaryNew);
  font-size: var(--fontSizeRegular);

  &:hover {
    color: var(--textSecondaryNew);
  }
`;

let ShortFooterBottomSide = styled(BottomSide)`
  margin-top: 12px;
  justify-content: flex-start;
  color: var(--textSecondaryNew);
  font-size: var(--fontSizeRegular);

  @media (min-width: 700px) {
    margin-top: 0;
  }
`;
