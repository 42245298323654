import styled from 'styled-components';
import { useEffect, useState } from 'preact/hooks';

function ArrowUpIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37897 12.0289C4.91886 11.6587 4.87103 11.0143 5.27216 10.5897L11.1669 4.34972C11.3768 4.12753 11.6806 4 12 4C12.3195 4 12.6232 4.12753 12.8331 4.34972L18.7278 10.5897C19.1289 11.0143 19.0812 11.6587 18.6211 12.0289C18.1609 12.3991 17.4628 12.3549 17.0617 11.9303L13.1053 7.74219V19.98C13.1053 20.5433 12.6104 21 12 21C11.3896 21 10.8947 20.5433 10.8947 19.98V7.74219L6.93839 11.9303C6.53727 12.3549 5.83908 12.3991 5.37897 12.0289Z"
        fill="black"
      />
    </svg>
  );
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function ScrollToTopButton() {
  let [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Show button when user scrolls down 100px
  function toggleVisibility() {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  return (
    <ScrollToTopButtonStyled onClick={scrollToTop} $isVisible={isVisible}>
      <ArrowUpIcon />
    </ScrollToTopButtonStyled>
  );
}

let ScrollToTopButtonStyled = styled.button<{ $isVisible: boolean }>`
  display: ${(props) => (props.$isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: var(--buttonPrimary);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  border: none;
  outline: transparent;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--iconsPrimary);
    }
  }
`;
