import { useTermsAndConditions } from '@front_common/Strapi/StrapiLegal';
import DocumentContent from './DocumentContent';

export default function TermsAndConditions() {
  let [termsAndConditions] = useTermsAndConditions();

  return (
    <DocumentContent
      content={termsAndConditions?.attributes.content || ''}
      seo={termsAndConditions?.attributes.seo || null}
    />
  );
}
