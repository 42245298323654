import styled from 'styled-components';
import lockImage from './Images/Lock.png';
import fingerDownImage from './Images/FingerDown.png';
import world3dImage from './Images/World3d.png';
import CheckIcon from './Icons/CheckIcon';
import MinusIcon from './Icons/MinusIcon';

export default function UnlockTable() {
  return (
    <UnlockTableWrapper>
      <Title>
        <h2>{`Unlock it all with
        global plans`}</h2>
        <img src={lockImage} alt="lock" />
      </Title>
      <Table>
        <TableHeader>
          <div>
            <WhatYouGetHeader>
              What you get <img src={fingerDownImage} alt="finger down" />
            </WhatYouGetHeader>
          </div>
          <div>Regular</div>
          <div>
            <GlobalHeader>
              Global <img src={world3dImage} alt="world" />
            </GlobalHeader>
          </div>
        </TableHeader>
        <TableRow>
          <div>
            <FirstColTitle>High speed internet</FirstColTitle>Enjoy fast,
            reliable 4G/5G connections wherever you travel.
          </div>
          <div>
            <CheckIcon />
          </div>
          <div>
            <CheckIcon />
          </div>
        </TableRow>
        <TableRow>
          <div>
            <FirstColTitle>No hidden fees</FirstColTitle>What you see is what
            you pay — no unexpected charges.
          </div>
          <div>
            <CheckIcon />
          </div>
          <div>
            <CheckIcon />
          </div>
        </TableRow>
        <TableRow>
          <div>
            <FirstColTitle>One eSIM for all countries</FirstColTitle>Use the
            same eSIM in 112 countries — no need to buy and install new eSIMs.
          </div>
          <div>
            <MinusIcon />
          </div>
          <div>
            <CheckIcon />
          </div>
        </TableRow>
        <TableRow>
          <div>
            <FirstColTitle>Keep unused data for the next period</FirstColTitle>
            {`Any leftover data rolls over, so you do not lose what you've paid for.`}
          </div>
          <div>
            <MinusIcon />
          </div>
          <div>
            <CheckIcon />
          </div>
        </TableRow>
        <TableRow>
          <div>
            <FirstColTitle>Lower cost for global travel</FirstColTitle>Save up
            to 70% compared to buying separate plans for each country.
          </div>
          <div>
            <MinusIcon />
          </div>
          <div>
            <CheckIcon />
          </div>
        </TableRow>
      </Table>
    </UnlockTableWrapper>
  );
}

let UnlockTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 24px;
  }
`;

let Title = styled.div`
  text-align: center;

  h2 {
    font-family: var(--fontFamilyHeader);
    font-size: 24px;
    font-weight: var(--fontWeightBold);
    line-height: 32px;
    display: inline;
    white-space: pre-line;
  }

  img {
    width: 40px;
    height: 40px;
    margin-bottom: -12px;
  }

  @media (min-width: 700px) {
    h2 {
      font-size: 28px;
      line-height: 38px;
      white-space: initial;
    }

    img {
      width: 60px;
      height: 60px;
      margin-bottom: -20px;
    }
  }

  @media (min-width: 960px) {
    text-align: left;
    max-width: 361px;

    h2 {
      font-size: 34px;
      line-height: 40px;
    }

    img {
      width: 120px;
      height: 120px;
      margin-bottom: -80px;
    }
  }
`;

let Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 79px 79px;
  border: 1px solid var(--strokeSecondary);
  border-radius: var(--borderRadiusLarge);
  overflow: hidden;
  background: var(--backgroundSecondary);

  @media (min-width: 700px) {
    grid-template-columns: 1fr 100px 100px;
  }

  @media (min-width: 960px) {
    width: 100%;
    grid-template-columns: 1fr minmax(0, 180px) minmax(0, 180px);
  }
`;

let TableHeader = styled.div`
  display: contents;

  > div {
    height: 44px;
    background: var(--white100);
    border-bottom: 1px solid var(--strokeSecondary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontWeightBold);
    line-height: 20px;

    &:last-child {
      background-color: var(--cardsBackgroundGreenLightSecondary);
    }

    &:first-child {
      padding-left: 12px;
    }
  }

  @media (min-width: 700px) {
    > div {
      font-size: var(--fontSizeRegular);
      line-height: 22px;
    }
  }
`;

let TableRow = styled.div`
  display: contents;

  > div {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
    border-bottom: 1px solid var(--strokeSecondary);

    &:last-child {
      background-color: var(--cardsBackgroundGreenLightSecondary);
    }

    &:not(:first-child) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:last-child > div {
    border-bottom: none;
  }

  @media (min-width: 700px) {
    > div {
      font-size: var(--fontSizeSmall);
      line-height: 20px;
    }
  }
`;

let WhatYouGetHeader = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: auto;

  img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
`;

let GlobalHeader = styled.span`
  display: flex;
  align-items: center;
  padding: 0 4px;
  background-color: var(--backgroundSecondary);
  border-radius: 6px;

  img {
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }

  @media (min-width: 700px) {
    padding: 0 8px;
  }
`;

let FirstColTitle = styled.p`
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;
  margin-bottom: 4px;

  @media (min-width: 700px) {
    font-size: var(--fontSizeRegular);
    line-height: 22px;
  }
`;
