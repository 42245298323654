export default function CaseIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M28.1875 8H20.3578C20.0885 6.541 19.1651 4 16 4C12.8349 4 11.9115 6.541 11.6422 8H3.8125C3.59701 8 3.39035 8.10536 3.23798 8.29289C3.0856 8.48043 3 8.73478 3 9V27C3 27.2652 3.0856 27.5196 3.23798 27.7071C3.39035 27.8946 3.59701 28 3.8125 28H28.1875C28.403 28 28.6097 27.8946 28.762 27.7071C28.9144 27.5196 29 27.2652 29 27V9C29 8.73478 28.9144 8.48043 28.762 8.29289C28.6097 8.10536 28.403 8 28.1875 8ZM16 6C17.7611 6 18.4294 7.122 18.6845 8H13.3139C13.5698 7.1265 14.2405 6 16 6ZM27.375 10V14H4.625V10H27.375ZM14.7812 16H17.2188V17H14.7812V16ZM4.625 26V16H13.1562V18C13.1562 18.2652 13.2419 18.5196 13.3942 18.7071C13.5466 18.8946 13.7533 19 13.9688 19H18.0312C18.2467 19 18.4534 18.8946 18.6058 18.7071C18.7581 18.5196 18.8438 18.2652 18.8438 18V16H27.375V26H4.625Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
