import { SubscriptionType } from '@shared_backend/Module/Subscription/MySubscriptionResponse';

export function formatDateToShortString(date: Date | null | undefined) {
  if (!date || typeof date !== 'object') {
    return '';
  }
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDataAmount(mb: number) {
  let ONE_GB = 1024;

  if (mb < ONE_GB) {
    return `${mb}MB`;
  }

  let amountInGb = mb / ONE_GB;
  return `${(Math.floor(amountInGb * 100) / 100).toFixed(2).replace('.00', '')}GB`;
}

export function calculateUsagePercentage(usedMb: number, totalMb: number) {
  if (totalMb === 0) {
    return 0;
  }

  return Math.floor((usedMb / totalMb) * 100);
}

export function timeLeftFromToday(date: Date | undefined | null): string {
  if (!date) {
    return '';
  }

  // Get current date in UTC
  let now = new Date();
  let currentDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds(),
    ),
  );
  //TODO: check after transformer applied - date should be as Date
  let targetDate = new Date(date);
  let diffInMilliseconds = targetDate.getTime() - currentDate.getTime();

  if (diffInMilliseconds <= 0) {
    return '';
  }

  let diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  let days = Math.floor(diffInHours / 24);
  let hours = diffInHours % 24;

  return `${days} days ${hours} hours`;
}

export function getSubscriptionByEsimId(
  subscriptions: SubscriptionType[],
  esimId: number,
) {
  return subscriptions.find((sub) => sub.esimId === esimId);
}
