export function HpIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8196 15.5059L27.8236 26.5099H24.9436L28.9636 15.5059H31.8196Z"
        fill="#0391CF"
      />
      <path
        d="M38.9359 21C38.9359 23.3638 38.4703 25.7044 37.5657 27.8883C36.6611 30.0722 35.3353 32.0565 33.6638 33.7279C31.9923 35.3994 30.008 36.7252 27.8242 37.6298C25.6403 38.5344 23.2997 39 20.9359 39H20.3959L24.0919 28.872H29.1559C29.6294 28.8737 30.092 28.7295 30.4806 28.459C30.8693 28.1884 31.1651 27.8047 31.3279 27.36L35.4079 16.2C35.5296 15.8664 35.5704 15.5086 35.5269 15.1562C35.4834 14.8037 35.3569 14.4666 35.1577 14.1726C34.9586 13.8786 34.6925 13.636 34.3813 13.4648C34.0702 13.2937 33.7228 13.1988 33.3679 13.188H25.9879L16.7599 38.508C12.898 37.5719 9.45442 35.3843 6.96609 32.2861C4.47776 29.188 3.08455 25.3535 3.00373 21.3807C2.9229 17.4078 4.15899 13.5198 6.51925 10.323C8.87952 7.12626 12.2313 4.80035 16.0519 3.708L6.89588 28.908H10.6999L15.5839 15.552H18.5359L13.5919 28.86H17.3959L22.0279 16.2C22.1426 15.8812 22.1832 15.5405 22.1467 15.2036C22.1101 14.8668 21.9974 14.5427 21.817 14.2559C21.6366 13.9691 21.3933 13.7271 21.1055 13.5483C20.8177 13.3696 20.4929 13.2586 20.1559 13.224H16.4479L20.1319 3H20.9359C23.2997 3 25.6403 3.46558 27.8242 4.37017C30.008 5.27475 31.9923 6.60062 33.6638 8.27208C35.3353 9.94353 36.6611 11.9278 37.5657 14.1117C38.4703 16.2956 38.9359 18.6362 38.9359 21Z"
        fill="#0391CF"
      />
    </svg>
  );
}
