export default function AlertIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99817 7.49824V10.8316M9.99817 14.1649H10.0065M8.84427 3.24134L1.99019 15.0802C1.61002 15.7369 1.41994 16.0652 1.44803 16.3347C1.47254 16.5697 1.59568 16.7833 1.78681 16.9222C2.00594 17.0816 2.38533 17.0816 3.1441 17.0816H16.8522C17.611 17.0816 17.9904 17.0816 18.2095 16.9222C18.4007 16.7833 18.5238 16.5697 18.5483 16.3347C18.5764 16.0652 18.3863 15.7369 18.0061 15.0802L11.1521 3.24134C10.7733 2.58704 10.5839 2.25989 10.3368 2.15001C10.1212 2.05416 9.87513 2.05416 9.65959 2.15001C9.41248 2.25989 9.22308 2.58704 8.84427 3.24134Z"
        stroke="#F70D0D"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
