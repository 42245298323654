import { useGetMyEsimsQuery } from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import styled from 'styled-components';
import {
  EsimEntityPlanName,
  SubscriptionPlanNameWithIcon,
} from '@front_common/Components/EsimPlanName';

type CurrentPlanProps = {
  esimId: string;
  title: string;
};

export function CurrentPlan(props: CurrentPlanProps) {
  let { esimId, title } = props;

  let [response] = useGetMyEsimsQuery();

  if (!response) {
    return <LoadingAnimation />;
  }

  let esim = response.esims.find((item) => item.id === Number(esimId));

  if (esim === undefined) {
    return <LoadingAnimation />;
  }

  return (
    <TitlePlan>
      {`${title} `}
      <span>
        {esim.subscriptionId ? (
          <SubscriptionPlanNameWithIcon amountGb={esim.dataPurchasedGb} />
        ) : (
          <EsimEntityPlanName esim={esim} />
        )}
      </span>
    </TitlePlan>
  );
}

let TitlePlan = styled.span`
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
  text-align: center;

  span {
    font-weight: var(--fontWeightBold);

    img {
      height: 24px;
    }
  }
`;
