export default function SimCard() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33203 5.66797C3.33203 4.26784 3.33203 3.56777 3.60451 3.03299C3.8442 2.56259 4.22665 2.18014 4.69706 1.94045C5.23183 1.66797 5.9319 1.66797 7.33203 1.66797H10.0085C10.62 1.66797 10.9257 1.66797 11.2134 1.73704C11.4685 1.79829 11.7124 1.8993 11.9361 2.03637C12.1884 2.19097 12.4046 2.40716 12.8369 2.83954L15.4938 5.4964C15.9262 5.92878 16.1424 6.14497 16.297 6.39726C16.434 6.62094 16.535 6.8648 16.5963 7.11989C16.6654 7.40761 16.6654 7.71335 16.6654 8.32482V14.3346C16.6654 15.7348 16.6654 16.4348 16.3929 16.9696C16.1532 17.44 15.7707 17.8225 15.3003 18.0622C14.7656 18.3346 14.0655 18.3346 12.6654 18.3346H7.33203C5.9319 18.3346 5.23183 18.3346 4.69706 18.0622C4.22665 17.8225 3.8442 17.44 3.60451 16.9696C3.33203 16.4348 3.33203 15.7348 3.33203 14.3346V5.66797Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66536 11.5013C6.66536 11.2679 6.66536 11.1513 6.71078 11.0621C6.75073 10.9837 6.81447 10.92 6.89287 10.88C6.982 10.8346 7.09868 10.8346 7.33203 10.8346H12.6654C12.8987 10.8346 13.0154 10.8346 13.1045 10.88C13.1829 10.92 13.2467 10.9837 13.2866 11.0621C13.332 11.1513 13.332 11.2679 13.332 11.5013V14.3346C13.332 14.568 13.332 14.6847 13.2866 14.7738C13.2467 14.8522 13.1829 14.9159 13.1045 14.9559C13.0154 15.0013 12.8987 15.0013 12.6654 15.0013H7.33203C7.09868 15.0013 6.982 15.0013 6.89287 14.9559C6.81447 14.9159 6.75073 14.8522 6.71078 14.7738C6.66536 14.6847 6.66536 14.568 6.66536 14.3346V11.5013Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
