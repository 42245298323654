export type SubscriptionType = {
  id: number;
  paymentSessionId: number; // no payment_id bc sub == payment
  subProductId: number;
  esimId: number | null;
  totalAmountCents: number;
  cardEnding: string | null;
  status: SubscriptionStatus;
  createdAt: Date;
  renewsAt: Date | null; // date by which internet have already been paid
  refundedAt: Date | null;
  cancelRequestedAt: Date | null;
  cancelledAt: Date | null;
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  UNPAID = 'unpaid',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
}

export default class MySubscriptionResponse {
  subscriptions: SubscriptionType[];
}
