/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */ // --> OFF
import {
  h,
  cloneElement,
  toChildArray,
  Component,
  createContext,
  createElement,
  VNode,
  ComponentType,
  ComponentChildren,
} from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';
import { Dispatch, SetStateAction } from 'react';

// ------------- EXPORTS -------------------
export { route, RouterWrapper, Route, useRouterState };
// ------------- EXPORTS -------------------

let MAIN_ROUTER_COMPONENT: RouterWrapper;

type RouteMatches = { [key: string]: string };

type RouteChild = VNode<any> & {
  index: number;
  rank: number;
  path: string;
  component: ComponentType<any>;
};

type RouterState = {
  url: string;
  previous?: string;
  path: string;
  matches: RouteMatches;
};

type StateType = RouterState | Record<string, never> | undefined;

const routeChangeSubscribers: Dispatch<SetStateAction<StateType>>[] = [];

const initialContext: RouterState = {
  url: getCurrentUrl(),
  path: '', // TODO
  matches: {},
};
const RouterContext = createContext<RouterState>(initialContext);

const Route = (props: any) => h(props.component, props);

type RouterComponentProps = {
  onChange: (router: RouterState) => void | Promise<void>;
  children: ComponentChildren;
};

type RouterComponentState = {
  url: string;
};

class RouterWrapper extends Component<
  RouterComponentProps,
  RouterComponentState
> {
  private isUpdating: boolean;
  private contextValue: RouterState;

  constructor() {
    super();
    this.state = {
      url: getCurrentUrl(),
    };
    MAIN_ROUTER_COMPONENT = this;
  }

  canRoute(url: string) {
    // @ts-ignore
    const children: RouteChild[] = toChildArray(this.props.children);
    return getMatchingChild(children, url) !== false;
  }

  routeTo(url: string) {
    this.setState({ url });
    const didRoute = this.canRoute(url);
    // trigger a manual re-route if we're not in the middle of an update:
    if (!this.isUpdating) {
      // React reload
      this.forceUpdate();
    }
    return didRoute;
  }

  componentWillMount() {
    this.isUpdating = true;
  }

  componentDidMount() {
    initEventListeners();
    this.isUpdating = false;
  }

  componentWillUpdate() {
    this.isUpdating = true;
  }

  componentDidUpdate() {
    this.isUpdating = false;
  }

  render(
    { children, onChange }: RouterComponentProps,
    { url }: RouterComponentState,
  ) {
    let ctx = this.contextValue;
    let uniqueKey = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;

    // @ts-ignore
    let active = getMatchingChild(toChildArray(children), url);
    let matches: RouteMatches = {};
    let renderPage;
    if (active) {
      matches = active[1];
      let renderProps = assign(assign({ url, matches }, matches), {
        key: uniqueKey,
        ref: undefined,
      });

      renderPage = cloneElement(active[0], renderProps);
    }

    if (url !== (ctx && ctx.url)) {
      let newCtx: RouterState = {
        url,
        previous: ctx && ctx.url,
        // @ts-ignore TODO do we need this?
        current: renderPage,
        path: renderPage ? renderPage.props.path : null,
        matches,
      };

      // only copy simple properties to the global context:
      assign(initialContext, (ctx = this.contextValue = newCtx));

      // these are only available within the subtree of a Router:
      // @ts-ignore TODO do we need this?
      ctx.router = this;
      // @ts-ignore TODO do we need this?
      ctx.active = renderPage ? [renderPage] : [];

      // notify useRouter subscribers outside this subtree:
      for (let i = routeChangeSubscribers.length; i--; )
        routeChangeSubscribers[i]?.({});

      if (typeof onChange === 'function') {
        onChange(ctx);
      }
    }

    return createElement(
      RouterContext.Provider,
      {
        value: ctx,
      },
      renderPage,
    );
  }
}

function useRouterState() {
  const ctx = useContext(RouterContext);
  // Note: this condition can't change without a remount, so it's a safe conditional hook call
  if (ctx === initialContext) {
    const update = useState<StateType>()[1];
    useEffect(() => {
      routeChangeSubscribers.push(update);
      return () =>
        routeChangeSubscribers.splice(
          routeChangeSubscribers.indexOf(update),
          1,
        );
    }, []);
  }
  return ctx;
}

function getCurrentUrl() {
  return `${window.location.pathname || ''}${window.location.search || ''}`;
}

function route(url: string, replace = false) {
  if (MAIN_ROUTER_COMPONENT.canRoute(url)) {
    const type = replace ? 'replace' : 'push';
    if (typeof history !== 'undefined' && history[`${type}State`]) {
      // @ts-ignore
      history[`${type}State`](null, null, url);
    }
  }
  return routeTo(url);
}

function routeTo(url: string): boolean {
  return MAIN_ROUTER_COMPONENT.routeTo(url);
}

let eventListenersInitialized = false;

function initEventListeners() {
  if (eventListenersInitialized) return;
  eventListenersInitialized = true;

  addEventListener('popstate', () => {
    routeTo(getCurrentUrl());
  });
}

function getMatchingChild(
  children: RouteChild[],
  url: string,
): [RouteChild, RouteMatches] | false {
  children = children
    .filter((vnode: RouteChild, index: number) => {
      vnode.index = index;
      vnode.rank = rankChild(vnode);
      return vnode.props;
    })
    .sort(pathRankSort);
  for (let vnode of children) {
    let matches = matchUrlWithRoute(url, vnode.props.path, vnode.props);
    if (matches) return [vnode, matches];
  }
  return false;
}

function assign(obj: any, props: any) {
  // eslint-disable-next-line guard-for-in
  for (let i in props) {
    obj[i] = props[i];
  }
  return obj;
}

function matchUrlWithRoute(
  urlOriginal: string,
  routeOriginal: string,
  opts: { path?: string; default?: any },
) {
  let reg = /(?:\?([^#]*))?(#.*)?$/;
  let c = urlOriginal.match(reg);
  let matches: RouteMatches = {};
  let ret;

  if (c && c[1]) {
    let p = c[1].split('&');
    for (let i = 0; i < p.length; i++) {
      // @ts-ignore
      let r = p[i].split('=');
      // @ts-ignore
      matches[decodeURIComponent(r[0])] = decodeURIComponent(
        r.slice(1).join('='),
      );
    }
  }
  let url = segmentize(urlOriginal.replace(reg, ''));
  let route = segmentize(routeOriginal || '');
  let max = Math.max(url.length, route.length);
  for (let i = 0; i < max; i++) {
    // @ts-ignore
    if (route[i] && route[i].charAt(0) === ':') {
      // @ts-ignore
      let param = route[i].replace(/(^:|[+*?]+$)/g, ''),
        // @ts-ignore
        flags = (route[i].match(/[+*?]+$/) || {})[0] || '',
        plus = ~flags.indexOf('+'),
        star = ~flags.indexOf('*'),
        val = url[i] || '';
      if (!val && !star && (flags.indexOf('?') < 0 || plus)) {
        ret = false;
        break;
      }
      matches[param] = decodeURIComponent(val);
      if (plus || star) {
        matches[param] = url.slice(i).map(decodeURIComponent).join('/');
        break;
      }
    } else if (route[i] !== url[i]) {
      ret = false;
      break;
    }
  }
  if (opts.default !== true && ret === false) return false;
  return matches;
}

function pathRankSort(
  a: { rank: number; index: number },
  b: { rank: number; index: number },
) {
  return a.rank < b.rank ? 1 : a.rank > b.rank ? -1 : a.index - b.index;
}

function segmentize(url: string) {
  return url.replace(/(^\/+|\/+$)/g, '').split('/');
}

function rankSegment(segment: string) {
  return segment.charAt(0) == ':'
    ? 1 + '*+?'.indexOf(segment.charAt(segment.length - 1)) || 4
    : 5;
}

function rank(path: string) {
  return Number(segmentize(path).map(rankSegment).join(''));
}

function rankChild(vnode: RouteChild) {
  return vnode.props.default ? 0 : rank(vnode.props.path);
}
