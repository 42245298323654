import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import VisitorApi from '@front_common/Api/VisitorApi';
import { routeHardRedirectWithReload } from '@front_common/Router/Router';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

type Props = {
  onErrorTextChange: (errorMessage: string) => void;
  onLoaderChange: (loading: boolean) => void;
  onAuthSuccess?: (() => void) | undefined;
  marketingConsent?: boolean;
};

function onGoogleAuthError() {
  logger.error('Something went wrong with Google Auth');
}
export default function GoogleAuth({
  onAuthSuccess,
  onLoaderChange,
  onErrorTextChange,
  marketingConsent = false,
}: Props) {
  async function handleGoogleLogin(credentialResponse: CredentialResponse) {
    onLoaderChange(true);

    try {
      await VisitorApi.authByGoogle(
        credentialResponse.credential!,
        marketingConsent,
      );
      if (onAuthSuccess) {
        onAuthSuccess();
      } else {
        routeHardRedirectWithReload(WEB_ROUTE_HOME);
      }
    } catch (error) {
      if (error instanceof Error) {
        onErrorTextChange(error.message);
      }
      logger.error(error);
    } finally {
      onLoaderChange(false);
    }
  }

  return (
    <GoogleLogin
      type="icon"
      onSuccess={handleGoogleLogin}
      onError={onGoogleAuthError}
    />
  );
}
