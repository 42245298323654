import SecureSVG from './Icons/SecureSVG';
import styled from 'styled-components';

export default function RiskFree() {
  return (
    <RiskFreeWrapper>
      <SecureIconContainer>
        <Secure />
      </SecureIconContainer>
      <ContentContainer>
        <Title>Money Back Guarantee</Title>
        <Description>
          Not used your data? Get a full refund, no questions asked. Your
          satisfaction, guaranteed.
        </Description>
      </ContentContainer>
    </RiskFreeWrapper>
  );
}

let RiskFreeWrapper = styled.div`
  border-radius: 20px;
  padding: 16px;
  text-align: center;
  margin: 16px;
  background: var(--backgroundSecondaryNew);
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 24px 32px;
    margin: 32px;
  }

  @media (min-width: 960px) {
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 48px auto;
  }
`;

let SecureIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px auto;
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  background-color: var(--iconBackgroundNew);
  border-radius: 50%;

  @media (min-width: 700px) {
    width: 112px;
    min-width: 112px;
    min-height: 112px;
    margin: 0 32px 0 0;

    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

let Secure = styled(SecureSVG)`
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin: 0 auto 20px;
  color: #303c42;

  @media (min-width: 700px) {
    margin: initial;
  }
`;

let ContentContainer = styled.div``;

let Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 6px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    margin-bottom: 8px;
  }
`;

let Description = styled.span`
  opacity: 75%;
  line-height: 20px;
  font-size: var(--fontSizeRegular);
`;
