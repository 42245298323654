import { CONFIG_API_HOST } from '@front_common/Config';
import {
  deepObjectFreeze,
  ReadonlyDeep,
} from '@shared_frontend/Common/ObjectFreeze';
import {
  API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN,
  API_ROUTE_GEO_ACTIVE_COUNTRIES,
  API_ROUTE_SESSION,
} from '@shared_backend/ApiRoutes';
import { logoutAndRedirect } from '@front_common/Router/Router';
import { sleep } from '@shared_frontend/Common/Async';
import { transformDates } from '@shared_frontend/Common/Date';

const SKIP_WAIT_SESSION_ROUTES = [
  API_ROUTE_SESSION,
  API_ROUTE_GEO_ACTIVE_COUNTRIES,
  API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN,
];
export default class AbstractApi {
  private static headersForAll: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };

  private static cache: { [key: string]: { result: any; expires: number } } =
    {};
  private static requestsInProgress: { [key: string]: Promise<any> } = {};
  private static sessionRequestInProgress: Promise<any> | null = null;

  protected static setHeaderForAll(name: string, value: string) {
    this.headersForAll[name] = value;
  }

  protected static async get<T>(route: string, cacheSeconds = 5) {
    let cacheKey = 'GET_' + route;
    let cachedData = this.cache[cacheKey];
    if (cachedData) {
      if (cachedData.expires > Date.now() / 1000) {
        return cachedData.result;
      }
      delete this.cache[cacheKey];
    }
    if (cacheSeconds !== 0 && this.requestsInProgress[cacheKey]) {
      return this.requestsInProgress[cacheKey];
    }
    let promise = this.request<T>(route, { method: 'GET' });
    if (cacheSeconds !== 0) {
      this.requestsInProgress[cacheKey] = promise;
    }
    let result;
    try {
      result = await promise;
    } catch (error) {
      delete this.cache[cacheKey];
      delete this.requestsInProgress[cacheKey];
      throw error;
    }
    if (cacheSeconds !== 0) {
      this.cache[cacheKey] = {
        result,
        expires: Date.now() / 1000 + cacheSeconds,
      };
    }
    if (this.requestsInProgress[cacheKey]) {
      delete this.requestsInProgress[cacheKey];
    }
    return result;
  }

  protected static async post<T>(route: string, body?: object | null) {
    return this.request<T>(route, {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
    });
  }

  protected static async request<T>(
    route: string,
    options: RequestInit,
  ): Promise<ReadonlyDeep<T>> {
    options.headers = this.headersForAll;
    options.headers['X-Client-Url'] = window.location.href;
    let url = CONFIG_API_HOST + route;
    let response: Response;

    if (
      !this.sessionRequestInProgress &&
      !SKIP_WAIT_SESSION_ROUTES.includes(route)
    ) {
      throw new Error(
        'Session Request should be started first. Route ' + route,
      );
    }

    try {
      if (route === API_ROUTE_SESSION) {
        let promise = fetch(url, options);
        this.sessionRequestInProgress = promise;
        response = await promise;
      } else {
        if (!SKIP_WAIT_SESSION_ROUTES.includes(route)) {
          await this.sessionRequestInProgress;
        }
        response = await fetch(url, options);
      }
    } catch (error: any) {
      if (options.method === 'GET') {
        // ------------- Retry ---------------
        await sleep(1);
        try {
          response = await fetch(url, options);
        } catch {
          throw new Error('Network Error: ' + (error['message'] ?? ''));
        }
      } else {
        throw new Error('Network Error: ' + (error['message'] ?? ''));
      }
    }

    if (!response.ok) {
      if (response.status === 401) {
        logoutAndRedirect();
      }
      let message;
      if (response.headers.get('X-Error-Message')) {
        message = String(response.headers.get('X-Error-Message'));
      } else {
        message = 'Internal error';
      }
      throw new Error(message);
    }

    let data = await response.json();
    transformDates(data);
    return deepObjectFreeze(data);
  }
}
