import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';
import Modal, {
  ModalCloseButton,
} from '@front_common/Components/Modal/OldDesign/Modal';
import CloseIcon from '@front_common/Components/Modal/OldDesign/CloseIcon';

//TODO: no need to export these constants
export const HEADER_CLASS_NAME = 'header-navigation';
export const SHORT_FOOTER_CLASS_NAME = 'short-footer';
export const BURGER_MENU_CLASS_NAME = 'burger-menu';

type IProps = {
  isOpen: boolean;
  header: string;
  onClose: () => void;
  children: React.ReactNode;
};

export default function CheckoutModal(props: IProps) {
  let { isOpen, onClose, header, children } = props;

  let windowDimensions = useWindowDimensions();

  let [isModalOpen, setIsModalOpen] = useState(isOpen);
  let [initialHeaderStyle, setInitialHeaderStyle] = useState('');
  let [initialFooterStyle, setInitialFooterStyle] = useState('');
  let [initialBurgerStyle, setInitialBurgerStyle] = useState('');

  //TOOD: check if you can reuse the useDisplayingAllElements function
  useEffect(() => {
    let headerNav = document.querySelector<HTMLElement>(
      `.${HEADER_CLASS_NAME}`,
    );
    let footer = document.querySelector<HTMLElement>(
      `.${SHORT_FOOTER_CLASS_NAME}`,
    );
    let burgerMenu = document.querySelector<HTMLElement>(
      `.${BURGER_MENU_CLASS_NAME}`,
    );

    // Store initial styles when component mounts
    if (headerNav && footer && burgerMenu) {
      setInitialHeaderStyle(headerNav.style.display);
      setInitialFooterStyle(footer.style.paddingBottom);
      setInitialBurgerStyle(burgerMenu.style.display);
    }
  }, []);

  useEffect(() => {
    setIsModalOpen(isOpen);

    let headerNav = document.querySelector<HTMLElement>(
      `.${HEADER_CLASS_NAME}`,
    );
    let footer = document.querySelector<HTMLElement>(
      `.${SHORT_FOOTER_CLASS_NAME}`,
    );
    let burgerMenu = document.querySelector<HTMLElement>(
      `.${BURGER_MENU_CLASS_NAME}`,
    );

    if (!headerNav || !footer || !burgerMenu) {
      return;
    }

    if (isOpen) {
      // Apply new styles
      headerNav.style.display = 'none';
      footer.style.paddingBottom = 'initial';
      burgerMenu.style.display = 'none';
    } else {
      // Restore original styles
      headerNav.style.display = initialHeaderStyle;
      footer.style.paddingBottom = initialFooterStyle;
      burgerMenu.style.display = initialBurgerStyle;
    }
  }, [isOpen]);

  function closeModal() {
    onClose();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      overlayClick={false}
      overlayStyles={{ display: 'block', overflowY: 'auto' }}
      windowStyles={{
        maxHeight: 'initial',
        margin:
          windowDimensions.width >= 393
            ? '86px auto'
            : // eslint-disable-next-line unicorn/no-nested-ternary
              windowDimensions.width >= 700
              ? '100px auto'
              : '36px auto 14px auto',
      }}
      analyticType="checkout"
    >
      <ModalContent>
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <Top>
          <Title>{header}</Title>
        </Top>
        <Bottom>{children}</Bottom>
      </ModalContent>
    </Modal>
  );
}

let ModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

//TODO: do you really need to make the new Close button? The Modal is already have a close button
let CloseButton = styled(ModalCloseButton)`
  top: 4px;
  right: 4px;
  background-color: var(--orange70);
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 32px 20px 20px;
  text-align: center;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Bottom = styled.div`
  padding: 16px;
  background-color: var(--backgroundPrimary);

  @media (min-width: 393px) {
    padding: 20px;
  }
`;
