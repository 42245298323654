import styled from 'styled-components';
import WatchImage from './Images/Watch.png';
import BusAnimationImage from './Images/BusAnimation.gif';

export default function WaitContent() {
  return (
    <WaitContentStyled>
      <Top>
        <img loading="lazy" src={BusAnimationImage} alt="bus animation" />
      </Top>
      <Bottom>
        <Title>
          Sorry for the wait!
          <img src={WatchImage} alt="watch" />
        </Title>
        <SubTitleFirst>
          {`It's taking us a while to get your eSIM. It may take another minute or
          two.`}
        </SubTitleFirst>
        <SubTitleSecond>
          Your eSIM will also be <b>delivered via email</b>. So feel free to
          wait here or check your inbox in a few minutes.
        </SubTitleSecond>
      </Bottom>
    </WaitContentStyled>
  );
}

let WaitContentStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 20px 0;

  img {
    width: 100%;
  }
`;

let Bottom = styled.div`
  padding: 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  img {
    width: 32px;
    margin-bottom: -5px;
  }

  @media (min-width: 393px) {
    img {
      margin-left: 8px;
    }
  }

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;

    img {
      margin-left: 12px;
    }
  }
`;

let SubTitleFirst = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
  margin-bottom: 4px;
`;

let SubTitleSecond = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;

  b {
    font-weight: var(--fontWeightBold);
  }
`;
