import { CONFIG_GOOGLE_TAG_ID } from '@front_common/Config';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const GoogleAdsPixel = {
  trackFirstPayment,
  trackRebillPayment,
  trackSubscriptionPayment,
  trackPayment,
};

export function initGoogleAdsConfig() {
  window.dataLayer ||= [];

  //@ts-ignore
  gtag('js', new Date());
  //@ts-ignore
  gtag('config', CONFIG_GOOGLE_TAG_ID);
}

function trackFirstPayment(
  totalAmountOfDollars: number,
  deduplicationId: number,
) {
  //@ts-ignore
  gtag('event', 'conversion', {
    send_to: `${CONFIG_GOOGLE_TAG_ID}/DsOnCJ7Thb8ZEKi8s-Y9`,
    value: totalAmountOfDollars,
    currency: 'USD',
    transaction_id: deduplicationId,
  });
}

function trackRebillPayment(
  totalAmountOfDollars: number,
  deduplicationId: number,
) {
  //@ts-ignore
  gtag('event', 'conversion', {
    send_to: `${CONFIG_GOOGLE_TAG_ID}/nRRICIGpm4caEKi8s-Y9`,
    value: totalAmountOfDollars,
    currency: 'USD',
    transaction_id: deduplicationId,
  });
}

function trackSubscriptionPayment(deduplicationId: number) {
  //@ts-ignore
  gtag('event', 'conversion', {
    send_to: `${CONFIG_GOOGLE_TAG_ID}/Za96CNf8xYEaEKi8s-Y9`,
    value: 30,
    currency: 'USD',
    transaction_id: deduplicationId,
  });
}

function trackPayment(
  totalAmountOfDollars: number,
  deduplicationId: number,
  isRebill: boolean,
  isCountryPlan: boolean,
) {
  if (isCountryPlan) {
    //country plan
    if (isRebill) {
      trackRebillPayment(totalAmountOfDollars, deduplicationId);
    } else {
      trackFirstPayment(totalAmountOfDollars, deduplicationId);
    }
  } else {
    //subscription plan
    trackSubscriptionPayment(deduplicationId);
  }
}
