export default function TwoLayersIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4875 2.1543H7.2625C6.82871 2.15594 6.41317 2.32899 6.10643 2.63573C5.7997 2.94246 5.62665 3.35801 5.625 3.7918V5.9043H3.5125C3.07871 5.90594 2.66317 6.07899 2.35643 6.38573C2.0497 6.69246 1.87665 7.10801 1.875 7.5418V16.7668C1.87665 17.2006 2.0497 17.6161 2.35643 17.9229C2.66317 18.2296 3.07871 18.4027 3.5125 18.4043H12.7375C13.1713 18.4027 13.5868 18.2296 13.8936 17.9229C14.2003 17.6161 14.3734 17.2006 14.375 16.7668V14.6543H16.4875C16.9213 14.6527 17.3368 14.4796 17.6436 14.1729C17.9503 13.8661 18.1234 13.4506 18.125 13.0168V3.7918C18.1234 3.35801 17.9503 2.94246 17.6436 2.63573C17.3368 2.32899 16.9213 2.15594 16.4875 2.1543ZM13.125 16.7668C13.125 16.8696 13.0842 16.9681 13.0115 17.0408C12.9388 17.1135 12.8403 17.1543 12.7375 17.1543H3.5125C3.40973 17.1543 3.31117 17.1135 3.2385 17.0408C3.16583 16.9681 3.125 16.8696 3.125 16.7668V7.5418C3.125 7.43903 3.16583 7.34046 3.2385 7.26779C3.31117 7.19512 3.40973 7.1543 3.5125 7.1543H12.7375C12.8403 7.1543 12.9388 7.19512 13.0115 7.26779C13.0842 7.34046 13.125 7.43903 13.125 7.5418V16.7668ZM16.875 13.0168C16.875 13.1196 16.8342 13.2181 16.7615 13.2908C16.6888 13.3635 16.5903 13.4043 16.4875 13.4043H14.375V7.5418C14.3734 7.10801 14.2003 6.69246 13.8936 6.38573C13.5868 6.07899 13.1713 5.90594 12.7375 5.9043H6.875V3.7918C6.875 3.68903 6.91583 3.59046 6.9885 3.51779C7.06117 3.44512 7.15973 3.4043 7.2625 3.4043H16.4875C16.5903 3.4043 16.6888 3.44512 16.7615 3.51779C16.8342 3.59046 16.875 3.68903 16.875 3.7918V13.0168Z"
        fill="black"
      />
    </svg>
  );
}
