export default function GlobeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3116_247)">
        <path
          d="M11 17H6.086L0.045 10.959C0.016 11.3 0 11.649 0 12C6.5391e-05 15.0094 1.1309 17.909 3.16833 20.1239C5.20575 22.3387 8.00102 23.7072 11 23.958V17Z"
          fill="#B3B3B3"
        />
        <path
          d="M19.126 19.1242C18.4508 18.4529 17.9153 17.6544 17.5504 16.7749C17.1855 15.8954 16.9984 14.9524 17 14.0002H13C12.2044 14.0002 11.4413 13.6841 10.8787 13.1215C10.3161 12.5589 10 11.7959 10 11.0002V8.00022H13V4.00022H17.086L18.9 2.18322C17.3428 1.08932 15.5479 0.381428 13.6632 0.117987C11.7785 -0.145455 9.85824 0.043104 8.06082 0.6681C6.26339 1.2931 4.64038 2.33661 3.32574 3.7125C2.01111 5.08839 1.04254 6.75721 0.5 8.58122L6.914 15.0002H10C10.7956 15.0002 11.5587 15.3163 12.1213 15.8789C12.6839 16.4415 13 17.2046 13 18.0002V23.9582C15.928 23.716 18.6647 22.4055 20.689 20.2762L19.126 19.1242Z"
          fill="#B3B3B3"
        />
        <path
          d="M20.489 17.6572L21.943 18.7192C23.5094 16.4014 24.2167 13.6091 23.9429 10.8251C23.669 8.04113 22.431 5.44029 20.443 3.47217L17.914 6.00017H15V10.0002H12V12.0002H19V13.9902C19.0042 15.3589 19.5378 16.673 20.489 17.6572Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3116_247">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
