export default function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.003 1.66931C7.79431 1.6718 5.67664 2.54971 4.11396 4.11062C2.55128 5.67153 1.67102 7.7882 1.66602 9.99691C1.66935 12.207 2.54878 14.3256 4.11155 15.8884C5.67432 17.4512 7.79293 18.3306 10.003 18.3339C12.2123 18.3298 14.3298 17.4498 15.8913 15.887C17.4529 14.3243 18.3312 12.2062 18.3337 9.99691C18.3312 7.78823 17.4527 5.67075 15.891 4.10899C14.3292 2.54722 12.2117 1.66864 10.003 1.66614V1.66931ZM10.003 3.33235C10.8789 3.32986 11.7467 3.50053 12.5564 3.83457C13.3661 4.16861 14.1018 4.65939 14.7211 5.27875C15.3405 5.89811 15.8313 6.63382 16.1654 7.44352C16.4994 8.25323 16.6701 9.12101 16.6676 9.99691C16.6701 10.8731 16.4994 11.7412 16.1655 12.5513C15.8315 13.3613 15.3408 14.0973 14.7215 14.7172C14.1022 15.337 13.3666 15.8285 12.5569 16.1632C11.7471 16.4979 10.8792 16.6694 10.003 16.6677C9.12679 16.669 8.25893 16.4973 7.4492 16.1624C6.63947 15.8276 5.90379 15.3362 5.28434 14.7165C4.6649 14.0968 4.17387 13.3609 3.83942 12.551C3.50496 11.7411 3.33365 10.8731 3.33531 9.99691C3.33531 6.30557 6.31169 3.33235 10.003 3.33235ZM10.003 5.00164C9.78216 5.00245 9.57049 5.0901 9.41373 5.24568C9.25697 5.40126 9.16771 5.61231 9.16523 5.83316C9.16523 6.2867 9.54948 6.66775 10.003 6.66775C10.2244 6.66775 10.4367 6.57985 10.5932 6.42332C10.7497 6.2668 10.8377 6.05452 10.8377 5.83316C10.8377 5.6118 10.7497 5.39942 10.5932 5.24289C10.4367 5.08637 10.2244 4.99847 10.003 4.99847V5.00164ZM9.99042 8.31811C9.96199 8.31875 9.93361 8.3209 9.90539 8.32445C9.69914 8.34792 9.50904 8.44741 9.37219 8.60348C9.23533 8.75955 9.16155 8.96102 9.16523 9.16856V14.1701C9.16523 14.3914 9.25316 14.6038 9.40969 14.7603C9.56622 14.9169 9.77851 15.0048 9.99987 15.0048C10.2212 15.0048 10.4335 14.9169 10.5901 14.7603C10.7466 14.6038 10.8345 14.3914 10.8345 14.1701V9.16856C10.8366 9.05684 10.8163 8.94576 10.7747 8.84205C10.7331 8.73833 10.6711 8.64405 10.5924 8.56474C10.5137 8.48544 10.4198 8.42275 10.3164 8.38039C10.213 8.33803 10.1022 8.31682 9.99042 8.31811Z"
        fill="black"
      />
    </svg>
  );
}
