import styled, { css, keyframes } from 'styled-components';
import PartySmileImage from './Images/PartySmile.png';
import { useEffect, useRef, useState } from 'preact/hooks';
import useAnimatedNumber from '@front_common/Components/UseAnimatedNumber';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';

const PACKAGE_SIZES = [1, 2, 3, 5, 10, 20, 30];

function getCurrentValue() {
  let now = new Date();
  let secondsInADay = 86_400; // Number of seconds in a day
  let maxNumber = 5000;
  let minNumber = 200;

  // We get the number of seconds since the beginning of the day
  let secondsSinceMidnight =
    now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

  // We calculate the current number taking into account the minimum value
  let currentValue = Math.ceil(
    (secondsSinceMidnight / secondsInADay) * (maxNumber - minNumber + 1) +
      minNumber,
  );

  return currentValue;
}

function generateMaskedUsername() {
  // eslint-disable-next-line secrets/no-secrets
  let alphabet = 'abcdefghijklmnopqrstuvwxyz';
  let usernameLength = Math.floor(Math.random() * 6) + 5;

  let username = '';
  for (let index = 0; index < usernameLength; index++) {
    let randomIndex = Math.floor(Math.random() * alphabet.length);
    username += alphabet[randomIndex];
  }

  let len = username.length;
  let maskedUsername = '';
  let maskStreak = 0;
  let hasMasked = false;

  maskedUsername += username[0];

  for (let index = 1; index < len - 1; index++) {
    if (maskStreak < 2 && Math.random() > 0.5) {
      maskedUsername += '*';
      maskStreak++;
      hasMasked = true;
    } else {
      maskedUsername += username[index];
      maskStreak = 0;
    }
  }

  maskedUsername += username[len - 1];

  if (!hasMasked) {
    let randomIndexToMask = Math.floor(Math.random() * (len - 2)) + 1;
    maskedUsername =
      maskedUsername.slice(0, randomIndexToMask) +
      '*' +
      maskedUsername.slice(randomIndexToMask + 1);
  }

  return maskedUsername;
}

function getRandomPackageSize() {
  return PACKAGE_SIZES[Math.floor(Math.random() * PACKAGE_SIZES.length)] || 1;
}

function getRandomCountry() {
  let randomCountryCode =
    POPULAR_COUNTRIES[Math.floor(Math.random() * POPULAR_COUNTRIES.length)];

  return randomCountryCode && countryNameByCode(randomCountryCode);
}

export default function SocialTrust() {
  let [userCount, setUserCount] = useState(getCurrentValue());
  let [maskedUsername, setMaskedUsername] = useState('');
  let [userCountry, setUserCountry] = useState('');
  let [userPackage, setUserPackage] = useState(0);
  let [descriptionHeight, setDescriptionHeight] = useState<number | null>(null);
  let [isAnimating, setIsAnimating] = useState(false);

  let descriptionRef = useRef<HTMLDivElement | null>(null);

  let animatedPeopleJoined = useAnimatedNumber(userCount);

  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionHeight(descriptionRef.current.scrollHeight);
    }
  }, [maskedUsername, userCountry, userPackage]);

  useEffect(() => {
    setMaskedUsername(generateMaskedUsername());
    setUserCountry(getRandomCountry() || '');
    setUserPackage(getRandomPackageSize());

    let purchaseIntervalId = setInterval(() => {
      setIsAnimating(true);

      let purchaseTimeoutId = setTimeout(() => {
        setMaskedUsername(generateMaskedUsername());
        setUserCountry(getRandomCountry() || '');
        setUserPackage(getRandomPackageSize());

        clearTimeout(purchaseTimeoutId);
        setIsAnimating(false);
      }, 1000);
    }, 17_000);

    let countIntervalId = setInterval(() => {
      setUserCount((prevCount) => prevCount + 1);
    }, 17_000);

    return () => {
      clearInterval(purchaseIntervalId);
      clearInterval(countIntervalId);
    };
  }, []);

  return (
    <SocialTrustStyled>
      <FirstRow>
        <img src={PartySmileImage} alt="party smile" />
        <span>{animatedPeopleJoined} people joined today:</span>
      </FirstRow>
      <div
        style={{
          height: descriptionHeight ? `${descriptionHeight}px` : 'auto',
        }}
      >
        <SecondRow $slideIn={!isAnimating} ref={descriptionRef}>
          <span>
            User {maskedUsername} purchased {userPackage}GB in {userCountry}
          </span>
        </SecondRow>
      </div>
    </SocialTrustStyled>
  );
}

let slideIn = keyframes`
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
`;

let slideOut = keyframes`
  from {
      transform: translateY(0);
      opacity: 1;
  }
  to {
      transform: translateY(-100%);
      opacity: 0;
  }
`;

let SocialTrustStyled = styled.div`
  background-color: var(--lightBlue80);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  position: relative;
  min-height: 100px;
`;

let FirstRow = styled.span`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    margin-right: 6px;
  }

  span {
    font-size: var(--fontSizeRegular);
    font-weight: var(--fontWeightBold);
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
  }
`;

let SecondRow = styled.div<{ $slideIn: boolean }>`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  position: absolute;
  top: 49px;
  width: calc(100% - 32px);
  transition:
    transform 0.5s ease-in-out,
    opacity 1s ease-in-out;
  ${(props) =>
    props.$slideIn
      ? css`
          animation: ${slideIn} 0.3s forwards ease-in-out;
        `
      : css`
          animation: ${slideOut} 0.3s forwards ease-in-out;
        `};

  span {
    font-size: var(--fontSizeSmall);
    line-height: 20px;
  }
`;
