export default function Support() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12266_77750)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.018 18.1719C12.018 17.4806 11.4576 16.9202 10.7663 16.9202C10.2788 16.9202 9.72121 16.9202 9.23367 16.9202C8.90172 16.9202 8.58336 17.0521 8.34863 17.2868C8.11391 17.5216 7.98203 17.8399 7.98203 18.1719C7.98203 18.8631 8.54242 19.4235 9.23367 19.4235C9.72121 19.4235 10.2788 19.4235 10.7663 19.4235C11.4576 19.4235 12.018 18.8631 12.018 18.1719ZM2.63129 14.9444C2.84035 14.9604 3.09328 14.9696 3.34734 14.9547C3.4827 15.6411 3.81895 16.2777 4.32086 16.7797C4.98719 17.446 5.8909 17.8203 6.8332 17.8203H7.31078C7.28973 17.9355 7.27891 18.0532 7.27891 18.1719C7.27891 18.2919 7.28973 18.4095 7.31047 18.5234H6.8332C5.70441 18.5234 4.62184 18.075 3.82367 17.2769C3.18691 16.6401 2.77273 15.8223 2.63129 14.9444ZM2.27363 14.1962C1.71852 14.1027 1.20195 13.8387 0.798711 13.4355C0.287305 12.9241 0 12.2304 0 11.5072C0 10.9759 0 10.3767 0 9.84535C0 9.12211 0.287305 8.42848 0.798711 7.91707C1.31012 7.40566 2.00375 7.11832 2.72699 7.11832H2.90852C3.19867 3.45598 6.26281 0.574219 10 0.574219C13.7372 0.574219 16.8013 3.45598 17.0915 7.11832H17.273C17.9963 7.11832 18.6899 7.40566 19.2013 7.91707C19.7127 8.42848 20 9.12211 20 9.84535V11.5072C20 12.2304 19.7127 12.9241 19.2013 13.4355C18.6899 13.9469 17.9963 14.2342 17.273 14.2342H16.4683C16.1345 14.2342 15.864 13.9636 15.864 13.6299V7.6882C15.864 4.44961 13.2386 1.82422 10 1.82422C6.76141 1.82422 4.13602 4.44961 4.13602 7.6882C4.13602 7.6882 4.13602 12.0092 4.13602 13.6299C4.13602 13.8655 4.00113 14.0697 3.80434 14.1694C3.26262 14.3573 2.4225 14.2214 2.27363 14.1962Z"
          fill="#203ADB"
        />
      </g>
      <defs>
        <clipPath id="clip0_12266_77750">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
