import styled from 'styled-components';
import RightIcon from './Icons/RightIcon';
import { content } from './Content';
import { ScrollToTopButton } from '@front_common/Components/ScrollToTopButton';
import PageTitleText from '@front_common/Components/PageTitleText';

function getStars(count: number) {
  return Array.from({ length: count });
}

function scrollToBlock(blockName: string) {
  let blockElement = document.querySelector(
    `#${blockName.replaceAll(/\s/gu, '')}`,
  );

  if (blockElement) {
    blockElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export default function CompatibilityGuidePage() {
  return (
    <CompatibilityGuidePageContainer>
      <BackButtonContainer>
        <BackButton onClick={() => history.back()}>
          <RightIcon />
        </BackButton>
      </BackButtonContainer>
      <Title data-testid="compatible-guide-page-title">
        <PageTitleText>eSIM Compatible Devices</PageTitleText>
      </Title>{' '}
      <Description>
        {`To use an eSIM, your device must be carrier-unlocked and eSIM-compatible. Please refer to the list below to see if your device supports eSIM technology. If you don’t see your device in this list, then unfortunately it does not support eSIM. However, to make sure you can check in manually:
Go to Settings → Connections → SIM Card Manager.
If you have the option to “Add mobile plan”, your smartphone supports eSIMs.`}
      </Description>
      <BrandButtons>
        {content.map((item) => (
          <BrandButton
            key={item.brand}
            onClick={() => scrollToBlock(item.brand)}
          >
            {item.brand}
          </BrandButton>
        ))}
      </BrandButtons>
      <BrandList>
        {content.map((item) => (
          <BrandBox key={item.brand} id={item.brand.replaceAll(/\s/gu, '')}>
            <BrandTitle>
              <Subtitle>eSIM compatible devices: </Subtitle>
              <Brand>{item.brand}</Brand>
            </BrandTitle>
            <BrandCard>
              {item.logo && <Logo>{item.logo}</Logo>}
              <DeviceList>
                {item.devices.map((device) => (
                  <DeviceName key={device}>{device}</DeviceName>
                ))}
              </DeviceList>
              {item.footnotes && (
                <AdditionalInfo>
                  {item.footnotes?.map((footnote, index) => (
                    <Footnote key={index}>
                      <Symbol>{getStars(index + 1).map(() => '*')} </Symbol>
                      <FootnoteText>{footnote}</FootnoteText>
                    </Footnote>
                  ))}
                </AdditionalInfo>
              )}
              {item.note && (
                <AdditionalInfo>
                  <Note>
                    <NoteTitle>NOTE: </NoteTitle>
                    <NoteText>{item.note}</NoteText>
                  </Note>
                </AdditionalInfo>
              )}
            </BrandCard>
          </BrandBox>
        ))}
      </BrandList>
      <ScrollToTopButtonWrapper>
        <ScrollToTopButton />
      </ScrollToTopButtonWrapper>
    </CompatibilityGuidePageContainer>
  );
}

//TODO: need to fix the isRouteMatched function in the Layout and remove padding from the CompatibilityGuidePageContainer
let CompatibilityGuidePageContainer = styled.div`
  padding: 0 16px;
  max-width: calc(631px + 32px);
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 0 40px;
    max-width: calc(631px + 80px);
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: calc(631px + 160px);
  }
`;

let BackButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
  }
`;

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 34px;
  line-height: 1;
  text-align: left;
  padding: 12px 0;
`;

let Description = styled.p`
  font-size: var(--fontSizeBig);
  line-height: 24px;
  margin-bottom: 20px;
  white-space: pre-wrap;
`;

let BrandButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 40px;
`;

let BrandButton = styled.button`
  border: none;
  height: 40px;
  padding: 0 16px;
  border-radius: 20px;
  background-color: var(--grey70);
  outline: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightBold);
  line-height: 16px;
  cursor: pointer;
  color: var(--textPrimary);
`;

let BrandList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

let BrandBox = styled.div``;

let BrandTitle = styled.div`
  margin-bottom: 16px;
`;

let Subtitle = styled.span`
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightMedium);
  line-height: 1;
`;

let Brand = styled.span`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  white-space: nowrap;
`;

let BrandCard = styled.div`
  background-color: var(--backgroundSecondary);
  border-radius: 20px;
  border: 1px solid var(--strokeSecondary);
`;

let Logo = styled.div`
  padding-top: 20px;
  text-align: center;
`;

let DeviceList = styled.ul`
  padding: 20px;
`;

let DeviceName = styled.li`
  margin-left: 20px;
  line-height: 24px;
  font-size: var(--fontSizeBig);
`;

let AdditionalInfo = styled.div`
  padding: 20px;
  padding-top: 16px;
  border-top: 1px solid var(--strokeSecondary);
`;

let Footnote = styled.p`
  line-height: 24px;
`;

let Symbol = styled.span`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;

let FootnoteText = styled.span``;

let Note = styled.span`
  line-height: 24px;
`;

let NoteTitle = styled.span`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;

let NoteText = styled.span`
  a {
    overflow-wrap: anywhere;
  }
`;

let ScrollToTopButtonWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 100px;

  //631px - max-width of the content block
  //44px - width of the button
  @media (min-width: 700px) {
    right: initial;
    bottom: 132px;
    left: calc(631px + (100vw - 631px) / 2 - 44px);
  }

  @media (min-width: 960px) {
    left: calc(100vw - (100vw - 631px) / 2 + 60px);
  }

  @media (min-width: 1100px) {
    left: calc(100vw - (100vw - 631px) / 2 + 150px);
  }
`;
