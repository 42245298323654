import { ADDRESS } from '@shared_frontend/Module/Layout/FooterConstants';
import styled from 'styled-components';
import ShieldTickIcon from '../Icons/ShieldTickIcon';
import visaImage from '../Images/Visa.png';
import mastercardImage from '../Images/MC.png';
import pciImage from '../Images/PCI.png';

export default function PaymentSecured() {
  return (
    <PaymentSecuredWrapper>
      <TitleWrapper>
        <ShieldTickIcon />
        <Title>Your payment is secured</Title>
      </TitleWrapper>
      <Vendors>
        <Vendor src={visaImage} alt="Visa" />
        <Vendor src={mastercardImage} alt="Mastercard" />
        <Vendor src={pciImage} alt="PCI" />
      </Vendors>
      <LegalInfo>The payment processed by {ADDRESS}</LegalInfo>
    </PaymentSecuredWrapper>
  );
}

let PaymentSecuredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  align-content: center;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

let Title = styled.h3`
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  text-align: center;
`;

let Vendors = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

let Vendor = styled.img`
  height: 40px;

  &:last-child {
    height: 24px;
  }
`;

let LegalInfo = styled.p`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--textSecondary);
`;
