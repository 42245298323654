import { JSX } from 'preact/jsx-runtime';
import { useDynamicSplitComponent } from '../Layout/useDynamicSplitComponent';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import OldDesignHomePage from './OldDesign/HomePage';
import NewDesignHomePage from './NewDesign/HomePage';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';

export function HomePageContainer<P>(props: P) {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  if (!splitGroupId) {
    return <LoadingAnimation />;
  }

  let HomePageComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignHomePage,
      [DesignSplitGroupId.B]: NewDesignHomePage,
    },
    identifier: DesignSplitGroupId.A,
  });

  return <HomePageComponent {...(props as P & JSX.IntrinsicAttributes)} />;
}
