/* eslint-disable max-lines-per-function */
import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import ESimSubCardBackgroundIcon from '../Icons/ESimSubBackgroundIcon';
import styled from 'styled-components';
import {
  CardHeaderWrapper,
  CardOpenButton,
  PackageDataInfo,
  PackageDataInfoWrapper,
} from '../ESimCard';
import Card from '@front_common/Components/Card';
import StatusBadge from './StatusBadge';
import Button from '@front_common/Components/Button/Button';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import AlertIcon from '../Icons/AlertIcon';
import {
  SubscriptionPlanImage,
  SubscriptionPlanName,
} from '@front_common/Components/EsimPlanName';
import {
  formatDataAmount,
  formatDateToShortString,
  timeLeftFromToday,
} from '../../Utils';
import { ReactElement } from 'preact/compat';

type ESimSubCardProps = {
  eSim: EsimEntityType;
  subscription: SubscriptionType;
  onCardClick: () => void;
  onInstallNowClick: () => void;
  onRepurchaseClick: () => void;
};

export default function ESimSubCard(props: ESimSubCardProps) {
  let {
    eSim,
    subscription,
    onCardClick,
    onInstallNowClick,
    onRepurchaseClick,
  } = props;

  let isActivated = eSim.status === EsimStatus.ACTIVATED;
  let renewsIn = timeLeftFromToday(subscription.renewsAt);

  function handleInstallNowClick(event: MouseEvent) {
    event.stopPropagation();
    onInstallNowClick();
  }

  function handleRepurchaseClick(event: MouseEvent) {
    event.stopPropagation();
    onRepurchaseClick();
  }

  let eSimStatusCardContentMap: Record<EsimStatus, ReactElement | null> = {
    [EsimStatus.PREPARING]: (
      <>
        <DescriptionRow>Your payment is received.</DescriptionRow>
        <DescriptionRow>We&#39;re preparing your eSIM.</DescriptionRow>
      </>
    ),
    [EsimStatus.NOT_INSTALLED]: (
      <RowBox>
        <ColumnBox>
          <DescriptionLabel>
            {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
          </DescriptionLabel>
          <DescriptionValue>{renewsIn}</DescriptionValue>
        </ColumnBox>
        <ButtonStyled
          sizeParam="S"
          typeStyle="Secondary"
          onClick={handleInstallNowClick}
        >
          Install eSIM
        </ButtonStyled>
      </RowBox>
    ),
    [EsimStatus.INSTALLED]: (
      <ColumnBox>
        <DescriptionLabel>
          {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
        </DescriptionLabel>
        <DescriptionValue>{renewsIn}</DescriptionValue>
      </ColumnBox>
    ),
    [EsimStatus.ACTIVATED]:
      subscription.status === SubscriptionStatus.UNPAID ? (
        <AlertRow>
          <AlertIcon />
          <span>Complete payment to renew data.</span>
        </AlertRow>
      ) : (
        <RowBox>
          <ColumnBox>
            <DescriptionLabel>Data left:</DescriptionLabel>
            <DescriptionValue>
              {formatDataAmount(eSim.dataRemainingMb)}{' '}
              <DescriptionValueSmall>out of</DescriptionValueSmall>{' '}
              {formatDataAmount(eSim.dataPurchasedGb * 1024)}
            </DescriptionValue>
          </ColumnBox>
          <ColumnBox>
            <DescriptionLabel>
              {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
            </DescriptionLabel>
            <DescriptionValue>{renewsIn}</DescriptionValue>
          </ColumnBox>
        </RowBox>
      ),
    [EsimStatus.EXPIRED]: (
      <RowBox>
        <ColumnBox>
          <DescriptionLabel>Ended on:</DescriptionLabel>
          <DescriptionValue>
            {formatDateToShortString(subscription.cancelledAt)}
          </DescriptionValue>
        </ColumnBox>
        <ButtonStyled
          sizeParam="S"
          typeStyle="Secondary"
          onClick={handleRepurchaseClick}
        >
          Repurchase
        </ButtonStyled>
      </RowBox>
    ),
    [EsimStatus.DATA_FINISHED]: (
      <RowBox>
        <ColumnBox>
          <DescriptionLabel>Data left:</DescriptionLabel>
          <DescriptionValue>
            {formatDataAmount(eSim.dataRemainingMb)}{' '}
            <DescriptionValueSmall>out of</DescriptionValueSmall>{' '}
            {formatDataAmount(eSim.dataPurchasedGb * 1024)}
          </DescriptionValue>
        </ColumnBox>
        <ColumnBox>
          <DescriptionLabel>Renews in:</DescriptionLabel>
          <DescriptionValue>{renewsIn}</DescriptionValue>
        </ColumnBox>
      </RowBox>
    ),
    [EsimStatus.REFUNDED]: (
      <>
        <DescriptionLabel>Refund on:</DescriptionLabel>
        <DescriptionValue>{subscription.refundedAt}</DescriptionValue>
      </>
    ),
    [EsimStatus.UNKNOWN]: null,
  };

  return (
    <BundleStoreItem
      id={eSim.id.toString()} //needed for Hint.
      $isActivated={isActivated}
      onClick={onCardClick}
    >
      <CardBackgroundIconWrapper $isActivated={isActivated}>
        <ESimSubCardBackgroundIcon />
      </CardBackgroundIconWrapper>
      <CardHeaderWrapper>
        <IconBox>
          <SubscriptionPlanImage />
        </IconBox>
        <StatusBadge eSimStatus={eSim.status} />
      </CardHeaderWrapper>
      <PackageDataInfoWrapper>
        <PackageDataInfo>
          <SubscriptionPlanName amountGb={eSim.dataPurchasedGb} />
        </PackageDataInfo>
        <CardOpenButton onClick={onCardClick}>
          <ArrowRightIcon />
        </CardOpenButton>
      </PackageDataInfoWrapper>
      {eSimStatusCardContentMap[eSim.status]}
    </BundleStoreItem>
  );
}

type ActivatedItemProps = {
  $isActivated: boolean;
};

let BundleStoreItem = styled(Card)<ActivatedItemProps>`
  position: relative;
  height: 150px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  background: ${({ $isActivated }) =>
    $isActivated
      ? 'linear-gradient(288.79deg, var(--lightBlue80) 1.45%, var(--lightBlue90) 97.97%)'
      : 'linear-gradient(288.95deg, #fbfbfb 15.15%, #dbdbdb 126.02%)'};

  span {
    display: inline-block;
    padding: 0;
  }

  @media (min-width: 700px) {
    width: 414px;
  }
`;

let CardBackgroundIconWrapper = styled.div<ActivatedItemProps>`
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;

  svg path {
    fill: ${({ $isActivated }) =>
      $isActivated ? 'var(--lightBlue90)' : 'var(--grey80)'};
    opacity: 0.5;
  }
`;

//TODO: think about reuse this, because it duplicates with bundle esim
let DescriptionRow = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPrimary);
`;

let AlertRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPrimary);
  margin-top: auto;
`;

let RowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  white-space: nowrap;
  &:first-child {
    min-width: 60%;
  }
  &:last-child {
    min-width: 100px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

let DescriptionLabel = styled.span`
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPrimary);
`;

let DescriptionValue = styled.span`
  line-height: 22px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightSemiBold);
`;

let DescriptionValueSmall = styled.span`
  line-height: 16px;
  font-size: 12px;
  font-weight: var(--fontWeightSemiBold);
`;

let ButtonStyled = styled(Button)`
  width: 115px;
  z-index: 1;
`;

let IconBox = styled.div`
  display: inline-flex;
  margin-right: 15px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border: 1px solid var(--backgroundSecondary);

  img {
    width: 22px;
    height: 22px;
  }
`;
