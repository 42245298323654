import PageTitleText from '@front_common/Components/PageTitleText';
import FileIcon from '@front_common/Components/Icons/FileIcon';
import {
  useGetMySubscriptions,
  useGetUserQuery,
} from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import {
  checkLoginOrRedirect,
  LinkToRoute,
  logoutAndRedirect,
} from '@front_common/Router/Router';
import {
  WEB_ROUTE_FAQ,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
} from '@shared_frontend/Common/WebRoutes';
import Button from '@front_common/Components/Button/Button';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';
import ReminderModal from '../InstallFlow/ReminderModal';
import SearchIcon from '../Home/OldDesign/Icons/SearchIcon';
import SumscriptionsImage from './Images/Subscriptions.png';
import styled from 'styled-components';
import RenewalModal from '../Esims/ESimPage/RenewalModal/RenewalModal';
import UnpaidSubscriptionModal from '../Subscription/UnpaidSubscriptionModal';

export default function ProfileComp() {
  checkLoginOrRedirect();
  let [user, error] = useGetUserQuery();

  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  if (!user) {
    return <LoadingAnimation />;
  }

  let [subs] = useGetMySubscriptions();

  return (
    <>
      <Title data-testid="profile-page-title">
        <PageTitleText>Profile</PageTitleText>
      </Title>

      <div style={{ textAlign: 'center' }}>{user.email}</div>
      <br />
      <br />
      <br />

      <StyledLink href={WEB_ROUTE_MY_ESIMS}>
        <FileIcon /> My eSIMs
      </StyledLink>
      <StyledLink href={WEB_ROUTE_FAQ}>
        <SearchIcon /> FAQ
      </StyledLink>
      {subs && subs.length > 0 ? (
        <StyledLink href={WEB_ROUTE_MY_SUBSCRIPTIONS}>
          <img src={SumscriptionsImage} alt="subscriptions image" /> My
          subscriptions
        </StyledLink>
      ) : null}
      <MenuSubButton onClick={handleZendeskOpenChatClick}>
        <SupportIcon />
        <span>Contact support</span>
      </MenuSubButton>

      <br />
      <br />
      <br />

      <LogoutButton
        typeStyle="Secondary"
        sizeParam="L"
        onClick={() => logoutAndRedirect()}
      >
        Log out
      </LogoutButton>
      <ReminderModal />
      <RenewalModal />
      <UnpaidSubscriptionModal />
    </>
  );
}

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  margin: 20px 0;
`;

let StyledLink = styled(LinkToRoute)`
  border-radius: 10px;
  font-weight: var(--fontWeightSemiBold);
  width: 100%;
  padding: 14px 16px;
  margin: 0 auto 10px;
  font-family: var(--fontFamilyPrimary);
  background: var(--backgroundSecondary);
  color: var(--textPrimary);
  border: var(--borderOutlinePrimary);
  text-align: left;
  display: flex;
  align-items: center;
  font-size: var(--fontSizeRegular);
  text-decoration: none;

  svg,
  img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    color: var(--iconsPrimaryInvert);
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  @media (min-width: 960px) {
    width: 50%;
    min-width: 500px;

    &:hover {
      border: 1px solid transparent;
    }
  }
`;

let LogoutButton = styled(Button)`
  width: 100%;
  @media (min-width: 960px) {
    width: 50%;
    margin: 0 auto;
    min-width: 500px;
  }
`;

let MenuSubButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 0 auto 10px;
  padding: 14px 16px;
  border-radius: 10px;
  color: var(--textPrimary);
  border: var(--borderOutlinePrimary);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--backgroundSecondary);

  @media (min-width: 700px) {
    display: none;
  }

  span {
    margin-left: 12px;
  }

  svg {
    stroke: var(--textPrimary);
    width: 24px;
    height: 24px;
    path {
      fill: var(--textPrimary);
    }
  }
`;
