import { StoreItem } from '@shared_backend/Module/Store/StoreResponse';
import styled from 'styled-components';

type PlanCardProps = {
  planData: StoreItem;
  selected: boolean;
  popular: boolean;
  onPlanClick: () => void;
};

export default function PlanCard(props: PlanCardProps) {
  let { planData, selected, onPlanClick, popular } = props;

  return (
    <PlanCardStyled
      key={planData.bundleCodeAndPriceId}
      $popular={popular}
      $selected={selected}
      onClick={onPlanClick}
    >
      {popular && <PopularLabel>Most popular</PopularLabel>}
      <InfoContainer>
        <PlanInfo $selected={selected}>
          <Amount $selected={selected}>
            <strong>{planData.dataAmountGb}GB</strong>
          </Amount>
          <Price>
            <span>
              <time dateTime={`P${planData.durationDays}D`}>
                {planData.durationDays} days
              </time>
            </span>
            <Full>
              <span>${planData.totalAmountCents / 100}</span>
              <PerPageWrapper>
                <strong>
                  $
                  {Number.parseFloat(
                    (
                      planData.totalAmountCents /
                      100 /
                      planData.dataAmountGb
                    ).toFixed(2),
                  )}{' '}
                  / GB
                </strong>
              </PerPageWrapper>
            </Full>
          </Price>
        </PlanInfo>
      </InfoContainer>
    </PlanCardStyled>
  );
}

let PlanCardStyled = styled.li<{ $popular: boolean; $selected: boolean }>`
  border-radius: var(--borderRadiusLarge);
  border: var(--borderOutlinePrimary);
  flex: 1 1 calc(50% - 8px);
  z-index: 5;
  padding: 0;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border: none;
  background-color: ${(props) =>
    props.$selected ? 'var(--newOrange100)' : 'var(--backgroundSecondary2New)'};

  @media (min-width: 700px) {
    flex: calc(33% - 8px);
  }

  @media (min-width: 960px) {
    flex: calc(25% - 8px);
    align-items: center;
    justify-content: center;
    max-width: calc(25% - 8px);
  }
`;

let InfoContainer = styled.div`
  position: relative;
  width: 100%;
`;

let PlanInfo = styled.div<{ $selected: boolean }>`
  width: 100%;
  display: flex;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  z-index: 5;
  border: ${(props) =>
    props.$selected
      ? '2px solid var(--newOrange100)'
      : '2px solid var(--backgroundSecondary2New)'};
`;

let PopularLabel = styled.div`
  gap: 4px;
  top: -14px;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 34px;
  display: flex;
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  justify-content: center;
  text-transform: uppercase;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: var(--textPrimaryNew);
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--backgroundSecondaryNew);
`;

let Amount = styled.p<{ $selected: boolean }>`
  z-index: 1;
  display: flex;
  width: 72px;
  overflow: hidden;
  padding: 18px 22px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props.$selected ? 'var(--newOrange100)' : 'var(--backgroundSecondary2New)'};

  strong {
    line-height: 22px;
    font-size: var(--fontSizeRegular);
    font-weight: var(--fontWeightBold);

    @media (min-width: 960px) {
      line-height: 24px;
      font-size: var(--fontSizeBig);
    }
  }
`;

let Price = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px 8px 12px;
  width: 100%;
  font-size: var(--fontSizeRegular);
  background-color: var(--backgroundSecondary);

  @media (min-width: 700px) {
    padding: 8px;
  }

  span {
    display: inline-block;
    font-size: 12px;
    color: var(--textSecondaryNew);
  }
`;

let Full = styled.div`
  display: flex;
  line-height: 22px;
  flex-direction: column;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 960px) {
    line-height: 24px;
    font-size: var(--fontSizeBig);
  }

  span {
    display: inline-flex;
    color: var(--textPrimaryNew);
  }
`;

let PerPageWrapper = styled.span`
  strong {
    display: inline-flex;
    line-height: 14px;
    font-size: 10px;
    padding: 4px;
    border-radius: 8px;
    background-color: var(--backgroundSecondaryNew);
    font-weight: var(--fontWeightRegular);
  }
`;
