import { useState } from 'preact/hooks';
import {
  getLastInvalidText,
  isValidEmail,
} from 'email-validator-dns-provider-rules';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_MY_PROFILE,
} from '@shared_frontend/Common/WebRoutes';
import {
  ParsedFormData,
  submitHandlerFactory,
} from '@shared_frontend/Common/FormHelper';
import VisitorApi from '@front_common/Api/VisitorApi';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import { routeHardRedirectWithReload } from '@front_common/Router/Router';
import AuthFormEmail from './AuthFormEmail';
import AuthFormOTP from './AuthFormOTP';
import { setReminderClear } from '../../../InstallFlow/ReminderModal';
import styled from 'styled-components';
import GoogleAuth from '../../ThirdPartyAuth/GoogleAuth';

type AuthFormProps = {
  onLoadingChange?: (isLoading: boolean) => void;
  onShowOTPChange?: (isLoading: boolean) => void;
  onAuthSuccess?: (() => void) | undefined;
};

export default function AuthForm(props: AuthFormProps) {
  let { onLoadingChange, onShowOTPChange, onAuthSuccess } = props;

  let [showOTPForm, setShowOTPForm] = useState(false);
  let [email, setEmail] = useState('');
  let [errorTextEmail, setErrorTextEmail] = useState('');
  let [errorTextOTP, setErrorTextOTP] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [isCheckedMarketingConsent, setIsCheckedMarketingConsent] =
    useState(false);

  function setIsLoadingCommon(value: boolean) {
    if (onLoadingChange) {
      onLoadingChange(value);
    }
    setIsLoading(value);
  }

  function handleChangeEmail() {
    if (onShowOTPChange) {
      onShowOTPChange(false);
    }

    setShowOTPForm(false);
  }

  async function processEmail(data: ParsedFormData) {
    setIsLoadingCommon(true);

    let emailForm = data['email'] ?? '';

    if (!emailForm) {
      setErrorTextEmail('Please enter your email');
      setIsLoadingCommon(false);
      return;
    }

    if (!(await isValidEmail(emailForm))) {
      setErrorTextEmail('Invalid email: ' + getLastInvalidText());
      setIsLoadingCommon(false);
      return;
    }

    setEmail(emailForm);

    try {
      let isUserRegistered = await VisitorApi.getIsUserRegistered(emailForm);

      if (isUserRegistered) {
        await VisitorApi.authSendEmailCode(emailForm);

        if (onShowOTPChange) {
          onShowOTPChange(true);
        }

        setShowOTPForm(true);

        setErrorTextEmail('');
      } else {
        let subscribe = data['subscribe'];
        let subscribeBoolean = subscribe === 'on';

        await VisitorApi.signup(emailForm, subscribeBoolean);

        if (onAuthSuccess) {
          onAuthSuccess();
        } else {
          routeHardRedirectWithReload(WEB_ROUTE_HOME);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorTextEmail(error.message);
      }
      logger.error(error);
    } finally {
      setIsLoadingCommon(false);
    }
  }

  async function processOTP(data: ParsedFormData) {
    setIsLoadingCommon(true);

    let otp = data['otp'];

    if (!otp) {
      setIsLoadingCommon(false);
      return;
    }

    let otpInteger = Number.parseInt(otp, 10);

    try {
      await VisitorApi.authByEmailCode(email, otpInteger);

      setReminderClear();

      if (onAuthSuccess) {
        onAuthSuccess();
      } else {
        routeHardRedirectWithReload(WEB_ROUTE_MY_PROFILE);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorTextOTP(error.message);
      }
      logger.error(error);
    } finally {
      setIsLoadingCommon(false);
    }
  }

  async function processResendOTP() {
    setIsLoadingCommon(true);

    try {
      await VisitorApi.authResendEmailCode(email);
    } catch (error) {
      if (error instanceof Error) {
        setErrorTextOTP(error.message);
      }
      logger.error(error);
    } finally {
      setIsLoadingCommon(false);
    }
  }

  return (
    <>
      {!showOTPForm && (
        <EmailFormWrapper>
          <AuthFormEmail
            isLoading={isLoading}
            errorTextEmail={errorTextEmail}
            onSubmit={submitHandlerFactory(processEmail)}
            lastEmail={email}
            formId="auth"
            isChecked={isCheckedMarketingConsent}
            onCheckBoxClick={setIsCheckedMarketingConsent}
          />
          <AuthMethodsSeparationLine>
            <Line />
            <Text>or continue with</Text>
          </AuthMethodsSeparationLine>
          <ThirdPartyAuthMethodsWrapper>
            <GoogleAuth
              onErrorTextChange={setErrorTextEmail}
              onLoaderChange={setIsLoadingCommon}
              onAuthSuccess={onAuthSuccess}
              marketingConsent={isCheckedMarketingConsent}
            />
          </ThirdPartyAuthMethodsWrapper>
        </EmailFormWrapper>
      )}
      {showOTPForm && (
        <AuthFormOTP
          email={email}
          isLoading={isLoading}
          errorTextOTP={errorTextOTP}
          onSubmit={submitHandlerFactory(processOTP)}
          onResendOTP={processResendOTP}
          onChangeEmail={handleChangeEmail}
        />
      )}
    </>
  );
}

let EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let AuthMethodsSeparationLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let Text = styled.span`
  padding: 0 8px 0 8px;
  background-color: var(--backgroundSecondary);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  z-index: 2;
`;

let Line = styled.span`
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 10px;
  background-color: var(--strokeSecondary);
  z-index: 1;
`;

let ThirdPartyAuthMethodsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
