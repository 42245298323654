import styled, { keyframes } from 'styled-components';

let rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

let StyledLoader = styled.svg`
  animation: ${rotate} 2s linear infinite;
`;

export default function Loader({ ...rest }) {
  return (
    <StyledLoader
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.6212 6.32823C13.8589 6.52806 14.161 6.63792 14.4708 6.63792C14.864 6.63792 15.2341 6.46547 15.4868 6.16479C15.9562 5.60544 15.8829 4.76836 15.3235 4.29863C15.0854 4.0988 14.7834 3.98877 14.4732 3.98877C14.0802 3.98877 13.7101 4.16122 13.4577 4.4619C12.9882 5.02142 13.0616 5.85833 13.6212 6.32823Z"
        fill="white"
      />
      <path
        d="M16.7474 7.84473C16.6652 7.84473 16.5822 7.85204 16.5011 7.86633C15.7384 8.00085 15.2268 8.73129 15.3613 9.49439C15.4797 10.167 16.0605 10.6551 16.7421 10.6551C16.8241 10.6551 16.9074 10.6478 16.9889 10.6337C17.7516 10.499 18.263 9.76871 18.1285 9.00595C18.0098 8.33316 17.4288 7.84473 16.7474 7.84473Z"
        fill="white"
      />
      <path
        d="M16.6978 12.4391C16.4719 12.3085 16.2158 12.2397 15.9573 12.2397C15.427 12.2397 14.9332 12.5246 14.6684 12.9831C14.4702 13.3265 14.4172 13.7265 14.5201 14.1098C14.623 14.4931 14.869 14.8137 15.2121 15.0119C15.4381 15.1426 15.6942 15.2115 15.9531 15.2115C16.483 15.2115 16.9769 14.9268 17.2415 14.4685C17.6507 13.759 17.4068 12.8486 16.6978 12.4391Z"
        fill="white"
      />
      <path
        d="M12.4726 15.0806C12.2907 15.0806 12.1104 15.1125 11.9369 15.1755C11.5441 15.3185 11.2306 15.6061 11.0537 15.9852C10.877 16.3642 10.8585 16.7894 11.0014 17.1824C11.2259 17.7991 11.8174 18.2136 12.4731 18.2136C12.6551 18.2136 12.8354 18.1816 13.0083 18.1185C13.4009 17.9755 13.7141 17.688 13.8912 17.3083C14.068 16.929 14.0867 16.5039 13.9442 16.1115C13.7201 15.495 13.1284 15.0806 12.4726 15.0806Z"
        fill="white"
      />
      <path
        d="M8.49165 15.0995C8.309 15.0332 8.11954 14.9995 7.92873 14.9995C7.23927 14.9995 6.6175 15.4352 6.38162 16.0837C6.23094 16.4971 6.2505 16.9442 6.43638 17.3427C6.62209 17.7413 6.95186 18.0436 7.36529 18.1941C7.5476 18.2606 7.73723 18.2939 7.92856 18.2939C8.61835 18.2939 9.23995 17.8582 9.47567 17.2102C9.62601 16.7976 9.60628 16.3505 9.42022 15.9517C9.234 15.5524 8.90441 15.2499 8.49165 15.0995Z"
        fill="white"
      />
      <path
        d="M5.94224 12.8617C5.63442 12.3288 5.06078 11.998 4.44479 11.998C4.1436 11.998 3.84581 12.0783 3.58305 12.2295C3.1834 12.4603 2.89768 12.8331 2.77846 13.2781C2.65891 13.7237 2.72013 14.1892 2.95091 14.5887C3.25874 15.1217 3.83254 15.4526 4.44853 15.4526C4.74955 15.4526 5.04734 15.3724 5.30993 15.221C6.13442 14.745 6.41809 13.6865 5.94224 12.8617Z"
        fill="white"
      />
      <path
        d="M3.66028 11.0577C4.53749 11.0577 5.28528 10.4293 5.438 9.56349C5.52168 9.08781 5.41539 8.60822 5.13834 8.21315C4.86164 7.81757 4.44736 7.55363 3.97151 7.46962C3.86607 7.45125 3.75943 7.44189 3.65518 7.44189C2.77797 7.44189 2.03018 8.07013 1.87746 8.93594C1.79379 9.41128 1.90008 9.89087 2.17712 10.2865C2.45399 10.6819 2.86845 10.946 3.34396 11.0298C3.44889 11.0484 3.55535 11.0577 3.66028 11.0577Z"
        fill="white"
      />
      <path
        d="M5.93071 7.20214C6.37271 7.20214 6.80332 7.04517 7.14329 6.76031C7.52901 6.43667 7.76557 5.98139 7.80962 5.47833C7.85367 4.97527 7.69958 4.48582 7.37577 4.1001C7.01625 3.67153 6.48853 3.42578 5.92832 3.42578C5.48564 3.42578 5.05503 3.58258 4.71557 3.86745C4.32935 4.1916 4.09227 4.64687 4.04839 5.14925C4.00435 5.65129 4.1586 6.14092 4.48292 6.52748C4.84295 6.95622 5.3705 7.20214 5.93071 7.20214Z"
        fill="white"
      />
      <circle cx="10.2002" cy="3.75931" r="2.05374" fill="white" />
    </StyledLoader>
  );
}
