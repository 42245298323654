import styled from 'styled-components';
import ESimDataLoader from './ESimDataLoader';
import Tooltip from '../Tooltip/Tooltip';

export function ActivatedPlanLoaderContent({
  availableData,
  purchasedData,
  progress,
}: {
  availableData: string;
  purchasedData: string;
  progress: number;
}) {
  return (
    <ESimDataLoader
      progress={progress}
      primaryColor="var(--progressBarPrimary)"
      secondaryColor="var(--progressBarSecondary)"
    >
      <DataAvailableText>
        <span>
          Available <BoldBox>{availableData}</BoldBox>
        </span>
        <br />
        <span>
          out of {purchasedData}{' '}
          <Tooltip
            tooltipContent="Data balance may not include recent usage. Updates can take a
                  short while to appear."
          />
        </span>
      </DataAvailableText>
    </ESimDataLoader>
  );
}

export function DataFinishedLoaderContent({
  availableData,
}: {
  availableData: string;
}) {
  return (
    <ESimDataLoader progress={100} primaryColor="var(--progressBarPrimary)">
      <DataAvailableText>
        <BoldBox>0GB</BoldBox>
        <br />
        <span>out of {availableData}</span>
      </DataAvailableText>
    </ESimDataLoader>
  );
}

export function DefaultLoaderContent({
  label,
  availableData,
}: {
  label: string;
  availableData: string;
}) {
  return (
    <ESimDataLoader>
      <DataAvailableText>
        <span>{label}</span> <BoldBox>{availableData}</BoldBox>
      </DataAvailableText>
    </ESimDataLoader>
  );
}

let DataAvailableText = styled.span`
  position: absolute;
  bottom: 0px;
  line-height: 20px;
  text-align: center;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  white-space: break-spaces;
  width: 156px;
  height: 40px;
  color: var(--textSecondary);
  vertical-align: top;
`;

let BoldBox = styled.span`
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  color: var(--textPrimary);
`;
