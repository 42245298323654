import { useState } from 'preact/hooks';
import styled from 'styled-components';

type ToggleStateStatus = 'left' | 'right';

type IProps = {
  initial?: ToggleStateStatus;
  leftButtonTitle: string;
  rightButtonTitle: string;
  onChange: (status: ToggleStateStatus) => void;
};

export default function Toggle({
  onChange,
  leftButtonTitle,
  rightButtonTitle,
  initial = 'left',
}: IProps) {
  let [selected, setLeftSelected] = useState(initial);

  function onToggle(status: ToggleStateStatus) {
    onChange(status);
    setLeftSelected(status);
  }

  return (
    <OsToggleContainer data-testid="custom-toggle">
      <Values>
        <ValueButton
          data-testid="toggle-button-left"
          $isSelected={selected === 'left'}
          onClick={() => onToggle('left')}
        >
          {leftButtonTitle}
        </ValueButton>
        <ValueButton
          data-testid="toggle-button-right"
          $isSelected={selected === 'right'}
          onClick={() => onToggle('right')}
        >
          {rightButtonTitle}
        </ValueButton>
      </Values>
      <SelectedIsland $toLeft={selected === 'left'} />
    </OsToggleContainer>
  );
}

let OsToggleContainer = styled.div`
  padding: 2px;
  position: relative;
  background-color: var(--strokeSecondary);
  border-radius: 60px;
  height: 48px;
`;

let Values = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

let ValueButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  z-index: 2;

  font-size: var(--fontSizeSmall);
  line-height: 20px;
  font-weight: ${(props) =>
    props.$isSelected ? 'var(--fontWeightBold)' : 'var(--fontWeightMedium)'};
  color: ${(props) =>
    props.$isSelected ? 'var(--textSelected)' : 'var(--textSecondary)'};

  transition:
    color 0.2s,
    font-weight 0.2s;
`;

let SelectedIsland = styled.div<{ $toLeft: boolean }>`
  width: calc(50% - 4px);
  position: absolute;
  top: 2px;
  height: calc(100% - 4px);
  left: ${(props) => (props.$toLeft ? '2px' : 'calc(50% + 2px)')};
  z-index: 1;
  background-color: var(--backgroundSecondary);
  border-radius: 60px;
  transition: left 0.2s;
`;
