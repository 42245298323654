import { useState } from 'preact/hooks';
import { forwardRef, HTMLAttributes } from 'preact/compat';
import { popularEmailDomains } from '@shared_frontend/Module/Email/PopularEmailDomains';
import styled from 'styled-components';

interface DomainSuggestionProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  errorText?: string;
  value?: string;
}

let DomainSuggestion = forwardRef<
  HTMLInputElement | null,
  DomainSuggestionProps
>((props, ref) => {
  let { label, errorText, value = '', ...rest } = props;

  let [searchTerm, setSearchTerm] = useState(value);
  let [isFocused, setIsFocused] = useState(false);

  function handleEmailClick(email: string) {
    setSearchTerm(email);
    setIsFocused(false);

    if (ref && typeof ref === 'object' && ref.current) {
      ref.current?.blur();
    }
  }

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputBlur() {
    setIsFocused(false);
  }

  function handleSearchChange(event: Event) {
    // eslint-disable-next-line xss/no-mixed-html
    let target = event.target as HTMLInputElement;
    setSearchTerm(target.value);
  }

  function filteredEmails() {
    if (!searchTerm.includes('@')) {
      return [];
    }

    let [localPart, domainPart] = searchTerm.split('@');

    if (!domainPart) {
      return popularEmailDomains.map((domain) => `${localPart}@${domain}`);
    }

    return popularEmailDomains
      .filter((domain) => domain.startsWith(domainPart))
      .map((domain) => `${localPart}@${domain}`);
  }

  return (
    <SuggestionContainer>
      {label && <Label>{label}</Label>}
      <InputContainer $menuOpen={isFocused && filteredEmails().length > 0}>
        {/*@ts-expect-error  @TODO fix this pls */}
        <StyledInput
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="youremail@mail.com"
          $hasError={Boolean(errorText)}
          $menuOpen={isFocused && filteredEmails().length > 0}
          ref={ref}
          {...rest}
        />
        {isFocused && filteredEmails().length > 0 && (
          <SearchResultsWrapper $hasError={Boolean(errorText)}>
            <SearchResults
              onMouseDown={(event: Event) => event.preventDefault()}
            >
              {filteredEmails().map((emailHint: string) => (
                <li
                  key={emailHint}
                  tabIndex={0}
                  onMouseDown={() => handleEmailClick(emailHint)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleEmailClick(emailHint);
                    }
                  }}
                >
                  {emailHint}
                </li>
              ))}
            </SearchResults>
          </SearchResultsWrapper>
        )}
      </InputContainer>
      {errorText && <Error>{errorText}</Error>}
    </SuggestionContainer>
  );
});

export default DomainSuggestion;

let SuggestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

let InputContainer = styled.div<{ $menuOpen: boolean }>`
  position: relative;

  &::after {
    display: ${(props) => (props.$menuOpen ? 'block' : 'none')};
    content: '';
    width: calc(100% - 2px);
    height: 4px;
    position: absolute;
    bottom: -2px;
    left: 1px;
    z-index: 3;
    background-color: var(--backgroundSecondaryNew);
  }
`;

let Label = styled.label`
  line-height: 20px;
  font-size: var(--fontSizeRegular);
  margin-bottom: 8px;
`;

let StyledInput = styled.input<{ $hasError: boolean; $menuOpen: boolean }>`
  height: 48px;
  padding: 0 16px;
  width: 100%;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);
  outline: none;
  text-overflow: ellipsis;
  border-radius: 8px;
  border-color: ${(props) =>
    props.$hasError
      ? 'var(--errorButtonDangerNew)'
      : 'var(--strokePrimaryNew)'};
  border-width: 1px;
  border-style: solid;
  border-bottom-color: ${(props) =>
    props.$menuOpen
      ? 'transparent'
      : // eslint-disable-next-line unicorn/no-nested-ternary
        props.$hasError
        ? 'var(--errorButtonDangerNew)'
        : 'var(--strokePrimaryNew)'};
  background-color: var(--backgroundSecondary3New);

  &::placeholder {
    color: var(--textSecondaryNew);
  }

  &:hover {
    border: ${(props) =>
      props.$hasError
        ? '1px solid var(--errorButtonDangerNew)'
        : '1px solid var(--strokePrimaryNew)'};
  }

  &:focus {
    border: ${(props) =>
      props.$hasError
        ? '1px solid var(--errorButtonDangerNew)'
        : '1px solid var(--strokePrimaryNew)'};
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

let SearchResultsWrapper = styled.div<{ $hasError: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  border: 1px solid
    ${(props) =>
      props.$hasError
        ? 'var(--errorButtonDangerNew)'
        : 'var(--strokePrimaryNew)'};
  border-radius: 0 0 14px 14px;
`;

let SearchResults = styled.ul`
  background: var(--backgroundSecondary);
  max-height: 156px;
  overflow-y: auto;

  li {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    font-size: 14px;
    height: 36px;

    &:hover {
      background-color: var(--backgroundPrimary);
    }
  }
`;

let Error = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--errorButtonDangerNew);
`;
