import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
  label?: string | undefined;
  errorText?: string;
}

// eslint-disable-next-line react/display-name
let TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  let { label, errorText, ...rest } = props;

  return (
    <InputContainer>
      {label && <Label>{label}</Label>}
      {/*@ts-expect-error  @TODO fix this pls */}
      <StyledInput
        ref={ref}
        type="text"
        $hasError={Boolean(errorText)}
        {...rest}
      />
      {errorText && <Error>{errorText}</Error>}
    </InputContainer>
  );
});

export default TextInput;

let InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let Label = styled.label`
  line-height: 16px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightBold);
`;

let StyledInput = styled.input<{ $hasError: boolean }>`
  height: 56px;
  padding: 0 16px;
  border-radius: var(--borderRadiusPrimary);
  width: 100%;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);
  border: ${(props) =>
    props.$hasError
      ? '1px solid var(--errorButtonDanger)'
      : '1px solid var(--strokeSecondary)'};
  outline: none;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--strokePrimary);
  }

  &:hover {
    border: ${(props) =>
      props.$hasError
        ? '1px solid var(--errorButtonDanger)'
        : '1px solid var(--strokePrimary)'};
  }

  &:focus {
    border: ${(props) =>
      props.$hasError
        ? '1px solid var(--errorButtonDanger)'
        : '1px solid var(--strokePrimary)'};
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

let Error = styled.span`
  color: var(--errorButtonDanger);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;
