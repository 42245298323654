import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../Module/Layout/OldDesign/Header';
import SaleIcon from './Icons/SaleIcon';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_STORE_GLOBAL } from '@shared_frontend/Common/WebRoutes';
import mapImage from '@front_common/Components/PromoteBanner/OldDesign/Images/MapImage.png';
import airplaneImage from '@front_common/Components/PromoteBanner/OldDesign/Images/AirplaneImage.png';
import DashedLine from './Icons/DashedLineSVG';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';

export const BANNER_HEIGHT_MOBILE = '76px';
export const BANNER_HEIGHT = '88px';

function handleGetPlan() {
  Api.trackAction(ActionTypes.click_sub_banner, null);
}

export default function PromoteBanner() {
  return (
    <>
      <PromoteBannerContainer>
        <Gradient>
          <DashedLine />
        </Gradient>
        <Content>
          <Blur />
          <MapBackground src={mapImage} />
          <AirplaneImage src={airplaneImage} />
          <LayerWrapper>
            <IconText>
              <SaleIcon />
              <Text>Save up to 70% with global plan</Text>
            </IconText>
            <LinkToRoute
              href={`${WEB_ROUTE_STORE_GLOBAL}/`}
              style={{ textDecoration: 'none' }}
            >
              <StyledButton onClick={handleGetPlan} sizeParam="L">
                Get a global plan
              </StyledButton>
            </LinkToRoute>
          </LayerWrapper>
        </Content>
      </PromoteBannerContainer>
      <Space />
    </>
  );
}

let PromoteBannerContainer = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT};
  left: 0;
  right: 0;
  height: ${BANNER_HEIGHT_MOBILE};
  z-index: 10;
  padding: 0 16px;

  @media (min-width: 700px) {
    height: ${BANNER_HEIGHT};
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
  }
`;

let Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(90deg, #bdc5ff 0%, #e4bdff 86.56%);
  overflow: hidden;

  svg {
    display: none;

    @media (min-width: 1440px) {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 562px;
    }
  }
`;

let Blur = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  height: 200px;
  width: 200px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 40%,
    rgba(255, 255, 255, 0.05) 70%,
    transparent 100%
  );
  filter: blur(30px);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.4) 0%,
      transparent 70%
    );
  }
`;

let MapBackground = styled.img`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

let AirplaneImage = styled.img`
  display: none;

  @media (min-width: 393px) {
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 170px;
    height: 65px;
  }

  @media (min-width: 700px) {
    right: 240px;
    height: 90px;
  }

  @media (min-width: 960px) {
    right: 270px;
  }
`;

let Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 960px) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

let LayerWrapper = styled.div`
  z-index: 1;
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  position: relative;

  svg {
    min-width: 24px;
    height: 24px;
  }
`;

let IconText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  @media (min-width: 700px) {
    align-items: center;
  }
`;

let Space = styled.div`
  min-height: ${BANNER_HEIGHT_MOBILE};
  width: 100%;

  @media (min-width: 700px) {
    min-height: ${BANNER_HEIGHT};
  }
`;

let Text = styled.p`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeSmall);

  @media (min-width: 393px) {
    font-size: var(--fontSizeRegular);
    line-height: 22px;
  }

  @media (min-width: 700px) {
    font-size: var(--fontSizeBig);
    line-height: 24px;
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

let StyledButton = styled(Button)`
  @media (min-width: 393px) {
    width: 178px;
  }

  @media (min-width: 700px) {
    width: 236px;
  }
`;
