export const METAPixelAPI = {
  sendEvent,
};

declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
  }
}

export type METAEvent = 'CompleteRegistration' | 'Purchase';

export type PurchaseType =
  | 'meta-first-purchase'
  | 'meta-rebill'
  | 'subscription-first-purchase';

export type METACustomData = {
  currency: string;
  value: number;
  type: PurchaseType;
  targetCountry?: string;
};

async function sendEvent(
  event: METAEvent,
  customData: METACustomData | Record<string, never>,
  eventID: string,
) {
  if (window.fbq) {
    await window.fbq('track', event, customData, {
      eventID,
    });
  }
}
