import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import { BundleEntityType } from '@shared_backend/Module/Bundle/BundleEntityType';
import styled from 'styled-components';

type IProps = {
  bundle: BundleEntityType;
  totalAmountCents: number;
  targetCountry: string;
  borderColor?: string;
};

export default function ProductInfo({
  bundle,
  totalAmountCents,
  targetCountry,
}: IProps) {
  let countryName = getEsimPlanCountry(targetCountry);

  return (
    <ProductInfoWrapper>
      <ContentLeft>
        <IconBox>
          <EsimPlanFlag withBorder targetCountry={targetCountry} />
        </IconBox>
        <PlanDataInfo>
          <FirstRow>{`${bundle.dataAmountGb}GB in ${countryName}`}</FirstRow>
          <SecondRow>Valid for: {bundle.durationDays} days</SecondRow>
        </PlanDataInfo>
      </ContentLeft>
      <PriceItem>${totalAmountCents / 100}</PriceItem>
    </ProductInfoWrapper>
  );
}

let ProductInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 22px;
`;

let PriceItem = styled.span`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let IconBox = styled.div`
  margin-right: 8px;
  height: 30px;

  img {
    height: 30px;
  }
`;

let PlanDataInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let FirstRow = styled.span`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  line-height: 22px;
`;

let SecondRow = styled.span`
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let ContentLeft = styled.div`
  display: flex;
  align-items: center;
`;
