import styled from 'styled-components';

let line = (
  <svg
    width="1"
    height="750"
    viewBox="0 0 1 750"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.5"
      y1="-2.18557e-08"
      x2="0.500055"
      y2="750"
      stroke="#B3B3B3"
      strokeDasharray="6 6"
    />
  </svg>
);

export type GuideStep = {
  stepNumber: number;
  title: string;
  mainText: React.ReactNode;
  images: string[];
  additionalBlock?: React.ReactNode;
};

type GuideCardProps = {
  step: GuideStep;
  isLast: boolean;
};

export function GuideCard(props: GuideCardProps) {
  let { step, isLast } = props;

  return (
    <GuideCardStyled $isLast={isLast}>
      <CardTop>
        <StepWrapper>
          <StepNumber>{step.stepNumber}</StepNumber>
          {!isLast && line}
        </StepWrapper>
        <div>
          <Title>{step.title}</Title>
          <MainText>{step.mainText}</MainText>
        </div>
      </CardTop>
      <Screens $justifyCenter={step.images.length === 1}>
        {step.images.map((image, index) => (
          <CardImage
            loading="lazy"
            key={index}
            src={image}
            alt="illustration"
          />
        ))}
      </Screens>
      {step.additionalBlock && (
        <AdditionalBlockWrapper>{step.additionalBlock}</AdditionalBlockWrapper>
      )}
    </GuideCardStyled>
  );
}

export let GuideCardStyled = styled.div<{ $isLast: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  padding-bottom: ${(props) => (props.$isLast ? '' : '20px')};
  overflow: hidden;

  @media (min-width: 700px) {
    gap: 12px;
  }

  @media (min-width: 960px) {
    padding-left: 80px;
    padding-right: 80px;
    max-width: 1006px;
    margin: 0 auto;
    gap: 16px;
  }
`;

let StepWrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    position: absolute;
    top: 30px;
    left: 31px;
    height: 750px;
  }

  @media (min-width: 700px) {
    svg {
      left: 55px;
    }
  }

  @media (min-width: 960px) {
    svg {
      left: 95px;
    }
  }
`;

let CardTop = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: initial;
  }
`;

let StepNumber = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--itemBrand);
  color: var(--textPrimaryInvert);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontSizeSmall);
  line-height: 1;
  font-weight: var(--fontWeightBold);
`;

let Title = styled.p`
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  margin-bottom: 4px;

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

let MainText = styled.span`
  font-size: var(--fontSizeRegular);
  white-space: pre-wrap;
  line-height: 22px;

  b {
    font-weight: var(--fontWeightBold);
  }

  ul {
    padding-left: 17px;
  }
`;

let CardImage = styled.img`
  height: 300px;
`;

let Screens = styled.div<{ $justifyCenter: boolean }>`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  margin-left: 54px;
  padding-right: 16px;
  justify-content: ${(props) => (props.$justifyCenter ? 'center' : 'initial')};

  @media (min-width: 700px) {
    margin-left: 78px;
    padding-right: 40px;
  }

  @media (min-width: 960px) {
    max-width: 846px;
    margin: initial;
    padding: initial;
    display: flex;
    justify-content: center;
  }
`;

let AdditionalBlockWrapper = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 30px;
  }
`;
