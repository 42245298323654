import styled from 'styled-components';
import lightningImage from './Images/Lightning.png';
import arrowsImage from './Images/Arrows.png';
import phoneImage from './Images/Phone.png';
import dishImage from './Images/Dish.png';
import worldImage from './Images/World.png';
import lightsImage from './Images/Lights.png';
import rocketImage from './Images/Rocket.png';

const planDetailsData = [
  {
    imgSrc: lightningImage,
    alt: 'lightning',
    title: 'Speed:',
    text: 'Full speed available (5G/4G/LTE depending on the operator and the location)',
  },
  {
    imgSrc: arrowsImage,
    alt: 'arrows',
    title: 'Type:',
    text: 'Monthly subscription (with auto-renewal every 30 days)',
  },
  {
    imgSrc: phoneImage,
    alt: 'phone',
    title: 'Phone number / SMS:',
    text: 'No',
  },
  {
    imgSrc: dishImage,
    alt: 'dish',
    title: 'Hot-spot capabilities:',
    text: 'Yes, you can connect other devices',
  },
  {
    imgSrc: worldImage,
    alt: 'world',
    title: 'Available networks:',
    text: 'We offer more than one operator in many countries so you can enjoy the best possible coverage',
  },
  {
    imgSrc: lightsImage,
    alt: 'lights',
    title: 'Throttling:',
    text: 'No, we will never slow down the speed',
  },
  {
    imgSrc: rocketImage,
    alt: 'rocket',
    title: 'Delivery:',
    text: 'Immediate delivery (available via E-mail, Website or App)',
  },
];

export default function PlanDetails() {
  return (
    <>
      {planDetailsData.map((detail, index) => (
        <Row key={index}>
          <StyledImg src={detail.imgSrc} alt={detail.alt} />
          <Info>
            <Title>{detail.title}</Title>
            <Text>{detail.text}</Text>
          </Info>
        </Row>
      ))}
    </>
  );
}

let Row = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: var(--borderOutlinePrimary);
  padding-top: 16px;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
`;

let StyledImg = styled.img`
  width: 24px;
  height: 24px;
`;

let Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let Title = styled.h5`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  line-height: 22px;
`;

let Text = styled.p`
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
`;
