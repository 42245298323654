export default function RefreshIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.452 12.8946C20.1742 15.5045 18.6954 17.9503 16.2484 19.363C12.1829 21.7102 6.98442 20.3173 4.63721 16.2518L4.38721 15.8188M3.54515 11.1086C3.82295 8.4987 5.30174 6.05297 7.74873 4.6402C11.8142 2.29299 17.0127 3.68593 19.3599 7.75142L19.6099 8.18443M3.49219 18.0676L4.22424 15.3356L6.95629 16.0676M17.0414 7.9356L19.7735 8.66765L20.5055 5.9356"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
