import styled from 'styled-components';
import { LinkToRoute } from '@front_common/Router/Router';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import GlobeIcon from './Icons/GlobeIcon';
import SimCardIcon from './Icons/SimCardIcon';
import ProfileIcon from './Icons/ProfileIcon';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_PROFILE,
} from '@shared_frontend/Common/WebRoutes';

export default function MobileNavBar() {
  let router = useRouterState();
  let currentPath = router.path || '';

  return (
    <MenuWrapper>
      <Menu>
        <MenuLink
          href={WEB_ROUTE_HOME}
          $selected={currentPath === WEB_ROUTE_HOME}
        >
          <GlobeIcon />
          Country plans
        </MenuLink>
        <MenuLink
          href={WEB_ROUTE_MY_ESIMS}
          $selected={currentPath.startsWith('/my/esims')}
        >
          <SimCardIcon />
          My eSIMs
        </MenuLink>
        <MenuLink
          href={WEB_ROUTE_MY_PROFILE}
          $selected={currentPath === WEB_ROUTE_MY_PROFILE}
        >
          <ProfileIcon />
          Profile
        </MenuLink>
      </Menu>
    </MenuWrapper>
  );
}

let MenuWrapper = styled.div`
  height: 58px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--backgroundSecondary);
  border-top: 1px solid var(--strokeSecondary);
  padding: 0 24px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }
`;

let Menu = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let MenuLink = styled(LinkToRoute)<{ $selected?: boolean }>`
  height: 100%;
  width: 82px;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  color: ${(props) =>
    props.$selected ? 'var(--textLink)' : 'var(--strokePrimary)'};

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${(props) =>
        props.$selected ? 'var(--iconsPrimaryBrand)' : 'var(--strokePrimary)'};
    }
  }
`;
