import { useSubscriptionPolicy } from '@front_common/Strapi/StrapiLegal';
import DocumentContent from './DocumentContent';

export default function SubscriptionPolicy() {
  let [subscriptionPolicy] = useSubscriptionPolicy();

  return (
    <DocumentContent
      content={subscriptionPolicy?.attributes.content || ''}
      seo={subscriptionPolicy?.attributes.seo || null}
    />
  );
}
