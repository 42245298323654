export default function DashedLine() {
  return (
    <svg
      width="564"
      height="29"
      viewBox="0 0 564 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.5C47.9919 6.01539 135.194 -4.04538 214.913 4.57242C292.449 12.9542 400.779 47.4678 563 12.9539"
        stroke="#5F5F5F"
        strokeDasharray="8 8"
      />
    </svg>
  );
}
