import styled from 'styled-components';
import Accordion from '@front_common/Components/Accordion/OldDesign/Accordion';

export let qnaData = [
  {
    title: 'Do I need to install a new eSIM every month?',
    content: `No, you don’t need to install a new eSIM every month. Once your eSIM is installed, it will stay active as long as your subscription is renewed. Your data plan will automatically refresh every month, so you can continue using your eSIM without any interruptions or extra setup.`,
  },
  {
    title:
      'What will happen if I need internet in a country that is not on the list?',
    content: `If you’re traveling to a country not covered by the Global Subscription, don’t worry! You can purchase a separate plan for that country directly from our website. This ensures you always have the internet access you need, no matter where you go.`,
  },
  {
    title: 'Can I upgrade or downgrade my plan?',
    content: `Yes, you can upgrade or downgrade your plan at any time to better suit your needs. If you’re running low on data or want to save more on a higher plan, upgrading is easy. Downgrading works the same way if you need less data. Simply select the plan that works best for you from your account.`,
  },
  {
    title: 'What will happen to unused GBs if I upgrade my plan?',
    content: `Good news! Any unused GBs from your current plan will carry over when you upgrade. They’ll be added to your new plan, so you won’t lose any data you’ve already paid for.`,
  },
  {
    title: 'What will happen to unused GBs if I cancel the plan?',
    content: `If you cancel your plan, you can still use any remaining data until the plan’s expiration date. After that, any unused data will no longer be available, so we recommend using it up before the plan ends.`,
  },
  {
    title: 'How does auto-renewal work?',
    content: `Auto-renewal ensures your subscription is always active, so you don’t have to worry about losing connectivity. Your plan will renew automatically each month or year, depending on your billing cycle. You can cancel anytime, and no further charges will be made after your current billing period ends. It’s designed to be flexible and hassle-free.`,
  },
  {
    title: 'What happens if I use up all my data for the month?',
    content: `If you run out of data, you have two options: you can restart your current subscription to top up your data immediately, or upgrade to a higher plan. Upgrading not only gives you more data for this month but also helps you pay less per GB in the long run. Either way, you’ll always have an easy solution to stay connected.`,
  },
  {
    title: 'How do I know how much data I need?',
    content: `The amount of data you need depends on how you use the internet. If you mostly browse websites, send messages, or check maps, you’ll need less data. If you watch videos, stream music, or use apps heavily, we'd suggest going for one of our bigger plans of 15 or 25GB.`,
  },
];

export default function FAQ() {
  return (
    <>
      <Title>Global eSIM FAQ</Title>
      <Accordion data={qnaData} />
    </>
  );
}

let Title = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;
