import styled from 'styled-components';
import LogoPictureOhayu from './Images/NewLogoImage.png';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { HELP_LIST } from '@shared_frontend/Module/Layout/FooterConstants';
import AuthService from '@front_common/Api/AuthService';
import { LinkToRoute } from '@front_common/Router/Router';
import MenuSelect, { DropdownItem } from './MenuSelect';
import SignInProfileButton from './SignInProfileButton';
import MobileSupportChatButton from '@front_common/ZendeskWidget/OldDesign/MobileSupportChatButton';
import {
  HEADER_CLASS_NAME,
  PROFILE_BUTTON_HEADER_CLASS_NAME,
} from '../../Checkout/useDisplayingHeaderFooterElements';
import { useState } from 'react';
import { useEffect } from 'preact/hooks';

type IProps = {
  onlyLogo?: boolean;
  hideSignInProfileButton: boolean;
  hideSupportButton: boolean;
};

export const HEADER_HEIGHT = '64px';

export default function Header({
  onlyLogo,
  hideSignInProfileButton,
  hideSupportButton,
}: IProps) {
  let authenticated = AuthService.isLoggedIn();

  let [isScrolledPage, setIsScrolledPage] = useState(false);

  // TODO (SPLIT TEST): (new design)
  useEffect(() => {
    function handleScroll() {
      let scrollPosition = window.scrollY;
      setIsScrolledPage(scrollPosition > 10);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderWrapper $isScrolledPage={isScrolledPage}>
      <HeaderContent>
        <Logo href={WEB_ROUTE_HOME}>
          <LogoImage src={LogoPictureOhayu} alt="Ohayu esim logo" />
        </Logo>

        {onlyLogo ? null : (
          <DesktopNavigation className={HEADER_CLASS_NAME}>
            {!hideSupportButton && <MobileSupportChatButton />}
            <MenuLink href={WEB_ROUTE_HOME}>Country plans</MenuLink>
            <MenuLink href={`${WEB_ROUTE_STORE_GLOBAL}/`}>
              Global plans
            </MenuLink>
            <MenuSelect placeholder="Help">
              {HELP_LIST.map((option) => (
                <DropdownItem key={option.label}>
                  <LinkToRoute href={`${option.href}/`}>
                    {option.label}
                  </LinkToRoute>
                </DropdownItem>
              ))}
            </MenuSelect>
            {authenticated && (
              <MenuLink href={WEB_ROUTE_MY_ESIMS}>My eSIMs</MenuLink>
            )}
            {!hideSignInProfileButton && (
              <div className={PROFILE_BUTTON_HEADER_CLASS_NAME}>
                <SignInProfileButton authenticated={authenticated} />
              </div>
            )}
          </DesktopNavigation>
        )}
      </HeaderContent>
    </HeaderWrapper>
  );
}

let HeaderWrapper = styled.div<{ $isScrolledPage: boolean }>`
  height: ${HEADER_HEIGHT};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
    
  // TODO (SPLIT TEST): (new design)
  background-color: ${(props) => (props.$isScrolledPage ? 'rgba(255, 255, 255, 0.75' : 'var(--backgroundPrimaryNew)')} );
  box-shadow: ${(props) => (props.$isScrolledPage ? '0 4px 8px -2px #0000001a' : 'none')} ;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  @media (min-width: 700px) {
    padding: 0 32px;
  }

  @media (min-width: 1104px) {
    padding: 0;
  }
`;

let HeaderContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 960px) {
    max-width: 1040px;
  }
`;

let Logo = styled(LinkToRoute)`
  height: 16px;
  display: block;
`;

let LogoImage = styled.img`
  height: 100%;
  display: block;
`;

let DesktopNavigation = styled.nav`
  display: none;

  @media (min-width: 960px) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

let MenuLink = styled(LinkToRoute)`
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  color: var(--textPrimaryNew);
  font-weight: var(--fontWeightRegular);

  &:hover {
    color: var(--black100);
  }
`;
