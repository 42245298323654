export default function CheckMark() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.7803 9.20507C18.0732 8.9294 18.0732 8.48245 17.7803 8.20677C17.4875 7.93109 17.0126 7.93108 16.7197 8.20674L10.25 14.2958L7.28031 11.501C6.9874 11.2253 6.51253 11.2253 6.21965 11.501C5.92677 11.7767 5.92679 12.2237 6.21969 12.4993L9.71969 15.7933C10.0126 16.0689 10.4874 16.0689 10.7803 15.7933L17.7803 9.20507Z"
        fill="#203ADB"
      />
    </svg>
  );
}
