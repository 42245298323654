import { HTMLAttributes } from 'preact/compat';
import { EmblaOptionsType } from 'embla-carousel';
import StarGoldIcon from '@front_common/Components/Feedbacks/OldDesign/Icons/StarGoldIcon';
import ChevronLeftIcon from '@front_common/Components/Feedbacks/OldDesign/Icons/ChevronLeftIcon';
import Carousel from '@front_common/Components/Carousel';
import Flag from '@front_common/Components/Flag/Flag';
import Card from '@front_common/Components/Card';
import styled from 'styled-components';

export type Feedback = {
  stars: number;
  name: string;
  flag: string;
  country?: string;
  city?: string;
  text: string;
};

function renderStars(number: number) {
  let res = [];

  for (let index = 0; index < number; index++) {
    res.push(<StarGoldIcon key={index} />);
  }

  return res;
}

let OPTIONS: EmblaOptionsType = {
  slidesToScroll: 1,
  duration: 20,
  align: 'start',
};

type IProps = {
  feedbacksData: Feedback[];
  baseClassName: string;
};

export default function Feedbacks(props: IProps) {
  let { feedbacksData, baseClassName } = props;

  return (
    <Carousel
      slides={feedbacksData}
      options={OPTIONS}
      baseClassName={baseClassName}
      getSlideComponent={(data: Feedback) => <Slide slideData={data} />}
      prevBtnChildren={
        <ButtonLeftWrapper>
          <ChevronLeftIcon />
        </ButtonLeftWrapper>
      }
      nextBtnChildren={
        <ButtonRightWrapper>
          <ChevronLeftIcon />
        </ButtonRightWrapper>
      }
    />
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Feedback;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <SlideWrapper>
      <StyledCard>
        <Stars>{renderStars(slideData.stars)}</Stars>
        <FlagEtc>
          <FlagWrapper>
            <FlagStyled
              countryCode={slideData.flag}
              circle={false}
              loading="lazy"
              className={slideData.flag === 'JP' ? 'japan' : ''}
            />
          </FlagWrapper>
          <UserCountry>
            <User>{slideData.name}</User>
            <CountryCity>{`used eSIM card in ${slideData.city || slideData.country}`}</CountryCity>
          </UserCountry>
        </FlagEtc>
        <FeedbackText>{slideData.text}</FeedbackText>
      </StyledCard>
    </SlideWrapper>
  );
}

let SlideWrapper = styled.article`
  height: 344px;

  @media (min-width: 700px) {
    height: 350px;
  }

  @media (min-width: 960px) {
    height: 376px;
  }
`;

let StyledCard = styled(Card)`
  font-family: var(--fontFamilyPrimary);
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: initial;

  @media (min-width: 960px) {
    padding: 20px;
  }
`;

let Stars = styled.div`
  display: flex;
  gap: 6px;
`;

let FlagEtc = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

let FlagWrapper = styled.div`
  min-width: 57px;
`;

let FlagStyled = styled(Flag)`
  height: 38px;
  overflow: hidden;
  border-radius: 10px;

  &.japan {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

let UserCountry = styled.div`
  display: flex;
  flex-direction: column;
`;

let User = styled.span`
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightBold);
  line-height: 30px;
`;

let CountryCity = styled.h3`
  font-size: var(--fontSizeRegular);
  line-height: 24px;
  color: var(--textPrimary);
  opacity: 0.5;
  font-weight: var(--fontWeightRegular);
`;

let FeedbackText = styled.p`
  font-size: var(--fontSizeBig);
  line-height: 24px;
`;

let ButtonLeftWrapper = styled.div`
  position: relative;
  height: 100%;
`;

let ButtonRightWrapper = styled.div`
  right: 0;
  height: 100%;
  transform: rotate(180deg);
`;
