import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import Toggle from '@front_common/Components/Toggle';
import {
  useGetMyEsimsQuery,
  useGetMySubscriptions,
} from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';
import {
  checkLoginOrRedirect,
  getQueryParam,
  removeQueryParam,
  routeRedirect,
  setQueryParam,
} from '@front_common/Router/Router';
import Hint from '../../InstallFlow/Hint';
import InstallModal from '../../InstallFlow/InstallModal';
import ReminderModal from '../../InstallFlow/ReminderModal';
import ESimCard from './ESimCard';
import ESimsPageHeader from './ESimsPageHeader';
import ESimsEmptyList from './ESimsEmptyList';
import { useCountriesActiveQuery } from '@front_common/Api/VisitorApi';
import { ESimCardProvider } from '../ESimContext';
import ESimSubCard from './ESimSubCard/ESimSubCard';
import {
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { getSubscriptionByEsimId } from '../Utils';
import RenewalModal from '../ESimPage/RenewalModal/RenewalModal';
import UnpaidSubscriptionModal from '../../Subscription/UnpaidSubscriptionModal';
import {
  SHOW_ESIM_QR,
  SHOW_HINT,
} from '@shared_frontend/Module/InstallFlow/Constants';

function processESimClick(esimId: number) {
  routeRedirect(`${WEB_ROUTE_MY_ESIMS}/${esimId}`);
}

function onRepurchaseClick() {
  routeRedirect(WEB_ROUTE_STORE_GLOBAL);
}

type FilterCardStatus = 'current' | 'archive';

export default function EsimsPage() {
  checkLoginOrRedirect();
  let [response] = useGetMyEsimsQuery();
  let [responseSub] = useGetMySubscriptions();

  //TODO: use isLoading variable here
  if (!response || !responseSub) {
    return <LoadingAnimation />;
  }

  let [allActiveCountries] = useCountriesActiveQuery();

  let showHintParam = getQueryParam(SHOW_HINT);
  let initialFilterStatus = getQueryParam('status');

  let [isShowAllESims, setIsShowAllESims] = useState(false);
  let [showQrForId, setShowQrForId] = useState<string | null>(null);
  let [filterCardStatus, setFilterCardStatus] = useState<FilterCardStatus>(
    (initialFilterStatus as FilterCardStatus) || 'current',
  );
  let [filteredESims, setFilteredESims] = useState<Array<EsimEntityType>>([]);
  let [visibleESims, setVisibleESims] = useState<Array<EsimEntityType>>([]);

  useEffect(() => {
    let esimIdParam = getQueryParam(SHOW_ESIM_QR);
    setShowQrForId(esimIdParam);
  }, []);

  useEffect(() => {
    let filtered = response.esims
      .filter((item) =>
        filterCardStatus === 'current' ? !item.archived : item.archived,
      )
      .sort((aSim, bSim) => {
        if (!aSim.id && bSim.id) {
          return -1;
        }

        if (aSim.id && !bSim.id) {
          return 1;
        }

        return bSim.id - aSim.id;
      });

    if (showHintParam) {
      setIsShowAllESims(true);
      let index = filtered.findIndex(
        (item) => item.id === Number(showHintParam),
      );
      if (index !== -1) {
        let [selectedItem] = filtered.splice(index, 1);
        // @ts-ignore
        filtered.unshift(selectedItem);
      }
    }

    setFilteredESims(filtered);
  }, [response.esims, filterCardStatus, showHintParam]);

  useEffect(() => {
    setVisibleESims(isShowAllESims ? filteredESims : filteredESims.slice(0, 7));
  }, [filteredESims, isShowAllESims]);

  function handleCloseInstall() {
    removeQueryParam(SHOW_ESIM_QR);
    setShowQrForId(null);
  }

  function handleCardStatusToggle(position: 'left' | 'right') {
    let filterBy: FilterCardStatus =
      position === 'left' ? 'current' : 'archive';

    setFilterCardStatus(filterBy);

    if (filterBy === 'archive') {
      setQueryParam('status', filterBy);
    } else {
      removeQueryParam('status');
    }
  }

  function handleShowAllESims() {
    setIsShowAllESims(true);
  }

  function checkIfESimExistInActiveCountries(countryCode: string | null) {
    if (!countryCode) {
      return false;
    }
    return allActiveCountries
      ? Boolean(allActiveCountries[countryCode])
      : false;
  }

  function onInstallNowSubClick(esimId: number) {
    setShowQrForId(esimId.toString());
  }

  return (
    <MyESimsWrapperStyled>
      <ESimsPageHeader showAddNewESimBtn={filteredESims.length > 0} />
      <ToggleWrapperStyled>
        <Toggle
          leftButtonTitle="Current"
          rightButtonTitle="Archived"
          initial={filterCardStatus === 'current' ? 'left' : 'right'}
          onChange={handleCardStatusToggle}
        />
      </ToggleWrapperStyled>
      {filteredESims.length > 0 ? (
        <>
          {visibleESims.map((eSim) => {
            let subscription = getSubscriptionByEsimId(responseSub, eSim.id);

            return (
              <ESimCardProvider
                key={eSim.id}
                eSim={eSim}
                isFromActive={checkIfESimExistInActiveCountries(
                  eSim.targetCountry,
                )}
              >
                {eSim.subscriptionId ? (
                  <>
                    {subscription && (
                      <ESimSubCard
                        eSim={eSim}
                        subscription={subscription}
                        onCardClick={() => processESimClick(eSim.id)}
                        onInstallNowClick={() => onInstallNowSubClick(eSim.id)}
                        onRepurchaseClick={onRepurchaseClick}
                      />
                    )}
                  </>
                ) : (
                  <ESimCard
                    setShowQrForId={setShowQrForId}
                    onCardClick={() => processESimClick(eSim.id)}
                  />
                )}
              </ESimCardProvider>
            );
          })}
          {filteredESims.length > 7 &&
            filteredESims.length !== visibleESims.length && (
              <ShowMoreButton onClick={handleShowAllESims}>
                Show more
              </ShowMoreButton>
            )}
        </>
      ) : (
        <ESimsEmptyList isCurrentESims={filterCardStatus === 'current'} />
      )}
      <Hint />
      <InstallModal
        show={Boolean(showQrForId)}
        esimId={showQrForId || ''}
        onClose={handleCloseInstall}
      />
      <ReminderModal />
      <RenewalModal />
      <UnpaidSubscriptionModal />
    </MyESimsWrapperStyled>
  );
}

let MyESimsWrapperStyled = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 700px) {
    width: 414px;
  }
`;

let ToggleWrapperStyled = styled.div`
  margin-bottom: 20px;
`;

let ShowMoreButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: var(--textLink);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
`;
