export default function TimeSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116047)">
        <path
          d="M21 8H17V3C17 2.20435 16.6839 1.44129 16.1213 0.87868C15.5587 0.31607 14.7956 0 14 0L10 0C9.20435 0 8.44129 0.31607 7.87868 0.87868C7.31607 1.44129 7 2.20435 7 3V8H3C2.20435 8 1.44129 8.31607 0.87868 8.87868C0.31607 9.44129 0 10.2044 0 11L0 22H2V24H4V22H20V24H22V22H24V11C24 10.2044 23.6839 9.44129 23.1213 8.87868C22.5587 8.31607 21.7956 8 21 8ZM9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V8H9V3ZM22 20H2V11C2 10.7348 2.10536 10.4804 2.29289 10.2929C2.48043 10.1054 2.73478 10 3 10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11V20ZM7 12H17V14H7V12Z"
          fill="#21252A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_116047">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
