export default function LargeBlob({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 635 491">
      <path
        d="M455.125 115.91C366.779 105.489 338.404 94.4925 277.162 46.9378C186.224 -23.6563 50.5593 -23.976 11.7654 111.058C-18.0643 214.915 10.6977 343.77 76.899 402.847C188.375 502.334 394.688 520.122 522.75 443.37C633.155 377.211 668.632 249.931 599.791 166.983C576.443 138.849 539.743 125.89 455.125 115.91Z"
        fill="currentColor"
      />
    </svg>
  );
}
