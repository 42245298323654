import { useEffect, useState } from 'preact/hooks';
import { showConsentCountries } from '@shared_frontend/Module/Email/ShowConsentCountries';
import { useSessionData } from '@front_common/Api/Session';
import styled from 'styled-components';

export default function EmailConsent(
  props: React.HTMLAttributes<HTMLInputElement>,
) {
  let { ...rest } = props;

  let [isChecked, setIsChecked] = useState(false);
  let [showElement, setShowElement] = useState(false);

  let sessionData = useSessionData();

  useEffect(() => {
    if (sessionData) {
      if (showConsentCountries.includes(sessionData.country)) {
        setShowElement(true);
      } else {
        setShowElement(false);
        setIsChecked(true);
      }
    }
  }, [sessionData]);

  function handleSwitchChange() {
    setIsChecked((prev) => !prev);
  }

  return (
    <SwitchContainer $show={showElement}>
      <SwitchLabel>
        {/* @ts-ignore */}
        <SwitchInput
          type="checkbox"
          checked={isChecked}
          onChange={handleSwitchChange}
          {...rest}
        />
        <SwitchSlider $isChecked={isChecked} />
      </SwitchLabel>
      <Text>
        I would like to receive special offers, promotions and product updates
        from Ohayu via email.
      </Text>
    </SwitchContainer>
  );
}

let SwitchContainer = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  align-items: center;
  cursor: pointer;
`;

let SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

let SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + div {
    background-color: var(--backgroundPrimaryDarkNew);
  }

  &:checked + div:before {
    transform: translateX(12px);
  }
`;

let SwitchSlider = styled.div<{ $isChecked: boolean }>`
  position: relative;
  cursor: pointer;
  background-color: var(--backgroundPrimaryDarkNew);
  border-radius: 20px;
  transition: 0.3s;
  width: 36px;
  height: 24px;
  margin-right: 16px;
  opacity: ${(props) => (props.$isChecked ? 1 : '35%')};

  &:before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
  }
`;

let Text = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: var(--textSecondaryNew);
`;
