export default function ToggleIcon() {
  return (
    <svg viewBox="0 0 32 32" width="32" height="32">
      <path
        d="M23 8H9C6.87827 8 4.84344 8.84285 3.34315 10.3431C1.84285 11.8434 1 13.8783 1 16C1 18.1217 1.84285 20.1566 3.34315 21.6569C4.84344 23.1571 6.87827 24 9 24H23C25.1217 24 27.1566 23.1571 28.6569 21.6569C30.1571 20.1566 31 18.1217 31 16C31 13.8783 30.1571 11.8434 28.6569 10.3431C27.1566 8.84285 25.1217 8 23 8ZM23 22C21.8133 22 20.6533 21.6481 19.6666 20.9888C18.6799 20.3295 17.9108 19.3925 17.4567 18.2961C17.0026 17.1997 16.8838 15.9933 17.1153 14.8295C17.3468 13.6656 17.9182 12.5965 18.7574 11.7574C19.5965 10.9182 20.6656 10.3468 21.8295 10.1153C22.9933 9.88378 24.1997 10.0026 25.2961 10.4567C26.3925 10.9108 27.3295 11.6799 27.9888 12.6666C28.6481 13.6533 29 14.8133 29 16C28.9987 17.5909 28.3662 19.1163 27.2412 20.2412C26.1163 21.3662 24.5909 21.9987 23 22Z"
        fill="currentColor"
      />
    </svg>
  );
}
