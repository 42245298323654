import AbstractApi from './AbstractApi';
import { WEB_ROUTE_AUTH } from '@shared_frontend/Common/WebRoutes';
import {
  API_ROUTE_CREATE_PAYMENT,
  API_ROUTE_CREATE_SUB_PAYMENT,
  API_ROUTE_GET_SUB_PAYMENT_STATUS,
  API_ROUTE_MRK_EMAILS_UNSUBSCRIBE,
  API_ROUTE_MY_ESIMS,
  API_ROUTE_MY_PAYMENT_SESSIONS,
  API_ROUTE_MY_PAYMENTS,
  API_ROUTE_MY_SUBS,
  API_ROUTE_PAYMENT_APPLY_DISCOUNT,
  API_ROUTE_QRCODE,
  API_ROUTE_SUB_CANCEL,
  API_ROUTE_SUB_CANCEL_FEEDBACK,
} from '@shared_backend/ApiRoutes';
import PaymentResponse from '@shared_backend/Module/Payment/PaymentResponse';
import { CONFIG_API_HOST } from '@front_common/Config';
import AuthService from '@front_common/Api/AuthService';
import MyEsimResponse from '@shared_backend/Module/Esim/MyEsimResponse';
import { API_ROUTE_MY_USER } from '@shared_backend/ApiRoutes.ts';
import MyUserResponse from '@shared_backend/Module/Profile/MyUserResponse';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import { base64UrlEncode } from '@shared_frontend/Common/Base64Url';
import { routeHardRedirectWithReload } from '@front_common/Router/Router';
import MyPaymentResponse from '@shared_backend/Module/Payment/MyPaymentResponse';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import BooleanResponse from '@shared_backend/Module/Common/BooleanResponse';
import ApplyDiscountRequest from '@shared_backend/Module/Payment/Gateway/ApplyDiscountRequest';
import MySubscriptionResponse from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { asyncHook } from '@shared_frontend/Common/HookFactory';
import PaymentSessionsResponse from '@shared_backend/Module/Payment/PaymentSessionsResponse';
import SubCancelFeedbackRequest from '@shared_backend/Module/Subscription/SubCancelFeedbackRequest';

export function getAddressCodeString(address: string, code: string) {
  if (!address || address === '' || !code || code === '') {
    logger.error(
      'Rendering broken QR code ' + JSON.stringify({ address, code }),
    );
  }
  return `LPA:1$${address}$${code}`;
}

export function getAppleUniversalLink(
  smdpAddress: string,
  activationCode: string,
) {
  let cardData = getAddressCodeString(smdpAddress, activationCode);

  return (
    'https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=' + cardData
  );
}

export default class UserApi extends AbstractApi {
  protected static async request<T>(method: string, options: RequestInit) {
    let loginTokenBasic = AuthService.getLoginTokenBasic();
    if (loginTokenBasic === false) {
      routeHardRedirectWithReload(WEB_ROUTE_AUTH);
    }
    this.setHeaderForAll('Authorization', 'Basic ' + loginTokenBasic);
    this.setHeaderForAll(
      'X-Visitor-Token',
      await AuthService.getVisitorToken(),
    );
    return AbstractApi.request<T>(method, options);
  }

  static async getUser() {
    let result: MyUserResponse = await this.get(API_ROUTE_MY_USER);
    return result.user;
  }

  static async createPaymentSession(
    bundleCodeAndPriceId: string,
    targetCountry: string,
  ) {
    let response = await this.post<PaymentResponse>(
      `${fillRouteParams(API_ROUTE_CREATE_PAYMENT, {
        bundleCodeAndPriceId,
      })}?target_country=${targetCountry}`,
    );
    return response.paymentSession;
  }

  static async createSubscriptionAttempt(subProductId: number) {
    let response: PaymentResponse = await this.post(
      fillRouteParams(API_ROUTE_CREATE_SUB_PAYMENT, {
        productId: subProductId,
      }),
    );
    return response.paymentSession;
  }

  //TODO: why do we use this function in UserApi meanwhile we may use it the VisitorApi for unauthenticated users?
  static async getSubscriptionAttemptStatus(subAttemptId: number) {
    let response: PaymentResponse = await this.get(
      fillRouteParams(API_ROUTE_GET_SUB_PAYMENT_STATUS, {
        paymentSessionId: subAttemptId,
      }),
    );
    return response.paymentSession;
  }

  static async getMySubscriptions() {
    let response: MySubscriptionResponse = await this.get(API_ROUTE_MY_SUBS);
    return response.subscriptions;
  }

  static async cancelSubscription(subscriptionId: number) {
    let response: BooleanResponse = await this.post(
      fillRouteParams(API_ROUTE_SUB_CANCEL, { subId: subscriptionId }),
    );
    return response.result;
  }

  static async sendCancelSubscriptionFeedback(
    subscriptionId: number,
    data: SubCancelFeedbackRequest,
  ) {
    let response: BooleanResponse = await this.post(
      fillRouteParams(API_ROUTE_SUB_CANCEL_FEEDBACK, { subId: subscriptionId }),
      data,
    );
    return response.result;
  }

  static qrCodeImageUrl(smdpAddress: string, activationCode: string) {
    let data = getAddressCodeString(smdpAddress, activationCode);
    let base64data = base64UrlEncode(data);
    return CONFIG_API_HOST + fillRouteParams(API_ROUTE_QRCODE, { base64data });
  }

  static async getMyEsims(): Promise<MyEsimResponse> {
    return this.get<MyEsimResponse>(API_ROUTE_MY_ESIMS);
  }

  static async getMyPayments(): Promise<MyPaymentResponse> {
    return this.get<MyPaymentResponse>(API_ROUTE_MY_PAYMENTS);
  }

  static async getMyPaymentSessions(): Promise<PaymentSessionsResponse> {
    return this.get(API_ROUTE_MY_PAYMENT_SESSIONS);
  }

  static async isRebill(paidPaymentSessionId: number): Promise<boolean> {
    let paySessionsResponse = await this.getMyPaymentSessions();
    let payments = 0;
    if (
      !paySessionsResponse ||
      !paySessionsResponse.paymentSessions ||
      paySessionsResponse.paymentSessions.length === 0
    ) {
      return false;
    }
    for (let paySession of paySessionsResponse.paymentSessions) {
      if (paySession.id === paidPaymentSessionId) {
        continue;
      }
      if (!paySession.paid) {
        continue;
      }
      payments++;
    }
    return payments > 0;
  }

  static async unsubscribeMrkEmails() {
    return await this.post<BooleanResponse>(API_ROUTE_MRK_EMAILS_UNSUBSCRIBE);
  }

  static async applyDiscount(
    bundleCodeAndPriceId: string,
    targetCountry: string,
    discountCode: string,
  ) {
    let data: ApplyDiscountRequest = {
      bundleCodeAndPriceId,
      targetCountry,
      discountCode,
    };

    let response = await this.post<PaymentResponse>(
      API_ROUTE_PAYMENT_APPLY_DISCOUNT,
      data,
    );
    return response.paymentSession;
  }
}

export const useGetUserQuery = asyncHook(() => UserApi.getUser());
export const useGetMyEsimsQuery = asyncHook(() => UserApi.getMyEsims());
export const useGetMySubscriptions = asyncHook(() =>
  UserApi.getMySubscriptions(),
);

export const useCreatePaymentSession = asyncHook(
  (bundleCodeAndPriceId: string, targetCountry: string) =>
    UserApi.createPaymentSession(bundleCodeAndPriceId, targetCountry),
);
export const useCreateSubscriptionAttempt = asyncHook((subProductId: number) =>
  UserApi.createSubscriptionAttempt(subProductId),
);
