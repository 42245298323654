export default function UmbrellaIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M30.472 12.765C29.6936 9.66052 27.891 6.90923 25.3557 4.95583C22.8203 3.00242 19.7004 1.96104 16.5 2C13.2997 1.96122 10.1798 3.00267 7.64449 4.95605C5.10919 6.90942 3.30659 9.66061 2.528 12.765C2.49245 12.9121 2.49073 13.0653 2.52298 13.2131C2.55523 13.361 2.62061 13.4996 2.71419 13.6185C2.80777 13.7374 2.92712 13.8335 3.06323 13.8996C3.19935 13.9657 3.34869 14 3.5 14H15.5V28H12.5C12.2348 28 11.9804 28.1054 11.7929 28.2929C11.6054 28.4804 11.5 28.7348 11.5 29C11.5 29.2652 11.6054 29.5196 11.7929 29.7071C11.9804 29.8946 12.2348 30 12.5 30H20.5C20.7652 30 21.0196 29.8946 21.2071 29.7071C21.3946 29.5196 21.5 29.2652 21.5 29C21.5 28.7348 21.3946 28.4804 21.2071 28.2929C21.0196 28.1054 20.7652 28 20.5 28H17.5V14H29.5C29.6513 14 29.8007 13.9657 29.9368 13.8996C30.0729 13.8335 30.1922 13.7374 30.2858 13.6185C30.3794 13.4996 30.4448 13.361 30.477 13.2131C30.5093 13.0653 30.5076 12.9121 30.472 12.765ZM12.9 4.518C11.6381 6.83163 10.8574 9.37671 10.605 12H4.843C5.50115 10.2061 6.57411 8.59316 7.97432 7.29287C9.37454 5.99258 11.0623 5.04177 12.9 4.518ZM12.619 12C13.279 7.029 15.084 4 16.5 4C17.916 4 19.721 7.029 20.381 12H12.619ZM22.4 12C22.1461 9.37636 21.3637 6.83126 20.1 4.518C21.9377 5.04177 23.6255 5.99258 25.0257 7.29287C26.4259 8.59316 27.4989 10.2061 28.157 12H22.4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
