import { logger } from '@shared_frontend/Common/BetterStackLogger';
import { scriptLoader } from '@shared_frontend/Common/JsScriptLoader';
import { CONFIG_ZENDESK_SNIPPET_KEY } from '@front_common/Config.ts';

export const ZE_EVENT_MESSENGER_TYPE = 'ZE_MESSENGER_VISIBILITY';
const ZENDESK_BASE_URL = 'https://static.zdassets.com/ekr/snippet.js';

function notifyWidgetVisibility(variant: 'open' | 'close') {
  window.parent.postMessage(
    { type: ZE_EVENT_MESSENGER_TYPE, variant },
    window.location.origin,
  );
}

function initializeZendesk() {
  let MAX_ATTEMPTS = 10;
  let attempt = 0;
  let isInitialized = false;

  function tryInitialize() {
    try {
      // @ts-ignore
      let { zE: zendesk } = window;

      if (zendesk && typeof zendesk === 'function' && !isInitialized) {
        zendesk('messenger:on', 'open', () => {
          notifyWidgetVisibility('open');
        });
        zendesk('messenger:on', 'close', () => {
          notifyWidgetVisibility('close');
        });

        isInitialized = true;
        return;
      }

      if (!isInitialized && attempt < MAX_ATTEMPTS) {
        attempt++;
        setTimeout(tryInitialize, 1000);
      } else if (!isInitialized) {
        logger.error('Zendesk initialization failed after maximum attempts.');
      }
    } catch (error) {
      logger.error(['Failed to initialize Zendesk chat', error]);
    }
  }

  tryInitialize();
}

export function handleZendeskOpenChatClick() {
  try {
    // @ts-ignore
    let { zE: zendesk } = window;
    if (zendesk && typeof zendesk === 'function') {
      zendesk('messenger', 'open');
    }
  } catch (error) {
    logger.error(['Failed to open Zendesk chat', error]);
  }
}

let zendeskSnippetUrl = `${ZENDESK_BASE_URL}?key=${CONFIG_ZENDESK_SNIPPET_KEY}`;

export async function loadAndInitializeZenDeskWidget() {
  await scriptLoader(zendeskSnippetUrl, 'ze-snippet');
  initializeZendesk();
}
