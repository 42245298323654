export default function StarGoldIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Fill 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4254 6.13678L13.1201 5.64326C12.786 5.61336 12.4972 5.40462 12.36 5.08994L10.46 0.560607C10.1563 -0.186869 9.07711 -0.186869 8.77291 0.560607L6.88766 5.08994C6.76663 5.40462 6.46243 5.61336 6.12763 5.64326L0.823027 6.13678C0.0325995 6.21115 -0.286612 7.18343 0.306399 7.7061L4.30395 11.1594C4.56236 11.3844 4.66838 11.7128 4.59276 12.042L3.39192 16.8853C3.20932 17.6481 4.04573 18.2758 4.74495 17.8721L9.16774 15.316C9.45712 15.151 9.80673 15.151 10.0955 15.316L14.5183 17.8721C15.2183 18.2758 16.054 17.6627 15.8714 16.8853L14.6861 12.042C14.6097 11.7128 14.7165 11.3844 14.9747 11.1594L18.9725 7.7061C19.5499 7.18343 19.2159 6.21115 18.4254 6.13678Z"
        fill="#F4AD6E"
      />
    </svg>
  );
}
