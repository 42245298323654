import { LinkToRoute } from '@front_common/Router/Router';
import React from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '../../Module/Faq/Icons/ChevronRightIcon';

export type RouteConfig = {
  path: string;
  href: string;
  label: React.ReactNode;
  children?: RouteConfig[];
};

function findBreadcrumbs(routeConfig: RouteConfig[], currentPath: string) {
  let breadcrumbs: RouteConfig[] = [];

  function search(nodes: RouteConfig[], path: string) {
    for (let node of nodes) {
      if (node.path === path) {
        breadcrumbs.push(node);
        return true;
      }
      if (node.children && search(node.children, path)) {
        breadcrumbs.push(node);
        return true;
      }
    }
    return false;
  }

  search(routeConfig, currentPath);
  return breadcrumbs.reverse();
}

export default function Breadcrumbs({
  routeConfig,
  currentPath,
}: {
  routeConfig: RouteConfig[];
  currentPath: string;
}) {
  let breadcrumbs = findBreadcrumbs(routeConfig, currentPath);

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Separator>
              <ChevronRightIcon />
            </Separator>
          )}
          {index === breadcrumbs.length - 1 ? (
            <Text>{breadcrumb.label}</Text>
          ) : (
            <BreadcrumbLink
              href={index > 0 ? `${breadcrumb.href}/` : breadcrumb.href}
            >
              <Text>{breadcrumb.label}</Text>
            </BreadcrumbLink>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbsContainer>
  );
}

const BreadcrumbsContainer = styled.nav`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100%;
`;

const BreadcrumbLink = styled(LinkToRoute)`
  text-decoration: none;
  color: var(--textPrimary);
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 16px;

  span {
    font-weight: var(--fontWeightRegular);
  }
`;

const Separator = styled.div`
  margin-right: 10px;
  margin-left: 6px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--fontWeightSemiBold);
`;
