import {
  useBundleByFullCodeQuery,
  usePaymentSessionForUser,
  usePriceQuery,
} from '@front_common/Api/VisitorApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import SolidgateProcessor from '../Checkout/PaymentProcessor/SolidgateProcessor/OldDesign/SolidgateProcessor';
import {
  getQueryParam,
  getQueryParamOrFail,
  routeHardRedirectWithReload,
} from '@front_common/Router/Router';
import ProductInfo from './ProductInfo';
import { useState } from 'preact/hooks';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import { getEsimPlanCountry } from '@front_common/Components/EsimPlanName';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import styled from 'styled-components';

function onSuccess() {
  routeHardRedirectWithReload(window.location.href + '&payment_success=1');
}

export default function PaymentWebview() {
  // eslint-disable-next-line secrets/no-secrets
  // http://localhost:5173/webview/payment?hide_layout=1&pay_session=1&user_id=1

  let [errorText, setErrorText] = useState<string>('');

  let success = getQueryParam('payment_success');
  if (success) {
    return <>Payment successful.</>;
  }

  let paymentSessionId = getQueryParamOrFail('pay_session');
  let userId: number = Number(getQueryParamOrFail('user_id'));

  // eslint-disable-next-line unicorn/prefer-number-properties
  if (!paymentSessionId || !userId || isNaN(Number(paymentSessionId))) {
    return <>wrong parameters</>;
  }

  let [paymentSession, createSessionError, isLoading] =
    usePaymentSessionForUser(Number(paymentSessionId), userId);
  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (createSessionError) {
    return <ErrorBlock message={createSessionError.message} />;
  }

  if (!paymentSession || !paymentSession.targetCountry) {
    return <LoadingAnimation />;
  }

  if (paymentSession.paid) {
    onSuccess();
    return <></>;
  }

  if (!paymentSession.bundlePriceId) {
    logger.error('404: Bundle price ID is not defined!');
    throw new Error('404: Bundle price ID is not defined!');
  }

  let [price, priceError] = usePriceQuery(paymentSession.bundlePriceId);

  if (priceError) {
    return <ErrorBlock message={priceError.message} />;
  }

  if (!price) {
    return <LoadingAnimation />;
  }

  let [bundle, bundleError] = useBundleByFullCodeQuery(price.bundle);
  if (bundleError) {
    return <ErrorBlock message={bundleError.message} />;
  }
  if (!bundle) {
    return <LoadingAnimation />;
  }

  return (
    <Wrapper>
      <ProductInfo
        dataAmountGb={bundle.dataAmountGb}
        durationDays={bundle.durationDays}
        countryName={getEsimPlanCountry(paymentSession.targetCountry)}
        totalAmount={price.totalAmountCents / 100}
        targetCountry={paymentSession.targetCountry}
      />
      <Space />
      {errorText && <ErrorBlock message={'Payment error: ' + errorText} />}
      <SolidgateProcessor
        paymentSession={paymentSession}
        isWebView={true}
        onSuccess={onSuccess}
        onFail={setErrorText}
      />
    </Wrapper>
  );
}

let Space = styled.div`
  height: 16px;
`;

let Wrapper = styled.div`
  max-width: 410px;
  margin: 0 auto;
`;
