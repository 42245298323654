export default function CheckboxCircleIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_115840)">
        <path
          d="M16 16.5H0V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5L14 0.5C14.5304 0.5 15.0391 0.710714 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5V16.5ZM1.33333 15.1667H14.6667V2.5C14.6667 2.32319 14.5964 2.15362 14.4714 2.0286C14.3464 1.90357 14.1768 1.83333 14 1.83333H2C1.82319 1.83333 1.65362 1.90357 1.5286 2.0286C1.40357 2.15362 1.33333 2.32319 1.33333 2.5V15.1667Z"
          fill="#68C36B"
        />
        <path
          d="M6.22198 12.4463C6.04682 12.4468 5.87329 12.4125 5.71148 12.3455C5.54966 12.2784 5.40276 12.1799 5.27931 12.0556L2.19531 8.97163L3.13798 8.02896L6.22198 11.113L12.862 4.47363L13.8046 5.4163L7.16531 12.0556C7.04174 12.1799 6.89473 12.2784 6.7328 12.3455C6.57087 12.4126 6.39724 12.4468 6.22198 12.4463Z"
          fill="#68C36B"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_115840">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
