export const POPULAR_COUNTRIES = [
  'US',
  'AE',
  'TR',
  'GB',
  'ES',
  'CN',
  'FR',
  'JP',
  'IT',
  'TH',
  'IN',
  'BR',
];
