import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import { useEffect, useRef, useState } from 'preact/hooks';
import EsimReadyContent from './EsimReadyContent';
import PaymentSuccessfulContent from './PaymentSuccessfulContent';
import WaitContent from './WaitContent';
import UserApi from '@front_common/Api/UserApi';
import { ReactElement } from 'preact/compat';
import { T_PaymentHistory } from '@shared_backend/Module/Payment/MyPaymentResponse';
import { setInstallLaterClicked, setReminderShown } from './ReminderModal';
import { routeRedirect } from '@front_common/Router/Router';
import { WEB_ROUTE_MY_ESIMS } from '@shared_frontend/Common/WebRoutes';

type SuccessModalProps = {
  show: boolean;
  paymentSessionId: number;
};

function useInterval(
  callback: () => void,
  intervalDuration: number,
  maxDuration: number,
  allCompletedCallback?: () => void,
) {
  let intervalId = setInterval(() => {
    callback();
  }, intervalDuration * 1000);

  let timeoutId = setTimeout(() => {
    clearInterval(intervalId);

    if (allCompletedCallback) {
      clearTimeout(timeoutId);
      allCompletedCallback();
    }
  }, maxDuration * 1000);

  return () => {
    clearTimeout(timeoutId);
    clearInterval(intervalId);
  };
}

async function getMyPayments() {
  let { payments } = await UserApi.getMyPayments();
  return payments;
}

function getEsimIdBySessionId(
  payments: T_PaymentHistory[],
  paymentSessionId: number,
) {
  let searchedEsimId = payments.find(
    (payment) => payment.paymentSessionId === paymentSessionId,
  )?.esimId;

  return searchedEsimId;
}

function handleClose() {
  setReminderShown();
  setInstallLaterClicked('');
  routeRedirect(WEB_ROUTE_MY_ESIMS);
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default function SuccessModal(props: SuccessModalProps) {
  let { show, paymentSessionId } = props;

  let [isModalOpen, setIsModalOpen] = useState(show);
  //TODO: check if this approach is correct and safe
  let [content, setContent] = useState<ReactElement | null>(
    <PaymentSuccessfulContent />,
  );

  let clearFirstCheckTimers = useRef<(() => void) | null>(null);
  let clearSecondCheckTimers = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (show) {
      checkPaymentsInitial();
    }

    setIsModalOpen(show);
  }, [show]);

  useEffect(
    () => () => {
      if (clearFirstCheckTimers.current && clearSecondCheckTimers.current) {
        clearFirstCheckTimers.current();
        clearSecondCheckTimers.current();
      }
    },
    [],
  );

  async function checkPaymentsInitial() {
    // wait at least 3 seconds for showing the esim ready content
    let [payments] = await Promise.all([getMyPayments(), delay(3000)]);

    let esimId = getEsimIdBySessionId(payments, paymentSessionId);

    if (esimId) {
      setContent(<EsimReadyContent esimId={esimId} />);
    } else {
      let clearTimers = useInterval(
        firstCheckPayment,
        10,
        30,
        startSecondCheckPayments,
      );
      clearFirstCheckTimers.current = clearTimers;
    }
  }

  function firstCheckPayment() {
    // eslint-disable-next-line promise/catch-or-return
    getMyPayments()
      // eslint-disable-next-line no-shadow
      .then((payments) => {
        // eslint-disable-next-line promise/always-return
        let esimId = getEsimIdBySessionId(payments, paymentSessionId);

        if (esimId && clearFirstCheckTimers?.current) {
          clearFirstCheckTimers?.current();

          setContent(<EsimReadyContent esimId={esimId} />);
        }

        return null;
      })
      .catch(() => setContent(<WaitContent />));
  }

  function startSecondCheckPayments() {
    let clearTimers = useInterval(secondCheckPayment, 10, 60);
    clearSecondCheckTimers.current = clearTimers;

    setContent(<WaitContent />);
  }

  function secondCheckPayment() {
    // eslint-disable-next-line promise/catch-or-return
    getMyPayments()
      // eslint-disable-next-line no-shadow
      .then((payments) => {
        let esimId = getEsimIdBySessionId(payments, paymentSessionId);

        if (esimId && clearSecondCheckTimers.current) {
          clearSecondCheckTimers.current();

          setContent(<EsimReadyContent esimId={esimId} />);
        }

        return null;
      })
      .catch(() => setContent(<WaitContent />));
  }

  function closeModal() {
    handleClose();
    setIsModalOpen(false);
  }

  return (
    <Modal
      analyticType="successfull-payment"
      isOpen={isModalOpen}
      onClose={closeModal}
      overlayClick={false}
      hideCloseButton={content?.type !== WaitContent}
    >
      {content}
    </Modal>
  );
}
