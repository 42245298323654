export function MicrosoftIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.1053 20.1057H2.99951V3H20.1053V20.1057Z" fill="#F1511B" />
      <path
        d="M38.9969 20.1057H21.8867V3H38.9925V20.1057H38.9969Z"
        fill="#80CC28"
      />
      <path
        d="M20.1053 39.0003H2.99951V21.8945H20.1053V39.0003Z"
        fill="#00ADEF"
      />
      <path
        d="M38.9969 39.0003H21.8867V21.8945H38.9925V39.0003H38.9969Z"
        fill="#FBBC09"
      />
    </svg>
  );
}
