import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from './useDynamicSplitComponent';
import { PageShortFooter as OldDesignPageShortFooter } from './OldDesign/PageFooter';
import { PageShortFooter as NewDesignPageShortFooter } from './NewDesign/PageFooter';

type PageShortFooterProps = {
  splitGroupId: number;
  withBottomMargin: boolean;
};

export function ShortFooterContainer<P extends PageShortFooterProps>(props: P) {
  let { splitGroupId } = props;

  let ShortFooterComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignPageShortFooter,
      [DesignSplitGroupId.B]: NewDesignPageShortFooter,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return <ShortFooterComponent {...(props as P & JSX.IntrinsicAttributes)} />;
}
