import { isValidElement } from 'preact';
import { useEffect } from 'preact/hooks';
import { ReactNode } from 'react';
import { CONFIG_MIRROR_NAME } from '@front_common/Config';
import { setTitleHeader } from '@front_common/Seo/SeoHeaders';

export default function PageTitleText({ children }: { children: ReactNode }) {
  useEffect(() => {
    let childrenText = renderComponentToText(children);
    childrenText = childrenText.trim();

    if (childrenText !== '') {
      setTitleHeader(String(childrenText) + ' | ' + CONFIG_MIRROR_NAME);
    }
  }, []);

  return <>{children}</>;
}

function renderComponentToText(component: ReactNode): string {
  if (typeof component === 'string') {
    return component;
  }

  if (Array.isArray(component)) {
    let stringValue = '';
    let success = true;
    for (let item of component) {
      if (typeof item === 'string' || typeof item === 'number') {
        stringValue += item;
      } else {
        success = false;
      }
    }
    if (success) {
      return stringValue;
    }
  }

  if (isValidElement(component)) {
    let children = component.props.children;

    if (typeof children === 'string') {
      return children;
    }

    if (Array.isArray(children)) {
      return children
        .map((subComponent: ReactNode) => renderComponentToText(subComponent))
        .join(' ');
    }
  }

  return '';
}
