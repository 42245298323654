const BotUserAgentParts = [
  'facebookexternalhit',
  'Googlebot',
  'AdsBot',
  'Applebot',
  'bingbot',
  'Google-Read-Aloud',
  'Google-InspectionTool',
  'AdsBot-Google-Mobile',
  'VirusTotalBot',
  'AdsBot-Google',
  'YandexBot',
  'AhrefsBot',
];

export function isBot(userAgent: string): boolean {
  for (let uaPart of BotUserAgentParts) {
    if (userAgent.includes(uaPart)) {
      return true;
    }
  }
  return false;
}
