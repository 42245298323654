// @ts-expect-error
const head: HTMLHeadElement = document.querySelector('head');

export function setTitleHeader(value: string) {
  document.title = value;
  // @ts-ignore
  let openGraph: HTMLMetaElement | null = document.querySelector(
    'meta[property="og:title"]',
  );
  if (openGraph !== null) {
    openGraph.content = value;
  }
  // @ts-ignore
  let twitter: HTMLMetaElement | null = document.querySelector(
    'meta[name="twitter:title"]',
  );
  if (twitter !== null) {
    twitter.content = value;
  }
}

export function setDescriptionHeader(value: string) {
  let tag: HTMLMetaElement | null;
  tag = document.querySelector('meta[name="description"]');
  if (tag !== null) {
    tag.content = value;
  }
  tag = document.querySelector('meta[name="twitter:description"]');
  if (tag !== null) {
    tag.content = value;
  }
  tag = document.querySelector('meta[property="og:description"]');
  if (tag !== null) {
    tag.content = value;
  }
}

let canonicalElement: { href: string; rel: string } | null = null;

export function setCanonicalHeader(value: string) {
  if (value.includes('?')) {
    // eslint-disable-next-line no-param-reassign
    value = value.slice(0, value.indexOf('?'));
  }
  if (!canonicalElement) {
    // @ts-expect-error
    canonicalElement = document.querySelector('link[rel="canonical"]');
  }
  if (!canonicalElement) {
    canonicalElement = document.createElement('link');
    canonicalElement.rel = 'canonical';
    // @ts-expect-error
    head.prepend(canonicalElement);
  }
  canonicalElement.href = value;
}

export function removeCanonicalHeader() {
  let canonical = document.querySelector('link[rel="canonical"]');

  if (canonical) {
    canonical.remove();
    canonicalElement = null;
  }
}

export function addRobotsMetaTag(content: string) {
  let metaTag = document.createElement('meta');
  metaTag.name = 'robots';
  metaTag.content = content;
  document.head.append(metaTag);
}

let organizationSchemaElement: HTMLScriptElement | null = null;

export function setOrganizationSchema(
  data: Record<string, unknown> | null,
): void {
  if (!data) {
    if (organizationSchemaElement) {
      organizationSchemaElement.remove();
      organizationSchemaElement = null;
    }
    return;
  }

  if (!organizationSchemaElement) {
    organizationSchemaElement = document.querySelector<HTMLScriptElement>(
      '#organization-structure',
    );

    if (!organizationSchemaElement) {
      organizationSchemaElement = document.createElement('script');
      organizationSchemaElement.id = 'organization-structure';
      organizationSchemaElement.type = 'application/ld+json';
      head.prepend(organizationSchemaElement);
    }
  }

  organizationSchemaElement.textContent = JSON.stringify(data);
}
