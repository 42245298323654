import { MODAL_HEIGHT } from '@front_common/Components/Modal/OldDesign/Modal';
import styled from 'styled-components';
import PlanDetails from './PlanDetails';
import CountriesDetails from './CountriesDetails';
import { Tab, Tabs } from '../Tabs';
import { ReactElement } from 'preact/compat';

type IProps = {
  preOpenedIndexTab: number;
  footerBlock?: ReactElement;
  onTabChange?: (index: number) => void;
};

export default function DetailsModalContent(props: IProps) {
  let { preOpenedIndexTab, footerBlock, onTabChange } = props;

  let tabs: Tab[] = [
    {
      label: 'Plan details',
      content: (
        <TabBody $withCTABlock={Boolean(footerBlock)}>
          <PlanDetails />
        </TabBody>
      ),
    },
    {
      label: 'Covered countries',
      content: (
        <TabBody $withCTABlock={Boolean(footerBlock)}>
          <CountriesDetails />
        </TabBody>
      ),
    },
  ];

  return (
    <ModalContent>
      <Top>
        <Title>Details</Title>
      </Top>
      <Bottom>
        <Tabs
          tabs={tabs}
          defaultIndex={preOpenedIndexTab}
          tabListClassName="details-modal-content-tabs"
          onTabChange={onTabChange}
        />
      </Bottom>
      {Boolean(footerBlock) && footerBlock}
    </ModalContent>
  );
}

let ModalContent = styled.div`
  width: 100%;
  height: ${MODAL_HEIGHT};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 32px 20px 20px;
  text-align: center;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Bottom = styled.div`
  padding: 20px;
  padding-right: 0;
  flex: 1;

  .details-modal-content-tabs {
    padding: 0;
    padding-right: 20px;
  }
`;

let TabBody = styled.div<{ $withCTABlock: boolean }>`
  max-height: ${(props) =>
    props.$withCTABlock
      ? `calc(${MODAL_HEIGHT} - 276px)`
      : `calc(${MODAL_HEIGHT} - 276px + 88px)`};
  overflow-y: auto;
  padding-right: 20px;

  @media (min-width: 700px) {
    max-height: ${(props) =>
      props.$withCTABlock
        ? `calc(${MODAL_HEIGHT} - 282px)`
        : `calc(${MODAL_HEIGHT} - 282px + 88px)`};
  }
`;
