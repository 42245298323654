import EarthImage from './Images/Earth.png';
import styled from 'styled-components';

export default function LargeHeaderWithCustomers() {
  return (
    <LargeHeaderWithCustomersWrapper>
      <ImageContainer>
        <img src={EarthImage} alt="earth-icom" />
      </ImageContainer>
      <LargeTitle>
        <span> Travel eSIM: </span>
        <ColoredText>get internet </ColoredText>
        <BottomLine>effortlessly, anywhere</BottomLine>
      </LargeTitle>
    </LargeHeaderWithCustomersWrapper>
  );
}

let LargeHeaderWithCustomersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    white-space: nowrap;
  }

  @media (min-width: 960px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

let ImageContainer = styled.div`
  height: 97px;

  img {
    width: 97px;
    height: 97px;
  }

  @media (min-width: 700px) {
    height: 136px;
    img {
      width: 136px;
      height: 136px;
    }
  }

  @media (min-width: 960px) {
    position: absolute;
    right: 32px;
    top: 32px;
    img {
      width: 259px;
      height: 259px;
    }
  }

  @media (min-width: 1104px) {
    right: 64px;
  }
`;

let LargeTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  padding: 0;
  font-weight: var(--fontWeightBold);
  color: var(--textPrimaryNew);
  max-width: 240px;

  @media (min-width: 700px) {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    max-width: 680px;
  }

  @media (min-width: 960px) {
    text-align: left;
    margin: 0;
    max-width: 653px;
  }
`;

let ColoredText = styled.span`
  font-size: 32px;
  line-height: 32px;
  color: var(--textPrimaryInvert);

  @media (min-width: 700px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

let BottomLine = styled.span`
  display: block;
`;
