import Card from '@front_common/Components/Card';
import styled from 'styled-components';
import GlobeImage from './Images/Globe.png';
import LightningImage from './Images/Lightning.png';
import MoneyImage from './Images/Money.png';
import PhoneImage from './Images/Phone.png';

type PlanDetailsProps = {
  validDays: number;
};

export default function PlanDetails(props: PlanDetailsProps) {
  let { validDays } = props;

  return (
    <PlanDetailsWrapper>
      <CardStyled>
        <Subtitle>Features:</Subtitle>
        <Features>
          <FirstPartFeature>
            <FeatureItem>
              <Image src={LightningImage} />
              <Text>
                <BoldPart>️Fast and reliable connection: </BoldPart>
                <PlainPart>We never slow down the speed</PlainPart>
              </Text>
            </FeatureItem>
            <FeatureItem>
              <Image src={MoneyImage} />
              <Text>
                <BoldPart>Cheap travel rates: </BoldPart>
                <PlainPart>
                  Access cost-effective data plans and reduced roaming charges
                  globally
                </PlainPart>
              </Text>
            </FeatureItem>
          </FirstPartFeature>
          <SecondPartFeature>
            <FeatureItem>
              <Image src={GlobeImage} />
              <Text>
                <BoldPart>Stay connected: </BoldPart>
                <PlainPart>
                  Instant access to all apps like Maps, Youtube, Spotify,
                  WhatsApp & more
                </PlainPart>
              </Text>
            </FeatureItem>
            <FeatureItem>
              <Image src={PhoneImage} />
              <Text>
                <BoldPart>Hotspot Enabled: </BoldPart>
                <PlainPart>Share your connection with other devices</PlainPart>
              </Text>
            </FeatureItem>
          </SecondPartFeature>
        </Features>
      </CardStyled>
      <PaddingLessCard>
        <FirstPart>
          <CardRow>
            <SubtitleLessMargin>Valid for</SubtitleLessMargin>
            <RowText>{validDays} days from activation</RowText>
          </CardRow>
          <CardRow>
            <SubtitleLessMargin>Plan Type</SubtitleLessMargin>
            <RowText>Data only. eSIM does not come with a phone number</RowText>
          </CardRow>
          <CardRow>
            <SubtitleLessMargin>Speed</SubtitleLessMargin>
            <RowText>The speed may vary depending on your location</RowText>
          </CardRow>
        </FirstPart>
        <SecondPart>
          <CardRow>
            <SubtitleLessMargin>Delivery</SubtitleLessMargin>
            <RowText>Immediate delivery after purchase</RowText>
          </CardRow>
          <CardRow>
            <SubtitleLessMargin>Activation</SubtitleLessMargin>
            <RowText>Activate anytime within 180 days of purchase</RowText>
          </CardRow>
          <CardRow>
            <SubtitleLessMargin>Cancelation Policy</SubtitleLessMargin>
            <RowText>
              Cancel any time before activation with no data usage for a full
              refund
            </RowText>
          </CardRow>
        </SecondPart>
      </PaddingLessCard>
    </PlanDetailsWrapper>
  );
}

let CardStyled = styled(Card)``;

let PlanDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    gap: 20px;
  }
`;

let Subtitle = styled.p`
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  text-align: left;
  color: var(--textSecondary);
  margin-bottom: 8px;

  @media (min-width: 960px) {
    margin-bottom: 12px;
  }
`;

let Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

let FirstPartFeature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 960px) {
    flex: 1;
  }
`;

let SecondPartFeature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 960px) {
    flex: 1;
  }
`;

let FeatureItem = styled.div`
  display: flex;
  gap: 8px;
`;

let Image = styled.img`
  width: 20px;
  height: 20px;
`;

let Text = styled.span`
  line-height: 22px;
  font-size: var(--fontSizeRegular);
`;

let BoldPart = styled.span`
  font-weight: var(--fontWeightBold);
`;

let PlainPart = styled.span`
  font-weight: var(--fontWeightMedium);
`;

let PaddingLessCard = styled(Card)`
  padding: 0 16px;

  @media (min-width: 960px) {
    display: flex;
    gap: 12px;
  }
`;

let FirstPart = styled.div`
  flex: 1;
`;

let SecondPart = styled.div`
  flex: 1;
`;

let SubtitleLessMargin = styled(Subtitle)`
  margin: initial;
`;

let CardRow = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid var(--strokeSecondary);
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 960px) {
    height: 100px;
    gap: 8px;
  }
`;

let RowText = styled.span`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
`;
