import styled from 'styled-components';
import EmptyESimsImage from './Images/EmptyESimsImage.png';
import Button from '@front_common/Components/Button/Button';
import { routeRedirect } from '@front_common/Router/Router';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';

function processExploreESims() {
  routeRedirect(WEB_ROUTE_HOME);
}

type IProps = {
  isCurrentESims: boolean;
};

export default function ESimsEmptyList({ isCurrentESims }: IProps) {
  return (
    <EmptyESimsWrapperStyled>
      <EmptyListTileStyled>No eSIMs yet</EmptyListTileStyled>
      <img src={EmptyESimsImage} alt="party smile" width={120} height={120} />
      {isCurrentESims ? (
        <EmptyListDescriptionStyled data-testid="empty-plans-list-text">
          Explore our plans and get connected in
          <br />
          <span>199+ countries!</span>
        </EmptyListDescriptionStyled>
      ) : (
        <EmptyListDescriptionStyled>
          Once an eSIM is no longer active, you’ll find it here.
        </EmptyListDescriptionStyled>
      )}
      <ButtonStyled sizeParam="L" onClick={processExploreESims}>
        Explore eSIMs
      </ButtonStyled>
    </EmptyESimsWrapperStyled>
  );
}

let EmptyESimsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

let EmptyListTileStyled = styled.h2`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;

let EmptyListDescriptionStyled = styled.p`
  line-height: 22px;
  margin-bottom: 16px;
  margin-top: 4px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);
  text-align: center;
  span {
    font-weight: var(--fontWeightBold);
  }
`;

let ButtonStyled = styled(Button)`
  width: 100%;

  @media (min-width: 700px) {
    width: 236px;
  }
`;
