export default function SlidersSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_115958)">
        <path
          d="M10.931 2.75026C10.7164 1.96053 10.2478 1.26336 9.59767 0.766328C8.94752 0.269291 8.15188 0 7.3335 0C6.51512 0 5.71948 0.269291 5.06933 0.766328C4.41917 1.26336 3.95064 1.96053 3.736 2.75026H0V4.75026H3.736C3.95064 5.53999 4.41917 6.23715 5.06933 6.73419C5.71948 7.23123 6.51512 7.50052 7.3335 7.50052C8.15188 7.50052 8.94752 7.23123 9.59767 6.73419C10.2478 6.23715 10.7164 5.53999 10.931 4.75026H24V2.75026H10.931ZM7.333 5.50026C6.98688 5.50026 6.64854 5.39762 6.36075 5.20533C6.07297 5.01304 5.84866 4.73973 5.71621 4.41995C5.58376 4.10018 5.5491 3.74832 5.61663 3.40885C5.68415 3.06938 5.85082 2.75756 6.09556 2.51282C6.3403 2.26808 6.65213 2.10141 6.99159 2.03388C7.33106 1.96636 7.68293 2.00102 8.0027 2.13347C8.32247 2.26592 8.59578 2.49023 8.78807 2.77801C8.98036 3.0658 9.083 3.40414 9.083 3.75026C9.08221 4.21414 8.89758 4.6588 8.56956 4.98682C8.24154 5.31484 7.79689 5.49947 7.333 5.50026Z"
          fill="#EF8268"
        />
        <path
          d="M16.667 8.25C15.8495 8.25293 15.0553 8.5233 14.4058 9.01985C13.7564 9.51639 13.2872 10.2118 13.07 11H0V13H13.07C13.2843 13.7899 13.7526 14.4873 14.4027 14.9846C15.0528 15.4818 15.8485 15.7512 16.667 15.7512C17.4855 15.7512 18.2812 15.4818 18.9313 14.9846C19.5814 14.4873 20.0497 13.7899 20.264 13H24V11H20.264C20.0468 10.2118 19.5776 9.51639 18.9282 9.01985C18.2787 8.5233 17.4845 8.25293 16.667 8.25ZM16.667 13.75C16.3209 13.75 15.9825 13.6474 15.6948 13.4551C15.407 13.2628 15.1827 12.9895 15.0502 12.6697C14.9178 12.3499 14.8831 11.9981 14.9506 11.6586C15.0181 11.3191 15.1848 11.0073 15.4296 10.7626C15.6743 10.5178 15.9861 10.3511 16.3256 10.2836C16.6651 10.2161 17.0169 10.2508 17.3367 10.3832C17.6565 10.5157 17.9298 10.74 18.1221 11.0278C18.3144 11.3155 18.417 11.6539 18.417 12C18.4165 12.464 18.2319 12.9088 17.9039 13.2369C17.5758 13.5649 17.131 13.7495 16.667 13.75Z"
          fill="#EF8268"
        />
        <path
          d="M7.333 16.5C6.51494 16.5023 5.72013 16.7723 5.07004 17.2689C4.41995 17.7655 3.95035 18.4613 3.733 19.25H0V21.25H3.736C3.95064 22.0397 4.41917 22.7369 5.06933 23.2339C5.71948 23.731 6.51512 24.0002 7.3335 24.0002C8.15188 24.0002 8.94752 23.731 9.59767 23.2339C10.2478 22.7369 10.7164 22.0397 10.931 21.25H24V19.25H10.931C10.7137 18.4617 10.2444 17.7661 9.59476 17.2695C8.94507 16.773 8.15072 16.5027 7.333 16.5ZM7.333 22C6.98688 22 6.64854 21.8974 6.36075 21.7051C6.07297 21.5128 5.84866 21.2395 5.71621 20.9197C5.58376 20.5999 5.5491 20.2481 5.61663 19.9086C5.68415 19.5691 5.85082 19.2573 6.09556 19.0126C6.3403 18.7678 6.65213 18.6011 6.99159 18.5336C7.33106 18.4661 7.68293 18.5008 8.0027 18.6332C8.32247 18.7657 8.59578 18.99 8.78807 19.2777C8.98036 19.5655 9.083 19.9039 9.083 20.25C9.08221 20.7139 8.89758 21.1585 8.56956 21.4866C8.24154 21.8146 7.79689 21.9992 7.333 22Z"
          fill="#EF8268"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_115958">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
