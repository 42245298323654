const MIN_TRANSFORM_YEAR = 2000;
const MIN_TRANSFORM_DATE_LENGTH = 10;

export function now(): number {
  return Math.floor(Date.now() / 1000);
}

const dateISO8061 =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(?:\d{4})-(?:\d{2})-(?:\d{2})T(?:\d{2}):(?:\d{2}):(?:\d{2}(?:\.\d*)?)(?:(?:-(?:\d{2}):(?:\d{2})|Z)?)$/u;

export function transformDates(data: any): void {
  if (!data || typeof data !== 'object') {
    return;
  }

  for (let key in data) {
    if (data[key] === undefined) {
      continue;
    }
    // we need const here
    // eslint-disable-next-line preferLet/prefer-let
    const value = data[key];
    if (typeof value === 'object') {
      transformDates(data[key]);
      continue;
    }
    if (typeof value !== 'string') {
      continue;
    }
    if (!dateISO8061.test(value)) {
      continue;
    }
    if (value.length < MIN_TRANSFORM_DATE_LENGTH) {
      continue;
    }
    let timestamp = Date.parse(value);
    if (Number.isNaN(timestamp)) {
      continue;
    }
    let parsedDate = new Date(timestamp);
    if (parsedDate.getFullYear() >= MIN_TRANSFORM_YEAR) {
      data[key] = parsedDate;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Failed to transform '${data[key]}' to Date object.`);
    }
  }
}

export function formatDateToShortString(date: Date | null | undefined) {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDateToLongString(date: Date | null | undefined): string {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}
