import { stringifyForLog } from './StringifyForLog';

type Simple = string | number | null | boolean | undefined | Date;
type MessageType = Simple | Request | Error;
type ContextType = { [key: string]: ContextType | Simple };

let BSToken: string;

export function initLogger(token: string) {
  BSToken = token;
  window.addEventListener('error', (event: ErrorEvent) => {
    logger.error(['[Uncaught]', event.error]);
  });
}

function sendLog(
  level: string,
  messages: MessageType[],
  passedContext?: ContextType,
) {
  if (document.visibilityState === 'hidden') {
    return;
  }
  let context: ContextType = {
    url: window.location.href,
    ua: navigator.userAgent,
    visibility: document.visibilityState,
  };
  if (passedContext) {
    if (typeof passedContext === 'object') {
      context = structuredClone(passedContext);
    } else {
      context['context'] = passedContext;
    }
  }
  let stack: string | string[] | null = null;
  for (let item of messages) {
    if (
      typeof item === 'object' &&
      item &&
      'stack' in item &&
      typeof item.stack === 'string'
    ) {
      stack = item.stack;
    }
  }
  if (stack) {
    stack = stack.replaceAll(' at ', '');
    stack = stack.split('\n');
    delete stack[0];
  }
  sendToBetterStack({
    message: stringifyForLog(messages),
    stack,
    level,
    context,
  });
  if (passedContext) {
    // @ts-ignore
    // eslint-disable-next-line no-console
    console[level](...messages, passedContext);
  } else {
    // @ts-ignore
    // eslint-disable-next-line no-console
    console[level](...messages);
  }
}

type BetterStackRequestData = {
  message: string;
  stack: string | string[] | null;
  level: string;
  context: ContextType;
};

function sendToBetterStack(bodyObject: BetterStackRequestData) {
  setTimeout(() => {
    if (!BSToken || BSToken === 'local') {
      return;
    }
    // eslint-disable-next-line promise/valid-params
    fetch('https://in.logs.betterstack.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BSToken}`,
      },
      body: JSON.stringify(bodyObject),
    })
      .then()
      .catch();
  }, 0);
}

export const logger = {
  log: (messages: MessageType | MessageType[], context?: ContextType) =>
    sendLog('log', Array.isArray(messages) ? messages : [messages], context),
  error: (error: any, context?: ContextType) =>
    sendLog('error', Array.isArray(error) ? error : [error], context),
};
