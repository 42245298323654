import styled from 'styled-components';
import InfoBigIcon from '../Icons/InfoBigIcon';
import Card from '@front_common/Components/Card';
import AlertIcon from '../Icons/AlertIcon';

type IProps = {
  text: string;
  isAlert?: boolean;
};

export function QuickInfo({ text, isAlert }: IProps) {
  return (
    <>
      {isAlert ? (
        <QuickInfoAlertWrapper>
          <AlertIcon />
          <span>{text}</span>
        </QuickInfoAlertWrapper>
      ) : (
        <QuickInfoWrapper>
          <InfoBigIcon />
          <span>{text}</span>
        </QuickInfoWrapper>
      )}
    </>
  );
}

let QuickInfoWrapper = styled(Card)`
  padding: 12px 16px;
  display: flex;
  gap: 8px;

  svg {
    min-width: 24px;
  }

  span {
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontWeightMedium);
    line-height: 20px;
  }
`;

let QuickInfoAlertWrapper = styled(QuickInfoWrapper)`
  border: 1px solid var(--red100);
`;
