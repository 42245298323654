import styled from 'styled-components';
import LocationIcon from './Icons/LocationIcon';
import CaseIcon from './Icons/CaseIcon';
import DualSimIcon from './Icons/DualSimIcon';
import UmbrellaIcon from './Icons/UmbrellaIcon';
import GraduationIcon from './Icons/GraduationIcon';
import TimeIcon from './Icons/TimeIcon';
import LaptopIcon from './Icons/LaptopIcon';

export default function WeGotYouCovered() {
  return (
    <WeGotYouCoveredWrapper>
      <Background>
        <Header>{`We got you covered for all your
      eSIM needs`}</Header>
        <Bullets>
          <Bullet>
            <LocationIcon />
            <BulletText>International studying</BulletText>
          </Bullet>
          <Bullet>
            <CaseIcon />
            <BulletText>Business trips</BulletText>
          </Bullet>
          <Bullet>
            <DualSimIcon />
            <BulletText>Dual-SIM functionality</BulletText>
          </Bullet>
          <Bullet>
            <UmbrellaIcon />
            <BulletText>Family vacations</BulletText>
          </Bullet>
          <Bullet>
            <GraduationIcon />
            <BulletText>Study abroad</BulletText>
          </Bullet>
          <Bullet>
            <TimeIcon />
            <BulletText>Temporary relocation</BulletText>
          </Bullet>
          <Bullet>
            <LaptopIcon />
            <BulletText>Remote work</BulletText>
          </Bullet>
        </Bullets>
      </Background>
    </WeGotYouCoveredWrapper>
  );
}

let WeGotYouCoveredWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;

  @media (min-width: 700px) {
    padding-top: 52px;
    padding-bottom: 52px;
  }
`;

let Background = styled.div`
  border: 1px dashed var(--strokePrimary);
  border-radius: 20px;
  padding: 40px 20px;
  background: var(--backgroundSecondary);

  @media (min-width: 700px) {
    padding: 40px;
    background: transparent;
    text-align: center;
  }
`;

let Header = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 1;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    font-size: 52px;
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 960px) {
    white-space: pre-wrap;
  }
`;

let Bullets = styled.div`
  @media (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 28px;
  }
`;

let Bullet = styled.div`
  margin-bottom: 28px;
  height: 32px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: initial;
  }

  svg {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }

  @media (min-width: 700px) {
    font-size: 28px;
    margin: initial;

    svg {
      margin-right: 5px;
    }
  }
`;

let BulletText = styled.span`
  vertical-align: top;
  line-height: 1;

  @media (min-width: 700px) {
    font-family: var(--fontFamilyHeader);
    line-height: 30px;
  }
`;
