import { useState } from 'preact/hooks';
import { forwardRef, HTMLAttributes } from 'preact/compat';
import TextInput from '@front_common/Components/TextInput/NewDesign/TextInput';

interface OTPInputProps extends HTMLAttributes<HTMLInputElement> {
  onValueChange: (value: string) => void;
  label?: string;
  errorText?: string;
}

let OTPInput = forwardRef<HTMLInputElement, OTPInputProps>((props, ref) => {
  let { errorText = '', label, onValueChange, ...rest } = props;

  let [value, setValue] = useState('');

  function handleInput(event: Event) {
    // eslint-disable-next-line xss/no-mixed-html
    let target = event.target as HTMLInputElement;
    let newValue = target.value;
    let valueOnlyNumbers = newValue.replaceAll(/\D+/gu, '');

    target.value = valueOnlyNumbers;
    onValueChange(valueOnlyNumbers);
    setValue(valueOnlyNumbers);
  }

  return (
    <TextInput
      type="text"
      inputMode="numeric"
      maxLength={6}
      onInput={handleInput}
      value={value}
      errorText={errorText}
      label={label}
      ref={ref}
      {...rest}
    />
  );
});

export default OTPInput;
