import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import { BundleEntityType } from '@shared_backend/Module/Bundle/BundleEntityType';
import CheckboxCircleIcon from './Icons/CheckboxCircleIcon';
import styled from 'styled-components';

type IProps = {
  bundle: BundleEntityType;
  totalAmountCents: number;
  targetCountry: string;
  authenticated?: boolean;
  borderColor?: string;
  email?: string;
};

export default function ProductInfo({
  bundle,
  totalAmountCents,
  targetCountry,
  authenticated,
  email,
}: IProps) {
  let countryName = getEsimPlanCountry(targetCountry);

  return (
    <ProductInfoWrapper>
      <MainInfo>
        <ContentLeft>
          <PlanDataInfo>
            <FirstRow>{`${bundle.dataAmountGb}GB in ${countryName}`}</FirstRow>
            <SecondRow>Valid for: {bundle.durationDays} days</SecondRow>
          </PlanDataInfo>
        </ContentLeft>
        <IconBox>
          <EsimPlanFlag withBorder targetCountry={targetCountry} />
        </IconBox>
      </MainInfo>
      {authenticated && (
        <AuthenticatedEmail>
          <CheckboxCircleIcon />
          <span>{email}</span>
        </AuthenticatedEmail>
      )}
      <Divider />
      <TotalPrice>
        <span>Total:</span>
        <span>${totalAmountCents / 100}</span>
      </TotalPrice>
    </ProductInfoWrapper>
  );
}

let ProductInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 22px;
  row-gap: 12px;
  color: var(--textPrimaryNew);
`;

let MainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

let ContentLeft = styled.div`
  display: flex;
  align-items: center;
`;

let PlanDataInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let FirstRow = styled.span`
  line-height: 20px;
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
`;

let SecondRow = styled.span`
  line-height: 16px;
  font-size: 12px;
  color: var(--textSecondaryNew);
`;

let IconBox = styled.div`
  height: 32px;
  width: 32px;

  img {
    height: 30px;
    width: 32px;
    object-fit: cover;
  }
`;

let AuthenticatedEmail = styled.div`
  position: relative;
  left: -4px;
  display: flex;
  align-items: center;
  line-height: 22px;
  text-align: left;
  width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--strokeSecondary);
`;

let TotalPrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--fontWeightBold);
`;
