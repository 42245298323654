import { EsimPlanFlag } from '@front_common/Components/EsimPlanName';
import styled from 'styled-components';

type Props = {
  dataAmountGb: number;
  durationDays: number;
  countryName: string;
  totalAmount: number;
  targetCountry: string;
  borderColor?: string;
};

export default function ProductInfo({
  dataAmountGb,
  durationDays,
  countryName,
  totalAmount,
  targetCountry,
  borderColor = 'var(--strokeSecondary)',
}: Props) {
  return (
    <PurchaseItem $borderColor={borderColor}>
      <ContentLeft>
        <IconBox>
          <EsimPlanFlag withBorder targetCountry={targetCountry} />
        </IconBox>
        <PlanDataInfo>
          <FirstRow>{`${dataAmountGb}GB in ${countryName}`}</FirstRow>
          <SecondRow>Validity: {durationDays} days</SecondRow>
        </PlanDataInfo>
      </ContentLeft>
      <PriceItem>${totalAmount}</PriceItem>
    </PurchaseItem>
  );
}

let PurchaseItem = styled.div<{ $borderColor: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background: var(--backgroundSecondary);
  border-radius: var(--borderRadiusLarge);
  border: var(--borderOutlinePrimary);
  border-color: ${({ $borderColor }) => $borderColor};
  line-height: 22px;
  padding: 16px;
  position: relative;
`;

let PriceItem = styled.span`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let IconBox = styled.div`
  margin-right: 8px;
  display: flex;

  img {
    height: 24px;
  }
`;

let PlanDataInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let FirstRow = styled.span`
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  line-height: 22px;
`;

let SecondRow = styled.span`
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let ContentLeft = styled.div`
  display: flex;
  align-items: center;
`;
