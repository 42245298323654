import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from './useDynamicSplitComponent';
import OldDesignPageFooter from './OldDesign/PageFooter';
import NewDesignPageFooter from './NewDesign/PageFooter';

type IFooterContainerProps = {
  splitGroupId: number;
};

export function FooterContainer<P extends IFooterContainerProps>(props: P) {
  let { splitGroupId } = props;

  let HeaderComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignPageFooter,
      [DesignSplitGroupId.B]: NewDesignPageFooter,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return <HeaderComponent {...(props as P & JSX.IntrinsicAttributes)} />;
}
