export default function SignOutIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3259_464)">
        <path
          d="M9.58333 13.3333C9.25181 13.3333 8.93387 13.465 8.69945 13.6995C8.46503 13.9339 8.33333 14.2518 8.33333 14.5833V15.25C8.33333 15.8467 8.09628 16.419 7.67432 16.841C7.25237 17.2629 6.68007 17.5 6.08333 17.5H4.75C4.15326 17.5 3.58097 17.2629 3.15901 16.841C2.73705 16.419 2.5 15.8467 2.5 15.25V4.75C2.5 4.15326 2.73705 3.58097 3.15901 3.15901C3.58097 2.73705 4.15326 2.5 4.75 2.5H6.08333C6.68007 2.5 7.25237 2.73705 7.67432 3.15901C8.09628 3.58097 8.33333 4.15326 8.33333 4.75V5.41667C8.33333 5.74819 8.46503 6.06613 8.69945 6.30055C8.93387 6.53497 9.25181 6.66667 9.58333 6.66667C9.91485 6.66667 10.2328 6.53497 10.4672 6.30055C10.7016 6.06613 10.8333 5.74819 10.8333 5.41667V4.75C10.832 3.49063 10.3311 2.28322 9.44063 1.3927C8.55012 0.502194 7.34271 0.00132357 6.08333 0L4.75 0C3.49063 0.00132357 2.28322 0.502194 1.3927 1.3927C0.502194 2.28322 0.00132357 3.49063 0 4.75L0 15.25C0.00132357 16.5094 0.502194 17.7168 1.3927 18.6073C2.28322 19.4978 3.49063 19.9987 4.75 20H6.08333C7.34271 19.9987 8.55012 19.4978 9.44063 18.6073C10.3311 17.7168 10.832 16.5094 10.8333 15.25V14.5833C10.8333 14.2518 10.7016 13.9339 10.4672 13.6995C10.2328 13.465 9.91485 13.3333 9.58333 13.3333Z"
          fill="#FE2836"
        />
        <path
          d="M18.8005 7.93761L14.9788 4.11594C14.8627 3.99988 14.7249 3.90783 14.5732 3.84505C14.4215 3.78226 14.259 3.74996 14.0948 3.75C13.9306 3.75004 13.7681 3.78241 13.6164 3.84527C13.4648 3.90813 13.327 4.00025 13.2109 4.11636C12.9765 4.35085 12.8449 4.66885 12.845 5.0004C12.845 5.16457 12.8774 5.32712 12.9403 5.47877C13.0031 5.63043 13.0952 5.76822 13.2113 5.88427L16.0538 8.72677L5.83301 8.75011C5.50149 8.75011 5.18354 8.8818 4.94912 9.11622C4.7147 9.35064 4.58301 9.66859 4.58301 10.0001C4.58301 10.3316 4.7147 10.6496 4.94912 10.884C5.18354 11.1184 5.50149 11.2501 5.83301 11.2501L16.098 11.2268L13.2088 14.1159C12.9743 14.3503 12.8426 14.6683 12.8425 14.9998C12.8424 15.3314 12.974 15.6494 13.2084 15.8839C13.4428 16.1184 13.7607 16.2501 14.0923 16.2502C14.4238 16.2503 14.7418 16.1187 14.9763 15.8843L18.798 12.0626C19.3444 11.5154 19.6514 10.7739 19.6519 10.0006C19.6524 9.22736 19.3462 8.48547 18.8005 7.93761Z"
          fill="#FE2836"
        />
      </g>
      <defs>
        <clipPath id="clip0_3259_464">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
