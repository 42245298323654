/* eslint-disable max-lines */
import { AppleIcon } from './Icons/AppleIcon';
import { DellIcon } from './Icons/DellIcon';
import { GoogleIcon } from './Icons/GoogleIcon';
import { HpIcon } from './Icons/HpIcon';
import { LenovoIcon } from './Icons/LenovoIcon';
import { MicrosoftIcon } from './Icons/MicrosoftIcon';
import { MotorolaIcon } from './Icons/MotorolaIcon';
import { SamsungIcon } from './Icons/SamsungIcon';
import { SharpIcon } from './Icons/SharpIcon';
import { SonyIcon } from './Icons/SonyIcon';
import { XiaomiIcon } from './Icons/XiaomiIcon';

type Brand = {
  brand: string;
  devices: string[];
  note?: string | React.ReactNode;
  footnotes?: string[];
  logo?: React.ReactNode;
};

export let content: Brand[] = [
  {
    brand: 'Apple',
    devices: [
      'iPhone XR*',
      'iPhone XS**',
      'iPhone XS Max*',
      'iPhone 11*',
      'iPhone 11 Pro*',
      'iPhone 11 Pro Max*',
      'iPhone SE',
      'iPhone 12*',
      'iPhone 12 Mini**',
      'iPhone 12 Pro*',
      'iPhone 12 Pro Max*',
      'iPhone 13*',
      'iPhone 13 Mini**',
      'iPhone 13 Pro*',
      'iPhone 13 Pro Max*',
      'iPhone 14*',
      'iPhone 14 Plus*',
      'iPhone 14 Pro*',
      'iPhone 14 Pro Max*',
      'iPhone 15*',
      'iPhone 15 Plus*',
      'iPhone 15 Pro*',
      'iPhone 15 Pro Max*',
      'iPad (A2198, 2019 and newer)**',
      'iPad Mini (A2124, 2019 and newer)**',
      'iPad Air (A2123, 2019 and newer)**',
      'iPad Pro 11″ (A2068, 2020 and newer)**',
      'iPad Pro 12.9″ (A2069, 2020 and newer)**',
    ],
    footnotes: [
      'excluding devices purchased in Mainland China, Hong Kong, or Macao',
      'excluding devices purchased in Mainland China',
    ],
    logo: <AppleIcon />,
  },
  {
    brand: 'Samsung',
    devices: [
      'Samsung Note 20',
      'Samsung Note 20+',
      'Samsung Note 20 Ultra***',
      'Samsung Galaxy Fold',
      'Samsung Galaxy Z Fold2***',
      'Samsung Galaxy Z Fold3',
      'Samsung Galaxy Z Fold4',
      'Samsung Galaxy Z Fold5',
      'Samsung Galaxy Fold6',
      'Samsung Galaxy Z Flip**',
      'Samsung Galaxy Z Flip3 5G',
      'Samsung Galaxy Z Flip4',
      'Samsung Galaxy Z Flip5',
      'Samsung Galaxy Z Flip6',
      'Samsung Galaxy A54 5G',
      'Samsung Galaxy S20**',
      'Samsung Galaxy S20+**',
      'Samsung Galaxy S20 Ultra',
      'Samsung Galaxy S21 5G*',
      'Samsung Galaxy S21+ 5G*',
      'Samsung Galaxy S21 Ultra 5G*',
      'Samsung Galaxy S22 5G',
      'Samsung Galaxy S22+ 5G',
      'Samsung Galaxy S22 Ultra 5G',
      'Samsung Galaxy S23',
      'Samsung Galaxy S23+',
      'Samsung Galaxy S23 Ultra',
      'Samsung Galaxy S24 Ultra',
      'Samsung Galaxy 24+',
      'Samsung Galaxy S24',
    ],
    footnotes: [
      'U.S. models may support eSIM if the One UI 4 update has been installed',
      'except U.S. models',
      'except U.S. and Hong Kong models',
    ],
    logo: <SamsungIcon />,
  },
  {
    brand: 'Google Pixel',
    devices: [
      'Google Pixel 3',
      'Google Pixel 3a',
      'Google Pixel 4',
      'Google Pixel 4a',
      'Google Pixel 4 XL',
      'Google Pixel 4a 5G',
      'Google Pixel 5',
      'Google Pixel 6',
      'Google Pixel 6a',
      'Google Pixel 6 Pro',
      'Google Pixel 7',
      'Google Pixel 7a',
      'Google Pixel 7 Pro',
      'Google Pixel 8',
      'Google Pixel 8 Pro',
      'Google Pixel Fold',
    ],
    note: 'Google Pixel 3 phones purchase with service from a U.S. or Canadian carrier will not support eSIM, with the exception of Google Pixel 3 phones purchased with Sprint or Google Fi. Google Pixel 3 phones purchased in Australia, Taiwan, and Japan also do not support eSIM. Google Pixel 3a phones purchased with Verizon service or purchased in Japan do not support eSIM.',
    logo: <GoogleIcon />,
  },
  {
    brand: 'Xiaomi',
    devices: [
      'Xiaomi 13T Pro',
      'Xiaomi 13 Pro',
      'Xiaomi 13 Lite',
      'Xiaomi 13',
      'Xiaomi 12T Pro',
    ],
    note: (
      <>
        eSIM availability may vary depending on country/region and carrier. For
        more details please visit the official website:{' '}
        <a
          href="https://www.mi.com/global/support/esim?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          https://www.mi.com/global/support/esim?lang=en
        </a>
      </>
    ),
    logo: <XiaomiIcon />,
  },
  {
    brand: 'Motorola',
    devices: [
      'Motorola Razr 2019',
      'Motorola Razr 5G',
      'Motorola Razr 40',
      'Motorola Razr 40 Ultra',
      'Motorola Razr+',
      'Motorola Edge+',
      'Motorola Edge 40',
      'Motorola Edge 40 Pro',
      'Motorola Edge 40 Neo',
      'Motorola G52J 5G',
      'Motorola G52J 5G Ⅱ',
      'Motorola G53J 5G',
      'Motorola G54',
    ],
    logo: <MotorolaIcon />,
  },
  {
    brand: 'Sharp',
    devices: [
      'Sharp AQUOS Sense 4 Lite',
      'Sharp AQUOS Sense 6S',
      'Sharp AQUOS Sense 7',
      'Sharp AQUOS Sense 7 Plus',
      'Sharp AQUOS Wish 2 SHG08',
      'Sharp AQUOS Wish3',
      'Sharp AQUOS Zero 6',
      'Sharp Simple Sumaho6',
      'Sharp AQUOS R7',
      'Sharp AQUOS R8',
      'Sharp AQUOS R8 Pro',
    ],
    logo: <SharpIcon />,
  },
  {
    brand: 'Sony',
    devices: [
      'Sony Xperia 1 IV',
      'Sony Xperia 1 III',
      'Sony Xperia 1 II',
      'Sony Xperia 5 II',
      'Sony Xperia 5 III',
      'Sony Xperia 5 IV',
      'Sony Xperia 10 II',
      'Sony Xperia 10 III',
      'Sony Xperia 10 IV',
      'Sony Xperia PRO-I',
    ],
    logo: <SonyIcon />,
  },
  {
    brand: 'Dell',
    devices: [
      'Dell Latitude 7210',
      'Dell Latitude 9410',
      'Dell Latitude 7310',
      'Dell Latitude 7320',
      'Dell Latitude 7410',
      'Dell Latitude 9420',
      'Dell Latitude 9510',
      'Dell Latitude 5410',
      'Dell Latitude 5420',
      'Dell Latitude 5411',
      'Dell Latitude 5511',
    ],
    logo: <DellIcon />,
  },
  {
    brand: 'HP',
    devices: [
      'HP Elite Dragonfly G2',
      'HP Elite Folio 13',
      'HP Elitebook G5',
      'HP Probook G5',
      'HP Zbook G5',
      'HP Spectre Folio 13',
      'HP Spectre x360',
    ],
    logo: <HpIcon />,
  },
  {
    brand: 'Lenovo',
    devices: [
      'Lenovo ThinkPad X1 Titanium Yoga 2 in 1',
      'Lenovo ThinkPad X1 Carbon Gen 9',
      'Lenovo ThinkPad X1 Fold',
      'Lenovo ThinkPad X1 Nano',
      'Lenovo ThinkPad X12 Detachable',
      'Lenovo Flex 5G',
      'Lenovo Yoga C630',
      'Lenovo Miix 630',
      'Lenovo Yoga 520',
      'Lenovo Yoga 720 (2-in-1 models)',
    ],
    logo: <LenovoIcon />,
  },
  {
    brand: 'Microsoft',
    devices: [
      'Microsoft Surface Duo',
      'Microsoft Surface Go 2',
      'Microsoft Surface Go 3',
      'Microsoft Surface Pro X',
      'Microsoft Surface Pro 5 LTE Advanced',
      'Microsoft New Surface Pro 7+',
      'Microsoft Surface Pro 8',
      'Microsoft Surface Pro LTE',
    ],
    logo: <MicrosoftIcon />,
  },
  {
    brand: 'Other',
    devices: [
      'Acer Swift 3',
      'Acer Swift 7',
      'Acer TravelMate P2',
      'Acer TravelMate P6',
      'Acer TravelMate Spin P4',
      'ASUS Mini Transformer T103HAF',
      'ASUS NovaGo TP370QL',
      'ASUS Vivobook Flip 14 TP401NA',
      'DOOGEE V30',
      'Fairphone 4',
      'Fairphone 5',
      'Gemini PDA',
      'HAMMER Blade 3',
      'HAMMER Blade 5G',
      'HAMMER Explorer PRO',
      'Honor 90',
      'Honor Magic 4 Pro',
      'Honor Magic 5 Pro',
      'Honor X8',
      'Huawei Mate 40 Pro',
      'Huawei Mate X2',
      'Huawei P40',
      'Huawei P40 Pro',
      'Huawei P50 Pro',
      'Huawei P70',
      'Huawei P70 Pro',
      'Huawei P70 Art',
      'myPhone NOW eSIM',
      'Nokia G60 5G',
      'Nokia X30',
      'Nokia XR21',
      'Nuu Mobile X5',
      'OnePlus 11',
      'OnePlus 12',
      'OnePlus Open',
      'Oppo A55s 5G',
      'Oppo Find N2 Flip',
      'Oppo Find X3 Pro',
      'Oppo Find X5',
      'Oppo Find X5 Pro',
      'Oppo Reno 5A',
      'Oppo Reno 6 Pro 5G',
      'Oppo Reno 9A',
      'Rakuten Big',
      'Rakuten Big S',
      'Rakuten Hand',
      'Rakuten Hand 5G',
      'Rakuten Mini',
      'Vivo V29 Lite 5G*',
      'Vivo X90 Pro',
      'WP30 Pro',
    ],
  },
];
