import VisitorApi, {
  useStoreForCountryQuery,
} from '@front_common/Api/VisitorApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import styled from 'styled-components';
import Flag from '@front_common/Components/Flag/Flag';
import { NavigationTabs } from './NavigationTabs';
import { useEffect, useRef, useState } from 'preact/hooks';
import PhoneIcon from './Icons/PhoneIcon';
import PlanDetails from './PlanDetails';
import PlanCard from './PlanCard';
import HowEsimWorks from './HowEsimWorks/HowEsimWorks';
import ChevronDownIcon from '@front_common/Components/Icons/ChevronDownIcon';
import Button from '@front_common/Components/Button/Button';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';
import FAQ from './FAQ';
import { LinkToRoute } from '@front_common/Router/Router';
import HeaderBackground from './Images/HeaderBackground.svg';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import CompatibleDevicesModalContent from '../../CompatibilityGuide/CompatibleDevicesModalContent';
import Api from '@front_common/Api/Api';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import { setTitleHeader } from '@front_common/Seo/SeoHeaders';
import { StoreItem } from '@shared_backend/Module/Store/StoreResponse';
import DownloadApp from '@front_common/Components/DownloadApp/OldDesign/DownloadApp';
import CheckoutModalContent from '../../Checkout/CheckoutModal/OldDesign/CheckoutModalContent';
import ModalStyledCheckout from '../../Checkout/CheckoutModal/OldDesign/ModalStyledCheckout';
import SuccessModal from '../../InstallFlow/SuccessModal';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { useDisplayingAllElements } from '../../Checkout/useDisplayingHeaderFooterElements';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { getCountryShortCodeBySlug } from './CountryStoreUtils';
import StoreFeedbacks from './StoreFeedbacks/StoreFeedbacks';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

const HEADER_HEIGHT = 72;

let tabs = ['Plans', 'Details', 'How it works', 'Reviews', 'FAQ'];

function extractShortBundleCode(bundleCodeAndPriceId: string) {
  return bundleCodeAndPriceId.split('_').at(0);
}

// eslint-disable-next-line max-lines-per-function,complexity
export default function CountryStorePage({
  countrySlug,
}: {
  countrySlug: string;
}) {
  let countryCode = getCountryShortCodeBySlug(countrySlug);
  let windowDimensions = useWindowDimensions();

  if (!countryCode) {
    throw new Error('Country code is not defined');
  }

  let countryName = countryNameByCode(countryCode);
  let [storeItems, error, isLoading] = useStoreForCountryQuery(countryCode);

  let headerComp = (
    <Header>
      <BackButtonWrapper>
        <BackButton href={WEB_ROUTE_HOME}>
          <ChevronDownIcon />
        </BackButton>
      </BackButtonWrapper>
      <Background>
        <BackgroundWrapper>
          <img src={HeaderBackground} alt="City background" />
        </BackgroundWrapper>
      </Background>
      <FlagWrapper>
        <Flag countryCode={countryCode} />
      </FlagWrapper>
      <Title data-testid="country-store-page-title">
        eSIM plans for {countryNameByCode(countryCode)}
      </Title>
      <NavigationTabsWrapper>
        <NavigationTabs
          tabs={tabs}
          containerClassName="tabs-container"
          onTabClick={handleTabClick}
        />
      </NavigationTabsWrapper>
    </Header>
  );

  if (error) {
    logger.error(['Country store items error:', error]);
    return (
      <CountryStoreWrapper>
        {headerComp}
        <ErrorBlock message={error.message} />
      </CountryStoreWrapper>
    );
  }

  if (isLoading || !storeItems?.length) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <LoadingAnimation />
      </CountryStoreWrapper>
    );
  }

  if (!isLoading && storeItems?.length === 0) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <ErrorBlock message={'No active packages for ' + countryName} />
      </CountryStoreWrapper>
    );
  }

  let [selectedPlan, setSelectedPlan] = useState<number>(2);
  let [selectedStoreItem, setSelectedStoreItem] = useState<StoreItem | null>(
    null,
  );
  let [bundleCodeAndPriceId, setBundleCodeAndPriceId] = useState<string>('');
  let [isCompatibilityModalOpen, setIsCompatibilityModalOpen] = useState(false);
  let [isBillingDetailsOpen, setIsBillingDetailsOpen] = useState(false);
  let [paymentSession, setPaymentSession] =
    useState<PaymentSessionEntityType | null>(null);
  let [showSuccessModal, setShowSuccessModal] = useState(false);

  let planIndex =
    selectedPlan !== 0 && selectedPlan < storeItems.length ? selectedPlan : 0;

  useEffect(() => {
    setTimeout(() => {
      preloadBundles(storeItems);
    }, 3000);
  }, [storeItems]);

  useEffect(() => {
    if (storeItems) {
      updateSeoHeaders(storeItems, countryName);
      if (storeItems.length < 3) {
        setSelectedPlan(storeItems.length - 1);
      }
    }
  }, [storeItems, selectedPlan]);

  useEffect(() => {
    if (storeItems) {
      let currentStoreItem = storeItems[planIndex];

      if (currentStoreItem) {
        setSelectedStoreItem(currentStoreItem);
        setBundleCodeAndPriceId(currentStoreItem.bundleCodeAndPriceId);
      }
    }
  }, [storeItems, planIndex]);

  useDisplayingAllElements(isBillingDetailsOpen);

  function openCompatibilityModal() {
    setIsCompatibilityModalOpen(true);
  }

  function closeCompatibilityModal() {
    setIsCompatibilityModalOpen(false);
  }

  function closeBillingDetails() {
    setIsBillingDetailsOpen(false);
  }

  let sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  function handleTabClick(index: number) {
    if (sectionRefs[index]?.current) {
      let element = sectionRefs[index]?.current;
      let offsetTop = element?.offsetTop;

      if (offsetTop) {
        window.scrollTo({
          top: offsetTop - HEADER_HEIGHT,
          behavior: 'smooth',
        });
      }
    }
  }

  function handleBuyClick() {
    Api.trackAction(
      ActionTypes.button_buy,
      extractShortBundleCode(bundleCodeAndPriceId),
    );

    setIsBillingDetailsOpen(true);
  }

  function onPaymentSuccess(paymentSessionProp: PaymentSessionEntityType) {
    setPaymentSession(paymentSessionProp);
    setIsBillingDetailsOpen(false);
    setShowSuccessModal(true);
  }

  return (
    <CountryStoreWrapper>
      {headerComp}
      <Body>
        {sectionRefs[0] && (
          <BodyBlock ref={sectionRefs[0]}>
            <CountryPlansSection>
              <Plans>
                {storeItems.length > 0
                  ? storeItems.map((item, index: number) => (
                      <PlanCard
                        key={item.bundleCodeAndPriceId}
                        planData={item}
                        selected={selectedPlan === index}
                        popular={index === 2}
                        onPlanClick={() => setSelectedPlan(index)}
                      />
                    ))
                  : null}
              </Plans>
            </CountryPlansSection>
            <CompatibilityGuideBtn
              onClick={openCompatibilityModal}
              sizeParam="L"
              typeStyle="Secondary"
            >
              <span>Check my device’s compatibility</span>
              <PhoneIcon />
            </CompatibilityGuideBtn>
          </BodyBlock>
        )}
        {sectionRefs[1] && (
          <BodyBlock ref={sectionRefs[1]}>
            <BlockTitle>Plan details</BlockTitle>
            {selectedStoreItem && (
              <PlanDetails validDays={selectedStoreItem.durationDays} />
            )}
          </BodyBlock>
        )}
        {sectionRefs[2] && (
          <BodyBlock ref={sectionRefs[2]} $withoutPadding>
            <HowItWorksHeader>
              <BlockTitle>How eSim works</BlockTitle>
              <HowItWorksSubtitle>
                Just a couple of steps separate you from hassle-free internet
                access
              </HowItWorksSubtitle>
            </HowItWorksHeader>
            <HowEsimWorks />
          </BodyBlock>
        )}
        {sectionRefs[3] && (
          <BodyBlock ref={sectionRefs[3]}>
            <BlockTitle>
              Best eSIM for {countryNameByCode(countryCode)} - tourist reviews
            </BlockTitle>
            <StoreFeedbacks countryId={countryCode} storeItems={storeItems} />
          </BodyBlock>
        )}
        {sectionRefs[4] && (
          <BodyBlock ref={sectionRefs[4]}>
            <BlockTitle>FAQ</BlockTitle>
            <FAQ />
          </BodyBlock>
        )}
        <BodyBlock>
          <DownloadApp />
        </BodyBlock>
      </Body>

      {isBillingDetailsOpen ? null : (
        <BuyBlock>
          <BuyContent>
            <PlanInfo>
              {selectedStoreItem && (
                <PlanData>
                  {selectedStoreItem.dataAmountGb}GB -{' '}
                  {selectedStoreItem.durationDays} days
                </PlanData>
              )}
              <CountryFlag>
                <SmallFlagWrapper>
                  <Flag circle={false} withBorder countryCode={countryCode} />
                </SmallFlagWrapper>
                <CountryName>{countryNameByCode(countryCode)}</CountryName>
              </CountryFlag>
            </PlanInfo>
            {selectedStoreItem && (
              <Button
                style={{ whiteSpace: 'nowrap' }}
                sizeParam={windowDimensions.width < 393 ? 'M' : 'L'}
                className="primary-btn"
                onClick={handleBuyClick}
              >
                Buy for ${selectedStoreItem.totalAmountCents / 100}
              </Button>
            )}
          </BuyContent>
        </BuyBlock>
      )}

      <Modal
        analyticType="device-compatibility"
        isOpen={isCompatibilityModalOpen}
        onClose={closeCompatibilityModal}
        closeButtonPrimary={false}
      >
        <CompatibleDevicesModalContent />
      </Modal>
      <ModalStyledCheckout
        isOpen={isBillingDetailsOpen}
        onClose={closeBillingDetails}
        header="Billing details"
      >
        <CheckoutModalContent
          bundleCodeAndPriceId={bundleCodeAndPriceId}
          targetCountry={countryCode}
          onPaymentSuccess={onPaymentSuccess}
        />
      </ModalStyledCheckout>

      {paymentSession && (
        <SuccessModal
          show={showSuccessModal}
          paymentSessionId={paymentSession.id}
        />
      )}
    </CountryStoreWrapper>
  );
}

function preloadBundles(storeItems: readonly StoreItem[]) {
  for (let item of storeItems) {
    let parts = item.bundleCodeAndPriceId.split('_');
    let bundleShortCode = parts[0];
    let priceId = parts[1];
    if (!bundleShortCode || !priceId) {
      continue;
    }
    VisitorApi.getBundleByShortCode(bundleShortCode);
    VisitorApi.getPrice(priceId);
  }
}

function updateSeoHeaders(
  storeItems: readonly StoreItem[],
  countryName: string,
) {
  // eslint-disable-next-line arrow-body-style
  let lowestPrice = Object.values(storeItems).reduce((acc, val) => {
    return acc < val.totalAmountCents ? acc : val.totalAmountCents;
  }, 9999);
  // Before useStoreForCountryQuery result we use title from SeoData.ts
  setTitleHeader(
    `eSIM ${countryName} - Best Plans from $${lowestPrice / 100} & Unlimited Data`,
  );
}

let CountryStoreWrapper = styled.div`
  width: 100%;
  position: relative;
`;

let Header = styled.div`
  display: initial;
`;

let Background = styled.div`
  width: 100%;
`;

let BackgroundWrapper = styled.div`
  height: 80px;
  overflow: hidden;
  background-color: var(--cardsBackgroundOrange);
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    height: 134px;
  }

  @media (min-width: 393px) {
    height: 100px;

    img {
      top: 43%;
    }
  }

  @media (min-width: 700px) {
    height: 156px;

    img {
      height: 184px;
      top: 45%;
    }
  }

  @media (min-width: 960px) {
    height: 188px;

    img {
      height: 213px;
      top: 44%;
    }
  }
`;

let BackButtonWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1440px;
    left: 50%;
    transform: translate(-50%);
  }
`;

let BackButton = styled(LinkToRoute)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: rotate(90deg);
  }
`;

let FlagWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid var(--backgroundSecondary);
  position: absolute;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 44px;
  }

  @media (min-width: 393px) {
    top: 76px;
  }

  @media (min-width: 700px) {
    top: 132px;
  }

  @media (min-width: 960px) {
    width: 64px;
    height: 64px;
    top: 156px;

    img {
      height: 64px;
    }
  }
`;

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 8px;
  padding: 0 16px;

  @media (min-width: 393px) {
    margin-bottom: 12px;
  }

  @media (min-width: 700px) {
    margin-bottom: 20px;
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    margin-top: 36px;
    margin-bottom: 32px;
    padding: 0 80px;
  }
`;

let NavigationTabsWrapper = styled.div`
  height: 48px;
  width: 100%;
  position: sticky;
  top: 72px;
  z-index: 2;
  background-color: var(--backgroundPrimary);
  display: flex;
  align-items: flex-end;

  .tabs-container {
    padding: 0 16px;
  }

  @media (min-width: 393px) {
    height: 52px;
  }

  @media (min-width: 700px) {
    .tabs-container {
      padding: 0 40px;
    }
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin: 0 auto;

    .tabs-container {
      padding: initial;
    }
  }
`;

let Body = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 700px) {
    gap: 40px;
  }

  @media (min-width: 960px) {
    gap: 56px;
  }
`;

let BodyBlock = styled.div<{ $withoutPadding?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: ${(props) => (props.$withoutPadding ? '0' : '0 16px')};

  @media (min-width: 700px) {
    padding: ${(props) => (props.$withoutPadding ? '0' : '0 40px')};
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin: 0 auto;
  }
`;

let BlockTitle = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
`;

let CountryPlansSection = styled.section``;

let Plans = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

let CompatibilityGuideBtn = styled(Button)`
  width: 100%;

  @media (min-width: 960px) {
    width: 327px;
    margin: 0 auto;
    margin-top: 12px;
  }
`;

let HowItWorksHeader = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: initial;
  }
`;

let HowItWorksSubtitle = styled.span`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: center;
  display: block;
  margin: 0 auto;
`;

let BuyBlock = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background-color: var(--backgroundSecondary);
  border-top: 1px solid var(--strokeSecondary);
  height: 88px;
  padding: 12px 16px;

  @media (min-width: 393px) {
    padding: 16px;

    .primary-btn {
      min-width: 172px;
    }
  }

  @media (min-width: 700px) {
    padding: 16px 40px;

    .primary-btn {
      min-width: 240px;
    }
  }

  @media (min-width: 960px) {
    padding: 16px 80px;
  }
`;

let BuyContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 393px) {
    .primary-btn {
      min-width: 172px;
    }
  }

  @media (min-width: 700px) {
    .primary-btn {
      min-width: 240px;
    }
  }

  @media (min-width: 960px) {
    max-width: 846px;
    margin: 0 auto;

    .primary-btn {
      min-width: 216px;
    }
  }
`;

let PlanInfo = styled.div`
  text-align: left;
`;

let PlanData = styled.span`
  margin-bottom: 4px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightBold);
  line-height: 20px;
  text-align: left;
  display: inline-block;

  @media (min-width: 393px) {
    margin-bottom: 8px;
    font-size: var(--fontSizeRegular);
  }

  @media (min-width: 700px) {
    line-height: 24px;
    font-size: var(--fontSizeBig);
  }
`;

let CountryFlag = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 4px;
  align-items: center;
`;

let SmallFlagWrapper = styled.div`
  display: flex;

  img {
    height: 14px;
  }

  @media (min-width: 700px) {
    img {
      height: 16px;
    }
  }
`;

let CountryName = styled.span`
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: 700px) {
    font-size: var(--fontSizeRegular);
  }
`;
