import {
  useBundleByShortCodeQuery,
  usePriceQuery,
} from '@front_common/Api/VisitorApi';
import AuthService from '@front_common/Api/AuthService';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { getQueryParam } from '@front_common/Router/Router';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import {
  Discount,
  DiscountTotal,
} from '../PaymentProcessor/Discount/OldDesign/Discount';
import Payment from '../PaymentProcessor/Payment/OldDesign/Payment';
import Card from '@front_common/Components/Card';
import { DiscountPaymentProvider } from '../PaymentProcessor/DiscountPaymentContext';
import FakeBreadcrumbs from './FakeBreadcrumbs/FakeBreadcrumbs';
import { getEsimPlanCountry } from '@front_common/Components/EsimPlanName';
import Summary from './Summary/Summary';
import PaymentError from '../PaymentProcessor/PaymentError/PaymentError';
import {
  useDisplayingAllElements,
  useDisplayingSupport,
} from '../useDisplayingHeaderFooterElements';
import SuccessModal from '../../InstallFlow/SuccessModal';
import Legal from '../../Auth/AuthForm/OldDesign/Legal';
import MoneyBackGuarantee from './MoneyBackGuarantee/MoneyBackGuarantee';
import UserApi from '@front_common/Api/UserApi';
import AuthForm from '../../Auth/AuthForm/OldDesign/AuthForm';
import bluredSImage from '../CheckoutModal/OldDesign/Images/BluredMobile.png';
import bluredMImage from '../CheckoutModal/OldDesign/Images/BluredTablet.png';
import bluredLImage from '../CheckoutModal/OldDesign/Images/BluredDesktop.png';
import PaymentSecured from '../PaymentSecured/OldDesign/PaymentSecured';
import CheckboxCircleIcon from '../Icons/CheckboxCircleIcon';
import LockIcon from '../Icons/LockIcon';
import { getESimCountryUrlByCountryCode } from '../../CountryStore/OldDesign/CountryStoreUtils';

export default function CheckoutPage({
  bundleCodeAndPriceId,
}: {
  bundleCodeAndPriceId: string;
}) {
  let targetCountry = getQueryParam('target_country');

  let [showSuccessModal, setShowSuccessModal] = useState(false);
  let [paymentSession, setPaymentSession] =
    useState<PaymentSessionEntityType | null>(null);
  let [paymentError, setPaymentError] = useState<string | null>(null);
  let [authenticated, setAuthenticated] = useState(AuthService.isLoggedIn());
  let [email, setEmail] = useState('');
  let [authTitle, setAuthTitle] = useState('');

  useDisplayingSupport(!paymentError);
  useDisplayingAllElements(true);

  let [bundleShortCode, priceId] = bundleCodeAndPriceId.split('_');

  if (!bundleShortCode || !priceId) {
    throw new Error('Bundle short code or price ID is not defined');
  }

  let [bundle] = useBundleByShortCodeQuery(bundleShortCode);
  let [price] = usePriceQuery(priceId);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let user = await UserApi.getUser();

        if (user) {
          setEmail(user.email);
        }
      }
    })();
  }, [authenticated]);

  if (!bundle || !price || !targetCountry) {
    return <LoadingAnimation />;
  }

  function handleAuthSuccess() {
    setAuthenticated(true);
  }

  function handleShowOTPChange(showOTP: boolean) {
    if (showOTP) {
      setAuthTitle('Enter the verification code');
    } else {
      setAuthTitle('');
    }
  }

  function onSuccessfulPayment(
    paymentSessionProp: PaymentSessionEntityType | null,
  ) {
    setPaymentSession(paymentSessionProp);
    setShowSuccessModal(true);
  }

  return (
    <CheckoutPageWrapper>
      <BreadcrumbsWrapper>
        <FakeBreadcrumbs
          prevLink={getESimCountryUrlByCountryCode(targetCountry)}
          prevName={getEsimPlanCountry(targetCountry)}
          currentLink="Billing details"
        />
      </BreadcrumbsWrapper>
      <MainContentWrapper>
        {paymentError && (
          <ErrorWrapper>
            <PaymentError errorText={paymentError} />
          </ErrorWrapper>
        )}

        <DesktopContentWrapper>
          <DiscountPaymentProvider
            totalAmountCents={price.totalAmountCents}
            bundleCodeAndPriceId={bundleCodeAndPriceId}
            targetCountry={targetCountry}
          >
            <FirstColumnBox>
              <CardStyled>
                <Summary
                  totalAmount={`$${price.totalAmountCents / 100}`}
                  targetCountry={targetCountry}
                  dataAmountGb={bundle.dataAmountGb}
                  durationDays={bundle.durationDays || 0}
                >
                  <GapStyled>
                    {authenticated && <Discount />}
                    <DiscountTotal />
                  </GapStyled>
                </Summary>
                <Divider />
                <div>
                  {authenticated ? (
                    <AuthenticatedEmail>
                      <span>{email}</span>
                      <CheckboxCircleIcon />
                    </AuthenticatedEmail>
                  ) : (
                    <AuthFormWrapper>
                      {authTitle && <AuthTitle>{authTitle}</AuthTitle>}
                      <AuthForm
                        onAuthSuccess={handleAuthSuccess}
                        onShowOTPChange={handleShowOTPChange}
                      />
                    </AuthFormWrapper>
                  )}
                </div>
              </CardStyled>
            </FirstColumnBox>
            <SecondColumnBox>
              {authenticated ? (
                <PaymentWrapper>
                  <PaymentTitle>Choose payment method</PaymentTitle>
                  <Payment
                    onSuccessfulPayment={onSuccessfulPayment}
                    onPaymentFail={setPaymentError}
                  />
                  <Legal text="By submitting your information and continuing to purchase, you agree to our" />
                </PaymentWrapper>
              ) : (
                <Blured>
                  <picture>
                    <source media="(min-width: 393px)" srcSet={bluredMImage} />
                    <source media="(min-width: 700px)" srcSet={bluredLImage} />
                    <img src={bluredSImage} alt="blured payment" />
                  </picture>
                  <TextIcon>
                    <LockIcon />
                    <span>Checkout will open after email validation</span>
                  </TextIcon>
                </Blured>
              )}
            </SecondColumnBox>
          </DiscountPaymentProvider>

          <FirstColumnBox>
            <MoneyBackGuarantee />
          </FirstColumnBox>
        </DesktopContentWrapper>

        <CardStyled>
          <PaymentSecured />
        </CardStyled>
      </MainContentWrapper>

      {paymentSession && (
        <SuccessModal
          show={showSuccessModal}
          paymentSessionId={paymentSession.id}
        />
      )}
    </CheckoutPageWrapper>
  );
}

let CheckoutPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    max-width: 1064px;
    margin: 0 auto;
  }
`;

let BreadcrumbsWrapper = styled.div`
  margin-top: 20px;
`;

let FirstColumnBox = styled.div`
  @media (min-width: 960px) {
    &:nth-child(-n + 3) {
      grid-column: 1;
    }
  }
`;

let SecondColumnBox = styled.div`
  @media (min-width: 960px) {
    grid-column: 2;
    grid-row: 1 / 3;
  }
`;

let MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 700px) {
    max-width: 494px;
    margin: 0 auto;
  }

  @media (min-width: 960px) {
    max-width: initial;
    margin: initial;
    gap: initial;
  }
`;

let ErrorWrapper = styled.div`
  width: 100%;

  @media (min-width: 960px) {
    margin-bottom: 20px;
  }
`;

let DesktopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

let CardStyled = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--strokeSecondary);
`;

let GapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let PaymentWrapper = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let AuthenticatedEmail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  text-align: left;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  svg {
    min-width: 24px;
  }
`;

let AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let AuthTitle = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  text-align: left;
`;

let PaymentTitle = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  text-align: left;
`;

let Blured = styled(Card)`
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;

  picture {
    width: 100%;
    height: 568px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 393px) {
    picture {
      height: 522px;
    }
  }

  @media (min-width: 700px) {
    picture {
      height: 438px;
    }
  }

  @media (min-width: 960px) {
    height: 100%;
    align-items: flex-start;

    picture {
      height: 522px;
    }
  }
`;

let TextIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    font-family: var(--fontFamilyHeader);
    font-size: var(--fontSizeBig);
    font-weight: var(--fontWeightMedium);
    line-height: 24px;
    text-align: center;
  }
`;
