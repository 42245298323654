import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';

export let ESimContext = createContext<
  | {
      isFromActive?: boolean | undefined;
      eSim: EsimEntityType;
    }
  | undefined
>(undefined);

export function useESimCardContext() {
  let context = useContext(ESimContext);
  if (!context) {
    throw new Error(
      'useESimCardContext must be called within an ESimCardProvider.',
    );
  }
  return context;
}

export function ESimCardProvider({
  eSim,
  isFromActive,
  children,
}: {
  eSim: EsimEntityType;
  isFromActive?: boolean | undefined;
  children: React.ReactNode;
}) {
  return (
    <ESimContext.Provider value={{ isFromActive, eSim }}>
      {children}
    </ESimContext.Provider>
  );
}
