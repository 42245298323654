import { ReactElement } from 'react';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { WEB_ROUTE_STORE_GLOBAL } from '@shared_frontend/Common/WebRoutes';
import { formatDateToShortString } from '@shared_frontend/Common/Date';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';
import GlobalImage from './Images/Global.png';
import AlertTriangleIcon from './Icons/AlertTriangle';
import SubscriptionAction from './SubscriptionAction';
import Support from './Icons/Support';
import styled from 'styled-components';

type ElementContent = {
  status: SubscriptionStatus;
  statusActionDateLabel: string | null;
  statusActionDate: Date | null;
  statusColor: string;
  subscriptionAction: ReactElement | null;
};

function capitalizeFirstLetter(string: SubscriptionStatus) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type IProps = {
  subscription: SubscriptionType;
};

export default function SubscriptionCard({ subscription }: IProps) {
  let subscriptionStrategyMap: Record<
    SubscriptionStatus,
    () => ElementContent
  > = {
    [SubscriptionStatus.ACTIVE]: () => ({
      status: SubscriptionStatus.ACTIVE,
      statusActionDateLabel: 'Next payment date:',
      statusActionDate: subscription.renewsAt,
      statusColor: 'var(--statusActivated)',
      subscriptionAction: null,
    }),
    [SubscriptionStatus.CANCELLED]: () => ({
      status: SubscriptionStatus.CANCELLED,
      statusActionDateLabel: 'Cancelled on:',
      statusActionDate: subscription.cancelRequestedAt,
      statusColor: 'var(--statusArchived)',
      subscriptionAction: (
        <RepurchaseLinkButton href={WEB_ROUTE_STORE_GLOBAL}>
          Repurchase
        </RepurchaseLinkButton>
      ),
    }),
    [SubscriptionStatus.REFUNDED]: () => ({
      status: SubscriptionStatus.REFUNDED,
      statusActionDateLabel: 'Refund date:',
      statusActionDate: subscription.refundedAt,
      statusColor: 'var(--statusArchived)',
      subscriptionAction: null,
    }),
    [SubscriptionStatus.UNPAID]: () => ({
      status: SubscriptionStatus.UNPAID,
      statusActionDateLabel: null,
      statusActionDate: null,
      statusColor: 'var(--statusArchived)',
      subscriptionAction: (
        <SupportButton onClick={handleZendeskOpenChatClick}>
          <Support />
          <span>Contact support</span>
        </SupportButton>
      ),
    }),
  };

  let subscriptionStrategy: ElementContent =
    subscriptionStrategyMap[subscription.status]();

  return subscriptionStrategy ? (
    <SubscriptionWrapper>
      <SubscriptionCardHeader>
        <SubscriptionCardTitle>
          <img src={GlobalImage} alt="global-subscription" />
          {/* //TODO: what is the static data? */}
          <span>Global plan: 2GB</span>
        </SubscriptionCardTitle>
        <SubscriptionAction
          subscriptionId={subscription.id}
          eSimId={subscription.esimId}
          isCancelable={
            [SubscriptionStatus.ACTIVE, SubscriptionStatus.UNPAID].includes(
              subscriptionStrategy.status,
            ) && !subscription.cancelRequestedAt
          }
        />
      </SubscriptionCardHeader>
      <CardSectionWrapper>
        <CardSectionLabel>Status: </CardSectionLabel>
        <StatusWrapper
          $bgColor={subscriptionStrategy.statusColor}
          data-testid={`esim-status-${subscriptionStrategy.status}`}
        >
          {capitalizeFirstLetter(subscriptionStrategy.status)}
        </StatusWrapper>
      </CardSectionWrapper>
      {subscriptionStrategy.statusActionDateLabel && (
        <CardSectionWrapper>
          <CardSectionLabel>
            {subscriptionStrategy.statusActionDateLabel}
          </CardSectionLabel>
          <CardSectionValue>
            {formatDateToShortString(subscriptionStrategy.statusActionDate)}
          </CardSectionValue>
        </CardSectionWrapper>
      )}
      {subscriptionStrategy.status === SubscriptionStatus.ACTIVE && (
        <CardSectionWrapper>
          <CardSectionLabel>Billed:</CardSectionLabel>
          {/* //TODO: what is the static data? */}
          <CardSectionValue>Monthly</CardSectionValue>
        </CardSectionWrapper>
      )}
      {subscriptionStrategy.status === SubscriptionStatus.UNPAID && (
        <UnpaidInfoWrapper>
          <IconBox>
            <AlertTriangleIcon />
          </IconBox>
          We couldn’t renew your plan. Please complete the payment to maintain
          access and retain your unused GBs.
        </UnpaidInfoWrapper>
      )}
      {subscription.cardEnding && (
        <CardSectionWrapper>
          <CardSectionLabel>
            <span>Payment method: </span>
          </CardSectionLabel>
          <CardSectionLabel>
            <span>Card ending in {subscription.cardEnding}</span>
          </CardSectionLabel>
        </CardSectionWrapper>
      )}
      {subscriptionStrategy.subscriptionAction}
    </SubscriptionWrapper>
  ) : null;
}

let SubscriptionWrapper = styled.div`
  padding: 16px;
  border: 1px solid var(--strokeSecondary);
  margin-bottom: 16px;
  border-radius: 16px;
  background-color: var(--backgroundSecondary);
`;

let SubscriptionCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

let SubscriptionCardTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightBold);
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

type StatusWrapperProps = {
  $bgColor: string;
};

export let StatusWrapper = styled.div<StatusWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 12px;
  padding: 2px 12px;
  line-height: 20px;
  z-index: 2;
  color: var(--textPrimary);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontFamilyPrimary);
  background-color: ${({ $bgColor }) => $bgColor};
`;

let CardSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

let CardSectionLabel = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
`;

let CardSectionValue = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
`;

let UnpaidInfoWrapper = styled.div`
  display: flex;
  line-height: 20px;
  margin-bottom: 12px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);

  @media (min-width: 960px) {
    padding-right: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

let IconBox = styled.span`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

let SupportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 53px;
  border-radius: 10px;
  line-height: 22px;
  cursor: pointer;
  color: var(--textLink);
  font-weight: var(--fontWeightSemiBold);
  border: 1px solid var(--strokeBrand);
  background-color: var(--backgroundSecondary);

  svg {
    margin-right: 8px;
  }
`;

let RepurchaseLinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 53px;
  border: none;
  border-radius: 10px;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
  color: var(--textPrimaryInvert);
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--buttonPrimary);
`;
