import Feedbacks from '@front_common/Components/Feedbacks/NewDesign/Feedbacks';
import './HomeFeedbackCarousel.css';

let feedbacksData = [
  {
    stars: 5,
    name: 'margret',
    flag: 'AU',
    country: 'Australia',
    text: 'I travel for business a lot and internet with no SIM card is super useful for me. Bought everything super and easy and fast. Was able to install everything right away. Thank you!',
  },
  {
    stars: 5,
    name: 's2312378',
    flag: 'TR',
    country: 'Turkey',
    text: 'got 5gb and it was perfect for my trip. very useful to arrive at airport and be able to call a taxi right away with no hassle with airport wi-fi',
  },
  {
    stars: 5,
    name: 'James',
    flag: 'ES',
    country: 'Spain',
    text: 'Great service! Thank you for keeping me online during my Europe trip!',
  },
  {
    stars: 5,
    name: 'Lillian',
    flag: 'TH',
    country: 'Thailand',
    text: 'just recently found out what esims are and it turned out to be super convenient :D no more hassle with all the SIM cards at different countries.',
  },
  {
    stars: 5,
    name: 'chill0903',
    flag: 'HK',
    country: 'Hong Kong',
    text: 'Saw an ad on Facebook and gave this service a try. Actually used another one, but they always have problem with service. This one turned out so much better, so I am happy I bought it.',
  },
  {
    stars: 5,
    name: 'H.Heift',
    flag: 'JP',
    country: 'Japan',
    text: 'Everything was great and best of all super simple. Turns out this is so much cheaper than roaming.. will always be buying e-sim here now',
  },
];

export default function HomeFeedbacks() {
  return (
    <Feedbacks feedbacksData={feedbacksData} baseClassName="newHomeFeedbacks" />
  );
}
