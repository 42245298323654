import { useEffect, useState } from 'preact/hooks';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import {
  useBundleByShortCodeQuery,
  usePriceQuery,
} from '@front_common/Api/VisitorApi';
import Card from '@front_common/Components/Card';
import AuthService from '@front_common/Api/AuthService';
import UserApi from '@front_common/Api/UserApi';
import { DiscountPaymentProvider } from '../../PaymentProcessor/DiscountPaymentContext';
import Payment from '../../PaymentProcessor/Payment/NewDesign/Payment';
import bluredSImage from '../NewDesign/Images/BluredMobile.png';
import bluredMImage from '../NewDesign/Images/BluredTablet.png';
import bluredLImage from '../NewDesign/Images/BluredDesktop.png';
import AuthForm from '../../../Auth/AuthForm/NewDesign/AuthForm';
import Legal from '../../../Auth/AuthForm/NewDesign/Legal';
import PaymentSecured from '../../PaymentSecured/NewDesign/PaymentSecured';
import ProductInfo from './ProductInfo';
import DiscountDropdown from './DiscountDropdown/DiscountDropdown';
import { Discount } from '../../PaymentProcessor/Discount/NewDesign/Discount';
import PaymentErrorSupport from './PaymentErrorSupport/PaymentErrorSupport';
import styled from 'styled-components';

type IProps = {
  bundleCodeAndPriceId: string;
  targetCountry: string;
  onPaymentSuccess: (paymentSession: PaymentSessionEntityType) => void;
};

export default function CheckoutAModalContent(props: IProps) {
  let { bundleCodeAndPriceId, targetCountry, onPaymentSuccess } = props;

  let [authenticated, setAuthenticated] = useState(AuthService.isLoggedIn());
  let [email, setEmail] = useState('');
  let [paymentError, setPaymentError] = useState<string | null>(null);

  let [bundleShortCode, priceId] = bundleCodeAndPriceId.split('_');

  if (!bundleShortCode || !priceId) {
    throw new Error('Bundle short code or price ID is not defined');
  }

  let [bundle] = useBundleByShortCodeQuery(bundleShortCode);
  let [price] = usePriceQuery(priceId);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let user = await UserApi.getUser();

        if (user) {
          setEmail(user.email);
        }
      }
    })();
  }, [authenticated]);

  if (!bundle || !price || !targetCountry) {
    return <LoadingAnimation />;
  }

  function handleAuthSuccess() {
    setAuthenticated(true);
  }

  return (
    <CheckoutModalContentWrapper>
      {paymentError && <PaymentErrorSupport text={paymentError} />}

      <DiscountPaymentProvider
        totalAmountCents={price.totalAmountCents}
        bundleCodeAndPriceId={bundleCodeAndPriceId}
        targetCountry={targetCountry}
      >
        <AuthWrapper>
          <ProductInfo
            authenticated={authenticated}
            email={email}
            bundle={bundle}
            totalAmountCents={price.totalAmountCents}
            targetCountry={targetCountry}
          />
          {!authenticated && (
            <AuthFormWrapper>
              <AuthForm onAuthSuccess={handleAuthSuccess} />
            </AuthFormWrapper>
          )}
        </AuthWrapper>
        {authenticated && (
          <DiscountDropdown>
            <Discount />
          </DiscountDropdown>
        )}

        <div>
          {authenticated ? (
            <PaymentWrapper>
              <PaymentTitle>Choose payment method</PaymentTitle>
              <Payment
                onSuccessfulPayment={onPaymentSuccess}
                onPaymentFail={setPaymentError}
              />
              <Legal text="By submitting your information and continuing to purchase, you agree to our" />
            </PaymentWrapper>
          ) : (
            <Blured>
              <picture>
                <source media="(min-width: 700px)" srcSet={bluredMImage} />
                <source media="(min-width: 960px)" srcSet={bluredLImage} />
                <img src={bluredSImage} alt="blured payment" />
              </picture>
            </Blured>
          )}
        </div>
      </DiscountPaymentProvider>
      <PaymentSecured />
    </CheckoutModalContentWrapper>
  );
}

let CheckoutModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

let AuthWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: none;
  padding: 0;
`;

let Blured = styled(Card)`
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  border: none;

  picture {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

let AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let PaymentWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
`;

let PaymentTitle = styled.h4`
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 12px;
  font-family: var(--fontFamilyNew);
  font-weight: var(--fontWeightBold);
`;
