export default function AlertIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9978 9.00023V13.0002M11.9978 17.0002H12.0078M10.6131 3.89195L2.38823 18.0986C1.93203 18.8866 1.70393 19.2806 1.73764 19.6039C1.76705 19.886 1.91482 20.1423 2.14417 20.309C2.40713 20.5002 2.86239 20.5002 3.77292 20.5002H20.2227C21.1332 20.5002 21.5885 20.5002 21.8514 20.309C22.0808 20.1423 22.2286 19.886 22.258 19.6039C22.2917 19.2806 22.0636 18.8866 21.6074 18.0986L13.3825 3.89195C12.9279 3.10679 12.7006 2.71421 12.4041 2.58235C12.1454 2.46734 11.8502 2.46734 11.5915 2.58235C11.295 2.71421 11.0677 3.10679 10.6131 3.89195Z"
        stroke="#F70D0D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
