// docs.strapi.io/dev-docs/api/rest
import { CONFIG_STRAPI_KEY } from '@front_common/Config';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

const STRAPI_URL = 'https://strapi.ohayu.com';

// Cache and pending requests maps
const cache = new Map<string, Promise<any>>();
const pendingRequests = new Map<string, Promise<any>>();

type StrapiRequestOptions = {
  populate?: string;
};

export function strapiAssetUrl(relativePath: string | undefined) {
  if (relativePath === undefined || relativePath === '') {
    logger.error(['Strapi asset url is broken: ', relativePath]);
    return '';
  }
  return STRAPI_URL + relativePath;
}

// TODO: move to /Api/StrapiRequest.ts?
export async function strapiRequest<T>(
  collection: string,
  options: StrapiRequestOptions = {},
): Promise<T> {
  let queryParams: { [key: string]: string } = {
    populate: options.populate ?? '*',
  };

  let url = STRAPI_URL + '/api/' + collection;
  url += '?' + new URLSearchParams(queryParams).toString();

  // Check if the result is already in the cache
  if (cache.has(url)) {
    return cache.get(url) as Promise<T>;
  }

  // Check if there's already a pending request for this URL
  if (pendingRequests.has(url)) {
    // Return the pending promise
    return pendingRequests.get(url) as Promise<T>;
  }

  // Create a new fetch promise
  let fetchPromise = fetch(url, {
    headers: {
      authorization: `Bearer ${CONFIG_STRAPI_KEY}`,
    },
  })
    .then(async (response) => {
      let data = await response.json();

      if (!response.ok) {
        logger.error('Strapi Error', data);
        throw new Error('Strapi Error');
      }

      // Cache the result
      let result = data.data;
      cache.set(url, result);

      // Remove from pending requests and return the result
      pendingRequests.delete(url);
      return result;
    })
    .catch((error) => {
      // Remove from pending requests in case of an error
      pendingRequests.delete(url);
      logger.error(error);
      throw error;
    });

  // Store the promise in the pending requests map
  pendingRequests.set(url, fetchPromise);

  return fetchPromise;
}
