export default function BlueCheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_114916)">
        <path
          d="M24 20H14.816C14.6666 19.5818 14.4262 19.2019 14.1122 18.8878C13.7981 18.5738 13.4182 18.3334 13 18.184V14H19C19.7956 14 20.5587 13.6839 21.1213 13.1213C21.6839 12.5587 22 11.7956 22 11V8H21V3C21 2.20435 20.6839 1.44129 20.1213 0.87868C19.5587 0.31607 18.7956 0 18 0L6 0C5.20435 0 4.44129 0.31607 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V8H2V11C2 11.7956 2.31607 12.5587 2.87868 13.1213C3.44129 13.6839 4.20435 14 5 14H11V18.184C10.5818 18.3334 10.2019 18.5738 9.88785 18.8878C9.5738 19.2019 9.3334 19.5818 9.184 20H0V22H9.184C9.38765 22.5845 9.76816 23.0912 10.2727 23.4498C10.7773 23.8083 11.381 24.0009 12 24.0009C12.619 24.0009 13.2227 23.8083 13.7273 23.4498C14.2318 23.0912 14.6124 22.5845 14.816 22H24V20ZM5 3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V8H14.586L13.586 9H10.414L9.414 8H5V3ZM4 11V10H8.586L9.586 11H14.414L15.414 10H20V11C20 11.2652 19.8946 11.5196 19.7071 11.7071C19.5196 11.8946 19.2652 12 19 12H5C4.73478 12 4.48043 11.8946 4.29289 11.7071C4.10536 11.5196 4 11.2652 4 11ZM12 22C11.8022 22 11.6089 21.9414 11.4444 21.8315C11.28 21.7216 11.1518 21.5654 11.0761 21.3827C11.0004 21.2 10.9806 20.9989 11.0192 20.8049C11.0578 20.6109 11.153 20.4327 11.2929 20.2929C11.4327 20.153 11.6109 20.0578 11.8049 20.0192C11.9989 19.9806 12.2 20.0004 12.3827 20.0761C12.5654 20.1518 12.7216 20.28 12.8315 20.4444C12.9414 20.6089 13 20.8022 13 21C13 21.2652 12.8946 21.5196 12.7071 21.7071C12.5196 21.8946 12.2652 22 12 22Z"
          fill="#EF8268"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_114916">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
