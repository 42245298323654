import styled, { css } from 'styled-components';
import BlueCheckIcon from './Icons/BlueCheckIcon';
import fireImage from './Images/Fire.png';
import { StoreItem } from '@shared_backend/Module/Store/StoreResponse';

type PlanCardProps = {
  planData: StoreItem;
  selected: boolean;
  popular: boolean;
  onPlanClick: () => void;
};

export default function PlanCard(props: PlanCardProps) {
  let { planData, selected, onPlanClick, popular } = props;

  return (
    <PlanCardStyled
      key={planData.bundleCodeAndPriceId}
      $popular={popular}
      $selected={selected}
      onClick={onPlanClick}
    >
      <PlanInfo>
        <Amount>
          <strong>{planData.dataAmountGb}GB</strong>
          <span>
            Validity:{' '}
            <time dateTime={`P${planData.durationDays}D`}>
              {planData.durationDays} days
            </time>
          </span>
        </Amount>
        <Price>
          <strong>${planData.totalAmountCents / 100}</strong>
          <span>
            $
            {Number.parseFloat(
              (planData.totalAmountCents / 100 / planData.dataAmountGb).toFixed(
                2,
              ),
            )}{' '}
            / per GB
          </span>
        </Price>
      </PlanInfo>
      <CheckWrapper $checked={selected}>
        <BlueCheckIcon />
      </CheckWrapper>
    </PlanCardStyled>
  );
}

let PlanCardStyled = styled.li<{ $popular: boolean; $selected: boolean }>`
  border-radius: var(--borderRadiusLarge);
  border: var(--borderOutlinePrimary);
  padding: 16px;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.$popular ? '#E0E4FF' : 'var(--backgroundSecondary)'};
  border: ${(props) =>
    props.$selected ? '1px solid var(--strokeBrand)' : '1px solid transparent'};

  ${(props) =>
    props.$popular &&
    css`
      &::after {
        content: 'Most popular';
        display: flex;
        align-items: center;
        gap: 4px;
        height: 24px;
        padding: 0 8px;
        padding-left: 24px;
        background: url(${fireImage}) no-repeat;
        background-position: left 8px center;
        background-size: 12px;
        position: absolute;
        top: -12px;
        right: 16px;
        background-color: var(--itemBrand);
        border-radius: 100px;
        color: var(--iconsPrimary);
        font-size: 12px;
        font-weight: var(--fontWeightSemiBold);
        line-height: 16px;
      }
    `} @media (
    min-width: 396px) {
    gap: 12px;
  }

  @media (min-width: 960px) {
    width: 196px;
    align-items: flex-start;
  }
`;

let PlanInfo = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  @media (min-width: 396px) {
    gap: 12px;
  }

  @media (min-width: 960px) {
    flex-direction: column;
  }
`;

let Amount = styled.p`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1.2;

  @media (min-width: 396px) {
    flex: 1;
  }

  strong {
    font-size: var(--fontSizeRegular);
    font-weight: var(--fontWeightBold);
    line-height: 22px;

    @media (min-width: 960px) {
      font-size: var(--fontSizeBig);
      line-height: 24px;
    }
  }

  span {
    display: inline-block;
    font-size: var(--fontSizeSmall);
    line-height: 20px;
    color: var(--textSecondary);
  }
`;

let Price = styled.p`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;

  strong {
    display: inline-block;
    font-size: var(--fontSizeRegular);
    font-weight: var(--fontWeightBold);
    line-height: 22px;

    @media (min-width: 960px) {
      font-size: var(--fontSizeBig);
      line-height: 24px;
    }
  }

  span {
    display: inline-block;
    font-size: var(--fontSizeSmall);
    line-height: 20px;
    color: var(--textSecondary);
  }
`;

let CheckWrapper = styled.div<{ $checked: boolean }>`
  height: 24px;
  width: 24px;
  visibility: ${(props) => (props.$checked ? 'visible' : 'hidden')};
`;
