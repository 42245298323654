import { strapiAssetUrl } from '@front_common/Strapi/StrapiRequest';
import FaqLayout from '../FaqLayout';
import LinksBox from '../LinksBox';
import { useFAQQuestions, useFAQTopics } from '@front_common/Strapi/StrapiFAQ';
import styled from 'styled-components';
import { WEB_ROUTE_FAQ } from '@shared_frontend/Common/WebRoutes';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { useEffect } from 'preact/compat';
import {
  setDescriptionHeader,
  setTitleHeader,
} from '@front_common/Seo/SeoHeaders';

export default function FaqTopicPage({ topicId }: { topicId: string }) {
  let [faqTopics] = useFAQTopics();
  let [faqQuestions] = useFAQQuestions();

  let topicQuestions = faqQuestions?.filter((question) => {
    let slug = question?.attributes?.faq_topic?.data?.attributes?.slug;
    if (!slug) {
      logger.error(['FAQ without slug: ', question]);
      return false;
    }
    return slug === topicId;
  });

  let topicObj = faqTopics?.find((topic) => topic.attributes.slug === topicId);
  if (!topicObj) {
    return <LoadingAnimation />;
  }

  useEffect(() => {
    setTitleHeader(topicObj.attributes.seo?.title || topicObj.attributes.name);
    setDescriptionHeader(topicObj.attributes.seo?.description || '');
  }, [topicObj]);

  let links = topicQuestions
    ?.sort(
      (questionA, questionB) =>
        questionA.attributes.order - questionB.attributes.order,
    )
    .map((question) => ({
      id: question.attributes.slug,
      href: `${WEB_ROUTE_FAQ}/${topicId}/${question.attributes.slug}`,
      text: question.attributes.question,
    }));

  let title = (
    <span>
      <TopicImage
        src={strapiAssetUrl(topicObj?.attributes.image.data?.attributes.url)}
        alt="topic image"
      />
      <span data-testid="faq-page-title">{topicObj?.attributes.name}</span>
    </span>
  );

  return (
    <FaqLayout title={title}>
      <LinksBox links={links || []} />
    </FaqLayout>
  );
}

let TopicImage = styled.img`
  height: 40px;
  line-height: 40px;
  margin-bottom: -7px;
  margin-right: 2px;

  @media (min-width: 700px) {
    height: 48px;
    margin-bottom: -10px;
    margin-right: 12px;
  }

  @media (min-width: 960px) {
    height: 64px;
    margin-bottom: -12px;
  }
`;
