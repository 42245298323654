import { HTMLAttributes } from 'preact/compat';
import { EmblaOptionsType } from 'embla-carousel';
import RightArrowIcon from './Icons/RightArrowSVG';
import Carousel from '@front_common/Components/Carousel';
import Flag from '@front_common/Components/Flag/Flag';
import Card from '@front_common/Components/Card';
import styled from 'styled-components';

export type Feedback = {
  stars: number;
  name: string;
  flag: string;
  country?: string;
  city?: string;
  text: string;
};

let OPTIONS: EmblaOptionsType = {
  slidesToScroll: 1,
  duration: 20,
  align: 'start',
};

type IProps = {
  feedbacksData: Feedback[];
  baseClassName: string;
};

export default function Feedbacks(props: IProps) {
  let { feedbacksData, baseClassName } = props;

  return (
    <Carousel
      slides={feedbacksData}
      options={OPTIONS}
      baseClassName={baseClassName}
      getSlideComponent={(data: Feedback) => <Slide slideData={data} />}
      prevBtnChildren={
        <ButtonLeftWrapper>
          <RightArrowIcon />
        </ButtonLeftWrapper>
      }
      nextBtnChildren={
        <ButtonRightWrapper>
          <RightArrowIcon />
        </ButtonRightWrapper>
      }
    />
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Feedback;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <SlideWrapper>
      <StyledCard>
        <FlagEtc>
          <FlagWrapper>
            <FlagStyled
              countryCode={slideData.flag}
              circle={true}
              loading="lazy"
              className={slideData.flag === 'JP' ? 'japan' : ''}
            />
          </FlagWrapper>
          <UserCountry>
            <User>{slideData.name}</User>
            <CountryCity>{`used eSIM card in ${slideData.city || slideData.country}`}</CountryCity>
          </UserCountry>
        </FlagEtc>
        <FeedbackText>{slideData.text}</FeedbackText>
      </StyledCard>
    </SlideWrapper>
  );
}

let SlideWrapper = styled.article`
  border-radius: 16px;
  font-family: var(--fontFamilyNew);
  border: 1px solid var(--strokePrimaryNew);

  @media (min-width: 700px) {
    height: 100%;
  }

  @media (min-width: 960px) {
    height: 100%;
  }
`;

let StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin: initial;
  font-family: var(--fontFamilyPrimary);

  @media (min-width: 960px) {
    padding: 20px;
  }
`;

let FlagEtc = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

let FlagWrapper = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--strokePrimaryNew);

  img {
    width: 46px;
    height: 46px;
    object-fit: cover;
  }
`;

let FlagStyled = styled(Flag)`
  height: 38px;
  overflow: hidden;
  border-radius: 10px;

  &.japan {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

let UserCountry = styled.div`
  display: flex;
  flex-direction: column;
`;

let User = styled.span`
  line-height: 30px;
  font-family: var(--fontFamilyNew);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;

let CountryCity = styled.h3`
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondaryNew);
  font-family: var(--fontFamilyNew);
  font-weight: var(--fontWeightRegular);
`;

let FeedbackText = styled.p`
  line-height: 20px;
  color: var(--textSecondaryNew);
  font-family: var(--fontFamilyNew);
  font-size: var(--fontSizeRegular);
`;

let ButtonLeftWrapper = styled.div`
  position: relative;
  height: 100%;
  transform: rotate(180deg);
`;

let ButtonRightWrapper = styled.div`
  right: 0;
  height: 100%;
`;
