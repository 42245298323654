import UserApi, { useGetMyEsimsQuery } from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import styled from 'styled-components';

type QRCodeImageProps = {
  id: string;
};

export function QRCodeImage(props: QRCodeImageProps) {
  let { id } = props;

  // TODO: move to parent
  let [response] = useGetMyEsimsQuery();

  if (!response) {
    return <LoadingAnimation />;
  }

  let esim = response.esims.find((item) => item.id === Number(id));

  if (!esim) {
    throw new Error('404: eSIM is not defined!');
  }

  return (
    <QRCodeImageStyled
      src={UserApi.qrCodeImageUrl(esim.smdpAddress, esim.activationCode)}
      alt="qrcode"
    />
  );
}

let QRCodeImageStyled = styled.img`
  width: 100%;
`;
