/* eslint-disable max-lines-per-function */
import { SubscriptionPlanNameWithIcon } from '@front_common/Components/EsimPlanName';
import styled from 'styled-components';
import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import Button from '@front_common/Components/Button/Button';
import { useESimCardContext } from '../../ESimContext';
import Card from '@front_common/Components/Card';
import BellIcon from '../Icons/BellIcon';
import StatusBadge from '../../ESimsPage/ESimSubCard/StatusBadge';
import GlobeIcon from '../Icons/GlobeIcon';
import RefreshIcon from '../Icons/RefreshIcon';
import ChartIcon from '../Icons/ChartIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import Tooltip from '../../Tooltip/Tooltip';
import simCardImage from '../Images/SimCardImage.png';
import SupportIcon from '../Icons/SupportIcon';
import supportImage from '../Images/SupportImage.png';
import settingsImage from '../Images/SettingsImage.png';
import planDetailsImage from '../Images/PlanDetailsImage.png';
import troubleShoutingImage from '../Images/TroubleShoutingImage.png';
import inslallInstructionsImage from '../Images/InslallInstructionsImage.png';
import { Action } from './Action';
import { QuickInfo } from './QuickInfo';
import {
  calculateUsagePercentage,
  formatDataAmount,
  formatDateToShortString,
  timeLeftFromToday,
} from '../../Utils';
import { DataLoaderContainer } from './DataLoaderContainer';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { ReactElement } from 'preact/compat';

type ESimSubPageMainContentProps = {
  subscription: SubscriptionType;
  onPurchaseClick: () => void;
  onSupportClick: () => void;
  onInstallNowClick: (eSimId: number) => void;
  onViewClick: () => void;
  onManageSubscriptionClick: () => void;
  onInstallationInstructionsClick: (eSimId: number) => void;
  onPlanDetailsClick: () => void;
  onTroubleshootingClick: () => void;
};

export default function ESimSubPageMainContent(
  props: ESimSubPageMainContentProps,
) {
  let {
    subscription,
    onPurchaseClick,
    onSupportClick,
    onInstallNowClick,
    onViewClick,
    onManageSubscriptionClick,
    onInstallationInstructionsClick,
    onPlanDetailsClick,
    onTroubleshootingClick,
  } = props;
  let { eSim } = useESimCardContext();

  let availableData = formatDataAmount(eSim.dataRemainingMb);
  let purchasedData = formatDataAmount(eSim.dataPurchasedGb * 1024);
  let purchasedDataWithRolledOver = eSim.dataRolledOverMb
    ? formatDataAmount(eSim.dataPurchasedGb * 1024 + eSim.dataRolledOverMb)
    : formatDataAmount(eSim.dataPurchasedGb * 1024);
  let rolledOverData = eSim.dataRolledOverMb
    ? formatDataAmount(eSim.dataRolledOverMb)
    : null;

  let renewsIn = timeLeftFromToday(subscription.renewsAt);

  let eSimStatusLoaderMap: Record<EsimStatus, ReactElement | null> = {
    [EsimStatus.PREPARING]: (
      <DataLoaderContainer>
        <span>Data amount: </span>
        <BoldBox>{purchasedData}</BoldBox>
      </DataLoaderContainer>
    ),
    [EsimStatus.NOT_INSTALLED]: (
      <DataLoaderContainer>
        <span>Available: </span>
        <BoldBox>{availableData}</BoldBox>
      </DataLoaderContainer>
    ),
    [EsimStatus.INSTALLED]: (
      <DataLoaderContainer>
        <span>Data amount: </span>
        <BoldBox>{purchasedDataWithRolledOver}</BoldBox>
      </DataLoaderContainer>
    ),
    [EsimStatus.ACTIVATED]:
      subscription.status === SubscriptionStatus.UNPAID ? (
        <DataLoaderContainer>
          <span>Data amount: </span>
          <BoldBox>0GB</BoldBox>
        </DataLoaderContainer>
      ) : (
        <DataLoaderContainer
          progress={calculateUsagePercentage(
            eSim.dataUsedMb,
            eSim.dataPurchasedGb * 1024 + (eSim.dataRolledOverMb || 0),
          )}
          primaryColor="var(--progressBarPrimary)"
          secondaryColor="var(--progressBarSecondary)"
        >
          <span>
            Available <BoldBox>{availableData}</BoldBox>
          </span>
          <br />
          <span>
            out of {purchasedDataWithRolledOver}{' '}
            <Tooltip
              tooltipContent="Data balance may not include recent usage. Updates can take a short
            while to appear."
            />
          </span>
        </DataLoaderContainer>
      ),
    [EsimStatus.EXPIRED]: (
      <DataLoaderContainer>
        <span>Data amount: </span>
        <BoldBox>0GB</BoldBox>
      </DataLoaderContainer>
    ),
    [EsimStatus.DATA_FINISHED]: (
      <DataLoaderContainer
        progress={100}
        primaryColor="var(--progressBarPrimary)"
      >
        <BoldBox>0GB</BoldBox>
        <br />
        <span>out of {purchasedDataWithRolledOver}</span>
      </DataLoaderContainer>
    ),
    [EsimStatus.REFUNDED]: (
      <DataLoaderContainer>
        <span>Data amount: </span>
        <BoldBox>0GB</BoldBox>
      </DataLoaderContainer>
    ),
    [EsimStatus.UNKNOWN]: (
      <DataLoaderContainer>
        <span>Data amount: </span>
        <BoldBox>0GB</BoldBox>
      </DataLoaderContainer>
    ),
  };

  let eSimStatusInfoDynamicMap: Record<EsimStatus, ReactElement | null> = {
    [EsimStatus.PREPARING]: (
      <InfoRow>
        <InfoLabel>
          <RefreshIcon />
          <span>
            {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
          </span>
        </InfoLabel>
        <InfoValue>{renewsIn}</InfoValue>
      </InfoRow>
    ),
    [EsimStatus.NOT_INSTALLED]: (
      <>
        <InfoRow>
          <InfoLabel>
            <RefreshIcon />
            <span>
              {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
            </span>
          </InfoLabel>
          <InfoValue>{renewsIn}</InfoValue>
        </InfoRow>
        {rolledOverData && (
          <InfoRow>
            <InfoLabel>
              <ChartIcon />
              <span>Rolled over data</span>
              <Tooltip tooltipContent="Your leftover data has been rolled over." />
            </InfoLabel>
            <InfoValue>{rolledOverData}</InfoValue>
          </InfoRow>
        )}
      </>
    ),
    [EsimStatus.INSTALLED]: (
      <>
        <InfoRow>
          <InfoLabel>
            <RefreshIcon />
            <span>
              {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
            </span>
          </InfoLabel>
          <InfoValue>{renewsIn}</InfoValue>
        </InfoRow>
        {rolledOverData && (
          <InfoRow>
            <InfoLabel>
              <ChartIcon />
              <span>Rolled over data</span>
              <Tooltip tooltipContent="Your leftover data has been rolled over." />
            </InfoLabel>
            <InfoValue>{rolledOverData}</InfoValue>
          </InfoRow>
        )}
      </>
    ),
    [EsimStatus.ACTIVATED]:
      subscription.status === SubscriptionStatus.UNPAID ? null : (
        <>
          <InfoRow>
            <InfoLabel>
              <RefreshIcon />
              <span>
                {subscription.cancelRequestedAt ? 'Ends in' : 'Renews in'}:
              </span>
            </InfoLabel>
            <InfoValue>{renewsIn}</InfoValue>
          </InfoRow>
          {rolledOverData && (
            <InfoRow>
              <InfoLabel>
                <ChartIcon />
                <span>Rolled over data</span>
                <Tooltip tooltipContent="Your leftover data has been rolled over." />
              </InfoLabel>
              <InfoValue>{rolledOverData}</InfoValue>
            </InfoRow>
          )}
        </>
      ),
    [EsimStatus.EXPIRED]: (
      <InfoRow>
        <InfoLabel>
          <CalendarIcon />
          <span>Expired on:</span>
        </InfoLabel>
        <InfoValue>
          {formatDateToShortString(subscription.cancelledAt)}
        </InfoValue>
      </InfoRow>
    ),
    [EsimStatus.DATA_FINISHED]: (
      <>
        <InfoRow>
          <InfoLabel>
            <RefreshIcon />
            <span>Renews in:</span>
          </InfoLabel>
          <InfoValue>{renewsIn}</InfoValue>
        </InfoRow>
        {rolledOverData && (
          <InfoRow>
            <InfoLabel>
              <ChartIcon />
              <span>Rolled over data</span>
              <Tooltip tooltipContent="Your leftover data has been rolled over." />
            </InfoLabel>
            <InfoValue>{rolledOverData}</InfoValue>
          </InfoRow>
        )}
      </>
    ),
    [EsimStatus.REFUNDED]: (
      <InfoRow>
        <InfoLabel>
          <CalendarIcon />
          <span>Refund date:</span>
        </InfoLabel>
        <InfoValue>
          {formatDateToShortString(subscription.refundedAt)}
        </InfoValue>
      </InfoRow>
    ),
    [EsimStatus.UNKNOWN]: null,
  };

  let eSimStatusQuickInfoMap: Record<EsimStatus, ReactElement | null> = {
    [EsimStatus.PREPARING]: (
      <QuickInfo text="Your payment is received. Please wait while we prepare your eSIM."></QuickInfo>
    ),
    [EsimStatus.NOT_INSTALLED]: (
      <QuickInfo text="Install the eSIM on your device to start using your plan."></QuickInfo>
    ),
    [EsimStatus.INSTALLED]: (
      <QuickInfo text="Your eSIM is installed but not yet active. To start using your data plan, you must have the line and data roaming turned on."></QuickInfo>
    ),
    [EsimStatus.ACTIVATED]:
      subscription.status === SubscriptionStatus.UNPAID ? (
        <QuickInfo
          isAlert
          text="Your payment didn’t go through, so your eSIM has no data. Complete
          payment to renew your plan."
        />
      ) : (
        <QuickInfo text="Your eSIM is active and ready to use. Enjoy your data plan! You can monitor your data usage and see the validity period here."></QuickInfo>
      ),
    [EsimStatus.EXPIRED]: (
      <NeedToReconnectWrapper>
        <img src={simCardImage} alt="sim card" />
        <ReconnectTextBlock>
          <ReconnectTitle>Need to reconnect?</ReconnectTitle>
          <ReconnectText>
            Your subscription was canceled. You can reconnect by buying a new
            plan.
          </ReconnectText>
        </ReconnectTextBlock>
        <Button sizeParam="L" onClick={onPurchaseClick}>
          Repurchase
        </Button>
      </NeedToReconnectWrapper>
    ),
    [EsimStatus.DATA_FINISHED]: (
      <SupportWrapper>
        <SupportIconText>
          <SuportText>
            If you need update your data amount, please contact us.
          </SuportText>
          <img src={supportImage} alt="support" />
        </SupportIconText>
        <Button
          onClick={onSupportClick}
          sizeParam="L"
          typeStyle="Secondary"
          leftIcon={<SupportIcon />}
        >
          Contact support
        </Button>
      </SupportWrapper>
    ),
    [EsimStatus.REFUNDED]: (
      <QuickInfo text="Your purchase has been refunded, and the payment has been returned to your payment method."></QuickInfo>
    ),
    [EsimStatus.UNKNOWN]: null,
  };

  // eslint-disable-next-line no-shadow
  enum ActionType {
    MANAGE_SUBSCRIPTION = 'manageSubscription',
    INSTRUCTIONS_INSTALLATION = 'instructionsInstallation',
    PLAN_DETAILS = 'planDetails',
    TROUBLESHOOTING = 'troubleshooting',
  }

  let actions = {
    [ActionType.MANAGE_SUBSCRIPTION]: (
      <Action
        onActionClick={onManageSubscriptionClick}
        icon={settingsImage}
        text="Manage subscription"
      />
    ),
    [ActionType.INSTRUCTIONS_INSTALLATION]: (
      <Action
        onActionClick={() => onInstallationInstructionsClick(eSim.id)}
        icon={inslallInstructionsImage}
        text="Installation instructions"
      />
    ),
    [ActionType.PLAN_DETAILS]: (
      <Action
        onActionClick={onPlanDetailsClick}
        icon={planDetailsImage}
        text="Plan details"
      />
    ),
    [ActionType.TROUBLESHOOTING]: (
      <Action
        onActionClick={onTroubleshootingClick}
        icon={troubleShoutingImage}
        text="Troubleshooting"
      />
    ),
  };

  let eSimStatusActionsMap: Record<EsimStatus, ReactElement | null> = {
    [EsimStatus.PREPARING]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.PLAN_DETAILS]}
        {actions[ActionType.TROUBLESHOOTING]}
      </>
    ),
    [EsimStatus.NOT_INSTALLED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.INSTRUCTIONS_INSTALLATION]}
        {actions[ActionType.PLAN_DETAILS]}
        {actions[ActionType.TROUBLESHOOTING]}
      </>
    ),
    [EsimStatus.INSTALLED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.INSTRUCTIONS_INSTALLATION]}
        {actions[ActionType.PLAN_DETAILS]}
        {actions[ActionType.TROUBLESHOOTING]}
      </>
    ),
    [EsimStatus.ACTIVATED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.INSTRUCTIONS_INSTALLATION]}
        {actions[ActionType.PLAN_DETAILS]}
        {actions[ActionType.TROUBLESHOOTING]}
      </>
    ),
    [EsimStatus.EXPIRED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.PLAN_DETAILS]}
      </>
    ),
    [EsimStatus.DATA_FINISHED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.PLAN_DETAILS]}
      </>
    ),
    [EsimStatus.REFUNDED]: (
      <>
        {actions[ActionType.MANAGE_SUBSCRIPTION]}
        {actions[ActionType.PLAN_DETAILS]}
      </>
    ),
    [EsimStatus.UNKNOWN]: null,
  };

  return (
    <>
      <EsimTitleWrapper>
        <ESimTitle>
          <SubscriptionPlanNameWithIcon amountGb={eSim.dataPurchasedGb} />
        </ESimTitle>
      </EsimTitleWrapper>
      {eSimStatusLoaderMap[eSim.status]}
      <InfoCard>
        <InfoRow>
          <InfoLabel>
            <GlobeIcon />
            <span>Covers 112 countries</span>
          </InfoLabel>
          <ViewButton onClick={onViewClick}>view</ViewButton>
        </InfoRow>
        {eSimStatusInfoDynamicMap[eSim.status]}
        <Divider />
        <InfoRow>
          <InfoLabel>
            <BellIcon />
            <span>eSIM status:</span>
          </InfoLabel>
          <StatusBadge eSimStatus={eSim.status} />
        </InfoRow>
      </InfoCard>
      {eSimStatusQuickInfoMap[eSim.status]}
      {[EsimStatus.NOT_INSTALLED].includes(eSim.status) && (
        <ButtonStyled sizeParam="L" onClick={() => onInstallNowClick(eSim.id)}>
          Install now
        </ButtonStyled>
      )}
      <ActionsWrapper>{eSimStatusActionsMap[eSim.status]}</ActionsWrapper>
    </>
  );
}

let EsimTitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

let InfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

let Divider = styled.div`
  height: 1px;
  background-color: var(--strokeSecondary);
`;

let InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

let InfoLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-weight: var(--fontWeightMedium);
    line-height: 22px;
  }
`;

let InfoValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--fontWeightBold);
  line-height: 22px;
`;

let ViewButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: var(--fontWeightBold);
  line-height: 22px;
  text-decoration: underline;
  color: var(--textLink);
`;

let ESimTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 34px;
  padding: 10px 0;
  text-align: left;
  margin-right: 8px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);

  span {
    display: inline;
  }

  img {
    width: 36px;
    height: 36px;
    margin-bottom: -4px;
  }
`;

let ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`;

let BoldBox = styled.span`
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  color: var(--textPrimary);
`;

let NeedToReconnectWrapper = styled.div`
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--orange70);

  img {
    width: 42px;
    height: 54px;
    margin-bottom: 8px;
  }
`;

let ReconnectTextBlock = styled.div`
  margin-bottom: 12px;
`;

let ReconnectTitle = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  margin-bottom: 4px;
`;

let ReconnectText = styled.p`
  line-height: 22px;
`;

let SupportWrapper = styled.div`
  background-color: var(--orange70);
  border-radius: 16px;
  padding: 20px;

  button {
    width: 100%;
  }
`;

let SupportIconText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  img {
    width: 60px;
    height: auto;
  }
`;

let SuportText = styled.p`
  line-height: 22px;
`;

let ActionsWrapper = styled(Card)`
  padding: 0 16px;
`;
