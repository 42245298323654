import { HTMLAttributes } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import styled from 'styled-components';

interface TimerButtonProps extends HTMLAttributes<HTMLButtonElement> {
  timeSeconds: number;
  activeName: string;
  timerName: string;
  disabled: boolean;
}

export default function TimerButton(props: TimerButtonProps) {
  let { timeSeconds, activeName, timerName, disabled, ...rest } = props;

  let [secondsLeft, setSecondsLeft] = useState(timeSeconds);

  let timerRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    let timer = setInterval(() => {
      setSecondsLeft((prev) => prev - 1);
    }, 1000);

    timerRef.current = timer;

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (timerRef.current && secondsLeft <= 0) {
      clearInterval(timerRef.current);
    }
  }, [secondsLeft]);

  return (
    // @ts-expect-error  @TODO fix this pls
    <TimerButtonStyled disabled={secondsLeft > 0 || disabled} {...rest}>
      {secondsLeft <= 0 ? (
        <ActiveName>{activeName}</ActiveName>
      ) : (
        <TimerName>
          {timerName}
          {secondsLeft}s
        </TimerName>
      )}
    </TimerButtonStyled>
  );
}

let TimerButtonStyled = styled.button`
  width: 100%;
  height: 20px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

let ActiveName = styled.div`
  color: var(--textSelected);
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;
  text-align: center;
`;

let TimerName = styled.div`
  color: var(--textSecondary);
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;
  text-align: center;
`;
