import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import SolidgateProcessor from '../Checkout/PaymentProcessor/SolidgateProcessor/OldDesign/SolidgateProcessor';
import {
  getQueryParam,
  getQueryParamOrFail,
  LinkToRoute,
  routeHardRedirectWithReload,
} from '@front_common/Router/Router';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'preact/hooks';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import ProductInfoSubs from './ProductInfoSubs';
import {
  useGetSubscriptionPaymentStatus,
  useSubscriptionProducts,
} from '@front_common/Api/VisitorApi';
import { WEB_ROUTE_SUBSCRIPTION_POLICY } from '@shared_frontend/Common/WebRoutes';
import { useSessionData } from '@front_common/Api/Session';
import { showConsentCountries } from '@shared_frontend/Module/Email/ShowConsentCountries';

function onSuccess() {
  routeHardRedirectWithReload(window.location.href + '&payment_success=1');
}

let CONFIRMATION_PAYMENT_ERROR = 'Please tick this box if you want to proceed.';

//we receive subs_pay_session_id, product_id and user_id
//we get the information about the products by product_id
//we get the paymentSession for solidgate by subs_pay_session_id
// eslint-disable-next-line secrets/no-secrets
// http://localhost:5173/webview/payment-subs?hide_layout=1&product_id=4&subs_pay_session_id=94280&user_id=15782
export default function PaymentWebviewSubs() {
  let checkboxRef = useRef<HTMLInputElement | null>(null);

  let sessionData = useSessionData();

  let [errorText, setErrorText] = useState<string>('');
  let [isLegalChecked, setISLegalChecked] = useState(false);
  let [confirmValidationError, setConfirmValidationError] = useState('');
  let [skipLegalCheck, setSkipLegalCheck] = useState(false);

  let success = getQueryParam('payment_success');

  if (success) {
    return <>Payment successful.</>;
  }

  let subsPaymentSessionId = Number(getQueryParamOrFail('subs_pay_session_id'));
  let productId = Number(getQueryParamOrFail('product_id'));
  let userId = Number(getQueryParamOrFail('user_id'));

  if (!subsPaymentSessionId || !productId) {
    return <>wrong parameters</>;
  }

  let [subsPaymentSession, subsPaymentSessionError, isLoadingSession] =
    useGetSubscriptionPaymentStatus(subsPaymentSessionId, userId);
  if (isLoadingSession) {
    return <LoadingAnimation />;
  }
  if (subsPaymentSessionError || !subsPaymentSession) {
    return (
      <ErrorBlock
        message={
          subsPaymentSessionError
            ? subsPaymentSessionError.message
            : 'Unknown error'
        }
      />
    );
  }
  if (subsPaymentSession && subsPaymentSession.paid) {
    onSuccess();
    return null;
  }

  let [products, productsError, isLoadingProducts] = useSubscriptionProducts();
  if (isLoadingProducts) {
    return <LoadingAnimation />;
  }
  if (productsError || !products) {
    return (
      <ErrorBlock
        message={productsError ? productsError.message : 'Unknown error'}
      />
    );
  }

  let product = products.find((productItem) => productItem.id === productId);
  let billingPeriod = product?.billingPeriod === 1 ? 'month' : 'year';

  useEffect(() => {
    if (sessionData && !showConsentCountries.includes(sessionData.country)) {
      setSkipLegalCheck(true);
      setISLegalChecked(true);
    }
  }, [sessionData]);

  useEffect(() => {
    if (confirmValidationError && checkboxRef.current) {
      checkboxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [confirmValidationError]);

  function handleCheckboxChange() {
    setISLegalChecked((prev) => {
      if (!prev) {
        setConfirmValidationError('');
      }
      return !prev;
    });
  }

  function validateConfirmation() {
    if (!isLegalChecked) {
      setConfirmValidationError(CONFIRMATION_PAYMENT_ERROR);
    }
  }

  return (
    <Wrapper>
      {product && (
        <ProductInfoSubs
          dataAmountGb={product?.dataGb}
          totalAmount={product?.priceCents / 100}
          billedPeriod={`Billed ${billingPeriod}ly`}
        />
      )}
      <Space />
      {errorText && <ErrorBlock message={'Payment error: ' + errorText} />}
      <SolidgateProcessor
        paymentSession={subsPaymentSession}
        isWebView={true}
        onSuccess={onSuccess}
        onFail={setErrorText}
        isConfirmedPolicy={isLegalChecked}
        onValidateConfirmation={validateConfirmation}
      />
      <Space />
      {/* TODO: Reuse it */}
      <Legal>
        {!skipLegalCheck && (
          <Checkbox
            ref={checkboxRef}
            $hasError={Boolean(confirmValidationError)}
            type="checkbox"
            checked={isLegalChecked}
            onChange={handleCheckboxChange}
          />
        )}
        <ColumnBox>
          {!skipLegalCheck && (
            <LegalConfirmMessage>
              By starting your plan immediately, you agree to forfeit the 14-day
              right of withdrawal under EU consumer protection laws.
            </LegalConfirmMessage>
          )}

          {confirmValidationError && (
            <ConfirmValidationError>
              {confirmValidationError}
            </ConfirmValidationError>
          )}

          <LegalConfirmMessage>
            By continuing, you agree to a{' '}
            <TextBold>
              {` ${subsPaymentSession.totalAmountCents / 100} ${billingPeriod}ly `}
            </TextBold>{' '}
            charge unless canceled at least 24 hours before renewal. Cancel
            anytime in settings. Charges will appear as{' '}
            <TextBold>Ohayu</TextBold> on your credit card. Read more in
            <ButtonLink href={WEB_ROUTE_SUBSCRIPTION_POLICY}>
              {' '}
              Subscription Terms
            </ButtonLink>
            .
          </LegalConfirmMessage>
        </ColumnBox>
      </Legal>
    </Wrapper>
  );
}

let Space = styled.div`
  height: 16px;
`;

let Wrapper = styled.div`
  max-width: 410px;
  margin: 0 auto;
`;

let Legal = styled.div`
  display: flex;
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let LegalConfirmMessage = styled.span`
  font-size: 12px;
  line-height: 18px;

  &:first-child {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-top: 4px;
  }
`;

let TextBold = styled.span`
  line-height: 16px;
  font-weight: var(--fontWeightBold);
`;

type CheckBoxProp = {
  $hasError: boolean;
};

let Checkbox = styled.input.attrs<CheckBoxProp>({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;

  /* Hide default checkbox */
  appearance: none;
  border: ${(props) =>
    props.$hasError
      ? '1px solid var(--errorButtonDanger)'
      : 'var(--borderOutlinePrimary)'};
  border-radius: 6px;
  position: relative;

  /* Custom checkmark */

  &:checked::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid var(--strokeBrand);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

let ButtonLink = styled(LinkToRoute)`
  cursor: pointer;
  text-decoration: underline;
  color: var(--textLink);
`;

let ConfirmValidationError = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: var(--fontWeightRegular);
  color: var(--errorButtonDanger);
`;
