import React, { forwardRef, HTMLAttributes } from 'react';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import OldDesignTextSearchInput from './OldDesign/TextSearchInput';
import NewDesignTextSearchInput from './NewDesign/TextSearchInput';
import { useDynamicSplitComponent } from '../../../Module/Layout/useDynamicSplitComponent';

interface TextSearchInputProps extends HTMLAttributes<HTMLInputElement> {
  customIcon: React.ReactNode;
  placeholder?: string;
}

export let TextSearchInputContainer = forwardRef<
  HTMLInputElement,
  TextSearchInputProps
>((props, ref) => {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  let TextSearchInputComponent = useDynamicSplitComponent<TextSearchInputProps>(
    {
      components: {
        [DesignSplitGroupId.A]: OldDesignTextSearchInput,
        [DesignSplitGroupId.B]: NewDesignTextSearchInput,
      },
      identifier: splitGroupId || DesignSplitGroupId.A,
    },
  );

  return <TextSearchInputComponent ref={ref} {...props} />;
});

TextSearchInputContainer.displayName = 'TextSearchInputContainer';
