import { useContext } from 'preact/hooks';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { WEB_ROUTE_MY_SUBSCRIPTIONS } from '@shared_frontend/Common/WebRoutes';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { routeRedirect } from '@front_common/Router/Router';
import { useGetMySubscriptions } from '@front_common/Api/UserApi';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import { UnsubscribeContext, UnsubscribeProvider } from './UnsubscribeProvider';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import styled from 'styled-components';

type IUnsubscribeFormProps = {
  subscription: SubscriptionType;
};

function UnsubscribeForm({
  subscription: { id, renewsAt },
}: IUnsubscribeFormProps) {
  let context = useContext(UnsubscribeContext);

  //TODO: this error better to move to the provider
  //TODO: to be honest, I don't see the reason to use the context here.
  //You can just use the same logic (that in the provider) in this component.
  //The profit - less code, and you see the logical conection between the steps in this form.
  if (!context) {
    throw new Error('UnsubscribeContext must be used within a provider');
  }

  let { step, prevStep } = context;

  return (
    <>
      <BackButtonContainer>
        <BackButton onClick={prevStep}>
          <ChevronLeftIcon />
        </BackButton>
      </BackButtonContainer>
      <UnsubscribeFormWrapper>
        {step === 1 && <FirstStep />}
        {step === 2 && <SecondStep />}
        {step === 3 && (
          <ThirdStep subscriptionId={id} cancellationEffectiveDate={renewsAt} />
        )}
      </UnsubscribeFormWrapper>
    </>
  );
}

//TODO: you can get the subscriptionId from the component's params, so you don't need to use the router
export default function UnsubscribePage() {
  let router = useRouterState();

  let [subs, error, isLoading] = useGetMySubscriptions();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  let subscriptionId = router.matches?.['subscriptionId'] || '';

  let currentSubscription = subs?.find(
    (sub) => sub.id === Number(subscriptionId),
  );

  if (
    !isLoading &&
    (!currentSubscription ||
      ![SubscriptionStatus.ACTIVE, SubscriptionStatus.UNPAID].includes(
        currentSubscription.status,
      ))
  ) {
    return routeRedirect(WEB_ROUTE_MY_SUBSCRIPTIONS);
  }

  return (
    <UnsubscribeProvider>
      <UnsubscribeWrapperStyled>
        {currentSubscription && (
          <UnsubscribeForm subscription={currentSubscription} />
        )}
      </UnsubscribeWrapperStyled>
    </UnsubscribeProvider>
  );
}

let UnsubscribeWrapperStyled = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  @media (min-width: 700px) {
    max-width: 642px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;

let BackButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  &:disabled {
    cursor: default;
  }

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
  }
`;

let UnsubscribeFormWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
