import { useState } from 'preact/hooks';
import ChevronIcon from '../Icons/ChevronIcon';
import styled from 'styled-components';

type MenuSelectProps = {
  children: React.ReactNode;
  placeholder: string;
};

export default function MenuSelect(props: MenuSelectProps) {
  let { placeholder, children } = props;
  let [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownContainer
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <DropdownButton $isOpen={isOpen}>
        <span>{placeholder}</span>
        <ChevronIcon />
      </DropdownButton>
      <DropdownListWrapper $isOpen={isOpen}>
        <DropdownList>{children}</DropdownList>
      </DropdownListWrapper>
    </DropdownContainer>
  );
}

let DropdownContainer = styled.div`
  position: relative;
`;

let DropdownButton = styled.button<{ $isOpen: boolean }>`
  height: 42px;
  padding: 0 16px;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  color: ${(props) =>
    props.$isOpen ? 'var(--black100)' : 'var(--textPrimaryNew)'};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    transition: transform 0.2s;
    transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : '')};

    path {
      stroke: ${(props) => (props.$isOpen ? 'var(--black100)' : '')};
    }
  }
`;

let DropdownListWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: transparent;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 1;
`;

let DropdownList = styled.ul`
  background-color: white;
  list-style: none;
  background: var(--backgroundSecondary);
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  padding: 8px 0;
`;

export let DropdownItem = styled.li`
  height: 36px;
  padding: 0 12px;
  cursor: pointer;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;

  a {
    width: 100%;
    text-decoration: none;
    color: var(--textPrimaryNew);
  }

  &:hover {
    background-color: var(--itemHover);
  }
`;
