export function XiaomiIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.625 3H7.375C4.96875 3 3 4.96875 3 7.375V33.625C3 36.0312 4.96875 38 7.375 38H33.625C36.0312 38 38 36.0312 38 33.625V7.375C38 4.96875 36.0312 3 33.625 3ZM31.4375 27.0625H28.1562V13.9375H31.4375V27.0625ZM19.4062 27.0625H16.125V19.4062H19.4062V27.0625ZM20.5 13.9375C23.5625 13.9375 25.9688 16.3438 25.9688 19.4062V27.0625H22.6875V19.4062C22.6875 18.2031 21.7031 17.2188 20.5 17.2188H12.8438V27.0625H9.5625V13.9375H20.5Z"
        fill="#FF4A00"
      />
    </svg>
  );
}
