import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from './useDynamicSplitComponent';
import OldDesignSignInProfileButton from './OldDesign/SignInProfileButton';
import NewDesignSignInProfileButton from './NewDesign/SignInProfileButton';

type ISignInProfileButtonProps = {
  splitGroupId: number;
  authenticated: boolean;
};

export function SignInProfileButtonContainer<
  P extends ISignInProfileButtonProps,
>(props: P) {
  let { splitGroupId } = props;

  let SignInProfileButtonComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignSignInProfileButton,
      [DesignSplitGroupId.B]: NewDesignSignInProfileButton,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return (
    <SignInProfileButtonComponent {...(props as P & JSX.IntrinsicAttributes)} />
  );
}
