import Button from '@front_common/Components/Button/Button';
import Flag from '@front_common/Components/Flag/Flag';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import styled from 'styled-components';
import ChevronRightIcon from './Icons/ChevronRightIcon';
import EarthImage from './Images/Earth.png';
import Card from '@front_common/Components/Card';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { getESimCountryUrlByCountryCode } from '../CountryStore/OldDesign/CountryStoreUtils';

export default function PopularCountries() {
  let countries = POPULAR_COUNTRIES.filter((_country, index) => index < 6);

  return (
    <PopularCountriesWrapper>
      <div>
        <Title>
          Get the best eSIM plans now and stay online across 195+ countries
          <img src={EarthImage} alt="Earth" />
        </Title>
        <Subtitle>
          Seamless connectivity for less than your daily coffee.
        </Subtitle>
      </div>
      <CountriesList>
        {countries.map((countryCode) => (
          <CountryLink
            key={countryCode}
            style={{ textDecoration: 'none' }}
            // eslint-disable-next-line secrets/no-secrets
            href={`${getESimCountryUrlByCountryCode(countryCode)}?utm_source=landing_page&utm_content=how_to_install_esim`}
          >
            <CountryCard>
              <FlagWrapper>
                <Flag withBorder countryCode={countryCode} />
              </FlagWrapper>
              <CountryName>{countryNameByCode(countryCode)}</CountryName>
              <ChevronRightIcon />
            </CountryCard>
          </CountryLink>
        ))}
      </CountriesList>
      <CTAButtonLink
        style={{ textDecoration: 'none' }}
        // eslint-disable-next-line secrets/no-secrets
        href={`${WEB_ROUTE_HOME}?all_countries=true&utm_source=landing_page&utm_content=how_to_install_esim`}
      >
        <Button sizeParam="L">Show all countries</Button>
      </CTAButtonLink>
    </PopularCountriesWrapper>
  );
}

let PopularCountriesWrapper = styled.div`
  background-color: var(--lightBlue80);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    padding: 40px;
  }

  @media (min-width: 960px) {
    gap: 28px;
  }
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;
  margin-bottom: 8px;

  img {
    width: 32px;
    margin-bottom: -7px;
    margin-left: 8px;
  }

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;

    img {
      margin-bottom: -6px;
    }
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;

    img {
      margin-bottom: -5px;
    }
  }
`;

let Subtitle = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
`;

let CountriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

let CountryLink = styled(LinkToRoute)`
  color: var(--textPrimary);
`;

let CountryCard = styled(Card)`
  height: 62px;
  display: flex;
  gap: 12px;
  align-items: center;

  svg {
    margin-left: auto;
    width: 24px;
    min-width: 24px;
  }

  @media (min-width: 700px) {
    height: 70px;
  }
`;

let FlagWrapper = styled.div`
  img {
    height: 38px;
  }
`;

let CountryName = styled.div`
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  text-align: left;

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

let CTAButtonLink = styled(LinkToRoute)`
  button {
    width: 100%;
  }

  @media (min-width: 960px) {
    button {
      width: 236px;
      margin: 0 auto;
    }
  }
`;
