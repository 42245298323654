import styled from 'styled-components';
import {
  WEB_ROUTE_ACCEPTABLE_USE_POLICY,
  WEB_ROUTE_PRIVACY_POLICY,
  WEB_ROUTE_REFUND_POLICY,
  WEB_ROUTE_SUBSCRIPTION_POLICY,
  WEB_ROUTE_TERMS_AND_CONDITIONS,
} from '@shared_frontend/Common/WebRoutes';

export default function Legal({ text }: { text: string }) {
  return (
    <LegalStyled>
      {text}{' '}
      <span>
        <a href={WEB_ROUTE_TERMS_AND_CONDITIONS} rel="noindex, nofollow">
          Terms & Conditions,
        </a>{' '}
        <a href={`${WEB_ROUTE_PRIVACY_POLICY}/#`} rel="noindex, nofollow">
          Privacy Policy,
        </a>{' '}
        <a href={WEB_ROUTE_REFUND_POLICY} rel="noindex, nofollow">
          Refund Policy,
        </a>{' '}
        <a href={WEB_ROUTE_ACCEPTABLE_USE_POLICY} rel="noindex, nofollow">
          Acceptable Use Policy.
        </a>
        <a href={WEB_ROUTE_SUBSCRIPTION_POLICY} rel="noindex, nofollow">
          Subscription Policy.
        </a>
      </span>
    </LegalStyled>
  );
}

let LegalStyled = styled.span`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-top: 4px;
  color: var(--textPrimaryNew);
  font-family: var(--fontFamilyNew);

  a {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--textPrimaryNew);
  }
`;
