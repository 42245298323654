import Card from '@front_common/Components/Card';
import styled from 'styled-components';
import TroubleshootIllustration from './Images/TroubleshootIllustration.png';
import Button from '@front_common/Components/Button/Button';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_FAQ } from '@shared_frontend/Common/WebRoutes';

export default function Troubleshoot() {
  return (
    <StyledCard>
      <Top>
        <img src={TroubleshootIllustration} alt="troubleshoot illustration" />
      </Top>
      <Bottom>
        <Title>Having trouble with your eSIM?</Title>
        <Text>Visit our Troubleshooting FAQ for quick solutions.</Text>
        <LinkToRoute
          style={{ textDecoration: 'none' }}
          href={`${WEB_ROUTE_FAQ}/troubleshooting/?utm_source=installation_guide`}
        >
          <CTAButton sizeParam="L">Troubleshoot now</CTAButton>
        </LinkToRoute>
      </Bottom>
    </StyledCard>
  );
}

let StyledCard = styled(Card)`
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

let Top = styled.div`
  background-color: var(--green90);
  padding: 20px 16px;
  height: 240px;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 329px;
  }

  @media (min-width: 960px) {
    order: 2;
    flex: 1;
  }
`;

let Bottom = styled.div`
  padding: 20px;

  @media (min-width: 960px) {
    order: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  margin-bottom: 4px;

  @media (min-width: 960px) {
    margin-bottom: 8px;
  }
`;

let Text = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  margin-bottom: 12px;

  @media (min-width: 960px) {
    margin-bottom: 16px;
  }
`;

let CTAButton = styled(Button)`
  width: 100%;

  @media (min-width: 960px) {
    width: 236px;
  }
`;
