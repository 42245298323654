import { JSX } from 'preact/jsx-runtime';
import { useDynamicSplitComponent } from '../Layout/useDynamicSplitComponent';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import OldDesignCountryStorePage from './OldDesign/CountryStorePage';
import NewDesignCountryStorePage from './NewDesign/CountryStorePage';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';

type ICountryStorePageProps = {
  countrySlug: string;
};

export function CountryStorePageContainer<P extends ICountryStorePageProps>(
  props: P,
) {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  if (!splitGroupId) {
    return <LoadingAnimation />;
  }

  let CountryStorePageComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignCountryStorePage,
      [DesignSplitGroupId.B]: NewDesignCountryStorePage,
    },
    identifier: DesignSplitGroupId.A,
  });

  return (
    <CountryStorePageComponent {...(props as P & JSX.IntrinsicAttributes)} />
  );
}
