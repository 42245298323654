import phoneHandImage from '@front_common/Components/DownloadApp/OldDesign/Images/PhoneHand.png';
import appleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/AppleDownload.png';
import googleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/GoogleDownload.png';
import qRImage from '@front_common/Components/DownloadApp/Images/QR.png';
import phoneFrameImage from '@front_common/Components/DownloadApp/OldDesign/Images/PhoneFrame.png';
import styled from 'styled-components';
import {
  APP_DOWNLOAD_APPLE,
  APP_DOWNLOAD_GOOGLE,
} from '@shared_frontend/Module/Layout/FooterConstants';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';

function handleDownloadClick(value: 'Apple' | 'Google') {
  Api.trackAction(ActionTypes.button_app, value);
}

export default function DownloadApp() {
  return (
    <DownloadAppWrapper>
      <MainContent>
        <Illustration>
          <img src={phoneHandImage} alt="phone hand" />
        </Illustration>
        <TitleDownload>
          <Title>Get the Ohayu app</Title>
          <TitleDesktop>Download the Ohayu app</TitleDesktop>
          <DownloadLinks>
            <a
              href={APP_DOWNLOAD_APPLE}
              onClick={() => handleDownloadClick('Apple')}
              target="_blank"
              rel="noreferrer"
            >
              <img src={appleDownloadImage} alt="apple download" />
            </a>
            <a
              href={APP_DOWNLOAD_GOOGLE}
              onClick={() => handleDownloadClick('Google')}
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleDownloadImage} alt="google download" />
            </a>
          </DownloadLinks>
          <AditionalText>
            Or scan the code with your phone to download Ohayu.
          </AditionalText>
        </TitleDownload>
      </MainContent>
      <QRContent>
        <PhoneFrameImage src={phoneFrameImage} alt="phone frame" />
        <QRImage src={qRImage} alt="qr" />
      </QRContent>
    </DownloadAppWrapper>
  );
}

let DownloadAppWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;

  @media (min-width: 700px) {
    border: var(--borderOutlinePrimary);
    display: flex;
    justify-content: space-between;
    min-height: 284px;
  }

  @media (min-width: 960px) {
    min-height: 310px;
  }
`;

let MainContent = styled.div`
  padding: 20px;
  display: flex;
  gap: 12px;
  background-color: var(--cardsBackgroundGreenLight);

  @media (min-width: 700px) {
    background-color: var(--backgroundSecondary);
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  @media (min-width: 960px) {
    padding: 32px;
    gap: 24px;
  }
`;

let Illustration = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 58px;
    height: 70px;
  }
`;

let TitleDownload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 700px) {
    gap: 16px;
  }

  @media (min-width: 960px) {
    gap: 24px;
  }
`;

let Title = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;

  @media (min-width: 700px) {
    display: none;
  }
`;

let TitleDesktop = styled.h2`
  display: none;

  @media (min-width: 700px) {
    display: block;
    font-family: var(--fontFamilyHeader);
    font-size: 28px;
    font-weight: var(--fontWeightBold);
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

let DownloadLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  a {
    display: flex;

    img {
      width: 120px;
      height: 40px;
    }
  }

  @media (min-width: 393px) {
    flex-direction: row;
  }
`;

let AditionalText = styled.p`
  display: none;

  @media (min-width: 700px) {
    display: block;
    font-size: var(--fontSizeBig);
    line-height: 24px;
  }
`;

let QRContent = styled.div`
  display: none;
  position: relative;

  @media (min-width: 700px) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--cardsBackgroundGreenLight);
    width: 255px;
  }

  @media (min-width: 960px) {
    width: 522px;
  }
`;

let QRImage = styled.img`
  @media (min-width: 700px) {
    width: 125px;
    height: auto;
    position: absolute;
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  @media (min-width: 960px) {
    bottom: 32px;
    width: 185px;
  }
`;

let PhoneFrameImage = styled.img`
  @media (min-width: 700px) {
    width: 169px;
    height: auto;
  }

  @media (min-width: 960px) {
    width: 234px;
  }
`;
