import styled from 'styled-components';
import Button from '@front_common/Components/Button/Button';
import { useEffect, useRef, useState } from 'preact/hooks';
import OTPInput from '../../OTPInput/OldDesign/OTPInput';
import TimerButton from '../../TimerButton/OldDesign/TimerButton';

type AuthFormOTPProps = {
  email: string;
  isLoading: boolean;
  errorTextOTP: string;
  onSubmit: (event: SubmitEvent) => void;
  onResendOTP: () => void;
  onChangeEmail: () => void;
};

export default function AuthFormOTP(props: AuthFormOTPProps) {
  let { email, isLoading, onSubmit, onResendOTP, errorTextOTP, onChangeEmail } =
    props;

  let [otp, setOtp] = useState('');
  let [randomKey, setRandomKey] = useState(0);

  let inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  function handleValueChange(value: string) {
    setOtp(value);
  }

  function isOTPValid() {
    return otp && otp.length === 6;
  }

  function handleTimerButtonClick() {
    setRandomKey(Date.now());
    onResendOTP();
  }

  return (
    <AuthFormOTPWrapperStyled>
      <EmailChange>
        <EmailInfo>
          <span>We have sent the code to your email</span>
          <a>{email}</a>
        </EmailInfo>
      </EmailChange>
      <ChangeEmailButton
        disabled={isLoading}
        typeStyle="Secondary"
        sizeParam="S"
        onClick={onChangeEmail}
      >
        Change
      </ChangeEmailButton>
      <SpamInfo>Remember to check your Spam folder.</SpamInfo>
      <FormStyled noValidate onSubmit={onSubmit}>
        <OTPInput
          name="otp"
          placeholder="Enter 6-digit code here"
          errorText={errorTextOTP}
          disabled={isLoading}
          ref={inputRef}
          onValueChange={handleValueChange}
        />
        <ContinueButton
          type="submit"
          sizeParam="L"
          isLoading={isLoading}
          disabled={isLoading || !isOTPValid()}
        >
          Continue
        </ContinueButton>
        <TimerButton
          key={randomKey}
          timeSeconds={60}
          activeName="Resend"
          timerName="Resend code in "
          onClick={handleTimerButtonClick}
          disabled={isLoading}
        />
      </FormStyled>
    </AuthFormOTPWrapperStyled>
  );
}

let AuthFormOTPWrapperStyled = styled.div`
  order: 0;
`;

let EmailChange = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

let EmailInfo = styled.div`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-weight: var(--fontWeightBold);
    text-decoration: none;
    pointer-events: none;
    color: inherit;
  }
`;

let ChangeEmailButton = styled(Button)`
  margin-bottom: 8px;
`;

let SpamInfo = styled.span`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  text-align: left;
`;

let FormStyled = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let ContinueButton = styled(Button)``;
