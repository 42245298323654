import { useEffect, useRef, useState } from 'preact/hooks';
import { SubscriptionStatus } from '@shared_backend/Module/Subscription/MySubscriptionResponse';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import Toggle from '@front_common/Components/Toggle';
import DownloadApp from '@front_common/Components/DownloadApp/OldDesign/DownloadApp';
import Button from '@front_common/Components/Button/Button';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import AuthService from '@front_common/Api/AuthService';
import UserApi from '@front_common/Api/UserApi';
import { LinkToRoute } from '@front_common/Router/Router';
import { isProdEnv } from '@front_common/Config';
import DetailsModalContent from '@front_common/Components/DetailsModalContent/DetailsModalContent';
import MonthlyPlans from './MonthlyPlans';
import YearlyPlans from './YearlyPlans';
import UnlockTable from './UnlockTable';
import HowItWorks from './HowItWorks';
import ChevronLeftIcon from './Icons/ChevronLeftIcon';
import worldImage from './Images/World.png';
import FAQ from './FAQ';
import GlobeIcon from './Icons/GlobeIcon';
import { useSubscriptionProducts } from '@front_common/Api/VisitorApi';
import ExistedSubscriptionModalContent from './EsixtedSubscriptionModalContent';
import SuccessModal from './SuccessModal/SuccessModal';
import styled from 'styled-components';
import { PaymentProvider } from './Checkout/PaymentContext';
import CheckoutModal from './Checkout/CheckoutModal/CheckoutModal';
import CheckoutModalContent from './Checkout/CheckoutModal/CheckoutModalContent';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

function handleCTAClickDesktop() {
  window.scrollTo({ top: 200, behavior: 'smooth' });
  Api.trackAction(ActionTypes.button_choose_global_plan, null);
}

function onPlanDetailsTabChange(index: number) {
  let tabName =
    index === 0 ? 'global-plan-details' : 'global-covered-countries';
  Api.trackAction(ActionTypes.modal_open, tabName);
}

// eslint-disable-next-line max-lines-per-function
export default function StoreGlobalPage() {
  let authenticated = AuthService.isLoggedIn();

  let [selectedPeriod, setSelectedPeriod] = useState<'month' | 'year'>('month');
  let [selectedPlanId, setSelectedPlan] = useState<number | null>(null);
  let [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  let [isSubscriptionExist, setIsSubscriptionExist] = useState(false);
  let [isExistedSubscriptionModalOpen, setIsExistedSubscriptionModalOpen] =
    useState(false);
  let [preOpenedIndexTab, setPreOpenedIndexTab] = useState(0);
  let [showCTABlockDesktop, setShowCTABlockDesktop] = useState(false);

  let [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  let [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] =
    useState(false);
  let [successPaymentSessionId, setSuccessPaymentSessionId] = useState<
    number | null
  >(null);

  //TODO: after merge think to move this request to MonthlyPlans and YearlyPlans, because we should keep in mind they may be very different products
  let [plans, _error, isLoading] = useSubscriptionProducts();

  let filteredPlans = plans?.filter((plan) =>
    isProdEnv ? plan.priceCents >= 100 : true,
  );

  let plansRef = useRef<HTMLDivElement>(null);
  let monthlyPlans = filteredPlans?.filter((plan) => plan.billingPeriod === 1);
  let yearlyPlans = filteredPlans?.filter((plan) => plan.billingPeriod === 2);
  let selectedPlan = filteredPlans?.find((plan) => plan.id === selectedPlanId);

  useEffect(() => {
    let capitalizedPeriod =
      selectedPeriod.charAt(0).toUpperCase() + selectedPeriod.slice(1);
    Api.trackAction(ActionTypes.global_plans, `${capitalizedPeriod}ly`);
  }, [selectedPeriod]);

  useEffect(() => {
    // TODO: move from IntersectionObserver
    // eslint-disable-next-line compat/compat
    let observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) {
          setShowCTABlockDesktop(!entry.isIntersecting);
        }
      },
      { threshold: 0 },
    );

    if (plansRef.current) {
      observer.observe(plansRef.current);
    }

    return () => {
      if (plansRef.current) {
        observer.unobserve(plansRef.current);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let subscriptions = await UserApi.getMySubscriptions();

        let hasActiveSubscription = subscriptions.some(
          (eSim) => eSim.status === SubscriptionStatus.ACTIVE,
        );

        setIsSubscriptionExist(hasActiveSubscription);
      }
    })();
  }, [authenticated]);

  function handlePeriodToggle(position: 'left' | 'right') {
    if (position === 'left') {
      setSelectedPeriod('month');
    } else {
      setSelectedPeriod('year');
    }
  }

  function onPlanSelect(id: number) {
    setSelectedPlan(id);
  }

  function handleCTAClick(planId?: number) {
    let currentPlanId = selectedPlanId;

    if (planId) {
      currentPlanId = planId;
      onPlanSelect(planId);
    }

    Api.trackAction(ActionTypes.button_global_go_to_checkout, currentPlanId);

    if (isSubscriptionExist) {
      setIsExistedSubscriptionModalOpen(true);
      return;
    }

    if (!currentPlanId) {
      logger.error('404: Subscription plan ID is not defined!');
      throw new Error('404: Subscription plan ID is not defined!');
    }

    setIsDetailsModalOpen(false);
    setIsPaymentModalOpen(true);
  }

  function closePlanDetailsModal() {
    setIsDetailsModalOpen(false);
  }

  function onPlanDetailsClick(id: number) {
    setIsDetailsModalOpen(true);
    setPreOpenedIndexTab(0);
    setSelectedPlan(id);
  }

  function onCountriesClick(id: number) {
    setIsDetailsModalOpen(true);
    setPreOpenedIndexTab(1);
    setSelectedPlan(id);
  }

  function onPaymentSuccess(paymentId: number) {
    setIsPaymentSuccessModalOpen(true);
    setIsPaymentModalOpen(false);
    setSuccessPaymentSessionId(paymentId);
  }

  function onExistSubscription() {
    setIsPaymentModalOpen(false);
    setIsExistedSubscriptionModalOpen(true);
  }

  return (
    <StoreGlobalPageWrapper>
      <PaddingWrapper>
        <LittleBlock>
          <div>
            <BackButtonContainer>
              <LinkToRoute href={WEB_ROUTE_HOME}>
                <BackButton>
                  <ChevronLeftIcon />
                </BackButton>
              </LinkToRoute>
            </BackButtonContainer>
            <TitleLogoWrapper>
              <img src={worldImage} alt="world" />
            </TitleLogoWrapper>
            <Title>Global eSIM plans</Title>
          </div>
          <div>
            <ToggleWrapper>
              <Toggle
                leftButtonTitle="Monthly"
                rightButtonTitle="Yearly • Save 20%"
                initial="left"
                onChange={handlePeriodToggle}
              />
            </ToggleWrapper>
          </div>
          <div ref={plansRef}>
            {selectedPeriod === 'month' ? (
              <MonthlyPlans
                plans={monthlyPlans}
                onPlanSelect={onPlanSelect}
                onCountriesClick={onCountriesClick}
                onPlanDetailsClick={onPlanDetailsClick}
                onGoToCheckoutClick={handleCTAClick}
              />
            ) : (
              <YearlyPlans
                plans={yearlyPlans}
                monthlyPlans={monthlyPlans}
                onPlanSelect={onPlanSelect}
                onCountriesClick={onCountriesClick}
                onPlanDetailsClick={onPlanDetailsClick}
                onGoToCheckoutClick={handleCTAClick}
              />
            )}
          </div>
        </LittleBlock>
      </PaddingWrapper>
      <PaddingWrapper>
        <LittleBlock>
          <UnlockTable />
        </LittleBlock>
      </PaddingWrapper>
      <div>
        <LittleBlock>
          <HowItWorks />
        </LittleBlock>
      </div>
      <PaddingWrapper>
        <LittleBlock>
          <FAQ />
        </LittleBlock>
      </PaddingWrapper>
      <PaddingWrapper>
        <DownloadApp />
      </PaddingWrapper>
      {!isDetailsModalOpen && (
        <CTABlock>
          <Button
            disabled={isLoading}
            sizeParam="L"
            onClick={() => handleCTAClick()}
          >
            Go to checkout
          </Button>
        </CTABlock>
      )}
      {showCTABlockDesktop && (
        <CTABlockDesktop>
          <Button sizeParam="L" onClick={() => handleCTAClickDesktop()}>
            Choose a global plan
            <GlobeIcon />
          </Button>
        </CTABlockDesktop>
      )}
      <Modal
        analyticType={
          preOpenedIndexTab === 0
            ? 'global-plan-details'
            : 'global-covered-countries'
        }
        isOpen={isDetailsModalOpen}
        onClose={closePlanDetailsModal}
      >
        <DetailsModalContent
          preOpenedIndexTab={preOpenedIndexTab}
          footerBlock={
            <ModalCTABlock>
              <Button sizeParam="L" onClick={() => handleCTAClick()}>
                Go to checkout
              </Button>
            </ModalCTABlock>
          }
          onTabChange={onPlanDetailsTabChange}
        />
      </Modal>

      {selectedPlan && (
        <CheckoutModal
          isOpen={isPaymentModalOpen}
          header="Billing details"
          onClose={() => setIsPaymentModalOpen(false)}
        >
          <PaymentProvider subscription={selectedPlan}>
            <CheckoutModalContent
              onPaymentSuccess={onPaymentSuccess}
              onExistSubscription={onExistSubscription}
            />
          </PaymentProvider>
        </CheckoutModal>
      )}

      {successPaymentSessionId && (
        <SuccessModal
          show={isPaymentSuccessModalOpen}
          paymentSessionId={successPaymentSessionId}
        />
      )}

      {isSubscriptionExist && (
        <Modal
          analyticType="subscription-exist"
          isOpen={isExistedSubscriptionModalOpen}
          onClose={() => setIsExistedSubscriptionModalOpen(false)}
          overlayClick={false}
          closeButtonPrimary={false}
        >
          <ExistedSubscriptionModalContent />
        </Modal>
      )}
    </StoreGlobalPageWrapper>
  );
}

let StoreGlobalPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 700px) {
    gap: 40px;
  }

  @media (min-width: 960px) {
    gap: 56px;
  }
`;

let PaddingWrapper = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
  }
`;

let BackButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    color: var(--iconsPrimaryInvert);
  }
`;

let TitleLogoWrapper = styled.div`
  margin-bottom: 4px;
  text-align: center;

  img {
    width: 48px;
    height: 48px;
  }

  @media (min-width: 960px) {
    img {
      width: 64px;
      height: 64px;
    }
  }
`;

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-size: 34px;
  font-weight: var(--fontWeightBold);
  line-height: 40px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 36px;
    line-height: 48px;
  }

  @media (min-width: 960px) {
    font-size: 52px;
    line-height: 64px;
  }
`;

let ToggleWrapper = styled.div`
  @media (min-width: 700px) {
    margin: 0 auto;
    width: 414px;
  }
`;

let LittleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    gap: 32px;
  }
`;

let CTABlock = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--backgroundSecondary);
  border-top: 1px solid var(--strokeSecondary);
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  button {
    width: 100%;
  }

  @media (min-width: 700px) {
    button {
      width: 236px;
    }
  }

  @media (min-width: 960px) {
    display: none;
  }
`;

let CTABlockDesktop = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: block;
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--backgroundSecondary);
    border-top: 1px solid var(--strokeSecondary);
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 236px;
    }
  }
`;

let ModalCTABlock = styled.div`
  background-color: var(--backgroundSecondary);
  border-top: 1px solid var(--strokeSecondary);
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  button {
    width: 100%;
  }
`;
