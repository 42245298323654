export function fillRouteParams(
  route: string,
  params: { [key: string]: string | number },
): string {
  let result = route;
  for (let key in params) {
    if (params[key] === undefined) {
      continue;
    }
    if (!route.includes(':' + key)) {
      throw new Error(`Route ${route} dont have passed parameter ${key}`);
    }
    let value = params[key];
    if (value === undefined) {
      continue;
    }
    result = result.replaceAll(':' + key, encodeURIComponent(value));
  }
  return result;
}
