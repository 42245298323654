import { useEffect, useState } from 'preact/hooks';
import AuthService from '@front_common/Api/AuthService';
import PaymentSecured from '../../../Checkout/PaymentSecured/OldDesign/PaymentSecured';
import SubscriptionAccessControl from './SubscriptionAccessControl';
import { usePaymentContext } from '../PaymentContext';
import PaymentError from './PaymentError';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
// import DiscountDropdown from '../../../Payment/TestCParts_ForFutureUse/DiscountDropdown/DiscountDropdown';
// import { Discount } from './Discount/Discount';
import { TotalPriceToPay } from './TotalPriceToPay';
import PaymentForm from './PaymentForm';
import PaymentBlurred from '../PaymentBlured';
import styled from 'styled-components';

const EXISTED_SUBSCRIPTION_ERROR_MESSAGE =
  'Only one subscription per user allowed';

type IProps = {
  onPaymentSuccess: (paymentId: number) => void;
  onExistSubscription: () => void;
};

export default function CheckoutModalContent({
  onPaymentSuccess,
  onExistSubscription,
}: IProps) {
  let {
    subscription,
    paymentSession,
    setPaymentSession,
    paymentSessionError,
    setPaymentSessionError,
    setPaymentError,
    paymentError,
    // resetPaymentSession,
    amountCents: {
      sessionAmountCents,
      // subscriptionAmountCents
    },
  } = usePaymentContext();

  let [authenticated, setAuthenticated] = useState(AuthService.isLoggedIn());

  if (!subscription) {
    return <LoadingAnimation />;
  }

  useEffect(() => {
    if (paymentSessionError === EXISTED_SUBSCRIPTION_ERROR_MESSAGE) {
      onExistSubscription();
    }
  }, [paymentSessionError]);

  function onAuthSuccess() {
    setAuthenticated(true);
  }

  return (
    <PaymentModalContent>
      <ContentWrapper>
        {paymentError && <PaymentError />}
        {/* TODO: subscriptionPeriod - better to pass the original value and the
        SubscriptionAccessControl should handle the conversion. May be disscussed */}
        <SubscriptionAccessControl
          authenticated={authenticated}
          subscriptionPeriod={
            subscription.billingPeriod === 1 ? 'month' : 'year'
          }
          subscription={subscription}
          onAuthSuccess={onAuthSuccess}
        />
        <PaymentOverviewWrapper>
          {/*{authenticated && (*/}
          {/*  <DiscountDropdown>*/}
          {/*    <Discount*/}
          {/*      paymentError={paymentError}*/}
          {/*      sessionTotalAmountCents={sessionAmountCents}*/}
          {/*      subscriptionTotalAmountCents={subscriptionAmountCents}*/}
          {/*      onDiscountRequestSuccess={setPaymentSession}*/}
          {/*      onDiscountRemoveClick={resetPaymentSession}*/}
          {/*    ></Discount>*/}
          {/*  </DiscountDropdown>*/}
          {/*)}*/}

          <TotalPriceToPay
            showPaymentMessage={authenticated}
            sessionAmountCents={sessionAmountCents}
          />

          {authenticated ? (
            <>
              <PaymentForm
                paymentSubId={subscription.id}
                paymentSession={paymentSession}
                onSessionCreate={setPaymentSession}
                onSessionError={setPaymentSessionError}
                onPaymentError={setPaymentError}
                onPaymentSuccess={onPaymentSuccess}
                billingPeriod={
                  subscription.billingPeriod === 1 ? 'month' : 'year'
                }
              />
            </>
          ) : (
            <PaymentBlurred />
          )}
        </PaymentOverviewWrapper>
        <PaymentSecuredWrapper>
          <PaymentSecured />
        </PaymentSecuredWrapper>
      </ContentWrapper>
    </PaymentModalContent>
  );
}

let PaymentModalContent = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

let ContentWrapper = styled.div`
  background-color: var(--backgroundPrimary);
`;

let PaymentOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

let PaymentSecuredWrapper = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--strokeSecondary);
  background-color: var(--backgroundSecondary);
`;
