import styled from 'styled-components';
import LogoPictureOhayu from '@shared_frontend/Module/Layout/Logo_ohayu_dark.png';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { HELP_LIST } from '@shared_frontend/Module/Layout/FooterConstants';
import { CONFIG_MIRROR_NAME } from '@front_common/Config';
import AuthService from '@front_common/Api/AuthService';
import { LinkToRoute } from '@front_common/Router/Router';
import MenuSelect, { DropdownItem } from './MenuSelect';
import SignInProfileButton from './SignInProfileButton';
import MobileSupportChatButton from '@front_common/ZendeskWidget/OldDesign/MobileSupportChatButton';
import {
  HEADER_CLASS_NAME,
  PROFILE_BUTTON_HEADER_CLASS_NAME,
} from '../../Checkout/useDisplayingHeaderFooterElements';

type IProps = {
  onlyLogo?: boolean;
  hideSignInProfileButton: boolean;
  hideSupportButton: boolean;
};

export const HEADER_HEIGHT = '72px';

export default function Header({
  onlyLogo,
  hideSignInProfileButton,
  hideSupportButton,
}: IProps) {
  let authenticated = AuthService.isLoggedIn();

  return (
    <HeaderWrapper>
      <HeaderContent>
        <Logo href={WEB_ROUTE_HOME}>
          <LogoImage
            src={LogoPictureOhayu}
            alt={CONFIG_MIRROR_NAME.toLowerCase()}
          />
        </Logo>

        {onlyLogo ? null : (
          <DesktopNavigation className={HEADER_CLASS_NAME}>
            {!hideSupportButton && <MobileSupportChatButton />}
            <MenuLink href={WEB_ROUTE_HOME}>Country plans</MenuLink>
            <MenuLink href={`${WEB_ROUTE_STORE_GLOBAL}/`}>
              Global plans
            </MenuLink>
            <MenuSelect placeholder="Help">
              {HELP_LIST.map((option) => (
                <DropdownItem key={option.label}>
                  <LinkToRoute href={`${option.href}/`}>
                    {option.label}
                  </LinkToRoute>
                </DropdownItem>
              ))}
            </MenuSelect>
            {authenticated && (
              <MenuLink href={WEB_ROUTE_MY_ESIMS}>My eSIMs</MenuLink>
            )}
            {!hideSignInProfileButton && (
              <div className={PROFILE_BUTTON_HEADER_CLASS_NAME}>
                <SignInProfileButton authenticated={authenticated} />
              </div>
            )}
          </DesktopNavigation>
        )}
      </HeaderContent>
    </HeaderWrapper>
  );
}

let HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background: var(--backgroundSecondary);
  box-shadow:
    0px 2px 5px 0px rgba(219, 219, 219, 0.1),
    0px 9px 9px 0px rgba(219, 219, 219, 0.09),
    0px 20px 12px 0px rgba(219, 219, 219, 0.05),
    0px 36px 14px 0px rgba(219, 219, 219, 0.01),
    0px 56px 16px 0px rgba(219, 219, 219, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    box-shadow:
      0px 2px 5px 0px rgba(219, 219, 219, 0.1),
      0px 9px 9px 0px rgba(219, 219, 219, 0.09),
      0px 20px 12px 0px rgba(219, 219, 219, 0.05),
      0px 36px 14px 0px rgba(219, 219, 219, 0.01),
      0px 56px 16px 0px rgba(219, 219, 219, 0);
  }
`;

let HeaderContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 960px) {
    max-width: 1280px;
  }
`;

let Logo = styled(LinkToRoute)`
  height: 32px;
  display: block;
`;

let LogoImage = styled.img`
  height: 100%;
  display: block;
`;

let DesktopNavigation = styled.nav`
  display: none;

  @media (min-width: 960px) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

let MenuLink = styled(LinkToRoute)`
  height: 36px;
  padding: 0 16px;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  color: var(--textPrimary);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  &:hover {
    color: var(--textHover);
  }
`;
