import { useState } from 'preact/hooks';
import QRIcon from './Icons/QRIcon';
import GearIcon from './Icons/GearIcon';
import styled from 'styled-components';
import OsToggle from './OsToggle';
import { AppleQRTabContent } from './AppleTabContent/AppleQRTabContent';
import { AppleManualTabContent } from './AppleTabContent/AppleManualTabContent';
import { AndroidQRTabContent } from './AndroidTabContent/AndroidQRTabContent';
import { AndroidManualTabContent } from './AndroidTabContent/AndroidManualTabContent';
import { Tab, Tabs } from '@front_common/Components/Tabs';

type InstallGuideBodyProps = {
  esimId?: string;
  forGeneralPage?: boolean;
};

export default function InstallGuideBody(props: InstallGuideBodyProps) {
  let { esimId, forGeneralPage = false } = props;

  let [isApple, setIsApple] = useState(true);

  let appleTabs: Tab[] = [
    {
      label: 'QR-code',
      icon: <QRIcon />,
      content: (
        <AppleQRTabContent esimId={esimId} forGeneralPage={forGeneralPage} />
      ),
    },
    {
      label: 'Manual',
      icon: <GearIcon />,
      content: (
        <AppleManualTabContent
          esimId={esimId}
          forGeneralPage={forGeneralPage}
        />
      ),
    },
  ];

  let androidTabs: Tab[] = [
    {
      label: 'QR-code',
      icon: <QRIcon />,
      content: (
        <AndroidQRTabContent esimId={esimId} forGeneralPage={forGeneralPage} />
      ),
    },
    {
      label: 'Manual',
      icon: <GearIcon />,
      content: (
        <AndroidManualTabContent
          esimId={esimId}
          forGeneralPage={forGeneralPage}
        />
      ),
    },
  ];

  function handleToggleChange(leftSelected: boolean) {
    setIsApple(leftSelected);
  }

  return (
    <>
      <PaddingWrapper>
        <Title
          data-testid="installation-guide-page-title"
          $forGeneralPage={forGeneralPage}
        >
          Installation
        </Title>
        <ToggleWrapper>
          <OsToggle onChange={handleToggleChange} />
        </ToggleWrapper>
      </PaddingWrapper>
      <Tabs tabs={isApple ? appleTabs : androidTabs} />
    </>
  );
}

let PaddingWrapper = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin: 0 auto;
  }
`;

let Title = styled.h2<{ $forGeneralPage: boolean }>`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: ${(props) => (props.$forGeneralPage ? 'center' : 'left')};
  margin-bottom: 20px;
  font-size: ${(props) => (props.$forGeneralPage ? '24px' : '34px')};
  line-height: ${(props) => (props.$forGeneralPage ? '32px' : '40px')};

  @media (min-width: 700px) {
    text-align: center;
    font-size: ${(props) => (props.$forGeneralPage ? '28px' : '34px')};
    line-height: ${(props) => (props.$forGeneralPage ? '38px' : '40px')};
  }

  @media (min-width: 960px) {
    text-align: center;
    font-size: 34px;
    line-height: 40px;
  }
`;

let ToggleWrapper = styled.div`
  margin-bottom: 20px;

  @media (min-width: 700px) {
    width: 324px;
    margin-left: auto;
    margin-right: auto;
  }
`;
