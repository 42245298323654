export default function LocationSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116055)">
        <path
          d="M22.922 1.69976C22.5852 1.41749 22.1903 1.21305 21.7654 1.10104C21.3405 0.989022 20.8961 0.972196 20.464 1.05176L14.284 2.17476C13.3997 2.33421 12.5949 2.7873 12 3.46076C11.4055 2.78619 10.6007 2.33202 9.716 2.17176L3.536 1.04876C3.10377 0.970271 2.65957 0.987667 2.2348 1.09972C1.81004 1.21177 1.41506 1.41574 1.07778 1.69721C0.740505 1.97869 0.469162 2.3308 0.282926 2.72867C0.0966906 3.12654 0.000106745 3.56046 0 3.99976L0 20.8338L12 23.0168L24 20.8338V3.99976C24 3.56078 23.9035 3.12717 23.7172 2.72968C23.5309 2.3322 23.2594 1.98058 22.922 1.69976ZM11 20.7998L2 19.1658V3.99976C2.00012 3.85335 2.0324 3.70875 2.09454 3.57618C2.15668 3.44361 2.24717 3.32631 2.35962 3.23255C2.47208 3.13879 2.60374 3.07087 2.74533 3.03358C2.88691 2.99629 3.03495 2.99055 3.179 3.01676L9.358 4.13976C9.81882 4.2236 10.2356 4.46652 10.5357 4.82617C10.8357 5.18581 11.0001 5.63937 11 6.10776V20.7998ZM22 19.1638L13 20.7998V6.10776C12.9999 5.63937 13.1643 5.18581 13.4643 4.82617C13.7644 4.46652 14.1812 4.2236 14.642 4.13976L20.821 3.01676C20.965 2.99055 21.1131 2.99629 21.2547 3.03358C21.3963 3.07087 21.5279 3.13879 21.6404 3.23255C21.7528 3.32631 21.8433 3.44361 21.9055 3.57618C21.9676 3.70875 21.9999 3.85335 22 3.99976V19.1638Z"
          fill="#21252A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_116055">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
