import styled from 'styled-components';
import GlobalIcon from './Icons/GlobalIcon.svg';
import Flag from './Flag/Flag';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import planetImage from './Images/PlanetImage.png';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';

type EsimPlanNameProps = {
  esim: EsimEntityType;
};

export function EsimEntityPlanName(props: EsimPlanNameProps) {
  let { esim } = props;

  if (!esim.targetCountry) {
    // TODO process "null" better
    logger.log('no target country ' + esim);
    return <>no target country</>;
  }

  return (
    <span>
      {`${esim.dataPurchasedGb}GB in ${getEsimPlanCountry(esim.targetCountry)} `}
      <FlagWrapper>
        <EsimPlanFlag targetCountry={esim.targetCountry} />
      </FlagWrapper>
      {esim.durationDays && ` for ${esim.durationDays} days`}
    </span>
  );
}

type EsimPlanFlagProps = {
  targetCountry: string;
  withBorder?: boolean;
};

export function EsimPlanFlag({
  targetCountry,
  withBorder = false,
}: EsimPlanFlagProps) {
  return (
    <>
      {targetCountry ? (
        <Flag circle withBorder={withBorder} countryCode={targetCountry} />
      ) : (
        <img src={GlobalIcon} alt="global" />
      )}
    </>
  );
}

export function getEsimPlanCountry(targetCountry: string): string {
  try {
    return countryNameByCode(targetCountry);
  } catch {
    return 'Group of countries';
  }
}

export function SubscriptionPlanImage() {
  return <SubscriptionPlanImageStyled src={planetImage} alt="planet" />;
}

export function SubscriptionPlanName({ amountGb }: { amountGb: number }) {
  return <span>Global plan: {amountGb}GB</span>;
}

export function SubscriptionPlanNameWithIcon({
  amountGb,
}: {
  amountGb: number;
}) {
  return (
    <SubscriptionPlanNameWithIconWrapper>
      <SubscriptionPlanName amountGb={amountGb} /> <SubscriptionPlanImage />
    </SubscriptionPlanNameWithIconWrapper>
  );
}

let SubscriptionPlanNameWithIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

let SubscriptionPlanImageStyled = styled.img`
  width: 24px;
  height: 24px;
`;

let FlagWrapper = styled.span`
  display: inline-flex;
  vertical-align: top;
`;
