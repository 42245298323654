import { GuideCard, GuideStep } from '../GuideCard';
import { ActivationCodeBox } from '../ActivationCodeBox';
import styled from 'styled-components';
import Warning from '../Warning';
import Card from '@front_common/Components/Card';

import AndroidManualInstallS1S1 from './Images/Manual/AndroidManualInstallS1S1.png';

import AndroidManualInstallS2S1 from './Images/Manual/AndroidManualInstallS2S1.png';

import AndroidManualInstallS3S1 from './Images/Manual/AndroidManualInstallS3S1.png';
import AndroidManualInstallS3S2 from './Images/Manual/AndroidManualInstallS3S2.png';

import AndroidManualInstallS4S1 from './Images/Manual/AndroidManualInstallS4S1.png';
import AndroidManualInstallS4S2 from './Images/Manual/AndroidManualInstallS4S2.png';
import AndroidManualInstallS4S3 from './Images/Manual/AndroidManualInstallS4S3.png';

import AndroidQRInstallS3S1 from './Images/Qr/AndroidQRInstallS3S1.png';

import AndroidQRActivationS1S1 from './Images/Qr/AndroidQRActivationS1S1.png';
import AndroidQRActivationS1S2 from './Images/Qr/AndroidQRActivationS1S2.png';

import AndroidQRActivationS2S1 from './Images/Qr/AndroidQRActivationS2S1.png';
import AndroidQRActivationS2S2 from './Images/Qr/AndroidQRActivationS2S2.png';

import AndroidQRActivationS3S1 from './Images/Qr/AndroidQRActivationS3S1.png';
import AndroidQRActivationS3S2 from './Images/Qr/AndroidQRActivationS3S2.png';

const ACTIVATION_STEPS: GuideStep[] = [
  {
    stepNumber: 1,
    title: 'Turn on your eSIM',
    mainText: (
      <ul>
        <li>{`Find your installed eSIM in "Mobile Network" list.`}</li>
        <li>{`Click on your eSIM and switch on the toggle to start using eSIM.`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS1S1, AndroidQRActivationS1S2],
  },
  {
    stepNumber: 2,
    title: 'Make eSIM a preferred data plan',
    mainText: (
      <ul>
        <li>{`Go back into "Network&Internet" or "Connections" (depending on your device) tab in your Settings.`}</li>
        <li>{`Then go to "SIM preferences".`}</li>
        <li>{`Switch on the toggle for your installed eSIM as the preferred source for data.`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS2S1, AndroidQRActivationS2S2],
  },
  {
    stepNumber: 3,
    title: 'Enable Data Roaming',
    mainText: (
      <ul>
        <li>{`Go back to the "Network & Internet" or "Connections" (depending on your device) tab in your settings.`}</li>
        <li>{`Tap on "SIM Card Manager" and select your newly added eSIM plan.`}</li>
        <li>{`Turn on "Data Roaming".`}</li>
      </ul>
    ),
    images: [AndroidQRActivationS3S1, AndroidQRActivationS3S2],
  },
];

type AndroidManualTabContentProps = {
  esimId: string | undefined;
  forGeneralPage: boolean;
};

export function AndroidManualTabContent(props: AndroidManualTabContentProps) {
  let { esimId, forGeneralPage } = props;

  let INSTALL_STEPS: GuideStep[] = [
    {
      stepNumber: 1,
      title: 'Navigate to Settings',
      mainText: `In Settings, tap on "Network & Internet" or "Connections" (depending on your device).`,
      images: [AndroidManualInstallS1S1],
    },
    {
      stepNumber: 2,
      title: 'SIM Card Manager/Add Mobile Plan',
      mainText: `Tap on "SIM Card Manager" or "Add Mobile Plan/Network" (depending on your device).`,
      images: [AndroidManualInstallS2S1],
    },
    {
      stepNumber: 3,
      title: 'Add eSIM via manual installation',
      mainText: `Tap on "Add eSIM" and then tap on "Enter Details Manually" to enter the code below.`,

      images: [AndroidManualInstallS3S1, AndroidManualInstallS3S2],
    },
    {
      stepNumber: 4,
      title: 'Enter activation code',
      mainText: forGeneralPage ? (
        <ul>
          <li>{`Enter the code in the corresponding fields and click "Continue" to finish set-up.`}</li>
          <li>{`The activation code can be found in the installation guide, which is provided after your purchase.`}</li>
        </ul>
      ) : (
        `Enter the code provided below and click "Continue" to finish set-up.`
      ),
      images: [
        AndroidManualInstallS4S1,
        AndroidManualInstallS4S2,
        AndroidManualInstallS4S3,
      ],
      additionalBlock: esimId && (
        <ActivationCodeBox forApple={false} esimId={esimId} />
      ),
    },
    {
      stepNumber: 5,
      title: 'Confirm successful installation',
      mainText: `If eSIM is installed successfully, you will see it in your "SIMs" list in "Mobile Network".`,
      images: [AndroidQRInstallS3S1],
    },
  ];

  return (
    <div>
      <PaddingWrapper>
        <Warning text="You need to be connected to the internet to install the eSIM." />
        <MainNote>
          <b>NOTE:</b> Screenshots may differ slightly from what you see on your
          device due to variations between device models.
        </MainNote>
      </PaddingWrapper>
      {INSTALL_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={INSTALL_STEPS.length === index + 1}
        />
      ))}
      <PaddingWrapper>
        <ActivationTitle $forGeneralPage={forGeneralPage}>
          Activation
        </ActivationTitle>
        <Warning text="The following steps should be done once you arrive at your destination, to ensure your eSIM's validity starts correctly." />
      </PaddingWrapper>
      {ACTIVATION_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={ACTIVATION_STEPS.length === index + 1}
        />
      ))}
    </div>
  );
}

let PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin-left: auto;
    margin-right: auto;
  }
`;

let MainNote = styled(Card)`
  padding: 12px;
  font-size: var(--fontSizeSmall);
  line-height: 20px;

  b {
    font-weight: var(--fontWeightBold);
  }
`;

let ActivationTitle = styled.h3<{ $forGeneralPage: boolean }>`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: ${(props) => (props.$forGeneralPage ? 'center' : 'left')};
  margin-top: 32px;
  font-size: ${(props) => (props.$forGeneralPage ? '24px' : '34px')};
  line-height: ${(props) => (props.$forGeneralPage ? '32px' : '40px')};

  @media (min-width: 700px) {
    text-align: center;
    margin-top: 40px;
    font-size: ${(props) => (props.$forGeneralPage ? '28px' : '34px')};
    line-height: ${(props) => (props.$forGeneralPage ? '38px' : '40px')};
  }

  @media (min-width: 960px) {
    margin-top: 56px;
    font-size: 34px;
    line-height: 40px;
  }
`;
