import { useEffect, useState } from 'preact/hooks';
import UserApi from '@front_common/Api/UserApi';
import CheckCircleBrokenIcon from '../../Icons/CheckCircleBroken';
import AuthForm from '../../../Auth/AuthForm/OldDesign/AuthForm';
import styled from 'styled-components';

//TODO: IProps?
type Props = {
  authenticated: boolean;
  onAuthSuccess: () => void;
};

//TODO: sync with the file name
export default function AuthenticationUserInfo({
  authenticated,
  onAuthSuccess,
}: Props) {
  let [email, setEmail] = useState('');
  let [authenticationFormTitle, setAuthenticationFormTitle] = useState('');

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let user = await UserApi.getUser();

        if (user) {
          setEmail(user.email);
        }
      }
    })();
  }, [authenticated]);

  function handleShowOTPChange(showOTP: boolean) {
    if (showOTP) {
      setAuthenticationFormTitle('Enter the verification code');
    } else {
      setAuthenticationFormTitle('');
    }
  }

  return (
    <UserInfoWrapper>
      {authenticated ? (
        <span>
          {email} <CheckCircleBrokenIcon />
        </span>
      ) : (
        <AuthFormWrapper>
          {authenticationFormTitle && (
            <AuthTitle>{authenticationFormTitle}</AuthTitle>
          )}
          <AuthForm
            onAuthSuccess={onAuthSuccess}
            onShowOTPChange={handleShowOTPChange}
          />
        </AuthFormWrapper>
      )}
    </UserInfoWrapper>
  );
}

let UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  span {
    display: inline-flex;
    align-items: center;
  }
`;

let AuthFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 8px;
`;

let AuthTitle = styled.h4`
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;
