import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

type FeedbackCarouselProps<T> = {
  slides: T[];
  getSlideComponent: (data: T) => ReactElement;
  baseClassName: string;
  options?: EmblaOptionsType;
  prevBtnChildren?: React.ReactNode;
  nextBtnChildren?: React.ReactNode;
};

export default function Carousel<T>(props: FeedbackCarouselProps<T>) {
  let {
    slides,
    getSlideComponent,
    baseClassName,
    options,
    prevBtnChildren,
    nextBtnChildren,
  } = props;
  let [emblaRef, emblaApi] = useEmblaCarousel(options);

  let { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  let {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className={baseClassName}>
      <div className={`${baseClassName}__viewport`} ref={emblaRef}>
        <div className={`${baseClassName}__container`}>
          {slides.map((slide, index) => (
            <div className={`${baseClassName}__slide`} key={index}>
              {getSlideComponent(slide)}
            </div>
          ))}
        </div>
      </div>

      <div className={`${baseClassName}__controls`}>
        <div className={`${baseClassName}__buttons`}>
          <PrevButton
            baseClassName={baseClassName}
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          >
            {prevBtnChildren}
          </PrevButton>
          <NextButton
            baseClassName={baseClassName}
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          >
            {nextBtnChildren}
          </NextButton>
        </div>

        <div className={`${baseClassName}__dots`}>
          {scrollSnaps.map((_item, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={`${baseClassName}__dot`.concat(
                index === selectedIndex
                  ? ` ${baseClassName}__dot--selected`
                  : '',
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

function usePrevNextButtons(
  emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType {
  let [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  let [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  let onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
  }, [emblaApi]);

  let onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
  }, [emblaApi]);

  let onSelect = useCallback((emblaApiParam: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApiParam.canScrollPrev());
    setNextBtnDisabled(!emblaApiParam.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
}

type PrevNextButtonProps = PropsWithChildren<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> & { baseClassName: string };

function PrevButton(props: PrevNextButtonProps) {
  let { children, baseClassName, ...restProps } = props;

  return (
    <button
      className={`${baseClassName}__button ${baseClassName}__button--prev`}
      type="button"
      {...restProps}
    >
      {children}
    </button>
  );
}

function NextButton(props: PrevNextButtonProps) {
  let { children, baseClassName, ...restProps } = props;

  return (
    <button
      className={`${baseClassName}__button ${baseClassName}__button--next`}
      type="button"
      {...restProps}
    >
      {children}
    </button>
  );
}

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

function useDotButton(
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonType {
  let [selectedIndex, setSelectedIndex] = useState(0);
  let [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  let onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) {
        return;
      }
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  let onInit = useCallback((emblaApiParam: EmblaCarouselType) => {
    setScrollSnaps(emblaApiParam.scrollSnapList());
  }, []);

  let onSelect = useCallback((emblaApiParam: EmblaCarouselType) => {
    setSelectedIndex(emblaApiParam.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
}

type DotButtonProps = PropsWithChildren<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

function DotButton(props: DotButtonProps) {
  let { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
}
