import {
  getAddressCodeString,
  useGetMyEsimsQuery,
} from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import styled from 'styled-components';
import TwoLayersIcon from './Icons/TwoLayersIcon';
import { CopyButton } from './CopyButton';
import { CurrentPlan } from './CurrentPlan';
import Card from '@front_common/Components/Card';
import { useState } from 'preact/hooks';
import CloseIcon from './Icons/CloseIcon';

type ActivationCodeBoxProps = {
  esimId: string;
  forApple: boolean;
};

export function ActivationCodeBox(props: ActivationCodeBoxProps) {
  let { esimId, forApple } = props;

  let [showPopup, setShowPopup] = useState(false);
  let [field, setField] = useState('');
  let [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  let [response] = useGetMyEsimsQuery();

  function handleCopy(fieldCopied: string) {
    setField(fieldCopied);
    setShowPopup(true);

    let clearId = setTimeout(() => {
      handleClose();
    }, 4000);

    setTimeoutId(clearId);
  }

  function handleClose() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setShowPopup(false);
  }

  if (!response) {
    return <LoadingAnimation />;
  }

  let esim = response.esims.find((item) => item.id === Number(esimId));

  if (esim === undefined) {
    return <LoadingAnimation />;
  }

  return (
    <ActivationCodeBoxStyled>
      <CurrentPlan title="Your activation code for" esimId={esimId} />
      <div>
        {forApple && (
          <>
            <Field>
              <Label>SM-DP+ Address:</Label>
              <Text>
                {esim.smdpAddress}
                <CopyButton
                  textToCopy={esim.smdpAddress}
                  onCopy={() => handleCopy('SM-DP+ address')}
                >
                  <TwoLayersIcon />
                </CopyButton>
              </Text>
            </Field>
            <Divider />
          </>
        )}
        <Field>
          <Label>Activation code:</Label>
          <Text>
            <span>
              {forApple
                ? esim.activationCode
                : getAddressCodeString(esim.smdpAddress, esim.activationCode)}
            </span>
            <CopyButton
              textToCopy={
                forApple
                  ? esim.activationCode
                  : getAddressCodeString(esim.smdpAddress, esim.activationCode)
              }
              onCopy={() => handleCopy('activation code')}
            >
              <TwoLayersIcon />
            </CopyButton>
          </Text>
        </Field>
      </div>
      {showPopup && (
        <Popup>
          <IconWrapper>
            <TwoLayersIcon />
          </IconWrapper>
          <span>Your {field} was copied to the clipboard</span>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Popup>
      )}
    </ActivationCodeBoxStyled>
  );
}

let ActivationCodeBoxStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

let Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 12px 0;
`;

let Label = styled.span`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  color: var(--textSecondary);
`;

let Text = styled.div`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  span {
    overflow-wrap: anywhere;
  }

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill-opacity: 0.5;
  }
`;

let Popup = styled.div`
  position: fixed;
  width: calc(100% - 32px);
  padding: 14px;
  background-color: var(--lightBlue80);
  bottom: 132px;
  left: 16px;
  border-radius: var(--borderRadiusPrimary);
  display: flex;
  gap: 8px;
  z-index: 10;

  span {
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontWeightMedium);
    line-height: 20px;
    width: 100%;
  }

  @media (min-width: 700px) {
    width: calc(100% - 80px);
    left: 40px;
  }

  @media (min-width: 960px) {
    max-width: 846px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
`;

let IconWrapper = styled.div`
  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`;

let CloseButton = styled.button`
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
`;
