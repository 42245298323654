import styled from 'styled-components';
import Screen1Image from './Images/Screen1.png';
import Screen2Image from './Images/Screen2.png';
import Screen3Image from './Images/Screen3.png';
import { HTMLAttributes, useState } from 'preact/compat';
import Button from '@front_common/Components/Button/Button';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_HOW_TO_INSTALL_ESIM } from '@shared_frontend/Common/WebRoutes';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import CompatibleDevicesModalContent from '../CompatibilityGuide/CompatibleDevicesModalContent';

type Step = {
  step: number;
  title: string;
  text: React.ReactNode;
  image: string;
  topBackground: string;
  bottomBackground: string;
  alt: string;
};

export default function HowItWorks() {
  let [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  let stepsData: Step[] = [
    {
      step: 1,
      title: 'Check compatibility',
      text: (
        <SlideText>
          <ul>
            <li>
              Make sure your phone supports eSIM and is not carrier locked.
            </li>
          </ul>
          <StyledButton typeStyle="Secondary" sizeParam="L" onClick={openModal}>
            Check my device’s compatibility
          </StyledButton>
        </SlideText>
      ),
      image: Screen1Image,
      topBackground: 'var(--orange70)',
      bottomBackground: 'var(--orange90)',
      alt: 'Global eSIM compatibility check',
    },
    {
      step: 2,
      title: 'Choose your global subscription',
      text: (
        <SlideText>
          <ul>
            <li>Select the best plan for you and enjoy worldwide coverage.</li>
            <li>
              Your subscription is auto-renewable, so you’ll never lose
              connection.
            </li>
          </ul>
        </SlideText>
      ),
      image: Screen2Image,
      topBackground: 'var(--lightBlue80)',
      bottomBackground: 'var(--cardsBackgroundLightBlueSecondary)',
      alt: 'Global eSIM subscription plans',
    },
    {
      step: 3,
      title: 'Activate your eSIM',
      text: (
        <SlideText>
          <ul>
            <li>Instantly receive your QR code via email, app, and web.</li>
            <li>
              Install it in minutes on your device with our{' '}
              <LinkToRoute
                href={`${WEB_ROUTE_HOW_TO_INSTALL_ESIM}/?utm_source=global_plans`}
              >
                tailored guide
              </LinkToRoute>
              .
            </li>
          </ul>
        </SlideText>
      ),
      image: Screen3Image,
      topBackground: 'var(--green80)',
      bottomBackground: 'var(--green90)',
      alt: 'how to get and activate global eSIM',
    },
  ];

  return (
    <>
      <div>
        <HowItWorksTitle>How it works</HowItWorksTitle>
        <HowItWorksSubtitle>
          Get your Global eSIM in <b>3 easy steps:</b>
        </HowItWorksSubtitle>
      </div>
      <HowItWorksWrapper>
        {stepsData.map((stepData, index) => (
          <Slide key={index} slideData={stepData} />
        ))}
      </HowItWorksWrapper>
      <Modal
        analyticType="device-compatibility"
        isOpen={isModalOpen}
        onClose={closeModal}
        closeButtonPrimary={false}
      >
        <CompatibleDevicesModalContent />
      </Modal>
    </>
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Step;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <SlideWrapper>
      <TopBackground $background={slideData.topBackground}>
        <ScreensImage src={slideData.image} alt={slideData.alt} />
        <Figure $background={slideData.bottomBackground} />
      </TopBackground>
      <BottomBackground $background={slideData.bottomBackground}>
        <StepsNumber>{slideData.step}</StepsNumber>
        <StepsTitle>{slideData.title}</StepsTitle>
        <StepsText>{slideData.text}</StepsText>
      </BottomBackground>
    </SlideWrapper>
  );
}

let HowItWorksWrapper = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    gap: 20px;
    padding: 0 80px;
    max-width: 1440px;
  }

  @media (min-width: 1440px) {
    margin: 0 auto;
  }
`;

let HowItWorksTitle = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

let HowItWorksSubtitle = styled.h3`
  line-height: 22px;
  text-align: center;
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeRegular);

  b {
    font-weight: var(--fontWeightSemiBold);
  }
`;

let SlideWrapper = styled.div`
  min-width: 260px;

  @media (min-width: 393px) {
    min-width: 320px;
  }

  @media (min-width: 960px) {
    min-width: 405px;
  }
`;

let TopBackground = styled.div<{ $background: string }>`
  background-color: ${(props) => props.$background};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 12px 0;
  position: relative;
`;

let BottomBackground = styled.div<{ $background: string }>`
  background-color: ${(props) => props.$background};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 252px;
  padding: 12px;

  @media (min-width: 393px) {
    height: 206px;
  }

  @media (min-width: 960px) {
    padding: 16px;
    height: initial;
    min-height: 212px;
  }
`;

let Figure = styled.div<{ $background: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 116px;
  height: 116px;
  z-index: 2;
  background-color: ${(props) => props.$background};
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  border-radius: 28px;

  @media (min-width: 393px) {
    width: 138px;
    height: 138px;
  }

  @media (min-width: 700px) {
    width: 164px;
    height: 164px;
  }
`;

let ScreensImage = styled.img`
  position: relative;
  display: flex;
  margin: 0 auto;
  z-index: 3;
  width: 83px;

  @media (min-width: 393px) {
    width: 97px;
  }

  @media (min-width: 700px) {
    width: 117px;
  }
`;

let StepsNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--itemBrand);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeBig);
  color: var(--textPrimaryInvert);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 12px;
  line-height: 24px;

  @media (min-width: 393px) {
    width: 32px;
    height: 32px;
    font-size: var(--fontSizeBig);
    line-height: 24px;
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 28px;
  }
`;

let StepsTitle = styled.span`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeBig);
  line-height: 24px;
  text-align: center;
  margin-bottom: 4px;
  display: block;

  @media (min-width: 393px) {
    line-height: 24px;
    font-size: var(--fontSizeBig);
  }

  @media (min-width: 960px) {
    line-height: 28px;
    font-size: 20px;
  }
`;

let StepsText = styled.span`
  font-size: var(--fontSizeRegular);
  line-height: 22px;

  @media (min-width: 393px) {
    font-size: var(--fontSizeRegular);
  }
`;

let SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ul {
    padding-left: 28px;
    text-align: left;
  }

  a {
    color: var(--textLink);
  }
`;

let StyledButton = styled(Button)`
  white-space: initial;
`;
