export function base64UrlEncode(input: string): string {
  let result = btoa(input);
  return result.replaceAll('=', '').replaceAll('+', '-').replaceAll('/', '_');
}

export function isBase64UrlSafe(input: string): boolean {
  // eslint-disable-next-line require-unicode-regexp
  return /^[\w-]+$/.test(input);
}

export function base64UrlDecode(base64string: string): string {
  if (!isBase64UrlSafe(base64string)) {
    throw new Error(
      `String is not in Base64 url safe format: "${base64string}"`,
    );
  }
  let replaced = base64string.replaceAll('-', '+').replaceAll('_', '/');
  return atob(replaced);
}
