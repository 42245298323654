/* eslint-disable max-lines */
import { ChangeEvent } from 'react';
import { MutableRef, useEffect, useState, useRef } from 'preact/hooks';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import {
  countryNameByCode,
  GLOBAL_COUNTRY_SEARCH_KEY,
} from '@shared_backend/Module/Geo/CountryNames';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_HOME_ALL_COUNTRIES,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import Api from '@front_common/Api/Api';
import {
  LinkToRoute,
  routeChangeWithoutRerender,
} from '@front_common/Router/Router';
import VisitorApi, {
  useCountriesActiveQuery,
} from '@front_common/Api/VisitorApi';
import TextSearchInput from '@front_common/Components/TextSearchInput/OldDesign/TextSearchInput';
import Flag from '@front_common/Components/Flag/Flag';
import Button from '@front_common/Components/Button/Button';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import { setCanonicalHeader } from '@front_common/Seo/SeoHeaders';
import DownloadApp from '@front_common/Components/DownloadApp/OldDesign/DownloadApp';
import PromoteBanner from '@front_common/Components/PromoteBanner/OldDesign/PromoteBanner';
import { useSessionData } from '@front_common/Api/Session';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import LargeHeaderWithCustomers from './LargeHeaderWithCustomers';
import BulletPointsComp from './BulletPointsComp';
import HowEsimWorks from './HowEsimWorks/HowEsimWorks';
import FAQ from './FAQ';
import RiskFree from './RiskFree';
import WeGotYouCovered from './WeGotYouCovered';
import WhatYouGet from './WhatYouGet';
import RightIcon from './Icons/RightIcon';
import SearchIcon from './Icons/SearchIcon';
import { getESimCountryUrlByCountryCode } from '../../CountryStore/OldDesign/CountryStoreUtils';
import HomeFeedbacks from './HomeFeedbacks/HomeFeedbacks';
import GlobalImage from './Images/World.png';
import styled from 'styled-components';

const DEFAULT_LIST_TITLE = 'Popular countries';
const LIST_TITLE = 'All countries';
const POPULAR_COUNTRIES_LENGTH = 12;

let inputElement: MutableRef<HTMLInputElement | null>;

function debounceChangeInput(func: (value: string) => void, delay: number) {
  let timer: NodeJS.Timeout;

  return (value: string) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(value), delay);
  };
}

function filterPopularCountriesWithLocation(
  countries: string[],
  locationCountry: string,
  limit?: number,
): string[] {
  let filteredCountries = countries.filter(
    (country) => country !== locationCountry,
  );

  if (!countries.includes(locationCountry)) {
    // If the country code is not included in the list of active countries, it may mean that this country
    // does not have active tariff plans at the moment. In this case, we do not need to show it in the list.
    return limit ? filteredCountries.slice(0, limit) : filteredCountries;
  }

  filteredCountries.unshift(locationCountry);
  return limit ? filteredCountries.slice(0, limit) : filteredCountries;
}

function getAllCountriesList(countries: { [key: string]: string }) {
  return [
    ...POPULAR_COUNTRIES,
    ...Object.keys(countries ?? {}).filter(
      (item) => !POPULAR_COUNTRIES.includes(item),
    ),
  ]; //for always display country list with the same start/popular countries
}

export default function HomePage() {
  let currentRoute = useRouterState();
  let sessionData = useSessionData();

  let inputRef = useRef<HTMLInputElement>(null);
  inputElement = inputRef;

  let [isAllCountriesShowed, setIsAllCountriesShowed] = useState(
    currentRoute.path === WEB_ROUTE_HOME_ALL_COUNTRIES,
  );

  let [allActiveCountries, error] = useCountriesActiveQuery();

  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  let [countries, setCountries] = useState<string[]>(POPULAR_COUNTRIES);
  let [listTitle, setListTitle] = useState(DEFAULT_LIST_TITLE);

  useEffect(() => {
    let currentTitle = isAllCountriesShowed ? LIST_TITLE : DEFAULT_LIST_TITLE;

    setListTitle(currentTitle);
  }, [isAllCountriesShowed]);

  useEffect(() => {
    if (sessionData?.country && allActiveCountries) {
      let countriesList = getAllCountriesList(allActiveCountries);

      setCountries(
        filterPopularCountriesWithLocation(
          countriesList,
          sessionData?.country,
          isAllCountriesShowed ? undefined : POPULAR_COUNTRIES_LENGTH,
        ),
      );
    }
  }, [sessionData, isAllCountriesShowed, allActiveCountries]);

  let debouncedHandleTypo = debounceChangeInput(async (value: string) => {
    if (value.length < 2) {
      let limitCountries = isAllCountriesShowed
        ? undefined
        : POPULAR_COUNTRIES_LENGTH;
      let title = isAllCountriesShowed ? LIST_TITLE : DEFAULT_LIST_TITLE;
      setListTitle(title);

      if (sessionData?.country && allActiveCountries) {
        setCountries(
          filterPopularCountriesWithLocation(
            getAllCountriesList(allActiveCountries),
            sessionData?.country,
            limitCountries,
          ),
        );
      }

      return;
    }

    if (!allActiveCountries) {
      return;
    }

    let foundCountries = await searchCountriesByNamePart(
      value,
      allActiveCountries,
    );

    if (foundCountries.length === 0) {
      setCountries([]);
      setListTitle('No results by search');
    } else {
      setListTitle('Search results');
      let result =
        sessionData?.country && foundCountries.includes(sessionData?.country)
          ? filterPopularCountriesWithLocation(
              foundCountries,
              sessionData.country,
            )
          : foundCountries;
      setCountries(result);
    }
  }, 500);

  function hideAllCountries() {
    routeChangeWithoutRerender(WEB_ROUTE_HOME);
    setIsAllCountriesShowed(false);
    setListTitle(DEFAULT_LIST_TITLE);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    Api.trackAction(ActionTypes.button_home_show_all_countries);
  }

  function displayAllCountries() {
    routeChangeWithoutRerender(WEB_ROUTE_HOME_ALL_COUNTRIES);
    setIsAllCountriesShowed(true);
    setListTitle(LIST_TITLE);
    setCanonicalHeader(window.location.href);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    Api.trackAction(ActionTypes.button_home_show_all_countries);
  }

  function handleShowCountriesClick(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (isAllCountriesShowed) {
      let scrollTimeout: NodeJS.Timeout;
      let time =
        // eslint-disable-next-line unicorn/no-nested-ternary
        window.scrollY > 12_000 ? 900 : window.scrollY > 6000 ? 800 : 700;

      // change text before animation ends
      setIsAllCountriesShowed(false);
      scrollTimeout = setTimeout(() => {
        hideAllCountries();
        clearTimeout(scrollTimeout);
      }, time);
      scrollToPopularCountries();
    } else {
      displayAllCountries();
    }
    return false;
  }

  return (
    <LandingWrapper>
      <PromoteBanner />
      <HeaderWrapper>
        <LargeHeaderWithCustomers />
        <FindCountryInputWrapper>
          <TextSearchInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              debouncedHandleTypo(event.currentTarget.value)
            }
            ref={inputRef}
            placeholder="Search 190+ Countries for data packages"
            customIcon={<SearchIcon />}
          />
        </FindCountryInputWrapper>
      </HeaderWrapper>

      <CountriesBlock>
        <WhiteWrapper>
          <Subtitle>{listTitle}</Subtitle>
          {sessionData ? (
            <>
              <CountriesWrapper $fix={isAllCountriesShowed}>
                {countries.map((countryCode) =>
                  countryCode === GLOBAL_COUNTRY_SEARCH_KEY ? (
                    <GlobalPlan
                      href={`${WEB_ROUTE_STORE_GLOBAL}/`}
                      key="global"
                    >
                      <GlobalImageWrapper>
                        <img
                          width={32}
                          height={32}
                          src={GlobalImage}
                          alt="glbal plan icon"
                        />
                      </GlobalImageWrapper>
                      Global plan
                      <IconWrapper>
                        <RightIcon />
                      </IconWrapper>
                    </GlobalPlan>
                  ) : (
                    <Country
                      key={countryCode}
                      href={getESimCountryUrlByCountryCode(countryCode)}
                    >
                      <FlagStyled circle={false} countryCode={countryCode} />
                      {countryNameByCode(countryCode)}
                      <IconWrapper>
                        <RightIcon />
                      </IconWrapper>
                    </Country>
                  ),
                )}
              </CountriesWrapper>
              <ShowAllCountriesContainer
                href={
                  isAllCountriesShowed
                    ? WEB_ROUTE_HOME
                    : `${WEB_ROUTE_HOME_ALL_COUNTRIES}/`
                }
                onClick={handleShowCountriesClick}
              >
                <ShowAllCountries sizeParam="L">
                  {isAllCountriesShowed
                    ? 'Show popular countries'
                    : 'Show all countries'}
                </ShowAllCountries>
              </ShowAllCountriesContainer>
            </>
          ) : (
            <LoadingAnimation />
          )}
        </WhiteWrapper>
      </CountriesBlock>

      <BulletPointsComp />
      <RiskFree />
      <HowEsimWorks />
      <WeGotYouCovered />
      <WhatYouGet />
      <FeedbackReviewsWrapper>
        <Title>Our users’ feedback</Title>
        <HomeFeedbacks />
      </FeedbackReviewsWrapper>
      <FAQ />
      <DownloadAppWrapper>
        <DownloadApp />
      </DownloadAppWrapper>
    </LandingWrapper>
  );
}

export async function scrollToMainInput() {
  if (!inputElement) {
    throw new Error('Called scroll too early');
  }

  inputElement.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  inputElement.current?.focus({ preventScroll: true });

  Api.trackAction(ActionTypes.button_home_cta);
}

function scrollToPopularCountries() {
  window.scrollTo({ top: 199, behavior: 'smooth' });
}

async function searchCountriesByNamePart(
  namePart: string,
  allActiveCountries: { [isoCode: string]: string },
): Promise<string[]> {
  let search = namePart.toUpperCase().trim().replaceAll(/s+/gu, ' ');
  let response = await VisitorApi.getCountriesByQuerySearch(search);

  let priority1 = [];
  let priority2 = [];
  let priority3 = [];

  for (let code of response) {
    let name = allActiveCountries[code]?.toUpperCase();

    if (name && name.startsWith(search)) {
      priority1.push(code);
      continue;
    }
    if (name && name.includes(' ' + search)) {
      priority2.push(code);
      continue;
    }
    priority3.push(code);
  }
  return [...priority1, ...priority2, ...priority3];
}

let LandingWrapper = styled.div`
  font-size: var(--fontSizeBig);
`;

let Subtitle = styled.div`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: center;
  font-size: 22px;
  line-height: 38px;
  margin-bottom: 12px;

  @media (min-width: 960px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

let HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    gap: 32px;
  }
`;

let CountriesBlock = styled.div`
  padding: 20px 0 40px 0;

  @media (min-width: 700px) {
    padding: 52px 0;
  }
`;

let WhiteWrapper = styled.div`
  @media (min-width: 700px) {
    border-radius: 20px;
    background: var(--backgroundSecondary);
    padding: 40px;
  }
`;

let FindCountryInputWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;

  @media (min-width: 700px) {
    max-width: 600px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
    padding-bottom: 52px;
  }

  @media (min-width: 960px) and (max-height: 762px) {
    padding-bottom: 16px;
  }
`;

let CountriesWrapper = styled.div<{ $fix: boolean }>`
  height: ${({ $fix }) => ($fix ? 'initial' : '296px')};
  overflow: ${({ $fix }) => ($fix ? 'initial' : 'hidden')};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4px;

  @media (min-width: 700px) {
    height: inherit;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
  }

  @media (min-width: 960px) {
    height: inherit;
    margin-right: 0;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1060px;
    margin: 0 auto;
  }

  @media (min-width: 1100px) {
    height: inherit;
    margin-right: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1060px;
    margin: 0 auto;
  }
`;

let Country = styled(LinkToRoute)`
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  color: var(--textPrimary);
  width: 100%;
  padding: 0 16px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: var(--grey70);
  height: 56px;
  text-decoration: none;

  &:hover {
    background-color: var(--strokeSecondary);
  }
`;

let GlobalPlan = styled(LinkToRoute)`
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  color: var(--textPrimary);
  width: 100%;
  padding: 0 16px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: var(--grey70);
  height: 56px;
  text-decoration: none;

  &:hover {
    background-color: var(--strokeSecondary);
  }
`;

const GlobalImageWrapper = styled.div`
  width: 24px;
  height: 18px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;

  img {
    position: absolute;
    width: 32px;
    height: 32px;
  }
`;

let FlagStyled = styled(Flag)`
  width: 24px;
  margin-right: 16px;
`;

let IconWrapper = styled.div`
  margin-left: auto;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    opacity: 0.4;
  }
`;

let ShowAllCountries = styled(Button)`
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  position: sticky;
  bottom: 40px;

  @media (min-width: 700px) {
    margin-top: 32px;
    width: 500px;
    position: initial;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;

let ShowAllCountriesContainer = styled.a`
  text-decoration: none;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  position: sticky;
  bottom: 40px;

  @media (min-width: 700px) {
    margin-top: 32px;
    width: 500px;
    position: initial;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;

let FeedbackReviewsWrapper = styled.div`
  padding: 40px 0 20px 0;

  @media (min-width: 700px) {
    padding: 52px 0;
  }
`;

let Title = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: 700px) {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 42px;
  }
`;

let DownloadAppWrapper = styled.div`
  padding-top: 32px;

  @media (min-width: 700px) {
    padding-top: 40px;
  }

  @media (min-width: 960px) {
    padding-top: 56px;
  }
`;
