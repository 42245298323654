export const popularEmailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'outlook.com',
  'live.com',
  'yahoo.fr',
  'aol.com',
  'me.com',
  'msn.com',
  'wp.pl',
  'proton.me',
  'web.de',
  'gmx.net',
  'gmx.com',
  'zoho.com',
  'orange.fr',
  'qq.com',
];
