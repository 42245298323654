export default function DualSimSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116028)">
        <path
          d="M11 6H13C13.3182 6 13.5947 6.10691 13.774 6.25638C13.9481 6.40141 14 6.55481 14 6.66667V19H10V6.66667C10 6.55481 10.0519 6.40141 10.226 6.25638C10.4053 6.10691 10.6818 6 11 6Z"
          stroke="#21252A"
          strokeWidth="2"
        />
        <path
          d="M20 1H22C22.3182 1 22.5947 1.10691 22.774 1.25638C22.9481 1.40141 23 1.55481 23 1.66667V19H19V1.66667C19 1.55481 19.0519 1.40141 19.226 1.25638C19.4053 1.10691 19.6818 1 20 1Z"
          stroke="#21252A"
          strokeWidth="2"
        />
        <path
          d="M2 11H4C4.3182 11 4.59467 11.1069 4.77403 11.2564C4.94807 11.4014 5 11.5548 5 11.6667V19H1V11.6667C1 11.5548 1.05193 11.4014 1.22597 11.2564C1.40533 11.1069 1.6818 11 2 11Z"
          stroke="#21252A"
          strokeWidth="2"
        />
        <rect x="9" y="22" width="6" height="2" fill="#21252A" />
        <rect x="18" y="22" width="6" height="2" fill="#21252A" />
        <rect y="22" width="6" height="2" fill="#21252A" />
      </g>
      <defs>
        <clipPath id="clip0_14411_116028">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
