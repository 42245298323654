import styled from 'styled-components';
import Button from '@front_common/Components/Button/Button';
import { useESimCardContext } from '../ESimContext';
import { formatDataAmount } from '../Utils';

export function ActivatedPlanContent({
  dataRemainingMb,
  dataPurchasedGb,
  expiresIn,
}: {
  dataRemainingMb: number;
  dataPurchasedGb: number;
  expiresIn: string | null | undefined;
}) {
  return (
    <RowBox>
      <ColumnBox>
        <DescriptionLabel data-testid="active-data-left-label">
          Data left:
        </DescriptionLabel>
        <DescriptionValue>
          {formatDataAmount(dataRemainingMb)}{' '}
          <DescriptionValueSmall>out of</DescriptionValueSmall>{' '}
          {formatDataAmount(dataPurchasedGb * 1024)}
        </DescriptionValue>
      </ColumnBox>
      <ColumnBox>
        <DescriptionLabel data-testid="active-expires-in-label">
          Expires in:
        </DescriptionLabel>
        <DescriptionValue>{expiresIn}</DescriptionValue>
      </ColumnBox>
    </RowBox>
  );
}

export function NotActivatedPlanContent({
  expiresIn,
  handleInstallNewSim,
}: {
  expiresIn: string | null | undefined;
  handleInstallNewSim: (event: MouseEvent) => void;
}) {
  return (
    <RowBox>
      <ColumnBox>
        <DescriptionLabel data-testid="not-active-valid-for-label">
          Valid for:
        </DescriptionLabel>
        <DescriptionValue>{expiresIn}</DescriptionValue>
      </ColumnBox>
      <ButtonStyled
        data-testid="not-active-install-esim-button"
        sizeParam="S"
        typeStyle="Secondary"
        onClick={handleInstallNewSim}
      >
        Install eSIM
      </ButtonStyled>
    </RowBox>
  );
}

export function PreparingPlanContent() {
  return (
    <>
      <DescriptionRow data-testid="preparing-received-text">
        Your payment is received.
      </DescriptionRow>
      <DescriptionRow data-testid="preparing-esim-text">
        We&#39;re preparing your eSIM.
      </DescriptionRow>
    </>
  );
}

export function InstalledPlanContent({
  expiresIn,
}: {
  expiresIn: string | null | undefined;
}) {
  return (
    <ColumnBox>
      <DescriptionLabel data-testid="installed-valid-for-label">
        Valid for:
      </DescriptionLabel>
      <DescriptionValue>{expiresIn}</DescriptionValue>
    </ColumnBox>
  );
}

export function ExpiredPlanContent({
  expiresAt,
  handleRepurchaseSim,
}: {
  expiresAt: string;
  handleRepurchaseSim: (event: MouseEvent) => void;
}) {
  let { isFromActive } = useESimCardContext();

  return (
    <RowBox>
      <ColumnBox>
        <DescriptionLabel data-testid="expired-on-label">
          Expired on:
        </DescriptionLabel>
        <DescriptionValue>{expiresAt}</DescriptionValue>
      </ColumnBox>
      {isFromActive && (
        <ButtonStyled
          data-testid="expired-repurchase-button"
          sizeParam="S"
          typeStyle="Secondary"
          onClick={handleRepurchaseSim}
        >
          Repurchase
        </ButtonStyled>
      )}
    </RowBox>
  );
}

export function DataFinishedPlanContent({
  dataPurchasedGb,
  handleRepurchaseSim,
}: {
  dataPurchasedGb: number;
  handleRepurchaseSim: (event: MouseEvent) => void;
}) {
  return (
    <RowBox>
      <ColumnBox>
        <DescriptionLabel data-testid="data-finished-data-left-label">
          Data left:
        </DescriptionLabel>
        <DescriptionValue>
          0GB <DescriptionValueSmall>out of</DescriptionValueSmall>{' '}
          {formatDataAmount(dataPurchasedGb * 1024)}
        </DescriptionValue>
      </ColumnBox>
      <ButtonStyled
        data-testid="data-finished-repurchase-button"
        sizeParam="S"
        typeStyle="Secondary"
        onClick={handleRepurchaseSim}
      >
        Repurchase
      </ButtonStyled>
    </RowBox>
  );
}

export function RefundedPlanContent({ refundedAt }: { refundedAt: string }) {
  return (
    <>
      <DescriptionLabel data-testid="refunded-date-label">
        Refund on:
      </DescriptionLabel>
      <DescriptionValue>{refundedAt}</DescriptionValue>
    </>
  );
}

export function UnknownPlanContent({
  validFor,
  handleInstallNewSim,
}: {
  validFor: string;
  handleInstallNewSim: (event: MouseEvent) => void;
}) {
  return (
    <RowBox>
      <ColumnBox>
        <DescriptionLabel data-testid="unknown-valid-for-label">
          Valid for:
        </DescriptionLabel>
        <DescriptionValue>{validFor}</DescriptionValue>
      </ColumnBox>
      <ButtonStyled
        data-testid="unknown-esim-button"
        sizeParam="S"
        typeStyle="Secondary"
        onClick={handleInstallNewSim}
      >
        Install eSIM
      </ButtonStyled>
    </RowBox>
  );
}

type StatusWrapperProps = {
  $bgColor: string;
};

export let StatusWrapper = styled.div<StatusWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 12px;
  padding: 2px 12px;
  line-height: 20px;
  z-index: 2;
  color: var(--textPrimary);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontFamilyPrimary);
  background-color: ${({ $bgColor }) => $bgColor};
`;

let DescriptionRow = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPrimary);
`;

let RowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  white-space: nowrap;
  &:first-child {
    min-width: 60%;
  }
  &:last-child {
    min-width: 100px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

let DescriptionLabel = styled.span`
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPrimary);
`;

let DescriptionValue = styled.span`
  line-height: 22px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightSemiBold);
`;

let DescriptionValueSmall = styled.span`
  line-height: 16px;
  font-size: 12px;
  font-weight: var(--fontWeightSemiBold);
`;

let ButtonStyled = styled(Button)`
  width: 115px;
  z-index: 1;
`;
