import bluredSImage from './Images/BlurredMobile.png';
import bluredMImage from './Images/BlurredTablet.png';
import bluredLImage from './Images/BluredDesktop.png';
import Card from '@front_common/Components/Card';
import LockIcon from '../../Checkout/Icons/LockIcon';
import styled from 'styled-components';

export default function PaymentBlurred() {
  return (
    <Blurred>
      <picture>
        <source media="(min-width: 393px)" srcSet={bluredMImage} />
        <source media="(min-width: 700px)" srcSet={bluredLImage} />
        <img src={bluredSImage} alt="blured payment" />
      </picture>
      <TextIcon>
        <LockIcon />
        <span>Checkout will open after email validation</span>
      </TextIcon>
    </Blurred>
  );
}

let Blurred = styled(Card)`
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;

  picture {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 700px) {
    picture {
      height: 438px;
    }
  }
`;

let TextIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    line-height: 24px;
    text-align: center;
    font-size: var(--fontSizeBig);
    font-family: var(--fontFamilyHeader);
    font-weight: var(--fontWeightMedium);
  }
`;
