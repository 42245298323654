import styled from 'styled-components';
import simCardImage from './Images/SimCardImage.png';
import Button from '@front_common/Components/Button/Button';

type IProps = {
  message: string;
  handleRepurchaseSim: (event: MouseEvent) => void;
};

export default function ESimReconnectTooltip({
  message,
  handleRepurchaseSim,
}: IProps) {
  return (
    <ReconnectTooltipWrapper>
      <RowBox>
        <img src={simCardImage} alt="reconnect-image" />
        <div>
          <ReconnectTitle>Need to reconnect?</ReconnectTitle>
          {message}
        </div>
      </RowBox>
      <ButtonStyled sizeParam="L" onClick={handleRepurchaseSim}>
        Repurchase
      </ButtonStyled>
    </ReconnectTooltipWrapper>
  );
}

let ReconnectTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: var(--borderRadiusLarge);
  background-color: var(--cardsBackgroundOrange);
`;

let ReconnectTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;

let RowBox = styled.div`
  display: flex;
  margin-bottom: 16px;
  img {
    width: 42px;
    height: 54px;
    margin-right: 8px;
  }
`;

let ButtonStyled = styled(Button)`
  width: 100%;
`;
