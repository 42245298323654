/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';
import { scrollToMainInput } from './HomePage';
import { data } from '../../CountryStore/OldDesign/FAQ';
import Accordion from '@front_common/Components/Accordion/OldDesign/Accordion';
import { WEB_ROUTE_COMPATIBLE_DEVICES } from '@shared_frontend/Common/WebRoutes';
import Button from '@front_common/Components/Button/Button';

export let qnaData = [
  {
    title: 'What is an eSIM and what are the benefits of using it?',
    content:
      'An eSIM is a digital SIM that allows you to activate a cellular plan without a physical SIM card. Benefits include it being more affordable that roaming, easier switching between carriers, no hassle with physical SIM cards, and they are safe and reliable.',
  },
  {
    title: 'Is my smartphone compatible with eSIM?',
    content: (
      <span>
        Compatibility varies by device. Most newer smartphones support eSIM
        technology. Check your device settings under "Cellular" or "Mobile
        Network" options, or refer to your device’s manual for confirmation.
        Please note, that some Smartphones from Asian countries may not support
        eSIM. For more information check out this{' '}
        <a href={`${WEB_ROUTE_COMPATIBLE_DEVICES}/`}>
          Compatible Devices guide
        </a>
        .
      </span>
    ),
  },
  {
    title: 'What are the steps to install an eSIM on my device?',
    content: `Once you purchase your eSim you will be able to access out step-by-step guide to help you with quick and easy activation.
      Here are the main steps for installation:
      1. Ensure your device is eSIM compatible and connected to Wi-Fi.
      2. Navigate to 'Settings' and select 'Cellular' or 'Mobile Network'.
      3. Choose 'Add Cellular Plan' and scan the QR code provided after your eSIM purchase.
      4. Follow the on-screen instructions to complete the setup.`,
  },
  {
    title: "What should I do if my eSIM isn't working?",
    content:
      'Restart your device first. If the issue persists, ensure your device settings are correctly configured for eSIM use. Contact our support team if you need further assistance.',
  },
  ...data,
  {
    title: 'How secure is an eSIM compared to traditional SIM cards?',
    content:
      'eSIMs offer enhanced security features. They are built into the device and cannot be physically removed or tampered with, reducing the risk of theft or loss.',
  },
];

export default function FAQ() {
  return (
    <FAQWrapper>
      <Header>FAQ</Header>
      <Accordion data={qnaData} />
      <CTAButton onClick={scrollToMainInput} sizeParam="L">
        Get started
      </CTAButton>
    </FAQWrapper>
  );
}

let FAQWrapper = styled.div`
  padding-top: 40px;

  a {
    color: var(--textLink);
  }

  @media (min-width: 700px) {
    max-width: 846px;
    margin: 0 auto;
    padding-top: 52px;
    text-align: center;
  }
`;

let Header = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: 700px) {
    margin-bottom: 42px;
    font-size: 36px;
    line-height: 38px;
  }
`;

let CTAButton = styled(Button)`
  margin: 24px auto 0;
  width: 100%;

  @media (min-width: 700px) {
    width: 500px;
    margin: 40px auto 0;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;
