export default function EarningsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_114922)">
        <path
          d="M2 24H11V22H3.414L24 1.414L22.586 0L2 20.586V13H0V22C0 22.5304 0.210714 23.0391 0.585787 23.4142C0.960859 23.7893 1.46957 24 2 24V24Z"
          fill="#EF8268"
        />
        <path
          d="M17.9998 14C17.2086 14 16.4353 14.2346 15.7775 14.6741C15.1197 15.1136 14.607 15.7384 14.3042 16.4693C14.0015 17.2002 13.9223 18.0044 14.0766 18.7804C14.231 19.5563 14.6119 20.269 15.1713 20.8284C15.7307 21.3878 16.4435 21.7688 17.2194 21.9231C17.9953 22.0775 18.7996 21.9983 19.5305 21.6955C20.2614 21.3928 20.8861 20.8801 21.3256 20.2223C21.7652 19.5645 21.9998 18.7911 21.9998 18C21.9998 16.9391 21.5783 15.9217 20.8282 15.1716C20.078 14.4214 19.0606 14 17.9998 14ZM17.9998 20C17.6042 20 17.2175 19.8827 16.8886 19.6629C16.5597 19.4432 16.3034 19.1308 16.152 18.7654C16.0006 18.3999 15.961 17.9978 16.0382 17.6098C16.1154 17.2219 16.3058 16.8655 16.5855 16.5858C16.8652 16.3061 17.2216 16.1156 17.6096 16.0384C17.9975 15.9613 18.3997 16.0009 18.7651 16.1522C19.1306 16.3036 19.4429 16.56 19.6627 16.8889C19.8825 17.2178 19.9998 17.6044 19.9998 18C19.9998 18.5304 19.789 19.0391 19.414 19.4142C19.0389 19.7893 18.5302 20 17.9998 20Z"
          fill="#EF8268"
        />
        <path
          d="M6 10C6.79112 10 7.56448 9.7654 8.22228 9.32588C8.88007 8.88635 9.39277 8.26164 9.69552 7.53073C9.99827 6.79983 10.0775 5.99556 9.92314 5.21964C9.7688 4.44371 9.38784 3.73098 8.82843 3.17157C8.26902 2.61216 7.55628 2.2312 6.78036 2.07686C6.00444 1.92252 5.20017 2.00173 4.46926 2.30448C3.73836 2.60723 3.11365 3.11992 2.67412 3.77772C2.23459 4.43552 2 5.20887 2 6C2 7.06087 2.42143 8.07828 3.17157 8.82843C3.92172 9.57857 4.93913 10 6 10ZM6 4C6.39556 4 6.78224 4.1173 7.11114 4.33706C7.44004 4.55682 7.69638 4.86918 7.84776 5.23463C7.99913 5.60008 8.03874 6.00222 7.96157 6.39018C7.8844 6.77814 7.69392 7.13451 7.41421 7.41421C7.13451 7.69392 6.77814 7.8844 6.39018 7.96157C6.00222 8.03874 5.60008 7.99913 5.23463 7.84776C4.86918 7.69638 4.55682 7.44004 4.33706 7.11114C4.1173 6.78224 4 6.39556 4 6C4 5.46957 4.21071 4.96086 4.58578 4.58579C4.96086 4.21071 5.46956 4 6 4Z"
          fill="#EF8268"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_114922">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
