import styled from 'styled-components';
import LogoPng from '@shared_frontend/Module/Layout/Logo_ohayu_dark.png';
import { LinkToRoute } from '@front_common/Router/Router';
import {
  CONFIG_MIRROR_HOST,
  CONFIG_SUPPORT_EMAIL,
} from '@front_common/Config.ts';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import {
  ADDRESS,
  APP_DOWNLOAD_APPLE,
  APP_DOWNLOAD_GOOGLE,
  HELP_LIST,
  LEGAL_LIST,
  SOCIALS_LIST,
} from '@shared_frontend/Module/Layout/FooterConstants';
import NoIndexContent from '@front_common/Components/NoIndexContent';
import {
  WEB_ROUTE_BLOG,
  WEB_ROUTE_HOME,
} from '@shared_frontend/Common/WebRoutes';
import appleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/AppleDownload.png';
import googleDownloadImage from '@shared_frontend/Common/Components/DownloadApp/Images/GoogleDownload.png';
import { SHORT_FOOTER_CLASS_NAME } from '../../Checkout/useDisplayingHeaderFooterElements';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { getESimCountryUrlByCountryCode } from '../../CountryStore/OldDesign/CountryStoreUtils';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';

function handleDownloadClick(value: 'Apple' | 'Google') {
  Api.trackAction(ActionTypes.button_app, value);
}

function AppLinks() {
  return (
    <LogoApp>
      <Logo href={WEB_ROUTE_HOME}>
        <LogoImg loading="lazy" src={LogoPng} />
      </Logo>
      <DownloadLinks>
        <a
          href={APP_DOWNLOAD_APPLE}
          onClick={() => handleDownloadClick('Apple')}
          target="_blank"
          rel="noreferrer"
        >
          <img src={appleDownloadImage} alt="apple download" />
        </a>
        <a
          href={APP_DOWNLOAD_GOOGLE}
          onClick={() => handleDownloadClick('Google')}
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleDownloadImage} alt="google download" />
        </a>
      </DownloadLinks>
    </LogoApp>
  );
}

function TopDestinationColumn() {
  return (
    <ContentItem>
      <SubTitle>Top destinations</SubTitle>
      <List>
        {POPULAR_COUNTRIES.map((countryCode) => (
          <ListItem key={countryCode}>
            <LinkToRoute href={getESimCountryUrlByCountryCode(countryCode)}>
              {countryNameByCode(countryCode)}
            </LinkToRoute>
          </ListItem>
        ))}
      </List>
    </ContentItem>
  );
}

function LegalColumn() {
  return (
    <ContentItem>
      <SubTitle>Legal</SubTitle>
      <List>
        {LEGAL_LIST.map((legalItem) => (
          <ListItem key={legalItem.label}>
            <LinkToRoute href={`${legalItem.href}/`}>
              {legalItem.label}
            </LinkToRoute>
          </ListItem>
        ))}
      </List>
    </ContentItem>
  );
}

function HelpColumn() {
  return (
    <ContentItem>
      <SubTitle>Help</SubTitle>
      <List>
        {HELP_LIST.map((helpItem) => (
          <ListItem key={helpItem.label}>
            <LinkToRoute href={`${helpItem.href}/`}>
              {helpItem.label}
            </LinkToRoute>
          </ListItem>
        ))}
        <ListItem>
          <a href={`${CONFIG_MIRROR_HOST}${WEB_ROUTE_BLOG}`}>Blog</a>
        </ListItem>
      </List>
    </ContentItem>
  );
}

function SocialColumn() {
  return (
    <ContentItem>
      <SubTitle>Socials</SubTitle>
      <List>
        {SOCIALS_LIST.map((socialItem) => (
          <ListItem key={socialItem.label}>
            <a href={socialItem.href} target="_blank" rel="noreferrer">
              {<img src={socialItem.icon} alt={`${socialItem.label} icon`} />}
              {socialItem.label}
            </a>
          </ListItem>
        ))}
      </List>
    </ContentItem>
  );
}

export default function PageFooter() {
  return (
    <FooterWrapper>
      <Footer>
        <MainContent>
          <AppLinks />
          <NavigationLinks>
            <ColumnsWrapper>
              <TopDestinationColumn />
              <LegalColumn />
            </ColumnsWrapper>
            <ColumnsWrapper>
              <HelpColumn />
              <SocialColumn />
            </ColumnsWrapper>
          </NavigationLinks>
        </MainContent>
        <Divider />
        <SupportDefault href={'mailto:' + CONFIG_SUPPORT_EMAIL}>
          {CONFIG_SUPPORT_EMAIL}
        </SupportDefault>
        <BottomSide>
          <NoIndexContent>{ADDRESS}</NoIndexContent>
        </BottomSide>
      </Footer>
    </FooterWrapper>
  );
}

type PageShortFooterProps = {
  withBottomMargin: boolean;
};

export function PageShortFooter(props: PageShortFooterProps) {
  let { withBottomMargin } = props;

  return (
    <ShortFooterWrapper
      className={SHORT_FOOTER_CLASS_NAME}
      $withBottomMargin={withBottomMargin}
    >
      <PaddingWrapper>
        <Footer>
          <Support href={'mailto:' + CONFIG_SUPPORT_EMAIL}>
            {CONFIG_SUPPORT_EMAIL}
          </Support>
          <BottomSideShort>
            <NoIndexContent>{ADDRESS}</NoIndexContent>
          </BottomSideShort>
        </Footer>
      </PaddingWrapper>
    </ShortFooterWrapper>
  );
}

let FooterWrapper = styled.div`
  padding: 40px 16px 16px;
  background-color: white;

  @media (min-width: 700px) {
    padding: 40px 40px 20px;
  }

  @media (min-width: 960px) {
    padding: 40px 80px 20px;
  }
`;

let ShortFooterWrapper = styled.div<{ $withBottomMargin: boolean }>`
  padding-bottom: ${(props) => (props.$withBottomMargin ? '88px' : 'initial')};
`;

let PaddingWrapper = styled(FooterWrapper)`
  padding-top: 16px;
  padding-bottom: 16px;

  @media (min-width: 700px) {
    padding: 20px 40px;
  }

  @media (min-width: 960px) {
    padding: 20px 80px;
  }
`;

let Support = styled.a`
  text-decoration: none;
  color: var(--textSecondary);
  display: inline-block;
  line-height: 20px;
  font-size: var(--fontSizeSmall);

  &:hover {
    color: var(--textHover);
  }
`;

let SupportDefault = styled(Support)`
  margin-top: 16px;
`;

let Footer = styled.footer`
  margin: 0 auto;

  @media (min-width: 960px) {
    max-width: 1280px;
  }
`;

let MainContent = styled.div`
  margin-bottom: 16px;

  @media (min-width: 700px) {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 20px;
  }
`;

let LogoApp = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 700px) {
    margin-bottom: initial;
  }

  @media (min-width: 960px) {
    width: calc((100% - 80px) / 5);
  }
`;

let Logo = styled(LinkToRoute)`
  display: inline-block;
  height: 32px;
`;

let LogoImg = styled.img`
  height: 32px;
`;

let NavigationLinks = styled.div`
  display: flex;
  column-gap: 16px;

  @media (min-width: 700px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 960px) {
    column-gap: 20px;
    flex-wrap: nowrap;
    width: calc(100% - calc((100% - 80px) / 5) - 20px);
  }
`;

let ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: calc(50% - 8px);

  @media (min-width: 700px) {
    flex-direction: row;
    column-gap: 16px;
    width: initial;
    flex: 1;
  }

  @media (min-width: 960px) {
    column-gap: 20px;
    width: calc((100% - 20px) / 2);
  }
`;

let ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 700px) {
    flex: 1;
  }

  @media (min-width: 960px) {
    width: calc((100% - 20px) / 2);
  }
`;

let SubTitle = styled.span`
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;

  @media (min-width: 960px) {
    white-space: nowrap;
  }
`;

let List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let ListItem = styled.li`
  min-height: 20px;
  font-size: 14px;
  list-style-type: none;

  a {
    text-decoration: none;
    color: var(--textPrimary);
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: var(--textHover);
    }
  }
`;

let BottomSide = styled.div`
  margin-top: 12px;
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  color: var(--textSecondary);
`;

let BottomSideShort = styled(BottomSide)`
  margin-top: 12px;
`;

let Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--strokePrimary);
`;

let DownloadLinks = styled.div`
  display: flex;
  gap: 12px;

  a {
    display: flex;

    img {
      width: 120px;
      height: 40px;
    }
  }

  @media (min-width: 960px) {
    flex-direction: column;
  }
`;
