import { useCallback, useEffect, useState } from "react";

export default function useWindowDimensions({ onlyWidth = false } = {}) {
  let [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // Callback function to handle resize
  let handleResize = useCallback(() => {
    setWindowDimensions((prevDimensions) => {
      let newWidth = window.innerWidth;
      let newHeight = window.innerHeight;

      if (onlyWidth) {
        if (newWidth !== prevDimensions.width) {
          return { ...prevDimensions, width: newWidth };
        }
      } else if (
        newWidth !== prevDimensions.width ||
        newHeight !== prevDimensions.height
      ) {
        return { width: newWidth, height: newHeight };
      }

      return prevDimensions;
    });
  }, [onlyWidth]);

  useEffect(() => {
    // Call handleResize initially to set the correct dimensions
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return windowDimensions;
}
