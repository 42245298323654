import { useEffect, useState } from 'preact/hooks';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import { CONFIG_MIRROR_NAME } from '@front_common/Config';
import { SUPPORT_EMAIL } from '@shared_frontend/Common/Constants/EnvConstants';
import UserApi, { useGetUserQuery } from '@front_common/Api/UserApi';
import styled from 'styled-components';
import Button from '@front_common/Components/Button/Button';
import { LinkToRoute, removeQueryParam } from '@front_common/Router/Router';
import LogoPictureOhayu from '@shared_frontend/Module/Layout/Logo_ohayu_dark.png';
import LoudSpeaker from './Icons/LoudSpeakerIcon.png';
import { QUERY_PARAM_HIDE_LAYOUT } from '../Layout/Layout';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import ErrorBlock from '@front_common/Components/ErrorBlock';

export default function EmailUnsubscribePage() {
  let [isSubscribed, setIsSubscribed] = useState(true);
  let [userData, error] = useGetUserQuery();
  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  async function handleUnsubscribe() {
    try {
      await UserApi.unsubscribeMrkEmails();
      setIsSubscribed(false);
    } catch (error_: unknown) {
      logger.error(error_);
    }
  }

  useEffect(() => {
    if (userData) {
      setIsSubscribed(userData?.emailMarketingConsent);
    }
  }, [userData]);

  return (
    <Wrapper>
      <Header>
        <Logo
          href={WEB_ROUTE_HOME}
          onClick={() => removeQueryParam(QUERY_PARAM_HIDE_LAYOUT)}
        >
          <LogoImage
            src={LogoPictureOhayu}
            alt={CONFIG_MIRROR_NAME.toLowerCase()}
          />
        </Logo>
      </Header>
      {userData && (
        <ContentContainer>
          <Title>
            <TitleText>
              {isSubscribed ? 'Unsubscribe' : 'You have been unsubscribed'}
            </TitleText>
          </Title>
          <DescriptionContainer>
            {isSubscribed && (
              <LoudSpeakerImage src={LoudSpeaker} alt={'Loud speaker image'} />
            )}
            <Description $isNotSubscribed={!isSubscribed}>
              {isSubscribed ? (
                "By clicking 'Unsubscribe' you will no longer be receiving any special offers, promotions or product updates via email."
              ) : (
                <>
                  You will no longer receive any special offers, promotions or
                  product updates via email.
                  <br />
                  If you have any questions, please contact us at
                </>
              )}
            </Description>

            {isSubscribed ? (
              <UserEmailInfo>
                <Label>Email</Label>
                <UserEmail>{userData?.email}</UserEmail>
              </UserEmailInfo>
            ) : (
              <SupportEmail href={`mailto:${SUPPORT_EMAIL}`}>
                {SUPPORT_EMAIL}
              </SupportEmail>
            )}
            {isSubscribed && (
              <ButtonUnsub sizeParam={'L'} onClick={handleUnsubscribe}>
                Unsubscribe
              </ButtonUnsub>
            )}
          </DescriptionContainer>
        </ContentContainer>
      )}
    </Wrapper>
  );
}

let Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
`;

let Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 16px 16px 16px;
  width: 100%;
`;

let Logo = styled(LinkToRoute)`
  height: 32px;
  display: block;
`;

let LogoImage = styled.img`
  height: 100%;
  display: block;
`;

let ContentContainer = styled.div`
  border: var(--borderOutlinePrimary);
  border-radius: var(--borderRadiusLarge);
  width: 100%;
  max-width: 530px;
  margin-top: 10%;
`;

let Title = styled.div`
  padding: 20px;
  background-color: var(--orange90);
  border-radius: 15px 15px 0 0;
`;

let TitleText = styled.h4`
  font-size: 24px;
  line-height: 32px;
  width: 70%;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);

  @media (min-width: 393px) {
    width: 100%;
  }

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let DescriptionContainer = styled.div`
  padding: 20px;
  background-color: var(--backgroundSecondary);
  border-radius: var(--borderRadiusLarge);
`;

let LoudSpeakerImage = styled.img`
  height: 38px;
  width: 38px;
  display: block;
  margin-bottom: 6px;
`;

let Description = styled.p<{ $isNotSubscribed: boolean }>`
  font-size: var(--fontSizeRegular);
  font-weight: ${({ $isNotSubscribed }) =>
    $isNotSubscribed ? 'var(--fontWeightRegular)' : 'var(--fontWeightMedium)'};
  line-height: 22px;
`;

let SupportEmail = styled.a`
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
  line-height: 22px;
`;

let UserEmailInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;
`;

let Label = styled.p`
  color: var(--textSecondary);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let UserEmail = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
`;

let ButtonUnsub = styled(Button)`
  width: 100%;
`;
