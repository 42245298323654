export function stringifyForLog(items: unknown): string {
  if (typeof items === 'function') {
    let messageAsStr = Function.prototype.toString.call(items);
    let isClass = messageAsStr.startsWith('class ');
    if (isClass) {
      return stringifyForLog(items.name);
    }
    return stringifyForLog(['Function', items.name, 'result:', items()]);
  }
  if (items instanceof Error) {
    return items.name + ': ' + items.message;
  }
  if (items instanceof Date) {
    return items.toISOString();
  }
  if (items === undefined) {
    return 'undefined';
  }
  if (items === null) {
    return 'null';
  }
  if (typeof items === 'string' || typeof items === 'number') {
    return String(items);
  }
  if (Array.isArray(items)) {
    return items.map((subItem) => stringifyForLog(subItem)).join(' ');
  }
  if (
    typeof items === 'object' &&
    'message' in items &&
    typeof items.message === 'string'
  ) {
    return items.message;
  }
  if (typeof items.toString === 'function') {
    return items.toString() + ' ' + JSON.stringify(items);
  }
  return JSON.stringify(items);
}
