import { HTMLAttributes, useState } from 'preact/compat';
import { EmblaOptionsType } from 'embla-carousel';
import Screen1Image from '../Images/Screen1.png';
import Screen2Image from '../Images/Screen2.png';
import Screen3Image from '../Images/Screen3.png';
import Screen4Image from '../Images/Screen4.png';
import { scrollToMainInput } from '../HomePage';
import Carousel from './Carousel';
import RightArrowSVG from '../Icons/RightArrowSVG';
import Button from '@front_common/Components/Button/Button';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import CompatibleDevicesModalContent from '../../../CompatibilityGuide/CompatibleDevicesModalContent';
import styled from 'styled-components';
import './HomeHowEsimWorksCarousel.css';

export type Step = {
  step: number;
  title: string;
  text: string;
  image: string;
};

let stepsData: Step[] = [
  {
    step: 1,
    title: 'Verify for eSIM compatibility',
    text: 'Make sure your device supports eSIM before purchase',
    image: Screen1Image,
  },
  {
    step: 2,
    title: 'Select your country',
    text: 'Choose where you need service',
    image: Screen2Image,
  },
  {
    step: 3,
    title: 'Pick your data plan',
    text: 'Select how much data you will need',
    image: Screen3Image,
  },
  {
    step: 4,
    title: 'Install your eSIM and enjoy',
    text: 'Follow simple steps provided to you after purchase to activate the eSIM on your device',
    image: Screen4Image,
  },
];

let OPTIONS: EmblaOptionsType = {
  slidesToScroll: 1,
  duration: 20,
  align: 'start',
};

export default function HowEsimWorks() {
  let [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <HowESimWorksWrapper>
      <LeftBlock>
        <Title>How eSIM works</Title>
        <Description>
          Just a couple of steps separate you from <br /> hassle-free internet
          access
        </Description>
        <ButtonsWrapper>
          <CTAButton onClick={scrollToMainInput} sizeParam="M">
            Get started
          </CTAButton>
          <CompatibilityGuideBtn
            onClick={openModal}
            sizeParam="M"
            typeStyle="Secondary"
          >
            <span>Check my device’s compatibility</span>
          </CompatibilityGuideBtn>
        </ButtonsWrapper>
      </LeftBlock>
      <RightBlock>
        <Carousel
          slides={stepsData}
          options={OPTIONS}
          baseClassName="home-how-it-works"
          getSlideComponent={(data: Step) => <Slide slideData={data} />}
          prevBtnChildren={
            <ButtonLeftWrapper>
              <BlobStep />
              <IconWrapper>
                <RightArrowSVG />
              </IconWrapper>
            </ButtonLeftWrapper>
          }
          nextBtnChildren={
            <ButtonRightWrapper>
              <BlobStep />
              <IconWrapper>
                <RightArrowSVG />
              </IconWrapper>
            </ButtonRightWrapper>
          }
        />
      </RightBlock>
      <Modal
        analyticType="device-compatibility"
        isOpen={isModalOpen}
        onClose={closeModal}
        closeButtonPrimary={false}
      >
        <CompatibleDevicesModalContent />
      </Modal>
    </HowESimWorksWrapper>
  );
}

interface SlideProps extends HTMLAttributes<HTMLDivElement> {
  slideData: Step;
}

function Slide(props: SlideProps) {
  let { slideData } = props;

  return (
    <SlideWrapper>
      <PhoneImageWrapper>
        <ScreensWrapper>
          <img loading="lazy" src={slideData.image} alt={slideData.title} />
        </ScreensWrapper>
      </PhoneImageWrapper>
      <StepInfo>
        <StepTitle>{slideData.title}</StepTitle>
        <StepText>{slideData.text}</StepText>
      </StepInfo>
    </SlideWrapper>
  );
}

let HowESimWorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    padding: 0 32px;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    padding: 0;
    margin: 0 auto;
    width: calc(100% - 64px);
    max-width: 1040px;
  }
`;

let LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 960px) {
    text-align: left;
  }
`;

let Title = styled.h2`
  width: 100%;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    text-align: left;
  }
`;

let Description = styled.div`
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;
  opacity: 75%;
  font-size: var(--fontSizeRegular);

  @media (min-width: 960px) {
    text-align: left;
  }
`;

let ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 8px;
  }
`;

let CTAButton = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
  background-color: var(--buttonPrimaryNew);

  &:hover {
    background-color: var(--black100) !important;
  }

  @media (min-width: 700px) {
    max-width: 500px;
  }

  @media (min-width: 960px) {
    display: flex;
    max-width: 164px;
  }
`;

let CompatibilityGuideBtn = styled(Button)`
  width: 100%;
  white-space: nowrap;
  color: var(--textPrimaryNew);
  border-color: var(--strokeBrandNew);
  margin-bottom: 24px;

  &:hover {
    // TODO: fix important after design variant confirmation
    color: var(--black100) !important;
    border-color: var(--black100) !important;
    background-color: var(--backgroundHoveredNew) !important;
  }

  @media (min-width: 700px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 960px) {
    max-width: 327px;
    margin-left: initial;
  }
`;

let RightBlock = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 960px) {
    min-width: 417px;
    width: 417px;
    margin-right: 32px;
  }
`;

let SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  padding-top: 26px;
  height: 100%;
  background-color: var(--backgroundSecondary3New);
`;

let PhoneImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
`;

let ScreensWrapper = styled.div`
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 5px;

  img {
    width: 254px;
    margin-top: 1px;
  }
`;

let StepInfo = styled.div`
  padding: 24px 24px 24px 24px;
`;

let StepTitle = styled.div`
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  display: block;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    width: 100%;
    white-space: nowrap;
  }
`;

let StepText = styled.div`
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  opacity: 75%;

  @media (min-width: 700px) {
    width: 100%;
  }
`;

let BlobStep = styled.div`
  color: var(--iconsPrimary);
  opacity: 0.5;
  width: 70px;

  @media (min-width: 960px) {
    width: 90px;
  }
`;

let IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: black;
    }
  }
`;

let ButtonLeftWrapper = styled.div`
  position: relative;
  height: 100%;

  ${BlobStep} {
    transform: scaleX(-1);
  }

  ${IconWrapper} {
    transform: rotate(180deg);
  }
`;

let ButtonRightWrapper = styled.div`
  right: 0;
  height: 100%;

  ${BlobStep} {
    transform: scaleX(1);
  }
`;
