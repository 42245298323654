import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';
import styled from 'styled-components';

export default function MobileSupportChatButton() {
  return (
    <MobileSupportIconWrapper onClick={handleZendeskOpenChatClick}>
      <SupportIcon />
    </MobileSupportIconWrapper>
  );
}

let MobileSupportIconWrapper = styled.button`
  border: 1px solid var(--strokeBrand);
  background-color: var(--backgroundSecondary);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;
