import styled from 'styled-components';
import Blob from './Icons/LargeBlob';
import CoinIcon from './Icons/CoinIcon';
import HotspotIcon from './Icons/HotspotIcon';
import InternetIcon from './Icons/InternetIcon';
import VideoIcon from './Icons/VIdeoIcon';
import { scrollToMainInput } from './HomePage';
import Button from '@front_common/Components/Button/Button';

export default function WhatYouGet() {
  return (
    <WhatYouGetWrapper>
      <Header>
        What <ColoredText>you get</ColoredText>
      </Header>
      <Cards>
        <CardStyled>
          <BlobWrapper>
            <HotspotIcon />
            <Blob1 />
          </BlobWrapper>
          <CardHeader>Hotspot capabilities:</CardHeader>
          <span>Connect multiple devices with stable, fast internet.</span>
        </CardStyled>
        <CardStyled>
          <BlobWrapper>
            <VideoIcon />
            <Blob2 />
          </BlobWrapper>
          <CardHeader>Support for iMessage and FaceTime:</CardHeader>
          <span>Stay connected with family and friends.</span>
        </CardStyled>
        <CardStyled>
          <BlobWrapper>
            <InternetIcon />
            <Blob3 />
          </BlobWrapper>
          <CardHeader>High-speed connections:</CardHeader>
          <span>In any location, ensuring reliable internet access.</span>
        </CardStyled>
        <CardStyled>
          <BlobWrapper>
            <CoinIcon />
            <Blob4 />
          </BlobWrapper>
          <CardHeader>Zero roaming fees:</CardHeader>
          <span>
            Enjoy your travels without the worry of high roaming charges.
          </span>
        </CardStyled>
      </Cards>
      <CTAButton onClick={scrollToMainInput} sizeParam="L">
        Get started
      </CTAButton>
    </WhatYouGetWrapper>
  );
}

let WhatYouGetWrapper = styled.div`
  padding: 40px 0 20px;
  text-align: center;

  @media (min-width: 700px) {
    padding: 52px 0;
  }
`;

let ColoredText = styled.span`
  color: var(--textSelected);
`;

let CTAButton = styled(Button)`
  width: 100%;
  margin: 24px auto 0;

  @media (min-width: 700px) {
    width: 500px;
    margin: 40px auto 0;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;

let Header = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: 700px) {
    font-size: 52px;
    line-height: 38px;
    margin-bottom: 40px;
  }
`;

let Cards = styled.div`
  display: inline-grid;
  gap: 8px;
  margin: 0 auto;

  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (min-width: 1457px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

let CardStyled = styled.div`
  padding: 20px;
  background-color: var(--backgroundSecondary);
  border-radius: 20px;
  text-align: initial;

  @media (min-width: 700px) {
    height: 200px;
    width: 305px;
  }
`;

let CardHeader = styled.b`
  display: block;
  margin-bottom: 4px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
`;

let BlobWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 48px;
  margin: 0 auto;
  margin-bottom: 12px;

  svg {
    &:first-child {
      z-index: 1;
      position: relative;
    }
  }
`;

let Blob1 = styled(Blob)`
  width: 52px;
  height: 40px;
  color: var(--orange90);
  position: absolute;
  z-index: 0;
  left: 13px;
  top: -10px;
`;

let Blob2 = styled(Blob1)`
  color: var(--cardsBackgroundLightBlueSecondary);
  transform: scaleX(-1) scaleY(-1);
`;

let Blob3 = styled(Blob1)`
  color: var(--green90);
  transform: scaleX(-1);
  left: -15px;
`;

let Blob4 = styled(Blob1)`
  color: var(--purple100);
  transform: scaleY(-1);
  left: -15px;
`;
