/* eslint-disable max-lines */
import { ChangeEvent } from 'react';
import { MutableRef, useEffect, useRef, useState } from 'preact/hooks';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import {
  countryNameByCode,
  GLOBAL_COUNTRY_SEARCH_KEY,
} from '@shared_backend/Module/Geo/CountryNames';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_HOME_ALL_COUNTRIES,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { POPULAR_COUNTRIES } from '@shared_frontend/Module/Home/PopularCountries';
import Api from '@front_common/Api/Api';
import {
  LinkToRoute,
  routeChangeWithoutRerender,
} from '@front_common/Router/Router';
import VisitorApi, {
  useCountriesActiveQuery,
} from '@front_common/Api/VisitorApi';
import Flag from '@front_common/Components/Flag/Flag';
import Button from '@front_common/Components/Button/Button';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import { setCanonicalHeader } from '@front_common/Seo/SeoHeaders';
import DownloadApp from '@front_common/Components/DownloadApp/NewDesign/DownloadApp';
import PromoteBanner from '@front_common/Components/PromoteBanner/NewDesign/PromoteBanner';
import { TextSearchInputContainer } from '@front_common/Components/TextSearchInput/TextSearchInputContainer';
import { useSessionData } from '@front_common/Api/Session';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import LargeHeaderWithCustomers from './LargeHeaderWithCustomers';
import BulletPointsComp from './BulletPointsComp';
import HowEsimWorks from './HowEsimWorks/HowEsimWorks';
import FAQ from './FAQ';
import RiskFree from './RiskFree';
import WeGotYouCovered from './WeGotYouCovered';
import WhatYouGet from './WhatYouGet';
import RightArrowSVG from './Icons/RightArrowSVG';
import SearchSVG from './Icons/SearchSVG';
import { getESimCountryUrlByCountryCode } from '../../CountryStore/NewDesign/CountryStoreUtils';
import HomeFeedbacks from './HomeFeedbacks/HomeFeedbacks';
import GlobalImage from '../NewDesign/Images/World.png';
import styled from 'styled-components';

const DEFAULT_LIST_TITLE = 'Popular countries';
const LIST_TITLE = 'All countries';
const POPULAR_COUNTRIES_LENGTH = 12;

let inputElement: MutableRef<HTMLInputElement | null>;

function debounceChangeInput(func: (value: string) => void, delay: number) {
  let timer: NodeJS.Timeout;

  return (value: string) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(value), delay);
  };
}

function filterPopularCountriesWithLocation(
  countries: string[],
  locationCountry: string,
  limit?: number,
): string[] {
  let filteredCountries = countries.filter(
    (country) => country !== locationCountry,
  );

  filteredCountries.unshift(locationCountry);
  return limit ? filteredCountries.slice(0, limit) : filteredCountries;
}

function getAllCountriesList(countries: { [key: string]: string }) {
  return [
    ...POPULAR_COUNTRIES,
    ...Object.keys(countries ?? {}).filter(
      (item) => !POPULAR_COUNTRIES.includes(item),
    ),
  ]; //for always display country list with the same start/popular countries
}

export default function HomePage() {
  let currentRoute = useRouterState();
  let sessionData = useSessionData();

  let inputRef = useRef<HTMLInputElement>(null);
  inputElement = inputRef;

  let [isAllCountriesShowed, setIsAllCountriesShowed] = useState(
    currentRoute.path === WEB_ROUTE_HOME_ALL_COUNTRIES,
  );

  let [allActiveCountries, error] = useCountriesActiveQuery();

  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  let [countries, setCountries] = useState<string[]>(POPULAR_COUNTRIES);
  let [listTitle, setListTitle] = useState(DEFAULT_LIST_TITLE);

  useEffect(() => {
    let currentTitle = isAllCountriesShowed ? LIST_TITLE : DEFAULT_LIST_TITLE;

    setListTitle(currentTitle);
  }, [isAllCountriesShowed]);

  useEffect(() => {
    if (sessionData?.country && allActiveCountries) {
      let countriesList = getAllCountriesList(allActiveCountries);

      setCountries(
        filterPopularCountriesWithLocation(
          countriesList,
          sessionData?.country,
          isAllCountriesShowed ? undefined : POPULAR_COUNTRIES_LENGTH,
        ),
      );
    }
  }, [sessionData, isAllCountriesShowed, allActiveCountries]);

  let debouncedHandleTypo = debounceChangeInput(async (value: string) => {
    if (value.length < 2) {
      let limitCountries = isAllCountriesShowed
        ? undefined
        : POPULAR_COUNTRIES_LENGTH;
      let title = isAllCountriesShowed ? LIST_TITLE : DEFAULT_LIST_TITLE;
      setListTitle(title);

      if (sessionData?.country && allActiveCountries) {
        setCountries(
          filterPopularCountriesWithLocation(
            getAllCountriesList(allActiveCountries),
            sessionData?.country,
            limitCountries,
          ),
        );
      }

      return;
    }

    if (!allActiveCountries) {
      return;
    }

    let foundCountries = await searchCountriesByNamePart(
      value,
      allActiveCountries,
    );

    if (foundCountries.length === 0) {
      setCountries([]);
      setListTitle('No results by search');
    } else {
      setListTitle('Search results');
      let result =
        sessionData?.country && foundCountries.includes(sessionData?.country)
          ? filterPopularCountriesWithLocation(
              foundCountries,
              sessionData.country,
            )
          : foundCountries;
      setCountries(result);
    }
  }, 500);

  function hideAllCountries() {
    routeChangeWithoutRerender(WEB_ROUTE_HOME);
    setIsAllCountriesShowed(false);
    setListTitle(DEFAULT_LIST_TITLE);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    Api.trackAction(ActionTypes.button_home_show_all_countries);
  }

  function displayAllCountries() {
    routeChangeWithoutRerender(WEB_ROUTE_HOME_ALL_COUNTRIES);
    setIsAllCountriesShowed(true);
    setListTitle(LIST_TITLE);
    setCanonicalHeader(window.location.href);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    Api.trackAction(ActionTypes.button_home_show_all_countries);
  }

  function handleShowCountriesClick(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (isAllCountriesShowed) {
      let scrollTimeout: NodeJS.Timeout;
      let time =
        // eslint-disable-next-line unicorn/no-nested-ternary
        window.scrollY > 12_000 ? 900 : window.scrollY > 6000 ? 800 : 700;

      // change text before animation ends
      setIsAllCountriesShowed(false);
      scrollTimeout = setTimeout(() => {
        hideAllCountries();
        clearTimeout(scrollTimeout);
      }, time);
      scrollToPopularCountries();
    } else {
      displayAllCountries();
    }
    return false;
  }

  return (
    <LandingWrapper>
      <PromoteBanner />
      <HeaderBackGround>
        <HeaderWrapper>
          <LargeHeaderWithCustomers />
          <FindCountryInputWrapper>
            <TextSearchInputContainer
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                debouncedHandleTypo(event.currentTarget.value)
              }
              ref={inputRef}
              placeholder="Search 190+ Countries for data packages"
              customIcon={<SearchSVG />}
            />
          </FindCountryInputWrapper>
        </HeaderWrapper>
      </HeaderBackGround>

      <ContentWrapper>
        <CountriesBlock>
          <Subtitle>{listTitle}</Subtitle>
          {sessionData ? (
            <>
              <CountriesWrapper $fix={isAllCountriesShowed}>
                {countries.map((countryCode) =>
                  countryCode === GLOBAL_COUNTRY_SEARCH_KEY ? (
                    <GlobalPlan
                      href={`${WEB_ROUTE_STORE_GLOBAL}/`}
                      key="global"
                    >
                      <GlobalImageWrapper>
                        <img
                          width={32}
                          height={32}
                          src={GlobalImage}
                          alt="glbal plan icon"
                        />
                      </GlobalImageWrapper>
                      Global plan
                      <IconWrapper>
                        <RightArrowSVG />
                      </IconWrapper>
                    </GlobalPlan>
                  ) : (
                    <Country
                      key={countryCode}
                      href={getESimCountryUrlByCountryCode(countryCode)}
                    >
                      <FlagWrapper>
                        <FlagStyled
                          circle={true}
                          countryCode={countryCode}
                          width={32}
                          height={32}
                          style={{
                            objectFit: 'cover',
                            marginRight: 0,
                            boxShadow: 'inset 0 0 3px #21252a',
                          }}
                        />
                      </FlagWrapper>
                      {countryNameByCode(countryCode)}
                      <IconWrapper>
                        <RightArrowSVG />
                      </IconWrapper>
                    </Country>
                  ),
                )}
              </CountriesWrapper>
              <ShowAllCountriesContainer
                href={
                  isAllCountriesShowed
                    ? WEB_ROUTE_HOME
                    : `${WEB_ROUTE_HOME_ALL_COUNTRIES}/`
                }
                onClick={handleShowCountriesClick}
              >
                <ShowAllCountriesButton sizeParam="L">
                  {isAllCountriesShowed
                    ? 'Show popular countries'
                    : 'Show all countries'}
                </ShowAllCountriesButton>
              </ShowAllCountriesContainer>
            </>
          ) : (
            <LoadingAnimation />
          )}
        </CountriesBlock>

        <BulletPointsComp />

        <RiskFree />

        <HowEsimWorks />

        <WeGotYouCovered />

        <WhatYouGet scrollToMainInput={scrollToMainInput} />

        <FeedbackReviewsWrapper>
          <Title>Our users’ feedback</Title>
          <HomeFeedbacks />
        </FeedbackReviewsWrapper>

        <FAQ />

        <DownloadApp />
      </ContentWrapper>
    </LandingWrapper>
  );
}

export async function scrollToMainInput() {
  if (!inputElement) {
    throw new Error('Called scroll too early');
  }

  inputElement.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  inputElement.current?.focus({ preventScroll: true });

  Api.trackAction(ActionTypes.button_home_cta);
}

function scrollToPopularCountries() {
  window.scrollTo({ top: 199, behavior: 'smooth' });
}

async function searchCountriesByNamePart(
  namePart: string,
  allActiveCountries: { [isoCode: string]: string },
): Promise<string[]> {
  let search = namePart.toUpperCase().trim().replaceAll(/s+/gu, ' ');
  let response = await VisitorApi.getCountriesByQuerySearch(search);

  let priority1 = [];
  let priority2 = [];
  let priority3 = [];

  for (let code of response) {
    let name = allActiveCountries[code]?.toUpperCase();

    if (name && name.startsWith(search)) {
      priority1.push(code);
      continue;
    }
    if (name && name.includes(' ' + search)) {
      priority2.push(code);
      continue;
    }
    priority3.push(code);
  }
  return [...priority1, ...priority2, ...priority3];
}

let LandingWrapper = styled.div`
  font-size: var(--fontSizeBig);
  color: var(--textPrimaryNew);
  font-family: var(--fontFamilyNew);
`;

let Subtitle = styled.h2`
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyNew);
`;

let HeaderBackGround = styled.div`
  padding-top: 64px;
  background-color: var(--backgroundPrimaryNew);

  @media (min-width: 960px) {
    padding-top: 64px;
    height: 384px;
  }
`;

let HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 32px 16px;
  row-gap: 24px;

  @media (min-width: 700px) {
    padding: 32px;
  }

  @media (min-width: 960px) {
    justify-content: flex-end;
    height: 320px;
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 32px 32px 32px;
  }

  @media (min-width: 1104px) {
    padding: 0 0 32px 0;
  }
`;

let ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundSecondary);
`;

let CountriesBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  @media (min-width: 700px) {
    row-gap: 24px;
    padding: 32px;
  }

  @media (min-width: 960px) {
    width: 100%;
    padding: 32px 32px 48px 32px;
    max-width: 1040px;
    margin: 0 auto;
  }

  @media (min-width: 1104px) {
    padding: 32px 0 48px 0;
  }
`;

let FindCountryInputWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;

let CountriesWrapper = styled.div<{ $fix: boolean }>`
  height: ${({ $fix }) => ($fix ? 'initial' : '316px')};
  overflow: ${({ $fix }) => ($fix ? 'initial' : 'hidden')};
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  @media (min-width: 700px) {
    height: inherit;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
  }

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

let GlobalPlan = styled(LinkToRoute)`
  width: 100%;
  padding: 0 16px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 56px;
  text-decoration: none;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
  background-color: var(--backgroundSecondary3New);

  &:hover {
    background-color: var(--strokeSecondary);
  }
`;

const GlobalImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;

  img {
    position: absolute;
    width: 32px;
    height: 32px;
  }
`;

let Country = styled(LinkToRoute)`
  width: 100%;
  padding: 0 16px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 56px;
  text-decoration: none;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
  background-color: var(--backgroundSecondary3New);

  &:hover {
    background-color: var(--strokeSecondary);
  }
`;

let FlagWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 16px;
  border: 1px solid var(--strokePrimaryNew);
`;

let FlagStyled = styled(Flag)`
  display: block;
  width: 30px;
  height: 30px;
`;

let IconWrapper = styled.div`
  margin-left: auto;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.4;
  }
`;

let ShowAllCountriesButton = styled(Button)`
  margin: 0 auto;
  width: 100%;
  position: sticky;
  background-color: var(--backgroundSecondary);
  color: var(--textPrimaryNew);
  border: 1px solid var(--strokeBrandNew);

  &:hover {
    color: var(--black100);
    border: 1px solid var(--black100);
    background-color: var(--backgroundHoveredNew) !important;
  }

  @media (min-width: 700px) {
    position: initial;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;

let ShowAllCountriesContainer = styled.a`
  text-decoration: none;
  display: block;
  margin: 0 auto;
  width: 100%;
  position: sticky;
  bottom: 40px;

  @media (min-width: 700px) {
    width: 100%;
    position: initial;
  }

  @media (min-width: 960px) {
    width: 236px;
  }
`;

let FeedbackReviewsWrapper = styled.div`
  padding: 16px 16px 32px 16px;
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    padding: 32px 32px 64px 32px;
  }

  @media (min-width: 960px) {
    padding: 32px 0 33px 0;
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let Title = styled.h2`
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
`;
