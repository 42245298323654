import React, { forwardRef, useState, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextSearchInputProps extends HTMLAttributes<HTMLInputElement> {
  customIcon: React.ReactNode;
  placeholder?: string;
}

// eslint-disable-next-line react/display-name
let TextSearchInput = forwardRef<HTMLInputElement, TextSearchInputProps>(
  (props, ref) => {
    let { placeholder = '', customIcon, ...rest } = props;

    let [isActive, setIsActive] = useState(false);

    function handleFocus() {
      setIsActive(true);
    }

    function handleBlur() {
      setIsActive(false);
    }

    return (
      <InputContainer>
        <InputLabel $active={isActive}>{placeholder}</InputLabel>
        <InputWrapper>
          <IconWrapper>{customIcon}</IconWrapper>
          {/*@ts-expect-error  @TODO fix this pls */}
          <StyledInput
            ref={ref}
            type="text"
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rest}
          />
        </InputWrapper>
      </InputContainer>
    );
  },
);

export default TextSearchInput;

let InputContainer = styled.div`
  position: relative;
`;

let InputLabel = styled.label<{ $active: boolean }>`
  position: absolute;
  top: ${({ $active }) => ($active ? '-20px' : '10px')};
  left: 0;
  transition: 0.2s;
  font-size: ${({ $active }) => ($active ? '14px' : '10px')};
  color: var(--textSecondary);
  pointer-events: none;
`;

let InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

let StyledInput = styled.input`
  padding: 0 16px;
  border-radius: var(--borderRadiusPrimary);
  padding-left: 50px;
  width: 100%;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightRegular);
  border: 1px solid var(--strokePrimary);
  height: 56px;
  outline: none;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--textSecondary);
  }

  &:hover {
    border: 1px solid var(--strokeBrand);
  }

  &:focus {
    border: 1px solid var(--strokeBrand);
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

let IconWrapper = styled.div`
  position: absolute;
  left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
`;
