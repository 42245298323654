export default function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0011 1.66919C7.79236 1.67168 5.67469 2.54959 4.11201 4.1105C2.54933 5.67141 1.66906 7.78808 1.66406 9.99679C1.66739 12.2069 2.54683 14.3255 4.1096 15.8883C5.67237 17.451 7.79098 18.3305 10.0011 18.3338C12.2103 18.3296 14.3278 17.4497 15.8894 15.8869C17.451 14.3241 18.3293 12.206 18.3318 9.99679C18.3293 7.78811 17.4508 5.67063 15.889 4.10886C14.3272 2.54709 12.2097 1.66852 10.0011 1.66602V1.66919ZM10.0011 3.33223C10.877 3.32973 11.7447 3.5004 12.5544 3.83445C13.3641 4.16849 14.0998 4.65926 14.7192 5.27862C15.3386 5.89798 15.8294 6.63369 16.1634 7.4434C16.4975 8.25311 16.6681 9.12088 16.6656 9.99679C16.6681 10.873 16.4975 11.7411 16.1635 12.5511C15.8296 13.3612 15.3388 14.0972 14.7196 14.7171C14.1003 15.3369 13.3647 15.8283 12.5549 16.1631C11.7452 16.4978 10.8773 16.6693 10.0011 16.6676C9.12484 16.6688 8.25698 16.4971 7.44724 16.1623C6.63751 15.8275 5.90184 15.3361 5.28239 14.7164C4.66295 14.0967 4.17192 13.3607 3.83746 12.5508C3.50301 11.741 3.3317 10.873 3.33335 9.99679C3.33335 6.30545 6.30973 3.33223 10.0011 3.33223ZM10.0011 5.00151C9.78021 5.00233 9.56854 5.08998 9.41178 5.24556C9.25502 5.40114 9.16576 5.61219 9.16328 5.83303C9.16328 6.28658 9.54753 6.66763 10.0011 6.66763C10.2224 6.66763 10.4347 6.57973 10.5913 6.4232C10.7478 6.26668 10.8357 6.0544 10.8357 5.83303C10.8357 5.61167 10.7478 5.3993 10.5913 5.24277C10.4347 5.08625 10.2224 4.99834 10.0011 4.99834V5.00151ZM9.98847 8.31798C9.96004 8.31863 9.93165 8.32078 9.90343 8.32433C9.69719 8.34779 9.50709 8.44729 9.37023 8.60336C9.23338 8.75943 9.15959 8.9609 9.16328 9.16844V14.17C9.16328 14.3913 9.25121 14.6037 9.40774 14.7602C9.56426 14.9167 9.77656 15.0046 9.99792 15.0046C10.2193 15.0046 10.4316 14.9167 10.5881 14.7602C10.7446 14.6037 10.8326 14.3913 10.8326 14.17V9.16844C10.8347 9.05671 10.8143 8.94564 10.7727 8.84192C10.7312 8.73821 10.6692 8.64393 10.5904 8.56462C10.5117 8.48531 10.4179 8.42263 10.3145 8.38027C10.2111 8.33791 10.1002 8.3167 9.98847 8.31798Z"
        fill="black"
      />
    </svg>
  );
}
