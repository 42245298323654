import { WEB_ROUTE_STORE_GLOBAL } from '@shared_frontend/Common/WebRoutes';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { useSubscriptionProducts } from '@front_common/Api/VisitorApi';
import FakeBreadcrumbs from '../FakeBreadcrumbs/FakeBreadcrumbs';
import { PaymentProvider } from '../PaymentContext';
import CheckoutPageContent from './CheckoutPageContent';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import styled from 'styled-components';

//TODO: you can get the planId from the component's params, so you don't need to use the router
export default function CheckoutPage() {
  let router = useRouterState();
  let planId = router.matches?.['planId'] || '';

  let [plans, _error, isLoading] = useSubscriptionProducts();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  let subscription = plans?.find((plan) => plan.id === Number(planId));

  if (!subscription) {
    logger.log('404: Subscription is not defined');
    throw new Error('404: Subscription is not defined');
  }

  return (
    <>
      <FakeBreadcrumbsWrapper>
        <FakeBreadcrumbs
          prevLink={WEB_ROUTE_STORE_GLOBAL}
          prevName="Global"
          currentLink="Billing details"
        />
      </FakeBreadcrumbsWrapper>
      <PaymentProvider subscription={subscription}>
        <CheckoutPageContent />
      </PaymentProvider>
    </>
  );
}

let FakeBreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;
