import styled from 'styled-components';

export default function ErrorBlock({ message }: { message: string }) {
  return <Wrapper>{message}</Wrapper>;
}

let Wrapper = styled.div`
  border: 1px solid var(--errorButtonDanger);
  color: var(--errorButtonDanger);
  padding: 14px 16px;
  margin: 20px auto;
  max-width: 800px;
  border-radius: var(--borderRadiusPrimary);
`;
