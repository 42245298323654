import { useEffect, useRef, useState } from 'preact/hooks';

export default function useAnimatedNumber(targetNumber: number) {
  let [displayNumber, setDisplayNumber] = useState(targetNumber);
  let isFirstRender = useRef(true); // Track the first render

  useEffect(() => {
    let animationIntervalId: NodeJS.Timeout;

    if (isFirstRender.current) {
      // On the first render set the number without animation
      setDisplayNumber(targetNumber);
      isFirstRender.current = false;
    } else {
      // For next renders, animate the number change
      let currentNumber = displayNumber;
      let duration = 100; // Duration for the number animation in milliseconds
      let frameRate = 60; // Frame rate for the animation
      let totalFrames = Math.round((duration / 1000) * frameRate);
      let increment = (targetNumber - currentNumber) / totalFrames;

      let frame = 0;
      animationIntervalId = setInterval(() => {
        if (frame < totalFrames) {
          currentNumber += increment;
          setDisplayNumber(Math.round(currentNumber));
          frame++;
        } else {
          clearInterval(animationIntervalId);
        }
      }, 1000 / frameRate);
    }

    return () => clearInterval(animationIntervalId);
  }, [targetNumber, displayNumber]);

  return displayNumber;
}
