import styled from 'styled-components';
import InfoCircleIcon from './InfoCircleIcon';
import { useState } from 'preact/hooks';
import { ReactElement } from 'preact/compat';

type TooltipProps = {
  tooltipContent: ReactElement | string;
};

export default function Tooltip({ tooltipContent }: TooltipProps) {
  let [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  return (
    <InfoTooltipWrapper
      onMouseEnter={() => setIsVisibleTooltip(true)}
      onMouseLeave={() => setIsVisibleTooltip(false)}
    >
      <InfoCircleIcon />
      {isVisibleTooltip && (
        <InfoTooltipStyled>
          <TooltipText>{tooltipContent}</TooltipText>
        </InfoTooltipStyled>
      )}
    </InfoTooltipWrapper>
  );
}

let InfoTooltipWrapper = styled.span`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  vertical-align: sub;
`;

let InfoTooltipStyled = styled.div`
  padding: 8px;
  width: 189px;
  background-color: var(--backgroundSecondary);
  color: var(--textPrimary);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px -2px #0000001a;
  position: absolute;
  bottom: 28px;
  right: -86px;

  &::after {
    content: '';
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -8px;
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

let TooltipText = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: var(--fontWeightRegular);
`;
