import styled from 'styled-components';
import { GuideCard, GuideStep } from '../GuideCard';
import { CurrentPlan } from '../CurrentPlan';
import Warning from '../Warning';
import { QRCodeImage } from '@front_common/Components/QRCodeBox';
import Card from '@front_common/Components/Card';

import AppleQRInstallS1S1 from './Images/Qr/AppleQRInstallS1S1.png';
import AppleQRInstallS1S2 from './Images/Qr/AppleQRInstallS1S2.png';

import AppleQRInstallS2S1 from './Images/Qr/AppleQRInstallS2S1.png';
import AppleQRInstallS2S2 from './Images/Qr/AppleQRInstallS2S2.png';
import AppleQRInstallS2S3 from './Images/Qr/AppleQRInstallS2S3.png';
import AppleQRInstallS2S4 from './Images/Qr/AppleQRInstallS2S4.png';

import AppleQRInstallS3S1 from './Images/Qr/AppleQRInstallS3S1.png';
import AppleQRInstallS3S2 from './Images/Qr/AppleQRInstallS3S2.png';
import AppleQRInstallS3S3 from './Images/Qr/AppleQRInstallS3S3.png';
import AppleQRInstallS3S4 from './Images/Qr/AppleQRInstallS3S4.png';

import AppleQRInstallS4S1 from './Images/Qr/AppleQRInstallS4S1.png';

import AppleQRActivationS1S1 from './Images/Qr/AppleQRActivationS1S1.png';
import AppleQRActivationS1S2 from './Images/Qr/AppleQRActivationS1S2.png';
import AppleQRActivationS1S3 from './Images/Qr/AppleQRActivationS1S3.png';

import AppleQRActivationS2S1 from './Images/Qr/AppleQRActivationS2S1.png';
import AppleQRActivationS2S2 from './Images/Qr/AppleQRActivationS2S2.png';

const ACTIVATION_STEPS: GuideStep[] = [
  {
    stepNumber: 1,
    title: 'Enable Data Roaming',
    mainText: (
      <ul>
        <li>{`Tap on your newly added eSIM plan in the "Mobile Service" ("Mobile Data" for iOS 16 and earlier versions).`}</li>
        <li>{`And turn on "Data Roaming" with the help of the toggle.`}</li>
      </ul>
    ),
    images: [
      AppleQRActivationS1S1,
      AppleQRActivationS1S2,
      AppleQRActivationS1S3,
    ],
  },
  {
    stepNumber: 2,
    title: 'Make sure eSIM is a preferred data plan',
    mainText: (
      <ul>
        <li>{`Go back to "Mobile Service" and then go to "Mobile Data".`}</li>
        <li>{`Click on your newly installed eSIM plan to use it as a source of internet data.`}</li>
      </ul>
    ),
    images: [AppleQRActivationS2S1, AppleQRActivationS2S2],
  },
];

type AppleQRTabContentProps = {
  esimId: string | undefined;
  forGeneralPage: boolean;
};

export function AppleQRTabContent(props: AppleQRTabContentProps) {
  let { esimId, forGeneralPage } = props;

  let INSTALL_STEPS: GuideStep[] = [
    {
      stepNumber: 1,
      title: 'Use Haptic Touch or your Camera',
      mainText: (
        <ul>
          <li>
            {`Hard press on the QR code and tap on "Add eSIM" option in the list. (*works only in Safari on iOS devices).`}
          </li>
          <li>Alternatively, scan the QR code with your camera.</li>
          {forGeneralPage ? (
            <li>
              QR code can be found in the email or the installation guide, which
              is provided after your purchase.
            </li>
          ) : (
            <li>QR code can be found above or in your e-mail.</li>
          )}
        </ul>
      ),
      images: [AppleQRInstallS1S1, AppleQRInstallS1S2],
    },
    {
      stepNumber: 2,
      title: 'Install your eSIM',
      mainText: `Follow on-screen instructions to install your eSIM.`,
      images: [
        AppleQRInstallS2S1,
        AppleQRInstallS2S2,
        AppleQRInstallS2S3,
        AppleQRInstallS2S4,
      ],
    },
    {
      stepNumber: 3,
      title: 'Complete installation',
      mainText: (
        <ul>
          <li>
            Select your personal SIM as your Default line for calls and sms.
          </li>
          <li>Select your personal SIM for iMessages & FaceTime.</li>
          <li>{`Select your new eSIM as the source for "Mobile Data".`}</li>
          <li>{`Click "Update later" for the preferred line.`}</li>
        </ul>
      ),

      images: [
        AppleQRInstallS3S1,
        AppleQRInstallS3S2,
        AppleQRInstallS3S3,
        AppleQRInstallS3S4,
      ],
    },
    {
      stepNumber: 4,
      title: 'Confirm successful installation',
      mainText: `If eSIM is installed successfully, you will see it in your "SIMs" list in "Mobile Service"  ("Mobile Data" for iOS 16 and earlier versions) with the line turned "On".`,
      images: [AppleQRInstallS4S1],
    },
  ];

  return (
    <div>
      <PaddingWrapper>
        <Warning text="You need to be connected to the internet to install the eSIM." />
        {esimId && (
          <CardStyled>
            <CurrentPlan title="Your QR-code for" esimId={esimId} />
            <QRWrapper>
              <QRCodeImage id={esimId} />
            </QRWrapper>
            <Note>
              *Take a picture with another phone to scan the code or open the
              email we sent with the QR code inside.
            </Note>
          </CardStyled>
        )}
      </PaddingWrapper>
      {INSTALL_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={INSTALL_STEPS.length === index + 1}
        />
      ))}
      <PaddingWrapper>
        <ActivationTitle $forGeneralPage={forGeneralPage}>
          Activation
        </ActivationTitle>
        <Warning text="The following steps should be done once you arrive at your destination, to ensure your eSIM's validity starts correctly." />
      </PaddingWrapper>
      {ACTIVATION_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={ACTIVATION_STEPS.length === index + 1}
        />
      ))}
    </div>
  );
}

let PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin-left: auto;
    margin-right: auto;
  }
`;

let CardStyled = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

let Note = styled.span`
  font-size: 12px;
  line-height: 24px;
`;

let QRWrapper = styled.div`
  height: 120px;
  width: 120px;
`;

let ActivationTitle = styled.h3<{ $forGeneralPage: boolean }>`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: ${(props) => (props.$forGeneralPage ? 'center' : 'left')};
  margin-top: 32px;
  font-size: ${(props) => (props.$forGeneralPage ? '24px' : '34px')};
  line-height: ${(props) => (props.$forGeneralPage ? '32px' : '40px')};

  @media (min-width: 700px) {
    text-align: center;
    margin-top: 40px;
    font-size: ${(props) => (props.$forGeneralPage ? '28px' : '34px')};
    line-height: ${(props) => (props.$forGeneralPage ? '38px' : '40px')};
  }

  @media (min-width: 960px) {
    margin-top: 56px;
    font-size: 34px;
    line-height: 40px;
  }
`;
