import { useState } from 'react';
import styled from 'styled-components';

type TabProps = {
  tabs: string[];
  onTabClick: (tabIndex: number) => void;
  containerClassName?: string;
};

export function NavigationTabs(props: TabProps) {
  let { tabs, onTabClick, containerClassName } = props;

  let [activeTabIndex, setActiveTabIndex] = useState(0);

  function handleTabClick(index: number) {
    onTabClick(index);
    setActiveTabIndex(index);
  }

  return (
    <TabContainer className={containerClassName}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          $isActive={activeTabIndex === index}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </Tab>
      ))}
    </TabContainer>
  );
}

let TabContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--strokeSecondary);
`;

let Tab = styled.div<{ $isActive: boolean }>`
  height: 40px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$isActive
      ? '2px solid var(--tabBarActive)'
      : '1px solid transparent'};
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: ${(props) =>
    props.$isActive ? 'var(--tabBarActive)' : 'var(--textPrimary)'};
  padding-top: 12px;
  padding-left: 7px;
  padding-right: 7px;
  line-height: 16px;
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  white-space: nowrap;
  position: relative;
  bottom: -1px;

  @media (min-width: 393px) {
    height: 44px;
    line-height: 20px;
    font-size: var(--fontSizeSmall);
  }
`;
