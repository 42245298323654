//TODO: we need to try change these routes to canonical urls => "/all-countries/"
export const WEB_ROUTE_HOME = '/';
export const WEB_ROUTE_HOME_ALL_COUNTRIES = '/all-countries';
export const WEB_ROUTE_TERMS_AND_CONDITIONS = '/toc';
export const WEB_ROUTE_PRIVACY_POLICY = '/privacy';
export const WEB_ROUTE_REFUND_POLICY = '/refund';
export const WEB_ROUTE_ACCEPTABLE_USE_POLICY = '/acceptable-use';
export const WEB_ROUTE_SUBSCRIPTION_POLICY = '/subscription-terms';
export const WEB_ROUTE_AUTH = '/auth';
export const WEB_ROUTE_MY_ESIMS = '/my/esims';
export const WEB_ROUTE_MY_ESIM = '/my/esims/:esimId';
export const WEB_ROUTE_MY_PROFILE = '/my/profile';
export const WEB_ROUTE_CHECKOUT = '/checkout/:bundleCodeAndPriceId';
export const WEB_ROUTE_MY_SUBSCRIPTIONS = '/my/subscriptions';
export const WEB_ROUTE_MY_SUBSCRIPTIONS_UNSUBSCRIBE =
  '/my/subscriptions/:subscriptionId/unsubscribe';
export const WEB_ROUTE_STORE_COUNTRY = '/esim/:countrySlug';
export const WEB_ROUTE_MY_INSTALL_GUIDE =
  '/my/esims/esim-installation-guide/:id';
export const WEB_ROUTE_HOW_TO_INSTALL_ESIM = '/how-to-install-esim';
export const WEB_ROUTE_COMPATIBLE_DEVICES = '/esim-compatible-devices';
export const WEB_ROUTE_FAQ = '/faq';
export const WEB_ROUTE_FAQ_TOPIC = '/faq/:topicId';
export const WEB_ROUTE_FAQ_QUESTION = '/faq/:topicId/:questionId';
export const WEB_ROUTE_WEBVIEW_PAYMENT = '/webview/payment';
export const WEB_ROUTE_WEBVIEW_PAYMENT_SUBS = '/webview/payment-subs';
export const WEB_ROUTE_APPLE_QR_CODE = '/apple-qr-code';
export const WEB_ROUTE_SUBSCRIPTION_SETTINGS = '/subscription-settings';
export const WEB_ROUTE_STRAPI_PREVIEW = '/strapi/preview';
export const WEB_ROUTE_STORE_GLOBAL = '/store/global';
export const WEB_ROUTE_GLOBAL_CHECKOUT = '/subscription-checkout/:planId';
export const WEB_ROUTE_BLOG = '/blog/';
