export default function SecureIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 100 100">
      <g>
        <path
          d="M50 45.8334C48.8949 45.8334 47.8351 46.2723 47.0537 47.0537C46.2723 47.8351 45.8333 48.895 45.8333 50C45.8449 50.7201 46.0429 51.4248 46.4081 52.0455C46.7732 52.6662 47.293 53.1817 47.9167 53.5417V56.25C47.9167 56.8026 48.1362 57.3325 48.5269 57.7232C48.9176 58.1139 49.4475 58.3334 50 58.3334C50.5525 58.3334 51.0824 58.1139 51.4731 57.7232C51.8638 57.3325 52.0833 56.8026 52.0833 56.25V53.5417C52.707 53.1817 53.2268 52.6662 53.5919 52.0455C53.9571 51.4248 54.1551 50.7201 54.1667 50C54.1667 48.895 53.7277 47.8351 52.9463 47.0537C52.1649 46.2723 51.1051 45.8334 50 45.8334ZM50 29.1667C48.3424 29.1667 46.7527 29.8252 45.5806 30.9973C44.4085 32.1694 43.75 33.7591 43.75 35.4167V37.5H56.25V35.4167C56.25 33.7591 55.5915 32.1694 54.4194 30.9973C53.2473 29.8252 51.6576 29.1667 50 29.1667Z"
          fill="currentColor"
        />
        <path
          d="M86.1252 12.5L50.7085 7.16746e-06C50.2634 -0.155601 49.7787 -0.155601 49.3335 7.17491e-06L13.8752 12.5C13.4518 12.6522 13.089 12.9374 12.8411 13.3129C12.5933 13.6885 12.4737 14.1342 12.5002 14.5833V39.5833C12.5002 71.9167 17.2919 83.75 49.0419 99.7917C49.3327 99.9382 49.6537 100.015 49.9794 100.015C50.305 100.015 50.6261 99.9382 50.9169 99.7917C82.7085 83.75 87.5002 71.9167 87.5002 39.5833V14.5833C87.5267 14.1342 87.4071 13.6885 87.1593 13.3129C86.9114 12.9374 86.5486 12.6522 86.1252 12.5ZM68.7502 62.5C68.7502 63.6051 68.3112 64.6649 67.5298 65.4463C66.7484 66.2277 65.6886 66.6667 64.5835 66.6667H35.4169C34.3118 66.6667 33.252 66.2277 32.4706 65.4463C31.6892 64.6649 31.2502 63.6051 31.2502 62.5V41.6667C31.2502 40.5616 31.6892 39.5018 32.4706 38.7204C33.252 37.939 34.3118 37.5 35.4169 37.5V35.4167C35.4169 31.5489 36.9533 27.8396 39.6882 25.1047C42.4231 22.3698 46.1325 20.8333 50.0002 20.8333C53.8679 20.8333 57.5773 22.3698 60.3122 25.1047C63.0471 27.8396 64.5835 31.5489 64.5835 35.4167V37.5C65.6886 37.5 66.7484 37.939 67.5298 38.7204C68.3112 39.5018 68.7502 40.5616 68.7502 41.6667V62.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
