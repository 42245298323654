import { useEffect, useState } from 'preact/hooks';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import TextInput from '@front_common/Components/TextInput/OldDesign/TextInput';
import UserApi from '@front_common/Api/UserApi';
import Button from '@front_common/Components/Button/Button';
import BadgeIcon from './Icons/BadgeIcon';
import CheckIcon from './Icons/CheckIcon';
import CrossIcon from './Icons/CrossIcon';
import { useDiscountPayment } from '../../DiscountPaymentContext';
import styled from 'styled-components';

//TODO: remove unused callbacks
type DiscountProps = {
  onDiscountPaymentSessionChange?: (
    discountPaymentSession: PaymentSessionEntityType | null,
  ) => void;
};

export function Discount(props: DiscountProps) {
  let { onDiscountPaymentSessionChange } = props;

  let {
    totalAmountCents,
    //TODO: use self state for discountAmountCents
    discountAmountCents,
    bundleCodeAndPriceId,
    targetCountry,
    resendDiscountRequest,
    setDiscountAmountCents,
    setDiscountPaymentSession,
    setResendDiscountRequest,
  } = useDiscountPayment();

  let [discountValue, setDiscountValue] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [discountErrorText, setDiscountErrorText] = useState('');

  useEffect(() => {
    if (resendDiscountRequest) {
      processDiscount();
      setResendDiscountRequest(false);
    }
  }, [resendDiscountRequest]);

  async function processDiscount(event?: Event) {
    event?.preventDefault();

    setIsLoading(true);
    setDiscountErrorText('');

    if (!discountValue) {
      setIsLoading(false);
      return;
    }

    try {
      let discountPaymentSession = await UserApi.applyDiscount(
        bundleCodeAndPriceId,
        targetCountry,
        discountValue,
      );

      let discountAmount =
        totalAmountCents - discountPaymentSession.totalAmountCents;
      setDiscountAmountCents(discountAmount);
      setDiscountPaymentSession(discountPaymentSession);
      if (onDiscountPaymentSessionChange) {
        onDiscountPaymentSessionChange(discountPaymentSession);
      }
    } catch (error) {
      if (error instanceof Error) {
        setDiscountErrorText(error.message);
      }
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onDiscountInput(event: Event) {
    // eslint-disable-next-line xss/no-mixed-html
    let target = event.target as HTMLInputElement;
    let newValue = target.value.trim();
    let upperCaseValue = newValue.toUpperCase();

    target.value = upperCaseValue;
    setDiscountValue(upperCaseValue);
  }

  function handleRemove() {
    setDiscountValue('');
    setDiscountErrorText('');
    setDiscountAmountCents(null);
    setDiscountPaymentSession(null);
    if (onDiscountPaymentSessionChange) {
      onDiscountPaymentSessionChange(null);
    }
  }

  return (
    <>
      <DiscountForm onSubmit={processDiscount}>
        <TextInputWrapper>
          <TextInput
            name="discountCode"
            type="text"
            onInput={onDiscountInput}
            value={discountValue}
            errorText={discountErrorText}
            placeholder="Discount code"
            disabled={isLoading || discountAmountCents !== null}
          />
          <RemoveButton type="button" onClick={handleRemove}>
            <CrossIcon />
          </RemoveButton>
        </TextInputWrapper>
        {discountAmountCents === null && !isLoading && (
          <ApplyButton
            disabled={Boolean(!discountValue)}
            sizeParam="L"
            typeStyle="Secondary"
            type="submit"
          >
            Apply
          </ApplyButton>
        )}
        {discountAmountCents !== null && (
          <ApplyButton
            sizeParam="L"
            typeStyle="Secondary"
            style={{
              pointerEvents: 'none',
            }}
          >
            <CheckIcon />
          </ApplyButton>
        )}
        {isLoading && (
          <ApplyButton
            sizeParam="L"
            typeStyle="Secondary"
            isLoading={isLoading}
          />
        )}
      </DiscountForm>
    </>
  );
}

export function DiscountTotal() {
  let { totalAmountCents, discountAmountCents } = useDiscountPayment();

  return (
    <div>
      {discountAmountCents !== null && (
        <DiscountInfo>
          <BadgeIcon />
          <span>Discount</span>
          <DiscountAmount>-${discountAmountCents / 100}</DiscountAmount>
        </DiscountInfo>
      )}
      <Total>
        <span>Total</span>
        {discountAmountCents ? (
          <span>${(totalAmountCents - discountAmountCents) / 100}</span>
        ) : (
          <span>${totalAmountCents / 100}</span>
        )}
      </Total>
    </div>
  );
}

let DiscountForm = styled.form`
  width: 100%;
  display: flex;
  gap: 16px;
`;

let ApplyButton = styled(Button)`
  width: 93px;
  min-width: 93px;
`;

let DiscountInfo = styled.div`
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: var(--fontSizeSmall);

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

let DiscountAmount = styled.span`
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  color: var(--errorButtonDanger);
  margin-left: auto;
  display: block;
`;

let TextInputWrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-right: 38px;
  }
`;

let RemoveButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 12px;
`;

let Total = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 28px;
`;
