import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import styled from 'styled-components';
import useESimPlan from '../useESimPlan';
import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import Button from '@front_common/Components/Button/Button';
import ESimPageActions from './ESimPageActions';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { useESimCardContext } from '../ESimContext';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import InstallModal from '../../InstallFlow/InstallModal';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import ESimPlanDetails from './ESimPlanDetails';
import { formatDateToShortString } from '../Utils';

type IProps = {
  onPlanDetailsClick: () => void;
  onInstallNowClick: Dispatch<StateUpdater<string | null>>;
};

export default function ESimPageMainInfo({
  onPlanDetailsClick,
  onInstallNowClick,
}: IProps) {
  let [showQrForId, setShowQrForId] = useState<string | null>(null);
  let [isPlanDetailsModalOpen, setIsPlanDetailsModalOpen] = useState(false);

  let { eSim } = useESimCardContext();

  let {
    content: { pageContent, tooltip, actions, loader },
    handleInstallNewSim,
  } = useESimPlan(onInstallNowClick);

  function handleClosePlaDetailsModal() {
    setIsPlanDetailsModalOpen(false);
  }

  if (!eSim.targetCountry) {
    // TODO process "null" better
    logger.log('no target country ' + eSim);
    return <>no target country</>;
  }

  return (
    <>
      <EsimTitleWrapper>
        <ESimTitle>
          <span>{getEsimPlanCountry(eSim.targetCountry)}</span>
          <IconBox>
            <EsimPlanFlag targetCountry={eSim.targetCountry} />
          </IconBox>
        </ESimTitle>
      </EsimTitleWrapper>
      <>{loader}</>
      <>{pageContent}</>
      <>{tooltip}</>
      {[EsimStatus.NOT_INSTALLED].includes(eSim.status) && (
        <ButtonStyled sizeParam="L" onClick={handleInstallNewSim}>
          Install now
        </ButtonStyled>
      )}
      <ESimPageActions
        eSimId={eSim.id}
        visibleActions={actions}
        onPlanDetailsClick={onPlanDetailsClick}
      />
      <InstallModal
        esimId={showQrForId || ''}
        show={Boolean(showQrForId)}
        onClose={() => setShowQrForId(null)}
      />
      <Modal
        analyticType="plan-details"
        isOpen={isPlanDetailsModalOpen}
        onClose={handleClosePlaDetailsModal}
      >
        <ESimPlanDetails
          durationDays={eSim.durationDays}
          purchaseDate={formatDateToShortString(eSim.createdAt)}
        />
      </Modal>
    </>
  );
}

let EsimTitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

let ESimTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 34px;
  padding: 10px 0;
  text-align: left;
  margin-right: 8px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;

let ButtonStyled = styled(Button)`
  width: 100%;
`;

let IconBox = styled.div`
  margin-left: 8px;
  height: 34px;

  img {
    height: 34px;
  }
`;
