import { useEffect, useRef, useState } from 'preact/hooks';
import styled from 'styled-components';
import Card from '../../Card';
import ChevronDownIcon from '../../Icons/ChevronDownIcon';

interface AccordionItemProps {
  title: string;
  content: preact.ComponentChildren;
}

function AccordionItem(props: AccordionItemProps) {
  let { title, content } = props;

  let [isActive, setIsActive] = useState(false);
  let [maxHeight, setMaxHeight] = useState('0');
  let panelRef = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setIsActive((prev) => !prev);
  }

  useEffect(() => {
    if (panelRef.current) {
      setMaxHeight(isActive ? `${panelRef.current.scrollHeight}px` : '0');
    }
  }, [isActive, content]);

  return (
    <AccordionItemStyled>
      <AccordionButton $active={isActive} onClick={toggleAccordion}>
        <span>{title}</span>
        <ChevronDownIcon />
      </AccordionButton>
      <AccordionContent
        $active={isActive}
        $maxHeight={maxHeight}
        ref={panelRef}
      >
        {content}
      </AccordionContent>
    </AccordionItemStyled>
  );
}

type DataItem = {
  title: string;
  content: preact.ComponentChildren;
};

interface AccordionProps {
  data: DataItem[];
}

export default function Accordion(props: AccordionProps) {
  let { data } = props;

  return (
    <AccordionStyled>
      {data.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </AccordionStyled>
  );
}

const AccordionStyled = styled(Card)`
  padding: 0 16px;
`;

const AccordionItemStyled = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid var(--strokeSecondary);

  &:last-child {
    border-bottom: none;
  }
`;

const AccordionButton = styled.button<{ $active: boolean }>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  color: var(--textPrimary);

  svg {
    min-width: 24px;
    transition: transform 0.2s;
    transform: ${(props) => (props.$active ? 'rotate(180deg)' : '')};
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const AccordionContent = styled.div<{ $active: boolean; $maxHeight: string }>`
  overflow: hidden;
  max-height: ${({ $active, $maxHeight }) => ($active ? $maxHeight : '0')};
  margin-top: ${(props) => (props.$active ? '8px' : '0px')};
  opacity: ${(props) => (props.$active ? '1' : '0')};
  transition:
    margin-top 0.2s ease-out,
    max-height 0.2s ease-out,
    opacity 0.3s ease-out;
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
  white-space: pre-wrap;
`;
