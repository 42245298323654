import AlertTriangle from '../../Icons/AlertTriangle';
import styled from 'styled-components';

export default function PaymentError() {
  return (
    <PaymentErrorWrapper>
      <IconBox>
        <AlertTriangle />
      </IconBox>
      <ColumnBox>
        <ErrorMessage>
          We were unable to process this payment. Please try another card or
          contact your bank for assistance.
        </ErrorMessage>
      </ColumnBox>
    </PaymentErrorWrapper>
  );
}

let PaymentErrorWrapper = styled.div`
  display: flex;

  padding: 12px 16px;
  border-radius: var(--borderRadiusLarge);
  border: 1px solid var(--red100);
  background-color: var(--backgroundSecondary);

  @media (min-width: 960px) {
    align-items: center;
  }
`;

let IconBox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let ErrorMessage = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
`;
