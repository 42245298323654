export default function SecureSVG() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_115967)">
        <path
          d="M16.4307 32.0127L15.9587 31.8034C15.4334 31.5727 3.16675 26.0327 3.16675 16.0007V7.30203C3.16722 6.46492 3.43179 5.64931 3.9228 4.97133C4.41381 4.29335 5.10622 3.78757 5.90141 3.52603L16.5001 0.0126953L27.0987 3.52603C27.8939 3.78757 28.5864 4.29335 29.0774 4.97133C29.5684 5.64931 29.8329 6.46492 29.8334 7.30203V16.0007C29.8334 27.4367 17.4494 31.674 16.9214 31.85L16.4307 32.0127ZM16.5001 2.8087L6.74541 6.04336C6.48001 6.13005 6.24884 6.2985 6.08502 6.5246C5.9212 6.75069 5.83313 7.02282 5.83341 7.30203V16.0007C5.83341 23.326 14.4201 28.078 16.5627 29.1487C18.7014 28.2887 27.1667 24.3514 27.1667 16.0007V7.30203C27.167 7.02282 27.079 6.75069 26.9151 6.5246C26.7513 6.2985 26.5202 6.13005 26.2547 6.04336L16.5001 2.8087Z"
          fill="#21252A"
        />
        <path
          d="M15.3148 19.3895H15.2708C14.9333 19.3843 14.6004 19.3107 14.2922 19.1732C13.9839 19.0357 13.7068 18.8371 13.4775 18.5895L10.4028 15.3895L12.3242 13.5468L15.3162 16.6668L22.2241 9.75879L24.1095 11.6441L17.0948 18.6588C16.8612 18.8923 16.5836 19.077 16.278 19.2025C15.9725 19.3279 15.6451 19.3914 15.3148 19.3895Z"
          fill="#21252A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_115967">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
