import { useEffect, useRef, useState } from 'preact/hooks';
import Card from '../../Card';
import ChevronDownIcon from '../../Icons/ChevronDownIcon';
import styled from 'styled-components';

interface AccordionItemProps {
  title: string;
  content: preact.ComponentChildren;
}

function AccordionItem(props: AccordionItemProps) {
  let { title, content } = props;

  let [isActive, setIsActive] = useState(false);
  let [maxHeight, setMaxHeight] = useState('0');
  let panelRef = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setIsActive((prev) => !prev);
  }

  useEffect(() => {
    if (panelRef.current) {
      setMaxHeight(isActive ? `${panelRef.current.scrollHeight}px` : '0');
    }
  }, [isActive, content]);

  return (
    <AccordionItemStyled>
      <AccordionButton $active={isActive} onClick={toggleAccordion}>
        <span>{title}</span>
        <ChevronDownIcon />
      </AccordionButton>
      {isActive && <Divider />}
      <AccordionContent
        $active={isActive}
        $maxHeight={maxHeight}
        ref={panelRef}
      >
        {content}
      </AccordionContent>
    </AccordionItemStyled>
  );
}

type DataItem = {
  title: string;
  content: preact.ComponentChildren;
};

interface AccordionProps {
  data: DataItem[];
}

export default function Accordion(props: AccordionProps) {
  let { data } = props;

  return (
    <AccordionStyled>
      {data.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </AccordionStyled>
  );
}

const AccordionStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  border: none;
  row-gap: 8px;
  padding: 0;
  font-family: var(--fontFamilyNew);
`;

const AccordionItemStyled = styled.div`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--strokePrimaryNew);
`;

const AccordionButton = styled.button<{ $active: boolean }>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);

  span {
    line-height: 20px;
    font-size: var(--fontSizeRegular);
    color: var(--textPrimaryNew);
  }

  svg {
    min-width: 24px;
    transition: transform 0.2s;
    transform: ${(props) => (props.$active ? 'rotate(180deg)' : '')};
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const AccordionContent = styled.div<{ $active: boolean; $maxHeight: string }>`
  overflow: hidden;
  max-height: ${({ $active, $maxHeight }) => ($active ? $maxHeight : '0')};
  opacity: ${(props) => (props.$active ? '1' : '0')};
  transition:
    margin-top 0.2s ease-out,
    max-height 0.2s ease-out,
    opacity 0.3s ease-out;
  line-height: 22px;
  text-align: left;
  white-space: pre-wrap;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--strokePrimaryNew);
  margin: 16px 0;
`;
