export default function GraduationIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M29.0905 11.8558L18.7059 6.47171C17.493 5.84276 15.5068 5.84276 14.2941 6.47171L3.90957 11.8558C3.30308 12.1708 3 12.5848 3 12.9999V27H5.25012V14.8389L7.4999 16.0051V22.8063C13.5007 25.3805 19.9368 25.1919 25.5002 22.8014V16.0051L29.0905 14.1437C30.3032 13.5148 30.3032 12.4858 29.0905 11.8558ZM23.2501 21.1861C18.8511 22.7209 14.151 22.7216 9.75002 21.1896V17.172L14.2941 19.5282C15.5071 20.1571 17.4933 20.1571 18.7059 19.5282L23.2501 17.172V21.1861ZM17.6999 17.4408C17.4899 17.55 17.0647 17.6662 16.5 17.6662C15.9354 17.6662 15.5102 17.55 15.3002 17.4408L6.73749 13.001L15.3002 8.55877C15.5102 8.44992 15.9354 8.33302 16.5 8.33302C17.0647 8.33302 17.4899 8.44992 17.6999 8.55877L26.2636 12.9989L17.6999 17.4408Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
