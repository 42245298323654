import styled from 'styled-components';
import LittlePetardImage from './Images/LittlePetard.png';
import BusAnimationImage from './Images/BusAnimation.gif';

export default function PaymentSuccessfulContent() {
  return (
    <div>
      <Top>
        <img loading="lazy" src={BusAnimationImage} alt="bus animation" />
      </Top>
      <Bottom>
        <Title>
          Payment successful <img src={LittlePetardImage} alt="little petard" />
        </Title>
        <SubTitle>We are preparing your eSIM, please wait a moment.</SubTitle>
      </Bottom>
    </div>
  );
}

let Top = styled.div`
  background-color: var(--orange90);
  padding: 20px 0;

  img {
    width: 100%;
  }
`;

let Bottom = styled.div`
  padding: 20px;
`;

let Title = styled.h4`
  padding: 0 39px;
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  img {
    width: 32px;
    margin-bottom: -5px;
  }

  @media (min-width: 393px) {
    padding: initial;

    img {
      margin-left: 8px;
    }
  }

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;

    img {
      margin-left: 12px;
    }
  }
`;

let SubTitle = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: center;
`;
