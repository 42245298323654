export function AppleIcon() {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.89 9.51484C28.583 8.71601 29.1115 7.78815 29.445 6.78452C29.7785 5.78089 29.9105 4.72127 29.8333 3.6665C27.7055 3.83826 25.7303 4.83902 24.3333 6.45317C23.6643 7.22679 23.1574 8.12678 22.8424 9.09985C22.5275 10.0729 22.4111 11.0993 22.5 12.1182C23.5379 12.1268 24.5639 11.8965 25.4986 11.4451C26.4332 10.9936 27.2515 10.3332 27.89 9.51484ZM32.51 23.1548C32.5223 21.75 32.8923 20.3715 33.5852 19.1494C34.278 17.9272 35.2708 16.9018 36.47 16.1698C35.7132 15.0794 34.7128 14.1804 33.5481 13.5439C32.3834 12.9074 31.0864 12.5511 29.76 12.5032C26.9 12.2098 24.26 14.1715 22.7383 14.1715C21.2166 14.1715 19.0716 12.5398 16.6883 12.5765C15.1302 12.6278 13.612 13.0821 12.2818 13.895C10.9516 14.7079 9.85474 15.8517 9.09829 17.2148C5.87163 22.8248 8.27329 31.1665 11.5 35.6948C12.9666 37.9132 14.8 40.4248 17.22 40.3332C19.64 40.2415 20.4283 38.8298 23.2333 38.8298C26.0383 38.8298 26.9 40.3332 29.2833 40.2782C31.6666 40.2232 33.3533 38.0048 34.8933 35.7865C35.9842 34.176 36.8364 32.4161 37.4233 30.5615C35.9703 29.9419 34.7307 28.9102 33.8575 27.5939C32.9844 26.2776 32.5159 24.7344 32.51 23.1548Z"
        fill="black"
      />
    </svg>
  );
}
