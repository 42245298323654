import styled from 'styled-components';
import ChevronLeftIcon from './Icons/ChevronLeftIcon';
import { useCompatibleDevices } from '@front_common/Api/VisitorApi';
import { useEffect, useRef, useState } from 'preact/hooks';
import { T_Devices } from '@shared_backend/Module/CompatibleDevice/CompatibleDeviceResponse';
import Card from '@front_common/Components/Card';
import { AppleIcon } from './Icons/AppleIcon';
import { DellIcon } from './Icons/DellIcon';
import { GoogleIcon } from './Icons/GoogleIcon';
import { HpIcon } from './Icons/HpIcon';
import { LenovoIcon } from './Icons/LenovoIcon';
import { MicrosoftIcon } from './Icons/MicrosoftIcon';
import { MotorolaIcon } from './Icons/MotorolaIcon';
import { SamsungIcon } from './Icons/SamsungIcon';
import { SharpIcon } from './Icons/SharpIcon';
import { SonyIcon } from './Icons/SonyIcon';
import { XiaomiIcon } from './Icons/XiaomiIcon';
import InfoIcon from './Icons/InfoIcon';
import { MODAL_HEIGHT } from '@front_common/Components/Modal/OldDesign/Modal';
import ErrorBlock from '@front_common/Components/ErrorBlock';

function getBrandLogo(brandName: string) {
  switch (brandName) {
    case 'Apple': {
      return <AppleIcon />;
    }
    case 'Dell': {
      return <DellIcon />;
    }
    case 'Google Pixel': {
      return <GoogleIcon />;
    }
    case 'HP': {
      return <HpIcon />;
    }
    case 'Lenovo': {
      return <LenovoIcon />;
    }
    case 'Microsoft': {
      return <MicrosoftIcon />;
    }
    case 'Motorola': {
      return <MotorolaIcon />;
    }
    case 'Samsung': {
      return <SamsungIcon />;
    }
    case 'Sharp': {
      return <SharpIcon />;
    }
    case 'Sony': {
      return <SonyIcon />;
    }
    case 'Xiaomi': {
      return <XiaomiIcon />;
    }
    default: {
      return null;
    }
  }
}

function replaceUrlWithLink(text: string) {
  let urlPattern = /(?<temp1>https?:\/\/\S+)/gu;

  // eslint-disable-next-line xss/no-mixed-html
  let htmlContent = text.replaceAll(
    urlPattern,
    (url: string) =>
      `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`,
  );

  return <span dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

function getStars(count: number | null) {
  return Array.from({ length: count || 0 }).map(() => '*');
}

export default function CompatibleDevicesModalContent() {
  let [compatibleDevices, error] = useCompatibleDevices();
  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  let [selectedBrand, setSelectedBrand] = useState<T_Devices | null>(null);
  let [maxHeight, setMaxHeight] = useState('0');
  let topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef.current) {
      if (selectedBrand) {
        let calculatedHeight = `calc(${MODAL_HEIGHT} - ${topRef.current.scrollHeight}px)`;

        setMaxHeight(`${calculatedHeight}`);
      } else {
        setMaxHeight('initial');
      }
    }
  }, [selectedBrand]);

  function handleBrandClick(brand: T_Devices) {
    setSelectedBrand(brand);
  }

  return (
    <CompatibleDevicesStyled>
      {selectedBrand && (
        <BackButton onClick={() => setSelectedBrand(null)}>
          <ChevronLeftIcon />
        </BackButton>
      )}
      <Top ref={topRef}>
        <TopTitle>
          {!selectedBrand && (
            <>Let’s see if your device is compatible with eSIM</>
          )}
          {selectedBrand && (
            <>
              Make sure your device is eSIM compatible with the help of this
              list:
            </>
          )}
        </TopTitle>
        <TopText>
          {!selectedBrand && (
            <>
              Select the brand of the device on which you will be installing
              your eSIM.
            </>
          )}
          {selectedBrand && (
            <>
              {`If your device doesn't appear on any list, it is because it does
              not support eSIM technology.`}
            </>
          )}
        </TopText>
        {!selectedBrand && (
          <InfoCard>
            <InfoIcon />
            <InfoText>
              To use an eSIM, your device must be carrier-unlocked
            </InfoText>
          </InfoCard>
        )}
      </Top>
      <Bottom $maxHeight={maxHeight}>
        {!selectedBrand &&
          compatibleDevices?.map((brand) => (
            <BrandButton
              key={brand.brand}
              onClick={() => handleBrandClick(brand)}
            >
              {brand.brand}
            </BrandButton>
          ))}
        {selectedBrand && (
          <>
            <BottomText>
              eSIM compatible devices: <b>{selectedBrand.brand}</b>
            </BottomText>
            <CardStyled>
              <LogoWrapper>{getBrandLogo(selectedBrand.brand)}</LogoWrapper>
              <DeviceList>
                {selectedBrand.models.map((model) => (
                  <Device key={model.name}>
                    {model.name}
                    {getStars(model.stars)}
                  </Device>
                ))}
              </DeviceList>
              {selectedBrand.specifics && (
                <>
                  <Divider />
                  <Specifics>
                    {replaceUrlWithLink(selectedBrand.specifics)}
                  </Specifics>
                </>
              )}
            </CardStyled>
          </>
        )}
      </Bottom>
    </CompatibleDevicesStyled>
  );
}

let CompatibleDevicesStyled = styled.div`
  width: 100%;
  max-height: 100%;
  overscroll-behavior-y: contain;
`;

let Top = styled.div`
  background-color: var(--backgroundPrimary);
  padding: 54px 16px 16px;

  @media (min-width: 700px) {
    padding: 58px 20px 20px;
  }
`;

let TopTitle = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;
  margin-bottom: 4px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let TopText = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
`;

let Bottom = styled.div<{ $maxHeight: string }>`
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: ${({ $maxHeight }) => $maxHeight};

  @media (min-width: 700px) {
    padding: 20px;
  }
`;

let BrandButton = styled.button`
  background-color: var(--backgroundPrimary);
  border: var(--borderOutlinePrimary);
  color: var(--textPrimary);
  border-radius: 20px;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightBold);
  line-height: 20px;
  text-align: center;
  cursor: pointer;
`;

let BackButton = styled.button`
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: var(--backgroundSecondary);
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let BottomText = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: left;
  margin-bottom: 12px;

  b {
    font-family: var(--fontFamilyHeader);
    font-size: var(--fontSizeBig);
    font-weight: var(--fontWeightSemiBold);
    line-height: 24px;
    text-align: left;
  }
`;

let CardStyled = styled(Card)`
  padding: 12px;
  width: 100%;
`;

let LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;

  svg {
    height: 44px;
  }
`;

let DeviceList = styled.ul`
  text-align: left;
`;

let Device = styled.li`
  margin-left: 30px;
  font-size: var(--fontSizeRegular);
  line-height: 22px;
`;

let Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: var(--strokeSecondary);
`;

let Specifics = styled.p`
  white-space: pre-line;
  line-height: 22px;
`;

let InfoCard = styled(Card)`
  margin-top: 8px;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;

  svg {
    min-width: 20px;
  }
`;

let InfoText = styled.span`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  text-align: left;
`;
