export function DellIcon() {
  return (
    <svg
      width="105"
      height="41"
      viewBox="0 0 105 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.281H24.0919L25.0886 10.3237L26.0434 10.4092L26.957 10.6226L27.829 10.8364L28.6175 11.178L29.3648 11.5196L30.0706 11.9468L30.7348 12.4166L31.3163 12.9293L31.8974 13.4843L32.3958 14.0824L32.8522 14.7229L33.2258 15.4062L33.5995 16.0898L33.89 16.8158L34.1394 17.5418L47.0499 9L51.741 12.2456L41.6943 18.9075L43.3133 20.0179L53.4431 13.3988L57.8016 16.3879L47.7969 23.178L49.499 24.2456L59.6695 17.5838V10.281H67.1425V24.8434H73.785V31.6758H59.6695V24.6724L47.0911 33L34.2629 24.0746L34.0135 24.8434L33.7645 25.5694L33.3909 26.2954L33.0172 27.0218L32.5604 27.6623L32.0621 28.3032L31.5225 28.9009L30.8999 29.4132L30.2772 29.9258L29.5299 30.3529L28.7826 30.7373L27.9521 31.0789L27.0801 31.335L26.1669 31.5057L25.2121 31.6339L24.1743 31.6767H15V10.281ZM22.1409 16.9856H24.0919L24.964 17.0711L25.6697 17.3272L26.2924 17.7116L26.8319 18.2243L27.206 18.822L27.4965 19.4629L27.6624 20.1889L27.7039 20.9149L27.6624 21.6409L27.4965 22.3673L27.206 23.0078L26.7907 23.606L26.2924 24.1182L25.6697 24.503L24.9224 24.7591L24.0919 24.8446H22.1409V16.9856ZM75.6534 10.281H83.1679V24.8434H89.7692V31.6758H75.6538L75.6534 10.281Z"
        fill="#0382C7"
      />
    </svg>
  );
}
