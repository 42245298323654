export const CONFIG_BETTER_STACK_TOKEN = getEnv('BETTER_STACK_TOKEN');
export const CONFIG_MIRROR_NAME = getEnv('MIRROR_NAME');
export const CONFIG_MIRROR_DOMAIN = getEnv('MIRROR_DOMAIN');
export const CONFIG_MIRROR_HOST = 'https://' + CONFIG_MIRROR_DOMAIN;
export const CONFIG_SUPPORT_EMAIL = getEnv('SUPPORT_EMAIL');
export const CONFIG_API_HOST = getEnv('API_HOST');
export const CONFIG_STRAPI_KEY = getEnv('STRAPI_KEY');
export const CONFIG_PAYMENT_SOLIDGATE_KEY = getEnv('PAYMENT_SOLIDGATE_KEY');
export const CONFIG_ZENDESK_SNIPPET_KEY = getEnv('ZENDESK_SNIPPET_KEY');
export const CONFIG_GOOGLE_TAG_ID = getEnv('GOOGLE_TAG_ID');
export const CONFIG_META_PIXEL_ID = getEnv('META_PIXEL_ID');
export const CONFIG_GOOGLE_CLIENT_ID = getEnv('CONFIG_GOOGLE_CLIENT_ID');

export const isProdEnv: boolean = getEnv('ENV') !== 'development';

// Please don't export this function
function getEnv(key: string): string {
  let value: string = import.meta.env['VITE_' + key];
  if (value === undefined) {
    throw new Error(`Config variable VITE_${key} is not found`);
  }
  return value;
}
