import { useState } from 'react';
import styled from 'styled-components';

type TabProps = {
  tabs: string[];
  onTabClick: (tabIndex: number) => void;
  containerClassName?: string;
};

export function NavigationTabs(props: TabProps) {
  let { tabs, onTabClick, containerClassName } = props;

  let [activeTabIndex, setActiveTabIndex] = useState(0);

  function handleTabClick(index: number) {
    onTabClick(index);
    setActiveTabIndex(index);
  }

  return (
    <TabContainer className={containerClassName}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          $isActive={activeTabIndex === index}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </Tab>
      ))}
    </TabContainer>
  );
}

let TabContainer = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--strokePrimaryNew);
`;

let Tab = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$isActive
      ? '3px solid var(--textPrimaryNew)'
      : '1px solid transparent'};
  flex: 1;
  display: flex;
  justify-content: center;
  color: ${(props) =>
    props.$isActive ? 'var(--textSecondaryNew)' : 'var(--textPrimaryNew)'};
  padding-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  bottom: -1px;
  text-align: center;
  align-items: center;
  font-weight: ${(props) =>
    props.$isActive ? 'var(--fontWeightBold)' : 'var(--fontWeightRegular)'};
  white-space: nowrap;

  &:nth-child(4) {
    margin-left: 12px;
  }

  @media (min-width: 700px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
