import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import { WEB_ROUTE_STORE_GLOBAL } from '@shared_frontend/Common/WebRoutes';
import Button from '@front_common/Components/Button/Button';
import { LinkToRoute } from '@front_common/Router/Router';
import Api from '@front_common/Api/Api';
import SaleIcon from './Icons/SaleIcon';
import styled from 'styled-components';

export const BANNER_HEIGHT_MOBILE = '64px';
export const BANNER_HEIGHT = '64px';

function handleGetPlan() {
  Api.trackAction(ActionTypes.click_sub_banner, null);
}

export default function PromoteBanner() {
  return (
    <>
      <PromoteBannerContainer>
        <Content>
          <LayerWrapper>
            <IconText>
              <SaleIcon />
              <Text>Save up to 70% with global plan</Text>
            </IconText>
            <LinkToRoute
              href={`${WEB_ROUTE_STORE_GLOBAL}/`}
              style={{ textDecoration: 'none' }}
            >
              <StyledButton onClick={handleGetPlan} sizeParam="M">
                Get a global plan
              </StyledButton>
            </LinkToRoute>
          </LayerWrapper>
        </Content>
      </PromoteBannerContainer>
      <Space />
    </>
  );
}

let PromoteBannerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${BANNER_HEIGHT_MOBILE};
  z-index: 10;
  padding: 0 16px;
  background-color: var(--backgroundSecondaryNew);

  @media (min-width: 700px) {
    height: ${BANNER_HEIGHT};
    padding: 0 32px;
  }

  @media (min-width: 1104px) {
    padding: 0;
  }
`;

let Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 960px) {
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let LayerWrapper = styled.div`
  z-index: 1;
  display: flex;
  gap: 12px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  @media (min-width: 393px) {
    svg {
      width: 32px;
      height: 32px;
      min-width: 32px;
    }
  }
`;

let IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (min-width: 700px) {
    gap: 16px;
    align-items: center;
  }
`;

let Space = styled.div`
  min-height: ${BANNER_HEIGHT_MOBILE};
  width: 100%;

  @media (min-width: 700px) {
    min-height: ${BANNER_HEIGHT};
  }
`;

let Text = styled.p`
  font-size: 12px;
  font-weight: var(--fontWeightSemiBold);

  @media (min-width: 393px) {
    font-size: var(--fontSizeRegular);
    line-height: 22px;
  }

  @media (min-width: 700px) {
    font-size: var(--fontSizeBig);
    line-height: 24px;
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

let StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0 8px;
  color: var(--textPrimaryNew);
  border: 1px solid var(--strokeBrandNew);

  &:hover {
    background-color: transparent !important;
    color: var(--black100);
  }

  @media (min-width: 393px) {
    padding: 0 24px;
  }
`;
