import styled from 'styled-components';
import ArrowRightIcon from '../../ESimsPage/Icons/ArrowRightIcon';

type ActionProps = {
  onActionClick: () => void;
  icon: string;
  text: string;
};

export function Action({ onActionClick, icon, text }: ActionProps) {
  return (
    <ActionStyled onClick={onActionClick}>
      <ActionRowBox>
        <img src={icon} />
        <span>{text}</span>
      </ActionRowBox>
      <ActionImageBox>
        <ArrowRightIcon />
      </ActionImageBox>
    </ActionStyled>
  );
}

let ActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer;
  border-bottom: var(--borderOutlinePrimary);

  &:last-child {
    border-bottom: none;
  }
`;

let ActionRowBox = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 22px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontFamilyHeader);

  img {
    width: 32px;
    height: auto;
    margin-right: 5px;
  }
`;

let ActionImageBox = styled.span`
  svg {
    height: 12px;
    width: 12px;

    path {
      stroke: var(--iconsPrimaryInvert);
    }
  }
`;
