import { LinkToRoute } from '@front_common/Router/Router';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import ChevronRightBigIcon from './Icons/ChevronRightBigIcon';
import ChevronBlueIcon from './Icons/ChevronBlueIcon';
import { useFAQTopics } from '@front_common/Strapi/StrapiFAQ';
import { WEB_ROUTE_FAQ } from '@shared_frontend/Common/WebRoutes';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';

type FaqNavigationProps = {
  currentQuestionId: string;
};

export default function FaqNavigation(props: FaqNavigationProps) {
  let { currentQuestionId } = props;
  let [faqTopics] = useFAQTopics();
  let windowDimensions = useWindowDimensions({ onlyWidth: true });

  let [showDropdown, setShowDropdown] = useState(
    () => windowDimensions.width > 960,
  );
  let [openTopics, setOpenTopics] = useState<{ [id: string]: boolean }>({});

  let faqLinks = faqTopics?.map((topic) => ({
    href: `${WEB_ROUTE_FAQ}/${topic.attributes.slug}`,
    label: topic.attributes.name,
    id: topic.attributes.slug,
    questions: topic.attributes.faq_pages.data
      .sort(
        (questionA, questionB) =>
          questionA.attributes.order - questionB.attributes.order,
      )
      .map((question) => ({
        href: `${WEB_ROUTE_FAQ}/${topic.attributes.slug}/${question.attributes.slug}/`,
        label: question.attributes.question,
        current: question.attributes.slug === currentQuestionId,
      })),
  }));

  function toggleTopic(id: string) {
    setOpenTopics((prevOpenTopics) => ({
      ...prevOpenTopics,
      [id]: !prevOpenTopics[id],
    }));
  }

  useEffect(() => {
    let topicWithCurrentQuestion = faqTopics?.find((topic) =>
      topic.attributes.faq_pages.data.some(
        (question) => question.attributes.slug === currentQuestionId,
      ),
    );

    if (topicWithCurrentQuestion) {
      toggleTopic(topicWithCurrentQuestion.attributes.slug);
    }
  }, [faqTopics]);

  useEffect(() => {
    //for mobile after questions navigation close dropdown and keep opened only active topic
    if (windowDimensions.width < 960) {
      setShowDropdown(false);

      let topicWithCurrentQuestion = faqTopics?.find((topic) =>
        topic.attributes.faq_pages.data.some(
          (question) => question.attributes.slug === currentQuestionId,
        ),
      );

      if (topicWithCurrentQuestion) {
        setOpenTopics({
          [topicWithCurrentQuestion?.attributes.slug]: true,
        });
      }
    }
  }, [currentQuestionId, windowDimensions, faqTopics]);

  return (
    <FaqNavigationWrapper $opened={showDropdown}>
      <DropdownButton
        $opened={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <span>View FAQ navigation</span>
        <ChevronIconWrapper $up={showDropdown}>
          <ChevronBlueIcon />
        </ChevronIconWrapper>
      </DropdownButton>
      <Topics $show={showDropdown}>
        {faqLinks?.map((link) => (
          <TopicDropdownButtonWrapper key={link.href}>
            <TopicDropdownButton onClick={() => toggleTopic(link.id)}>
              <span>{link.label}</span>
              <ChevronBigIconWrapper $up={openTopics[link.id]}>
                <ChevronRightBigIcon />
              </ChevronBigIconWrapper>
            </TopicDropdownButton>
            <Questions $show={openTopics[link.id]}>
              {link.questions.map((question) => (
                <QuestionLink
                  key={question.href}
                  href={question.href}
                  $current={question.current}
                >
                  <span>{question.label}</span>
                </QuestionLink>
              ))}
            </Questions>
          </TopicDropdownButtonWrapper>
        ))}
      </Topics>
    </FaqNavigationWrapper>
  );
}

let FaqNavigationWrapper = styled.div<{ $opened: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--strokeBrand);
  border-radius: 10px;
  background-color: var(--backgroundSecondary);

  @media (min-width: 960px) {
    border: none;
    background-color: transparent;
  }
`;

let DropdownButton = styled.button<{ $opened: boolean }>`
  background-color: transparent;
  border: none;
  height: 48px;
  padding: 0 16px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  color: var(--iconsPrimaryBrand);
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$opened ? '1px solid var(--strokeBrand)' : 'none'};

  @media (min-width: 960px) {
    display: none;
  }
`;

let ChevronIconWrapper = styled.div<{ $up: boolean }>`
  height: 20px;

  svg {
    transform: ${(props) => (props.$up ? 'rotate(180deg)' : '')};
    transition: transform 0.2s;
  }
`;

let ChevronBigIconWrapper = styled.div<{ $up: boolean | undefined }>`
  height: 24px;

  svg {
    transform: ${(props) => (props.$up ? 'rotate(270deg)' : 'rotate(90deg)')};
    transition: transform 0.2s;
  }
`;

let Topics = styled.div<{ $show: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.$show ? '2000px' : '0px')};
  opacity: ${(props) => (props.$show ? '1' : '0')};
  transition:
    max-height 0.2s,
    opacity 0.2s;
  padding: 0 16px;
  overflow: hidden;
`;

let TopicDropdownButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 56px;
  padding: 16px 0;
  cursor: pointer;
  width: 100%;
  color: var(--textPrimary);

  span {
    font-size: var(--fontSizeRegular);
    font-family: var(--fontFamilyHeader);
    font-weight: var(--fontWeightSemiBold);
    line-height: 20px;
    text-align: left;
  }

  &:active {
    color: var(--textSelected);
  }

  @media (min-width: 960px) {
    &:hover {
      color: var(--textSelected);
    }
  }
`;

let TopicDropdownButtonWrapper = styled.div`
  border-bottom: 1px solid var(--strokeSecondary);
  overflow: hidden;

  &:last-child {
    border-bottom: initial;
  }
`;

let Questions = styled.div<{ $show: boolean | undefined }>`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.$show ? '800px' : '0px')};
  opacity: ${(props) => (props.$show ? '1' : '0')};
  transition:
    max-height 0.2s,
    opacity 0.2s,
    padding-bottom 0.175s;
  padding-bottom: ${(props) => (props.$show ? '8px' : '0')};
`;

let QuestionLink = styled(LinkToRoute)<{ $current: boolean }>`
  padding: 8px 0;
  min-height: 36px;
  text-decoration: none;
  color: ${(props) =>
    props.$current ? 'var(--textLink)' : 'var(--textPrimary)'};
  display: flex;
  align-items: center;
  border-left: ${(props) =>
    props.$current ? '2px solid var(--strokeBrand)' : 'none'};
  padding-left: ${(props) => (props.$current ? '8px' : '0')};
  cursor: pointer;
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  text-align: left;

  &:active {
    color: var(--textLink);
  }

  @media (min-width: 960px) {
    &:hover {
      color: var(--textLink);
    }
  }
`;
