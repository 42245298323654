import { useEffect, useRef, useState } from 'preact/hooks';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { WEB_ROUTE_SUBSCRIPTION_POLICY } from '@shared_frontend/Common/WebRoutes';
import { showConsentCountries } from '@shared_frontend/Module/Email/ShowConsentCountries';
import { useCreateSubscriptionAttempt } from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { LinkToRoute } from '@front_common/Router/Router';
import Card from '@front_common/Components/Card';
import { useSessionData } from '@front_common/Api/Session';
import SessionError from '../SessionError';
import styled from 'styled-components';
import SolidgateProcessor from '../../../Checkout/PaymentProcessor/SolidgateProcessor/OldDesign/SolidgateProcessor';

type Props = {
  paymentSession: PaymentSessionEntityType | null;
  onSessionCreate: (session: PaymentSessionEntityType) => void;
  onSessionError: (error: any) => void;
  onPaymentError: (error: string) => void;
  onPaymentSuccess: (paymentId: number) => void;
  paymentSubId: number;
  billingPeriod: 'month' | 'year';
};

let CONFIRMATION_PAYMENT_ERROR = 'Please tick this box if you want to proceed.';

export default function PaymentForm({
  paymentSession,
  onSessionCreate,
  onPaymentError,
  paymentSubId,
  onPaymentSuccess,
  onSessionError,
  billingPeriod,
}: Props) {
  let checkboxRef = useRef<HTMLInputElement | null>(null);

  let sessionData = useSessionData();

  let [isLegalChecked, setISLegalChecked] = useState(false);
  let [confirmValidationError, setConfirmValidationError] = useState('');
  let [skipLegalCheck, setSkipLegalCheck] = useState(false);

  let [initialPaymentSession, initialPaymentSessionError, isLoading, execute] =
    useCreateSubscriptionAttempt(paymentSubId);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (initialPaymentSessionError) {
    onSessionError(initialPaymentSessionError.message);
    return <SessionError errorText={initialPaymentSessionError.message} />;
  }

  useEffect(() => {
    if (sessionData && !showConsentCountries.includes(sessionData.country)) {
      setSkipLegalCheck(true);
      setISLegalChecked(true);
    }
  }, [sessionData]);

  useEffect(() => {
    if (initialPaymentSession) {
      onSessionCreate(initialPaymentSession);
    }
  }, [initialPaymentSession]);

  useEffect(() => {
    if (confirmValidationError && checkboxRef.current) {
      checkboxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [confirmValidationError]);

  function handleCheckboxChange() {
    setISLegalChecked((prev) => {
      if (!prev) {
        setConfirmValidationError('');
      }
      return !prev;
    });
  }

  function validateConfirmation() {
    if (!isLegalChecked) {
      setConfirmValidationError(CONFIRMATION_PAYMENT_ERROR);
    }
  }

  function onPaymentFail(error: string) {
    execute();
    onPaymentError(error);
  }

  return (
    <div>
      {paymentSession ? (
        <PaymentFormWrapper>
          <PaymentTitle>Choose payment method</PaymentTitle>
          <PaymentWrapper>
            <SolidgateProcessor
              isWebView={false}
              paymentSession={paymentSession}
              isConfirmedPolicy={isLegalChecked}
              onValidateConfirmation={validateConfirmation}
              onSuccess={() => onPaymentSuccess(paymentSession.id)}
              onFail={onPaymentFail}
            />
          </PaymentWrapper>
          {/* TODO: move to the separate component */}
          <Legal>
            {!skipLegalCheck && (
              <Checkbox
                ref={checkboxRef}
                $hasError={Boolean(confirmValidationError)}
                type="checkbox"
                checked={isLegalChecked}
                onChange={handleCheckboxChange}
              />
            )}
            <ColumnBox>
              {!skipLegalCheck && (
                <LegalConfirmMessage>
                  By starting your plan immediately, you agree to forfeit the
                  14-day right of withdrawal under EU consumer protection laws.
                </LegalConfirmMessage>
              )}

              {confirmValidationError && (
                <ConfirmValidationError>
                  {confirmValidationError}
                </ConfirmValidationError>
              )}

              <LegalConfirmMessage>
                By continuing, you agree to a{' '}
                <TextBold>
                  {` ${paymentSession.totalAmountCents / 100} ${billingPeriod}ly `}
                </TextBold>{' '}
                charge unless canceled at least 24 hours before renewal. Cancel
                anytime in settings. Charges will appear as{' '}
                <TextBold>Ohayu</TextBold> on your credit card. Read more in
                <ButtonLink href={WEB_ROUTE_SUBSCRIPTION_POLICY}>
                  {' '}
                  Subscription Terms
                </ButtonLink>
                .
              </LegalConfirmMessage>
            </ColumnBox>
          </Legal>
        </PaymentFormWrapper>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  );
}

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let PaymentFormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
`;

let PaymentTitle = styled.h4`
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;

let PaymentWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

let Legal = styled.div`
  display: flex;
`;

let LegalConfirmMessage = styled.span`
  font-size: 12px;
  line-height: 18px;

  &:first-child {
    margin-bottom: 4px;
  }
  &:last-child {
    margin-top: 4px;
  }
`;

let TextBold = styled.span`
  line-height: 16px;
  font-weight: var(--fontWeightBold);
`;

type CheckBoxProp = {
  $hasError: boolean;
};

let Checkbox = styled.input.attrs<CheckBoxProp>({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;

  /* Hide default checkbox */
  appearance: none;
  border: ${(props) =>
    props.$hasError
      ? '1px solid var(--errorButtonDanger)'
      : 'var(--borderOutlinePrimary)'};
  border-radius: 6px;
  position: relative;

  /* Custom checkmark */
  &:checked::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid var(--strokeBrand);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

let ButtonLink = styled(LinkToRoute)`
  cursor: pointer;
  text-decoration: underline;
  color: var(--textLink);
`;

let ConfirmValidationError = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: var(--fontWeightRegular);
  color: var(--errorButtonDanger);
`;
