import { useEffect, useRef, useState } from 'preact/hooks';
import Button from '@front_common/Components/Button/Button';
import styled, { keyframes } from 'styled-components';
import { getQueryParam, removeQueryParam } from '@front_common/Router/Router';
import { SHOW_HINT } from '@shared_frontend/Module/InstallFlow/Constants';

function isElementFullyVisible(element: Element | null) {
  if (!element) {
    return true;
  }
  let rect = element.getBoundingClientRect();

  let fullyVisibleVertically =
    rect.top >= 0 && rect.bottom <= window.innerHeight;
  let fullyVisibleHorizontally =
    rect.left >= 0 && rect.right <= window.innerWidth;

  return fullyVisibleVertically && fullyVisibleHorizontally;
}

export default function Hint() {
  let [showHint, setShowHint] = useState(false);
  let [esimId, setEsimId] = useState<string | null>(null);

  let copiedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let showHintParam = getQueryParam(SHOW_HINT);
    setEsimId(showHintParam);
    setShowHint(Boolean(showHintParam));

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  function handleGotItClick() {
    removeQueryParam(SHOW_HINT);
    setShowHint(false);
  }

  useEffect(() => {
    let copiedContent = copiedRef.current;

    if (copiedContent && esimId) {
      // Clear existing content to avoid duplicates
      copiedContent.innerHTML = '';
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        let targetElement = document.querySelector(`[id="${esimId}"]`);
        let isTargetElementVisible = isElementFullyVisible(targetElement);
        if (targetElement) {
          if (!isTargetElementVisible) {
            targetElement.scrollIntoView();
          }

          // Clone the target element
          // eslint-disable-next-line xss/no-mixed-html
          let clonedElement = targetElement.cloneNode(true) as HTMLElement;

          // Copy styles from the target element
          let styles = window.getComputedStyle(targetElement);

          for (let style of styles) {
            (clonedElement.style as CSSStyleDeclaration).setProperty(
              style,
              styles.getPropertyValue(style),
            );
          }

          // Position it according to the target element's position
          let rect = targetElement.getBoundingClientRect();
          clonedElement.style.position = 'absolute';
          clonedElement.style.top = `${rect.top}px`;
          clonedElement.style.margin = '0px auto';

          // Append the cloned element to the copiedContent
          copiedContent.append(clonedElement);
        }
      }, 0);
    }
  }, [esimId]);

  if (!showHint) {
    document.body.style.overflow = 'auto';
    return null;
  }

  return (
    <Overlay>
      <CopiedContent ref={copiedRef} />
      <HintTooltip onGotItClick={handleGotItClick} />
    </Overlay>
  );
}

let Overlay = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

let CopiedContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media (min-width: 700px) {
    width: 414px;
    justify-content: center;
  }
`;

interface HintTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  onGotItClick: () => void;
}

function HintTooltip(props: HintTooltipProps) {
  let { onGotItClick, ...rest } = props;

  return (
    <HintTooltipStyled {...rest}>
      <HintText>
        When you are ready to install your eSIM, just come back here and click
        on your eSIM to start installation.
      </HintText>
      <GotItButton onClick={onGotItClick}>Got it</GotItButton>
    </HintTooltipStyled>
  );
}

let pulse = keyframes`
    0% {
        transform: translate(0, calc(-100% - 8px)) scale(1);
    }
    20% {
        transform: translate(0, calc(100% - 8px)) scale(1);
    }
    30% {
        transform: translate(0, calc(100% - 8px)) scale(1.1);
    }
    50% {
        transform: translate(0, calc(100% - 8px)) scale(1);
    }
    70% {
        transform: translate(0, calc(100% - 8px)) scale(1.1);
    }
    100% {
        transform: translate(0, calc(100% - 8px)) scale(1);
    }
`;

let HintTooltipStyled = styled.div`
  width: 270px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 8px -2px #0000001a;
  position: relative;
  z-index: 1;
  top: 0;
  transform: translate(-50%);
  animation: ${pulse} 2.5s ease-in-out;
  animation-fill-mode: forwards;

  &::after {
    content: '';
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

let HintText = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

let GotItButton = styled(Button)`
  height: 32px;
  width: 65px;
  font-size: 12px;
  margin: 0 auto;
`;
