export default function Dots() {
  return (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 2.83464C8.45894 2.83464 8.83203 2.46154 8.83203 2.0013C8.83203 1.54106 8.45894 1.16797 7.9987 1.16797C7.53846 1.16797 7.16536 1.54106 7.16536 2.0013C7.16536 2.46154 7.53846 2.83464 7.9987 2.83464Z"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.832 2.83464C14.2923 2.83464 14.6654 2.46154 14.6654 2.0013C14.6654 1.54106 14.2923 1.16797 13.832 1.16797C13.3718 1.16797 12.9987 1.54106 12.9987 2.0013C12.9987 2.46154 13.3718 2.83464 13.832 2.83464Z"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16536 2.83464C2.6256 2.83464 2.9987 2.46154 2.9987 2.0013C2.9987 1.54106 2.6256 1.16797 2.16536 1.16797C1.70513 1.16797 1.33203 1.54106 1.33203 2.0013C1.33203 2.46154 1.70513 2.83464 2.16536 2.83464Z"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
