export default function ProfileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3116_107)">
        <path
          d="M12.0001 0.768555C9.39989 0.768555 7.27739 2.89105 7.27739 5.4913C7.27739 8.09155 9.39989 10.2133 12.0001 10.2133C14.6004 10.2133 16.7229 8.0908 16.7229 5.4913C16.7229 2.8903 14.6004 0.768555 12.0001 0.768555ZM0.650391 22.4833C0.650686 22.5819 0.670402 22.6794 0.708413 22.7703C0.746423 22.8612 0.801983 22.9438 0.871916 23.0132C0.941848 23.0827 1.02478 23.1377 1.11598 23.175C1.20717 23.2124 1.30484 23.2314 1.40339 23.2311H22.5969C22.6954 23.2314 22.7931 23.2124 22.8843 23.175C22.9755 23.1377 23.0584 23.0827 23.1284 23.0132C23.1983 22.9438 23.2539 22.8612 23.2919 22.7703C23.3299 22.6794 23.3496 22.5819 23.3499 22.4833C23.3499 16.3326 18.4141 11.3661 12.0001 11.1943C5.58614 11.3653 0.650391 16.3318 0.650391 22.4833Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3116_107">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
