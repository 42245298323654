export default function InfoIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0034 2.00313C9.35298 2.00613 6.81178 3.05962 4.93656 4.93271C3.06134 6.8058 2.00502 9.34581 1.99902 11.9963C2.00302 14.6484 3.05834 17.1907 4.93366 19.066C6.80899 20.9414 9.35133 21.9967 12.0034 22.0007C14.6545 21.9957 17.1955 20.9397 19.0694 19.0644C20.9433 17.1891 21.9973 14.6474 22.0003 11.9963C21.9973 9.34584 20.9431 6.80487 19.069 4.93075C17.1948 3.05662 14.6538 2.00233 12.0034 1.99933V2.00313ZM12.0034 3.99878C13.0545 3.99579 14.0958 4.20059 15.0675 4.60144C16.0391 5.0023 16.9219 5.59123 17.6652 6.33446C18.4084 7.07769 18.9974 7.96054 19.3982 8.93219C19.7991 9.90384 20.0039 10.9452 20.0009 11.9963C20.0039 13.0477 19.7991 14.0894 19.3984 15.0615C18.9976 16.0335 18.4088 16.9168 17.6656 17.6606C16.9225 18.4044 16.0397 18.9941 15.068 19.3958C14.0963 19.7975 13.0549 20.0032 12.0034 20.0012C10.952 20.0027 9.91052 19.7967 8.93884 19.3949C7.96716 18.9931 7.08435 18.4035 6.34102 17.6598C5.59769 16.9161 5.00845 16.033 4.6071 15.0611C4.20575 14.0893 4.00018 13.0477 4.00217 11.9963C4.00217 7.56665 7.57383 3.99878 12.0034 3.99878ZM12.0034 6.00193C11.7384 6.0029 11.4844 6.10808 11.2963 6.29478C11.1082 6.48148 11.0011 6.73474 10.9981 6.99975C10.9981 7.544 11.4592 8.00127 12.0034 8.00127C12.2691 8.00127 12.5238 7.89578 12.7117 7.70795C12.8995 7.52012 13.005 7.26539 13.005 6.99975C13.005 6.73412 12.8995 6.47927 12.7117 6.29144C12.5238 6.1036 12.2691 5.99812 12.0034 5.99812V6.00193ZM11.9883 9.98169C11.9542 9.98247 11.9201 9.98504 11.8863 9.9893C11.6388 10.0175 11.4107 10.1369 11.2464 10.3241C11.0822 10.5114 10.9937 10.7532 10.9981 11.0022V17.0041C10.9981 17.2697 11.1036 17.5245 11.2914 17.7124C11.4793 17.9002 11.734 18.0057 11.9997 18.0057C12.2653 18.0057 12.52 17.9002 12.7079 17.7124C12.8957 17.5245 13.0012 17.2697 13.0012 17.0041V11.0022C13.0038 10.8682 12.9794 10.7349 12.9294 10.6104C12.8795 10.486 12.8051 10.3728 12.7107 10.2777C12.6162 10.1825 12.5036 10.1073 12.3795 10.0564C12.2554 10.0056 12.1224 9.98015 11.9883 9.98169Z"
        fill="black"
      />
    </svg>
  );
}
