export enum ActionTypes {
  backend_new_session,
  backend_sign_up,
  backend_sign_in_code_sent,
  backend_sign_in_incorrect_code,
  backend_sign_in_by_code,
  backend_sign_in_by_email_token,
  backend_unsubscribe_from_emails,
  backend_new_payment_session,
  backend_new_sub_attempt,
  backend_new_payment,
  backend_esim_installed,
  backend_esim_activated,
  backend_discount_code_invalid,
  backend_discount_code_expired,
  backend_discount_code_usage_limit_reached,
  screen,
  button_home_cta,
  button_home_show_all_countries,
  button_buy,
  modal_open,
  modal_close,
  download_installation_guide,
  global_plans,
  button_global_go_to_checkout,
  button_choose_global_plan,
  button_app,
  click_sub_banner,
}

export function getStringFromActionType(type: ActionTypes): string {
  return ActionTypes[type];
}

export function getActionTypeFromString(actionType: string): ActionTypes {
  // @ts-expect-error
  return ActionTypes[actionType];
}
