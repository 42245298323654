export default function LaptopIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M3.5 20V9C3.5 8.204 3.816 7.441 4.379 6.879C4.941 6.316 5.704 6 6.5 6C11.02 6 21.98 6 26.5 6C27.296 6 28.059 6.316 28.621 6.879C29.184 7.441 29.5 8.204 29.5 9V20C30.052 20 30.5 20.448 30.5 21C30.5 21 30.5 22 30.5 23C30.5 24.657 29.157 26 27.5 26C22.657 26 10.343 26 5.5 26C3.843 26 2.5 24.657 2.5 23V21C2.5 20.448 2.948 20 3.5 20ZM4.5 22V23C4.5 23.552 4.948 24 5.5 24H15.5V22H4.5ZM28.5 22H17.5V24H27.5C28.052 24 28.5 23.552 28.5 23V22ZM5.5 20H27.5V9C27.5 8.735 27.395 8.48 27.207 8.293C27.02 8.105 26.765 8 26.5 8C21.98 8 11.02 8 6.5 8C6.235 8 5.98 8.105 5.793 8.293C5.605 8.48 5.5 8.735 5.5 9V20Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
