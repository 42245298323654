import SecureIcon from './Icons/SecureIcon';
import styled from 'styled-components';

export default function RiskFree() {
  return (
    <RiskFreeWrapper>
      <Secure />
      <RightContent>
        <Title>Money Back Guarantee</Title>
        <Text>
          Not used your data? Get a full refund, no questions asked. Your
          satisfaction, guaranteed.
        </Text>
      </RightContent>
    </RiskFreeWrapper>
  );
}

let RiskFreeWrapper = styled.div`
  background: var(--cardsBackgroundLightBlueSecondary);
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
  text-align: center;

  @media (min-width: 700px) {
    height: 164px;
    margin: 52px 0;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 0 40px;
  }
`;

let Secure = styled(SecureIcon)`
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin: 0 auto 20px;
  color: #303c42;

  @media (min-width: 700px) {
    margin: initial;
  }
`;

let RightContent = styled.div``;

let Title = styled.h2`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 4px;
`;

let Text = styled.span`
  line-height: 24px;

  @media (min-width: 700px) {
    font-size: 20px;
  }
`;
