export default function PhoneIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5003 14.5837H10.5087M7.33366 18.3337H13.667C14.6004 18.3337 15.0671 18.3337 15.4236 18.152C15.7372 17.9922 15.9922 17.7372 16.152 17.4236C16.3337 17.0671 16.3337 16.6004 16.3337 15.667V4.33366C16.3337 3.40024 16.3337 2.93353 16.152 2.57701C15.9922 2.2634 15.7372 2.00844 15.4236 1.84865C15.0671 1.66699 14.6004 1.66699 13.667 1.66699H7.33366C6.40024 1.66699 5.93353 1.66699 5.57701 1.84865C5.2634 2.00844 5.00844 2.2634 4.84865 2.57701C4.66699 2.93353 4.66699 3.40024 4.66699 4.33366V15.667C4.66699 16.6004 4.66699 17.0671 4.84865 17.4236C5.00844 17.7372 5.2634 17.9922 5.57701 18.152C5.93353 18.3337 6.40024 18.3337 7.33366 18.3337ZM10.917 14.5837C10.917 14.8138 10.7304 15.0003 10.5003 15.0003C10.2702 15.0003 10.0837 14.8138 10.0837 14.5837C10.0837 14.3535 10.2702 14.167 10.5003 14.167C10.7304 14.167 10.917 14.3535 10.917 14.5837Z"
        stroke="#203ADB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
