import { createContext, useContext, useState, ReactNode } from 'react';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';

//TODO: think about removing bundleCodeAndPriceId, totalAmountCents, targetCountry - because they can be passed directly to the component. Better to store only state variables in the context.
interface DiscountPaymentContextType {
  totalAmountCents: number;
  bundleCodeAndPriceId: string;
  targetCountry: string;
  discountAmountCents: number | null;
  discountPaymentSession: PaymentSessionEntityType | null;
  resendDiscountRequest: boolean;
  setDiscountAmountCents: React.Dispatch<React.SetStateAction<number | null>>;
  setDiscountPaymentSession: React.Dispatch<
    React.SetStateAction<PaymentSessionEntityType | null>
  >;
  setResendDiscountRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

let DiscountPaymentContext = createContext<DiscountPaymentContextType>({
  totalAmountCents: 0,
  bundleCodeAndPriceId: '',
  targetCountry: '',
  discountAmountCents: null,
  discountPaymentSession: null,
  resendDiscountRequest: false,
  setDiscountAmountCents: () => {},
  setDiscountPaymentSession: () => {},
  setResendDiscountRequest: () => {},
});

export function useDiscountPayment() {
  return useContext(DiscountPaymentContext);
}

interface DiscountPaymentProviderProps {
  children: ReactNode;
  totalAmountCents: number;
  bundleCodeAndPriceId: string;
  targetCountry: string;
}

export function DiscountPaymentProvider({
  children,
  totalAmountCents,
  bundleCodeAndPriceId,
  targetCountry,
}: DiscountPaymentProviderProps) {
  let [discountAmountCents, setDiscountAmountCents] = useState<number | null>(
    null,
  );
  let [discountPaymentSession, setDiscountPaymentSession] =
    useState<PaymentSessionEntityType | null>(null);
  let [resendDiscountRequest, setResendDiscountRequest] = useState(false);

  return (
    <DiscountPaymentContext.Provider
      value={{
        totalAmountCents,
        bundleCodeAndPriceId,
        targetCountry,
        discountAmountCents,
        discountPaymentSession,
        resendDiscountRequest,
        setDiscountAmountCents,
        setDiscountPaymentSession,
        setResendDiscountRequest,
      }}
    >
      {children}
    </DiscountPaymentContext.Provider>
  );
}
