import { asyncHook } from '@shared_frontend/Common/HookFactory';
import { strapiRequest } from './StrapiRequest';

type Image = {
  id: number;
  attributes: {
    url: string;
  };
};

export type StrapiTopicResponse = {
  id: number;
  attributes: {
    name: string;
    slug: string;
    image: {
      data: Image | null;
    };
    faq_pages: {
      data: StrapiQuestionResponseFirstLevel[];
    };
    seo: {
      title: string;
      description: string;
    };
  };
};

type StrapiTopicResponseFirstLevel = Omit<StrapiTopicResponse, 'attributes'> & {
  attributes: Omit<StrapiTopicResponse['attributes'], 'faq_pages' | 'image'>;
};

type StrapiQuestionResponse = {
  id: number;
  attributes: {
    question: string;
    slug: string;
    answer_html: string;
    order: number;
    faq_topic: {
      data: StrapiTopicResponseFirstLevel;
    };
    seo: {
      title: string;
      description: string;
    };
  };
};

type StrapiQuestionResponseFirstLevel = Omit<
  StrapiQuestionResponse,
  'attributes'
> & {
  attributes: Omit<StrapiQuestionResponse['attributes'], 'faq_topic'>;
};

type StrapiPopularResponse = {
  id: number;
  attributes: {
    faq_page: {
      data: StrapiQuestionResponseFirstLevel | null;
    };
    seo: {
      title: string;
      description: string;
    };
  };
};

export const useFAQPopulars = asyncHook(() =>
  strapiRequest<StrapiPopularResponse[]>('faq-populars'),
);

export const useFAQTopics = asyncHook(() =>
  strapiRequest<StrapiTopicResponse[]>('faq-topics'),
);

export const useFAQQuestions = asyncHook(() =>
  strapiRequest<StrapiQuestionResponse[]>('faq-pages'),
);
