export default function CrossIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.472 4.97099L11.5293 4.02832L8.00063 7.55699L4.47196 4.02832L3.5293 4.97099L7.05796 8.49965L3.5293 12.0283L4.47196 12.971L8.00063 9.44232L11.5293 12.971L12.472 12.0283L8.9433 8.49965L12.472 4.97099Z"
        fill="#21252A"
      />
    </svg>
  );
}
