import { route } from '@front_common/Router/RouterLibrary';
import AuthService from '@front_common/Api/AuthService';
import { now } from '@shared_frontend/Common/Date';
import { isBot } from '@shared_backend/Module/UserAgent/Bots';
import { JSX } from 'preact/jsx-runtime';
import { WEB_ROUTE_AUTH } from '@shared_frontend/Common/WebRoutes';
import { createElement } from 'preact';

type SearchQueryParams = { [name: string]: string | number };

export function routeRedirect(
  routePath: string,
  searchQueryParams?: SearchQueryParams,
) {
  route(mayAddVisitorToRoute(routePath, searchQueryParams));
}

export function routeHardRedirectWithReload(
  routePath: string,
  searchQueryParams?: SearchQueryParams,
) {
  // eslint-disable-next-line xss/no-location-href-assign
  window.location.href = mayAddVisitorToRoute(routePath, searchQueryParams);
}

export function routeChangeWithoutRerender(
  routePath: string | null,
  searchQueryParams?: SearchQueryParams,
) {
  let changedPath = routePath === null ? window.location.href : routePath;
  changedPath = mayAddVisitorToRoute(changedPath, searchQueryParams);
  window.history.pushState({ path: changedPath }, '', changedPath);
}

//without reload the page
export function setQueryParam(param: string, value: string) {
  let queryParams = new URLSearchParams(window.location.search);
  queryParams.set(param, value);
  let newParams = queryParams.toString();
  let newPath = `${window.location.pathname}?${newParams}`;
  window.history.pushState(null, '', newPath);
}

export function removeQueryParam(param: string) {
  let queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(param);
  let newParams = queryParams.toString();
  let newPath = `${window.location.pathname}${newParams ? '?' + newParams : ''}`;
  window.history.pushState(null, '', newPath);
}

export function getQueryParam(param: string): string | null {
  let queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(param);
}

export function getQueryParamOrFail(name: string): string {
  let result = getQueryParam(name);
  if (result === null) {
    throw new Error('Missed url param ' + name);
  }
  return result;
}

export function logoutAndRedirect() {
  AuthService.deleteLoginData();
  routeRedirect(WEB_ROUTE_AUTH);
}

export function checkLoginOrRedirect() {
  if (!AuthService.isLoggedIn()) {
    routeRedirect(WEB_ROUTE_AUTH);
  }
}

export function LinkToRoute({
  href,
  children,
  ...rest
}: LinkToRouteProps & JSX.HTMLAttributes<HTMLAnchorElement>) {
  let changedHref = processLinkHref(href);

  return createElement(
    'a',
    {
      href: changedHref,
      onClick: (event) => linkClickHandler(event, changedHref),
      ...rest,
    },
    children,
  );
}

// --------- NOT exported functions below --------------------------

export let exportsForTesting = {
  mayAddVisitorToRoute,
  getVUrlParam,
};

function mayAddVisitorToRoute(
  routePath: string,
  searchQueryParams: SearchQueryParams = {},
): string {
  let finalSearchQuery: SearchQueryParams = {};
  let changedPath = routePath;
  if (changedPath.includes('?')) {
    let passedParams = new URLSearchParams(
      changedPath.slice(changedPath.indexOf('?') + 1),
    );
    // eslint-disable-next-line unicorn/no-array-for-each
    passedParams.forEach((value, key) => {
      finalSearchQuery[key] = value;
    });
    changedPath = changedPath.slice(0, changedPath.indexOf('?'));
  }
  // eslint-disable-next-line guard-for-in
  for (let name in searchQueryParams) {
    let value = searchQueryParams[name];
    if (value === undefined) {
      continue;
    }
    finalSearchQuery[name] = value;
  }
  if (shouldAddVisitorParam()) {
    finalSearchQuery['v'] = getVUrlParam();
  }
  if (Object.keys(finalSearchQuery).length > 0) {
    let finalParams = new URLSearchParams();
    // eslint-disable-next-line guard-for-in
    for (let name in finalSearchQuery) {
      let value = finalSearchQuery[name];
      if (value === undefined) {
        continue;
      }
      finalParams.append(name, String(value));
    }
    finalParams.sort();
    changedPath += '?' + finalParams.toString();
  }
  return changedPath;
}

function shouldAddVisitorParam(): boolean {
  if (!window.location.search || window.location.search.length < 15) {
    // no marketing params
    return false;
  }
  if (isBot(window.navigator.userAgent)) {
    return false;
  }
  if (AuthService.getLoginTokenBasic()) {
    return false;
  }
  let firstVisit = AuthService.getFirstVisitTimestamp();
  return firstVisit > now() - 3600;
}

function getVUrlParam() {
  let existVParam = getQueryParam('v');
  if (existVParam && existVParam.length === 10) {
    return existVParam;
  }
  return AuthService.getVisitorToken().slice(0, 10);
}

function processLinkHref(href: string) {
  if (!shouldAddVisitorParam()) {
    return href;
  }
  let queryParamDivider = href.includes('?') ? '&' : '?';
  return href + queryParamDivider + 'v=' + getVUrlParam();
}

type LinkToRouteProps = {
  href: string;
  children: React.ReactNode;
};

function linkClickHandler(
  event: JSX.TargetedMouseEvent<HTMLAnchorElement>,
  href: string,
) {
  routeRedirect(href);
  // ----- disabling event handling below
  if (event.stopImmediatePropagation) {
    event.stopImmediatePropagation();
  }
  if (event.stopPropagation) {
    event.stopPropagation();
  }
  event.preventDefault();
  return false;
}
