export enum SplitAddingContextEnum {
  LANDING_PAGE_RENDER = 'landing-page-render',
  PAYMENT_PAGE_RENDER = 'payment-page-render',
  LANDING_PRICE_PAGE_RENDER = 'landing-price-page-render',
  FUNNEL_PRICE_PAGE_RENDER = 'funnel-price-page-render',
  FUNNEL_SIGN_UP = 'funnel-sign-up',
  BUY_PLAN_CLICK = 'buy-plan-click',
  FUNNEL_FIRST_SCREEN_RENDER = 'funnel-first-screen-render',
  GLOBAL_PLANS_PAGE_RENDER = 'global-plans-page-render',
}
