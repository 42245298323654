export default function CommentsSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116067)">
        <path
          d="M24 16V24H16C14.5971 23.9986 13.2192 23.6282 12.0047 22.9262C10.7901 22.2241 9.78145 21.215 9.08 20C9.83387 19.9946 10.5852 19.9115 11.322 19.752C11.8832 20.4536 12.595 21.02 13.4048 21.4092C14.2146 21.7983 15.1016 22.0002 16 22H22V16C21.9998 15.1012 21.7972 14.2141 21.4074 13.4042C21.0175 12.5944 20.4504 11.8828 19.748 11.322C19.9088 10.5853 19.9933 9.83401 20 9.08C21.215 9.78145 22.2241 10.7901 22.9262 12.0047C23.6282 13.2192 23.9986 14.5971 24 16ZM18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9L0 18H9C11.3861 17.9974 13.6738 17.0483 15.361 15.361C17.0483 13.6738 17.9974 11.3861 18 9ZM2 9C2 7.61553 2.41054 6.26215 3.17971 5.11101C3.94888 3.95986 5.04213 3.06266 6.32122 2.53284C7.6003 2.00303 9.00776 1.86441 10.3656 2.1345C11.7235 2.4046 12.9708 3.07128 13.9497 4.05025C14.9287 5.02922 15.5954 6.2765 15.8655 7.63437C16.1356 8.99224 15.997 10.3997 15.4672 11.6788C14.9373 12.9579 14.0401 14.0511 12.889 14.8203C11.7378 15.5895 10.3845 16 9 16H2V9Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_116067">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
