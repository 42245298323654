type LoadedScripts = {
  [key: string]: number;
};

let loadedScripts: LoadedScripts = {};

export async function scriptLoader(url: string, scriptId?: string) {
  return new Promise<void>((resolve) => {
    let lowUrl = url.toLowerCase().trim();
    if (loadedScripts[lowUrl]) {
      resolve();
      return;
    }
    let script = document.createElement('script');
    script.addEventListener('load', () => resolve());
    script.src = lowUrl;
    if (scriptId) {
      script.id = scriptId;
    }
    document.head.append(script);
    loadedScripts[lowUrl] = 1;
  });
}
