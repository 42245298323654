import Accordion from '@front_common/Components/Accordion/NewDesign/Accordion';
import styled from 'styled-components';

export let data = [
  {
    title: 'Can I purchase the eSIM but not activate it right away?',
    content:
      'Absolutely! You can buy an eSIM and save the provided QR code for future use. Simply install and activate the eSIM on your device whenever you’re ready. The plan’s validity period starts only after activation.',
  },
  {
    title:
      'Should I buy my eSIM data plan before traveling or when I arrive at my destination?',
    content:
      'It’s best to purchase and install your eSIM data plan before you travel. This ensures you have immediate and seamless connectivity upon arrival at your destination.',
  },
  {
    title: 'Can I use the same eSIM on different devices?',
    content:
      'An eSIM is intended for use on one device at a time. If you wish to switch devices, you’ll need to delete the eSIM from the current device before installing it on a new one. Please note, some eSIMs are designed to be installed only once.',
  },
  {
    title: 'Can I keep my WhatsApp / Telegram number while using eSIM?',
    content:
      'Yes, you can continue using your existing number for WhatsApp, Telegram, and other services while you’re on an eSIM.',
  },
  {
    title:
      'How long will my eSIM remain active, and what happens when it expires?',
    content:
      'Your eSIM will remain active for the duration specified at the time of purchase. For example, if you opt for a 1GB plan with a 7-day validity, your eSIM will be active for 7 days from activation. After it expires, you’ll need to renew your plan or purchase a new one to continue service.',
  },
];

export default function FAQ() {
  return (
    <ContentWrapper>
      <Accordion data={data} />
    </ContentWrapper>
  );
}

let ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  row-gap: 16px;

  @media (min-width: 700px) {
    margin: 0;
  }

  @media (min-width: 960px) {
    max-width: 684px;
    row-gap: 24px;
    width: 100%;
  }
`;
