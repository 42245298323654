import Button from '@front_common/Components/Button/Button';
import LostPlanetImage from './LostPlanet.png';
import styled from 'styled-components';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import { useEffect } from 'preact/compat';
import {
  addRobotsMetaTag,
  removeCanonicalHeader,
  setDescriptionHeader,
  setTitleHeader,
} from '@front_common/Seo/SeoHeaders';

export default function NotFoundPage() {
  useEffect(() => {
    addRobotsMetaTag('noindex, follow');
    setTitleHeader('404 Not Found - OHAYU');
    setDescriptionHeader(
      "Oops! The page you're looking for doesn't exist. Return to Ohayu.com to explore eSIM options.",
    );
    removeCanonicalHeader();
  }, []);

  return (
    <NotFoundPageWrapper>
      <Image src={LostPlanetImage} />
      <Title>Page not found</Title>
      <LinkToRoute href={WEB_ROUTE_HOME} style={{ textDecoration: 'none' }}>
        <ButtonStyled sizeParam="S">Go to homepage</ButtonStyled>
      </LinkToRoute>
    </NotFoundPageWrapper>
  );
}

let NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    margin-top: 77px;
    margin-bottom: 45px;
  }

  @media (min-width: 960px) {
    margin-top: 62px;
    margin-bottom: 2px;
  }
`;

let Image = styled.img`
  width: 243px;
  margin-bottom: 4px;

  @media (min-width: 700px) {
    width: 270px;
  }
`;

let Title = styled.h1`
  margin-bottom: 24px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let ButtonStyled = styled(Button)`
  width: 236px;
`;
