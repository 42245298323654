import { useState, useEffect } from 'preact/hooks';
import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import {
  ZE_EVENT_MESSENGER_TYPE,
  handleZendeskOpenChatClick,
} from '@front_common/ZendeskWidget/initializeZendesk';
import styled from 'styled-components';

function checkIsZDWidgetVisible() {
  let zdWidgetVisibility = sessionStorage.getItem('ZD-widgetOpen');

  return zdWidgetVisibility === 'true';
}

type IProps = {
  shortFooter: boolean;
};

export default function SupportChatButton({ shortFooter }: IProps) {
  let [isZDWidgetVisible, setIsChatOpenVisible] = useState(
    checkIsZDWidgetVisible(),
  );

  useEffect(() => {
    function handleStorageChange(event: MessageEvent) {
      if (event.data.type === ZE_EVENT_MESSENGER_TYPE) {
        setIsChatOpenVisible(event.data.variant === 'open');
      }
    }

    window.addEventListener('message', handleStorageChange);

    return () => {
      window.removeEventListener('message', handleStorageChange);
    };
  }, []);

  // Hide button when the chat widget is open
  return isZDWidgetVisible ? null : (
    <SupportIconWrapper
      onClick={handleZendeskOpenChatClick}
      $shortFooter={shortFooter}
    >
      <SupportIcon />
    </SupportIconWrapper>
  );
}

let SupportIconWrapper = styled.button<{ $shortFooter: boolean }>`
  position: fixed;
  border-radius: 10px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--buttonPrimary);
  border: 1px solid var(--strokeBrand);
  right: 40px;
  bottom: ${(props) => (props.$shortFooter ? 90 : 40)}px;
  width: 52px;
  height: 52px;

  svg {
    stroke: #fff;
    path {
      fill: #fff;
    }
  }

  @media (min-width: 700px) {
    display: flex;
    bottom: ${(props) => (props.$shortFooter ? 90 : 60)}px;
  }
  @media (min-width: 960px) {
    bottom: ${(props) => (props.$shortFooter ? 90 : 40)}px;
  }
`;
