export type ParsedFormData = { [key: string]: string };

export function submitHandlerFactory(
  callback: (data: ParsedFormData) => void | Promise<void>,
) {
  return (event: Event) => {
    event.preventDefault();
    // eslint-disable-next-line xss/no-mixed-html
    let formData = new FormData(event.target as HTMLFormElement);
    let data: ParsedFormData = {};
    // eslint-disable-next-line unicorn/no-array-for-each
    formData.forEach((value: File | string, key) => {
      if (typeof value === 'string') {
        data[key] = value;
      }
    });
    callback(data);
  };
}
