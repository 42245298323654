import AuthForm from './AuthForm/OldDesign/AuthForm';
import styled from 'styled-components';
import Card from '@front_common/Components/Card';
import ChevronLeftIcon from './Icons/ChevronLeftIcon';
import OrangeBackgroundImage from './Images/OrangeBackground.png';
import PaperPlaneImage from './Images/PaperPlane.png';
import { useState } from 'preact/hooks';
import SocialTrust from './SocialTrust';
import Legal from './AuthForm/OldDesign/Legal';

export default function AuthPage() {
  let [isLoading, setIsLoading] = useState(false);
  let [showOTPForm, setShowOTPForm] = useState(false);

  function handleLoadingChange(value: boolean) {
    setIsLoading(value);
  }

  function handleShowOTPChange(value: boolean) {
    setShowOTPForm(value);
  }

  return (
    <AuthPageWrapper data-testid="auth-page-container">
      <BackButtonContainer>
        <BackButton disabled={isLoading} onClick={() => history.back()}>
          <ChevronLeftIcon />
        </BackButton>
      </BackButtonContainer>
      <CardStyled>
        <DesktopCardTop>
          <img src={OrangeBackgroundImage} alt="orange background" />
        </DesktopCardTop>
        <DesktopCardBottom>
          <CardTop>
            <Title data-testid="form-identifier-field-label">
              {showOTPForm
                ? 'Enter the verification code'
                : 'Please enter your email'}
            </Title>
            {showOTPForm ? (
              <DesktopTitleIllustration
                src={PaperPlaneImage}
                alt="orange paper airplane"
              />
            ) : (
              <SocialTrustDesktop>
                <SocialTrust />
              </SocialTrustDesktop>
            )}
          </CardTop>
          <CardBottom>
            <AuthForm
              onLoadingChange={handleLoadingChange}
              onShowOTPChange={handleShowOTPChange}
            />
            {showOTPForm ? null : (
              <LegalDesktop>
                <Legal text="By continuing, you agree to our" />
              </LegalDesktop>
            )}
            {showOTPForm ? null : (
              <SocialTrustMobile>
                <SocialTrust />
                <Legal text="By continuing, you agree to our" />
              </SocialTrustMobile>
            )}
          </CardBottom>
        </DesktopCardBottom>
      </CardStyled>
    </AuthPageWrapper>
  );
}

let AuthPageWrapper = styled.div`
  @media (min-width: 700px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 64px;
  }

  @media (min-width: 960px) {
    padding-top: 124px;
  }
`;

let BackButtonContainer = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: 700px) {
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  &:disabled {
    cursor: default;
  }

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
  }
`;

let CardStyled = styled(Card)`
  padding: 0;
  overflow: visible;

  @media (min-width: 960px) {
    max-width: 846px;
    margin: 0 auto;
  }
`;

let CardTop = styled.div`
  padding: 20px;
  background-color: var(--orange90);
  border-radius: 15px 15px 0 0;

  @media (min-width: 960px) {
    flex: 1;
    background-color: transparent;
    padding: 40px;
  }
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  @media (min-width: 960px) {
    flex: 1;
    padding: 40px;
  }
`;

let DesktopCardTop = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: block;
    height: 170px;

    img {
      width: 100%;
      height: auto;
      border-radius: 15px 15px 0 0;
    }
  }
`;

let DesktopCardBottom = styled.div`
  @media (min-width: 960px) {
    display: flex;
    width: 100%;
  }
`;

let DesktopTitleIllustration = styled.img`
  display: none;

  @media (min-width: 960px) {
    display: inline;
    margin-top: 24px;
    width: 279px;
    height: auto;
  }
`;

let SocialTrustDesktop = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: block;
    margin-top: 24px;
  }
`;

let SocialTrustMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    display: none;
  }
`;

let LegalDesktop = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: block;
  }
`;
