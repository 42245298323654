import styled from 'styled-components';
import Loader from './Loader';

type Size = 'S' | 'M' | 'L';
type TypeStyle = 'Primary' | 'Secondary' | 'Transparent';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  sizeParam?: Size;
  typeStyle?: TypeStyle;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

function getHeight(size: Size): number {
  switch (size) {
    case 'S': {
      return 40;
    }
    case 'M': {
      return 48;
    }
    case 'L': {
      return 52;
    }
    default: {
      return 40;
    }
  }
}

export default function Button(props: ButtonProps) {
  let {
    sizeParam = 'S',
    typeStyle = 'Primary',
    children,
    leftIcon,
    rightIcon,
    isLoading = false,
    ...rest
  } = props;

  let content = (
    <>
      {isLoading ? (
        <Loader className="loader" />
      ) : (
        <>
          {leftIcon && <IconWrapper $size={sizeParam}>{leftIcon}</IconWrapper>}
          {children}
          {rightIcon && (
            <IconWrapper $size={sizeParam}>{rightIcon}</IconWrapper>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {typeStyle === 'Primary' && (
        // @ts-expect-error  @TODO fix this pls
        <PrimaryButton
          $height={getHeight(sizeParam)}
          $size={sizeParam}
          style={{
            pointerEvents: isLoading ? 'none' : 'initial',
          }}
          {...rest}
        >
          {content}
        </PrimaryButton>
      )}
      {typeStyle === 'Secondary' && (
        // @ts-expect-error  @TODO fix this pls
        <SecondaryButton
          $height={getHeight(sizeParam)}
          $size={sizeParam}
          style={{
            pointerEvents: isLoading ? 'none' : 'initial',
          }}
          {...rest}
        >
          {content}
        </SecondaryButton>
      )}
      {typeStyle === 'Transparent' && (
        // @ts-expect-error  @TODO fix this pls
        <TransparentButton
          $height={getHeight(sizeParam)}
          $size={sizeParam}
          style={{
            pointerEvents: isLoading ? 'none' : 'initial',
          }}
          {...rest}
        >
          {content}
        </TransparentButton>
      )}
    </>
  );
}

let IconWrapper = styled.div<{ $size: Size }>`
  height: ${(props) => (props.$size === 'S' ? '16px' : '20px')};

  svg {
    width: ${(props) => (props.$size === 'S' ? '16px' : '20px')};
    height: ${(props) => (props.$size === 'S' ? '16px' : '20px')};
  }
`;

let BaseButton = styled.button`
  border-radius: 10px;
  padding: 0 16px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  outline: transparent;
  white-space: nowrap;
`;

let PrimaryButton = styled(BaseButton)<{
  $height: number;
  $size: Size;
}>`
  background-color: var(--buttonPrimary);
  border: none;
  color: var(--textPrimaryInvert);
  font-size: ${(props) =>
    props.$size === 'S' ? 'var(--fontSizeSmall)' : 'var(--fontSizeRegular)'};
  height: ${(props) => props.$height + 'px'};

  &:hover:not(:disabled) {
    background-color: var(--buttonHover);
  }

  &:active:not(:disabled) {
    background-color: var(--buttonPressed);
  }

  &:focus:focus-visible {
    border: 4px solid var(--buttonFocusStroke);
  }

  &:disabled {
    cursor: default;
    opacity: 0.45;
  }

  .loader {
    color: var(--iconsPrimary);

    path {
      fill: var(--iconsPrimary);
    }
  }
`;

let SecondaryButton = styled(BaseButton)<{ $height: number; $size: Size }>`
  font-size: ${(props) =>
    props.$size === 'S' ? 'var(--fontSizeSmall)' : 'var(--fontSizeRegular)'};
  height: ${(props) => props.$height + 'px'};

  background-color: transparent;
  border: 1px solid var(--strokeBrand);
  color: var(--textSelected);

  &:hover:not(:disabled) {
    color: var(--textHover);
    border: 1px solid var(--buttonHover);
  }

  &:active:not(:disabled) {
    color: var(--buttonPressed);
    border: 1px solid var(--buttonPressed);
  }

  &:focus:focus-visible {
    border: 4px solid var(--buttonFocusStroke);
  }

  &:disabled {
    cursor: default;
    opacity: 0.45;
  }

  .loader {
    color: var(--iconsPrimaryBrand);

    path {
      fill: var(--iconsPrimaryBrand);
    }
  }
`;

let TransparentButton = styled(BaseButton)<{ $height: number; $size: Size }>`
  font-size: ${(props) =>
    props.$size === 'S' ? 'var(--fontSizeSmall)' : 'var(--fontSizeRegular)'};
  height: ${(props) => props.$height + 'px'};

  background-color: transparent;
  color: var(--textSelected);

  &:hover:not(:disabled) {
    color: var(--textHover);
  }

  &:active:not(:disabled) {
    color: var(--buttonPressed);
  }

  &:focus:focus-visible {
    border: 4px solid var(--buttonFocusStroke);
  }

  &:disabled {
    cursor: default;
    opacity: 0.45;
  }

  .loader {
    color: var(--iconsPrimaryBrand);

    path {
      fill: var(--iconsPrimaryBrand);
    }
  }
`;
