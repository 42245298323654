import NetworkSVG from './Icons/NetworkSVG';
import CommentsSVG from './Icons/CommentsSVG';
import RocketIcon from './Icons/RocketWhiteSVG';
import MarkerSVG from './Icons/MarkerSVG';
import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';

type IProps = {
  scrollToMainInput: () => void;
};

export default function WhatYouGet({ scrollToMainInput }: IProps) {
  return (
    <WhatYouGetWrapper>
      <Header>What you get</Header>
      <ContentWrapper>
        <ItemsSection>
          <Bullet>
            <IconBox>
              <NetworkSVG />
            </IconBox>
            <CardFirstRow>
              <ItemTitle>Hotspot capabilities:</ItemTitle>
              <ItemDescription>
                Connect multiple devices with stable, fast internet.
              </ItemDescription>
            </CardFirstRow>
          </Bullet>
          <Bullet>
            <IconBox>
              <CommentsSVG />
            </IconBox>
            <CardFirstRow>
              <ItemTitle>Support for iMessage and FaceTime:</ItemTitle>
              <ItemDescription>
                Stay connected with family and friends.
              </ItemDescription>
            </CardFirstRow>
          </Bullet>
        </ItemsSection>
        <ItemsSection>
          <Bullet>
            <IconBox>
              <RocketIcon />
            </IconBox>
            <CardFirstRow>
              <ItemTitle>High-speed connections:</ItemTitle>
              <ItemDescription>
                In any location, ensuring reliable internet access.
              </ItemDescription>
            </CardFirstRow>
          </Bullet>
          <Bullet>
            <IconBox>
              <MarkerSVG />
            </IconBox>
            <CardFirstRow>
              <ItemTitle>Zero roaming fees:</ItemTitle>
              <ItemDescription>
                Enjoy your travels without the worry of high roaming charges.
              </ItemDescription>
            </CardFirstRow>
          </Bullet>
        </ItemsSection>
      </ContentWrapper>
      <CTAButton onClick={scrollToMainInput} sizeParam="L">
        Get started
      </CTAButton>
    </WhatYouGetWrapper>
  );
}

let WhatYouGetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 24px;
  background-color: var(--backgroundPrimaryNew);
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    margin: 0 32px 32px 32px;
    border-radius: 16px;
  }

  @media (min-width: 960px) {
    margin: 0 auto 32px auto;
    width: calc(100% - 64px);
    max-width: 1040px;
  }
`;

let Header = styled.h2`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-weight: var(--fontWeightBold);

  @media (min-width: 960px) {
    text-align: left;
  }
`;

let ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

let ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

let Bullet = styled.div`
  display: flex;
  text-align: initial;

  @media (min-width: 700px) {
    flex-direction: column;
    padding-bottom: 0;
    position: relative;
    row-gap: 24px;
    width: 50%;
    &:nth-child(even) {
      margin-left: 24px;
    }
  }
`;

let IconBox = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`;

let CardFirstRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
  row-gap: 8px;
`;

let ItemTitle = styled.b`
  display: block;
  line-height: 24px;
  font-size: 20px;
  font-weight: var(--fontWeightBold);
`;

let ItemDescription = styled.span`
  line-height: 22px;
  opacity: 75%;
  color: var(--textPrimaryNew);
  font-size: var(--fontSizeRegular);
`;

let CTAButton = styled(Button)`
  width: 100%;
  background-color: var(--textPrimaryNew);
  color: var(--textPrimaryInvert);

  &:hover {
    background-color: var(--black100) !important;
  }

  @media (min-width: 960px) {
    width: 164px;
  }
`;
