import { countryNames } from '@shared_backend/Module/Geo/CountryNames';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import { WEB_ROUTE_STORE_COUNTRY } from '@shared_frontend/Common/WebRoutes';

export function getCountryShortCodeBySlug(countrySlug: string) {
  let code = countrySlug.split('-').at(-1)?.toUpperCase();

  // Exception for 'US-HI': 'Hawaii'
  if (countrySlug.includes('hawaii')) {
    return `US-${code}`;
  }

  return countrySlug.split('-').at(-1)?.toUpperCase();
}

export function getESimCountryUrlByCountryCode(countryCode: string) {
  let storeUrl = countryNames[countryCode];

  if (!storeUrl) {
    throw new Error(`Could not find country by code "${countryCode}"`);
  }

  return `${fillRouteParams(WEB_ROUTE_STORE_COUNTRY, {
    countrySlug: getESimCountrySlug(storeUrl, countryCode),
  })}/`;
}

export function getESimCountrySlug(name: string, code: string) {
  let formattedName = name
    .toLowerCase()
    .replaceAll(/[^a-z0-9]+/gu, '-')
    .replaceAll(/-+/gu, '-')
    .replaceAll(/^-|-$/gu, '');

  return `${formattedName}-${code.toLowerCase()}`;
}

function generateESimCountryRedirects(
  countries: Record<string, string>,
): Record<string, string> {
  let redirects: Record<string, string> = {};

  for (let [code, name] of Object.entries(countries)) {
    redirects[`/store/${code}`] = `/esim/${getESimCountrySlug(name, code)}/`;
  }

  return redirects;
}

// TODO: move to the SEO folder?
let countriesRedirects = generateESimCountryRedirects(countryNames);

export default countriesRedirects;
