import styled from 'styled-components';
import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import InfoIcon from './Icons/InfoIcon';
import { useRef, useState, useEffect } from 'preact/hooks';
import LogoPictureOhayu from '@shared_frontend/Module/Layout/Logo_ohayu_dark.png';
import { CONFIG_API_HOST, CONFIG_MIRROR_NAME } from '@front_common/Config.ts';
import { getQueryParam } from '@front_common/Router/Router';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import { API_ROUTE_QRCODE } from '@shared_backend/ApiRoutes.ts';
import { MODAL_HEIGHT } from '@front_common/Components/Modal/OldDesign/Modal';

export default function AppleQrCodePage() {
  let [maxHeight, setMaxHeight] = useState('0');
  let esimId = getQueryParam('esim_id') ?? '';
  let country = getQueryParam('country') ?? '';
  let packageSize = getQueryParam('package_size') ?? '';
  let qrcode = getQueryParam('qrcode') ?? '';

  function getQrCodeUrl() {
    return (
      CONFIG_API_HOST +
      fillRouteParams(API_ROUTE_QRCODE, { base64data: qrcode })
    );
  }

  let topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef.current && esimId) {
      let calculatedHeight = `calc(${MODAL_HEIGHT} - ${topRef.current.scrollHeight}px - 52px - 20px - 20px - 20px)`;
      setMaxHeight(`${calculatedHeight}`);
    }
  }, [esimId]);

  return (
    <Wrapper>
      <Header>
        <LogoImage
          src={LogoPictureOhayu}
          alt={CONFIG_MIRROR_NAME.toLowerCase()}
        />
      </Header>
      <InstallModalContentStyled>
        <Top ref={topRef}>
          <Title>eSIM Installation</Title>
          <Subtitle>
            Your QR-code for{' '}
            <Plan>
              <>
                {packageSize}GB in {getEsimPlanCountry(country)}{' '}
                <EsimPlanFlag targetCountry={country} />
              </>
            </Plan>
          </Subtitle>
        </Top>
        <Bottom>
          <QrInfo $maxHeight={maxHeight}>
            <Text>
              <b>Hard press</b> on the QR code or <b>scan the code</b> with your
              camera and follow the steps on your screen to complete the eSIM
              setup.
            </Text>
            <QRCodeImageWrapper>
              <QRCodeImageStyled src={getQrCodeUrl()} alt="qrcode" />{' '}
            </QRCodeImageWrapper>
            <InfoBlock>
              <InfoIcon />
              <InfoText>
                You need to be connected to the internet to install the eSIM.
              </InfoText>
            </InfoBlock>
            <InfoBlock>
              <InfoIcon />
              <InfoText>
                eSIM activation period starts when you arrive at your
                destination.
              </InfoText>
            </InfoBlock>
          </QrInfo>
        </Bottom>
      </InstallModalContentStyled>
    </Wrapper>
  );
}
let Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

let Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 40px;
  width: 100%;
  background-color: var(--backgroundSecondary);
`;

let LogoImage = styled.img`
  height: 32px;
  display: block;
`;

let InstallModalContentStyled = styled.div`
  position: relative;
  margin: 38px 16px;
  border-radius: 16px;
  border: var(--borderOutlinePrimary);
  background-color: var(--backgroundSecondary);
  max-width: 744px;

  @media (min-width: 330px) {
    margin: 24px 16px;
  }

  @media (min-width: 393px) {
    margin: 38px 16px;
  }

  @media (min-width: 600px) {
    margin: 7.5% 16px;
  }
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 20px 16px;
  text-align: center;
  border-radius: 15px 15px 0 0;
`;

let Bottom = styled.div`
  padding: 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Subtitle = styled.span`
  font-size: var(--fontSizeBig);
  line-height: 24px;
  text-align: center;
`;

let Plan = styled.span`
  font-weight: var(--fontWeightBold);

  img {
    margin-bottom: -6px;
    height: 24px;
  }
`;

let QrInfo = styled.div<{ $maxHeight: string }>`
  overflow: auto;
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

let Text = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: center;
  margin-bottom: 8px;

  b {
    font-weight: var(--fontWeightBold);
  }
`;

let QRCodeImageWrapper = styled.div`
  width: 120px;
  margin: 0 auto;
  margin-bottom: 8px;
`;

let InfoBlock = styled.div`
  border: var(--borderOutlinePrimary);
  border-radius: 10px;
  padding: 8px;
  display: flex;
  gap: 6px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: initial;
  }

  svg {
    min-width: 20px;
  }
`;

let InfoText = styled.span`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  text-align: left;
`;

let QRCodeImageStyled = styled.img`
  width: 100%;
`;
