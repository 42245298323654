export default function MarkerIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0007 10.8346C11.3814 10.8346 12.5007 9.71535 12.5007 8.33464C12.5007 6.95392 11.3814 5.83464 10.0007 5.83464C8.61994 5.83464 7.50065 6.95392 7.50065 8.33464C7.50065 9.71535 8.61994 10.8346 10.0007 10.8346Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 18.3346C13.334 15.0013 16.6673 12.0165 16.6673 8.33464C16.6673 4.65274 13.6825 1.66797 10.0007 1.66797C6.31875 1.66797 3.33398 4.65274 3.33398 8.33464C3.33398 12.0165 6.66732 15.0013 10.0007 18.3346Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
