import {
  getAppleUniversalLink,
  useGetMyEsimsQuery,
} from '@front_common/Api/UserApi';
import Button from '@front_common/Components/Button/Button';
import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import { getQueryParam, routeRedirect } from '@front_common/Router/Router';
import { EsimEntityType } from '@shared_backend/Module/Esim/EsimEntityType';
import {
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_MY_PROFILE,
} from '@shared_frontend/Common/WebRoutes';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import { useSessionData } from '@front_common/Api/Session';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import {
  SHOW_ESIM_QR,
  SHOW_HINT,
} from '@shared_frontend/Module/InstallFlow/Constants';

const routesForDisplay = [WEB_ROUTE_MY_ESIMS, WEB_ROUTE_MY_PROFILE];
const INSTALL_LATER_CLICKED = 'install_later_clicked';
const INSTALL_NOW_CLICKED = 'install_now_clicked';
const REMINDER_SHOWN = 'reminder_shown';

export function setInstallLaterClicked(esimId: string) {
  let prevString = localStorage.getItem(INSTALL_LATER_CLICKED);
  let prev = prevString && JSON.parse(prevString);

  if (prev) {
    localStorage.setItem(
      INSTALL_LATER_CLICKED,
      JSON.stringify([esimId, ...prev]),
    );
  } else {
    localStorage.setItem(INSTALL_LATER_CLICKED, JSON.stringify([esimId]));
  }
}

export function setInstallNowClicked(esimId: string) {
  let prevString = localStorage.getItem(INSTALL_NOW_CLICKED);
  let prev = prevString && JSON.parse(prevString);

  if (prev) {
    localStorage.setItem(
      INSTALL_NOW_CLICKED,
      JSON.stringify([esimId, ...prev]),
    );
  } else {
    localStorage.setItem(INSTALL_NOW_CLICKED, JSON.stringify([esimId]));
  }
}

export function setReminderShown() {
  sessionStorage.setItem(REMINDER_SHOWN, 'true');
}

export function setReminderClear() {
  sessionStorage.removeItem(REMINDER_SHOWN);
}

export default function ReminderModal() {
  let [isModalOpen, setIsModalOpen] = useState(false);

  let [response] = useGetMyEsimsQuery();
  let esims = response?.esims.filter((esim) =>
    [EsimStatus.NOT_INSTALLED].includes(esim.status),
  );
  esims?.sort((esimA, esimB) => esimA.id - esimB.id);
  let firstNotActivatedEsim = esims && esims[0];

  useEffect(() => {
    let pathname = window.location.pathname;
    let showHintParam = getQueryParam(SHOW_HINT);
    let esimIdParam = getQueryParam(SHOW_ESIM_QR);
    let reminderShown = sessionStorage.getItem(REMINDER_SHOWN);

    let installLaterClickedString = localStorage.getItem(INSTALL_LATER_CLICKED);
    let installNowClickedString = localStorage.getItem(INSTALL_NOW_CLICKED);

    let installLaterClicked: Array<string> | null =
      installLaterClickedString && JSON.parse(installLaterClickedString);
    let installNowClicked: Array<string> | null =
      installNowClickedString && JSON.parse(installNowClickedString);

    if (
      firstNotActivatedEsim &&
      routesForDisplay.includes(pathname) &&
      !showHintParam &&
      !esimIdParam &&
      !reminderShown &&
      installLaterClicked?.includes(String(firstNotActivatedEsim.id)) &&
      !installNowClicked?.includes(String(firstNotActivatedEsim.id))
    ) {
      setReminderShown();
      setIsModalOpen(true);
    }
  }, [firstNotActivatedEsim]);

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Modal
      analyticType="reminder"
      isOpen={isModalOpen}
      onClose={closeModal}
      overlayClick={false}
    >
      {firstNotActivatedEsim && (
        <ReminderModalContent
          onInstallLaterClick={closeModal}
          esim={firstNotActivatedEsim}
        />
      )}
    </Modal>
  );
}

type ReminderModalContentProps = {
  onInstallLaterClick: () => void;
  esim: EsimEntityType;
};

function ReminderModalContent(props: ReminderModalContentProps) {
  let { onInstallLaterClick, esim } = props;

  let [appleUniversalLink, setAppleUniversalLink] = useState(false);

  let sessionData = useSessionData();

  useEffect(() => {
    if (
      sessionData &&
      sessionData.os.name === 'iOS' &&
      sessionData.os.version &&
      Number.parseFloat(sessionData.os.version) >= 17.4
    ) {
      setAppleUniversalLink(true);
    }
  }, [sessionData]);

  function handleInstall(isAppleUniversalLink?: boolean) {
    if (isAppleUniversalLink) {
      routeRedirect(WEB_ROUTE_MY_ESIMS);
    } else {
      routeRedirect(WEB_ROUTE_MY_ESIMS, {
        [SHOW_ESIM_QR]: String(esim?.id),
      });
    }
  }

  function handleInstallLater() {
    onInstallLaterClick();
  }

  if (!esim.targetCountry) {
    // TODO process "null" better
    logger.log('no target country ' + esim);
    return <>no target country</>;
  }

  return (
    <InstallModalContentStyled>
      <Top>
        <Title>Are you ready to install your eSIM?</Title>
        <Subtitle>
          {esim.dataPurchasedGb}GB in {getEsimPlanCountry(esim.targetCountry)}
          <EsimPlanFlag targetCountry={esim.targetCountry} />
        </Subtitle>
      </Top>
      <Bottom>
        {appleUniversalLink ? (
          <AnchorStyled
            href={getAppleUniversalLink(esim.smdpAddress, esim.activationCode)}
            title="iOS 17.4 and later eSIM install link"
          >
            <ButtonStyled sizeParam="L" onClick={() => handleInstall(true)}>
              Install eSIM now
            </ButtonStyled>
          </AnchorStyled>
        ) : (
          <ButtonStyled sizeParam="L" onClick={() => handleInstall()}>
            Install eSIM now
          </ButtonStyled>
        )}
        <ButtonStyled
          sizeParam="L"
          typeStyle="Secondary"
          onClick={handleInstallLater}
        >
          Install eSIM later
        </ButtonStyled>
      </Bottom>
    </InstallModalContentStyled>
  );
}

let InstallModalContentStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 32px 20px 20px;
`;

let Bottom = styled.div`
  padding: 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Subtitle = styled.span`
  font-size: var(--fontSizeRegular);
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  text-align: left;
  display: inline-flex;
  gap: 4px;

  img {
    height: 24px;
  }
`;

let ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: initial;
  }
`;

let AnchorStyled = styled.a`
  display: block;
  margin-bottom: 16px;
  text-decoration: none;
`;
