import { useState } from 'preact/hooks';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import {
  WEB_ROUTE_MY_ESIM,
  WEB_ROUTE_MY_SUBSCRIPTIONS_UNSUBSCRIBE,
} from '@shared_frontend/Common/WebRoutes';
import DotsIcon from './Icons/Dots';
import SimCard from './Icons/Simcard';
import SlashCircle from './Icons/SlashCircle';
import { routeRedirect } from '@front_common/Router/Router';
import styled from 'styled-components';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

function handleViewESim(esimId: number | null) {
  if (!esimId) {
    logger.error('404: eSIM ID is not defined');
    throw new Error('404: eSIM ID is not defined');
  }

  routeRedirect(fillRouteParams(WEB_ROUTE_MY_ESIM, { esimId }));
}

type IProps = {
  subscriptionId: number;
  eSimId: number | null;
  isCancelable?: boolean;
};

export default function SubscriptionAction({
  subscriptionId,
  eSimId,
  isCancelable,
}: IProps) {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <ActionsContainer
      onClick={() => setIsOpen(true)}
      //TODO: Is this strict requirement?
      // because it is very easy to move the mouse not strictly down, and the menu will hidden
      onMouseLeave={() => setIsOpen(false)}
    >
      <DotsIconWrapper>
        <DotsIcon />
      </DotsIconWrapper>
      <ActionsListWrapper $isOpen={isOpen}>
        <ActionItem onClick={() => handleViewESim(eSimId)}>
          <SimCard></SimCard>
          <span>View eSim</span>
        </ActionItem>
        {isCancelable && (
          <ActionItemLink
            href={fillRouteParams(WEB_ROUTE_MY_SUBSCRIPTIONS_UNSUBSCRIBE, {
              subscriptionId,
            })}
          >
            <SlashCircle />
            <CancelSubscriptionBtnTitle>
              Cancel subscription
            </CancelSubscriptionBtnTitle>
          </ActionItemLink>
        )}
      </ActionsListWrapper>
    </ActionsContainer>
  );
}

let ActionsContainer = styled.div`
  position: relative;
`;

let DotsIconWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: var(--grey70);
`;

let ActionsListWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  right: 0px;
  background-color: transparent;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
`;

//TODO: use let
const ActionItem = styled.button`
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 44px;
  padding: 12px;
  cursor: pointer;
  line-height: 20px;
  width: 100%;
  border: none;
  background-color: var(--backgroundSecondary);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);

  &:hover {
    background-color: var(--itemHover);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--strokeSecondary);
  }

  a {
    width: 100%;
    text-decoration: none;
    color: var(--textPrimary);
  }

  svg {
    margin-right: 8px;
  }
`;

let ActionItemLink = styled.a`
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 44px;
  padding: 12px;
  cursor: pointer;
  line-height: 20px;
  width: 100%;
  border: none;
  background-color: var(--backgroundSecondary);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  text-decoration: none;

  &:hover {
    background-color: var(--itemHover);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--strokeSecondary);
  }

  a {
    width: 100%;
    text-decoration: none;
    color: var(--textPrimary);
  }

  svg {
    margin-right: 8px;
  }
`;

let CancelSubscriptionBtnTitle = styled.span`
  color: var(--red100);
`;
