import { sha256 } from '@shared_frontend/Common/Sha256';

type TikTokObject = {
  methods: string | any[];
  setAndDefer: (t: any, e: any) => void;
  instance: (t: any) => any;
  _i: { [x: string]: { _u: string } };
  load: (arg0: string) => any;
  _t: { [x: string]: number };
  _o: { [x: string]: unknown };
  page: () => void;
  identify(param: { external_id: string }): Promise<void>;
  track(
    event: string,
    param2?: { event_id: number; currency: string; value: string | number },
  ): Promise<any>;
};

export const TikTokPixel = {
  identifyUser,
  trackPayment,
  trackReg,
};

function getTikTokObject(): TikTokObject | null {
  // script added in AnalyticsPixelScripts.ts
  // @ts-ignore
  return window['ttq'] ?? null;
}

async function identifyUser(userId: number) {
  let ttq = getTikTokObject();
  if (!ttq) {
    return;
  }
  await ttq.identify({
    external_id: sha256(userId),
  });
}

async function trackReg() {
  let ttq = getTikTokObject();
  if (!ttq) {
    return;
  }
  await ttq.track('CompleteRegistration');
}

async function trackPayment(
  totalAmountOfDollars: number,
  currency: string,
  deduplicationId: number,
) {
  let ttq = getTikTokObject();
  if (!ttq) {
    return;
  }
  await ttq.track('CompletePayment', {
    value: totalAmountOfDollars,
    currency,
    event_id: deduplicationId,
  });
}
