import styled from 'styled-components';
import CloseIcon from './CloseIcon';
import { createPortal, useEffect, useState } from 'react';
import { useRef } from 'preact/hooks';
import Api from '@front_common/Api/Api';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: (event: MouseEvent) => void;
  analyticType: string;
  closeButtonPrimary?: boolean;
  overlayClick?: boolean;
  overlayStyles?: React.CSSProperties;
  windowStyles?: React.CSSProperties;
  hideCloseButton?: boolean;
};

export default function Modal(props: ModalProps) {
  let {
    isOpen,
    children,
    onClose,
    analyticType,
    closeButtonPrimary = true,
    overlayClick = true,
    overlayStyles,
    windowStyles,
    hideCloseButton = false,
  } = props;

  let [startOpening, setStartOpening] = useState(false);

  let isMounted = useRef(false);
  let prevIsOpen = useRef(isOpen);
  let overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      if (isOpen) {
        setStartOpening(true);
        Api.trackAction(ActionTypes.modal_open, analyticType);
      }
      return;
    }

    if (isOpen) {
      setStartOpening(true);
      Api.trackAction(ActionTypes.modal_open, analyticType);
    } else if (prevIsOpen.current) {
      setStartOpening(false);
      Api.trackAction(ActionTypes.modal_close, analyticType);
    }

    prevIsOpen.current = isOpen;
  }, [isOpen, analyticType]);

  useEffect(() => {
    function handleTouchMove(event: TouchEvent) {
      let eventTarget = event.target;

      if (
        isOpen &&
        overlayRef.current &&
        eventTarget instanceof Node &&
        overlayRef.current === eventTarget
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    if (isOpen) {
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isOpen, overlayRef.current]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    [],
  );

  if (!isOpen) {
    document.body.style.overflow = 'auto';
    return null;
  }

  function handleCloseModal(event: MouseEvent) {
    onClose(event);
  }

  document.body.style.overflow = 'hidden';

  return createPortal(
    <ModalOverlay
      onClick={overlayClick ? handleCloseModal : undefined}
      $startOpening={Boolean(startOpening)}
      style={overlayStyles}
      ref={overlayRef}
    >
      <ModalWindow
        style={windowStyles}
        onClick={(event) => event.stopPropagation()}
      >
        {!hideCloseButton && (
          <ModalCloseButton
            onClick={handleCloseModal}
            $closeButtonPrimary={closeButtonPrimary}
          >
            <CloseIcon />
          </ModalCloseButton>
        )}
        {children}
      </ModalWindow>
    </ModalOverlay>,
    document.body,
  );
}

export const MODAL_HEIGHT = '80vh';

let ModalOverlay = styled.div<{ $startOpening: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overscroll-behavior-y: contain;
  opacity: ${(props) => (props.$startOpening ? '1' : '0')};
  transition: opacity 0.2s;
`;

let ModalWindow = styled.div`
  background: white;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  max-height: ${MODAL_HEIGHT};
  width: calc(100% - 32px);
  overscroll-behavior-y: contain;

  @media (min-width: 700px) {
    width: 530px;
  }
`;

export let ModalCloseButton = styled.button<{ $closeButtonPrimary?: boolean }>`
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
  background-color: ${(props) =>
    props.$closeButtonPrimary
      ? 'var(--orange70)'
      : 'var(--backgroundSecondary)'};
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
