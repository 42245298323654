import { useContext } from 'preact/hooks';
import { UnsubscribeContext } from './UnsubscribeProvider';
import Button from '@front_common/Components/Button/Button';
import CheckMark from '../Icons/CheckMark';
import MoneyImage from '../Images/Money.png';
import DataSignImage from '../Images/DataSign.png';
import ToolsImage from '../Images/Tools.png';
import MagnifierImage from '../Images/Magnifier.png';
import PlaneImage from '../Images/Plane.png';
import EarthImage from '../Images/Earth.png';
import SmileImage from '../Images/Smile.png';
import styled from 'styled-components';

let items = [
  {
    icon: MoneyImage,
    title: 'Too expensive',
  },
  {
    icon: DataSignImage,
    title: 'Not enough data for my needs',
  },
  {
    icon: ToolsImage,
    title: 'Having connection or service issues',
  },
  {
    icon: MagnifierImage,
    title: 'Found a cheaper or better alternative',
  },
  {
    icon: PlaneImage,
    title: 'I’m not traveling right now',
  },
  {
    icon: EarthImage,
    title: 'Doesn’t cover the countries I visit',
  },
  {
    icon: SmileImage,
    title: 'Other',
  },
];

type ISelectedItemProps = {
  label: string;
  isChecked: boolean;
  icon: string;
  onClick?: () => void;
};

function SelectItem({ label, icon, isChecked, onClick }: ISelectedItemProps) {
  return (
    <SelectButton onClick={onClick}>
      <ItemWrapper>
        <ItemIcon src={icon} alt="item-image" />
        <ItemLabel>{label}</ItemLabel>
      </ItemWrapper>
      <ItemCheckedWrapper>
        {isChecked ? <CheckMark /> : <ItemUnchecked />}
      </ItemCheckedWrapper>
    </SelectButton>
  );
}

export default function FirstStep() {
  let context = useContext(UnsubscribeContext);

  if (!context) {
    throw new Error('UnsubscribeContext must be used within a provider');
  }

  let {
    setStep,
    unsubscribeData: { cancellationReasons },
    updateUnsubscribeData,
  } = context;

  //TODO: maybe better to use some id instead of title?
  function handleButtonClick(title: string) {
    if (cancellationReasons.includes(title)) {
      updateUnsubscribeData(
        'cancellationReasons',
        cancellationReasons.filter((item) => item !== title),
      );
    } else {
      updateUnsubscribeData('cancellationReasons', [
        ...cancellationReasons,
        title,
      ]);
    }
  }

  return (
    <>
      <FirstStepWrapper>
        <FirstStepPageTitle>
          Why are you canceling your global plan?
        </FirstStepPageTitle>
        <FirstStepPageSubTitle>
          Your answer will help us improve our service for future users.
        </FirstStepPageSubTitle>
        {items.map((item) => (
          <SelectItem
            icon={item.icon}
            key={item.title}
            label={item.title}
            onClick={() => handleButtonClick(item.title)}
            isChecked={cancellationReasons.includes(item.title)}
          />
        ))}
      </FirstStepWrapper>
      <Footer>
        <ContinueButton
          sizeParam="L"
          onClick={() => setStep(2)}
          disabled={cancellationReasons.length === 0}
        >
          Continue
        </ContinueButton>
      </Footer>
    </>
  );
}

let FirstStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media (min-width: 700px) {
    margin-bottom: 8px;
  }
`;

let FirstStepPageTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
`;

let FirstStepPageSubTitle = styled.p`
  font-weight: var(--fontWeightRegular);
  margin-bottom: 22px;
`;

let SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 17px 16px;
  background-color: var(--backgroundSecondary);
  border: 1px solid var(--strokeSecondary);
  border-radius: 16px;
  cursor: pointer;
  min-height: 56px;
  color: var(--textPrimary);

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

let ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

let ItemIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

let ItemLabel = styled.p`
  text-align: left;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
`;

let ItemCheckedWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

let ItemUnchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--strokeSecondary);
`;

let Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
  background-color: var(--backgroundSecondary);
  z-index: 200;

  @media (min-width: 700px) {
    position: relative;
    background-color: transparent;
    padding: 24px 0;
    bottom: 0;
  }
`;

let ContinueButton = styled(Button)`
  width: 100%;

  @media (min-width: 700px) {
    max-width: 642px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;
