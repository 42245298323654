import styled from 'styled-components';
import { ReactElement } from 'react';
import CalendarIcon from './Icons/CalendarIcon';
import BellIcon from './Icons/BellIcon';

type IProps = {
  label: string;
  value: string;
  children?: ReactElement;
};

export default function ESimPagePlanInfo({
  label,
  value,
  children: statusElement,
}: IProps) {
  return (
    <ContentWrapperStyled>
      <ContentRowStyled>
        <ContentLabelStyled>
          <CalendarIcon />
          <span>{label}</span>
        </ContentLabelStyled>
        <ContentValueStyled>{value}</ContentValueStyled>
      </ContentRowStyled>
      {statusElement && (
        <ContentRowStyled>
          <ContentLabelStyled>
            <BellIcon />
            <span>Status</span>
          </ContentLabelStyled>
          {statusElement}
        </ContentRowStyled>
      )}
    </ContentWrapperStyled>
  );
}

let ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: var(--borderOutlinePrimary);
  border-radius: var(--borderRadiusLarge);
  background-color: var(--backgroundSecondary);
`;

let ContentRowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

let ContentLabelStyled = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 22px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);

  svg {
    margin-right: 8px;
  }
`;
let ContentValueStyled = styled.span`
  line-height: 22px;
  text-align: right;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;
