import { GuideCard, GuideStep } from '../GuideCard';
import { ActivationCodeBox } from '../ActivationCodeBox';
import styled from 'styled-components';
import Warning from '../Warning';

import AppleManualInstallS1S1 from './Images/Manual/AppleManualInstallS1S1.png';

import AppleManualInstallS2S1 from './Images/Manual/AppleManualInstallS2S1.png';

import AppleManualInstallS3S1 from './Images/Manual/AppleManualInstallS3S1.png';

import AppleManualInstallS4S1 from './Images/Manual/AppleManualInstallS4S1.png';

import AppleManualInstallS5S1 from './Images/Manual/AppleManualInstallS5S1.png';
import AppleManualInstallS5S2 from './Images/Manual/AppleManualInstallS5S2.png';

import AppleQRInstallS2S1 from './Images/Qr/AppleQRInstallS2S1.png';
import AppleQRInstallS2S2 from './Images/Qr/AppleQRInstallS2S2.png';
import AppleQRInstallS2S3 from './Images/Qr/AppleQRInstallS2S3.png';
import AppleQRInstallS2S4 from './Images/Qr/AppleQRInstallS2S4.png';

import AppleQRInstallS3S1 from './Images/Qr/AppleQRInstallS3S1.png';
import AppleQRInstallS3S2 from './Images/Qr/AppleQRInstallS3S2.png';
import AppleQRInstallS3S3 from './Images/Qr/AppleQRInstallS3S3.png';
import AppleQRInstallS3S4 from './Images/Qr/AppleQRInstallS3S4.png';

import AppleQRInstallS4S1 from './Images/Qr/AppleQRInstallS4S1.png';

import AppleQRActivationS1S1 from './Images/Qr/AppleQRActivationS1S1.png';
import AppleQRActivationS1S2 from './Images/Qr/AppleQRActivationS1S2.png';
import AppleQRActivationS1S3 from './Images/Qr/AppleQRActivationS1S3.png';

import AppleQRActivationS2S1 from './Images/Qr/AppleQRActivationS2S1.png';
import AppleQRActivationS2S2 from './Images/Qr/AppleQRActivationS2S2.png';

const ACTIVATION_STEPS: GuideStep[] = [
  {
    stepNumber: 1,
    title: 'Enable Data Roaming',
    mainText: (
      <ul>
        <li>{`Tap on your newly added eSIM plan in the "Mobile Service" ("Mobile Data" for iOS 16 and earlier versions).`}</li>
        <li>{`And turn on "Data Roaming" with the help of the toggle.`}</li>
      </ul>
    ),
    images: [
      AppleQRActivationS1S1,
      AppleQRActivationS1S2,
      AppleQRActivationS1S3,
    ],
  },
  {
    stepNumber: 2,
    title: 'Make sure eSIM is a preferred data plan',
    mainText: (
      <ul>
        <li>{`Go back to "Mobile Service" and then go to "Mobile Data".`}</li>
        <li>{`Click on your newly installed eSIM plan to use it as a source of internet data.`}</li>
      </ul>
    ),
    images: [AppleQRActivationS2S1, AppleQRActivationS2S2],
  },
];

type AppleManualContentProps = {
  esimId: string | undefined;
  forGeneralPage: boolean;
};

export function AppleManualTabContent(props: AppleManualContentProps) {
  let { esimId, forGeneralPage } = props;

  let INSTALL_STEPS: GuideStep[] = [
    {
      stepNumber: 1,
      title: 'Navigate to Mobile Service',
      mainText: `In your Settings, tap on "Mobile Service" ("Mobile Data" for iOS 16 and earlier versions). `,
      images: [AppleManualInstallS1S1],
    },
    {
      stepNumber: 2,
      title: 'Add eSIM',
      mainText: `Tap on "Add eSIM" ("Add Data Plan" for iOS 16 and earlier versions).`,
      images: [AppleManualInstallS2S1],
    },
    {
      stepNumber: 3,
      title: 'Use QR Code',
      mainText: `Tap on "Use QR Code" (this will lead to screen with manual installation option).`,

      images: [AppleManualInstallS3S1],
    },
    {
      stepNumber: 4,
      title: 'Enter Details Manually',
      mainText: `Tap on "Enter Details Manually".`,
      images: [AppleManualInstallS4S1],
    },
    {
      stepNumber: 5,
      title: 'Enter SM-DP+ Address',
      mainText: forGeneralPage ? (
        <ul>
          <li>{`Enter the SM-DP+ address in the corresponding fields and click "Next" to finish set-up.`}</li>
          <li>{`The activation code can be found in the installation guide, which is provided after your purchase.`}</li>
        </ul>
      ) : (
        `Enter the SM-DP+ address in the corresponding fields and click "Next" to finish set-up.
You may find these codes below.`
      ),
      images: [AppleManualInstallS5S1, AppleManualInstallS5S2],
      additionalBlock: esimId && (
        <ActivationCodeBox forApple={true} esimId={esimId} />
      ),
    },
    {
      stepNumber: 6,
      title: 'Install your eSIM',
      mainText: `Follow on-screen instructions to install your eSIM.`,
      images: [
        AppleQRInstallS2S1,
        AppleQRInstallS2S2,
        AppleQRInstallS2S3,
        AppleQRInstallS2S4,
      ],
    },
    {
      stepNumber: 7,
      title: 'Complete installation',
      mainText: (
        <ul>
          <li>{`Select your personal SIM as your Default line for calls and sms.`}</li>
          <li>{`Select your personal SIM for iMessages & FaceTime.`}</li>
          <li>{`Select your new eSIM as the source for "Mobile Data".`}</li>
          <li>{`Click "Update later" for the preferred line.`}</li>
        </ul>
      ),
      images: [
        AppleQRInstallS3S1,
        AppleQRInstallS3S2,
        AppleQRInstallS3S3,
        AppleQRInstallS3S4,
      ],
    },
    {
      stepNumber: 8,
      title: 'Confirm successful installation',
      mainText: `If eSIM is installed successfully, you will see it in your "SIMs" list in "Mobile Service"  ("Mobile Data" for iOS 16 and earlier versions) with the line turned "On".`,
      images: [AppleQRInstallS4S1],
    },
  ];

  return (
    <div>
      <PaddingWrapper>
        <Warning text="You need to be connected to the internet to install the eSIM." />
      </PaddingWrapper>
      {INSTALL_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={INSTALL_STEPS.length === index + 1}
        />
      ))}
      <PaddingWrapper>
        <ActivationTitle $forGeneralPage={forGeneralPage}>
          Activation
        </ActivationTitle>
        <Warning text="The following steps should be done once you arrive at your destination, to ensure your eSIM's validity starts correctly." />
      </PaddingWrapper>
      {ACTIVATION_STEPS.map((step, index) => (
        <GuideCard
          key={step.stepNumber}
          step={step}
          isLast={ACTIVATION_STEPS.length === index + 1}
        />
      ))}
    </div>
  );
}

let PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin-left: auto;
    margin-right: auto;
  }
`;

let ActivationTitle = styled.h3<{ $forGeneralPage: boolean }>`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  text-align: ${(props) => (props.$forGeneralPage ? 'center' : 'left')};
  margin-top: 32px;
  font-size: ${(props) => (props.$forGeneralPage ? '24px' : '34px')};
  line-height: ${(props) => (props.$forGeneralPage ? '32px' : '40px')};

  @media (min-width: 700px) {
    text-align: center;
    margin-top: 40px;
    font-size: ${(props) => (props.$forGeneralPage ? '28px' : '34px')};
    line-height: ${(props) => (props.$forGeneralPage ? '38px' : '40px')};
  }

  @media (min-width: 960px) {
    margin-top: 56px;
    font-size: 34px;
    line-height: 40px;
  }
`;

export let Popup = styled.div`
  position: fixed;
  width: calc(100% - 32px);
  padding: 14px;
  background-color: rgba(195, 219, 255, 1);
  bottom: 132px;
  left: 16px;
  border-radius: var(--borderRadiusPrimary);
  display: flex;
  gap: 8px;
  z-index: 10;

  span {
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontWeightMedium);
    line-height: 20px;
    width: 100%;
  }

  @media (min-width: 700px) {
    width: calc(100% - 80px);
    left: 40px;
  }

  @media (min-width: 960px) {
    max-width: 846px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
`;

export let IconWrapper = styled.div`
  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`;

export let CloseButton = styled.button`
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
`;
