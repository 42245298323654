import styled from 'styled-components';
import Breadcrumbs, { RouteConfig } from '@front_common/Components/Breadcrumbs';
import {
  WEB_ROUTE_FAQ,
  WEB_ROUTE_FAQ_QUESTION,
  WEB_ROUTE_FAQ_TOPIC,
  WEB_ROUTE_HOME,
} from '@shared_frontend/Common/WebRoutes';
import { useFAQQuestions, useFAQTopics } from '@front_common/Strapi/StrapiFAQ';
import HomeIcon from './Icons/HomeIcon';
import PopularQuestions from './PopularQuestions';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import SupportUserImage from './Images/SupportUser.png';
import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import HaveAnotherQuestion from './HaveAnotherQuestion';
import PageTitleText from '@front_common/Components/PageTitleText';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';

type FaqLayoutProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
};

export default function FaqLayout(props: FaqLayoutProps) {
  let { children, title } = props;

  let [faqQuestions] = useFAQQuestions();
  let [faqTopics] = useFAQTopics();
  let router = useRouterState();
  let topicId = router.matches?.['topicId'] || '';
  let questionId = router.matches?.['questionId'] || '';
  let bigMaxWidth = router.path === WEB_ROUTE_FAQ_QUESTION;
  let showPopularQuestions = router.path === WEB_ROUTE_FAQ;

  function getTopicLabel() {
    return faqTopics?.find((topic) => topic.attributes.slug === topicId)
      ?.attributes.name;
  }

  function getQuestionLabel() {
    return faqQuestions?.find(
      (question) => question.attributes.slug === questionId,
    )?.attributes.question;
  }

  function getTopicHref() {
    return WEB_ROUTE_FAQ_TOPIC.replace(':topicId', topicId);
  }

  function getQuestionHref() {
    return WEB_ROUTE_FAQ_QUESTION.replace(':questionId', questionId);
  }

  let routeConfig: RouteConfig[] = [
    {
      path: WEB_ROUTE_HOME,
      href: WEB_ROUTE_HOME,
      label: (
        <HomeIconWrapper>
          <HomeIcon />
        </HomeIconWrapper>
      ),
      children: [
        {
          path: WEB_ROUTE_FAQ,
          href: WEB_ROUTE_FAQ,
          label: 'FAQ',
          children: [
            {
              path: WEB_ROUTE_FAQ_TOPIC,
              href: getTopicHref(),
              label: getTopicLabel(),
              children: [
                {
                  path: WEB_ROUTE_FAQ_QUESTION,
                  href: getQuestionHref(),
                  label: getQuestionLabel(),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return (
    <FaqLayoutWrapper>
      <Header>
        <BreadcrumbsWrapper>
          <Breadcrumbs
            routeConfig={routeConfig}
            currentPath={router.path || ''}
          />
        </BreadcrumbsWrapper>
        {title && (
          <Title data-testid="faq-page-title-container">
            <PageTitleText>{title}</PageTitleText>
          </Title>
        )}
      </Header>
      <Body $bigMaxWidth={bigMaxWidth}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {showPopularQuestions && (
          <PopularQuestionsWrapper>
            <PopularQuestions />
          </PopularQuestionsWrapper>
        )}

        {topicId === 'troubleshooting' ? (
          <SupportChatWrapper>
            <SupportChatDescription>
              <SupportChatQuestion>
                If you need more assistance, please contact us.
              </SupportChatQuestion>
              <img src={SupportUserImage} alt="party smile" />
            </SupportChatDescription>
            <MenuSubButton onClick={handleZendeskOpenChatClick}>
              <SupportIcon />
              <span>Contact support</span>
            </MenuSubButton>
          </SupportChatWrapper>
        ) : (
          <HaveAnotherQuestionWrapper>
            <HaveAnotherQuestion />
          </HaveAnotherQuestionWrapper>
        )}
      </Body>
    </FaqLayoutWrapper>
  );
}

let FaqLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

let Header = styled.div`
  width: 100%;
  background-color: var(--cardsBackgroundOrange);
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media (min-width: 700px) {
    padding: 20px 40px;
  }

  @media (min-width: 960px) {
    padding: 20px 80px;
  }
`;

let Body = styled.div<{ $bigMaxWidth: boolean }>`
  width: 100%;
  padding: 32px 16px 0px 16px;
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 40px 40px 0px 40px;
  }

  @media (min-width: 960px) {
    padding: 56px 80px 0px 80px;
    max-width: ${(props) => (props.$bigMaxWidth ? '1224px' : '1006px')};
  }
`;

let BreadcrumbsWrapper = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 960px) {
    max-width: 1280px;
  }
`;

let Title = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-size: 34px;
  font-weight: var(--fontWeightBold);
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  padding-top: 4px;
  padding-bottom: 4px;

  @media (min-width: 700px) {
    font-size: 36px;
    line-height: 48px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 960px) {
    max-width: 1280px;
    margin: 0 auto;
    font-size: 52px;
    line-height: 64px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

let ChildrenWrapper = styled.div``;

let PopularQuestionsWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 13px;

  @media (min-width: 700px) {
    margin-top: 40px;
  }

  @media (min-width: 960px) {
    margin-top: 56px;
  }
`;

let HaveAnotherQuestionWrapper = styled.div`
  margin-top: 13px;

  @media (min-width: 960px) {
    margin-top: 29px;
  }
`;

let HomeIconWrapper = styled.div`
  height: 16px;
  min-width: 16px;

  svg {
    width: 16px;
    min-width: 16px;
  }
`;

let SupportChatWrapper = styled.div`
  background-color: var(--orange70);
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;

  @media (min-width: 700px) {
    display: none;
  }
`;

let SupportChatDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  img {
    width: 60px;
    height: 48px;
  }
`;

let SupportChatQuestion = styled.div`
  line-height: 24px;
  font-size: var(--fontSizeBig);
  font-weight: var(--fontWeightRegular);
`;

let MenuSubButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 14px 16px;
  border-radius: 10px;
  color: var(--textLink);
  border: 1px solid var(--strokeBrand);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  background-color: transparent;

  span {
    margin-left: 12px;
  }
`;
