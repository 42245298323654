import styled from 'styled-components';
import PageTitleText from '@front_common/Components/PageTitleText';
import Button from '@front_common/Components/Button/Button';
import { routeRedirect } from '@front_common/Router/Router';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';

function handlePressAddNewPlan() {
  routeRedirect(WEB_ROUTE_HOME);
}

type IProps = {
  showAddNewESimBtn: boolean;
};

export default function ESimsPageHeader({ showAddNewESimBtn }: IProps) {
  return (
    <TileWithButtonWrapperStyled>
      <TitleStyled data-testid="e-sims-page-title">
        <PageTitleText>My eSIMs</PageTitleText>
      </TitleStyled>
      {showAddNewESimBtn && (
        <ButtonStyled
          data-testid="add-new-plan-button"
          sizeParam="L"
          type="Secondary"
          onClick={handlePressAddNewPlan}
        >
          Add new plan
        </ButtonStyled>
      )}
    </TileWithButtonWrapperStyled>
  );
}

let TileWithButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  padding: 4px 0;
  height: 48px;

  @media (min-width: 393px) {
    margin: 32px auto 20px auto;
  }

  @media (min-width: 700px) {
    width: 414px;
    height: 60px;
  }
`;

let TitleStyled = styled.h1`
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;

let ButtonStyled = styled(Button)`
  width: 124px;
  height: 40px;
  font-size: var(--fontSizeSmall);

  @media (min-width: 393px) {
    width: 153px;
    height: 52px;
    font-size: var(--fontSizeRegular);
  }
`;
