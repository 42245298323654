import styled from 'styled-components';
import ToggleIcon from './Icons/ToggleIcon';
import PlanetIcon from './Icons/PlanetIcon';
import SetupIcon from './Icons/SetupIcon';
import FilterIcon from './Icons/FilterIcon';
import Card from '@front_common/Components/Card';

let DashesSVG = (
  <svg
    width="1"
    height="118"
    viewBox="0 0 1 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.5"
      y1="2.18557e-08"
      x2="0.499995"
      y2="118"
      stroke="black"
      strokeOpacity="0.3"
      strokeDasharray="4 4"
    />
  </svg>
);

export default function BulletPointsComp() {
  return (
    <BulletPointsWrapper>
      <Bullet>
        <BulletFirstRow>
          <ToggleIcon />
          <b>Instant Activation:</b>
        </BulletFirstRow>
        <span>Get online in seconds, no physical SIM required</span>
      </Bullet>
      <BorderWrapperMobile />
      <Bullet>
        <BorderWrapper>{DashesSVG}</BorderWrapper>
        <BulletFirstRow>
          <PlanetIcon />
          <b>Worldwide Access:</b>
        </BulletFirstRow>
        <span>Seamless coverage in over 199 countries</span>
      </Bullet>
      <BorderWrapperMobile />
      <Bullet>
        <BorderWrapper>{DashesSVG}</BorderWrapper>
        <BulletFirstRow>
          <FilterIcon />
          <b>Flexible Plans:</b>
        </BulletFirstRow>
        <span>Choose the data you need, when you need it</span>
      </Bullet>
      <BorderWrapperMobile />
      <Bullet>
        <BorderWrapper>{DashesSVG}</BorderWrapper>
        <BulletFirstRow>
          <SetupIcon />
          <b>Effortless Setup:</b>
        </BulletFirstRow>
        <span>Configure your eSIM quickly with our easy-to-follow guide</span>
      </Bullet>
    </BulletPointsWrapper>
  );
}

let BulletPointsWrapper = styled(Card)`
  padding: 20px;
  border-radius: 20px;

  @media (min-width: 700px) {
    padding: 52px 0;
    background-color: transparent;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }
`;

let Bullet = styled.div`
  text-align: initial;
  font-size: var(--fontSizeBig);

  @media (min-width: 700px) {
    min-width: 270px;
    width: 270px;
    margin-right: 20px;
    padding-bottom: 0;
    position: relative;
    padding-left: 20px;

    &:first-child {
      padding-left: initial;
    }
  }
`;

let BulletFirstRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  b {
    font-weight: var(--fontWeightBold);
  }

  svg {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }

  @media (min-width: 700px) {
    flex-direction: column;
    align-items: flex-start;

    svg {
      margin-bottom: 10px;
    }
  }
`;

let BorderWrapperMobile = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  background-image: url('data:image/svg+xml,<svg width="321" height="1" viewBox="0 0 321 1" fill="none" xmlns="http://www.w3.org/2000/svg" ><line y1="0.5" x2="321" y2="0.5" stroke="black" stroke-opacity="0.3" stroke-dasharray="4 4" /></svg>');
  background-repeat: repeat-x;
  background-position: center left;

  svg {
    width: 100%;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

let BorderWrapper = styled.div`
  display: none;

  @media (min-width: 700px) {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
