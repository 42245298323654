import styled from 'styled-components';
import {
  useFAQPopulars,
  useFAQQuestions,
} from '@front_common/Strapi/StrapiFAQ';
import LinksBox from './LinksBox';
import { WEB_ROUTE_FAQ } from '@shared_frontend/Common/WebRoutes';

export default function PopularQuestions() {
  let [faqPopulars] = useFAQPopulars();
  let [faqQuestions] = useFAQQuestions();

  function findTopicIdByQuestionId(questionId?: string) {
    return faqQuestions?.find(
      (question) => question.attributes.slug === questionId,
    )?.attributes.faq_topic.data.attributes.slug;
  }

  let faqDataPopularWithTopic = faqPopulars?.map((popular) => ({
    ...popular,
    topicId: findTopicIdByQuestionId(
      popular.attributes.faq_page.data?.attributes.slug,
    ),
  }));

  let links = faqDataPopularWithTopic?.map((question) => ({
    id: question.attributes.faq_page.data?.attributes.slug || '',
    href: `${WEB_ROUTE_FAQ}/${question.topicId}/${question.attributes.faq_page.data?.attributes.slug}`,
    text: question.attributes.faq_page.data?.attributes.question || '',
  }));

  return (
    <PopularQuestionsWrapper>
      <Title>Popular questions</Title>
      <LinksBox links={links || []} />
    </PopularQuestionsWrapper>
  );
}

let PopularQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;
