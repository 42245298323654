import styled from 'styled-components';

type CardProps = React.HTMLProps<HTMLDivElement>;

export default function Card(props: CardProps) {
  let { children, ...rest } = props;

  return <CardStyled {...rest}>{children}</CardStyled>;
}

let CardStyled = styled.div`
  background: var(--backgroundSecondary);
  border-radius: var(--borderRadiusLarge);
  border: var(--borderOutlinePrimary);
  padding: 16px;
  position: relative;
`;
