export default function SmartphoneSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_115952)">
        <path
          d="M3.99976 0V21C3.99976 21.7956 4.31583 22.5587 4.87844 23.1213C5.44104 23.6839 6.20411 24 6.99976 24H16.9998C17.7954 24 18.5585 23.6839 19.1211 23.1213C19.6837 22.5587 19.9998 21.7956 19.9998 21V0H3.99976ZM17.9998 2V16H5.99976V2H17.9998ZM16.9998 22H6.99976C6.73454 22 6.48019 21.8946 6.29265 21.7071C6.10511 21.5196 5.99976 21.2652 5.99976 21V18H17.9998V21C17.9998 21.2652 17.8944 21.5196 17.7069 21.7071C17.5193 21.8946 17.265 22 16.9998 22Z"
          fill="#EF8268"
        />
        <path
          d="M12.9995 18.999H10.9995V20.999H12.9995V18.999Z"
          fill="#EF8268"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_115952">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
