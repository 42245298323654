import { CONFIG_PAYMENT_SOLIDGATE_KEY } from '@front_common/Config.ts';
import { useRef, useState } from 'preact/hooks';
import Payment, { MessageType, SdkMessage } from '@solidgate/react-sdk';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import VisitorApi from '@front_common/Api/VisitorApi';
import styled from 'styled-components';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { AdEvents } from '@front_common/Ads/AdEvents';
import { useSessionData } from '@front_common/Api/Session';
import UserApi from '@front_common/Api/UserApi';
import AuthService from '@front_common/Api/AuthService';
import {
  DEFAULT_PAYMENT_ERROR,
  PAYMENT_ERROR_CODES_MAP,
} from '@shared_frontend/Module/Checkout/PaymentErrors';

// https://docs.solidgate.com/payments/integrate/payment-form/create-your-payment-form/
// TEST_CARDS https://docs.solidgate.com/payments/testing

type IProps = {
  paymentSession: PaymentSessionEntityType;
  isWebView: boolean;
  onSuccess: (paymentSession: PaymentSessionEntityType) => Promise<void> | void;
  onFail: (error: string) => void;
  isConfirmedPolicy?: boolean;
  onValidateConfirmation?: () => void;
};

//TODO: Move higher to separate folder? General question
export default function SolidgateProcessor(props: IProps) {
  let {
    paymentSession,
    onSuccess,
    onFail,
    isWebView,
    isConfirmedPolicy = true,
    onValidateConfirmation,
  } = props;

  let [hasError, setHasError] = useState(false);

  let sessionData = useSessionData();

  let isAndroid = sessionData?.os.name === 'Android';
  let isIOS = sessionData?.os.name === 'iOS' || sessionData?.os.name === 'iPad';

  if (!sessionData?.os.name) {
    return <LoadingAnimation />;
  }

  let merchantData: {
    merchant: string;
    paymentIntent: string;
    signature: string;
  } = {
    merchant: CONFIG_PAYMENT_SOLIDGATE_KEY,
    paymentIntent: String(paymentSession.meta['paymentIntentEncrypted']),
    signature: String(paymentSession.meta['paymentIntentSign']),
  };

  let applePayContainerRef = useRef(null);
  let googlePayContainerRef = useRef(null);

  let solidgateProps = {
    googlePayContainerRef,
    applePayContainerRef,
    googlePayButtonParams: {
      enabled: !isWebView && !isIOS,
    },
    applePayButtonParams: {
      enabled: !isAndroid,
    },
    merchantData,
    onSuccess: async () => {
      let id = paymentSession.id;
      let userId = paymentSession.userId;

      try {
        // for check and processing payment on backend
        await VisitorApi.getPaymentSessionStatus(id, userId);
      } catch {}

      await onSuccess(paymentSession);

      let isRebill = false;
      let authenticated = AuthService.isLoggedIn();
      if (authenticated) {
        //In the WebView the user always not authenticated
        isRebill = await UserApi.isRebill(paymentSession.id);
      }
      await AdEvents.trackPayment(paymentSession, isRebill);
    },
    onFail: async (event: SdkMessage[MessageType.Fail]) => {
      setHasError(true);

      if (event.code) {
        onFail(PAYMENT_ERROR_CODES_MAP[event.code] || DEFAULT_PAYMENT_ERROR);
      } else {
        onFail(DEFAULT_PAYMENT_ERROR);
      }
    },
    width: '100%',
    styles: {
      submit_button: {
        // CSS variables like var() don't work here!
        'background-color': '#203adb', // blue90
        color: '#ffffff', // white100
      },
    },
  };

  function onValidatePolicy() {
    if (onValidateConfirmation) {
      onValidateConfirmation();
    }
  }

  return (
    <ProcessorFrame>
      <div>
        <PaymentWrapper>
          {!isConfirmedPolicy && (
            <PaymentByWithOverlay onClick={onValidatePolicy} />
          )}
          <AppleGoogleButtonContainer ref={applePayContainerRef} />
        </PaymentWrapper>

        <PaymentWrapper>
          {!isConfirmedPolicy && !isWebView && (
            <PaymentByWithOverlay onClick={onValidatePolicy} />
          )}
          <AppleGoogleButtonContainer ref={googlePayContainerRef} />
        </PaymentWrapper>

        {!isWebView && !hasError && (
          <PaymentDivider>
            <Divider />
            <span>or</span>
            <Divider />
          </PaymentDivider>
        )}

        <PaymentWrapper>
          <Payment {...solidgateProps} />
          {!isConfirmedPolicy && (
            <PaymentSubmitOverlay onClick={onValidatePolicy} />
          )}
        </PaymentWrapper>
      </div>
    </ProcessorFrame>
  );
}

let PaymentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

let PaymentSubmitOverlay = styled.div`
  width: 100%;
  height: 100%;
  max-height: 56px;
  border-radius: 13px;
  position: absolute;
  bottom: 4px;
  cursor: pointer;
  z-index: 5;
`;

let PaymentByWithOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -1px;
  cursor: pointer;
  z-index: 5;
`;

let AppleGoogleButtonContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
  & > div {
    height: 50px;
  }
`;

let ProcessorFrame = styled.div`
  margin: 0 auto;

  iframe {
    border-radius: 15px;
  }
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--strokeSecondary);
`;

let PaymentDivider = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: var(--fontSizeSmall);
  line-height: 20px;

  span {
    white-space: nowrap;
    color: var(--textSecondary);
  }
`;
