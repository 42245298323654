import {
  getAppleUniversalLink,
  useGetMyEsimsQuery,
} from '@front_common/Api/UserApi';
import {
  EsimPlanFlag,
  getEsimPlanCountry,
  SubscriptionPlanNameWithIcon,
} from '@front_common/Components/EsimPlanName';
import Modal, {
  MODAL_HEIGHT,
} from '@front_common/Components/Modal/OldDesign/Modal';
import styled from 'styled-components';
import { QRCodeImage } from '../../Common/Components/QRCodeBox';
import Button from '@front_common/Components/Button/Button';
import InfoIcon from './Icons/InfoIcon';
import { useEffect, useRef, useState } from 'preact/hooks';
import { routeRedirect } from '@front_common/Router/Router';
import { WEB_ROUTE_MY_INSTALL_GUIDE } from '@shared_frontend/Common/WebRoutes';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import { useSessionData } from '@front_common/Api/Session';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

type InstallModalProps = {
  show: boolean;
  esimId: string;
  onClose: () => void;
};

export default function InstallModal(props: InstallModalProps) {
  let { show, esimId, onClose } = props;

  let [isModalOpen, setIsModalOpen] = useState(show);

  useEffect(() => {
    setIsModalOpen(show);
  }, [show]);

  function closeModal() {
    onClose();
    setIsModalOpen(false);
  }

  return (
    <Modal
      analyticType="esim-installation"
      isOpen={isModalOpen}
      onClose={closeModal}
      overlayClick={false}
    >
      <InstallModalContent esimId={esimId} />
    </Modal>
  );
}

type InstallModalContentProps = {
  esimId: string;
};

// TODO make it shorter
function InstallModalContent(props: InstallModalContentProps) {
  let { esimId } = props;

  let [appleUniversalLink, setAppleUniversalLink] = useState(false);
  let [maxHeight, setMaxHeight] = useState('0');

  let sessionData = useSessionData();
  let [response] = useGetMyEsimsQuery();

  let esim = response?.esims.find((item) => String(item.id) === esimId);
  let topRef = useRef<HTMLDivElement>(null);
  let targetCountry = esim?.targetCountry;

  useEffect(() => {
    if (
      sessionData &&
      sessionData.os.name === 'iOS' &&
      sessionData.os.version &&
      Number.parseFloat(sessionData.os.version) >= 17.4
    ) {
      setAppleUniversalLink(true);
    }
  }, [sessionData]);

  useEffect(() => {
    if (topRef.current && esim) {
      let calculatedHeight = `calc(${MODAL_HEIGHT} - ${topRef.current.scrollHeight}px - 52px - 20px ${appleUniversalLink ? '- 52px - 20px ' : ''}- 20px - 20px)`;
      setMaxHeight(`${calculatedHeight}`);
    }
  }, [esim, appleUniversalLink]);

  function handleAllMethodsBtn() {
    routeRedirect(fillRouteParams(WEB_ROUTE_MY_INSTALL_GUIDE, { id: esimId }));
  }

  if (esim && !esim.subscriptionId && !targetCountry) {
    // TODO process "null" better
    logger.log('no target country ' + esim);
    return <>no target country</>;
  }

  return (
    <InstallModalContentStyled>
      <Top ref={topRef}>
        <Title data-testid="install-esim-modal-title">eSIM Installation</Title>
        <Subtitle>
          Your QR-code for{' '}
          {esim && targetCountry && (
            <Plan>
              {esim.dataPurchasedGb}GB in {getEsimPlanCountry(targetCountry)}
              <EsimPlanFlag targetCountry={targetCountry} />
            </Plan>
          )}
          {esim && esim.subscriptionId && (
            <GlobalPlan>
              <SubscriptionPlanNameWithIcon amountGb={esim.dataPurchasedGb} />
            </GlobalPlan>
          )}
        </Subtitle>
      </Top>
      <Bottom>
        <QrInfo $maxHeight={maxHeight}>
          <Text>
            Use your camera <b>to scan the code</b> and follow the steps on your
            screen to complete the eSIM setup.
          </Text>
          <QRCodeImageWrapper>
            <QRCodeImage id={esimId} />
          </QRCodeImageWrapper>
          <InfoBlock>
            <InfoIcon />
            <InfoText>
              You need to be connected to the internet to install the eSIM.
            </InfoText>
          </InfoBlock>
          {!esim?.subscriptionId && (
            <InfoBlock>
              <InfoIcon />
              <InfoText>
                eSIM activation period starts when you arrive at your
                destination.
              </InfoText>
            </InfoBlock>
          )}
        </QrInfo>
        {appleUniversalLink && esim && (
          <AnchorStyled
            href={getAppleUniversalLink(esim.smdpAddress, esim.activationCode)}
            title="iOS 17.4 and later eSIM install link"
          >
            <ButtonStyled sizeParam="L">Install eSIM now</ButtonStyled>
          </AnchorStyled>
        )}
        <AllMethodsBtnStyled sizeParam="L" onClick={handleAllMethodsBtn}>
          See full instructions
        </AllMethodsBtnStyled>
      </Bottom>
    </InstallModalContentStyled>
  );
}

let InstallModalContentStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 32px 20px 20px;
  text-align: center;
`;

let Bottom = styled.div`
  padding: 20px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Subtitle = styled.span`
  font-size: var(--fontSizeBig);
  line-height: 24px;
  text-align: center;
`;

let Plan = styled.span`
  display: inline-flex;
  font-weight: var(--fontWeightBold);
  gap: 4px;

  img {
    height: 24px;
  }
`;

let GlobalPlan = styled.span`
  display: inline-flex;
  font-weight: var(--fontWeightBold);
`;

let QrInfo = styled.div<{ $maxHeight: string }>`
  overflow: auto;
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

let Text = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 22px;
  text-align: center;
  margin-bottom: 8px;

  b {
    font-weight: var(--fontWeightBold);
  }
`;

let QRCodeImageWrapper = styled.div`
  width: 120px;
  margin: 0 auto;
  margin-bottom: 8px;
`;

let InfoBlock = styled.div`
  border: var(--borderOutlinePrimary);
  border-radius: 10px;
  padding: 8px;
  display: flex;
  gap: 6px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: initial;
  }

  svg {
    min-width: 20px;
  }
`;

let InfoText = styled.span`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  text-align: left;
`;

let AllMethodsBtnStyled = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

let ButtonStyled = styled(Button)`
  width: 100%;
`;

let AnchorStyled = styled.a`
  display: block;
  margin-top: 20px;
  text-decoration: none;
`;
