import { ReactElement, ReactNode } from 'react';

export default function NoIndexContent({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  return (
    <span className="robots-nocontent">
      <script dangerouslySetInnerHTML={{ __html: `<!--googleoff: all-->` }} />
      {children}
      <script dangerouslySetInnerHTML={{ __html: `<!--googleon: all-->` }} />
    </span>
  );
}
