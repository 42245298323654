import styled from 'styled-components';
import ChevronRightBigIcon from './Icons/ChevronRightBigIcon';
import { LinkToRoute } from '@front_common/Router/Router';

type Link = {
  id: string;
  href: string;
  text: string;
};

type LinksBoxProps = {
  links: Link[];
};

export default function LinksBox(props: LinksBoxProps) {
  let { links } = props;

  return (
    <LinksBoxWrapper>
      {links.map((link) => (
        <LinkStyled key={link.id}>
          <LinkToRoute href={`${link.href}/`}>
            <Text>{link.text}</Text>
            <IconWrapper>
              <ChevronRightBigIcon />
            </IconWrapper>
          </LinkToRoute>
        </LinkStyled>
      ))}
    </LinksBoxWrapper>
  );
}

let LinksBoxWrapper = styled.ul`
  background-color: var(--backgroundSecondary);
  padding: 0 16px;
  border-radius: 16px;
  border: 1px solid var(--strokeSecondary);
  list-style: none;
`;

let LinkStyled = styled.li`
  min-height: 56px;
  border-bottom: 1px solid var(--strokeSecondary);
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    text-decoration: none;
    color: var(--textPrimary);
    width: 100%;
  }

  @media (min-width: 960px) {
    min-height: 60px;
  }
`;

let Text = styled.span`
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  text-align: left;

  @media (min-width: 700px) {
    font-size: var(--fontSizeBig);
    line-height: 24px;
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

let IconWrapper = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
  }
`;
