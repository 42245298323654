export default function Blob1({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 131 101">
      <path
        d="M93.892 77.1569C75.6662 79.3006 69.8124 81.5626 57.1783 91.3448C38.4179 105.866 10.4303 105.932 2.42719 78.155C-3.72664 56.7914 2.20692 30.2857 15.8642 18.1333C38.8616 -2.3315 81.4239 -5.99056 107.843 9.79756C130.619 23.4066 137.938 49.5885 123.736 66.6511C118.92 72.4383 111.349 75.1042 93.892 77.1569Z"
        fill="currentColor"
      />
    </svg>
  );
}
