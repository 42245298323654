export default function RightArrowSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.412 23.9996L6 22.5876L15.881 12.7066C16.0685 12.5191 16.1738 12.2648 16.1738 11.9996C16.1738 11.7344 16.0685 11.4801 15.881 11.2926L6.017 1.4306L7.431 0.0166016L17.293 9.8786C17.8554 10.4412 18.1714 11.2041 18.1714 11.9996C18.1714 12.7951 17.8554 13.558 17.293 14.1206L7.412 23.9996Z"
        fill="#21252A"
      />
    </svg>
  );
}
