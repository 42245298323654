import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';

type IProps = {
  isOpen: boolean;
  header: string;
  onClose: () => void;
  children: React.ReactNode;
};

export default function ModalStyledCheckout(props: IProps) {
  let { isOpen, onClose, header, children } = props;

  let windowDimensions = useWindowDimensions();

  let [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  function closeModal() {
    onClose();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      overlayClick={false}
      overlayStyles={{ display: 'block', overflowY: 'auto' }}
      windowStyles={{
        maxHeight: 'initial',
        margin:
          windowDimensions.width >= 393
            ? '86px auto'
            : // eslint-disable-next-line unicorn/no-nested-ternary
              windowDimensions.width >= 700
              ? '100px auto'
              : '36px auto',
      }}
      analyticType="checkout"
    >
      <ModalContent>
        <Top>
          <Title>{header}</Title>
        </Top>
        <Bottom>{children}</Bottom>
      </ModalContent>
    </Modal>
  );
}

let ModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

let Top = styled.div`
  background-color: var(--orange90);
  padding: 32px 20px 20px;
  text-align: center;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: left;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Bottom = styled.div`
  padding: 16px;
  background-color: var(--backgroundPrimary);

  @media (min-width: 393px) {
    padding: 20px;
  }
`;
