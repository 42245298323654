import { useRefundPolicy } from '@front_common/Strapi/StrapiLegal';
import DocumentContent from './DocumentContent';

export default function RefundPolicy() {
  let [refundPolicy] = useRefundPolicy();

  return (
    <DocumentContent
      content={refundPolicy?.attributes.content || ''}
      seo={refundPolicy?.attributes.seo || null}
    />
  );
}
