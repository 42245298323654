export function setCookie(
  name: string,
  value: string,
  expireDays: number,
): void {
  let date = new Date();
  date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + date.toUTCString();
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export function getCookie(name: string): string {
  let fixedName = name + '=';
  let ca = document.cookie.split(';');
  for (let char of ca) {
    if (char === undefined) {
      continue;
    }
    while (char.charAt(0) === ' ') {
      char = char.slice(1);
    }
    if (char.indexOf(fixedName) === 0) {
      // eslint-disable-next-line unicorn/prefer-string-slice
      return char.substring(fixedName.length, char.length);
    }
  }
  return '';
}

export function deleteCookie(name: string) {
  setCookie(name, '', 0);
}
