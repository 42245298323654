import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from '../../Module/Layout/useDynamicSplitComponent';
import OldDesignSupportChatButton from './OldDesign/SupportChatButton';
import NewDesignSupportChatButton from './NewDesign/SupportChatButton';

type ISupportChatButtonProps = {
  shortFooter: boolean;
};

export function SupportChatButtonContainer<P extends ISupportChatButtonProps>(
  props: P,
) {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  let SupportChatButtonComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignSupportChatButton,
      [DesignSplitGroupId.B]: NewDesignSupportChatButton,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return (
    <SupportChatButtonComponent {...(props as P & JSX.IntrinsicAttributes)} />
  );
}
