export default function CaseSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116024)">
        <path
          d="M21 4H18V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0L8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V4H3C2.20435 4 1.44129 4.31607 0.87868 4.87868C0.31607 5.44129 0 6.20435 0 7L0 24H24V7C24 6.20435 23.6839 5.44129 23.1213 4.87868C22.5587 4.31607 21.7956 4 21 4ZM8 2H16V4H8V2ZM3 6H21C21.2652 6 21.5196 6.10536 21.7071 6.29289C21.8946 6.48043 22 6.73478 22 7V12H2V7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6ZM2 22V14H11V16H13V14H22V22H2Z"
          fill="#21252A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_116024">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
