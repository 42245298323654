import styled from 'styled-components';
import InfoIcon from './Icons/InfoIcon';

export default function Warning({ text }: { text: string }) {
  return (
    <WarningStyled>
      <InfoIcon />
      <Text>{text}</Text>
    </WarningStyled>
  );
}

let WarningStyled = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--cardsBackgroundOrange);

  svg {
    min-width: 24px;
  }
`;

let Text = styled.span`
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
`;
