import { useAcceptablePolicy } from '@front_common/Strapi/StrapiLegal';
import DocumentContent from './DocumentContent';

export default function AcceptablePolicy() {
  let [acceptablePolicy] = useAcceptablePolicy();

  return (
    <DocumentContent
      content={acceptablePolicy?.attributes.content || ''}
      seo={acceptablePolicy?.attributes.seo || null}
    />
  );
}
