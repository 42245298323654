import { CONFIG_SUPPORT_EMAIL } from '@front_common/Config';
import styled from 'styled-components';
import QuestionIcon from './Icons/QuestionIcon';

export default function HaveAnotherQuestion() {
  return (
    <HaveAnotherQuestionWrapper>
      <Content>
        <QuestionSymbol>
          <QuestionIcon />
        </QuestionSymbol>
        <Title>Have another question?</Title>
        <MainText>
          Feel free to contact our support team anytime at{' '}
          <a href={`mailto:${CONFIG_SUPPORT_EMAIL}`}>{CONFIG_SUPPORT_EMAIL}</a>
        </MainText>
      </Content>
    </HaveAnotherQuestionWrapper>
  );
}

let HaveAnotherQuestionWrapper = styled.div`
  padding-top: 35px;
`;

let QuestionSymbol = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -35px;
`;

let Content = styled.div`
  background-color: var(--cardsBackgroundLightBlueSecondary);
  border-radius: 16px;
  padding: 40px 16px;
  position: relative;
`;

let Title = styled.p`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 4px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

let MainText = styled.p`
  font-size: var(--fontSizeRegular);
  line-height: 20px;
  text-align: center;

  a {
    color: var(--textLink);
  }
`;
