import { useEffect, useRef, useState } from 'preact/hooks';
import { ActionTypes } from '@shared_backend/Module/Action/ActionTypes';
import { StoreItem } from '@shared_backend/Module/Store/StoreResponse';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';
import { WEB_ROUTE_HOME } from '@shared_frontend/Common/WebRoutes';
import VisitorApi, {
  useStoreForCountryQuery,
} from '@front_common/Api/VisitorApi';
import ChevronDownIcon from '@front_common/Components/Icons/ChevronDownIcon';
import Button from '@front_common/Components/Button/Button';
import { LinkToRoute } from '@front_common/Router/Router';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import Api from '@front_common/Api/Api';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import DownloadApp from '@front_common/Components/DownloadApp/NewDesign/DownloadApp';
import { setTitleHeader } from '@front_common/Seo/SeoHeaders';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { NavigationTabs } from './NavigationTabs';
import PlanDetails from './PlanDetails';
import PlanCard from './PlanCard';
import HowESimWorksSlider from './HowEsimWorks/HowEsimWorks';
import FAQ from './FAQ';
import CompatibleDevicesModalContent from '../../CompatibilityGuide/CompatibleDevicesModalContent';
import CheckoutModalContent from '../../Checkout/CheckoutModal/NewDesign/CheckoutModalContent';
import CheckoutModal from '../../Checkout/CheckoutModal/NewDesign/CheckoutModal';
import SuccessModal from '../../InstallFlow/SuccessModal';
import { useDisplayingAllElements } from '../../Checkout/useDisplayingHeaderFooterElements';
import { getCountryShortCodeBySlug } from './CountryStoreUtils';
import StoreFeedbacksSlider from './StoreFeedbacks/StoreFeedbacks';
import AESymbolImage from './Images/AESymbol.png';
import AOSymbolImage from './Images/AOSymbol.png';
import FRSymbolImage from './Images/FRSymbol.png';
import CNSymbolImage from './Images/CNSymbol.png';
import ITSymbolImage from './Images/ITSymbol.png';
import JPSymbolImage from './Images/JPSymbol.png';
import THSymbolImage from './Images/THSymbol.png';
import TRSymbolImage from './Images/TRSymbol.png';
import GBSymbolImage from './Images/GBSymbol.png';
import USSymbolImage from './Images/USSymbol.png';
import DefaultSymbol from './Images/DefaultSymbol.png';
import styled from 'styled-components';

let countriesSymbols: { [key: string]: string } = {
  AE: AESymbolImage,
  AO: AOSymbolImage,
  FR: FRSymbolImage,
  CN: CNSymbolImage,
  IT: ITSymbolImage,
  JP: JPSymbolImage,
  TH: THSymbolImage,
  TR: TRSymbolImage,
  GB: GBSymbolImage,
  US: USSymbolImage,
};

const HEADER_HEIGHT = 64;

let tabs = ['Plans', 'Details', 'How it works', 'Reviews', 'FAQ'];

function extractShortBundleCode(bundleCodeAndPriceId: string) {
  return bundleCodeAndPriceId.split('_').at(0);
}

type IProps = {
  countrySlug: string;
};

export default function CountryStorePage({ countrySlug }: IProps) {
  let countryCode = getCountryShortCodeBySlug(countrySlug);

  if (!countryCode) {
    throw new Error('Country code is not defined');
  }

  let countryName = countryNameByCode(countryCode);
  let [storeItems, error, isLoading] = useStoreForCountryQuery(countryCode);

  let headerComp = (
    <Header>
      <HeaderBackGround>
        <HeaderContent>
          <BackButtonWrapper>
            <BackButton href={WEB_ROUTE_HOME}>
              <ChevronDownIcon />
            </BackButton>
          </BackButtonWrapper>
          <HeaderTitle data-testid="country-store-page-title">
            <span>eSIM plans for </span>
            <HeaderCountryName>
              {countryNameByCode(countryCode)}
            </HeaderCountryName>
          </HeaderTitle>
          <CountrySymbolWrapper>
            {countriesSymbols[countryCode] ? (
              <img
                src={countriesSymbols[countryCode]}
                alt={`eSIM ${countryNameByCode(countryCode)}`}
              />
            ) : (
              <img
                src={DefaultSymbol}
                alt={`eSIM ${countryNameByCode(countryCode)}`}
              />
            )}
          </CountrySymbolWrapper>
        </HeaderContent>
      </HeaderBackGround>

      <NavigationTabsWrapper>
        <NavigationTabs
          tabs={tabs}
          containerClassName="tabs-container"
          onTabClick={handleTabClick}
        />
      </NavigationTabsWrapper>
    </Header>
  );

  if (isLoading || !storeItems) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <LoadingAnimation />
      </CountryStoreWrapper>
    );
  }

  if (!isLoading && storeItems.length === 0) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <ErrorBlock message={'No active packages for ' + countryName} />
      </CountryStoreWrapper>
    );
  }

  if (error) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <ErrorBlock message={error.message} />
      </CountryStoreWrapper>
    );
  }

  let [selectedPlan, setSelectedPlan] = useState<number | null>(null);

  useEffect(() => {
    updateSeoHeaders(storeItems, countryName);
    if (selectedPlan === null) {
      let preSelected = 2;

      if (storeItems.length < 3) {
        preSelected = storeItems.length - 1;
      }

      setSelectedPlan(preSelected);
    }
    setTimeout(() => {
      preloadBundles(storeItems);
    }, 3000);
  }, [storeItems]);

  let [isCompatibilityModalOpen, setIsCompatibilityModalOpen] = useState(false);
  let [isBillingDetailsOpen, setIsBillingDetailsOpen] = useState(false);
  let [paymentSession, setPaymentSession] =
    useState<PaymentSessionEntityType | null>(null);
  let [showSuccessModal, setShowSuccessModal] = useState(false);

  let planIndex =
    selectedPlan !== null && selectedPlan < storeItems.length
      ? selectedPlan
      : 0;
  let selectedStoreItem = storeItems[planIndex];

  if (!selectedStoreItem) {
    return <LoadingAnimation />;
  }

  let bundleCodeAndPriceId = selectedStoreItem.bundleCodeAndPriceId;

  useDisplayingAllElements(isBillingDetailsOpen);

  function openCompatibilityModal() {
    setIsCompatibilityModalOpen(true);
  }

  function closeCompatibilityModal() {
    setIsCompatibilityModalOpen(false);
  }

  function closeBillingDetails() {
    setIsBillingDetailsOpen(false);
  }

  let sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  function handleTabClick(index: number) {
    if (sectionRefs[index]?.current) {
      let element = sectionRefs[index]?.current;
      let offsetTop = element?.offsetTop;

      if (offsetTop) {
        window.scrollTo({
          top: offsetTop - HEADER_HEIGHT - (index === 0 ? 94 : 54),
          behavior: 'smooth',
        });
      }
    }
  }

  function handleBuyClick() {
    Api.trackAction(
      ActionTypes.button_buy,
      extractShortBundleCode(bundleCodeAndPriceId),
    );

    setIsBillingDetailsOpen(true);
  }

  function onPaymentSuccess(paymentSessionProp: PaymentSessionEntityType) {
    setPaymentSession(paymentSessionProp);
    setIsBillingDetailsOpen(false);
    setShowSuccessModal(true);
  }

  if (selectedPlan === null) {
    return (
      <CountryStoreWrapper>
        {headerComp}
        <LoadingAnimation />
      </CountryStoreWrapper>
    );
  }

  return (
    <CountryStoreWrapper>
      {headerComp}
      <CountryStoreContent>
        <>
          {sectionRefs[0] && (
            <PlansSection ref={sectionRefs[0]}>
              <PlansList>
                {storeItems.map((item, index: number) => (
                  <PlanCard
                    key={item.bundleCodeAndPriceId}
                    planData={item}
                    selected={selectedPlan === index}
                    popular={index === 2}
                    onPlanClick={() => setSelectedPlan(index)}
                  />
                ))}
              </PlansList>
            </PlansSection>
          )}
          <CompatibilityGuideBtn
            onClick={openCompatibilityModal}
            sizeParam="M"
            typeStyle="Secondary"
          >
            <span>Check my device’s compatibility</span>
          </CompatibilityGuideBtn>
        </>
        {sectionRefs[1] && (
          <PlanDetailsSection ref={sectionRefs[1]}>
            <SectionTitle>Plan details</SectionTitle>
            <PlanDetails validDays={selectedStoreItem.durationDays} />
          </PlanDetailsSection>
        )}
        {sectionRefs[2] && (
          <HowItWorksSection ref={sectionRefs[2]}>
            <HowItWorksInfo>
              <SectionTitle>How eSim works</SectionTitle>
              <SectionDescription>
                Just a couple of steps separate you from hassle-free internet
                access
              </SectionDescription>
            </HowItWorksInfo>
            <HowESimWorksSlider />
          </HowItWorksSection>
        )}
        {sectionRefs[3] && (
          <FeedbacksSection ref={sectionRefs[3]}>
            <SectionTitle>
              Best eSIM for {countryNameByCode(countryCode)} - tourist reviews
            </SectionTitle>
            <StoreFeedbacksSlider
              countryId={countryCode}
              storeItems={storeItems}
            />
          </FeedbacksSection>
        )}
        {sectionRefs[4] && (
          <FAQSection ref={sectionRefs[4]}>
            <SectionTitle>FAQ</SectionTitle>
            <FAQ />
          </FAQSection>
        )}
        <DownloadApp />
      </CountryStoreContent>

      {isBillingDetailsOpen ? null : (
        <BuyBlock>
          <BuyContent>
            <PlanInfo>
              <PlanData>
                {selectedStoreItem.dataAmountGb}GB -{' '}
                {selectedStoreItem.durationDays} days
              </PlanData>
              <CountryFlag>
                <CountryName>{countryNameByCode(countryCode)}</CountryName>
              </CountryFlag>
            </PlanInfo>
            <ButtonStyled
              style={{ whiteSpace: 'nowrap' }}
              sizeParam="M"
              className="primary-btn"
              onClick={handleBuyClick}
            >
              Buy for ${selectedStoreItem.totalAmountCents / 100}
            </ButtonStyled>
          </BuyContent>
        </BuyBlock>
      )}

      <Modal
        analyticType="device-compatibility"
        isOpen={isCompatibilityModalOpen}
        onClose={closeCompatibilityModal}
        closeButtonPrimary={false}
      >
        <CompatibleDevicesModalContent />
      </Modal>
      <CheckoutModal
        isOpen={isBillingDetailsOpen}
        onClose={closeBillingDetails}
        header="Billing details"
      >
        <CheckoutModalContent
          bundleCodeAndPriceId={bundleCodeAndPriceId}
          targetCountry={countryCode}
          onPaymentSuccess={onPaymentSuccess}
        />
      </CheckoutModal>

      {paymentSession && (
        <SuccessModal
          show={showSuccessModal}
          paymentSessionId={paymentSession.id}
        />
      )}
    </CountryStoreWrapper>
  );
}

function preloadBundles(storeItems: readonly StoreItem[]) {
  for (let item of storeItems) {
    let parts = item.bundleCodeAndPriceId.split('_');
    let bundleShortCode = parts[0];
    let priceId = parts[1];
    if (!bundleShortCode || !priceId) {
      continue;
    }
    VisitorApi.getBundleByShortCode(bundleShortCode);
    VisitorApi.getPrice(priceId);
  }
}

function updateSeoHeaders(
  storeItems: readonly StoreItem[],
  countryName: string,
) {
  // eslint-disable-next-line arrow-body-style
  let lowestPrice = Object.values(storeItems).reduce((acc, val) => {
    return acc < val.totalAmountCents ? acc : val.totalAmountCents;
  }, 9999);
  // Before useStoreForCountryQuery result we use title from SeoData.ts
  setTitleHeader(
    `eSIM ${countryName} - Best Plans from $${lowestPrice / 100} & Unlimited Data`,
  );
}

let CountryStoreWrapper = styled.div`
  width: 100%;
  position: relative;
  font-family: var(--fontFamilyNew);
  color: var(--textPrimaryNew);
  background-color: var(--textPrimaryInvert);
`;

let Header = styled.div`
  display: initial;
`;

let HeaderBackGround = styled.div`
  background-color: var(--backgroundPrimaryNew);
`;

let HeaderContent = styled.div`
  display: flex;
  height: 320px;
  align-items: flex-end;
  padding: 32px 16px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (min-width: 700px) {
    height: 384px;
    padding: 32px;
  }

  @media (min-width: 960px) {
    padding: 64px 0 32px 0;
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let BackButtonWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 64px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0;
    left: 50%;
    max-width: 1440px;
    transform: translate(-50%);
  }
`;

let BackButton = styled(LinkToRoute)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: rotate(90deg);
  }
`;

let HeaderTitle = styled.h1`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  row-gap: 12px;
  font-weight: var(--fontWeightBold);
  z-index: 5;

  @media (min-width: 700px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

let HeaderCountryName = styled.span`
  font-size: 32px;
  color: var(--textPrimaryInvert);

  @media (min-width: 700px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

let CountrySymbolWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: -78px;
  height: 224px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 224px;
  }

  @media (min-width: 700px) {
    height: 316px;
    right: 0;
    img {
      height: 316px;
    }
  }
`;

let NavigationTabsWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding: 32px 16px 0 16px;
  z-index: 10;
  position: sticky;
  display: flex;
  align-items: flex-end;
  top: 54px;
  background-color: var(--backgroundSecondary);

  @media (min-width: 700px) {
    margin: 0 auto 32px auto;
    padding: 32px 32px 0 32px;
  }

  @media (min-width: 960px) {
    width: calc(100% - 64px);
    max-width: 1040px;
    padding: 32px 0 0 0;
    margin: 0 auto 41px auto;
  }
`;

let CountryStoreContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

let CompatibilityGuideBtn = styled(Button)`
  margin: 20px 16px 0 16px;
  max-width: 100%;
  border: 1px solid var(--strokeBrandNew);
  color: var(--strokeBrandNew);

  &:hover {
    background-color: var(--backgroundHoveredNew);
    // TODO: fix important after design variant confirmation
    border: 1px solid var(--black100) !important;
    color: var(--black100) !important;
  }

  @media (min-width: 700px) {
    margin: 20px 32px 0 32px;
  }

  @media (min-width: 960px) {
    width: 327px;
    margin: 0 auto;
    margin-top: 32px;
  }
`;

let SectionTitle = styled.h2`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-weight: var(--fontWeightBold);
`;

let SectionDescription = styled.p`
  line-height: 22px;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: var(--textSecondaryNew);
  font-size: var(--fontSizeRegular);
  padding: 0 16px;

  @media (min-width: 700px) {
    max-width: 393px;
  }

  @media (min-width: 960px) {
    max-width: 100%;
  }
`;

let PlansSection = styled.section<{ $withoutPadding?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: ${(props) => (props.$withoutPadding ? '0' : '0 16px')};

  @media (min-width: 700px) {
    padding: ${(props) => (props.$withoutPadding ? '0' : '0 40px')};
  }

  @media (min-width: 960px) {
    padding: 0 32px;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let PlansList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 20px;
  justify-content: center;
`;

let PlanDetailsSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px 0 0 0;
  row-gap: 16px;

  @media (min-width: 960px) {
    margin: 0 auto;
    width: calc(100% - 64px);
  }
`;

let HowItWorksSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 32px 16px 0 16px;

  @media (min-width: 700px) {
    padding: 0 32px;
  }

  @media (min-width: 960px) {
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 0 auto;
    padding: 32px 0 0 0;
  }
`;

let HowItWorksInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

let FeedbacksSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 32px 16px;

  @media (min-width: 700px) {
    padding: 32px 32px;
  }

  @media (min-width: 960px) {
    padding: 32px 0;
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let FAQSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 32px 16px;

  @media (min-width: 700px) {
    padding: 32px 32px 0 32px;
  }

  @media (min-width: 960px) {
    padding: 32px 200px 0 200px;
  }
`;

let BuyBlock = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: var(--backgroundSecondary);
  border-top: 1px solid var(--strokeSecondary);
  height: 88px;
  padding: 12px 16px;

  @media (min-width: 393px) {
    padding: 16px;

    .primary-btn {
      min-width: 172px;
    }
  }

  @media (min-width: 700px) {
    padding: 16px 32px;

    .primary-btn {
      min-width: 240px;
    }
  }

  @media (min-width: 960px) {
    padding: 16px 32px;
  }

  @media (min-width: 1104px) {
    padding: 16px 0;
  }
`;

let BuyContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 393px) {
    .primary-btn {
      min-width: 172px;
    }
  }

  @media (min-width: 700px) {
    .primary-btn {
      min-width: 240px;
    }
  }

  @media (min-width: 960px) {
    .primary-btn {
      min-width: 216px;
    }
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let PlanInfo = styled.div`
  text-align: left;
`;

let ButtonStyled = styled(Button)`
  background-color: var(--buttonPrimaryNew);

  &:hover {
    background-color: var(--black100) !important;
  }
`;

let PlanData = styled.span`
  margin-bottom: 4px;
  line-height: 20px;
  text-align: left;
  display: inline-block;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);

  @media (min-width: 393px) {
    margin-bottom: 8px;
    font-size: var(--fontSizeRegular);
  }

  @media (min-width: 700px) {
    line-height: 24px;
    font-size: var(--fontSizeBig);
  }
`;

let CountryFlag = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 4px;
  align-items: center;
`;

let CountryName = styled.span`
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: var(--fontWeightMedium);
`;
