import React, { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextSearchInputProps extends HTMLAttributes<HTMLInputElement> {
  customIcon: React.ReactNode;
  placeholder?: string;
}

let TextSearchInput = forwardRef<HTMLInputElement, TextSearchInputProps>(
  (props, ref) => {
    let { placeholder = '', customIcon, ...rest } = props;

    return (
      <InputContainer>
        <InputWrapper>
          {/*@ts-expect-error  @TODO fix this pls */}
          <StyledInput
            ref={ref}
            type="text"
            placeholder={placeholder}
            {...rest}
          />
          <IconWrapper>{customIcon}</IconWrapper>
        </InputWrapper>
      </InputContainer>
    );
  },
);

TextSearchInput.displayName = 'TextSearchInput';

export default TextSearchInput;

let InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

let InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;

  @media (min-width: 960px) {
    width: 425px;
  }
`;

let StyledInput = styled.input`
  padding: 0 16px;
  padding-right: 50px;
  width: 100%;
  height: 48px;
  outline: none;
  text-overflow: ellipsis;
  font-size: var(--fontSizeRegular);
  border: 1px solid var(--strokePrimaryNew);
  border-radius: 8px;

  &::placeholder {
    color: var(--textSecondary);
  }

  &:hover {
    border: 1px solid var(--strokePrimaryNew);
  }

  &:focus {
    border: 1px solid var(--strokePrimaryNew);
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

let IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  height: 100%;
  display: flex;
  align-items: center;
`;
