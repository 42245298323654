import UserApi from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import styled from 'styled-components';
import RightIcon from '../Icons/RightIcon';
import { checkLoginOrRedirect } from '@front_common/Router/Router';
import { useEffect } from 'preact/hooks';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import { ScrollToTopButton } from '@front_common/Components/ScrollToTopButton';
import Troubleshoot from '../Troubleshoot';
import InstallGuideBody from '../InstallGuideBody';

export default function InstallGuidePage({ id }: { id: string }) {
  checkLoginOrRedirect();

  useEffect(() => {
    (async () => {
      let user = await UserApi.getUser();

      if (!id) {
        logger.error('Install guide with empty esim_id for user ' + user.id!);
      }
    })();
  }, [id]);

  if (!id) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <PaddingWrapper>
        <BackButtonContainer>
          <BackButton onClick={() => history.back()}>
            <RightIcon />
          </BackButton>
        </BackButtonContainer>
      </PaddingWrapper>
      <InstallGuideBody esimId={id} />
      <PaddingWrapper>
        <TroubleshootWrapper>
          <Troubleshoot />
        </TroubleshootWrapper>
      </PaddingWrapper>
      <ScrollToTopButtonWrapper>
        <ScrollToTopButton />
      </ScrollToTopButtonWrapper>
    </div>
  );
}

let PaddingWrapper = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin: 0 auto;
  }
`;

let BackButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
  }
`;

let ScrollToTopButtonWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 76px;

  //846px - max-width of the content block
  //44px - width of the button
  @media (min-width: 700px) {
    right: 40px;
  }

  @media (min-width: 960px) {
    right: 20px;
    bottom: 60px;
  }

  @media (min-width: 1100px) {
    right: initial;
    left: calc(100vw - (100vw - 846px) / 2 + 40px);
  }
`;

let TroubleshootWrapper = styled.div`
  margin-top: 32px;

  @media (min-width: 960px) {
    margin-top: 40px;
  }
`;
