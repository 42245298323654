import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from '../../Module/Layout/useDynamicSplitComponent';
import OldDesignMobileSupportChatButton from './OldDesign/MobileSupportChatButton';
import NewDesignMobileSupportChatButton from './NewDesign/MobileSupportChatButton';

export function MobileSupportChatButtonContainer<P>(props: P) {
  // TODO: Delete it after that along with deleting the old version of the design.
  let splitGroupId = DesignSplitGroupId.A;

  let MobileSupportChatButtonComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignMobileSupportChatButton,
      [DesignSplitGroupId.B]: NewDesignMobileSupportChatButton,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return (
    <MobileSupportChatButtonComponent
      {...(props as P & JSX.IntrinsicAttributes)}
    />
  );
}
