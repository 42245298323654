import { useGetMySubscriptions } from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import ErrorBlock from '@front_common/Components/ErrorBlock';
import PageTitleText from '@front_common/Components/PageTitleText';
import ChevronLeftIcon from './Icons/ChevronLeftIcon';
import SubscriptionCard from './SubscriptionCard';
import RenewalModal from '../Esims/ESimPage/RenewalModal/RenewalModal';
import UnpaidSubscriptionModal from './UnpaidSubscriptionModal';
import styled from 'styled-components';

export default function SubscriptionsPage() {
  let [subs, error] = useGetMySubscriptions();

  if (error) {
    return <ErrorBlock message={error.message} />;
  }

  if (!subs) {
    return <LoadingAnimation />;
  }

  if (subs.length === 0) {
    return (
      <EmptySubscriptions>You have no active subscriptions</EmptySubscriptions>
    );
  }

  let sortedSubscriptions = [...subs].sort((aSub, bSub) => {
    if (!aSub.id && bSub.id) {
      return -1;
    }

    if (aSub.id && !bSub.id) {
      return 1;
    }

    return bSub.id - aSub.id;
  });

  return (
    <MySubscriptionsWrapperStyled>
      {/* //TODO: should we add this to the layout? This is general question. */}
      <BackButtonContainer>
        <BackButton onClick={() => history.back()}>
          <ChevronLeftIcon />
        </BackButton>
      </BackButtonContainer>
      <TitleStyled data-testid="e-sims-page-title">
        <PageTitleText>My subscriptions</PageTitleText>
      </TitleStyled>
      {sortedSubscriptions.map((subscription) => (
        <SubscriptionCard key={subscription.id} subscription={subscription} />
      ))}
      <UnpaidSubscriptionModal />
      <RenewalModal />
    </MySubscriptionsWrapperStyled>
  );
}

let MySubscriptionsWrapperStyled = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 960px) {
    width: 414px;
  }
`;

let BackButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  &:disabled {
    cursor: default;
  }

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
  }
`;

let TitleStyled = styled.h1`
  font-size: 34px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
  margin-bottom: 20px;
`;

let EmptySubscriptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;
