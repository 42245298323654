import { useEffect, useState } from 'react';

export function asyncHook<T, P extends unknown[]>(
  asyncFunction: (...params: P) => Promise<T>,
) {
  if (asyncFunction.toString().includes('this.')) {
    // eslint-disable-next-line no-console
    console.error('probably lost this context', asyncFunction);
  }
  return (
    ...params: P
  ): [T | undefined, Error | false, boolean, () => void] => {
    let [result, setResult] = useState<T>();
    let [error, setError] = useState<Error | false>(false);
    let [isLoading, setIsLoading] = useState(true);

    let aborted = false;

    function execute() {
      setIsLoading(true);
      setError(false);
      asyncFunction(...params)
        .then((resultData: T) => {
          if (!aborted) {
            setIsLoading(false);
            setResult(resultData);
          }

          return resultData;
        })
        .catch((error_) => {
          if (aborted) {
            return;
          }
          setError(error_);
          setResult(undefined);
          setIsLoading(false);
        });
    }

    useEffect(() => {
      execute();
      return (): void => {
        aborted = true;
      };
    }, [...params]);

    return [result, error, isLoading, execute];
  };
}
