import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';
import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import styled from 'styled-components';
import AlertIcon from './AlertIcon';

export default function PaymentErrorSupport({ text }: { text: string }) {
  return (
    <PaymentErrorWrapper>
      <IconBox>
        <AlertIcon />
      </IconBox>
      <ColumnBox>
        <ErrorMessage>{text}</ErrorMessage>
        <MenuSubButton onClick={handleZendeskOpenChatClick}>
          <SupportIcon />
          <span>Contact support</span>
        </MenuSubButton>
      </ColumnBox>
    </PaymentErrorWrapper>
  );
}

let PaymentErrorWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  border-radius: var(--borderRadiusLarge);
  border: 1px solid var(--red100);
  background-color: var(--backgroundSecondary);
`;

let IconBox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let ErrorMessage = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
`;

let MenuSubButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: var(--textLink);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  margin-top: 16px;
  span {
    margin-left: 12px;
  }
`;
