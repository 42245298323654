import { useEffect, useState } from 'preact/hooks';

export const MOBILE_SUPPORT_BUTTON_CLASS_NAME = 'mobile-support-chat-button';
export const SUPPORT_BUTTON_CLASS_NAME = 'support-chat-button';

export const HEADER_CLASS_NAME = 'header-navigation';
export const PROFILE_BUTTON_HEADER_CLASS_NAME = 'profile-button-header';
export const PROFILE_BUTTON_LAYOUT_CLASS_NAME = 'profile-button-layout';
export const SHORT_FOOTER_CLASS_NAME = 'short-footer';
export const BURGER_MENU_CLASS_NAME = 'burger-menu';

export function useDisplayingSupport(hide: boolean) {
  useEffect(() => {
    let mobileSupportButton = document.querySelector<HTMLElement>(
      `.${MOBILE_SUPPORT_BUTTON_CLASS_NAME}`,
    );
    let supportButton = document.querySelector<HTMLElement>(
      `.${SUPPORT_BUTTON_CLASS_NAME}`,
    );

    if (!mobileSupportButton || !supportButton) {
      return;
    }

    if (hide) {
      mobileSupportButton.style.display = 'none';
      supportButton.style.display = 'none';
      //@ts-ignore
      let { zE: zendesk } = window;
      if (zendesk && typeof zendesk === 'function') {
        zendesk('messenger', 'close');
      }
    } else {
      mobileSupportButton.style.display = 'block';
      mobileSupportButton.style.right = '16px';
      supportButton.style.display = 'block';
    }
  }, [hide]);
}

export function useDisplayingAllElements(hide: boolean) {
  let [initialHeaderStyle, setInitialHeaderStyle] = useState('');
  let [initialProfileButtonHeaderStyle, setInitialProfileButtonHeaderStyle] =
    useState('');
  let [initialProfileButtonLayoutStyle, setInitialProfileButtonLayoutStyle] =
    useState('');
  let [initialFooterStyle, setInitialFooterStyle] = useState('');
  let [initialBurgerStyle, setInitialBurgerStyle] = useState('');
  let [initialMobileSupportButtonStyle, setInitialMobileSupportButtonStyle] =
    useState('');
  let [initialSupportButtonStyle, setInitialSupportButtonStyle] = useState('');

  useEffect(() => {
    let headerNav = document.querySelector<HTMLElement>(
      `.${HEADER_CLASS_NAME}`,
    );
    let profileButtonHeader = document.querySelector<HTMLElement>(
      `.${PROFILE_BUTTON_HEADER_CLASS_NAME}`,
    );
    let profileButtonLayout = document.querySelector<HTMLElement>(
      `.${PROFILE_BUTTON_LAYOUT_CLASS_NAME}`,
    );
    let footer = document.querySelector<HTMLElement>(
      `.${SHORT_FOOTER_CLASS_NAME}`,
    );
    let burgerMenu = document.querySelector<HTMLElement>(
      `.${BURGER_MENU_CLASS_NAME}`,
    );
    let mobileSupportButton = document.querySelector<HTMLElement>(
      `.${MOBILE_SUPPORT_BUTTON_CLASS_NAME}`,
    );
    let supportButton = document.querySelector<HTMLElement>(
      `.${SUPPORT_BUTTON_CLASS_NAME}`,
    );

    // Store initial styles when component mounts
    if (
      headerNav &&
      footer &&
      burgerMenu &&
      profileButtonHeader &&
      profileButtonLayout &&
      mobileSupportButton &&
      supportButton
    ) {
      setInitialHeaderStyle(headerNav.style.display);
      setInitialProfileButtonHeaderStyle(profileButtonHeader.style.display);
      setInitialProfileButtonLayoutStyle(profileButtonLayout.style.display);
      setInitialFooterStyle(footer.style.paddingBottom);
      setInitialBurgerStyle(burgerMenu.style.display);
      setInitialMobileSupportButtonStyle(mobileSupportButton.style.display);
      setInitialSupportButtonStyle(supportButton.style.display);
    }
  }, []);

  useEffect(() => {
    let headerNav = document.querySelector<HTMLElement>(
      `.${HEADER_CLASS_NAME}`,
    );
    let profileButtonHeader = document.querySelector<HTMLElement>(
      `.${PROFILE_BUTTON_HEADER_CLASS_NAME}`,
    );
    let profileButtonLayout = document.querySelector<HTMLElement>(
      `.${PROFILE_BUTTON_LAYOUT_CLASS_NAME}`,
    );
    let footer = document.querySelector<HTMLElement>(
      `.${SHORT_FOOTER_CLASS_NAME}`,
    );
    let burgerMenu = document.querySelector<HTMLElement>(
      `.${BURGER_MENU_CLASS_NAME}`,
    );
    let mobileSupportButton = document.querySelector<HTMLElement>(
      `.${MOBILE_SUPPORT_BUTTON_CLASS_NAME}`,
    );
    let supportButton = document.querySelector<HTMLElement>(
      `.${SUPPORT_BUTTON_CLASS_NAME}`,
    );

    if (
      !headerNav ||
      !footer ||
      !burgerMenu ||
      !profileButtonHeader ||
      !profileButtonLayout ||
      !mobileSupportButton ||
      !supportButton
    ) {
      return;
    }

    if (hide) {
      // Apply new styles
      headerNav.style.display = 'none';
      profileButtonHeader.style.display = 'none';
      profileButtonLayout.style.display = 'none';
      footer.style.paddingBottom = 'initial';
      burgerMenu.style.display = 'none';
      mobileSupportButton.style.display = 'none';
      supportButton.style.display = 'none';
    } else {
      // Restore original styles
      headerNav.style.display = initialHeaderStyle;
      profileButtonHeader.style.display = initialProfileButtonHeaderStyle;
      profileButtonLayout.style.display = initialProfileButtonLayoutStyle;
      footer.style.paddingBottom = initialFooterStyle;
      burgerMenu.style.display = initialBurgerStyle;
      mobileSupportButton.style.display = initialMobileSupportButtonStyle;
      supportButton.style.display = initialSupportButtonStyle;
    }
  }, [hide]);
}
