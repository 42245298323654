import AlertTriangleImage from './Images/AlertTriangle.png';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';
import SupportIcon from '@front_common/ZendeskWidget/OldDesign/Icons/Support';
import { LinkToRoute } from '@front_common/Router/Router';
import { WEB_ROUTE_MY_SUBSCRIPTIONS } from '@shared_frontend/Common/WebRoutes';
import styled from 'styled-components';

type Props = {
  showGoToSubButton?: boolean;
};

//TODO: rename the file
export default function ExistedSubscriptionModalContent({
  showGoToSubButton,
}: Props) {
  return (
    <SubscriptionModalContent>
      <img src={AlertTriangleImage} alt="existed sub icon" />
      <SubscriptionMessage>
        Oops! You already have an active subscription.
      </SubscriptionMessage>
      <MenuSubButton onClick={handleZendeskOpenChatClick}>
        <SupportIcon />
        <span>Contact support</span>
      </MenuSubButton>
      {showGoToSubButton && (
        <ButtonLink href={`${WEB_ROUTE_MY_SUBSCRIPTIONS}/`}>
          Go to my subscriptions
        </ButtonLink>
      )}
    </SubscriptionModalContent>
  );
}

let SubscriptionModalContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 100px;
    width: 240px;
    margin-bottom: 16px;
  }
`;

let SubscriptionMessage = styled.h4`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let MenuSubButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 14px 16px;
  border-radius: 10px;
  color: var(--textLink);
  border: 1px solid var(--strokeBrand);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  background-color: transparent;

  span {
    margin-left: 12px;
  }
`;

let ButtonLink = styled(LinkToRoute)`
  text-align: center;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  padding: 14px 16px;
  margin-top: 16px;
  border-radius: 10px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 22px;
  color: var(--textPrimaryInvert);
  background-color: var(--buttonPrimary);
`;
