export default function WalletIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 6.66628V3.75016C13.3333 3.05704 13.3333 2.71048 13.1873 2.49751C13.0598 2.31143 12.8622 2.18503 12.6398 2.14718C12.3852 2.10386 12.0706 2.24909 11.4413 2.53955L4.04918 5.95127C3.48792 6.21031 3.20729 6.33984 3.00175 6.54071C2.82005 6.71829 2.68135 6.93507 2.59625 7.17447C2.5 7.44527 2.5 7.75435 2.5 8.3725V12.4996M13.75 12.0829H13.7583M2.5 9.33295L2.5 14.8329C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.8329V9.33295C17.5 8.39953 17.5 7.93282 17.3183 7.5763C17.1586 7.26269 16.9036 7.00773 16.59 6.84794C16.2335 6.66628 15.7668 6.66628 14.8333 6.66628L5.16667 6.66628C4.23325 6.66628 3.76654 6.66628 3.41002 6.84793C3.09641 7.00772 2.84144 7.26269 2.68166 7.57629C2.5 7.93281 2.5 8.39952 2.5 9.33295ZM14.1667 12.0829C14.1667 12.3131 13.9801 12.4996 13.75 12.4996C13.5199 12.4996 13.3333 12.3131 13.3333 12.0829C13.3333 11.8528 13.5199 11.6663 13.75 11.6663C13.9801 11.6663 14.1667 11.8528 14.1667 12.0829Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
