import styled from 'styled-components';
import { formatDateToShortString } from '@shared_frontend/Common/Date';

type TotalDiscountProps = {
  sessionAmountCents: number;
};

export function TotalPriceToPay({ sessionAmountCents }: TotalDiscountProps) {
  return (
    <div>
      <Total>
        <span>Total</span>
        <span>${sessionAmountCents / 100}</span>
      </Total>
      <PaymentPriceMessage>
        Your paid subscription begins on{' '}
        <TextBold>{formatDateToShortString(new Date())}</TextBold> - you will be
        charged
        <TextBold> ${sessionAmountCents / 100}</TextBold>. You may change or
        cancel your subscription at any time.
      </PaymentPriceMessage>
    </div>
  );
}

let Total = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
`;

let PaymentPriceMessage = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
`;

let TextBold = styled.span`
  font-weight: var(--fontWeightBold);
`;
