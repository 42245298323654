import { useFAQTopics } from '@front_common/Strapi/StrapiFAQ';
import FaqLayout from '../FaqLayout';
import styled from 'styled-components';
import { LinkToRoute } from '@front_common/Router/Router';
import { strapiAssetUrl } from '@front_common/Strapi/StrapiRequest';
import { WEB_ROUTE_FAQ } from '@shared_frontend/Common/WebRoutes';
import { useEffect } from 'preact/compat';
import {
  setDescriptionHeader,
  setTitleHeader,
} from '@front_common/Seo/SeoHeaders';

let colorSets = [
  {
    top: 'var(--lightBlue80)',
    bottom: 'var(--cardsBackgroundLightBlueSecondary)',
  },
  {
    top: 'var(--orange70)',
    bottom: 'var(--orange90)',
  },
  {
    top: 'var(--purple90)',
    bottom: 'var(--purple100)',
  },
  {
    top: 'var(--green80)',
    bottom: 'var(--green90)',
  },
];

export default function FaqPage() {
  let [faqTopics] = useFAQTopics();

  useEffect(() => {
    setTitleHeader('Ohayu eSIM Knowledgebase 📚 FAQs and Answers');
    setDescriptionHeader(
      'Learn how to get started with Ohayu. Find answers to common questions about eSIM installation, setup, using data plans, and troubleshooting.',
    );
  }, []);

  return (
    <FaqLayout title="FAQ">
      <Topics>
        {faqTopics?.map((topic, index) => (
          <LinkToRoute
            key={topic.attributes.slug}
            href={`${WEB_ROUTE_FAQ}/${topic.attributes.slug}/`}
            style={{ textDecoration: 'none' }}
          >
            <Topic>
              <TopSide
                $backgroundColor={colorSets[index % colorSets.length]?.top}
              >
                {topic.attributes.image.data && (
                  <img
                    height={48}
                    src={strapiAssetUrl(
                      topic.attributes.image.data.attributes.url,
                    )}
                    alt="topic image"
                  />
                )}
              </TopSide>
              <BottomSide
                $backgroundColor={colorSets[index % colorSets.length]?.bottom}
              >
                {topic.attributes.name}
              </BottomSide>
            </Topic>
          </LinkToRoute>
        ))}
      </Topics>
    </FaqLayout>
  );
}

let Topics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 393px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 960px) {
    gap: 20px;
  }
`;

let Topic = styled.div`
  border-radius: 16px;
  overflow: hidden;

  @media (min-width: 393px) {
    width: 172px;
  }

  @media (min-width: 700px) {
    width: 240px;
  }

  @media (min-width: 960px) {
    width: 196px;
  }
`;

let TopSide = styled.div<{ $backgroundColor: string | undefined }>`
  background-color: ${(props) => props.$backgroundColor};
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let BottomSide = styled.div<{ $backgroundColor: string | undefined }>`
  background-color: ${(props) => props.$backgroundColor};
  height: 44px;
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--textPrimary);
  text-align: center;
  padding: 12px;

  @media (min-width: 393px) {
    height: 64px;
  }

  @media (min-width: 700px) {
    height: 72px;
    font-size: var(--fontSizeBig);
    line-height: 24px;
  }

  @media (min-width: 960px) {
    height: 80px;
    font-size: 20px;
    line-height: 28px;
  }
`;
