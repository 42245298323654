import { asyncHook } from '@shared_frontend/Common/HookFactory';
import { strapiRequest } from './StrapiRequest';

type StrapiLegalResponse = {
  id: number;
  attributes: {
    title: string;
    content: string;
    seo: {
      title: string;
      description: string;
    };
  };
};

export const useTermsAndConditions = asyncHook(() =>
  strapiRequest<StrapiLegalResponse>('terms-and-conditions'),
);

export const usePrivacyPolicy = asyncHook(() =>
  strapiRequest<StrapiLegalResponse>('privacy-policy'),
);

export const useRefundPolicy = asyncHook(() =>
  strapiRequest<StrapiLegalResponse>('refund-policy'),
);

export const useAcceptablePolicy = asyncHook(() =>
  strapiRequest<StrapiLegalResponse>('acceptable-use-policy'),
);

export const useSubscriptionPolicy = asyncHook(() =>
  strapiRequest<StrapiLegalResponse>('subscription-policy'),
);
