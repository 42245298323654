import { JSX } from 'preact/jsx-runtime';
import { DesignSplitGroupId } from '@front_common/Api/Api';
import { useDynamicSplitComponent } from './useDynamicSplitComponent';
import NewDesignHeader from './NewDesign/Header';
import OldDesignHeader from './OldDesign/Header';

type IHeaderProps = {
  splitGroupId: number;
  onlyLogo?: boolean;
  hideSignInProfileButton: boolean;
  hideSupportButton: boolean;
};

export function HeaderContainer<P extends IHeaderProps>(props: P) {
  let { splitGroupId } = props;

  let HeaderComponent = useDynamicSplitComponent<P>({
    components: {
      [DesignSplitGroupId.A]: OldDesignHeader,
      [DesignSplitGroupId.B]: NewDesignHeader,
    },
    identifier: splitGroupId || DesignSplitGroupId.A,
  });

  return <HeaderComponent {...(props as P & JSX.IntrinsicAttributes)} />;
}
