import { createContext, useState, ReactNode } from 'react';

export interface UnsubscribeData {
  cancellationReasons: string[];
  cancellationDetailsMessage: string;
}

export interface UnsubscribeContextType {
  step: number;
  setStep: (step: number) => void;
  prevStep: () => void;
  unsubscribeData: UnsubscribeData;
  updateUnsubscribeData: (key: keyof UnsubscribeData, value: any) => void;
}

export const UnsubscribeContext = createContext<UnsubscribeContextType | null>(
  null,
);

interface UnsubscribeProviderProps {
  children: ReactNode;
}

export function UnsubscribeProvider({ children }: UnsubscribeProviderProps) {
  let [step, setStep] = useState(1);
  let [unsubscribeData, setUnsubscribeData] = useState<UnsubscribeData>(() => ({
    cancellationReasons: [],
    cancellationDetailsMessage: '',
    step: 1,
  }));

  function updateUnsubscribeData(
    key: keyof UnsubscribeData,
    value: string | string[],
  ) {
    setUnsubscribeData((prevData) => {
      let newData = { ...prevData, [key]: value };
      return newData;
    });
  }

  function prevStep() {
    if (step === 1) {
      history.back();
    } else {
      setStep((prev) => prev - 1);
    }
  }

  return (
    <UnsubscribeContext.Provider
      value={{
        step,
        setStep,
        unsubscribeData,
        prevStep,
        updateUnsubscribeData,
      }}
    >
      {children}
    </UnsubscribeContext.Provider>
  );
}
