import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';
import DomainSuggestion from '../../DomainSuggestion/OldDesign/DomainSuggestion';

type AuthFormEmailProps = {
  isLoading: boolean;
  errorTextEmail: string;
  onSubmit: (event: Event) => void;
  lastEmail: string;
  formId?: string;
};

export default function AuthFormEmail(props: AuthFormEmailProps) {
  let {
    isLoading,
    errorTextEmail,
    onSubmit,
    lastEmail,
    formId = 'auth',
  } = props;

  return (
    <AuthFormEmailStyled id={formId} noValidate onSubmit={onSubmit}>
      <TextInputWrapper>
        <DomainSuggestion
          name="email"
          type="email"
          placeholder="youremail@mail.com"
          label="Email"
          errorText={errorTextEmail}
          value={lastEmail}
        />
      </TextInputWrapper>
      <CreateAccountButton
        disabled={isLoading}
        form="auth"
        type="submit"
        sizeParam="L"
        isLoading={isLoading}
      >
        Continue
      </CreateAccountButton>
    </AuthFormEmailStyled>
  );
}

let AuthFormEmailStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  order: 0;
`;

let TextInputWrapper = styled.div`
  z-index: 50;
`;

let CreateAccountButton = styled(Button)`
  width: 100%;
`;
