import styled from 'styled-components';
import Blob1 from './Icons/Blob1';
import Customer1Image from './Images/Customer1.jpg';
import Customer2Image from './Images/Customer2.jpg';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';

export default function LargeHeaderWithCustomers() {
  let windowDimensions = useWindowDimensions({ onlyWidth: true });

  return (
    <LargeHeaderWithCustomersWrapper>
      <LargeTitle>
        Travel eSIM: <ColoredText>get internet </ColoredText>
        effortlessly, anywhere
      </LargeTitle>
      <Blob1Styled />
      <Blob2Styled />
      {windowDimensions.width >= 1200 && (
        <>
          <CustomerImage
            height="124px"
            width="124px"
            src={Customer1Image}
            alt="customer"
          />
          <CustomerImage2
            height="124px"
            width="124px"
            src={Customer2Image}
            alt="customer"
          />
        </>
      )}
    </LargeHeaderWithCustomersWrapper>
  );
}

let LargeHeaderWithCustomersWrapper = styled.div`
  position: relative;
  padding-top: 52px;

  @media (min-width: 960px) {
    padding-top: 152px;
  }

  @media (min-width: 960px) and (max-height: 762px) {
    padding-top: 52px;
  }
`;

let LargeTitle = styled.h1`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 32px;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 600px;

  @media (min-width: 960px) {
    max-width: 778px;
    font-size: 62px;
  }

  @media (min-width: 960px) and (max-height: 762px) {
    font-size: 48px;
  }
`;

let ColoredText = styled.span`
  color: var(--textSelected);
`;

let CustomerImage = styled.img`
  display: none;

  @media (min-width: 1200px) {
    display: block;
    z-index: 0;
    height: 124px;
    width: 124px;
    border-radius: 20px;
    position: absolute;
    top: 298px;
    left: 30px;
  }

  @media (min-width: 1200px) and (max-height: 762px) {
    top: 198px;
  }
`;

let CustomerImage2 = styled.img`
  display: none;

  @media (min-width: 1200px) {
    display: block;
    z-index: 0;
    height: 124px;
    width: 124px;
    border-radius: 20px;
    position: absolute;
    right: 0px;
    top: 105px;
  }

  @media (min-width: 1200px) and (max-height: 762px) {
    top: 55px;
  }
`;

let Blob1Styled = styled(Blob1)`
  z-index: 0;
  position: absolute;
  color: var(--green90);
  top: 9px;
  left: 22px;
  width: 75px;
  height: 58px;

  @media (min-width: 960px) {
    top: 259px;
    left: 0px;
    width: 131px;
    height: 101px;
  }

  @media (min-width: 960px) and (max-height: 762px) {
    top: 159px;
  }
`;

let Blob2Styled = styled(Blob1)`
  z-index: 0;
  position: absolute;
  color: var(--orange90);
  width: 57px;
  height: 43px;
  top: 138px;
  right: 7px;
  transform: rotate(180deg);

  @media (min-width: 960px) {
    top: 168px;
    right: 30px;
    width: 131px;
    height: 101px;
    transform: initial;
  }

  @media (min-width: 960px) and (max-height: 762px) {
    top: 118px;
  }
`;
