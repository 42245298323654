import { ComponentType } from 'react';

export interface UseDynamicComponentProps<T> {
  components: Record<string | number, ComponentType<T>>;
  identifier: number;
}

export function useDynamicSplitComponent<T>({
  components,
  identifier,
}: UseDynamicComponentProps<T>): ComponentType<T> {
  return components[identifier] || (() => null);
}
