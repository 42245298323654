/// <reference types="vite/client" />

// Import all SVG files from the flags directory
const flagFiles: Record<string, { default: string }> = import.meta.glob(
  './*.svg',
  { eager: true },
);

const flags: { [key: string]: string } = {};

for (let filename of Object.keys(flagFiles)) {
  // Extract the base name of the file without the `.svg` extension
  let baseName = filename.replace('./', '').replace('.svg', '');

  if (flagFiles[filename]?.default) {
    // Assign the SVG content to the flags object
    flags[baseName.toLowerCase()] = flagFiles[filename].default;
  }
}

export default flags;
