/* eslint-disable max-lines-per-function */
import styled from 'styled-components';
import ChevronDownIcon from '@front_common/Components/Icons/ChevronDownIcon';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import {
  useGetMyEsimsQuery,
  useGetMySubscriptions,
} from '@front_common/Api/UserApi';
import { useRouterState } from '@front_common/Router/RouterLibrary';
import {
  checkLoginOrRedirect,
  routeRedirect,
} from '@front_common/Router/Router';
import { ESimCardProvider } from '../ESimContext';
import { useCountriesActiveQuery } from '@front_common/Api/VisitorApi';
import ESimPageMainInfo from './ESimPageMainInfo';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import ESimSubPageMainContent from './ESimSubPageMainContent/ESimSubPageMainContent';
import InstallModal from '../../InstallFlow/InstallModal';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import ESimPlanDetails from './ESimPlanDetails';
import { useState } from 'preact/hooks';
import { fillRouteParams } from '@shared_frontend/Common/RouteFunctions';
import {
  WEB_ROUTE_FAQ,
  WEB_ROUTE_MY_INSTALL_GUIDE,
  WEB_ROUTE_MY_SUBSCRIPTIONS,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import DetailsModalContent from '@front_common/Components/DetailsModalContent/DetailsModalContent';
import { formatDateToShortString, getSubscriptionByEsimId } from '../Utils';
import RenewalModal from './RenewalModal/RenewalModal';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';

function processESimClick() {
  history.back();
}

function onPurchaseClick() {
  routeRedirect(WEB_ROUTE_STORE_GLOBAL);
}

function onTroubleshootingClick() {
  routeRedirect(`${WEB_ROUTE_FAQ}/troubleshooting`);
}

function onManageSubscriptionClick() {
  routeRedirect(WEB_ROUTE_MY_SUBSCRIPTIONS);
}

function onInstallationInstructionsClick(eSimIdProp: number) {
  routeRedirect(
    fillRouteParams(WEB_ROUTE_MY_INSTALL_GUIDE, { id: eSimIdProp }),
  );
}

function onSupportClick() {
  handleZendeskOpenChatClick();
}

export default function ESimPage() {
  checkLoginOrRedirect();

  let [myESimsResponse, eSIMsError, eSIMsLoading] = useGetMyEsimsQuery();
  let [mySubscriptionsResponse, mySubscriptionsError, mySubscriptionsLoading] =
    useGetMySubscriptions();

  if (eSIMsLoading || mySubscriptionsLoading) {
    return <LoadingAnimation />;
  }

  if (eSIMsError) {
    throw new Error(JSON.stringify(eSIMsError));
  }

  if (mySubscriptionsError) {
    throw new Error(JSON.stringify(mySubscriptionsError));
  }

  let [isPlanDetailsModalOpen, setIsPlanDetailsModalOpen] = useState(false);
  let [isCountriesModalOpen, setIsCountriesModalOpen] = useState(false);
  let [showQrForId, setShowQrForId] = useState<string | null>(null);
  let [preOpenedIndexTab, setPreOpenedIndexTab] = useState(0);

  let [allActiveCountries] = useCountriesActiveQuery();
  let router = useRouterState();

  let eSimId = router.matches?.['esimId'] || '';
  let eSim = myESimsResponse?.esims.find((sim) => sim.id === Number(eSimId));

  if (!eSim) {
    throw new Error(`404: Could not find eSIM with ID ${eSimId}`);
  }

  let subscription =
    eSim &&
    mySubscriptionsResponse &&
    getSubscriptionByEsimId(mySubscriptionsResponse, eSim.id);

  function checkIfESimExistInActiveCountries(countryCode: string) {
    return allActiveCountries
      ? Boolean(allActiveCountries[countryCode])
      : false;
  }

  function onCountriesModalClose() {
    setIsCountriesModalOpen(false);
  }

  function onSubPlanDetailsClick() {
    setIsCountriesModalOpen(true);
    setPreOpenedIndexTab(0);
  }

  function onInstallNowClick(eSimIdProp: number) {
    setShowQrForId(eSimIdProp.toString());
  }

  function onViewClick() {
    setIsCountriesModalOpen(true);
    setPreOpenedIndexTab(1);
  }

  function handleClosePlaDetailsModal() {
    setIsPlanDetailsModalOpen(false);
  }

  function onPlanDetailsClick() {
    setIsPlanDetailsModalOpen(true);
  }

  if (!eSim.subscriptionId && !eSim.targetCountry) {
    // TODO process "null" better
    logger.log('no target country ', { eSim });
    return <>no target country</>;
  }

  return (
    <ESimWrapperStyled>
      <BackButtonWrapper>
        <BackButton onClick={processESimClick}>
          <ChevronDownIcon />
        </BackButton>
      </BackButtonWrapper>
      <MainContentWrapper>
        <ESimCardProvider
          eSim={eSim}
          isFromActive={
            eSim.targetCountry
              ? checkIfESimExistInActiveCountries(eSim.targetCountry)
              : undefined
          }
        >
          {eSim.subscriptionId ? (
            <>
              {subscription && (
                <ESimSubPageMainContent
                  subscription={subscription}
                  onInstallNowClick={onInstallNowClick}
                  onPlanDetailsClick={onSubPlanDetailsClick}
                  onManageSubscriptionClick={onManageSubscriptionClick}
                  onInstallationInstructionsClick={
                    onInstallationInstructionsClick
                  }
                  onTroubleshootingClick={onTroubleshootingClick}
                  onPurchaseClick={onPurchaseClick}
                  onSupportClick={onSupportClick}
                  onViewClick={onViewClick}
                />
              )}
            </>
          ) : (
            <ESimPageMainInfo
              onPlanDetailsClick={onPlanDetailsClick}
              onInstallNowClick={setShowQrForId}
            />
          )}
        </ESimCardProvider>
      </MainContentWrapper>

      <InstallModal
        esimId={showQrForId || ''}
        show={Boolean(showQrForId)}
        onClose={() => setShowQrForId(null)}
      />
      <Modal
        analyticType="plan-details"
        isOpen={isPlanDetailsModalOpen}
        onClose={handleClosePlaDetailsModal}
      >
        <ESimPlanDetails
          durationDays={eSim.durationDays}
          purchaseDate={formatDateToShortString(eSim.createdAt)}
        />
      </Modal>
      <Modal
        analyticType="subscription-countries-details"
        isOpen={isCountriesModalOpen}
        onClose={onCountriesModalClose}
      >
        <DetailsModalContent preOpenedIndexTab={preOpenedIndexTab} />
      </Modal>
      <RenewalModal eSimId={eSim.id} />
    </ESimWrapperStyled>
  );
}

let ESimWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 700px) {
    width: 414px;
  }
`;

let MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let BackButtonWrapper = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
`;

let BackButton = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
    transform: rotate(90deg);
  }
`;
