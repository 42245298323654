export default function VideoIcon() {
  return (
    <svg viewBox="0 0 49 49">
      <g>
        <path
          d="M28.6381 35.7605H7.12089C6.24293 35.7605 5.40093 35.4118 4.78012 34.791C4.15931 34.1702 3.81055 33.3282 3.81055 32.4502V15.8985C3.81055 15.0205 4.15931 14.1785 4.78012 13.5577C5.40093 12.9369 6.24293 12.5881 7.12089 12.5881H28.6381C29.5161 12.5881 30.3581 12.9369 30.9789 13.5577C31.5997 14.1785 31.9485 15.0205 31.9485 15.8985V32.4502C31.9485 33.3282 31.5997 34.1702 30.9789 34.791C30.3581 35.4118 29.5161 35.7605 28.6381 35.7605ZM35.2588 19.2088V29.1399L42.6161 34.0458C42.8655 34.2122 43.1553 34.3077 43.4548 34.3222C43.7542 34.3366 44.052 34.2695 44.3163 34.1279C44.5805 33.9864 44.8014 33.7757 44.9553 33.5184C45.1091 33.2611 45.1902 32.9668 45.1899 32.667V15.6817C45.1899 15.382 45.1086 15.0879 44.9546 14.8309C44.8006 14.5738 44.5798 14.3634 44.3155 14.222C44.0513 14.0806 43.7537 14.0136 43.4544 14.0282C43.1551 14.0427 42.8653 14.1382 42.6161 14.3045L35.2588 19.2088Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
