import { useContext, useState } from 'preact/hooks';
import { WEB_ROUTE_MY_SUBSCRIPTIONS } from '@shared_frontend/Common/WebRoutes';
import { formatDateToLongString } from '@shared_frontend/Common/Date';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import Button from '@front_common/Components/Button/Button';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import { routeRedirect } from '@front_common/Router/Router';
import UserApi from '@front_common/Api/UserApi';
import { UnsubscribeContext } from './UnsubscribeProvider';
import UnsubscribeModalContent from './UnsubscribeModalContent';
import LightningImage from '../Images/Lightning.png';
import EarthImage from '../Images/Earth.png';
import DataSignImage from '../Images/DataSign.png';
import MoneyImage from '../Images/Money.png';
import styled from 'styled-components';

let items = [
  {
    icon: LightningImage,
    title: 'High speed internet',
    description: 'Fast, reliable 4G/5G connections wherever you travel.',
  },
  {
    icon: EarthImage,
    title: 'One eSIM for all countries',
    description:
      'Use the same eSIM in 112 countries — no need to buy and install new eSIMs.',
  },
  {
    icon: DataSignImage,
    title: 'Keep unused data for the next period',
    description:
      "Any leftover data rolls over, so you do not lose what you've paid for.",
  },
  {
    icon: MoneyImage,
    title: 'Lower cost for global travel',
    description:
      'Save up to 70% compared to buying separate plans for each country.',
  },
];

function handleNotNowClick() {
  routeRedirect(WEB_ROUTE_MY_SUBSCRIPTIONS);
}

type IProps = {
  subscriptionId: number;
  cancellationEffectiveDate: Date | null;
};

export default function ThirdStep({
  subscriptionId,
  cancellationEffectiveDate,
}: IProps) {
  let context = useContext(UnsubscribeContext);

  if (!context) {
    throw new Error('UnsubscribeContext must be used within a provider');
  }

  let {
    unsubscribeData: { cancellationReasons, cancellationDetailsMessage },
  } = context;

  let [isModalOpen, setIsModalOpen] = useState(false);

  function handleModalClose() {
    setIsModalOpen(false);
    routeRedirect(WEB_ROUTE_MY_SUBSCRIPTIONS);
  }

  async function handleConfirmUnsubscribe() {
    try {
      let isCanceled = await UserApi.cancelSubscription(subscriptionId);

      if (isCanceled) {
        setIsModalOpen(true);
        await UserApi.sendCancelSubscriptionFeedback(subscriptionId, {
          reason: cancellationReasons,
          comment: cancellationDetailsMessage,
        });
      }
    } catch (error_: unknown) {
      logger.error(error_);
    }
  }

  return (
    <>
      <ThirdStepWrapper>
        <ThirdStepPageTitle>Confirm cancellation</ThirdStepPageTitle>
        <ThirdStepPageSubTitle>
          You will lose access to the following:
        </ThirdStepPageSubTitle>
        <AdvantagesWrapper>
          {items.map((item) => (
            <AdvantageWrapper key={item.title}>
              <AdvantageIcon src={item.icon} alt="item-image" />
              <AdvantageInfo>
                <AdvantageTitle>{item.title}</AdvantageTitle>
                <AdvantageLabel>{item.description}</AdvantageLabel>
              </AdvantageInfo>
            </AdvantageWrapper>
          ))}
        </AdvantagesWrapper>
        {cancellationEffectiveDate && (
          <WarningMessage>
            If you cancel this subscription, it’ll take effect on{' '}
            {formatDateToLongString(cancellationEffectiveDate)}.
          </WarningMessage>
        )}
      </ThirdStepWrapper>
      <Footer>
        <NotNowButton sizeParam="L" onClick={handleNotNowClick}>
          Not now
        </NotNowButton>

        <ConfirmButton
          typeStyle="Secondary"
          sizeParam="L"
          onClick={handleConfirmUnsubscribe}
        >
          Confirm
        </ConfirmButton>
      </Footer>
      <Modal
        analyticType="unsubscribe"
        isOpen={isModalOpen}
        onClose={handleModalClose}
      >
        <UnsubscribeModalContent
          onClose={handleModalClose}
          cancelledAt={formatDateToLongString(cancellationEffectiveDate)}
        />
      </Modal>
    </>
  );
}

let ThirdStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 144px;

  @media (min-width: 700px) {
    margin-bottom: 0px;
  }
`;

let ThirdStepPageTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
`;

let ThirdStepPageSubTitle = styled.p`
  font-weight: var(--fontWeightRegular);
  margin-bottom: 22px;
`;

let AdvantagesWrapper = styled.div`
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 12px;
  background-color: var(--backgroundSecondary);
`;

let AdvantageWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--strokeSecondary);
  }
`;

let AdvantageIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

let AdvantageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

let AdvantageTitle = styled.span`
  line-height: 22px;
  margin-bottom: 4px;
  font-weight: var(--fontWeightBold);
`;

let AdvantageLabel = styled.span`
  font-weight: var(--fontWeightMedium);
`;

let Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--backgroundSecondary);
  z-index: 200;

  @media (min-width: 700px) {
    position: relative;
    background-color: transparent;
    padding: 24px 0;
    bottom: 0;
  }
`;

let WarningMessage = styled.div`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
`;

let NotNowButton = styled(Button)`
  width: 100%;
  margin-bottom: 12px;

  @media (min-width: 700px) {
    max-width: 642px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;

let ConfirmButton = styled(Button)`
  width: 100%;

  @media (min-width: 700px) {
    max-width: 642px;
  }

  @media (min-width: 960px) {
    max-width: 414px;
  }
`;
