import Accordion from '@front_common/Components/Accordion/OldDesign/Accordion';
import { WEB_ROUTE_COMPATIBLE_DEVICES } from '@shared_frontend/Common/WebRoutes';
import styled from 'styled-components';

export let data = [
  {
    title: 'Is my smartphone compatible with eSIM?',
    content: (
      <span>
        {`Compatibility varies by device. Most newer smartphones support eSIM technology. Check your device settings under "Cellular" or "Mobile Network" options, or refer to your device’s manual for confirmation. Please note, that some Smartphones from Asian countries may not support eSIM. For more information check out this `}
        <a href={`${WEB_ROUTE_COMPATIBLE_DEVICES}/`}>
          Compatible Devices guide
        </a>
        .
      </span>
    ),
  },
  {
    title: 'Does my eSIM come with a phone number?',
    content: `Our eSIMs focus purely on data services and do not include a phone number. 

This is ideal for travelers who need high-speed, reliable data without the need for traditional phone services. 

Enjoy seamless internet access worldwide with our data-only eSIM, perfect for browsing, streaming, and staying connected on the go.

However, you can still use your existing phone number and messengers like WhatsApp, Telegram, Viber, iMessage, and others to stay connected while enjoying seamless internet access worldwide.`,
  },
  {
    title: 'Can I use the data plan for hotspot?',
    content: `Absolutely! 

Our eSIM allows you to share your internet connection with other devices via a personal hotspot. 

You can turn on your Hotspot in your device's Settings. Just go to 'Personal Hotspot' in your Settings and switch the toggle ON.`,
  },
  {
    title: 'Where do I find my QR code for eSIM activation?',
    content: `Once you purchase an eSIM plan, you will receive an email with the QR code and installation instructions.

You may also find the code in your personal account in the app or website. To find it, please follow these steps:

    1. Log in to your account.
    2. Go to ‘My eSIMs’
    3. Click on the purchased eSIM
    4. You will see the Installation Guide with the QR code right on top of the page`,
  },
  {
    title: 'Are there any specific settings required to use an Ohayu eSIM?',
    content: (
      <span>
        {`To get started with your eSIM, follow these key steps:

    1. Check Device Compatibility: Before installing an Ohayu eSIM, ensure your device supports eSIM technology. You can usually find this information in your device's settings or just check out our`}{' '}
        <a href={`${WEB_ROUTE_COMPATIBLE_DEVICES}/`}>Compatible Guide</a>{' '}
        {`to see if your phone supports eSIM.
    2. Activate Data Roaming: For seamless service, especially when traveling abroad, enable Data Roaming in your device's settings.

Aside from that, no other settings are needed. You can just get the plan of your choice, install the eSIM to your device and start enjoying seamless internet connectivity.`}
      </span>
    ),
  },
  {
    title: 'Can’t activate my eSIM. What should I do?',
    content: (
      <span>
        {`If you're having trouble activating your eSIM, don’t worry! Here are some steps to help you out:

    1. Compatibility Check: Ensure your device supports eSIM and is unlocked. You can do that by using our friendly guide:`}{' '}
        <a href={`${WEB_ROUTE_COMPATIBLE_DEVICES}/`}>Compatible Guide</a>
        {`

    2. Internet Connection: Make sure that your device  has a a stable internet connection or is connected to Wi-Fi as eSIM activation typically requires internet access. 

    3. Scan Carefully: Double-check that you're scanning the QR code correctly, or try entering the activation code manually.

    4. Double Check the Guide: Check that you have followed the Installation Guide steps correctly. 

    5. Restart: Sometimes a simple phone restart can do the trick!

    6. Contact Us: If you're still having issues, reach out to our support team. We're here to help and get you connected quickly!`}
      </span>
    ),
  },
  {
    title: 'eSIM is activated, but not working. What should I do?',
    content: `If your eSIM is activated but not working, we’re here to help! 
Follow these friendly tips:

    1. Check Settings: Make sure your eSIM is correctly installed and activated in your device’s settings. Ensure you have selected the correct eSIM plan for data usage. 

    2. Signal Check: Make sure you’re in an area with good network coverage. Sometimes, the local network could have a weak signal where you are currently located, which is why the internet might not work. 
Unfortunately, we are unable to control this, so please try to just move to a different location with a better signal.

    3. Enable Roaming: If you’re traveling, ensure data roaming is turned on. You would need to go to your Settings and turn on Data Roaming.

    4. Make eSIM active: Make sure that your eSIM is set as the active cellular plan in your device’s settings. 

    5. Restart Device: Give your device a restart to refresh connections.

    6. Turn off Wi-Fi: This ensures the device is using the mobile network for data, since most phones tend to use Wi-Fi as source of data.

    7. Reach Out: If you’re still facing issues, contact our support team. We're ready to assist you and get things sorted out!`,
  },
  {
    title: 'Can I purchase the eSIM but not activate it right away?',
    content: `Yes, of course. 

You can purchase the eSIM and just save the provided QR code for later use. Once you’re ready just install the eSIM on your device and activate it. 
Only once activated does the validity of the plan start. 

ℹ️ We recommend activating your plan right before your trip, to ensure proper activation.`,
  },
];

export default function FAQ() {
  return (
    <div>
      <Title>FAQ</Title>
      <Accordion data={data} />
    </div>
  );
}

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (min-width: 960px) {
    font-size: 34px;
    line-height: 40px;
  }
`;
