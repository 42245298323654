import Button from '@front_common/Components/Button/Button';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import confettiImage from '@front_common/Components/Images/ConfettiImage.png';
import doneImage from './DoneImage.png';
import { formatDataAmount } from '../../Utils';

type IProps = {
  retainedData: number;
  closeModal: () => void;
};

export default function RenewalModalContent(props: IProps) {
  let { retainedData, closeModal } = props;

  let [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    setStartAnimation(true);
  }, []);

  return (
    <>
      <Top>
        <Animation>
          <Confetti src={confettiImage} $startAnimation={startAnimation} />
          <Done src={doneImage} />
          <BlurLine />
        </Animation>
      </Top>
      <Bottom>
        <Text>
          <Title>Renewal successful</Title>
          <Subtitle>
            Congrats! You have retained {formatDataAmount(retainedData)} of
            unused data from prior periods.
          </Subtitle>
        </Text>
        <ButtonStyled sizeParam="L" onClick={closeModal}>
          Got it
        </ButtonStyled>
      </Bottom>
    </>
  );
}

let Top = styled.div`
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

let Animation = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`;

let Confetti = styled.img<{ $startAnimation: boolean }>`
  width: 406px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${(props) => (props.$startAnimation ? '-543px' : '0px')};
  z-index: 1;
  transition: bottom 10s ease-out;

  @media (min-width: 700px) {
    width: 530px;
  }
`;

let Done = styled.img`
  z-index: 2;
  height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

let BlurLine = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: -1px;
  height: 20px;
  mask: linear-gradient(transparent, grey, black);
  backdrop-filter: blur(2px);
`;

let Text = styled.div`
  padding-bottom: 16px;
`;

let Title = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: 700px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

let Subtitle = styled.p`
  line-height: 22px;
  text-align: center;
`;

let Bottom = styled.div`
  padding: 0 20px 20px 20px;
`;

let ButtonStyled = styled(Button)`
  width: 100%;
`;
