import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import ChevronDownIcon from './Icons/ChevronDownIcon';
import mapImage from './Images/Map.png';
import bigMapImage from './Images/BigMap.png';
import SearchInput from './SearchInput';
import Flag from '@front_common/Components/Flag/Flag';
import mapPointImage from './Images/MapPoint.png';
import VisitorApi from '@front_common/Api/VisitorApi';
import { SubEsimCountryOperator } from '@shared_backend/Module/Subscription/SubEsimOperatorResponse';

export default function CountriesDetails() {
  return (
    <CountriesDetailsWrapper>
      <Map />
      <SearchCountry />
    </CountriesDetailsWrapper>
  );
}

let CountriesDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export function Map() {
  let [isVisible, setIsVisible] = useState(false);

  function toggleVisibility() {
    setIsVisible(!isVisible);
  }

  return (
    <MapWrapper>
      <ShowMapButton onClick={toggleVisibility}>
        <MapImage src={mapImage} alt="map" />
        <ShowMapText>
          <ShowMap>Show map</ShowMap>
          <Text>112 countries and 229 operators</Text>
        </ShowMapText>
        <ChevronWrapper $isVisible={isVisible}>
          <ChevronDownIcon />
        </ChevronWrapper>
      </ShowMapButton>
      <MapContainer $isVisible={isVisible}>
        <BigMapImage src={bigMapImage} alt="big map" />
      </MapContainer>
    </MapWrapper>
  );
}

export function SearchCountry() {
  let [searchValue, setSearchValue] = useState('');
  let [countries, setCountries] = useState<SubEsimCountryOperator[] | null>(
    null,
  );
  let [countriesOperators, setCountriesOperators] = useState<
    SubEsimCountryOperator[]
  >([]);

  useEffect(() => {
    async function loadData() {
      let data = await VisitorApi.getSubsCountriesOperators();

      setCountriesOperators(data);
      setCountries(data);
    }

    loadData();
  }, []);

  function handleSearchChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      setSearchValue(event.target.value);

      let filteredCountries = countriesOperators.filter((country) =>
        country.country
          .toLowerCase()
          .includes((event.target as HTMLInputElement).value.toLowerCase()),
      );

      setCountries(filteredCountries);
    }
  }

  function onClearClick() {
    setSearchValue('');
    setCountries(countriesOperators);
  }

  return (
    <SearchCountryWrapper>
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onInput={handleSearchChange}
        onClearClick={onClearClick}
      />
      <Countries>
        {countries &&
          countries.length > 0 &&
          countries.map((country) => (
            <CountryRow key={country.country}>
              <Country>
                <FlagWrapper>
                  <Flag withBorder countryCode={country.countryCode} />
                </FlagWrapper>
                <CountryName>{country.country}</CountryName>
              </Country>
              <OperatorInfo>
                {country.operators.map((operator) => (
                  <OperatorRow key={operator.operator}>
                    <Operator>{operator.operator}</Operator>
                    <Speed>{operator.speed}</Speed>
                  </OperatorRow>
                ))}
              </OperatorInfo>
            </CountryRow>
          ))}
        {!countries ||
          (countries.length === 0 && (
            <NoResults>
              <NoResultsTitle>No results found</NoResultsTitle>
              <img src={mapPointImage} alt="map point" />
              <NoResultsText>
                We couldn’t find the destination you’re looking for.
              </NoResultsText>
            </NoResults>
          ))}
      </Countries>
    </SearchCountryWrapper>
  );
}

let MapWrapper = styled.div`
  background-color: var(--lightBlue80);
  border-radius: 16px;
  padding: 12px;
`;

let ShowMapButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  gap: 8px;
  -webkit-tap-highlight-color: transparent; // Remove touch highlight
  width: 100%;
  color: var(--textPrimary);

  svg {
    min-width: 24px;
    height: 24px;
  }
`;

let MapImage = styled.img`
  width: 24px;
  height: 24px;
`;

let ShowMapText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: auto;
`;

let ChevronWrapper = styled.span<{ $isVisible: boolean }>`
  display: inline-block;
  transition: transform 0.2s;
  transform: ${({ $isVisible }) =>
    $isVisible ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

let ShowMap = styled.span`
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  line-height: 22px;
  text-align: left;
  color: var(--textLink);
`;

let Text = styled.span`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

let MapContainer = styled.div<{ $isVisible: boolean }>`
  max-height: ${({ $isVisible }) => ($isVisible ? '300px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s;
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

let BigMapImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 8px;
`;

let SearchCountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let Countries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let CountryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0;
`;

let Country = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

let FlagWrapper = styled.div`
  display: flex;

  img {
    height: 20px;
  }

  @media (min-width: 393px) {
    img {
      height: 22px;
    }
  }
`;

let CountryName = styled.span`
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeSmall);
  line-height: 20px;

  @media (min-width: 393px) {
    font-size: var(--fontSizeRegular);
    line-height: 22px;
  }
`;

let OperatorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let OperatorRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

let Operator = styled.span`
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  text-align: right;
`;

let Speed = styled.div`
  border: var(--borderOutlinePrimary);
  font-size: 10px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 14px;
  width: 29px;
  min-width: 29px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 8px;

  img {
    width: 120px;
    height: 120px;
  }
`;

let NoResultsTitle = styled.span`
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
`;

let NoResultsText = styled.span`
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
