export default function WorldWideSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_115946)">
        <path
          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM22 12C22.0006 13.9492 21.4282 15.8556 20.354 17.482C19.4918 16.5222 19.0102 15.2802 19 13.99V12H13C12.7348 12 12.4804 11.8946 12.2929 11.7071C12.1054 11.5196 12 11.2652 12 11V10H15V6H17.914L19.023 4.891C19.9662 5.81908 20.7152 6.92564 21.2263 8.14617C21.7374 9.3667 22.0004 10.6768 22 12ZM2.04901 12.963L6.08601 17H10C10.2652 17 10.5196 17.1054 10.7071 17.2929C10.8946 17.4804 11 17.7348 11 18V21.949C8.7002 21.7142 6.5524 20.6912 4.92093 19.0533C3.28947 17.4155 2.27479 15.2637 2.04901 12.963ZM13 21.949V18C13 17.2044 12.6839 16.4413 12.1213 15.8787C11.5587 15.3161 10.7957 15 10 15H6.91401L2.16301 10.249C2.44918 8.6222 3.13474 7.09189 4.15818 5.79538C5.18162 4.49887 6.51091 3.47671 8.0268 2.82061C9.54269 2.16452 11.1977 1.89502 12.8435 2.03628C14.4892 2.17755 16.0741 2.72517 17.456 3.63L17.086 4H13V8H10V11C10 11.7957 10.3161 12.5587 10.8787 13.1213C11.4413 13.6839 12.2044 14 13 14H17C16.9977 15.8945 17.7421 17.7137 19.072 19.063C17.4432 20.699 15.2971 21.7191 13 21.949Z"
          fill="#EF8268"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_115946">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
