import styled from 'styled-components';
import ChevronRightIcon from './ChevronRightIcon';
import { LinkToRoute } from '@front_common/Router/Router';

type IProps = {
  prevLink: string;
  prevName: string;
  currentLink: string;
};

export default function FakeBreadcrumbs(props: IProps) {
  let { prevLink, prevName, currentLink } = props;

  return (
    <FakeBreadcrumbsWrapper>
      <PrevLink href={prevLink}>{prevName} plans</PrevLink>
      <ChevronRightIcon />
      <CurrentLink>{currentLink}</CurrentLink>
    </FakeBreadcrumbsWrapper>
  );
}

let FakeBreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

let PrevLink = styled(LinkToRoute)`
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  color: var(--textPrimary);
`;

let CurrentLink = styled.span`
  font-size: 12px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 16px;
  margin-left: 4px;
`;
