export default function DualSimIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M23.3 2.9C22.7 2.3 22 2 21.2 2H13C11.3 2 10 3.3 10 5V6H9C7.3 6 6 7.3 6 9V27C6 28.7 7.3 30 9 30H23C24.7 30 26 28.7 26 27V26H27C28.7 26 30 24.7 30 23V10.8C30 10 29.7 9.2 29.1 8.7L23.3 2.9ZM23 28H9C8.4 28 8 27.6 8 27V9C8 8.4 8.4 8 9 8H17.2C17.5 8 17.7 8.1 17.9 8.3L23.7 14.1C23.9 14.3 24 14.5 24 14.8V25V27C24 27.6 23.6 28 23 28ZM28 23C28 23.6 27.6 24 27 24H26V14.8C26 14 25.7 13.2 25.1 12.7L19.3 6.9C18.7 6.3 18 6 17.2 6H12V5C12 4.4 12.4 4 13 4H21.2C21.5 4 21.7 4.1 21.9 4.3L27.7 10.1C27.9 10.3 28 10.5 28 10.8V23Z"
          fill="currentColor"
        />
        <path
          d="M16 18C15.4 18 15 18.4 15 19V25C15 25.6 15.4 26 16 26C16.6 26 17 25.6 17 25V19C17 18.4 16.6 18 16 18ZM12 18C11.4 18 11 18.4 11 19V25C11 25.6 11.4 26 12 26C12.6 26 13 25.6 13 25V19C13 18.4 12.6 18 12 18ZM20 18C19.4 18 19 18.4 19 19V25C19 25.6 19.4 26 20 26C20.6 26 21 25.6 21 25V19C21 18.4 20.6 18 20 18Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
