export default function RightIcon() {
  return (
    <svg viewBox="0 0 45 45">
      <g>
        <path
          d="M25.857 22.4743L15.8126 32.4799C15.5006 32.804 15.3285 33.237 15.3333 33.686C15.338 34.135 15.5191 34.5643 15.8379 34.8818C16.1566 35.1993 16.5875 35.3798 17.0383 35.3845C17.489 35.3892 17.9236 35.2178 18.249 34.907L29.1335 24.0638C29.2925 23.9836 29.4441 23.8853 29.5769 23.753C29.7443 23.5858 29.8751 23.3858 29.9609 23.1656C30.0468 22.9455 30.0858 22.71 30.0755 22.474C30.0858 22.238 30.0468 22.0025 29.9609 21.7824C29.8751 21.5622 29.7443 21.3622 29.5769 21.195C29.4441 21.0631 29.2925 20.9644 29.1335 20.8845L18.249 10.041C18.089 9.88167 17.899 9.75527 17.69 9.66903C17.481 9.58279 17.2569 9.53841 17.0307 9.53843C16.8044 9.53844 16.5804 9.58285 16.3714 9.66912C16.1623 9.75538 15.9724 9.88181 15.8125 10.0412C15.6525 10.2006 15.5256 10.3898 15.439 10.598C15.3524 10.8063 15.3079 11.0294 15.3079 11.2548C15.3079 11.4802 15.3525 11.7034 15.4391 11.9116C15.5257 12.1198 15.6526 12.309 15.8126 12.4684L25.857 22.4743Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
