import styled from 'styled-components';
import ESimDataLoader from '../ESimDataLoader';
import React from 'preact/compat';

type DataLoaderContainerProps = {
  children: React.ReactNode;
  progress?: number;
  primaryColor?: string;
  secondaryColor?: string;
};

export function DataLoaderContainer(props: DataLoaderContainerProps) {
  let { children, progress, primaryColor, secondaryColor } = props;

  return (
    <ESimDataLoader
      progress={progress}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      <LoaderInnerContainer>
        <FontWrapper>{children}</FontWrapper>
      </LoaderInnerContainer>
    </ESimDataLoader>
  );
}

let LoaderInnerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 136px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

let FontWrapper = styled.span`
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  color: var(--textSecondary);
  text-align: center;
`;
