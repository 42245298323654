export function MotorolaIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4973 3C30.2908 3 38 10.8881 38 20.4959C38 30.1605 30.162 37.9932 20.4973 37.9932C10.8327 37.9932 3 30.1605 3 20.4959C3 10.8312 10.8331 3 20.4973 3ZM20.4584 24.1642L15.5634 7.92519L9.22578 28.7165H10.415C10.415 28.7165 11.2158 25.0997 12.513 22.939C13.2214 21.7678 14.3119 20.8085 15.7705 20.8357C16.7983 20.8542 17.6871 21.4172 18.7985 22.9682C19.4666 23.9012 20.4954 26.2748 20.4954 26.2748C20.4954 26.2748 21.5242 23.9046 22.1946 22.9682C23.3017 21.4172 24.1915 20.8542 25.2212 20.8357C26.6769 20.808 27.7718 21.7678 28.4773 22.941C29.7774 25.0992 30.5757 28.716 30.5757 28.716H31.7674L25.4259 7.92471L20.5309 24.1642L20.4978 24.1939L20.4584 24.1642Z"
        fill="#5C92FA"
      />
    </svg>
  );
}
