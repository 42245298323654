export default function GraduationSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14411_116043)">
        <path
          d="M14.12 1.62653C13.4886 1.21759 12.7523 1 12 1C11.2477 1 10.5114 1.21759 9.88 1.62653L0 7.99953L4 10.5795V20.4135L4.293 20.7065C4.387 20.7995 6.647 22.9995 12 22.9995C17.353 22.9995 19.613 20.7995 19.707 20.7065L20 20.4135V10.5795L22 9.28953V19.9995H24V7.99953L14.12 1.62653ZM18 19.5155C16.1928 20.5977 14.1033 21.1145 12 20.9995C9.89685 21.1148 7.8073 20.5983 6 19.5165V11.8695L9.88 14.3695C10.5111 14.7794 11.2475 14.9976 12 14.9976C12.7525 14.9976 13.4889 14.7794 14.12 14.3695L18 11.8695V19.5155ZM13.036 12.6915C12.7277 12.8921 12.3678 12.9988 12 12.9988C11.6322 12.9988 11.2723 12.8921 10.964 12.6915L3.69 7.99953L10.964 3.30753C11.2723 3.10698 11.6322 3.00023 12 3.00023C12.3678 3.00023 12.7277 3.10698 13.036 3.30753L20.31 7.99953L13.036 12.6915Z"
          fill="#21252A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14411_116043">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
