import { useState } from 'preact/hooks';
import styled from 'styled-components';
import {
  WEB_ROUTE_HOME,
  WEB_ROUTE_MY_ESIMS,
  WEB_ROUTE_STORE_GLOBAL,
} from '@shared_frontend/Common/WebRoutes';
import { HELP_LIST } from '@shared_frontend/Module/Layout/FooterConstants';
import AuthService from '@front_common/Api/AuthService';
import { LinkToRoute, logoutAndRedirect } from '@front_common/Router/Router';
import SignOutIcon from './Icons/SignOut';
import ChevronIcon from './Icons/ChevronIcon';
import { handleZendeskOpenChatClick } from '@front_common/ZendeskWidget/initializeZendesk';

let menuLinkHeight = 44;

export default function Sidebar() {
  let authenticated = AuthService.isLoggedIn();

  let [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <StyledSidebar>
      <Body>
        <Links>
          <MenuLink href={WEB_ROUTE_HOME}>Country plans</MenuLink>
          <MenuLink href={`${WEB_ROUTE_STORE_GLOBAL}/`}>Global plans</MenuLink>
          <DropdownMenu>
            <MenuButton onClick={() => setShowSubMenu(!showSubMenu)}>
              <span>Help</span>
              <ChevronIconWrapper $up={showSubMenu}>
                <ChevronIcon />
              </ChevronIconWrapper>
            </MenuButton>
            <MenuList
              $show={showSubMenu}
              $menuHeight={(HELP_LIST.length + 1) * menuLinkHeight}
            >
              {HELP_LIST.map((link) => (
                <>
                  <MenuSubLink key={link.label} href={`${link.href}/`}>
                    {link.label}
                  </MenuSubLink>
                </>
              ))}
              <MenuSubButton onClick={handleZendeskOpenChatClick}>
                Contact support
              </MenuSubButton>
            </MenuList>
          </DropdownMenu>
          {authenticated && (
            <MenuLink href={WEB_ROUTE_MY_ESIMS}>My eSIMs</MenuLink>
          )}
        </Links>
      </Body>
      {authenticated && (
        <Footer>
          <SignOutButton onClick={logoutAndRedirect}>
            <SignOutIcon />
            <span>Sign out</span>
          </SignOutButton>
        </Footer>
      )}
    </StyledSidebar>
  );
}

let StyledSidebar = styled.div`
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

let Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 12px 16px;
  overflow-y: auto;

  @media (min-width: 700px) {
    padding: 12px 40px;
  }
`;

let Links = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

let Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid var(--strokeSecondary);
  padding: 16px 16px 32px;
  gap: 10px;

  @media (min-width: 700px) {
    padding: 16px;
  }
`;

let SignOutButton = styled.button`
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--errorButtonDanger);
  font-family: var(--fontFamilyHeader);
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;
`;

let MenuLink = styled(LinkToRoute)`
  height: ${menuLinkHeight}px;
  padding: 0 12px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeBig);
  color: var(--textPrimary);
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  &:active {
    color: var(--buttonPressed);
  }
`;

let MenuButton = styled.button`
  background-color: transparent;
  border: none;
  height: 48px;
  padding: 0 12px;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeBig);
  color: var(--textPrimary);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  &:active {
    color: var(--buttonPressed);
  }
`;

let ChevronIconWrapper = styled.div<{ $up: boolean }>`
  svg {
    transform: ${(props) => (props.$up ? 'rotate(180deg)' : '')};
    transition: transform 0.2s;
  }
`;

let DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

let MenuList = styled.div<{ $show: boolean; $menuHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.$show ? props.$menuHeight + 'px' : '0px')};
  overflow: hidden;
  transition: height 0.2s;
`;

let MenuSubLink = styled(MenuLink)`
  font-size: var(--fontSizeRegular);
  padding-left: 24px;
  min-height: ${menuLinkHeight}px;
  width: 100%;
`;

let MenuSubButton = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: start;
  padding-left: 24px;
  min-height: ${menuLinkHeight}px;
  color: var(--textPrimary);
  font-size: var(--fontSizeRegular);
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--backgroundSecondary);
`;
