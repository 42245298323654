import {
  WEB_ROUTE_AUTH,
  WEB_ROUTE_MY_PROFILE,
} from '@shared_frontend/Common/WebRoutes';
import { LinkToRoute } from '@front_common/Router/Router';
import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';

type IProps = {
  authenticated: boolean;
};

export default function SignInProfileButton({ authenticated }: IProps) {
  return authenticated ? (
    <LinkToRoute href={WEB_ROUTE_MY_PROFILE} style={{ textDecoration: 'none' }}>
      <CustomProfileButton sizeParam="S" type="Primary">
        Profile
      </CustomProfileButton>
    </LinkToRoute>
  ) : (
    <LinkToRoute href={WEB_ROUTE_AUTH} style={{ textDecoration: 'none' }}>
      <CustomSignInButton sizeParam="S" type="Primary">
        Sign In
      </CustomSignInButton>
    </LinkToRoute>
  );
}

let CustomProfileButton = styled(Button)`
  width: 86px;
  background-color: var(--buttonPrimaryNew);
  &:hover {
    background-color: var(--black100) !important;
  }
`;

let CustomSignInButton = styled(Button)`
  width: 86px;
  background-color: var(--buttonPrimaryNew);

  &:hover {
    background-color: var(--black100) !important;
  }

  @media (min-width: 700px) {
    width: 108px;
  }
`;
