import styled from 'styled-components';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

interface CopyButtonProps {
  textToCopy: string;
  children?: React.ReactNode;
  onCopy: (() => void) | undefined;
}

export function CopyButton(props: CopyButtonProps) {
  let { textToCopy, onCopy, children } = props;

  function handleCopy() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => onCopy && onCopy())
        .catch((error) =>
          logger.error('Failed to copy:' + JSON.stringify(error)),
        );
    }
  }

  return <StyledButton onClick={handleCopy}>{children}</StyledButton>;
}

let StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: transparent;
`;
