export default function PlanetIcon() {
  return (
    <svg viewBox="0 0 32 32" width="32" height="32">
      <path
        d="M6.01855 16.6066C6.33212 21.8469 10.6804 26 15.9992 26C18.3715 26 20.5503 25.1733 22.2648 23.7931C19.8268 23.0971 17.1249 22.1201 14.3476 20.9136C11.2956 19.5878 8.43474 18.1048 6.01855 16.6066ZM6.71124 12.2893C9.20568 13.9837 12.4656 15.7349 15.9412 17.2447C18.6656 18.4284 21.3857 19.4044 23.8074 20.0675C24.2013 20.1755 24.5969 20.2772 24.9941 20.3727C25.0997 20.1557 25.1975 19.9349 25.2871 19.7108C25.7459 18.5634 25.9987 17.3112 25.9987 16C25.9987 15.7963 25.9919 15.5942 25.9799 15.3934C25.6662 10.1531 21.3181 6 15.9992 6C13.6269 6 11.4481 6.82669 9.73368 8.20694C8.59018 9.1275 7.65343 10.2943 7.0043 11.6273C6.89858 11.8443 6.80083 12.0651 6.71124 12.2893Z"
        fill="currentColor"
      />
      <path
        d="M26.8542 17.7832C26.7757 18.5058 26.7056 18.6349 26.486 19.3266C27.5408 20.0791 28.4255 20.8083 29.057 21.4478C29.2623 21.6558 29.4242 21.8368 29.5515 21.9916C29.4463 21.9969 29.3292 21.9999 29.1996 21.9999C27.8317 21.9999 25.823 21.6562 23.5433 21.0319C21.0766 20.3566 18.3102 19.3641 15.5428 18.1619C11.8596 16.5619 8.4079 14.6906 5.8234 12.8928C4.63084 12.0631 3.63421 11.2536 2.94134 10.5521C2.73603 10.3441 2.57415 10.1631 2.44684 10.0083C2.55203 10.003 2.66909 9.99994 2.79884 9.99994C3.75559 9.99994 5.02615 10.1683 6.48334 10.483C6.8474 9.85775 6.91953 9.68919 7.39978 9.14494C5.7914 8.5625 4.16403 8.15763 2.79896 8C1.27378 7.82388 0.277715 8.34507 0.0499648 9.07125C-0.64216 11.2786 5.93746 16.1699 14.746 19.9964C20.6561 22.5639 26.069 24 29.1996 24C30.7349 24 31.7207 23.6549 31.9485 22.9287C32.3447 21.6652 30.4353 20 26.8542 17.7832Z"
        fill="currentColor"
      />
    </svg>
  );
}
