//Errors codes https://docs.solidgate.com/payments/payments-insights/error-codes/
export const PAYMENT_ERROR_CODES_MAP: Record<string, string> = {
  '5.01':
    'We couldn’t complete the 3D Secure check. Please try again or contact your bank.',
  '2.12':
    'Something went wrong with the 3D Secure process. Please try again or use another card.',
  '4.09':
    'Our security checks prevented this payment from going through. Please use a different card or contact our support team for help.',
  '3.10':
    'This transaction was flagged as potentially fraudulent. Please try another payment method or contact your bank.',
  '3.02':
    'It appears there are not enough funds available on your card. Please check your balance or use a different payment method.',
  '3.08':
    'Your bank declined the payment. Please try another card or contact your bank for more information.',
  '2.06':
    'The code you entered from the back of your card (CVV/CVC) does not match. Please double-check and try again.',
  '2.08':
    'The card number you entered is not valid. Please double-check the number and try again.',
  '3.07':
    'We do not accept that type of card. Please try a different card or payment method.',
};
export const DEFAULT_PAYMENT_ERROR =
  'We were unable to process this payment. Please try another card or contact your bank for assistance.';
