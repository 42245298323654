import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  EsimPlanFlag,
  getEsimPlanCountry,
} from '@front_common/Components/EsimPlanName';
import Card from '@front_common/Components/Card';
import ESimCardBackgroundIcon from './Icons/ESimBackgroundIcon';
import ArrowRightIcon from './Icons/ArrowRightIcon';
import useESimPlan from '../useESimPlan';
import { useESimCardContext } from '../ESimContext';
import { logger } from '@shared_frontend/Common/BetterStackLogger';

interface IProps {
  setShowQrForId: Dispatch<SetStateAction<string | null>>;
  onCardClick: () => void;
}

function ESimCard({ setShowQrForId, onCardClick }: IProps) {
  let { eSim } = useESimCardContext();

  let {
    content: { status, cardContent },
    isActive,
  } = useESimPlan(setShowQrForId);

  if (!eSim.targetCountry) {
    // TODO process "null" better
    logger.log('no target country ', { eSim });
    return <>no target country</>;
  }

  return (
    <BundleStoreItem
      data-testid={`esim-item-${eSim.status}`}
      id={eSim.id.toString()} //needed for Hint
      $isActive={isActive}
      onClick={onCardClick}
    >
      <CardBackgroundIconWrapper $isActive={isActive}>
        <ESimCardBackgroundIcon />
      </CardBackgroundIconWrapper>
      <CardHeaderWrapper>
        <IconBox>
          <EsimPlanFlag targetCountry={eSim.targetCountry} />{' '}
        </IconBox>
        {status}
      </CardHeaderWrapper>
      <PackageDataInfoWrapper>
        <PackageDataInfo>
          {getEsimPlanCountry(eSim.targetCountry)}: {eSim.dataPurchasedGb}GB
        </PackageDataInfo>
        <CardOpenButton onClick={onCardClick}>
          <ArrowRightIcon />
        </CardOpenButton>
      </PackageDataInfoWrapper>
      {cardContent}
    </BundleStoreItem>
  );
}

export default ESimCard;

type ActiveItemProps = {
  $isActive: boolean;
};

export let BundleStoreItem = styled(Card)<ActiveItemProps>`
  position: relative;
  height: 150px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  background: ${({ $isActive }) =>
    $isActive
      ? 'linear-gradient(288.79deg, var(--orange70) 1.45%, var(--orange90) 97.97%)'
      : 'linear-gradient(288.95deg, #fbfbfb 15.15%, #dbdbdb 126.02%)'};

  span {
    display: inline-block;
    padding: 0;
  }

  @media (min-width: 700px) {
    width: 414px;
  }
`;

export let CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export let PackageDataInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;

export let PackageDataInfo = styled.span`
  line-height: 22px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 350px;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightSemiBold);
`;

export let CardOpenButton = styled.button`
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  background-color: var(--buttonPrimary);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: transparent;
  cursor: pointer;
`;

export let CardBackgroundIconWrapper = styled.div<ActiveItemProps>`
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;

  svg {
    fill: ${({ $isActive }) =>
      $isActive ? 'var(--orange100)' : 'var(--grey80)'};
  }
`;

export let IconBox = styled.div`
  display: inline-flex;
  margin-right: 15px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid var(--backgroundSecondary);

  img {
    height: 22px;
  }
`;
