import Button from '@front_common/Components/Button/Button';
import styled from 'styled-components';
import MarkerIcon from './Icons/MarkerIcon';
import SaleIcon from './Icons/SaleIcon';
import fireImage from './Images/Fire.png';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';
import WalletIcon from './Icons/WalletIcon';

type IProps = {
  amountGb: number;
  priceLine: string;
  pricePerGb: string;
  discount: number;
  monthPriceDiscount?: string | undefined;
  highlighted?: boolean;
  selected?: boolean;
  onCountriesClick: () => void;
  onPlanDetailsClick: () => void;
  onClick: () => void;
  onGoToCheckoutClick?: () => void;
};

export default function PlanItem(props: IProps) {
  let {
    amountGb,
    priceLine,
    pricePerGb,
    discount,
    monthPriceDiscount,
    highlighted,
    selected,
    onCountriesClick,
    onPlanDetailsClick,
    onClick,
    onGoToCheckoutClick,
  } = props;

  let windowDimensions = useWindowDimensions();

  if (windowDimensions.width >= 960) {
    return (
      <PopularWrapper>
        {highlighted ? (
          <HighlightedHeader>
            <span>Most popular</span>
            <img src={fireImage} alt="fire" />
          </HighlightedHeader>
        ) : null}
        <PlanItemWrapperDesktop $highlighted={highlighted}>
          <PaddingWrapperDesktop>
            <TopDesktop>
              <AmountPriceDesktop>
                <AmountDesktop>{amountGb}GB</AmountDesktop>
                <PricePerGbDesktop>
                  {monthPriceDiscount && (
                    <MonthPriceDiscountDesktop>
                      ${monthPriceDiscount}
                    </MonthPriceDiscountDesktop>
                  )}
                  <PricePerGbValueDesktop>${pricePerGb}</PricePerGbValueDesktop>
                  <PricePerGbTextDesktop>per GB</PricePerGbTextDesktop>
                </PricePerGbDesktop>
              </AmountPriceDesktop>
              <Button
                sizeParam="L"
                typeStyle={highlighted ? 'Primary' : 'Secondary'}
                onClick={onGoToCheckoutClick}
              >
                Go to checkout
              </Button>
              <InfoDesktop>
                <InfoLineDesktop>
                  <WalletIcon />
                  <InfoTextDesktop>{priceLine}</InfoTextDesktop>
                </InfoLineDesktop>
                <InfoLineDesktop>
                  <MarkerIcon />
                  <InfoTextDesktop>
                    High-speed coverage in{' '}
                    <CountriesButton onClick={onCountriesClick}>
                      112 countries
                    </CountriesButton>
                  </InfoTextDesktop>
                </InfoLineDesktop>
                <InfoLineDesktop>
                  <SaleIcon />
                  <InfoTextDesktop>
                    {discount}% cheaper than buying individual plans{' '}
                  </InfoTextDesktop>
                </InfoLineDesktop>
              </InfoDesktop>
            </TopDesktop>
            <StyledDetailsButton
              typeStyle="Transparent"
              sizeParam="S"
              onClick={onPlanDetailsClick}
            >
              Plan details
            </StyledDetailsButton>
          </PaddingWrapperDesktop>
        </PlanItemWrapperDesktop>
      </PopularWrapper>
    );
  }

  return (
    <PopularWrapper>
      {highlighted ? (
        <HighlightedHeader>
          <span>Most popular</span>
          <img src={fireImage} alt="fire" />
        </HighlightedHeader>
      ) : null}
      <PlanItemWrapper
        $highlighted={highlighted}
        $selected={selected}
        onClick={onClick}
      >
        <PaddingWrapper>
          <FirstLine>
            <AmountPrice>
              <Amount>{amountGb}GB</Amount>
              <PriceLine>{priceLine}</PriceLine>
            </AmountPrice>
            <Button
              typeStyle="Secondary"
              sizeParam="S"
              onClick={onPlanDetailsClick}
            >
              Plan details
            </Button>
          </FirstLine>
          <Divider />
          <SecondLine>
            <PricePerGb>
              {monthPriceDiscount && (
                <MonthPriceDiscount>${monthPriceDiscount}</MonthPriceDiscount>
              )}
              <PricePerGbValue>${pricePerGb}</PricePerGbValue>
              <PricePerGbText>per GB</PricePerGbText>
            </PricePerGb>
            <Info>
              <InfoLine>
                <MarkerIcon />
                <InfoText>
                  High-speed coverage in{' '}
                  <CountriesButton onClick={onCountriesClick}>
                    112 countries
                  </CountriesButton>
                </InfoText>
              </InfoLine>
              <InfoLine>
                <SaleIcon />
                <InfoText>
                  {discount}% cheaper than buying individual plans{' '}
                </InfoText>
              </InfoLine>
            </Info>
          </SecondLine>
        </PaddingWrapper>
      </PlanItemWrapper>
    </PopularWrapper>
  );
}

let PopularWrapper = styled.div`
  position: relative;
`;

let PlanItemWrapper = styled.div<{
  $selected: boolean | undefined;
  $highlighted: boolean | undefined;
}>`
  background: var(--backgroundSecondary);
  border-radius: var(--borderRadiusLarge);
  border: 1px solid
    ${(props) =>
      props.$selected ? 'var(--strokeBrand)' : 'var(--strokeSecondary)'};
  overflow: hidden;
  padding-top: ${(props) => (props.$highlighted ? '28px' : '0')};
`;

let PaddingWrapper = styled.div`
  padding: 16px;
`;

let HighlightedHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: var(--itemBrand);
  color: var(--textPrimaryInvert);
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeSmall);
  line-height: 20px;
  height: 28px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
  }
`;

let FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  height: 58px;
`;

let AmountPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let Amount = styled.div`
  font-weight: var(--fontWeightBold);
  line-height: 22px;
  text-align: left;
`;

let PriceLine = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

let Divider = styled.div`
  height: 1px;
  background-color: var(--strokeSecondary);
  margin-top: 12px;
  margin-bottom: 4px;
`;

let SecondLine = styled.div`
  display: flex;
  gap: 4px;

  @media (min-width: 393px) {
    align-items: flex-end;
  }
`;

let MonthPriceDiscount = styled.div`
  height: 16px;
  font-size: 12px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  text-align: left;
  text-decoration-line: line-through;
  color: var(--textSecondary);
`;

let PricePerGb = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 65px;
  margin-top: 8px;

  @media (min-width: 393px) {
    margin-top: initial;
  }
`;

let PricePerGbValue = styled.div`
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  text-align: left;
`;

let PricePerGbText = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  text-decoration-skip-ink: none;
`;

let Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  min-height: 77px;

  @media (min-width: 393px) {
    min-height: 58px;
    justify-content: flex-end;
  }
`;

let InfoLine = styled.div`
  display: flex;
  gap: 4px;

  svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
`;

let InfoText = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

let CountriesButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--buttonPrimary);
  font-weight: var(--fontWeightBold);
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;

// Desktop styles
let PlanItemWrapperDesktop = styled.div<{
  $highlighted: boolean | undefined;
}>`
  background: var(--backgroundSecondary);
  border-radius: var(--borderRadiusLarge);
  border: 1px solid var(--strokeSecondary);
  overflow: hidden;
  padding-top: ${(props) => (props.$highlighted ? '28px' : '0')};
  width: 305px;

  @media (min-width: 1440px) {
    width: initial;
  }
`;

let PaddingWrapperDesktop = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 332px;
  gap: 12px;
`;

let TopDesktop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let AmountPriceDesktop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

let AmountDesktop = styled.div`
  font-weight: var(--fontWeightBold);
  line-height: 22px;
`;

let PricePerGbDesktop = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
`;

let MonthPriceDiscountDesktop = styled.div`
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  line-height: 32px;
  text-decoration-line: line-through;
  color: var(--textSecondary);
  margin-right: 4px;
`;

let PricePerGbValueDesktop = styled.div`
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
`;

let PricePerGbTextDesktop = styled.div`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let InfoDesktop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let InfoLineDesktop = styled.div`
  display: flex;
  gap: 4px;

  svg {
    min-width: 20px;
  }
`;

let InfoTextDesktop = styled.div`
  font-size: var(--fontSizeSmall);
  line-height: 20px;
`;

let StyledDetailsButton = styled(Button)`
  height: 22px;
  width: fit-content;
  margin: 0 auto;
`;
