import RocketIcon from './Icons/RocketOrangeSVG';
import NetworkIcon from './Icons/NetworkSVG';
import EarningsIcon from './Icons/EarningsSVG';
import WiFIAltIcon from './Icons/WiFIAltSVG';
import CheckListIcon from './Icons/CheckListSVG';
import Card from '@front_common/Components/Card';
import styled from 'styled-components';

type IProps = {
  validDays: number;
};

export default function PlanDetails({ validDays }: IProps) {
  return (
    <>
      <BulletPointsWrapper>
        <ItemsSection>
          <Bullet>
            <IconBox>
              <RocketIcon />
            </IconBox>
            <BulletFirstRow>
              <ItemTitle>️Fast and reliable connection:</ItemTitle>
              <ItemDescription>We never slow down the speed.</ItemDescription>
            </BulletFirstRow>
          </Bullet>
          <Bullet>
            <IconBox>
              <NetworkIcon />
            </IconBox>

            <BulletFirstRow>
              <ItemTitle>Stay connected:</ItemTitle>
              <ItemDescription>
                Instant access to all apps like Maps, Youtube, Spotify, WhatsApp
                & more.
              </ItemDescription>
            </BulletFirstRow>
          </Bullet>
        </ItemsSection>
        <ItemsSection>
          <Bullet>
            <IconBox>
              <EarningsIcon />
            </IconBox>

            <BulletFirstRow>
              <ItemTitle>Cheap travel rates:</ItemTitle>
              <ItemDescription>
                Access cost-effective data plans and reduced roaming charges
                globally.
              </ItemDescription>
            </BulletFirstRow>
          </Bullet>
          <Bullet>
            <IconBox>
              <WiFIAltIcon />
            </IconBox>

            <BulletFirstRow>
              <ItemTitle>Hotspot Enabled:</ItemTitle>
              <ItemDescription>
                Share your connection with other devices.
              </ItemDescription>
            </BulletFirstRow>
          </Bullet>
        </ItemsSection>
      </BulletPointsWrapper>
      <CheckedList>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>Valid for {validDays} days</TextBold> from activation.
          </span>
        </CheckedItem>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>Data only.</TextBold> eSIM does not come with a phone
            number.
          </span>
        </CheckedItem>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>The speed</TextBold> may vary depending on your location.
          </span>
        </CheckedItem>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>Immediate delivery</TextBold> after purchase.
          </span>
        </CheckedItem>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>Activate any time</TextBold> within 365 days of purchase.
          </span>
        </CheckedItem>
        <CheckedItem>
          <CheckedIconBox>
            <CheckListIcon />
          </CheckedIconBox>
          <span>
            <TextBold>Cancel any time</TextBold> before activation with no data
            usage for a full refund.
          </span>
        </CheckedItem>
      </CheckedList>
    </>
  );
}

let BulletPointsWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 0;
  border: none;
  row-gap: 24px;
  background-color: var(--backgroundPrimaryDarkNew);
  color: var(--textPrimaryInvert);
  font-family: var(--fontFamilyNew);

  @media (min-width: 700px) {
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    margin: 0 32px;
    border-radius: 16px;
  }

  @media (min-width: 960px) {
    margin: 0 auto;
    width: 100%;
    max-width: 1040px;
  }
`;

let ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

let Bullet = styled.div`
  display: flex;
  text-align: initial;

  @media (min-width: 700px) {
    flex-direction: column;
    padding-bottom: 0;
    position: relative;
    row-gap: 24px;
    width: 50%;
    &:nth-child(even) {
      margin-left: 24px;
    }
  }
`;

let IconBox = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`;

let BulletFirstRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
  row-gap: 8px;
`;

let ItemTitle = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
`;

let ItemDescription = styled.span`
  line-height: 22px;
  color: var(--textSecondaryLightNew);
  font-size: var(--fontSizeRegular);
`;

let CheckedList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  list-style: none;
  row-gap: 16px;

  @media (min-width: 700px) {
    padding: 16px 32px 0 32px;
  }

  @media (min-width: 960px) {
    padding: 32px 0 0 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1040px;
  }
`;

let CheckedItem = styled.li`
  display: flex;
  align-items: center;
`;

let CheckedIconBox = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

let TextBold = styled.b`
  font-weight: var(--fontWeightSemiBold);
`;
