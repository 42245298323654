import { useEffect, useState } from 'react';
import UserApi from '@front_common/Api/UserApi';
import WorldImage from '../../Images/World.png';
import CheckCircleBrokenIcon from '../../Icons/CheckCircleBroken';
import AuthForm from '../../../Auth/AuthForm/OldDesign/AuthForm';
import styled from 'styled-components';
import { SubProductType } from '@shared_backend/Module/Subscription/SubProductResponse';

type Props = {
  subscriptionPeriod: string;
  authenticated: boolean;
  subscription: SubProductType;
  onAuthSuccess: () => void;
};

export default function SubscriptionAccessControl({
  subscription,
  authenticated,
  subscriptionPeriod,
  onAuthSuccess,
}: Props) {
  let [email, setEmail] = useState('');
  let [authenticationFormTitle, setAuthenticationFormTitle] = useState('');

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let user = await UserApi.getUser();

        if (user) {
          setEmail(user.email);
        }
      }
    })();
  }, [authenticated]);

  function handleShowOTPChange(showOTP: boolean) {
    if (showOTP) {
      setAuthenticationFormTitle('Enter the verification code');
    } else {
      setAuthenticationFormTitle('');
    }
  }

  return (
    <SubscriptionCard>
      <SubscriptionInfoWrapper>
        <SubscriptionInfo>
          <img src={WorldImage} alt="global image" />
          <ColumnBox>
            <SubscriptionTitle>{`Global ${subscription.dataGb}GB`}</SubscriptionTitle>
            <SubscriptionPeriod>{`Billed ${subscriptionPeriod}ly`}</SubscriptionPeriod>
          </ColumnBox>
        </SubscriptionInfo>
        <SubscriptionPrice>${subscription.priceCents / 100}</SubscriptionPrice>
      </SubscriptionInfoWrapper>
      <UserInfo>
        {authenticated ? (
          <UserEmail>
            <span>{email}</span>
            <CheckCircleBrokenIcon />
          </UserEmail>
        ) : (
          <AuthFormWrapper>
            {authenticationFormTitle && (
              <AuthTitle>{authenticationFormTitle}</AuthTitle>
            )}
            <AuthForm
              onAuthSuccess={onAuthSuccess}
              onShowOTPChange={handleShowOTPChange}
            />
          </AuthFormWrapper>
        )}
      </UserInfo>
    </SubscriptionCard>
  );
}

let SubscriptionCard = styled.div`
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--strokeSecondary);
  background-color: var(--backgroundSecondary);
`;

let SubscriptionInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--strokeSecondary);
`;

let SubscriptionInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

let SubscriptionPrice = styled.span`
  line-height: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightBold);
`;

let ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

let SubscriptionTitle = styled.span`
  line-height: 22px;
  font-weight: var(--fontWeightBold);
`;

let SubscriptionPeriod = styled.span`
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
`;

let UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  svg {
    margin-left: 8px;
  }
  span {
    display: inline-flex;
    align-items: center;
  }
`;

let UserEmail = styled.span`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  span {
    display: block;
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

let AuthFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 8px;
`;

let AuthTitle = styled.h4`
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontFamilyHeader);
`;
