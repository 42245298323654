import { useEffect, useState } from 'preact/hooks';
import AuthService from '@front_common/Api/AuthService';
import Card from '@front_common/Components/Card';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import Summary from './Summary/Summary';
// import { Discount } from './Discount/Discount';
import { TotalPriceToPay } from './TotalPriceToPay';
import AuthenticationUserInfo from './UserInfo';
import PaymentForm from './PaymentForm';
import MoneyBackGuarantee from './MoneyBackGuarantee/MoneyBackGuarantee';
import PaymentSecured from './PaymentSecured/PaymentSecured';
import SuccessModal from '../../SuccessModal/SuccessModal';
import PaymentError from './PaymentError';
import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import ExistedSubscriptionModalContent from '../../EsixtedSubscriptionModalContent';
import { usePaymentContext } from '../PaymentContext';
import PaymentBlurred from '../PaymentBlured';
import styled from 'styled-components';

const EXISTED_SUBSCRIPTION_ERROR_MESSAGE =
  'Only one subscription per user allowed';

// eslint-disable-next-line max-lines-per-function
export default function CheckoutPageContent() {
  let [authenticated, setAuthenticated] = useState(AuthService.isLoggedIn());
  let [showSuccessModal, setShowSuccessModal] = useState(false);
  let [isExistedSubscriptionModalOpen, setIsExistedSubscriptionModalOpen] =
    useState(false);

  let {
    subscription,
    paymentSession,
    setPaymentSession,
    setPaymentSessionError,
    setPaymentError,
    paymentError,
    paymentSessionError,
    // resetPaymentSession,
    amountCents: {
      // sessionAmountCents,
      subscriptionAmountCents,
    },
  } = usePaymentContext();

  if (!subscription) {
    return <LoadingAnimation />;
  }

  useEffect(() => {
    if (
      authenticated &&
      paymentSessionError === EXISTED_SUBSCRIPTION_ERROR_MESSAGE
    ) {
      setIsExistedSubscriptionModalOpen(true);
    }
  }, [authenticated, paymentSessionError]);

  function onAuthSuccess() {
    setAuthenticated(true);
  }

  function onPaymentSuccess() {
    setShowSuccessModal(true);
  }

  return (
    <PaymentWrapperStyled>
      {paymentError && <PaymentError />}
      <DesktopContentWrapper>
        <FirstColumnBox>
          {/* TODO: subscriptionPeriod - better to pass the original value and the
        SubscriptionAccessControl should handle the conversion. May be disscussed */}
          <Summary
            authenticated={authenticated}
            totalAmountCents={subscriptionAmountCents}
            dataAmountGb={subscription.dataGb}
            subscriptionPeriod={
              subscription.billingPeriod === 1 ? 'month' : 'year'
            }
          >
            <>
              {authenticated && (
                <>
                  {/*<Discount*/}
                  {/*  paymentError={paymentError}*/}
                  {/*  sessionTotalAmountCents={sessionAmountCents}*/}
                  {/*  subscriptionTotalAmountCents={subscriptionAmountCents}*/}
                  {/*  onDiscountRequestSuccess={setPaymentSession}*/}
                  {/*  onDiscountRemoveClick={resetPaymentSession}*/}
                  {/*/>*/}
                  {paymentSession && (
                    <TotalPriceToPay
                      sessionAmountCents={paymentSession.totalAmountCents}
                    />
                  )}
                  <Divider />
                </>
              )}
              <AuthenticationUserInfo
                authenticated={authenticated}
                onAuthSuccess={onAuthSuccess}
              />
            </>
          </Summary>
        </FirstColumnBox>
        <SecondColumnBox>
          {authenticated ? (
            <PaymentForm
              paymentSubId={subscription.id}
              paymentSession={paymentSession}
              onSessionCreate={setPaymentSession}
              onSessionError={setPaymentSessionError}
              onPaymentError={setPaymentError}
              onPaymentSuccess={onPaymentSuccess}
              billingPeriod={
                subscription.billingPeriod === 1 ? 'month' : 'year'
              }
            />
          ) : (
            <PaymentBlurred />
          )}
        </SecondColumnBox>
        <FirstColumnBox>
          <MoneyBackGuarantee />
        </FirstColumnBox>
      </DesktopContentWrapper>
      <PaymentSecuredWrapper>
        <PaymentSecured />
      </PaymentSecuredWrapper>
      {paymentSession && (
        <SuccessModal
          show={showSuccessModal}
          paymentSessionId={paymentSession.id}
        />
      )}
      {paymentSessionError === 'Only one subscription per user allowed' && (
        <Modal
          analyticType="subscription-exist"
          isOpen={isExistedSubscriptionModalOpen}
          onClose={() => setIsExistedSubscriptionModalOpen(false)}
          overlayClick={false}
          closeButtonPrimary={false}
          hideCloseButton
        >
          <ExistedSubscriptionModalContent showGoToSubButton />
        </Modal>
      )}
    </PaymentWrapperStyled>
  );
}

let PaymentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 700px) {
    max-width: 494px;
    margin: 0 auto;
  }

  @media (min-width: 960px) {
    max-width: 1064px;
    margin: 0 auto;
  }
`;

let FirstColumnBox = styled.div`
  @media (min-width: 960px) {
    max-width: 522px;

    &:nth-child(-n + 3) {
      grid-column: 1;
    }
  }
`;

let SecondColumnBox = styled.div`
  @media (min-width: 960px) {
    max-width: 522px;
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`;

let DesktopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    > *:not(${SecondColumnBox}) {
      grid-column: 1;
    }
  }
`;

let Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--strokeSecondary);
`;

let PaymentSecuredWrapper = styled(Card)``;
