export default function QuestionIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.2266 35C69.2266 53.9028 53.9028 69.2266 35 69.2266C16.0972 69.2266 0.773438 53.9028 0.773438 35C0.773438 16.0972 16.0972 0.773438 35 0.773438C53.9028 0.773438 69.2266 16.0972 69.2266 35Z"
        fill="#C3DBFF"
        stroke="white"
      />
      <path
        d="M36.2715 12.2363C24.4043 12.2363 14.7793 21.8613 14.7793 33.7285C14.7793 37.4473 15.7227 40.9473 17.3906 43.9961L14.7793 55.2207L26.0039 52.6094C29.0527 54.2773 32.5527 55.2207 36.2715 55.2207C48.1387 55.2207 57.7637 45.5957 57.7637 33.7285C57.7637 21.8613 48.1387 12.2363 36.2715 12.2363Z"
        fill="white"
      />
      <path
        d="M37.4062 38.5957H33.4004V36.4355C33.4004 35.4922 33.5098 34.7812 33.7422 34.3301C33.9609 33.8652 34.4531 33.2637 35.2188 32.5117L37.8027 29.9277C38.3496 29.3398 38.623 28.6016 38.623 27.7266C38.623 26.8516 38.3359 26.127 37.7754 25.5527C37.2012 24.9785 36.4766 24.6777 35.5742 24.6777C34.6719 24.6777 33.9336 24.9512 33.3184 25.5117C32.7168 26.0723 32.3613 26.8105 32.2656 27.7266H27.9727C28.2051 25.6211 29.0254 23.9805 30.4336 22.791C31.8418 21.6016 33.5918 21 35.6973 21C37.8027 21 39.5117 21.5742 40.8379 22.7227C42.1641 23.8711 42.8203 25.4707 42.8203 27.5215C42.8203 28.9434 42.4375 30.1191 41.6582 31.0625C41.207 31.623 40.8652 32.0332 40.6328 32.2656C40.4004 32.498 40.0859 32.7988 39.6895 33.168C39.293 33.5371 38.9648 33.8516 38.6914 34.125C38.418 34.3984 38.1992 34.6309 38.0352 34.8223C37.6113 35.3418 37.3926 36.0801 37.3926 37.0234V38.5957H37.4062ZM35.4785 46.4707C34.7402 46.4707 34.0977 46.2109 33.5508 45.7051C33.0039 45.1992 32.7305 44.584 32.7305 43.8594C32.7305 43.1348 32.9902 42.5195 33.5234 42C34.0566 41.4805 34.6992 41.2207 35.4375 41.2207C36.1758 41.2207 36.8184 41.4805 37.3652 41.9863C37.9121 42.4922 38.1719 43.1074 38.1719 43.832C38.1719 44.5566 37.9121 45.1719 37.3789 45.6914C36.8594 46.2109 36.2168 46.4707 35.4785 46.4707Z"
        fill="#F4AD6E"
      />
    </svg>
  );
}
