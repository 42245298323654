import PageTitleText from '@front_common/Components/PageTitleText';
import styled from 'styled-components';
import RightIcon from '../Icons/RightIcon';
import GearsBackground from '../Images/GearsBackground.svg';
import useWindowDimensions from '@shared_frontend/Common/WindowDimensions';
import InstallGuideBody from '../InstallGuideBody';
import PopularCountries from '../PopularCountries';
import FAQ from '../FAQ';
import ExplorePlans from '../ExplorePlans';
import DownloadApp from '@front_common/Components/DownloadApp/OldDesign/DownloadApp';

export default function HowToInstallEsimPage() {
  let windowDimensions = useWindowDimensions();

  return (
    <div>
      <Header>
        <BackButtonWrapper>
          <BackButton onClick={() => history.back()}>
            <RightIcon />
          </BackButton>
        </BackButtonWrapper>
        <Background>
          <BackgroundWrapper>
            <img src={GearsBackground} alt="gears background" />
          </BackgroundWrapper>
        </Background>
        <Title data-testid="how-to-install-esim-page-title">
          {windowDimensions.width < 700 ? (
            <PageTitleText>{`How to install
                an eSIM`}</PageTitleText>
          ) : (
            <PageTitleText>How to install an eSIM</PageTitleText>
          )}
        </Title>
      </Header>
      <InstallGuideBody forGeneralPage />
      <PaddingWrapper>
        <AdditionalBlock>
          <PopularCountries />
          <FAQ />
          <ExplorePlans />
          <DownloadApp />
        </AdditionalBlock>
      </PaddingWrapper>
    </div>
  );
}

let PaddingWrapper = styled.div`
  padding: 0 16px;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1006px;
    margin: 0 auto;
  }
`;

let Header = styled.div`
  position: relative;
  margin-bottom: 32px;
`;

let Background = styled.div`
  width: 100%;
`;

let BackgroundWrapper = styled.div`
  height: 172px;
  overflow: hidden;
  background-color: var(--cardsBackgroundOrange);
  position: relative;

  img {
    position: absolute;
    left: 37%;
    top: 51%;
    transform: translate(-50%, -50%);
    height: 318px;
    width: 640px;
  }

  @media (min-width: 393px) {
    img {
      left: 40%;
    }
  }

  @media (min-width: 700px) {
    height: 156px;

    img {
      height: 293px;
      width: 585px;
      left: 50%;
      top: 50%;
    }
  }

  @media (min-width: 960px) {
    height: 188px;

    img {
      height: 347px;
      width: 697px;
    }
  }
`;

let BackButtonWrapper = styled.div`
  height: 64px;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  left: 0;
  top: 0;

  @media (min-width: 700px) {
    padding: 0 40px;
  }

  @media (min-width: 960px) {
    padding: 0 80px;
    max-width: 1440px;
    left: 50%;
    transform: translate(-50%);
  }
`;

let BackButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: transparent;
  border: none;

  svg {
    color: var(--iconsPrimaryInvert);
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
  }
`;

let Title = styled.h1`
  position: absolute;
  left: 0;
  top: 64px;
  padding: 0 16px;
  width: 100%;
  font-family: var(--fontFamilyHeader);
  font-weight: var(--fontWeightBold);
  font-size: 34px;
  line-height: 40px;
  white-space: pre-line;
  text-align: center;

  @media (min-width: 700px) {
    top: 76px;
    font-size: 36px;
    line-height: 48px;
  }

  @media (min-width: 960px) {
    top: 84px;
    font-size: 52px;
    line-height: 64px;
  }
`;

let AdditionalBlock = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 700px) {
    margin-top: 40px;
    gap: 40px;
  }

  @media (min-width: 960px) {
    margin-top: 56px;
    gap: 56px;
  }
`;
