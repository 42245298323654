import AbstractApi from './AbstractApi';
import {
  API_ROUTE_AUTH_IS_REGISTERED,
  API_ROUTE_AUTH_LOGIN_EMAIL_CODE,
  API_ROUTE_AUTH_RESEND_EMAIL_CODE,
  API_ROUTE_AUTH_SEND_EMAIL_CODE,
  API_ROUTE_AUTH_SIGNUP,
  API_ROUTE_AUTH_SIGNUP_RESEND_EMAIL_CODE,
  API_ROUTE_AUTH_SIGNUP_SEND_EMAIL_CODE,
  API_ROUTE_BUNDLE,
  API_ROUTE_BUNDLE_BY_CODE,
  API_ROUTE_COMPATIBLE_DEVICES,
  API_ROUTE_COUNTRY_STORE,
  API_ROUTE_GEO_ACTIVE_COUNTRIES,
  API_ROUTE_GET_PAYMENT_STATUS,
  API_ROUTE_PRICE,
  API_ROUTE_SESSION,
  API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN,
  API_ROUTE_SUB_PRODUCTS,
  API_ROUTE_GET_SUB_PAYMENT_STATUS,
  API_ROUTE_REVIEWS_PER_COUNTRY,
  API_ROUTE_AUTH_LOGIN_GOOGLE,
  API_ROUTE_GEO_COUNTRY_SEARCH,
  API_ROUTE_SUB_ESIM_OPERATORS,
} from '@shared_backend/ApiRoutes';
import BundleResponse from '@shared_backend/Module/Bundle/BundleResponse';
import AuthService from '@front_common/Api/AuthService';
import SignupRequest from '@shared_backend/Module/Auth/SignupRequest';
import { fillRouteParams } from '@shared_backend/Module/Common/Router';
import LoginTokenResponse from '@shared_backend/Module/Auth/LoginTokenResponse';
import StoreResponse from '@shared_backend/Module/Store/StoreResponse';
import BooleanResponse from '@shared_backend/Module/Common/BooleanResponse';
import SessionRequest from '@shared_backend/Module/Session/SessionRequest';
import { routeHardRedirectWithReload } from '@front_common/Router/Router';
import ActiveCountriesResponse from '@shared_backend/Module/Geo/ActiveCountriesResponse';
import PriceResponse from '@shared_backend/Module/Price/PriceResponse';
import { PricePublicType } from '@shared_backend/Module/Price/PricePublicType';
import { BundleEntityType } from '@shared_backend/Module/Bundle/BundleEntityType';
import PaymentResponse from '@shared_backend/Module/Payment/PaymentResponse';
import CompatibleDeviceResponse from '@shared_backend/Module/CompatibleDevice/CompatibleDeviceResponse';
import { setReminderClear } from '../../Module/InstallFlow/ReminderModal';
import SessionResponse from '@shared_backend/Module/Session/SessionResponse';
import { asyncHook } from '@shared_frontend/Common/HookFactory';
import { AdEvents } from '@front_common/Ads/AdEvents';
import SubProductResponse from '@shared_backend/Module/Subscription/SubProductResponse';
import ReviewsResponse from '@shared_backend/Module/Review/ReviewResponse';
import SubEsimOperatorResponse from '@shared_backend/Module/Subscription/SubEsimOperatorResponse';

export default class VisitorApi extends AbstractApi {
  protected static async request<T>(method: string, options: RequestInit) {
    this.setHeaderForAll(
      'X-Visitor-Token',
      await AuthService.getVisitorToken(),
    );
    return AbstractApi.request<T>(method, options);
  }

  static async signup(email: string, emailMarketingConsent: boolean) {
    let data: SignupRequest = {
      email,
      mirror: 1,
      visitorId: await AuthService.getVisitorToken(),
      emailMarketingConsent,
    };

    let response = await this.post<LoginTokenResponse>(
      API_ROUTE_AUTH_SIGNUP,
      data,
    );

    AuthService.storeLoginToken(response.login.userId, response.login.token);
    await AdEvents.trackReg(response.login.userId);
  }

  static async authByEmailToken(emailToken: string, redirectTo: string) {
    let response = await this.post<LoginTokenResponse>(
      API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN,
      {
        token: emailToken,
      },
    );
    setReminderClear();
    AuthService.storeLoginToken(response.login.userId, response.login.token);
    routeHardRedirectWithReload(redirectTo);
  }

  static async authByGoogle(token: string, marketingConsent: boolean) {
    let response = await this.post<LoginTokenResponse>(
      API_ROUTE_AUTH_LOGIN_GOOGLE,
      {
        token,
        mirror: 1,
        marketingConsent,
      },
    );
    AuthService.storeLoginToken(response.login.userId, response.login.token);
    //TODO: Do I need this line below that is commented? ask backend to return something if yes (waiting)

    // await AdEvents.trackReg(response.login.userId);
  }

  static async authSendEmailCode(email: string): Promise<boolean> {
    return this.post(API_ROUTE_AUTH_SEND_EMAIL_CODE, { email });
  }

  static async authSignupSendEmailCode(
    email: string,
    emailMarketingConsent: boolean,
  ): Promise<boolean> {
    return this.post(API_ROUTE_AUTH_SIGNUP_SEND_EMAIL_CODE, {
      email,
      emailMarketingConsent,
    });
  }

  static async authResendEmailCode(email: string): Promise<boolean> {
    return this.post(API_ROUTE_AUTH_RESEND_EMAIL_CODE, { email });
  }

  static async authSignupResendEmailCode(email: string): Promise<boolean> {
    return this.post(API_ROUTE_AUTH_SIGNUP_RESEND_EMAIL_CODE, { email });
  }

  static async authByEmailCode(email: string, code: number) {
    let response = await this.post<LoginTokenResponse>(
      API_ROUTE_AUTH_LOGIN_EMAIL_CODE,
      { email, code },
    );

    AuthService.storeLoginToken(response.login.userId, response.login.token);
  }

  static async sendSessionData(data: SessionRequest): Promise<SessionResponse> {
    return this.post(API_ROUTE_SESSION, data);
  }

  static async getStoreForCountry(countryCode: string) {
    let response: StoreResponse = await this.get(
      fillRouteParams(API_ROUTE_COUNTRY_STORE, { country: countryCode }),
      300,
    );
    return response.store;
  }

  static async getActiveCountries(): Promise<{ [code: string]: string }> {
    let response: ActiveCountriesResponse = await this.get(
      API_ROUTE_GEO_ACTIVE_COUNTRIES,
      3600,
    );

    return response.countries;
  }

  static async getPaymentSessionStatus(
    paymentSessionId: number,
    userId: number,
  ) {
    let response: PaymentResponse = await this.get(
      fillRouteParams(API_ROUTE_GET_PAYMENT_STATUS, { paymentSessionId }) +
        `?user_id=${userId}`,
    );
    return response.paymentSession;
  }

  static async getBundleByShortCode(
    bundleShortCode: string,
  ): Promise<BundleEntityType> {
    if (bundleShortCode.length !== 10) {
      throw new Error(`Wrong bundle shortCode: "${bundleShortCode}"`);
    }
    let response = await this.get<BundleResponse>(
      fillRouteParams(API_ROUTE_BUNDLE, { bundleShortCode }),
      300,
    );
    return response.bundle;
  }

  static async getBundleByFullCode(
    bundleFullCode: string,
  ): Promise<BundleEntityType> {
    let response = await this.get<BundleResponse>(
      fillRouteParams(API_ROUTE_BUNDLE_BY_CODE, { bundleFullCode }),
    );
    return response.bundle;
  }

  static async getPrice(priceId: number | string): Promise<PricePublicType> {
    let response = await this.get<PriceResponse>(
      fillRouteParams(API_ROUTE_PRICE, { priceId }),
      300,
    );
    return response.price;
  }

  static async getCompatibleDevices() {
    let response: CompatibleDeviceResponse = await this.get(
      API_ROUTE_COMPATIBLE_DEVICES,
      3600,
    );
    return response.devices;
  }

  static async getIsUserRegistered(email: string) {
    let response: BooleanResponse = await this.get(
      `${API_ROUTE_AUTH_IS_REGISTERED}?email=${encodeURIComponent(email)}`,
    );
    return response.result;
  }

  static async getSubscriptionProducts() {
    let response: SubProductResponse = await this.get(API_ROUTE_SUB_PRODUCTS);
    return response.subProducts;
  }

  static async getSubscriptionPaymentStatus(
    paymentSessionId: number,
    userId: number,
  ) {
    let response: PaymentResponse = await this.get(
      fillRouteParams(API_ROUTE_GET_SUB_PAYMENT_STATUS, {
        paymentSessionId,
      }) + `?user_id=${userId}`,
    );
    return response.paymentSession;
  }

  static async getReviewsPerCountry(countryId: string) {
    let response: ReviewsResponse = await this.get(
      fillRouteParams(API_ROUTE_REVIEWS_PER_COUNTRY, {
        countryCode: countryId,
      }),
      3600,
    );
    return response.reviews;
  }
  static async getCountriesByQuerySearch(query: string) {
    //TODO: need to find the correct type for the response
    let response: string[] = await this.get(
      `${API_ROUTE_GEO_COUNTRY_SEARCH}${query ? `/?q=${query}` : ''}`,
      3600,
    );
    return response;
  }
  static async getSubsCountriesOperators() {
    let response: SubEsimOperatorResponse = await this.get(
      API_ROUTE_SUB_ESIM_OPERATORS,
      3600,
    );
    return response.countryOperators;
  }
}

//TODO: refactor for displaying required params when hook is used
export const useStoreForCountryQuery = asyncHook((countryCode: string) =>
  VisitorApi.getStoreForCountry(countryCode),
);
export const useBundleByShortCodeQuery = asyncHook((shortCode: string) =>
  VisitorApi.getBundleByShortCode(shortCode),
);
export const useBundleByFullCodeQuery = asyncHook((code: string) =>
  VisitorApi.getBundleByFullCode(code),
);
export const usePriceQuery = asyncHook((priceId: number | string) =>
  VisitorApi.getPrice(priceId),
);
export const useCountriesActiveQuery = asyncHook(() =>
  VisitorApi.getActiveCountries(),
);
export const usePaymentSessionForUser = asyncHook(
  (paySessionId: number, userId: number) =>
    VisitorApi.getPaymentSessionStatus(paySessionId, userId),
);
export const useCompatibleDevices = asyncHook(() =>
  VisitorApi.getCompatibleDevices(),
);
export const useSubscriptionProducts = asyncHook(() =>
  VisitorApi.getSubscriptionProducts(),
);
export const useGetSubscriptionPaymentStatus = asyncHook(
  (paymentSessionId: number, userId: number) =>
    VisitorApi.getSubscriptionPaymentStatus(paymentSessionId, userId),
);
export const useGetReviewsPerCountry = asyncHook((countryId: string) =>
  VisitorApi.getReviewsPerCountry(countryId),
);
