export default function TimeIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <g>
        <path
          d="M16.5002 28.8C13.9686 28.8 11.4939 28.0493 9.3889 26.6428C7.28395 25.2363 5.64334 23.2372 4.67454 20.8983C3.70574 18.5595 3.45226 15.9858 3.94615 13.5028C4.44004 11.0199 5.65912 8.73914 7.44923 6.94903C9.23935 5.15892 11.5201 3.93984 14.003 3.44595C16.486 2.95206 19.0597 3.20554 21.3986 4.17434C23.7374 5.14314 25.7365 6.78375 27.143 8.8887C28.5495 10.9936 29.3002 13.4684 29.3002 16C29.3002 19.3948 27.9516 22.6505 25.5512 25.051C23.1507 27.4514 19.895 28.8 16.5002 28.8ZM16.5002 5.33333C14.3905 5.33333 12.3282 5.95892 10.5741 7.13099C8.81999 8.30306 7.45282 9.96896 6.64549 11.918C5.83815 13.8671 5.62692 16.0118 6.03849 18.081C6.45007 20.1501 7.46597 22.0507 8.95773 23.5425C10.4495 25.0342 12.3501 26.0501 14.4192 26.4617C16.4884 26.8733 18.6331 26.6621 20.5822 25.8547C22.5312 25.0474 24.1971 23.6802 25.3692 21.9261C26.5413 20.172 27.1669 18.1097 27.1669 16C27.1669 13.171 26.0431 10.4579 24.0427 8.45753C22.0423 6.45714 19.3292 5.33333 16.5002 5.33333Z"
          fill="currentColor"
        />
        <path
          d="M21.8331 21.8667C21.5687 21.866 21.3141 21.7671 21.1184 21.5893L15.7851 16.7893C15.6746 16.6896 15.5862 16.5679 15.5256 16.4319C15.465 16.296 15.4335 16.1488 15.4331 16V10.6667C15.4331 10.3838 15.5455 10.1125 15.7455 9.91242C15.9456 9.71238 16.2169 9.6 16.4998 9.6C16.7827 9.6 17.054 9.71238 17.254 9.91242C17.4541 10.1125 17.5664 10.3838 17.5664 10.6667V15.52L22.5478 20C22.7109 20.1438 22.8261 20.3341 22.8781 20.5452C22.93 20.7564 22.9162 20.9783 22.8384 21.1814C22.7607 21.3845 22.6227 21.559 22.4431 21.6814C22.2634 21.8039 22.0505 21.8685 21.8331 21.8667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
