import styled from 'styled-components';
import LocationSVG from './Icons/LocationSVG';
import CaseSVG from './Icons/CaseSVG';
import DualSimSVG from './Icons/DualSimSVG';
import SunriseSVG from './Icons/SunriseSVG';
import GraduationSVG from './Icons/GraduationSVG';
import TimeSVG from './Icons/TimeSVG';
import LaptopSVG from './Icons/LaptopSVG';

export default function WeGotYouCovered() {
  return (
    <WeGotYouCoveredWrapper>
      <Background>
        <Header>{`We got you covered for all your
      eSIM needs`}</Header>
        <Bullets>
          <Bullet>
            <CaseSVG />
            <BulletText>Business trips</BulletText>
          </Bullet>
          <Bullet>
            <DualSimSVG />
            <BulletText>Dual-SIM functionality</BulletText>
          </Bullet>
          <Bullet>
            <SunriseSVG />
            <BulletText>Family vacations</BulletText>
          </Bullet>
          <Bullet>
            <GraduationSVG />
            <BulletText>Study abroad</BulletText>
          </Bullet>
          <Divider />
          <Bullet>
            <TimeSVG />
            <BulletText>Temporary relocation</BulletText>
          </Bullet>
          <Bullet>
            <LaptopSVG />
            <BulletText>Remote work</BulletText>
          </Bullet>
          <Bullet>
            <LocationSVG />
            <BulletText>International studying</BulletText>
          </Bullet>
        </Bullets>
      </Background>
    </WeGotYouCoveredWrapper>
  );
}

let WeGotYouCoveredWrapper = styled.div``;

let Background = styled.div`
  padding: 16px;
  background-color: var(--backgroundSecondaryNew);

  @media (min-width: 700px) {
    padding: 32px;
    background: transparent;
    text-align: center;
  }

  @media (min-width: 960px) {
    padding: 48px 0;
    width: calc(100% - 64px);
    max-width: 1040px;
    margin: 0 auto;
  }
`;

let Header = styled.h2`
  width: 100%;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: var(--fontWeightBold);

  @media (min-width: 700px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;

let Bullets = styled.div`
  @media (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }

  @media (min-width: 960px) {
    column-gap: 8px;
    row-gap: 4px;
  }
`;

let Divider = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
    width: 100%;
  }
`;

let Bullet = styled.div`
  margin-bottom: 28px;
  height: 32px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: initial;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 24px;
  }

  @media (min-width: 700px) {
    height: 56px;
    font-size: 16px;
    margin: initial;
    padding: 16px;
    background-color: var(--backgroundSecondaryNew);
    border-radius: 32px;

    svg {
      margin-right: 5px;
    }
  }

  @media (min-width: 960px) {
    padding: 16px 24px;
  }
`;

let BulletText = styled.span`
  line-height: 20px;
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);

  @media (min-width: 700px) {
  }
`;
