import { Dispatch, ReactElement, SetStateAction } from 'react';
import { routeRedirect } from '@front_common/Router/Router';
import {
  ActivatedPlanContent,
  DataFinishedPlanContent,
  ExpiredPlanContent,
  InstalledPlanContent,
  NotActivatedPlanContent,
  PreparingPlanContent,
  RefundedPlanContent,
  StatusWrapper,
  UnknownPlanContent,
} from './ESimsPage/ESimCardsContent';
import { EsimStatus } from '@shared_backend/Module/Esim/EsimStatus';
import ESimPagePlanInfo from './ESimPage/ESimPagePlanInfo';
import ESimPageInfoBox from './ESimPage/ESimPageInfoBox';
import ESimReconnectTooltip from './ESimPage/ESimReconnectTooltip';
import { ESimActions } from './ESimPage/ESimPageActions';
import {
  ActivatedPlanLoaderContent,
  DataFinishedLoaderContent,
  DefaultLoaderContent,
} from './ESimPage/ESimLoaders';
import { useESimCardContext } from './ESimContext';
import { logger } from '@shared_frontend/Common/BetterStackLogger';
import {
  calculateUsagePercentage,
  formatDataAmount,
  formatDateToShortString,
} from './Utils';
import { getESimCountryUrlByCountryCode } from '../CountryStore/OldDesign/CountryStoreUtils';
import { useSessionData } from '@front_common/Api/Session';
import { countryNameByCode } from '@shared_backend/Module/Geo/CountryNames';

type ElementContent = {
  cardContent: ReactElement | null;
  status: ReactElement | null;
  pageContent: ReactElement;
  tooltip: ReactElement | null;
  actions: ESimActions[];
  loader: ReactElement | null;
};

let defaultActions = [
  ESimActions.INSTRUCTIONS_INSTALLATION,
  ESimActions.PLAN_DETAILS,
  ESimActions.TROUBLESHOOTING,
];

// eslint-disable-next-line max-lines-per-function
export default function useESimPlan(
  setShowQrForId?: Dispatch<SetStateAction<string | null>>,
) {
  let { eSim, isFromActive } = useESimCardContext();
  let sessionData = useSessionData();

  let {
    dataUsedMb,
    dataRemainingMb,
    dataPurchasedGb,
    expiresIn,
    bundleExpiresAt,
    refundedAt,
    durationDays,
    targetCountry,
  } = eSim;

  let isActive = eSim.status === EsimStatus.ACTIVATED;

  function handleInstallNewSim(event: MouseEvent) {
    event.stopPropagation();
    if (setShowQrForId) {
      setShowQrForId(String(eSim.id));
    }
  }

  function handleRepurchaseSim(event: MouseEvent) {
    event.stopPropagation();
    if (!eSim.targetCountry) {
      // TODO process "null" better
      logger.log('no target country ', { eSim });
      return;
    }
    routeRedirect(getESimCountryUrlByCountryCode(eSim.targetCountry));
  }

  let strategyMap: Record<EsimStatus, () => ElementContent> = {
    [EsimStatus.ACTIVATED]: () => {
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusActivated)"
          data-testid={`esim-status-${EsimStatus.ACTIVATED}`}
        >
          Activated
        </StatusWrapper>
      );

      return {
        cardContent: (
          <ActivatedPlanContent
            dataRemainingMb={dataRemainingMb}
            dataPurchasedGb={dataPurchasedGb}
            expiresIn={expiresIn}
          />
        ),
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Expires in:" value={expiresIn || ''}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: (
          <ESimPageInfoBox message="Your eSIM is active and ready to use. Enjoy your data plan! You can monitor your data usage and see the validity period here." />
        ),
        actions: defaultActions,
        loader: (
          <ActivatedPlanLoaderContent
            progress={calculateUsagePercentage(
              dataUsedMb,
              dataPurchasedGb * 1024,
            )}
            availableData={formatDataAmount(dataRemainingMb)}
            purchasedData={formatDataAmount(dataPurchasedGb * 1024)}
          />
        ),
      };
    },
    [EsimStatus.NOT_INSTALLED]: () => {
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusNotActivated)"
          data-testid={`esim-status-${EsimStatus.NOT_INSTALLED}`}
        >
          Not installed
        </StatusWrapper>
      );

      return {
        cardContent: (
          <NotActivatedPlanContent
            expiresIn={`${durationDays} days`}
            handleInstallNewSim={handleInstallNewSim}
          />
        ),
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Valid for:" value={`${durationDays} days`}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: (
          <ESimPageInfoBox message="Install the eSIM on your device to start using your plan. Please note, the plan will activate and change status only once you arrive at your destination." />
        ),
        actions: defaultActions,
        loader: (
          <DefaultLoaderContent
            label="Available:"
            availableData={formatDataAmount(dataRemainingMb)}
          />
        ),
      };
    },
    [EsimStatus.PREPARING]: () => {
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusPreparing)"
          data-testid={`esim-status-${EsimStatus.PREPARING}`}
        >
          Preparing
        </StatusWrapper>
      );

      return {
        cardContent: <PreparingPlanContent />,
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Valid for:" value={`${durationDays} days`}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: (
          <ESimPageInfoBox message="Your payment is received. Please wait while we prepare your eSIM." />
        ),
        actions: [ESimActions.PLAN_DETAILS, ESimActions.TROUBLESHOOTING],
        loader: (
          <DefaultLoaderContent
            label="Data amount:"
            availableData={formatDataAmount(dataRemainingMb)}
          />
        ),
      };
    },
    [EsimStatus.INSTALLED]: () => {
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusInstalled)"
          data-testid={`esim-status-${EsimStatus.INSTALLED}`}
        >
          Installed
        </StatusWrapper>
      );

      let tooltipMessage: string | ReactElement =
        `Your eSIM is installed but not yet active. To start using your data plan, 
        you must be in your target location and have the line turned on.`;

      if (sessionData?.country && targetCountry) {
        tooltipMessage =
          targetCountry.toLowerCase() === sessionData?.country.toLowerCase() ? (
            `Your eSIM is installed but not yet active. To start using your data plan, you must have ‘Data roaming’ turned on and eSIM chosen as ‘Primary’ for Mobile Data`
          ) : (
            <span>
              Your eSIM is installed but not yet active. To start using your
              data plan, you must be in{' '}
              <b>{countryNameByCode(targetCountry)} </b>
              <span>and have the line turned on.</span>
            </span>
          );
      }

      return {
        cardContent: (
          <InstalledPlanContent expiresIn={`${durationDays} days`} />
        ),
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Valid for:" value={`${durationDays} days`}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: <ESimPageInfoBox message={tooltipMessage} />,
        actions: defaultActions,
        loader: (
          <DefaultLoaderContent
            label="Data amount:"
            availableData={formatDataAmount(dataRemainingMb)}
          />
        ),
      };
    },
    [EsimStatus.EXPIRED]: () => {
      let expireDate = formatDateToShortString(bundleExpiresAt);
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.EXPIRED}`}
        >
          Expired
        </StatusWrapper>
      );

      return {
        cardContent: (
          <ExpiredPlanContent
            expiresAt={expireDate}
            handleRepurchaseSim={handleRepurchaseSim}
          />
        ),
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Expired on:" value={expireDate}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: isFromActive ? (
          <ESimReconnectTooltip
            handleRepurchaseSim={handleRepurchaseSim}
            message="The validity period on this eSIM has ended. To stay connected, you can purchase a new plan."
          />
        ) : null,
        actions: [ESimActions.PLAN_DETAILS],
        loader: (
          <DefaultLoaderContent label="Data amount:" availableData="0GB" />
        ),
      };
    },
    [EsimStatus.DATA_FINISHED]: () => {
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.DATA_FINISHED}`}
        >
          Data finished
        </StatusWrapper>
      );

      return {
        cardContent: (
          <DataFinishedPlanContent
            dataPurchasedGb={dataPurchasedGb}
            handleRepurchaseSim={handleRepurchaseSim}
          />
        ),
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Valid for:" value={`${durationDays} days`}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: isFromActive ? (
          <ESimReconnectTooltip
            handleRepurchaseSim={handleRepurchaseSim}
            message="You've used all the data on this eSIM. To stay connected, you can purchase a new plan."
          />
        ) : null,
        actions: [ESimActions.PLAN_DETAILS],
        loader: (
          <DataFinishedLoaderContent
            availableData={formatDataAmount(dataPurchasedGb * 1024)}
          />
        ),
      };
    },
    [EsimStatus.REFUNDED]: () => {
      let refundedDate = refundedAt ? formatDateToShortString(refundedAt) : '';
      let statusElement = (
        <StatusWrapper
          $bgColor="var(--statusArchived)"
          data-testid={`esim-status-${EsimStatus.REFUNDED}`}
        >
          Refunded
        </StatusWrapper>
      );

      return {
        cardContent: refundedAt ? (
          <RefundedPlanContent refundedAt={refundedDate} />
        ) : null,
        status: statusElement,
        pageContent: (
          <ESimPagePlanInfo label="Refund date:" value={refundedDate}>
            {statusElement}
          </ESimPagePlanInfo>
        ),
        tooltip: (
          <ESimPageInfoBox message="Your purchase has been refunded, and the payment has been returned to your payment method." />
        ),
        actions: [ESimActions.PLAN_DETAILS],
        loader: (
          <DefaultLoaderContent label="Data amount:" availableData="0GB" />
        ),
      };
    },
    [EsimStatus.UNKNOWN]: () => ({
      cardContent: (
        <UnknownPlanContent
          handleInstallNewSim={handleInstallNewSim}
          validFor={`${durationDays} days`}
        />
      ),
      status: null,
      pageContent: (
        <ESimPagePlanInfo
          label="Valid for:"
          value={`${durationDays} days`}
        ></ESimPagePlanInfo>
      ),
      tooltip: null,
      actions: defaultActions,
      loader: (
        <DefaultLoaderContent label="Data amount:" availableData="100GB" />
      ),
    }),
  };

  let content: ElementContent =
    strategyMap[eSim.status || EsimStatus.UNKNOWN]();

  return {
    content,
    isActive,
    handleInstallNewSim,
  };
}
