import styled from 'styled-components';
import SolidgateProcessor from '../../SolidgateProcessor/OldDesign/SolidgateProcessor';
import { useCreatePaymentSession } from '@front_common/Api/UserApi';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import { useEffect } from 'preact/hooks';
import PaymentError from '../../PaymentError/PaymentError';
import { useDiscountPayment } from '../../DiscountPaymentContext';

//TODO: remove unused callbacks
interface IProps {
  onSuccessfulPayment?: (paymentSession: PaymentSessionEntityType) => void;
  onPaymentFail?: (error: string) => void;
  onDefaultPaymentSessionChange?: (
    paymentSession: PaymentSessionEntityType | null,
  ) => void;
}

export default function Payment(props: IProps) {
  let { onSuccessfulPayment, onPaymentFail, onDefaultPaymentSessionChange } =
    props;

  let {
    bundleCodeAndPriceId,
    targetCountry,
    discountPaymentSession,
    setResendDiscountRequest,
  } = useDiscountPayment();

  let [defaultPaymentSession, createSessionError, isLoading, execute] =
    useCreatePaymentSession(bundleCodeAndPriceId, targetCountry);

  useEffect(() => {
    if (onDefaultPaymentSessionChange) {
      onDefaultPaymentSessionChange(defaultPaymentSession || null);
    }
  }, [defaultPaymentSession]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (createSessionError) {
    return <PaymentError errorText={createSessionError.message} />;
  }

  if (!defaultPaymentSession) {
    return null;
  }

  function onSuccess(paymentSession: PaymentSessionEntityType) {
    if (onSuccessfulPayment) {
      onSuccessfulPayment(paymentSession);
    }
  }

  function onFail(error: string) {
    execute();
    setResendDiscountRequest(true);

    if (onPaymentFail) {
      onPaymentFail(error);
    }
  }

  return (
    <PaymentWrapper>
      <SolidgateProcessor
        paymentSession={discountPaymentSession || defaultPaymentSession}
        isWebView={false}
        onSuccess={onSuccess}
        onFail={onFail}
      />
    </PaymentWrapper>
  );
}

let PaymentWrapper = styled.div`
  width: 100%;
`;
