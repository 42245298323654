import Modal from '@front_common/Components/Modal/OldDesign/Modal';
import RenewalModalContent from './RenewalModalContent';
import { useEffect, useState } from 'preact/hooks';
import {
  useGetMyEsimsQuery,
  useGetMySubscriptions,
} from '@front_common/Api/UserApi';
import { SubscriptionStatus } from '@shared_backend/Module/Subscription/MySubscriptionResponse';

const RENEWAL_DATE = 'renewal_date';

function setRenewalDateToStorage(date: string) {
  localStorage.setItem(RENEWAL_DATE, date);
}

type IProps = {
  eSimId?: number | undefined;
};

export default function RenewalModal(props: IProps) {
  let { eSimId: currentESimId } = props;

  let [response] = useGetMyEsimsQuery();
  let [responseSub] = useGetMySubscriptions();

  if (!response || !responseSub) {
    return null;
  }

  let activeSubscription = responseSub.find(
    (sub) => sub.status === SubscriptionStatus.ACTIVE,
  );
  let eSim = response.esims.find(
    (esim) => esim.id === activeSubscription?.esimId,
  );

  if (!activeSubscription || !eSim) {
    return null;
  }

  let [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);

  let { dataRolledOverMb } = eSim;
  let { renewsAt } = activeSubscription;

  function onRenewalModalClose() {
    setIsRenewalModalOpen(false);
  }

  useEffect(() => {
    let renewalDateStorage = localStorage.getItem(RENEWAL_DATE); //27.01.2025 19:00
    let renewalDate = String(renewsAt); //27.01.2025 19:00

    //initial case
    if (renewalDateStorage === null) {
      setRenewalDateToStorage(renewalDate);
      return;
    }

    //check if the new period started
    //if renewalDate > renewalDateStorage it means that the new period started
    //because the renewalDate is always the next period start date
    //
    //we have currentESimId when we on the esim page
    //and we don't need to show the modal if the current eSim is not the same as the eSim with active subscription
    if (
      new Date(renewalDate) > new Date(renewalDateStorage) &&
      dataRolledOverMb &&
      dataRolledOverMb > 10 &&
      (currentESimId ? currentESimId === eSim.id : true)
    ) {
      setRenewalDateToStorage(renewalDate);
      setIsRenewalModalOpen(true);
    }
  }, []);

  if (!dataRolledOverMb) {
    return null;
  }

  return (
    <Modal
      analyticType="renewal-successful"
      isOpen={isRenewalModalOpen}
      onClose={onRenewalModalClose}
      closeButtonPrimary={false}
    >
      <RenewalModalContent
        retainedData={dataRolledOverMb}
        closeModal={onRenewalModalClose}
      />
    </Modal>
  );
}
