import { ADDRESS } from '@shared_frontend/Module/Layout/FooterConstants';
import visaImage from '../Images/Visa.png';
import mastercardImage from '../Images/MC.png';
import pciImage from '../Images/PCI.png';
import styled from 'styled-components';
import Card from '@front_common/Components/Card';

export default function PaymentSecured() {
  return (
    <PaymentSecuredWrapper>
      <TitleWrapper>
        <Title>Your payment is secured</Title>
      </TitleWrapper>
      <ContentWrapper>
        <Vendors>
          <Vendor src={mastercardImage} alt="Mastercard" />
          <Vendor src={visaImage} alt="Visa" />
          <Vendor src={pciImage} alt="PCI" />
        </Vendors>
        <LegalInfo>The payment processed by {ADDRESS}</LegalInfo>
      </ContentWrapper>
    </PaymentSecuredWrapper>
  );
}

let PaymentSecuredWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--textPrimaryNew);
  font-family: var(--fontFamilyNew);
  border: 1px solid var(--strokePrimaryNew);
`;

let TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  align-content: center;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

let Title = styled.h3`
  line-height: 24px;
  text-align: center;
  font-size: var(--fontSizeRegular);
  font-weight: var(--fontWeightBold);
`;

let ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;

  @media (min-width: 393px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

let Vendors = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

let Vendor = styled.img`
  height: 24px;
`;

let LegalInfo = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--textSecondaryNew);

  @media (min-width: 393px) {
    text-align: right;
    min-width: 128px;
  }

  @media (min-width: 700px) {
    max-width: 230px;
  }
`;
