import styled from 'styled-components';
import { DiscountPaymentProvider } from '../../PaymentProcessor/DiscountPaymentContext';
import {
  Discount,
  DiscountTotal,
} from '../../PaymentProcessor/Discount/OldDesign/Discount';
import LoadingAnimation from '@front_common/Components/LoadingAnimation';
import {
  useBundleByShortCodeQuery,
  usePriceQuery,
} from '@front_common/Api/VisitorApi';
import { useEffect, useState } from 'react';
import { PaymentSessionEntityType } from '@shared_backend/Module/Payment/PaymentSessionEntityType';
import Card from '@front_common/Components/Card';
import Payment from '../../PaymentProcessor/Payment/OldDesign/Payment';
import DiscountDropdown from './DiscountDropdown/DiscountDropdown';
import ProductInfo from './ProductInfo';
import AuthForm from '../../../Auth/AuthForm/OldDesign/AuthForm';
import AuthService from '@front_common/Api/AuthService';
import bluredSImage from './Images/BluredMobile.png';
import bluredMImage from './Images/BluredTablet.png';
import bluredLImage from './Images/BluredDesktop.png';
import LockIcon from '../../Icons/LockIcon';
import CheckboxCircleIcon from '../../Icons/CheckboxCircleIcon';
import UserApi from '@front_common/Api/UserApi';
import Legal from '../../../Auth/AuthForm/OldDesign/Legal';
import PaymentSecured from '../../PaymentSecured/OldDesign/PaymentSecured';
import PaymentErrorSupport from './PaymentErrorSupport/PaymentErrorSupport';

type IProps = {
  bundleCodeAndPriceId: string;
  targetCountry: string;
  onPaymentSuccess: (paymentSession: PaymentSessionEntityType) => void;
};

export default function CheckoutAModalContent(props: IProps) {
  let { bundleCodeAndPriceId, targetCountry, onPaymentSuccess } = props;

  let [authenticated, setAuthenticated] = useState(AuthService.isLoggedIn());
  let [email, setEmail] = useState('');
  let [authTitle, setAuthTitle] = useState('');
  let [paymentError, setPaymentError] = useState<string | null>(null);

  let [bundleShortCode, priceId] = bundleCodeAndPriceId.split('_');

  if (!bundleShortCode || !priceId) {
    throw new Error('Bundle short code or price ID is not defined');
  }

  let [bundle] = useBundleByShortCodeQuery(bundleShortCode);
  let [price] = usePriceQuery(priceId);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        let user = await UserApi.getUser();

        if (user) {
          setEmail(user.email);
        }
      }
    })();
  }, [authenticated]);

  if (!bundle || !price || !targetCountry) {
    return <LoadingAnimation />;
  }

  function handleAuthSuccess() {
    setAuthenticated(true);
  }

  function handleShowOTPChange(showOTP: boolean) {
    if (showOTP) {
      setAuthTitle('Enter the verification code');
    } else {
      setAuthTitle('');
    }
  }

  return (
    <CheckoutAModalContentStyled>
      {paymentError && <PaymentErrorSupport text={paymentError} />}

      <DiscountPaymentProvider
        totalAmountCents={price.totalAmountCents}
        bundleCodeAndPriceId={bundleCodeAndPriceId}
        targetCountry={targetCountry}
      >
        <AuthWrapper>
          <ProductInfo
            bundle={bundle}
            totalAmountCents={price.totalAmountCents}
            targetCountry={targetCountry}
          />
          <Divider />
          {authenticated ? (
            <AuthenticatedEmail>
              <span>{email}</span>
              <CheckboxCircleIcon />
            </AuthenticatedEmail>
          ) : (
            <AuthFormWrapper>
              {authTitle && <AuthTitle>{authTitle}</AuthTitle>}
              <AuthForm
                onAuthSuccess={handleAuthSuccess}
                onShowOTPChange={handleShowOTPChange}
              />
            </AuthFormWrapper>
          )}
        </AuthWrapper>
        {authenticated && (
          <DiscountDropdown>
            <Discount />
          </DiscountDropdown>
        )}
        <DiscountTotal />

        <div>
          {authenticated ? (
            <PaymentWrapper>
              <PaymentTitle>Choose payment method</PaymentTitle>
              <Payment
                onSuccessfulPayment={onPaymentSuccess}
                onPaymentFail={setPaymentError}
              />
              <Legal text="By submitting your information and continuing to purchase, you agree to our" />
            </PaymentWrapper>
          ) : (
            <Blured>
              <picture>
                <source media="(min-width: 393px)" srcSet={bluredMImage} />
                <source media="(min-width: 700px)" srcSet={bluredLImage} />
                <img src={bluredSImage} alt="blured payment" />
              </picture>
              <TextIcon>
                <LockIcon />
                <span>Checkout will open after email validation</span>
              </TextIcon>
            </Blured>
          )}
        </div>
      </DiscountPaymentProvider>

      <Card>
        <PaymentSecured />
      </Card>
    </CheckoutAModalContentStyled>
  );
}

let CheckoutAModalContentStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let AuthWrapper = styled(Card)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--strokeSecondary);
`;

let Blured = styled(Card)`
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;

  picture {
    width: 100%;
    height: 568px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 393px) {
    picture {
      height: 522px;
    }
  }
  @media (min-width: 700px) {
    picture {
      height: 438px;
    }
  }
`;

let TextIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    font-family: var(--fontFamilyHeader);
    font-size: var(--fontSizeBig);
    font-weight: var(--fontWeightMedium);
    line-height: 24px;
    text-align: center;
  }
`;

let AuthenticatedEmail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  text-align: left;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  svg {
    min-width: 24px;
  }
`;

let AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

let AuthTitle = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  text-align: left;
`;

let PaymentWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

let PaymentTitle = styled.h4`
  font-family: var(--fontFamilyHeader);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 28px;
  text-align: left;
`;
