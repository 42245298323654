import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import { showConsentCountries } from '@shared_frontend/Module/Email/ShowConsentCountries';
import { useSessionData } from '@front_common/Api/Session';

export default function EmailConsent(
  props: React.HTMLAttributes<HTMLInputElement>,
) {
  let { ...rest } = props;

  let [isChecked, setIsChecked] = useState(false);
  let [showElement, setShowElement] = useState(false);

  let sessionData = useSessionData();

  useEffect(() => {
    if (sessionData) {
      if (showConsentCountries.includes(sessionData.country)) {
        setShowElement(true);
      } else {
        setShowElement(false);
        setIsChecked(true);
      }
    }
  }, [sessionData]);

  function handleCheckboxChange() {
    setIsChecked((prev) => !prev);
  }

  return (
    <CheckboxContainer $show={showElement}>
      <Checkbox
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        {...rest}
      />
      <Text>
        I would like to receive special offers, promotions and product updates
        from Ohayu via email.
      </Text>
    </CheckboxContainer>
  );
}

let CheckboxContainer = styled.label<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  cursor: pointer;
`;

let Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;

  /* Hide default checkbox */
  appearance: none;
  border: var(--borderOutlinePrimary);
  border-radius: 6px;
  position: relative;

  /* Custom checkmark */
  &:checked::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid var(--strokeBrand);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

let Text = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;
